import React ,{ useState } from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";
import {
    getCashRecieptWithAccNameById,
    getCostCenter,
  } from "../../Slice/cashReceipt.slice";
  import { getJVWithAccNameById } from "../../Slice/journalVoucher.slice";
  import { connect } from "react-redux";
  import { compose } from "@reduxjs/toolkit";
  import { showLoador, showNotification } from "../Landing/Landing.slice";
  import CashReciptPopupForReport from "../CashReceipt/CashReciptPopupForReport";
  import BankReciptPopupForReport from "../BankReciept/BankReciptPopupForReport";
  import CashPaymentPopupForReport from "../CashPayment/CashPaymentPopupForReport";
  import BankPaymentPopupForReport from "../BankPayment/BankPaymentPopupForReport";
  import JouranlEntryPopupForReport from "../JournalEntry/JournalEntryPopupForReport";
  import ContraEntryPopupForReport from "../ContraEntry/ContraEntryPopupForReport";
  import DebiteNotePopupForReport from "../DebitNote/DebiteNotePopupForReport";
  import CreditNotePopupForReport from "../CreditNote/CrediteNotePopupForReport";
const CashbookTable = ({ rowList = [], tableHead = [], classes, formData={},
    getList,
    getCashRecieptWithAccNameById,
    getJVWithAccNameById, }) => {

        const [openPopup, setOpenPopup] = useState(false);
        const [openBankReceiptPopup, setOpenBankReceiptPopup] = useState(false);
        const [openCashPaymentPopup, setOpenCashPaymentPopup] = useState(false);
        const [openBankPaymentPopup, setOpenBankPaymentPopup] = useState(false);
        const [openJouranlEntryPopup, setOpenJouranlEntryPopup] = useState(false);
        const [openContraEntryPopup, setOpenContraEntryPopup] = useState(false);
        const [openDebiteNotePopup, setOpenDebiteNotePopup] = useState(false);
        const [openCreditNotePopup, setOpenCreditNotePopup] = useState(false);
      
        const [editData, setEditData] = useState({});
        const [journalEditData, setJournalEditData] = useState({});
      
        const togglePopup = () => {
          setOpenPopup(!openPopup);
         getList(formData.fromDate, formData.toDate, formData.companyId)
        };
        const toggleBankReceiptPopup = () => {
          setOpenBankReceiptPopup(!openBankReceiptPopup);
          getList(formData.fromDate, formData.toDate, formData.companyId,formData.accountLedgerGroupsId)
        };
        const toggleCashPaymentPopup = () => {
          setOpenCashPaymentPopup(!openCashPaymentPopup);
          getList(formData.fromDate, formData.toDate, formData.companyId,formData.accountLedgerGroupsId)
        };
        const toggleBankPaymentPopup = () => {
          setOpenBankPaymentPopup(!openBankPaymentPopup);
          getList(formData.fromDate, formData.toDate, formData.companyId,formData.accountLedgerGroupsId)
        };
        const toggleJouranlEntryPopup = () => {
          setOpenJouranlEntryPopup(!openJouranlEntryPopup);
          getList(formData.fromDate, formData.toDate, formData.companyId,formData.accountLedgerGroupsId)
        };
      
        const toggleContraEntryPopup = () => {
          setOpenContraEntryPopup(!openContraEntryPopup);
          getList(formData.fromDate, formData.toDate, formData.companyId,formData.accountLedgerGroupsId)
        };
        const toggleDebiteNotePopup = () => {
          setOpenDebiteNotePopup(!openDebiteNotePopup);
          getList(formData.fromDate, formData.toDate, formData.companyId,formData.accountLedgerGroupsId)
        };
      
        const toggleCreditNotePopup = () => {
          setOpenCreditNotePopup(!openCreditNotePopup);
          getList(formData.fromDate, formData.toDate, formData.companyId,formData.accountLedgerGroupsId)
        };
        const getByIdData = async (id, tranctionTable) => {
          if (tranctionTable == 2) {
            showLoador({ loador: true });
            await getCashRecieptWithAccNameById({
              id: id,
            }).then(({ response, success }) => {
              showLoador({ loador: false });
              if (!success) {
              } else {
                setEditData(response);
                if (response.voucherType != null) {
                  if (response.voucherType == 1) {
                    setOpenPopup(!openPopup);
      
                  }
                  if (response.voucherType == 2) {
                    setOpenCashPaymentPopup(!openCashPaymentPopup);
                  }
                  if (response.voucherType == 3) {
                    setOpenBankReceiptPopup(!openBankReceiptPopup);
                  }
                  if (response.voucherType == 4) {
                    setOpenBankPaymentPopup(!openBankPaymentPopup);
                  }
      
                  if (response.voucherType == 6) {
                    setOpenCreditNotePopup(!openCreditNotePopup);
                  }
                  if (response.voucherType == 7) {
                    setOpenDebiteNotePopup(!openDebiteNotePopup);
                  }
                  if (response.voucherType == 8) {
                    setOpenContraEntryPopup(!openContraEntryPopup);
                  }
                }
              }
            });
          }
      
          if (tranctionTable == 3) {
            showLoador({ loador: true });
            await getJVWithAccNameById({
              id: id,
            }).then(({ response, success }) => {
              showLoador({ loador: false });
              if (!success) {
              } else {
                setJournalEditData(response);
      
                setOpenJouranlEntryPopup(!openJouranlEntryPopup);
              }
            });
          }
        };


    return (
        <>
            <div style={{ overflow: "auto" }}>
                <table className={classes.MuiTable}>
                    <tr className={classes.MuiTableHeadRoot}>
                        {
                            tableHead.map((head) => {
                                return (<td style={{ textAlign:"center"}} className={classes.MuiTableHeadCell} >{head.title}</td>)
                            })
                        } </tr>
                    {

                        rowList.map((data) => {
                            let creditTotal =data.openingAmt>0 ?data.openingAmt:0
                            let debitTotal =  data.openingAmt<=0 ?data.openingAmt:0
                            return (
                                <>
                                    <tr>
                                        <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight }}>
                                            {data.date}
                                        </td>
                                        <td className={classes.MuiTableBodyCell}>

                                        </td>
                                        <td className={classes.MuiTableBodyCell}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.MuiTableBodyCell} >
                                            Opening Amt
                                        </td>
                                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                                    
                                        {data.openingAmt<=0 ?Math.abs(data.openingAmt).toFixed(2):"" }  
                                      
                                        </td>
                                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                                        {data.openingAmt>0 ?Math.abs(data.openingAmt).toFixed(2):"" }
                                       
                                        </td>
                                    </tr>
                                    {
                                        data.transactionDetail.map((data) => {
                                            if (data.type == "D") {
                                                debitTotal = debitTotal + data.amount
                                            } else {
                                                creditTotal = creditTotal + data.amount
                                            }

                                            return (
                                                <>
                                                <tr>
                                                {data.tranctionTable !=1 ? (
                          data.remark != null ? (
                            <td
                              className={classes.MuiTableBodyCell}
                              style={{
                                cursor: "pointer",
                                fontWeight: fontWeight,
                              }}
                              onClick={() => {
                                getByIdData(
                                  data.documentId,
                                  data.tranctionTable
                                );
                              }}
                            >
                              <>
                              {data.type != "D" ? "To " + data.refAccName+" ( "+data.remark+" )"   : "By " + data.refAccName +" ( "+data.remark+" )"}<br />
                              </>
                            </td>
                          ) : (
                            <td
                              className={classes.MuiTableBodyCell}
                              style={{
                                cursor: "pointer",
                                fontWeight: fontWeight,
                              }}
                              onClick={() => {
                                getByIdData(
                                  data.documentId,
                                  data.tranctionTable
                                );
                              }}
                            >
                              {data.type != "D" ? "To " + data.refAccName   : "By " + data.refAccName}<br />
                            </td>
                          )
                        ) : data.remark != null ? (
                          <td
                            className={classes.MuiTableBodyCell}
                            style={{ fontWeight: fontWeight }}
                          >
                            <>
                            {data.type != "D" ? "To " + data.refAccName+" ( "+data.remark+" )"   : "By " + data.refAccName +" ( "+data.remark+" )"}<br />
                            </>
                          </td>
                        ) : (
                          <td
                            className={classes.MuiTableBodyCell}
                            style={{ fontWeight: fontWeight }}
                          >
                            {data.type != "D" ? "To " + data.refAccName   : "By " + data.refAccName}<br />
                          </td>
                        )}
                                                    {/* {
                                                        data.remark!=null?
                                                        <td className={classes.MuiTableBodyCell}  style={{ fontWeight: fontWeight }}>
                                                        <>
                                                            {data.type != "D" ? "To " + data.refAccName+" ( "+data.remark+" )"   : "By " + data.refAccName +" ( "+data.remark+" )"}<br />
                                                         </>
                                                    </td>: 
                                                    <td className={classes.MuiTableBodyCell}  style={{ fontWeight: fontWeight }}>
                                                        <>
                                                            {data.type != "D" ? "To " + data.refAccName   : "By " + data.refAccName}<br />
                                                         </>

                                                    </td>
                                                    } */}
                                                   
                                                    <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                                                        <>
                                                            {data.type == "D" ? Math.abs(data.amount).toFixed(2) : ""}
                                                        </>

                                                    </td>
                                                    <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                                                        <>
                                                            {data.type == "D" ? "" : Math.abs(data.amount).toFixed(2)}
                                                        </>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.MuiTableBodyCell} >
                                                        <>
                                                           &emsp; {data.type != "D" ? "Amt Paid To " + data.refAccName : "Amt Received "}
                                                        </>

                                                    </td>
                                                    <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                                                     
                                                    </td>
                                                    <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                                                       

                                                    </td>
                                                </tr>
</>
                                            )
                                        })
                                    }
                                    <tr >
                                        <td className={classes.MuiTableBodyCell} >
                                        </td>
                                        <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, borderTop: border, textAlign: "right" }}>
                                           {debitTotal == 0 ? "" : Math.abs(debitTotal).toFixed(2)}
                                        </td>
                                        <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, borderTop: border, textAlign: "right" }}>
                                            {creditTotal == 0 ? "" : Math.abs(creditTotal).toFixed(2)}
                                        </td>

                                    </tr>
                                    <tr style={{ borderBottom: border }}>
                                        <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight }}>
                                            Day Closing
                                        </td>
                                        <td className={classes.MuiTableBodyCell}  >
                                        </td>
                                        <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, textAlign: "right" }}>
                                          {data.closingAmt>0 ?Math.abs(data.closingAmt).toFixed(2)+" Cr":Math.abs(data.closingAmt).toFixed(2)+" Dr" }
                                        </td>
                                    </tr>
                                </>

                            )
                        })}

                </table>
            </div>
            {openPopup && (
        <CashReciptPopupForReport
          editData={editData}
          open={openPopup}
          handleCloses={togglePopup}
        />
      )}
      {openBankReceiptPopup && (
        <BankReciptPopupForReport
          editData={editData}
          open={openBankReceiptPopup}
          handleCloses={toggleBankReceiptPopup}
        />
      )}
      {openCashPaymentPopup && (
        <CashPaymentPopupForReport
          editData={editData}
          open={openCashPaymentPopup}
          handleCloses={toggleCashPaymentPopup}
        />
      )}
      {openBankPaymentPopup && (
        <BankPaymentPopupForReport
          editData={editData}
          open={openBankPaymentPopup}
          handleCloses={toggleBankPaymentPopup}
        />
      )}

      {openJouranlEntryPopup && (
        <JouranlEntryPopupForReport
          editData={journalEditData}
          open={openJouranlEntryPopup}
          handleCloses={toggleJouranlEntryPopup}
        />
      )}

      {openContraEntryPopup && (
        <ContraEntryPopupForReport
          editData={editData}
          open={openContraEntryPopup}
          handleCloses={toggleContraEntryPopup}
        />
      )}

      {openDebiteNotePopup && (
        <DebiteNotePopupForReport
          editData={editData}
          open={openDebiteNotePopup}
          handleCloses={toggleDebiteNotePopup}
        />
      )}

      {openCreditNotePopup && (
        <CreditNotePopupForReport
          editData={editData}
          open={openCreditNotePopup}
          handleCloses={toggleCreditNotePopup}
        />
      )}
        </>
    );
};


const mapStateToProps = (state) => ({
    cashRecieptList: state.cashReciept,
    journalVoucherList: state.journalVoucher,
  });
  
  const mapDispatchToProps = {
    getCashRecieptWithAccNameById,
    getJVWithAccNameById,
    showLoador,
    showNotification,
  };
  
  export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(CashbookTable);
