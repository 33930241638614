import dayjs from "dayjs";

export const BankRecoJson = {
    screenTitle: "Bank Reco",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta:
    [
     
      {
        label: "From Date",
        controlType: "datepicker",
        placeHolder: "",
        md:3.5,
        lg:3.5,
        sm:3.5,
        xs: 12,
        showLabel:true,
        error:"From Date",
        dataKey: "fromDate",
        isMandatory: true,
        onSubmit: "compare",
        onSubmitParameter: "toDate-le",
        defaultValue: dayjs(new Date()),
      },
      {
        label: "To Date",
        controlType: "datepicker",
        placeHolder: "",
        md:3.5,
        lg:3.5,
        sm:3.5,
        xs: 12,
        hideLabel:true,
        dataKey: "toDate",
        onSubmit: "compare",
        error:"To Date",
        onSubmitParameter: "fromDate-ge",
        isMandatory: true,
        showLabel:true,
        onSubmit: "compare",
        defaultValue: dayjs(new Date()),
      },
     
      {
        label: "Account",
        controlType: "autocomplete",
        placeHolder: "Select  Account ",
        md:4,
        lg:4,
        sm:4,
        xs: 12,
        error:"accountLedgerGroupsMaster",
        masterName: "accountLedgerGroupMasterDetail",
      hideLabel:true,
        dataKey: "accountLedgerGroupsId",
        isRootLevelKey: true,
        isMandatory: true,
      },
    ]
  };
  