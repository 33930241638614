import endpoint from "../../config/endpoints";

export const salePurchaseSettleJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  formPath: "/settle-bill",
  formPathView: "/payment-list",
  screenTitle: "Settle Bill/Purchase",
  showTitle: true,
  showCancel: false,
  showBackToList: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Entry Type",
      controlType: "autocomplete",
      placeHolder: "Select Entry type ",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
      masterName: "entryTypeDetails",
      dataKey: "entryType",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "entryType,accountLedgerCode",
    },
    {
      label: "Account",
      controlType: "autocomplete",
      placeHolder: "Select Account ",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
      masterName: "accountDetails",
      dataKey: "accountLedgerCode",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "accountLedgerCode,entryType",
    },
    {
      label: "Transactions ",
      controlType: "autocomplete",
      placeHolder: "Select Transactions",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
      masterName: "transactionDetails",
      dataKey: "transactionId",
      getListFrom: "entryType,accountLedgerCode",
      isRootLevelKey: true,
      isMandatory: true,
    },
    // {
    //   label: "Payment Receipt",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Payment Receipt",
    //   md: 5,
    //   lg: 5,
    //   sm: 5,
    //   xs: 12,
    //   masterName: "paymentRecTranDetail",
    //   dataKey: "paymentRecTran",
    //   getListFrom: "outlet",
    //   isRootLevelKey: false,
    //   isMandatory: true,
    //   getListId: "paymentRecTran",
    //   disableWithCondition: true,
    // },
  ],
};
