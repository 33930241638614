import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    payMode: [],
};
let URL = endpoints.payMode;
const payModeSlice = createSlice({
  name: "payMode",
  initialState,
  reducers: {
    payModeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        payMode: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { payModeSuccess, resetState } = payModeSlice.actions;

export default payModeSlice.reducer;

export const getPayMode = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
       const content=data.data;
      
        const row = data.data.length!=0?data.data.map((payListObject, index) => {
          let payData = {
            index: index + 1,
            id: payListObject.id === null ? "" : payListObject.id,
            name: payListObject.name === null ? "" : payListObject.name,
            payName:
            payListObject.name === null ? "" : payListObject.name,
            
          };
          return payData;
        }):[];
        dispatch(payModeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
