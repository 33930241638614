import endpoint from "../../config/endpoints";

export const settlementJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.asset,
  screenTitle: "Asset Calculation",
  formPath: "/asset-form",
  formPathView: "/asset-view",
  showBackToList: false,
  showTitle: true,
  
  fieldMeta: [
    {
        label: "Company",
        controlType: "autocomplete",
        placeHolder: "Select Company",
        md:4,
        lg:4,
        sm:4,
        xs: 12,
      
        error:"Company",
        masterName: "CompanyDetails",
      hideLabel:true,
        dataKey: "companyId",
        isRootLevelKey: true,
        isMandatory: true,
      },
      {
        label: "Year",
        controlType:"autocomplete",
        placeHolder: "Select  year ",
        md: 4,
        lg: 4,
        sm: 4,
        xs: 12,
       
        error:"year",
        masterName: "yearMasterDetail",
      hideLabel:true,
        dataKey: "yearId",
        isRootLevelKey: true,
        isMandatory: true,
      },
      {
        label: "Group",
        controlType: "multiSelect",
        placeHolder: "Select  Group ",
        md: 11,
        lg: 11,
        sm: 11,
        xs: 12,
        labelSM: 1.4,
        labelMD: 1.4,
        labelLG: 1.4,
        valueSM: 10.6,
        valueMD: 10.6,
        valueLG: 10.6,
        error:"accountLedgerGroupsMaster",
        masterName: "accountLedgerGroupMasterDetail",
  
        dataKey: "accountLedgerGroupsId",
        isRootLevelKey: true,
        isMandatory: true,
      },
  ],
 
 
  
};
