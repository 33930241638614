import { Divider, Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  deleteWarningMsg,
} from "../../config/messageconstant";

import { JournalEntryJSON } from "../../DynamicFormsJson/Transaction/journalEntry";
import {
  getAccountLedgerListByType,
  getAccountLedgerListByType1,
} from "../../Slice/subVoucherDetail.slice";
import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicTransactionForm from "../../components/dynamicscreens/DynamicTransactionForm";
import { getJVWithAccNameById } from "../../Slice/journalVoucher.slice";
import { getCostCenter } from "../../Slice/cashReceipt.slice";
import CostCenterMappingDialouge from "./CostCenterMappingDialouge";
import { getLedgerDataByAccId } from "../../Slice/ledger.slice";
import dayjs from "dayjs";
import { getProfitCenter } from "../../Slice/profitCenter.slice";

class JournalEntryFormForReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: { type: "C" },
      setFieldDataFromApi: {},
      voucherAmountShow: 0,
      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        creditAccountDetails:
          this.props.subVoucherDetailList?.accountLedgerByType1,
        cashBankDetails: this.props.subVoucherDetailList?.accountLedgerByType,
        costCenterDetail: this.props.cashRecieptList?.costCenter,
        typeDetails: [
          { id: "C", name: "Credit" },
          { id: "D", name: "Debit" },
        ],
        subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
        // voucherAmount: 0,
      },
      headerData: {},
      DataToSet: [],
      journalEntryDetailList: [],
      journalEntryDetailCcInfoList: [],
      openCostCenter: false,
      openBillMapping: false,
    };
  }
  componentWillReceiveProps(nextProps) {

    // if (this.props.editData.id != nextProps.editData.id) {
    //   this.getByIdData(nextProps.editData.id)
    // }
    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }
    if (
      this.props.subVoucherDetailList.accountLedgerByType !==
      nextProps.subVoucherDetailList.accountLedgerByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          cashBankDetails: nextProps.subVoucherDetailList.accountLedgerByType,
        },
      });
    }
    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          creditAccountDetails:
            nextProps.subVoucherDetailList.accountLedgerByType1,
        },
      });
    }
    if (
      this.props.cashRecieptList?.costCenter !==
      nextProps.cashRecieptList?.costCenter
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          costCenterDetail: nextProps.cashRecieptList?.costCenter,
        },
      });
    }
    if (
      this.props.subVoucherList?.subVoucherByType !==
      nextProps.subVoucherList?.subVoucherByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subVoucherDetail: nextProps.subVoucherList?.subVoucherByType,
        },
      });
    }
  }
  getListById = async (data) => {
    const {
      getAccountLedgerListByType,
      getAccountLedgerListByType1,
    
      showLoador,
      showNotification,
    } = this.props;
    console.log(data);
    if (navigator.onLine) {
    
   
      if (data.mainAccountId!=null && data.mainAccountId!="") {
        this.getList(data.mainAccountId, 1);
      }
      // if (data.cashBankId != null && data.cashBankId != "") {
      //   //    this.setState({
      //   //   dynamicMasterData: {
      //   //     ...this.state.dynamicMasterData,
      //   //     creditAccountDetails:this.state.dynamicMasterData.creditAccountDetails.filter((row)=>row.id!=data.cashBankId),
      //   //   },
      //   // });
      //   this.getList(data.cashBankId, 0);
      // }
   
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getList = async (accId, type) => {
    const {
      showLoador,
      showNotification,
      getLedgerDataByAccId,
      getSubVoucherByType,
      getCostCenter,
    } = this.props;
    const locationIdList = localStorage.getItem("locationIdList");
    let valuesArray = [];
    if (locationIdList != null) {
      valuesArray = JSON.parse(locationIdList);
      valuesArray = valuesArray.filter(
        (row) => row.id === localStorage.getItem("location")
      );
    }
    showLoador({ loador: true });
    await getLedgerDataByAccId({
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
      companyIds: valuesArray.length != 0 ? valuesArray[0].company.id : 0,
      accId: accId,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if (response!=null) {
          
          if (type == 1) {
            this.setState({
             
              setFieldDataFromApi: {
                ...this.state.fieldData,
                balance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt)+" "+"Cr": Math.abs(response.closingAmt)+" "+"Dr"
              },
            });
          } else {
            // this.setState({
            //   fieldData: {
            //     ...this.state.fieldData,
            //     balance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt)+" "+"Cr": Math.abs(response.closingAmt)+" "+"Dr"
            //   },
            // });
          }
        }
      }
    });
  };
  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const {
      showLoador,
      showNotification,
      getAccountLedgerListByType,
      getAccountLedgerListByType1,
      getSubVoucherByType,
     
      getProfitCenter,
      getCostCenter,
    } = this.props;
    const { dynamicMasterData } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAccountLedgerListByType({ accType: 0 }).then(
        ({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
            return { response, success };
          } else {
            return { response, success };
          }
        }
      );
      showLoador({ loador: true });
      const ledgerList = await getAccountLedgerListByType1({
        accType: 0,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return { response, success };
        } else {
          return { response, success };
        }
      });
      showLoador({ loador: true });
      await getProfitCenter().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return { response, success };
        } else {
          return { response, success };
        }
      });
    
      showLoador({ loador: true });
      await getSubVoucherByType({ type: 5 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
      showLoador({ loador: true });
      await getCostCenter().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
      if (ledgerList.success && this.props.editData) {
        this.getByIdData(this.props.editData,ledgerList)
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  getByIdData = async (editData,ledgerList) => {
   
   
        
            if(editData){
              let mainAccountCode = "";
              let mainAccountName = "";
              let amt = 0;
              let rowList = editData.journalEntryDetailList.map(
                (rowData, index) => {
                  let catList = ledgerList.response.length!=0
                    ? ledgerList.response.filter((row) => {
                        if (row.accountLedgerCode == rowData.mainAccountId) {
                          mainAccountCode = rowData.mainAccountId?rowData.mainAccountId:"";
                          mainAccountName = row.accountLedgerName!=null?row.accountLedgerName:"";
                        }
                      })
                    : [];
                  amt =
                    rowData.type == "C" ? amt + +rowData.amt : amt - +rowData.amt;
                  return {
                    index: index + 1,
                    mainAccountCode: mainAccountCode!=null?mainAccountCode:"",
                    mainAccountName: mainAccountName!=null?mainAccountName:"",
                    amt: rowData.amt!=null?rowData.amt:"",
                    type: rowData.type!=null?rowData.type:"",
                    sortNo: rowData.sortNo!=null?rowData.sortNo:"",
                    mainAccountId: editData.cashBankId!=null?editData.cashBankId:"",
                    remark: rowData.remark!=null?rowData.remark:"",
                    creditAmount: rowData.type == "C" ? (+rowData.amt).toFixed(2) : "",
                    debitAmount: rowData.type == "C" ? "" : (+rowData.amt).toFixed(2),
                    ...rowData,
                  };
                }
              );
              console.log(mainAccountName);
              this.setState({
                rowList: rowList,
                fieldData: {
                  ...editData,
                  date:editData.date!=null && editData.date!=""?dayjs(editData.date, "DD-MM-YYYY"):"",
                  subVoucherMaster: { id: editData.subVoucherMaster!=null && editData.subVoucherMaster.id?editData.subVoucherMaster.id:"" },
                  // cashBankId: response.cashBankId,
                  // paidTo: response.paidTo,
                  // voucherAmount: JSON.stringify(amt > 0 ? amt : amt * -1),
                  remark: editData.remark!=null?editData.remark:"",
                },
                voucherAmountShow: amt,
              });
            }
        
    }  
          



  onSearch = (data) => {
    const {
      rowList,
      dynamicMasterData,
      headerData,
      fieldData,
      journalEntryDetailCcInfoList,
    } = this.state;

    let catList = dynamicMasterData["creditAccountDetails"]
      ? dynamicMasterData["creditAccountDetails"].filter(
          (row) => row.id == data.mainAccountId
        )
      : [];
    let amt = 0;
    rowList.map((row) => {
      amt = row.type == "C" ? amt + +row.amt : amt - +row.amt;
    });
    amt = data.type == "C" ? amt + +data.amt : amt - +data.amt;
    console.log(journalEntryDetailCcInfoList);
    if(+data.amt >0){
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          mainAccountCode:
            catList.length != 0 ? catList[0].accountLedgerCode : "-",
          mainAccountName:
            catList.length != 0 ? catList[0].accountLedgerName : "-",
          journalEntryDetailCcInfoList: journalEntryDetailCcInfoList,
          ...data,
          amt:data.amt?(+data.amt).toFixed(2):"",
          type:data.type ? data.type : "",
          creditAmount: data.type == "C" ? (+data.amt).toFixed(2) : "",
          debitAmount: data.type == "C" ? "" : (+data.amt).toFixed(2),
        },
      ],
      fieldData: {
        ...fieldData,
        // voucherAmount: amt > 0 ? (+amt).toFixed(2) : (+amt * -1).toFixed(2),
      },
      // voucherAmountShow: amt,
    });
  }
  };

  onSubmitData = (costMappingList) => {
    const { showNotification } = this.props;
    console.log(costMappingList);
    const location = localStorage.getItem("location");
    console.log(this.state.rowList);
    if (this.checkValidationOnSubmit()) {
      const { rowList, fieldData } = this.state;
      let totalAmt = 0;
      if (rowList.length != 0) {
        let creditAmount = 0;
        let debitAmount = 0;
        const journalEntryDetailList = rowList.map((rowData) => {
          if (rowData.type == "C") {
            creditAmount = creditAmount + +rowData.amt;
          }
          if (rowData.type == "D") {
            debitAmount = debitAmount + -Math.abs(+rowData.amt);
          }
          totalAmt = creditAmount + debitAmount;
          console.log(totalAmt);
          const rowObj = {
            sortNo: 1,
            mainAccountId: rowData.mainAccountId?rowData.mainAccountId:"",
            amt: rowData.amt!=null?rowData.amt:"",
            type: rowData.type!=null?rowData.type:"",
            remark: rowData.remark!=null? rowData.remark:"",
            // voucherAmount: totalAmt > 0 ? totalAmt : totalAmt * -1,
            journalEntryDetailCcInfoList: rowData.journalEntryDetailCcInfoList!=null?rowData.journalEntryDetailCcInfoList:[],
            // recieptPaymentDetailBillInfoList: recieptPaymentDetailBillInfoList,
          };

          return rowObj;
        });
        const dataToSave = {
          ...fieldData,
          id: this.props.editData.id ? this.props.editData.id : "",
          subVoucherMaster: fieldData.subVoucherMaster!=null?fieldData.subVoucherMaster:"",
          // mainAccountId: rowList[0].mainAccountId,
          location: {
            id: location!=null?location:"",
          },
          remark: fieldData.remark!=null?fieldData.remark:"",
          // cashBankId: fieldData.cashBankId,
          // paidTo: fieldData.paidTo,
          journalEntryDetailList: journalEntryDetailList!=null?journalEntryDetailList:[],
          date: fieldData.date!=null && fieldData.date!=""?fieldData.date.format("DD-MM-YYYY"):"",
          status: this.props.approvalFlag == 0 ? 0 : 1,
        };
        console.log(dataToSave);
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            if (totalAmt == 0) {
              this.onSave(dataToSave);
            } else {
              showNotification({
                msg: "Voucher amount not matched.",
                severity: "error",
              });
            }
          }
        });
      } else {
        showNotification({
          msg: "Please add atleast one product",
          severity: "error",
        });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.journalEntryHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (this.props.approvalFlag == 0) {
            
            this.setState({rowList:[],
              fieldData:{}
             })
            
         } else {
         
          this.setState({rowList:[],
            fieldData:{}
           })
          
          }
          showNotification({ msg: savemsg });
          this.props.handleCloses();
        } else {
          showNotification({ msg: data.response.data.errorMessage, severity: "error" });
          this.props.handleCloses();
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    JournalEntryJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck1 = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    JournalEntryJSON.fieldMeta1.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    JournalEntryJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck() && this.mandatoryCheck1()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    JournalEntryJSON.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  rowDelete = (rowData) => {
    console.log(rowData);
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        let finalAmt =
          rowData.type == "C"
            ? +this.state.voucherAmountShow - +rowData.amt
            : +this.state.voucherAmountShow + +rowData.amt;
        this.setState({
          rowList: row,
          fieldData: {
            ...this.state.fieldData,
            // // voucherAmount: 
            //   finalAmt > 0 ? (+finalAmt).toFixed(2) : (+finalAmt * -1).toFixed(2)
            // ,
          },
          // voucherAmountShow: finalAmt,
        });
      }
    });
  };

  rowCopy = (rowData) => {
    const { openCostCenter } = this.state;
    console.log(rowData);
    this.setState({
      openCostCenter: !openCostCenter,
      headerData: rowData,
    });
    console.log(rowData);
  };

  rowTransfer = (rowData) => {
    const { openBillMapping } = this.state;
    this.setState({
      openBillMapping: !openBillMapping,
      headerData: rowData,
    });
    console.log(rowData);
  };

  handleCloseCC = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          journalEntryDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      journalEntryDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  OnSaveCostCenter = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    console.log(headerId);
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          journalEntryDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      journalEntryDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };
  getListData = () => {
    const {
      rowList
    } = this.state;
    let rowListData = ["Total", ""]

    let creditAmount = 0
    let debitAmount = 0
    rowList.map((rowListInfo) => {
      creditAmount = creditAmount + +rowListInfo.creditAmount
      debitAmount = debitAmount + +rowListInfo.debitAmount
      return rowListInfo
    })
    rowListData = [
      ...rowListData,
      creditAmount.toFixed(2),
      debitAmount.toFixed(2),
      ""
    ]
    if (rowList.length != 0)
      return rowListData
    return []
  }
  onSaveBillMapping = (dataToSave) => {
    const { openCostCenter } = this.state;
    console.log(dataToSave);
    this.setState({
      recieptPaymentDetailBillInfoList: dataToSave,
      openBillMapping: !openBillMapping,
    });
  };
  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      openCostCenter,
      headerData,
      formData,
      setFieldDataFromApi,
    } = this.state;
    const {rowReset} =this.props
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicTransactionForm
          subTitle={fieldData.jvNo ? fieldData.jvNo : "-"}
       
            getListById={this.getListById} 
            formData={formData}
            dynamicMasterData={dynamicMasterData}
            screenTitle={JournalEntryJSON.screenTitle}
            fieldMeta={JournalEntryJSON.fieldMeta}
            apiBaseURL={JournalEntryJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={JournalEntryJSON.showSaveBtn}
            showSaveBtnMain={false}
            showReset={true}
            rowReset={rowReset}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
          />
          <br />
          <Divider sx={{ borderWidth: 1, color: "#000000" }} />
          <br />
          <DynamicFormWithoutSave
            showSaveBtn={true}
            showSaveBtnMain={false}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={JournalEntryJSON.searchList}
            showTitle={JournalEntryJSON.showTitle}
            screenTitle={JournalEntryJSON.screenTitle}
            fieldMeta={JournalEntryJSON.fieldMetaDetails}
            showCancel={JournalEntryJSON.showCancel}
            showBackToList={JournalEntryJSON.showBackToList}
            apiBaseURL={JournalEntryJSON.apiBaseURL}
            showSaveNextBtn={JournalEntryJSON.showSaveNextBtn}
            saveBtnText={"ADD"}
            onSave={this.onSearch}
            resetAfterSave={true}
            getDataForDisabled={this.getDataForDisabled}
            setFieldDataFromApi={setFieldDataFromApi}
          />

          <br />
          <RTTable
          totalList={this.getListData()}
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "mainAccountCode", title: "ACCD" },
              { name: "mainAccountName", title: "Account Head" },
              {
                name: "creditAmount",
                title: "Credit Amount",
                align: "right",
              },
              {
                name: "debitAmount",
                title: "Debit Amount",
                align: "right",
              },
              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            isActionColActive={true}
            showEditIcon={false}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
            showCopyIcon={true}
            rowCopy={this.rowCopy}
            showTransferButtonInTable={false}
            rowTransfer={this.rowTransfer}
            iconTitleForCopy="Cost Center"
          />
          <br />
          <DynamicTransactionForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={JournalEntryJSON.screenTitle}
            fieldMeta={JournalEntryJSON.fieldMeta1}
            apiBaseURL={JournalEntryJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={JournalEntryJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showTitle={false}
            getDataForDisabled={this.getDataForDisabled}
          />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name={'Submit'}
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
        {openCostCenter && (
          <CostCenterMappingDialouge
            open={openCostCenter}
            handleCloses={this.handleCloseCC}
            dynamicMasterData={dynamicMasterData}
            onSave={this.OnSaveCostCenter}
            dataToSave={headerData}
          />
        )}
        {/* {openBillMapping && (
          <BillMappingDialouge
            open={openBillMapping}
            handleCloses={this.rowTransfer}
            dynamicMasterData={dynamicMasterData}
            onSave={this.onSaveBillMapping}
            dataToSave={headerData}
          />
        )} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  subVoucherList: state.subVoucher,
  journalVoucherList: state.journalVoucher,
  cashRecieptList: state.cashReciept,
  profitCenter: state.profitCenter
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getAccountLedgerListByType,
  getAccountLedgerListByType1,
  getSubVoucherByType,
  getJVWithAccNameById,
  getCostCenter,
  getLedgerDataByAccId,
  getProfitCenter,
  //   getProductByCategoryForStakeHolder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(JournalEntryFormForReport);
