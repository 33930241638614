import React from "react";
import { connect } from "react-redux";
import { AccountLedgerColumns } from "../../../tableColumns/table-columns";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import { getAccountLedgerList } from "../../../Slice/accountLedger.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";

class AccountLedgerTable extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { accountLedgerList, getAccountLedgerList } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={AccountLedgerJson.formPath}
                    screenTitle={AccountLedgerJson.screenTitle}
                    openContactPath={AccountLedgerJson.openContactPath}
                    openBankPath={AccountLedgerJson.openBankPath}
                    showPdfDownload={false}
                    showContactInfo={AccountLedgerJson.showContactInfo}
                    showBankInfo={true}
                    showExcelDownload={true}
                    showAddButton={true}
                    tableColumnsToFilter={AccountLedgerJson.tableColumnsToFilter}
                    tableColumns={AccountLedgerColumns}
                    tableData={accountLedgerList?.accountLedger}
                    getTableData={getAccountLedgerList}
                    apiBaseURL={AccountLedgerJson.apiBaseURL}
                    baseIdColumn={AccountLedgerJson.baseIdColumn}
                    showPegination={false}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    accountLedgerList: state.accountLedger,
});
const mapDispatchToProps = {
    getAccountLedgerList,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountLedgerTable);

