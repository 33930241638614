import endpoint from "../../config/endpoints";

export const billBookJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  formPath: "/settle-bill",
  formPathView: "/payment-list",
  screenTitle: "Bill Book",
  showTitle: true,
  showCancel: false,
  showBackToList: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Vendor",
      controlType: "autocomplete",
      placeHolder: "Select Vendor",
      md:4,
      lg:4,
      sm:4,
      xs: 12,
      masterName: "VendorDetails",
      dataKey: "vendor",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "entryType,accountLedgerCode",
    },
  ],
};
