
import dayjs from "dayjs";

export const costCenterJson = {
    screenTitle: "Cost center",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta: [
        {
            label: "",
            controlType: "radiogroup",
            md: 3.5,
            lg: 3.5,
            sm: 3.5,
            labelSM: 1,
            labelMD: 1,
            labelLG: 1,
            valueSM:11,
            valueMD:11,
            valueLG:11,
            xs: 12,
            dataKey: "type",
            defaultValue: "0",
            radioGroupItems: [
              {
                label: "Cost Center wise",
                value: "0",
              },
              {
                label: "Account wise",
                value: "1",
              },
            ],
            isMandatory: true,
          },
          {
            label: "Date Range",
            controlType: "datepicker",
            placeHolder: "",
            md:3.5,
            lg:3.5,
            sm:3.5,
            xs: 12,
            labelSM: 6,
            labelMD: 6,
            labelLG: 6,
            valueSM: 6,
            valueMD: 6,
            valueLG: 6,
            showLabel:true,
            error:"From Date",
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-le",
          },
          {
            label: "toDate",
            controlType: "datepicker",
            placeHolder: "",
            md: 2,
            lg: 2,
            sm: 2,
            xs: 12,
            hideLabel:true,
            labelSM: 1,
            labelMD: 1,
            labelLG: 1,
            valueSM: 11,
            valueMD: 11,
            valueLG: 11,
            dataKey: "toDate",
            onSubmit: "compare",
            error:"To Date",
            onSubmitParameter: "fromDate-ge",
            isMandatory: true,
            showLabel:true,
            onSubmit: "compare",
            defaultValue: dayjs(new Date()),
          },
    ]
  };
  