import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { saveAccountLedger, setSaveAccountLedger, getGstType } from "../../../Slice/accountLedger.slice";
import { noInternetMsg, saveFailedMsg, saveWarningMsg } from "../../../config/messageconstant";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import * as msgConstant from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";



class StatutoryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                // locationDetail: this.props.locationList?.location,
                gstTypeDetail: this.props.dropDownList?.gstType,

            },
            formErrors:{},
        };
    }
    componentWillReceiveProps(nextProps) {
        // if (this.props.locationList !== nextProps.locationList) {
        //     if (this.props.locationList?.location !== nextProps.locationList?.location) {
        //         this.setState({
        //             dynamicMasterData: {
        //                 ...this.state.dynamicMasterData,
        //                 locationDetail: nextProps.locationList?.location,
        //             },
        //         });
        //     }
        // }
        if (this.props.dropDownList !== nextProps.dropDownList) {
            if (
                this.props.dropDownList?.gstType !== nextProps.dropDownList?.gstType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        gstTypeDetail: nextProps.dropDownList?.gstType,
                    },
                });
            }
        }
    }
    async componentDidMount() {
        const { getLocation, getGstType, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            // showLoador({ loador: true });
            // await getLocation().then(({ success }) => {
            //     showLoador({ loador: false });
            //     if (!success) {
            //         showNotification({ msg: serverMsg, severity: "error" });
            //     }
            // });
            showLoador({ loador: true });
            await getGstType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            })
        }
        else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    onSubmitData = (dataToSave) => {
        const { fieldData } = this.state;
        const { accountLedger } = this.props;
      

       
        dataToSave = {
            ...accountLedger.accountLedgerData,
            ...dataToSave
        };
        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onSave(dataToSave);
            }
        });
   
    };

    onSave = (dataToSave) => {
        const { showLoador, showNotification, saveAccountLedger } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            saveAccountLedger({ dataToSave }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (success) {
                    this.props.changeTab(3)
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    getDataById = (id) => {
        const { showLoador, setSaveAccountLedger, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: endpoint.accountLedger + "/" + id,
            }).then(({ data, success }) => {
                console.log(data.data);
                showLoador({ loador: false });
                if (success) {

                    if (!data.error) {

                        let rowData = data.data
                        console.log(rowData)
                        setSaveAccountLedger({ rowObject: rowData });

                        return { data: rowData, success }
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { data: {}, success: false }
                }
                return { response: {}, success: false }
            });
            return response
        }
        else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    render() {
        const { dynamicMasterData,formErrors } = this.state;
        const {accountLedger} =this.props
        return (
            <>
                <DynamicFormWithoutSave
                   getByIdDataList={accountLedger.accountLedgerData}
                    formPath={AccountLedgerJson.formPath}
                    fieldMeta={AccountLedgerJson.fieldMetaForStatutory}
                    tableColumnsToFilter={AccountLedgerJson.tableColumnsToFilter}
                    apiBaseURL={AccountLedgerJson.apiBaseURL}
                    baseIdColumn={AccountLedgerJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                    showSaveBtnMain={false}
                    showSaveBtn={true}
                    saveBtnText={"SAVE & PROCEED"}
                    onSave={this.onSubmitData}
                    callApi={true}
                    getByIdApi={true}
                    getDataById={this.getDataById}
                   
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    accountLedger: state.accountLedger,
    dropDownList: state.accountLedger,
});
const mapDispatchToProps = {
    showLoador,
    saveAccountLedger,
    showNotification,
    setSaveAccountLedger,
    getGstType,
};
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StatutoryForm);
