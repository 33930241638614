export const validPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const hasNumber = /\d/;
export const onlyNumber = /^[0-9\b]+$/;
// export const validEmail = /^[a-z0-9](\.?[a-z0-9]){5,}@gmail\.com$/i;
export const validEmail = /^[a-zA-Z0-9._%+-]+@gmail.com$/i;
export const validAdhaar = /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/;
export const textOnly = /^[A-Za-z]+$/;
export const textOnlyWithSpaces = /^[a-zA-Z\s]*$/;
export const numberWithDot = /^[0-9]+\.?[0-9]*$/;
export const phoneNo = /^\d{10}$/;
export const panNo = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const onlyFloatNumber = /^[-+]?[0-9]+(\.[0-9]+)*$/;
export const gstNo =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
export const validText = /^[a-zA-Z]/;
export const validAll = /^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$/;
export const validPoint = /\b([1-9]|10)\b /;
export const validTextNo = /^[a-zA-Z0-9]/;
export const onlyFloatNumberPlus = /^[0-9]*([.,][0-9]+)?$/
