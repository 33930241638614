import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import AutoComplete from "../../components/Comman/AutoComplete";

import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { onlyFloatNumberPlus } from "../../components/Comman/Util/Validations";
import { NoLuggageOutlined } from "@mui/icons-material";

class CreditNoteTable extends React.Component {
 
 
  onTextFieldDataChange = (event, row) => {
    const { name,value } = event.target
    if (!onlyFloatNumberPlus.test(value)) {
      return
    }
    let productDataListNew =[]
    let filterData = this.props.rows.map((rowinfo, MainIndex) => {
      if (this.props.tabValue == MainIndex) {
        let productDataList = rowinfo.productListForOrder.map(
          (productInfo, productIndex) => {
            if (row.id == productInfo.id) {
            
                  productInfo = {
                    ...productInfo,
                    [name]: value
                  }
                  
                  productInfo = {
                    ...productInfo,
                    amount:( +productInfo.productRate *+productInfo.qty).toFixed(2)
                  } 
            }
            return productInfo
          }
        )

        if (this.props.tabValue == MainIndex) {
          productDataListNew=productDataList
         
        }
        return {
          ...rowinfo,
          productListForOrder: productDataList
        }
      }
      return rowinfo
    })

    this.props.setStockConsumptionList({rows:filterData,
      productDataList:productDataListNew})
  }
  render() {
    const { productDataList ,taxList=[]} = this.props;

    return (
      <>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align={"center"} sx={{ width: "5%" }}>
                  Sr. No.
                </TableCell>
                <TableCell align="center" sx={{ width: "30%" }}>
                  Product Name
                </TableCell>
                <TableCell align="center" sx={{ width: "20%" }}>
                  UOM
                </TableCell>
                <TableCell align="center" sx={{ width: "10%" }}>
                Rate
                </TableCell>
              
              
                <TableCell align="center" >
                  Quantity
                </TableCell>
                <TableCell align="center" sx={{ width: "10%" }}>
                  Total
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {productDataList.map((matrixDataRow, indexRow) => {
               console.log(matrixDataRow.qty)
               return (
                  <TableRow>
                    <TableCell align="center">{indexRow+1}</TableCell>
                    <TableCell>{matrixDataRow.name}</TableCell>
                    <TableCell>{matrixDataRow.storeUomName}</TableCell>
                    <TableCell align="right"><TextFieldCompo
                        placeHolder={"Rate"}
                        size="small"
                        color="primary"
                        name={"productRate"}
                        id={"productRate"}
                        value={matrixDataRow.productRate}
                        onChange={(event) =>
                          this.onTextFieldDataChange(event, matrixDataRow,'productRate')
                        }
                        fullWidth
                        errorText={matrixDataRow.productRateErr}
                      /> </TableCell>
                   <TableCell align="right">
                      
                      <TextFieldCompo
                        placeHolder={"Quantity"}
                        size="small"
                        color="primary"
                        name={"qty"}
                        id={"qty"}
                        value={matrixDataRow.qty}
                        onChange={(event) =>
                          this.onTextFieldDataChange(event, matrixDataRow,'qty')
                        }
                        fullWidth
                        errorText={matrixDataRow.qtyErr}
                      />
                    </TableCell>
                    <TableCell align="right">{matrixDataRow.amount?matrixDataRow.amount:0}</TableCell>
                 
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default CreditNoteTable;
