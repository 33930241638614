export const changeActive = "Do you want to change status?";
export const sendOrder = "Do you want to send order?";
export const serverMsg = "Something went wrong";
export const imageMsg = "Image not uploaded";
export const savemsg = "Save Successfully";
export const updateMsg = "Data updated Successfully";
export const saveFailedMsg = "Save Failed";
export const deletemsg = "Delete Successfully";
export const rejectMsg = "Order Rejected Successfully";
export const acceptMsg = "Order Accepted Successfully";
export const statusmsg = "Status updated";
export const statusFailedMsg = "Failed to change status";
export const deleteFailedMsg = "Delete Failed";
export const dobEmptyMsg = "Enter date of birth";
export const userNameEmptyMsg = "Enter username";
export const enterPasswordMsg = "Enter password";
export const confirmPasswordIncorrectMsg = "*Confirm Password does not match";
export const confirmPasswordMsg = "Confirm Password required";
export const otpEmptyMsg = "Enter OTP";
export const otpFormatMsg = "OTP should be 6 digits only";
export const accessRoleMsg = "*Access role name required";
export const shortNameMsg = "*Short name required";
export const stackHolderTypeMsg = "*Stack Holder Type required";
export const questionMsg = "*Question required";
export const sortOrderNoMsg = "*Sort order no required";
export const optionType = "*Select option type";
export const pointsMsg = "*Select points";
export const option = "*Option required";
export const dateMsg = "*Date required";
export const noInternetMsg = "Please check internet connection";
export const saveWarningMsg = "Do you want to save this record?";
export const deleteWarningMsg = "Do you want to delete this record?";
export const saveAndCloseWarningMsg =
  "Do you want to save and close this record?";
  export const errorMsg = "Required";
export const debitAmountMsg = "*Amount required";
export const checkedListEmptyMsg = "*Select atleast one record";
export const debitAmountNotValidMsg =
  "*Amount should be less or equal to pending amount";
export const debitAmountZeroMsg = "*Amount should be greater than 0";
export const transactionDateMsg = "*Transaction date required";
export const feesStructureHeaderMsg = "*Fees structure required";
export const feesMinimumPayMsg =
  "*Amount should be greater than minimum paid amount";
export const feesMaximumPayMsg = "*Amount should be less than pending amount";
export const feesEqualPayMsg = "*Amount should be equal to pending amount";
export const numberMsg = "*Transaction id required";
export const bankNameMsg = "*Bank name required";
export const bankBranchMsg = "*Bank branch required";
export const ifscCodeMsg = "*IFSC code required";
export const micrCodeMsg = "*MICR code required";
export const sendCredentialMsg = "Do yo like to send credential to user";
export const generateCredentialMsg = "Credential generated successfully";
export const receiveBillSuccess = "Bill received successfully";
export const FailTogenerateCredentialMsg = "Failed to generate credential";
export const modeOfPayMsg = "*Mode of Pay required";
export const deleteConfirmMsg =
  "Once deleted, Information can not be Retrieved. Would you like to continue?";
export const dateExistMsg = "Rate configration already added for this date";
export const beatNotAssignMsg = "Beats not assigned for this sales executive";
export const beatSelectMsg = "Select atleast one beat";
export const cancelledMsg = "Bill Cancelled Successfully";
export const atleastOne = "Please add atlest one product";
export const updateData = "Do you want to update this record?";
export const enterValueMsg = "Please add some data";
export const yearEndMsg = "Year end successfully";
export const outletInactiveMsg = "Do you want to in-active the outlets?";
export const nameMsg = "Please Enter Name";
export const contactNoMsg = "Enter Contact Number";
export const emailIdMsg = "Enter Email ID";
export const addressMsg = "Enter Address";
export const gstNumberMsg = "Enter GST Number";
export const panNumberMsg = "Enter PAN Number";
export const cinNumberMsg = "Enter CIN Number";
export const fdiFssiNumberMsg = "Enter FDI FSSI Number";
export const descriptionMsg = "Enter Description";
export const fromdateMsg = "From Date Is Required";
export const todateMsg = "To Date Is Required";
export const levelwiseTypeMsg = "Level-Wise Type Is Required";
export const applicableAmountMsg = "Applicable Amount Is Required";
export const discountAmountMsg = "Discount Amount Is Required";
export const discountPercentageMsg = "Discount Percentage Is Required";
export const percentageMsg = "Percentage Is Required";
export const productMsg = "Please Select Product";
export const batchMsg = "Please Select Batch";
export const productByCategoryMsg = "Please Select Product";
export const qtyMsg = "Quantity Is Required";
export const qtyForItemMsg = "Quantity Is Required";
export const discountPercentageErrorMsg =
  "Discount Percentage Should Not Be Greater Than 100 %";

export const ReportMsg = "*Report required";
export const apiNameMsg = "*API Name required";
export const paramsValueMsg = "*Parameter Value required";
export const paramsKeyMsg = "*Parameter Key required";
export const reportTypeMsg = "*Report Type Required";

export const controlTypeMsg = "*Control Type required";
export const labelMsg = "*Label required";
export const placeHolderMsg = "*Placeholder required";
export const fieldNameMsg = "*Field Name required";
export const fieldNameUniqueMsg = "*Field Name should be unique";

export const pojoFieldNameMsg = "*Pojo Field Name required";

export const keyMsg = "*Key required";
export const urlMsg = "*URL required";
export const localListMsg = "Please add atlest one object";
export const columnNameMsg = "*Column Name required";
export const widthMsg = "*Width required";
export const sortNoMsg = "*Sort number required";
export const valueMsg = "*Value required";
export const graphTypeMsg = "*Graph Type required";
export const sortOrderMsg = "*Sort Order required";
export const actionMsg = "*Action required";
export const parameterKeyMsg = "*Parameter key required";
export const parameterValueMsg = "*Parameter value required";
