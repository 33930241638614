import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { B2CLJson } from "../../DynamicFormsJson/Report/B2CL";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import B2CLTable from "./B2CLTable";

const B2CL = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [cashbook, setCashbookList] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [reportData, setReportData] = React.useState({})
  useEffect(() => {
    // getList = ("01-11-2023", "30-11-2023");
  }, [])
  const getList = (fromDate, toDate) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.b2cl +
        "?fromDate=" + fromDate + "&toDate=" + toDate+"&locationId="+localStorage.getItem("location")+'&type=1',
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        console.log(data.data)
        setCashbookList(data.data )

      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  }

  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };
  

  const getFilteredTableData = (tableData) => {

    let listData = []
    tableData.map((row) => {
      const subList = row["transactionDetail"].filter((currentRow) => {
        let isValid = false;

        if (
          currentRow["refAccName"] &&
          currentRow["refAccName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        return isValid;
      });
      if (subList.length != 0) {
        row = {
          ...row,
          ["transactionDetail"]: subList,
        };
        listData.push(row);
      }
    });
    return listData
  };
  const generateExcelMethod = () => {
    
    var columns = ["Invoice Number", "Invoice date","Invoice Value","Place Of Supply", "Rate", "Taxable Value", "Cess Amount"];
    let alignList = [];
    let tableData = []
    let rows = []
    let totalAmount=0
    let taxPercentage = 0;
    let taxableAmount = 0;
    let cessAmount = 0;
    cashbook.map((data, index) => {
         tableData = []
         totalAmount = totalAmount + data.totalAmount
         taxPercentage = taxPercentage + data.taxPercentage
         taxableAmount = taxableAmount + data.taxableAmount
         cessAmount = cessAmount + data.cessAmount
        tableData.push(data.documentNo)
        tableData.push(data.documentDate)
        tableData.push(Math.abs(data.totalAmount).toFixed(2))
        tableData.push(data.placeofSupply)
        tableData.push(Math.abs(data.taxPercentage).toFixed(2))
        tableData.push(Math.abs(data.taxableAmount).toFixed(2))
        tableData.push(Math.abs(data.cessAmount).toFixed(2))
        rows.push(tableData)
      })
      tableData = []
      
     tableData.push('Total')
     tableData.push('')
     tableData.push(Math.abs(totalAmount).toFixed(2))
     tableData.push('')
     tableData.push(Math.abs(taxPercentage).toFixed(2))
     tableData.push(Math.abs(taxableAmount).toFixed(2))
     tableData.push(Math.abs(cessAmount).toFixed(2))
     rows.push(tableData)

    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate

      ],
      title: B2CLJson.screenTitle,
    });

  };
  const onSave = (dataToSearch) => {

    setFormData({
      ...dataToSearch
    })
    getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId)
  }
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

        <DynamicFormWithoutSave
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={B2CLJson.showSaveBtn}
          screenTitle={B2CLJson.screenTitle}
          fieldMeta={B2CLJson.fieldMeta}
          showCancel={B2CLJson.showCancel}
          apiBaseURL={B2CLJson.apiBaseURL}
          searchName=""
          generateExcel={generateExcelMethod}
          showSaveNextBtn={B2CLJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          onSearch={onSearch}
          showSearchBox={false}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br/>
        <B2CLTable  tableHead={[
          {
            title: "Invoice Number ",
            align: "left",
            
          },
          {
            title: "Invoice date",
            align: "right",
            width: "12%"
          },
          {
            title: "Invoice Value",
            align: "right",
            width: "12%"
          },
          {
            title: "Place Of Supply",
            align: "right",
            width: "12%"
          },
          
          {
            title: "Rate",
            align: "right",
            width: "12%"
          },
          {
            title: "Taxable Value",
            align: "right",
            width: "12%"
          },
          {
            title: "Cess Amount",
            align: "right",
            width: "12%"
          }
        ]} rowList={cashbook} />

      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(B2CL);
