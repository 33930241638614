import {
  Paper,
} from "@mui/material";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutValidation";
import endpoint from "../../../config/endpoints";
import {
  errorMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  serverMsg
} from "../../../config/messageconstant";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import {
  getAccountNature,
  getDeductType,
  getGstType,
  getSubTaxTypeByTaxType,
  getTaxType,
  saveAccountLedger,
  setSaveAccountLedger,
} from "../../../Slice/accountLedger.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

import * as msgConstant from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";
class TaxInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fieldData: {},
      dynamicMasterData: {
        deductTypeDetail: this.props.dropDownList?.deductType,
        accountNatureDetail: this.props.dropDownList?.accountNature,
        gstTypeDetail: this.props.dropDownList?.gstType,
        typeOfTaxesDetail: this.props.dropDownList?.taxType,
        typeOfSubTaxesDetail: this.props.dropDownList?.subTaxTypeByTaxType,
      },
    }
  };


  handleChange = (event) => {
    this.props.setBillData({ row: [] });
    this.setState({
      value: event.target.value,
      formErrors: {},
      fieldData: {},
      DataToSet: [
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Remaining Amount ",
          value: 0,
        },
      ],
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.dropDownList !== nextProps.dropDownList) {
      if (
        this.props.dropDownList?.deductType !==
        nextProps.dropDownList?.deductType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            deductTypeDetail: nextProps.dropDownList?.deductType,
          },
        });
      }
    }
    if (this.props.dropDownList !== nextProps.dropDownList) {
      if (
        this.props.dropDownList?.accountNature !==
        nextProps.dropDownList?.accountNature
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            accountNatureDetail: nextProps.dropDownList?.accountNature,
          },
        });
      }
    }
    if (this.props.dropDownList !== nextProps.dropDownList) {
      if (
        this.props.dropDownList?.gstType !== nextProps.dropDownList?.gstType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            gstTypeDetail: nextProps.dropDownList?.gstType,
          },
        });
      }
    }
    if (this.props.dropDownList !== nextProps.dropDownList) {
      if (
        this.props.dropDownList?.taxType !== nextProps.dropDownList?.taxType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            typeOfTaxesDetail: nextProps.dropDownList?.taxType,
          },
        });
      }
    }
    if (this.props.dropDownList !== nextProps.dropDownList) {
      if (
        this.props.dropDownList?.subTaxTypeByTaxType !==
        nextProps.dropDownList?.subTaxTypeByTaxType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            typeOfSubTaxesDetail: nextProps.dropDownList?.subTaxTypeByTaxType,
          },
        });
      }
    }

  }

  getListById = async (data) => {
    const {
      getSubTaxTypeByTaxType,

      showLoador,
      showNotification,
    } = this.props;
    const { fieldData } = this.state;
    console.log(fieldData);
    console.log(data);

    if (navigator.onLine) {

      if (data.taxType) {
        showLoador({ loador: true });
        await getSubTaxTypeByTaxType({ taxTypeId: data.taxType.id }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  getData = async (outletId) => {
    const {
      showLoador,
      showNotification,
      getPaymentReceiptTransaction,
    } = this.props;
    if (navigator.onLine) {
      if (outletId) {
        showLoador({ loador: true });
        await getPaymentReceiptTransaction({
          outletId: outletId,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  async componentDidMount() {
    const {
      getDeductType,
      getAccountNature,
      getGstType,
      getTaxType,
      accountLedger,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(accountLedger.accountLedgerData)
      this.setState({
        fieldData: {
          ...accountLedger.accountLedgerData,
          tdsApplicable: accountLedger.accountLedgerData.tdsApplicable.toString(),
          creditDays: accountLedger.accountLedgerData.creditDays.toString(),
          tcsApplicable: accountLedger.accountLedgerData.tcsApplicable.toString(),
          alertDays: accountLedger.accountLedgerData.alertDays.toString(),
          creditLimit: accountLedger.accountLedgerData.creditLimit.toString(),
          isTaxRelatedAccount: accountLedger.accountLedgerData.isTaxRelatedAccount.toString(),
          isHold: accountLedger.accountLedgerData.isHold.toString(),
        },
      });
      if (accountLedger.accountLedgerData.taxType != null) {
        this.getListById(accountLedger.accountLedgerData)
      }
      showLoador({ loador: true });
      await getDeductType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getAccountNature().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getGstType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getTaxType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  checkValidationOnSubmit = () => {
    const { fieldData, value } = this.state;
    console.log(fieldData)
    const metaData =

      AccountLedgerJson.fieldMetaForTaxInformation

    let error = true;
    let formErrors = {};
    if (fieldData.tdsApplicable == null || fieldData.tdsApplicable == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["tdsApplicable"]: {
          isError: true,
          errorText: "TDS Applicable " + errorMsg
        }
      };
    }
    if (fieldData.tdsApplicable != null && fieldData.tdsApplicable == 1) {
      if (fieldData.deductyType == null || fieldData.deductyType == undefined) {
        error = false;
        formErrors = {
          ...formErrors,
          ["deductyType"]: {
            isError: true,
            errorText: "deduct Type " + errorMsg
          }
        };
      }
      if (fieldData.tdsAccountNature == null || fieldData.tdsAccountNature == undefined) {
        error = false;

        formErrors = {
          ...formErrors,
          ["tdsAccountNature"]: {
            isError: true,
            errorText: "tds Account Nature " + errorMsg
          }
        };
      }
      if (fieldData.tdsAutoDeduct == null || fieldData.tdsAutoDeduct == undefined) {
        error = false;
        formErrors = {
          ...formErrors,
          ["tdsAutoDeduct"]: {
            isError: true,
            errorText: "tds Auto Deduct " + errorMsg
          }
        };
      }
    }

    if (fieldData.tcsApplicable == null || fieldData.tcsApplicable == undefined) {
      error = false;
      formErrors = {
        ...formErrors,
        ["tcsApplicable"]: {
          isError: true,
          errorText: "tcs Applicable " + errorMsg
        }
      };
    }

    if (fieldData.isTaxRelatedAccount
      == null || fieldData.isTaxRelatedAccount
      == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["isTaxRelatedAccount"]: {
          isError: true,
          errorText: "Is Tax Related Account " + errorMsg
        }
      };
    }
    if (fieldData.isTaxRelatedAccount != null && fieldData.isTaxRelatedAccount == 1) {
      if (fieldData.taxType == null || fieldData.taxType == undefined) {
        error = false;

        formErrors = {
          ...formErrors,
          ["taxType"]: {
            isError: true,
            errorText: "Tax Type " + errorMsg
          }
        };
      }
      if (fieldData.subTaxType == null || fieldData.subTaxType == undefined) {
        error = false;
        formErrors = {
          ...formErrors,
          ["subTaxType"]: {
            isError: true,
            errorText: "Sub Tax Type " + errorMsg
          }
        };
      }

    }
    if (fieldData.isHold
      == null || fieldData.isHold
      == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["isHold"]: {
          isError: true,
          errorText: "Is Hold " + errorMsg
        }
      };
    }
    
    console.log("formErrors")
    console.log(formErrors)
    this.setState({
      formErrors: formErrors,
    });

    return error;
  }

  onSave = async (dataToSave) => {
    const { showLoador, showNotification, saveAccountLedger } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      saveAccountLedger({ dataToSave }).then(({ response, success }) => {

        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg, severity: "success"
          });
          if (dataToSave.accountLevel == 1) {
            this.props.changeTab("4");
          } else {
            this.props.callApi()
          }
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSubmit = (dataToSave) => {

    if (this.checkValidationOnSubmit()) {
      // "tdsApplicable":"true",
      // "tdsAccountNature":{
      //         "id":"10b44d5c-9e42-486a-ba32-1e18c4d2fe27"
      // },
      // "tdsAutoDeduct":"true",
      // "deductyType":{
      //         "id":"fe959054-c1d0-4f0d-8def-3817ebccd5b6"
      // },
      // "tcsApplicable":"true",
      // "billWiseBalance":"NA",
      // "creditDays":"20",
      // "alertDays":"2",
      // "creditLimit":"32",
      // "creditBills":"NA",
      // "gstType":{
      //         "id":"0b1ae02b-87fc-4e55-a1b4-34e432b650d4"
      // },
      // "pinCode":"P101",
      // "distance":"32",
      // "adharNo":"A3326564877921",
      // "companyVendorCode":"V101",
      // "isActive":"true",
      // "isHold":"true",
      // "thirdPartyLinkCode":"Na",
      // "isTaxRelatedAccount":"1",
      // "taxType":{
      //         "id":"881b0a55-5c9e-4546-9375-4e97a0db8eae"
      // },
      // "subTaxType":{
      //         "id":"10375ef2-24d1-4897-93a0-f052865187bd"
      // },
      // "insertedUserId":"I101"

      const { fieldData } = this.state;
      console.log(fieldData)
      const { accountLedger } = this.props;
      dataToSave = {
        ...accountLedger.accountLedgerData,
        tdsApplicable: fieldData.tdsApplicable,
        tdsAccountNature:
          fieldData.tdsAccountNature,
        isTaxRelatedAccount: fieldData.isTaxRelatedAccount,
        tdsAutoDeduct: 1,
        deductyType:
          fieldData.deductyType,

        tcsApplicable: fieldData.tcsApplicable,
        creditDays: fieldData.creditDays,
        alertDays: fieldData.alertDays,
        creditLimit: fieldData.creditLimit,
        creditBills: fieldData.creditBills,
        gstType:
          fieldData.gstType,

        isHold: fieldData.isHold,
        taxType:
          fieldData.taxType,

        subTaxType:
          fieldData.subTaxType,

      };
      console.log("dataToSave")
      console.log(dataToSave.accountLevel)
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
        }
      });
    }

  }


  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    const metaData = AccountLedgerJson.fieldMetaForTaxInformation;

    metaData.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataById = async (id) => {
    const {
      showLoador,
      setSaveAccountLedger,
      showNotification,
      getDeductType,
      getAccountNature,
      getGstType,
      getTaxType,
      getSubTaxType,
    } = this.props;
    const { fieldData } = this.state;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getDeductType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getAccountNature().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getGstType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getTaxType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      const response = apiGet({
        url: endpoint.accountLedger + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;
            rowData = {
              ...rowData,
              accType: rowData.accType.toString(),
              deductyType:
                rowData.deductyType != null ? rowData.deductyType : "",
              gstType: rowData.gstType != null ? rowData.gstType : "",
              subTaxType: rowData.subTaxType != null ? rowData.subTaxType : "",
              taxType: rowData.taxType != null ? rowData.taxType : "",
              tdsAccountNature:
                rowData.tdsAccountNature != null
                  ? rowData.tdsAccountNature
                  : "",
            };
            setSaveAccountLedger({ rowObject: rowData });
            this.setState({
              fieldData: rowData,
            });
            return { data: rowData, success };
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
          return { data: {}, success: false };
        }
        return { response: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData, fieldData, formErrors } = this.state;
    const { accountLedger } = this.props
    return (
      <>
        <Paper
          sx={{

          }}
          elevation={0}
        >


          <DynamicFormWithoutSave
            getByIdDataList={accountLedger.accountLedgerData}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={AccountLedgerJson.searchList}
            // showSaveBtn={AccountLedgerJson.showSaveBtn}
            showTitle={false}
            DataToSet={[]}
            screenTitle={AccountLedgerJson.screenTitle}
            fieldMeta={AccountLedgerJson.fieldMetaForTaxInformation}
            showCancel={AccountLedgerJson.showCancel}
            apiBaseURL={AccountLedgerJson.apiBaseURL}
            showSaveNextBtn={AccountLedgerJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={ Object.keys(accountLedger.accountLedgerData).length !==
              0 && accountLedger.accountLedgerData.accountLevel != 0?"Save And Next":"Submit"}
            onFormSave={this.onSubmit}
            fieldData={fieldData}
            formErrors={formErrors}
            onDataChange={this.onDataChange}
            getDataById={this.getDataById}
            baseIdColumn={AccountLedgerJson.baseIdColumn}
            callApi={true}
            getByIdApi={true}
          />
          {/* <DynamicDetails /> */}




        </Paper>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dropDownList: state.accountLedger,
  accountLedger: state.accountLedger,
});
const mapDispatchToProps = {
  getDeductType,
  getAccountNature,
  getGstType,
  getTaxType,
  getSubTaxTypeByTaxType,
  showLoador,
  showNotification,
  saveAccountLedger,
  setSaveAccountLedger,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TaxInformationForm);
