import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";

const initialState = {
  ledger: {},
};
let url = endpoints.accountLedgerTransaction;
const ledgerSlice = createSlice({
  name: "ledgerSlice",
  initialState,
  reducers: {
    ledgerListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        ledger: row,
      };
    },
  },
});

export const { ledgerListSuccess } = ledgerSlice.actions;

export default ledgerSlice.reducer;

export const getLedgerDataByAccId =
  ({ fromDate, toDate, companyIds, accId}) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        // url:url+ "?companyIds=f8fa0540-5397-11ee-8c99-0242ac120002&fromDate=1-11-2023&toDate=1-11-2023&accIds=effa1205-be32-4a45-947f-c0a7eb58aa11"
        url:url+ "?companyIds=" + companyIds + "&fromDate=" + fromDate + "&toDate=" + toDate + "&accId=" + accId+"&locationIds=" + localStorage.getItem("location"),
      }).then(({ data, success }) => {
        if (success) {
          let ledgerDetails = data.data;
     
          dispatch(ledgerListSuccess({ row: ledgerDetails }));
          return { response: ledgerDetails, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
