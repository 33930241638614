import endpoint from "../../config/endpoints";

export const AccountLedgerJson = {
  formPath: "/account-ledger",
  apiBaseURL: endpoint.accountLedger,
  screenTitle: "Account Ledger Master",
  openContactPath: "/account-contact-information",
  openBankPath: "/account-bank-information",
  showAddButton: true,
  showContactInfo: true,
  showPdfDownload: true,
  showExcelDownload: true,
  tableColumnsToFilter: [
    {
      columnDisplayName: "Account Code",
      columnKeyName: "accountLedgerCode",
      isChecked: true,
    },

    {
      columnDisplayName: "Account Ledger Name",
      columnKeyName: "accountLedgerName",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Account Ledger Code",
      controlType: "textfield",
      placeHolder: "Account Ledger Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "accountLedgerCode",
      isMandatory: true,
      inputType:'numberText'
      // uniqueValidation: true,
    },
    {
      label: "Account Ledger Name",
      controlType: "textfield",
      placeHolder: "Account Ledger Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "accountLedgerName",
      isMandatory: true,
      getData: true,
    },

    {
      label: "Location",
      controlType: "multiSelect",
      placeHolder: "Select Location",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2.5,
        labelMD: 2.5,
        labelLG: 2.5,
        valueSM: 9.5,
        valueMD: 9.5,
        valueLG: 9.5,
      masterName: "locationDetail",
      dataKey: "locationIds",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Print Name",
      controlType: "textfield",
      placeHolder: "Print Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "printName",
      fromData: "accountLedgerName",
      isMandatory: true,
    },
    {
      label: "Account ledeger Group",
      controlType: "autocomplete",
      placeHolder: "Select Account ledeger Group",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "accountLedgerGroupMasterDetail",
      dataKey: "accountLedgerGroupsMaster",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "A/C Type",
      controlType: "autocomplete",
      placeHolder: "Select A/C Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "accTypeMasterDetail",
      dataKey: "accType",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Account Level",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "accountLevel",
      tableDataKey: "accountLevel",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Plant Level",
          value: "0",
        },
        {
          label: "Corporate Level",
          value: "1",
        },
      ],
      isMandatory: true,
    },

    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },

    // {
    //   label: "Address",
    //   controlType: "textfield",
    //   placeHolder: "Address",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "address",
    //   isMandatory: false,
    // },
    // {
    //   label: "City",
    //   controlType: "textfield",
    //   placeHolder: "City",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "city",
    //   isMandatory: false,
    // },
    // {
    //   label: "Contact No.",
    //   controlType: "textfield",
    //   placeHolder: "Contact No.",
    //   inputType: "number",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "contactNo",
    //   maxLength: 10,
    //   valid: true,
    //   validName: "phoneNo",
    //   isMandatory: false,
    // },
    // {
    //   label: "Email",
    //   controlType: "textfield",
    //   placeHolder: "Email",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "mail",
    //   // valid: true,
    //   validName: "email",
    //   isMandatory: false,
    // },
    // {
    //   label: "Pin Code",
    //   controlType: "textfield",
    //   placeHolder: "Pin Code",
    //   inputType: "number",
    //   inputDataType: "number",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   maxLength: "6",
    //   dataKey: "pinCode",
    //   isMandatory: false,
    // },
    // {
    //   label: "Distance (km)",
    //   controlType: "textfield",
    //   placeHolder: "Distance",
    //   inputType: "number",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "distance",
    //   isMandatory: false,
    // },
  ],
  fieldMetaForAdditionalInfo: [
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Address",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "address",
      isMandatory: false,
    },
    {
      label: "City",
      controlType: "textfield",
      placeHolder: "City",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "city",
      isMandatory: false,
    },
    {
      label: "Contact No.",
      controlType: "textfield",
      placeHolder: "Contact No.",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactNo",
      maxLength: 10,
      valid: true,
      validName: "phoneNo",
      isMandatory: false,
    },
    {
      label: "Email",
      controlType: "textfield",
      placeHolder: "Email",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "mail",
      // valid: true,
      validName: "email",
      isMandatory: false,
    },
    {
      label: "Pin Code",
      controlType: "textfield",
      placeHolder: "Pin Code",
      inputType: "number",
      inputDataType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: "6",
      dataKey: "pinCode",
      isMandatory: false,
    },
    {
      label: "Distance (km)",
      controlType: "textfield",
      placeHolder: "Distance",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "distance",
      isMandatory: false,
    },
  ],

  fieldMetaForStatutory: [
    {
      label: "GST No ",
      controlType: "textfield",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      isMandatory: false,
      valid: true,
      validName: "gstNo",
      inputType: "numberText",
    },
    {
        label: "GST Type",
        controlType: "autocomplete",
        placeHolder: "GST Type",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName: "gstTypeDetail",
        dataKey: "gstType",
        isRootLevelKey: false,
      },
    {
      label: "PAN No",
      controlType: "textfield",
      placeHolder: "PAN No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "panNo",
      valid: true,
      validName: "panNo",
      error: "PAN Number",
      isMandatory: false,
    },
    {
      label: "TAN No",
      controlType: "textfield",
      placeHolder: "TAN No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "tanNo",
      isMandatory: false,
    },
    // {
    //   label: "Cost Center Applicable",
    //   controlType: "radiogroup",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "costCenterApplicable",
    //   tableDataKey: "costCenterApplicable",
    //   defaultValue: "0",
    //   radioGroupItems: [
    //     {
    //       label: "Yes",
    //       value: "0",
    //     },
    //     {
    //       label: "No",
    //       value: "1",
    //     },
    //   ],
    //   isMandatory: false,
    // },
    {
      label: "FSSAI No",
      controlType: "textfield",
      placeHolder: "FSSAI No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiNo",
      isMandatory: false,
      inputType: "numberText",
    },
    {
      label: "FSSAI Expiry Date",
      controlType: "datepicker",
      placeHolder: "FSSAI Expiry Date",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fssiExpiryDate",
      isMandatory: false,
    },
    {
      label: "Drug Licence No",
      controlType: "textfield",
      placeHolder: "Drug Licence No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "drugLiecNo",
      isMandatory: false,
    },
    {
      label: "Drug Licence Expiry Date",
      controlType: "datepicker",
      placeHolder: "Drug Licence Expiry Date",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "drugLiecExpiryDate",
      isMandatory: false,
    },
    {
      label: "Udyom Aadhar No",
      controlType: "textfield",
      placeHolder: "Udyom Aadhar No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "udyamNo",
      isMandatory: false,
    },
    {
      label: "IEC Code",
      controlType: "textfield",
      placeHolder: "IEC Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "iecCode",
      isMandatory: false,
    },
    {
      label: "CIN No",
      controlType: "textfield",
      placeHolder: "CIN No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "companyCin",
      isMandatory: false,
    },
    {
      label: "Company Vendor Code",
      controlType: "textfield",
      placeHolder: "Company Vendor Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "companyVendorCode",
      isMandatory: false,
    },
    {
      label: "Third Party Link Code",
      controlType: "textfield",
      placeHolder: "Third Party Link Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "thirdPartyLinkCode",
      isMandatory: false,
    },
  ],
  fieldMetaForTaxInformation: [
    {
      label: "TDS Applicable",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "tdsApplicable",
      tableDataKey: "tdsApplicable",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "TDS Deducty Type",
      controlType: "hideAutocomplete",
      placeHolder: "TDS Deducty Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "deductTypeDetail",
      dataKey: "deductyType",
      isRootLevelKey: false,
      isMandatory: true,
      openDataKey: "tdsApplicable",
      open: "1",
      error: "tdsApplicable",
      tableDataKey: "tdsApplicable",
    },
    {
      label: "TDS Auto Deduct",
      controlType: "hideRadiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "tdsAutoDeduct",
      // tableDataKey: "tdsAutoDeduct",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      openDataKey: "tdsApplicable",
      open: "1",
      error: "tdsApplicable",
      tableDataKey: "tdsApplicable",
      isMandatory: true,
    },
    {
      label: "TDS Account Nature",
      controlType: "hideAutocomplete",
      placeHolder: "TDS Account Nature",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "accountNatureDetail",
      dataKey: "tdsAccountNature",
      isRootLevelKey: false,
      isMandatory: true,
      openDataKey: "tdsApplicable",
      open: "1",
      error: "tdsApplicable",
      tableDataKey: "tdsApplicable",
    },
    {
      label: "TCS Applicable",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "tcsApplicable",
      tableDataKey: "tcsApplicable",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value: " 1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Credit Days",
      controlType: "textfield",
      inputType: "number",
      placeHolder: "Credit Days",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "creditDays",

      isMandatory: false,
    },
    {
      label: "Alert Days",
      controlType: "textfield",
      inputType: "number",
      placeHolder: "Alert Days",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "alertDays",
      isMandatory: false,
      valid: true,
      validName: "greater-less",
      validCondition: "less-creditDays",
      validText: "Credit Days",
    },
    {
      label: "Credit Bills",
      controlType: "textfield",
      inputType: "number",
      placeHolder: "Credit Bills",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "creditBills",
      isMandatory: false,
    },
    // {
    //   label: "GST Type",
    //   controlType: "autocomplete",
    //   placeHolder: "GST Type",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "gstTypeDetail",
    //   dataKey: "gstType",
    //   isRootLevelKey: false,
    // },
    {
      label: "Credit Limit",
      controlType: "textfield",
      placeHolder: "Credit Limit",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "creditLimit",
      defaultValue: "0",
      isMandatory: false,
    },
    {
      label: "Is Related To Tax",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isTaxRelatedAccount",
      tableDataKey: "isTaxRelatedAccount",
      defaultValue: "0",
      // hideGrid:true,
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Type of Taxes",
      controlType: "hideAutocomplete",
      placeHolder: "Type of Taxes",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeOfTaxesDetail",
      dataKey: "taxType",
      isRootLevelKey: false,
      openDataKey: "isTaxRelatedAccount",
      open: "1",
      hideGrid: true,
      error: "isTaxRelatedAccount",
      tableDataKey: "isTaxRelatedAccount",

      getListId: "taxType",
      isMandatory: true,
      saveDataKey: true,
    },
    {
      label: "Type of Sub Taxes",
      controlType: "hideAutocomplete",
      placeHolder: "Type of Sub Taxes",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeOfSubTaxesDetail",
      dataKey: "subTaxType",
      hideGrid: true,
      isRootLevelKey: false,
      openDataKey: "isTaxRelatedAccount",
      open: "1",
      error: "isTaxRelatedAccount",
      tableDataKey: "isTaxRelatedAccount",
      getListFrom: "taxType",

      isMandatory: true,
      saveDataKey: true,
    },
    {
      label: "Is Hold",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isHold",
      tableDataKey: "isHold",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],

  fieldMetaForContactInfo: [
    {
      label: "Contact Person Name",
      controlType: "textfield",
      placeHolder: "Contact Person Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactPrsnName",
      isMandatory: true,
    },
    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactNo",
      valid: true,
      validName: "phoneNo",
      maxLength: 10,
      isMandatory: false,
    },
    {
      label: "Email",
      controlType: "textfield",
      placeHolder: "Email",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "email",
      valid: "true",
      validName: "email",
      isMandatory: false,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Address",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
    },
  ],

  fieldMetaForBankInfo: [
    {
      label: "Bank Name",
      controlType: "textfield",
      placeHolder: "Bank Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "bankName",
      isMandatory: true,
    },
    {
      label: "Account Number",
      controlType: "textfield",
      placeHolder: "Account Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "accountNumber",
      isMandatory: true,
    },
    {
      label: "Branch Name",
      controlType: "textfield",
      placeHolder: "Branch Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "branchName",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Address",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "IFSC Code",
      controlType: "textfield",
      placeHolder: "IFSC Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ifscCode",
      isMandatory: true,
    },
  ],

  fieldMetaForFifthTab: [
    {
      label: "Location",
      controlType: "textfield",
      placeHolder: "Select Location",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "locationDetail",
      dataKey: "locationIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Shadow Account",
      controlType: "autocomplete",
      placeHolder: "Select Shadow Account",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "accountTypeDetail",
      dataKey: "accType",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
}

