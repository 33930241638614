import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import AutoComplete from "../../components/Comman/AutoComplete";

import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { onlyFloatNumberPlus } from "../../components/Comman/Util/Validations";
import { NoLuggageOutlined } from "@mui/icons-material";

class SalePurchaseSettle extends React.Component {
  onTextFieldDataChange = (e, row) => {
    const { name, value } = e.target;
    if (value === "" || onlyFloatNumberPlus.test(+value)) {
    const rowFilter = this.props.rows.map((rowInfo) => {
      rowInfo=  {
        ...rowInfo,
        [name]: row.id == rowInfo.id ? value : rowInfo[name],
      }
      if(row.id == rowInfo.id){
        let DataToSet= this.calculateRate(rowInfo)
        return DataToSet;
      }
      return rowInfo;
    });
    this.props.setRow(rowFilter)
}
  };
  onAutocompleteChange = (name, value, row) => {
    if (value !== "" &&value!=null) {
    let taxObj=  this.props.taxList.find((taxRow)=>taxRow.id==value)
  console.log(taxObj)
    const rowFilter = this.props.rows.map((rowInfo) => {
    
      if(row.id == rowInfo.id){
        rowInfo=  {
          ...rowInfo,
          ...taxObj,
          [name]:  value ,
        }
        let DataToSet= this.calculateRate(rowInfo)
        return DataToSet;
      }
      return rowInfo;
    });
    this.props.setRow(rowFilter)
}
  };
calculateRate=(row)=>{
  let cgstPer= row.cgstPer
                 let sgstPer= row.sgstPer
                 let igstPer= row.igstPer
               let amt=  row.mrnRate * row.billQty 
                 const cgstAmount = ((amt* cgstPer) / 100).toFixed(2)
                 const sgstAmount = ((amt* sgstPer) / 100).toFixed(2)
                 const igstAmount = ((amt* igstPer) / 100).toFixed(2)
                 const amount = (amt + (+cgstAmount + +sgstAmount)).toFixed(2)

  return {
    ...row,
    cgstPer:cgstPer,
    sgstPer:sgstPer,
    igstPer:igstPer,
    cgstAmount:cgstAmount,
    sgstAmount:sgstAmount,
    igstAmount:igstAmount,
    amount:amount
}
}
  render() {
    const { rows ,taxList=[]} = this.props;

    return (
      <>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align={"center"} sx={{ width: "5%" }}>
                  Sr. No.
                </TableCell>
                <TableCell align="center" >
                  Product Name
                </TableCell>
                <TableCell align="center" >
                  UOM
                </TableCell>
                <TableCell align="center" >
                  MRN No
                </TableCell>
                <TableCell align="center" >
                  CGST
                </TableCell>
                <TableCell align="center" >
                SGST
                </TableCell>
                <TableCell align="center" >
                IGST
                </TableCell>
              
                <TableCell align="center" >
                  Quantity
                </TableCell>
                <TableCell align="center" >
                  Rate
                </TableCell>
                <TableCell align="center" sx={{ width: "25%" }}>
                  Tax
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((matrixDataRow, indexRow) => {
               console.log(matrixDataRow.tax)
               return (
                  <TableRow>
                    <TableCell align="center">{matrixDataRow.index}</TableCell>
                    <TableCell>{matrixDataRow.product!=null?matrixDataRow.product.name:""}</TableCell>
                    <TableCell>{matrixDataRow.product!=null?matrixDataRow.product.storeUomName:""}</TableCell>
                    <TableCell align="center">{matrixDataRow.mrnNo}</TableCell>
                    <TableCell align="center">{matrixDataRow.cgstAmount+" ( "+matrixDataRow.cgstPer+" % ) "} </TableCell>
                    <TableCell align="center">{matrixDataRow.sgstAmount+" ( "+matrixDataRow.sgstPer+" % ) "}</TableCell>
                    <TableCell align="center">{matrixDataRow.igstAmount+" ( "+matrixDataRow.igstPer+" % ) "} </TableCell>
                    <TableCell align="right">{matrixDataRow.billQty}</TableCell>
                    <TableCell align="right">
                      
                      <TextFieldCompo
                        placeHolder={"MRN Rate"}
                        size="small"
                        color="primary"
                        name={"mrnRate"}
                        id={"mrnRate"}
                        value={matrixDataRow.mrnRate}
                        onChange={(event) =>
                          this.onTextFieldDataChange(event, matrixDataRow)
                        }
                        fullWidth
                        errorText={""}
                      />
                    </TableCell>
                    <TableCell align="right">
                      
                    <AutoComplete
                        keyColName={"id"}
                        placeHolder="Select Tax"
                        value={matrixDataRow.taxId}
                        name={"taxId"}
                        options={taxList }
                       
                        onChange={(name, newValue) =>
                          this.onAutocompleteChange(name,newValue, matrixDataRow)
                        }
                        isError={ false}
                        errorText={" "}
                        
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default SalePurchaseSettle;
