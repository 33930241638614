// import MenuIcon from "@mui/icons-material/Menu";
// import { Avatar, FormControl, Grid, Select } from "@mui/material";
// import AutoComplete from "./Comman/AutoComplete";
// import IconButton from "@mui/material/IconButton";
// import InputBase from "@mui/material/InputBase";
// import Button from "@mui/material/Button";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import { alpha, styled } from "@mui/material/styles";
// import * as React from "react";
// import { connect } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { onToggle } from "../CommonActions/common.slice";
// import "../CSS/NewLanding.css";
// import { logOut } from "../Pages/Login/login.slice";
// import { getLocation } from "../Slice/accountLedger.slice";
// import { FontAwesomeIconCompo } from "./Comman/IconButton";
// import { white } from "../config/ColorObj";
// import { showLoador, showNotification } from "../Pages/Landing/Landing.slice";
// import { serverMsg } from "../config/messageconstant";
// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     anchorOrigin={{
//       vertical: "bottom",
//       horizontal: "right",
//     }}
//     transformOrigin={{
//       vertical: "top",
//       horizontal: "right",
//     }}
//     {...props}
//   />
// ))(({ theme }) => ({
//   "& .MuiPaper-root": {
//     borderRadius: 6,
//     marginTop: theme.spacing(1),
//     minWidth: 180,
//     color:
//       theme.palette.mode === "light"
//         ? "rgb(55, 65, 81)"
//         : theme.palette.grey[300],
//     boxShadow:
//       "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
//     "& .MuiMenu-list": {
//       padding: "4px 0",
//     },
//     "& .MuiMenuItem-root": {
//       "& .MuiSvgIcon-root": {
//         fontSize: 18,
//         color: theme.palette.text.secondary,
//         marginRight: theme.spacing(1.5),
//       },
//       "&:active": {
//         backgroundColor: alpha(
//           theme.palette.primary.main,
//           theme.palette.action.selectedOpacity
//         ),
//       },
//     },
//   },
// }));

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(9),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     // padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: "21px",
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "26ch",
//     },
//     // border: "1px solid #ccc",
//     borderRadius: "5px",
//   },
// }));
// const breakpoint = 600;
// const NavbarLanding = ({
//   logOut,
//   getLocation,
//   onToggle,
//   showLoador,
//   showNotification,
// }) => {
//   const toggleDrawer = () => {
//     onToggle();
//   };
//   const [locationList, setLocationList] = React.useState([]);
 
//   const [width, setWidth] = React.useState(window.innerWidth);

//   React.useEffect(() => {
//     const handleWindowResize = () => setWidth(window.innerWidth);
   
  
//     const locationIdList = localStorage.getItem("locationIdList");
//     if (locationIdList && locationList.length === 0) {
//       const valuesArray = JSON.parse(locationIdList);
//       let location = localStorage.getItem("location");
//       if (location == null) {  
//         localStorage.setItem("location", valuesArray[0].id);
//       }
     
//       setLocationList(valuesArray);
//     }
//     window.addEventListener("resize", handleWindowResize);
//     getLocation().then(({ success }) => {
//       showLoador({ loador: false });
//       if (!success) {
//         showNotification({ msg: serverMsg, severity: "error" });
//       }
//     });
//     return () => window.removeEventListener("resize", handleWindowResize);
//   }, []);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   let newLocation = useLocation();
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

 
//   const logOutHandler = () => {
//     localStorage.clear();
//     logOut();
//     window.location.replace("/login");
//   };



//   const onAutocompleteChange = (location, newValue) => {
//     if (newValue !== "") {
//       localStorage.setItem("location", newValue);
//     } else {
//       localStorage.setItem("location", "");
//     }
//     window.location.replace(newLocation.pathname);
//   };

//   const ChangeHandlerSearch = (e) => {};
//   return (
//     <>
//       {width > breakpoint ? (
//         <>
//           <div className="navbar">
//             <div
//               className="col-lg-12 flex-wrap"
//               style={{ justifyContent: "flex-start", flexDirection: "row" }}
//             >
//               <div className="main_menu">
//                 <ul>
//                   <li>
//                     <IconButton
//                       size="medium"
//                       edge="start"
//                       color="inherit"
//                       aria-label="open drawer"
//                       sx={{ mr: 2, mt: 0.5 }}
//                       onClick={toggleDrawer}
//                     >
//                       <MenuIcon color="primary-white" />
//                     </IconButton>
//                   </li>
//                 </ul>
//               </div>
//               <p
//                 style={{ justifyContent: "flex-start", width: "70%" }}
//                 className="welcomelabel"
//               >
//                 Accounts &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;
//               </p>
//               {/* <div clasName="col-lg-4"> */}
//               <div
//                 style={{
//                   justifyContent: "flex-start",
//                   float: "left",
//                   width: "13%",
//                 }}
//                 // className="login_area"
//               >
                
//                 <Grid sx={{ marginTop: 1 }} item xs={12} sm={6} md={6} lg={6}>
//                   <AutoComplete
//                     name={"location"}
//                     label={"Location"}
//                     placeHolder={"Location"}
//                     keyColName={"id"}
//                     value={
//                       localStorage.getItem("location")
//                         ? localStorage.getItem("location")
//                         : ""
//                     }
//                     options={locationList}
//                     onChange={onAutocompleteChange}
//                     errorText={""}
//                   />
//                 </Grid>
//               </div>
//               <div style={{ justifyContent: "flex-end", flexDirection: "row" }}>
//                 <div className="login_area">
//                   <div style={{ float: "right" }} className="login_r">
//                     <Button
//                       sx={{
//                         width: 50,
//                         height: 35,
//                         mt: 0.7,
//                         color: white,
//                         // ml: "1000px",
//                         minWidth: "250px",
//                       }}
//                       id="basic-button"
//                       aria-controls={open ? "basic-menu" : undefined}
//                       aria-haspopup="true"
//                       aria-expanded={open ? "true" : undefined}
//                       onClick={handleClick}
//                       startIcon={<PersonOutlineIcon />}
//                     >
//                       {localStorage.getItem("userName")}
//                     </Button>
//                     <Menu
//                       id="basic-menu1"
//                       anchorEl={anchorEl}
//                       open={open}
//                       onClose={handleClose}
//                       MenuListProps={{
//                         "aria-labelledby": "basic-button",
//                       }}
//                     >
//                       {/* {role !== "parent" && (
//  <MenuItem onClick={getprofile}>Profile</MenuItem>
//  )}
//  {role !== "student" && role !== "alumni" && role !== "parent" && (
//  <MenuItem onClick={onClickButton}>HR Easy</MenuItem>
//  )}
//  {
//  <MenuItem onClick={forgotPasswordLink}>
//  Change Password
//  </MenuItem>
//  } */}
//                       <MenuItem onClick={logOutHandler}> Logout </MenuItem>
//                     </Menu>
//                   </div>
//                 </div>
//               </div>
//               {/* </div> */}
//             </div>
//             <div className="clr"></div>
//           </div>
//         </>
//       ) : (
//         <>
//           <div className="navbar">
//             <div
//               className="col-lg-6"
//               style={{ justifyContent: "flex-start", flexDirection: "row" }}
//             >
//               <div className="main_menu">
//                 <ul>
//                   <li>
//                     <IconButton
//                       size="medium"
//                       edge="start"
//                       color="inherit"
//                       aria-label="open drawer"
//                       sx={{ mr: 0, mt: 0.5 }}
//                       onClick={toggleDrawer}
//                     >
//                       <MenuIcon color="primary-white" />
//                     </IconButton>
//                   </li>
//                 </ul>
//               </div>
//               <p
//                 style={{ justifyContent: "flex-start" }}
//                 className="welcomelabel"
//               >
//                 Accounts &emsp;&emsp;&emsp;
//               </p>

//               <div style={{ justifyContent: "flex-end", flexDirection: "row" }}>
//                 <div className="login_area">
//                   <div
//                     style={{ float: "right", marginLeft: -100 }}
//                     className="login_r"
//                   >
//                     <Button
//                       sx={{
//                         width: "auto",
//                         height: 35,
//                         mt: 0.7,
//                         color: white,
//                         // ml: "1000px",
//                         // minWidth: "150px",
//                       }}
//                       id="basic-button"
//                       aria-controls={open ? "basic-menu" : undefined}
//                       aria-haspopup="true"
//                       aria-expanded={open ? "true" : undefined}
//                       onClick={handleClick}
//                       startIcon={<PersonOutlineIcon />}
//                     >
//                       {localStorage.getItem("userName")}
//                     </Button>
//                     <Menu
//                       id="basic-menu1"
//                       anchorEl={anchorEl}
//                       open={open}
//                       onClose={handleClose}
//                       MenuListProps={{
//                         "aria-labelledby": "basic-button",
//                       }}
//                     >
//                       {/* {role !== "parent" && (
//  <MenuItem onClick={getprofile}>Profile</MenuItem>
//  )}
//  {role !== "student" && role !== "alumni" && role !== "parent" && (
//  <MenuItem onClick={onClickButton}>HR Easy</MenuItem>
//  )}
//  {
//  <MenuItem onClick={forgotPasswordLink}>
//  Change Password
//  </MenuItem>
//  } */}
//                       <MenuItem onClick={logOutHandler}> Logout </MenuItem>
//                     </Menu>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="clr"></div>
//         </>
//       )}

//       {/* 
// <header id="page-topbar" className="topbar-shadow">
//  <div className="layout-width">
//  <div className="navbar-header">
//  <div className="d-flex">
//  <div className="navbar-brand-box horizontal-logo">
//  <a href="#" className="logo logo-dark">
//  <span className="logo-sm"> <img src="images/logo-sm.png" alt="" height="50" /> </span>
//  <span className="logo-lg"> <img src="images/logo-dark.png" alt="" /> </span>
//  </a>
//  <a href="#" className="logo logo-light">
//  <span className="logo-sm">
//  {" "}
//  <img src={user} alt="" height={50} />{" "}
//  </span>
//  <span className="logo-lg">
//  {" "}
//  <img src="images/logo-light.png" alt="" />{" "}
//  </span>
//  </a>
//  </div>
 


//  <IconButton
//  size="large"
//  edge="start"
//  color="inherit"
//  aria-label="open drawer"
//  sx={{ mr: 2 }}
//  onClick={toggleDrawer}
//  >
//  <MenuIcon color="primary.white" />
//  </IconButton>

//  <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={12}>
//  <LabelCompo
//  className="text-black"
//  style={{ fontSize: 18,color:'#ffffff' }}
//  label="BMN COLLEGE"
//  />
//  </Grid>

 
//  </div>
//  <div className="d-flex align-items-center">
//  <div className="dropdown ms-sm-3 header-item">
//  <button
//  type="button"
//  className="btn"
//  id="page-header-user-dropdown"
//  data-bs-toggle="dropdown"
//  aria-haspopup="true"
//  aria-expanded="false"
//  >
//  {" "}
//  <span className="d-flex align-items-center">
//  {" "}
//  <img
//  className="rounded-circle header-profile-user"
 
//  src={user}

//  alt="Header Avatar"
//  />{" "}
//  <span className="text-start ms-xl-2">
//  {" "}
//  <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
//  Ekant Visave
//  </span>{" "}
//  <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
//  Student
//  </span>{" "}
//  </span>{" "}
//  </span>{" "}
//  </button>
//  <div className="dropdown-menu dropdown-menu-end">
 
//  <h6 className="dropdown-header">Welcome Ekant!</h6>
//  <a className="dropdown-item" href="#">
//  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />{" "}
//  <span className="align-middle">Profile</span>
//  </a>
 
//  <a className="dropdown-item" href="#">
//  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1" />{" "}
//  <span className="align-middle">Help</span>
//  </a>
//  <div className="dropdown-divider" />
 
//  <a className="dropdown-item" href="#">
//  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />{" "}
//  <span className="align-middle" data-key="t-logout">
//  Logout
//  </span>
//  </a>{" "}
//  </div>
//  </div>
//  </div>
//  </div>
//  </div>
//  </header> */}
//     </>
//   );
// };

// const mapStateToProps = (state) => ({
//   common: state.common,
//   hrData: state.hrDetails,
//   departmentList: state.department,
//   parentChildList: state.parentProfile,
//   // locationList: state.accountLedger,
// });

// const mapDispatchToProps = {
//   onToggle,
//   getLocation,
//   showLoador,
//   showNotification,
//   logOut,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(NavbarLanding);


import MenuIcon from "@mui/icons-material/Menu";
import AutoComplete from "./Comman/AutoComplete";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import { Avatar, FormControl, Grid, Select } from "@mui/material";
import { getLocation } from "../Slice/accountLedger.slice";
import { showLoador, showNotification } from "../Pages/Landing/Landing.slice";

import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { onToggle } from "../CommonActions/common.slice";
import "../CSS/NewLanding.css";
import { logOut } from "../Pages/Login/login.slice";
import { FontAwesomeIconCompo } from "./Comman/IconButton";
import { white, blackColor, primaryColor } from "../config/ColorObj";
// import logo from "../Images/logo.png";
import bizintel from "../Images/bizintel.png";
import DrawerTab from "./DrawerTab";

import { serverMsg } from "../config/messageconstant";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(9),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "21px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "26ch",
    },
    // border: "1px solid #ccc",
    borderRadius: "5px",
  },
}));
const breakpoint = 600;
const NavbarLanding = ({
  logOut,
  getLocation,
  onToggle,
  showLoador,
  showNotification,
}) => {
  const [locationList, setLocationList] = React.useState([]);
  const toggleDrawer = () => {
    onToggle();
  };
  let newLocation = useLocation();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    const locationIdList = localStorage.getItem("locationIdList");
    if (locationIdList && locationList.length === 0) {
      const valuesArray = JSON.parse(locationIdList);
      let location = localStorage.getItem("location");
      if (location == null) {
        localStorage.setItem("location", valuesArray[0].id);
      }

      setLocationList(valuesArray);
    }
    window.addEventListener("resize", handleWindowResize);
    getLocation().then(({ success }) => {
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const onAutocompleteChange = (name, newValue) => {
    if (newValue !== "") {
      window.location.replace(newLocation.pathname);
      localStorage.setItem("denomination", newValue);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutHandler = () => {
    localStorage.clear();
    logOut();
    window.location.replace("/login");
  };


  return (
    <>
      {width > breakpoint ? (
        <>
          <div
            className="navbar"
            style={{
              backgroundColor: primaryColor,
            }}
          >
            <div
              className="col-lg-12 flex-wrap"
              style={{ justifyContent: "flex-start", flexDirection: "row" }}
            >
              <div className="main_menu">
                <ul>
                  <li>
                    <img
                      className="Image10"
                      style={{ height: 50,marginTop:5,marginBottom:5, }}
                      src={bizintel}
                    />
                  </li>
                </ul>
              </div>

              <div style={{ justifyContent: "flex-end", flexDirection: "row" }}>
                <div
                  style={{
                    justifyContent: "center",
                    float: "left",
                    width: "75%",
                    flexDirection: "row",
                  
                  }}
                // className="login_area"
                >
                 
                  <Grid container xs={12} sm={12} md={12}>
                    <Grid sx={{ paddingLeft: 5 }} item xs={12} sm={10} md={10} lg={10}>
                      <DrawerTab />
                    </Grid>
                    <Grid sx={{ marginTop: 1 }} item xs={12} sm={2} md={2} lg={2}>
                      <AutoComplete
                        name={"location"}
                        label={"Location"}
                        placeHolder={"Location"}
                        keyColName={"id"}
                        value={
                          localStorage.getItem("location")
                            ? localStorage.getItem("location")
                            : ""
                        }
                        options={locationList}
                        onChange={onAutocompleteChange}
                        errorText={""}
                      />
                    </Grid>
                  </Grid>

                </div>
                <div className="login_area">

                  <div style={{ float: "right" }} className="login_r">
                    <Button
                      sx={{
                        width: 50,
                        height: 35,
                        mt: 1,
                        color: white,
                        // ml: "1000px",
                        minWidth: "250px",
                      }}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      startIcon={<PersonOutlineIcon />}
                      endIcon={<ExpandMoreIcon />}
                    >
                      {localStorage.getItem("userName")}
                    </Button>
                    
                    <Menu
                      id="basic-menu1"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={logOutHandler}> Logout </MenuItem>
                      <MenuItem> User Profile </MenuItem>
                      <MenuItem> Change Password </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="clr"></div> */}
          </div>
        </>
      ) : (
        <>
          <div className="navbar">
            <div
              className="col-lg-6"
              style={{ justifyContent: "flex-start", flexDirection: "row" }}
            >
              <div className="main_menu">
                <ul>
                  <li>
                    <IconButton
                      size="medium"
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      sx={{ mr: 0, mt: 0.5 }}
                      onClick={toggleDrawer}
                    >
                      <MenuIcon color="primary-white" />
                    </IconButton>
                  </li>
                </ul>
              </div>
              <div
                style={{ justifyContent: "flex-start" }}
                className="welcomelabel"
              >


              </div>
              <div style={{ justifyContent: "flex-end", flexDirection: "row" }}>
                <div className="login_area">

                  <div
                    style={{ float: "right", marginLeft: -100 }}
                    className="login_r"
                  >

                    <Button
                      sx={{
                        width: "auto",
                        height: 35,
                        mt: 0.7,
                        color: "#d2d2d2",
                        // ml: "1000px",
                        // minWidth: "150px",
                      }}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      startIcon={<PersonOutlineIcon />}
                    >
                      {localStorage.getItem("userName")}
                    </Button>
                    <Menu
                      id="basic-menu1"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={logOutHandler}> Logout </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
            <div className="clr"></div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  hrData: state.hrDetails,
  departmentList: state.department,
  parentChildList: state.parentProfile,
});

const mapDispatchToProps = {
  onToggle,
  logOut,
  getLocation,
  showLoador,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarLanding);
