import {
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { saveReport, setSaveReport } from '../../Slice/report.slice'

import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import { redColor, titleColor } from "../../config/ColorObj";
import * as msgConstant from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { withRouter } from "../../components/withRouter";
import { LabelCompo } from "../../components/Comman/Label";
import { validText } from "../../components/Comman/Util/Validations";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import TableColumnsDetails from "./TableColumnsDetails";
import { ButtonCompo } from "../../components/Comman/Button";
import AddTableColoumnsPopUp from "./AddTableColoumnsPopUp";
let rowList = [
    {

        columnName: "Category Name",
        fieldName: "category",
        pojoFiledName: "category",
        allignment: "left",
        width: 200,
        isShow: 1,
        sortNo: 2,
        isTotal: 0,
        isList: 0
    },
    {

        columnName: "Short Name",
        fieldName: "shortName",
        pojoFiledName: "shortName",
        allignment: "left",
        width: 200,
        isShow: 1,
        sortNo: 2,
        isTotal: 0,
        isList: 0
    },
    {

        columnName: "Product List",
        fieldName: "productList",
        pojoFiledName: "productList",
        subList: [{
            columnName: "Product Name",
            fieldName: "productName",
            pojoFiledName: "productName",
            allignment: "left",
            width: 200,
            isShow: 1,
            sortNo: 2,
            isTotal: 0,
            subList: [{
                columnName: "Item Name",
                fieldName: "itemName",
                pojoFiledName: "itemName",
                allignment: "left",
                width: 200,
                isShow: 1,
                sortNo: 2,
                isTotal: 0,
                isList: 0
            },
            {
                columnName: "Item Short Name",
                fieldName: "shortName",
                pojoFiledName: "shortName",
                allignment: "left",
                width: 200,
                isShow: 1,
                sortNo: 2,
                isTotal: 0,
                isList: 0
            },
            ],
            isList: 1,
        },
        {
            columnName: "Product Short Name",
            fieldName: "shortName",
            pojoFiledName: "shortName",
            allignment: "left",
            width: 200,
            isShow: 1,
            sortNo: 2,
            isTotal: 0,
            isList: 0
        },
        ],
        isList: 1,
        allignment: "",
        width: 0,
        isShow: 1,
        sortNo: 0,
        isTotal: 0,

    },
    {

        columnName: "Item List",
        fieldName: "ItemList",
        pojoFiledName: "ItemList",
        subList: [{
            columnName: "Product Name",
            fieldName: "productName",
            pojoFiledName: "productName",
            allignment: "left",
            width: 200,
            isShow: 1,
            sortNo: 2,
            isTotal: 0,
            isList: 0,
        },
        {
            columnName: "Product Short Name",
            fieldName: "shortName",
            pojoFiledName: "shortName",
            allignment: "left",
            width: 200,
            isShow: 1,
            sortNo: 2,
            isTotal: 0,
            isList: 0
        },
        ],
        isList: 1,
        allignment: "",
        width: 0,
        isShow: 1,
        sortNo: 0,
        isTotal: 0,

    },
]
class ReportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumnsList: [],
            reportName: "",
            type: "",
            isGraph: "1",
            isSubReport: "0",
            formValidErrors: {},
            fieldData: {},
            fieldDataEdit: {},
            formErrors: {},
            editData: {},
            openDialog: false,
            rowData: {}
        };
    }

    componentDidMount() {
        console.log(JSON.parse(this.props.report.reportData.tableColumns))
        this.setState({
            tableColumnsList: JSON.parse(this.props.report.reportData.tableColumns) != null ? JSON.parse(this.props.report.reportData.tableColumns) : [],
        });
    }
    onFormSave = () => {

        const tableData = this.state.tableColumnsList.sort(function (a, b) {
            return +a.sortNo - b.sortNo;
        });
        this.onSave({ ...this.props.report.reportData, tableColumns: JSON.stringify(tableData) })
        console.log(tableData)
    };
    onSave = (dataToSave) => {

        if (navigator.onLine) {
            const {
                saveReport,
                showLoador,
                showNotification,
            } = this.props;
            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                    this.props.changeTab(4)
                    showNotification({
                        msg: dataToSave.id ? msgConstant.deletemsg : msgConstant.savemsg,
                    });

                } else {
                    showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.report != this.props.report) {
            if (nextProps.report.reportData != this.props.report.reportData) {
                console.log(nextProps.report.reportData)
                this.setState({
                    tableColumnsList: JSON.parse(nextProps.report.reportData.tableColumns) != null ? JSON.parse(nextProps.report.reportData.tableColumns) : [],
                });
            }
        }
    }
    onRadioChange = (event) => {
        this.setState({ isSubReport: event.target.value });
    };

    onCancel = () => {
        this.props.navigate(-1);
    };

    rowEdit = (rowData) => {
        this.setState({
            rowData: rowData
        })
        this.toggleFilter()
    }
    toggleFilter = () => {
        if (this.state.openDialog) {
            this.setState({
                rowData: {},
            })
        }
        this.setState({
            openDialog: !this.state.openDialog,
        })
    }
    render() {
        const { tableColumnsList, rowData, openDialog } = this.state;
        return (
            <>

                <Grid container sx={{ marginTop: 2 }} spacing={1}>

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="flex-end"
                    >
                        <ButtonCompo
                            sx={{ color: 'white', backgroundColor: '#0f3f6f' }}
                            size="medium"
                            type="button"
                            variant="contained"
                            name={"Add"}
                            onClick={this.toggleFilter}
                            fullWidth={false}
                        />
                    </Grid>

                </Grid>
                <br />
                <TableColumnsDetails rowData={rowData} rowEdit={this.rowEdit} rowList={tableColumnsList} />
                <br />
                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                >
                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save and Next"
                        // fullWidth={true}
                        onClick={this.onFormSave}
                    />

                </Grid>
                {
                    openDialog &&
                    <AddTableColoumnsPopUp openDialog={openDialog} tableColumnsList={tableColumnsList} rowDataEdit={rowData} setOpenDialouge={() => {
                        this.setState({
                            openDialog: false,
                            rowData: {},
                        })
                    }}
                    />}
            </>
        );
    }
}
const mapStateToProps = (state) => ({ report: state.report });
const mapDispatchToProps = { showLoador, showNotification, saveReport };
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ReportForm);
