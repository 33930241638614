import { Grid } from "@mui/material";
import swal from "sweetalert";

import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { CashReceiptJSON } from "../../DynamicFormsJson/Transaction/cashReceipt";
import { getCashReciept } from "../../Slice/cashReceipt.slice";
import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import DynamicMainScreen from "./DynamicMasterMain";
import CashReceiptFormEdit from "./DynamicMasterForm";
import CashRecieptView from "./DynamicMasterForm";
import DynamicMasterForm from "./DynamicMasterForm";

import { withRouter } from "../../components/withRouter";
import {
  deleteFailedMsg,
  deletemsg,
  noInternetMsg,
  serverMsg,
} from "../../config/messageconstant";
import { CashReceiptColumns } from "../../tableColumns/table-columns";
import { apiDelete, apiGet } from "../../utils/api_service";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import endpoint from "../../config/endpoints";
class DynamicMasterIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      reportName: {},
      graphName: {},
      formErrors: {},
      dynamicMasterData: {},
      tableData: [],
      tableDataObj: [],
      tableColumns: [],
      reportTableCheckedInfo: {},
      openFilter: false,
      searchList: [],
      fieldDataForSearch: {},
      editData: {},
      viewData: {},
      fieldNames: [],
      tableColumns: [],
      tableColumnsToFilter: [],
      searchList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.subVoucherList?.subVoucherByType !==
      nextProps.subVoucherList?.subVoucherByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subVoucherDetail: nextProps.subVoucherList?.subVoucherByType,
        },
      });
    }
  }
  setFormData = (rowData) => {
    const { searchList = [], dynamicMasterData } = this.state;

    let fieldData = {};

    searchList.forEach((currentField) => {
      let dataToSearch = {};
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey];
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          // this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "autocomplete/textfield") {
        if (!currentField.isRootLevelKey) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[tableDataKey].id,
            },
          };
        } else {
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
        fieldData = {
          ...fieldData,
          [currentField.dataKeyName]: rowData[tableDataKey].name,
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: {
            id: rowData[tableDataKey].id,
          },
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          // this.props.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "autocomplete" &&
        currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: rowData[tableDataKey],
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          // this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          fieldData[currentField.dataKey] = dayjs(
            rowData[tableDataKey],
            "DD-MM-YYYY"
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] = rowData.isActive;
    console.log(fieldData);
    return fieldData;
  };

  async componentDidMount() {
    this.getMasterInfo(this.props.params.id);
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.params != nextProps.params &&
      this.props.params.id != nextProps.params.id
    ) {
      this.getMasterInfo(nextProps.params.id);
    }
  }

  getDropdownList = async (report) => {
    const {
      // getAreaByUserType,
      showLoador,
      showNotification,
    } = this.props;
    const { dynamicMasterData } = this.state;
    if (navigator.onLine) {
      const fieldMeta = JSON.parse(report.formFields);
      console.log(fieldMeta);

      let filterList = fieldMeta.filter(
        (fieldObj) =>
          (fieldObj.localList && fieldObj.localList.length != 0) ||
          (fieldObj.url && fieldObj.url != "")
      );
      Promise.all(filterList.map((fieldObj) => this.getDynamicList(fieldObj)));
    } else {
      showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
    }
  };
  getDynamicList = async (fieldObj) => {
    if (fieldObj.localList && fieldObj.localList.length != 0) {
      let LocalList = fieldObj.localList.map((dataObj) => {
        return { id: dataObj.key, name: dataObj.label };
      });
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          [fieldObj.masterName]: LocalList,
        },
      });
      return true;
    } else {
      const { showLoador, showNotification } = this.props;
      showLoador({ loador: true });
      let response = await apiGet({
        url: "/api/account/" + fieldObj.url,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        }
        return { data: data.data, success };
      });
      if (response.success) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            [fieldObj.masterName]:
              response.data &&
              response.data.map((dataObj) => {
                return {
                  ...dataObj,
                  id:
                    fieldObj.selectKey && dataObj[fieldObj.selectKey]
                      ? dataObj[fieldObj.selectKey]
                      : dataObj.id,
                  name:
                    fieldObj.selectLabel && dataObj[fieldObj.selectLabel]
                      ? dataObj[fieldObj.selectLabel]
                      : dataObj.name,
                };
              }),
          },
        });
      }

      return response;
    }
  };

  getMasterInfo = async (id) => {
    const { showLoador, showNotification } = this.props;
    if (id) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.formBuilder + "/" + id,
        }).then(async ({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              let rowObject = data.data;
              const formFields =
                JSON.parse(rowObject.formFields) != null
                  ? JSON.parse(rowObject.formFields)
                  : [];
              console.log(formFields);
              let fieldNames = formFields.map((columns) => {
                return {
                  ...columns,
                  label: columns.label,
                  controlType: columns.controlType,
                  placeHolder: columns.placeHolder,
                  masterName: columns.masterName,
                  dataKey: columns.dataKey,
                  md: 6,
                  lg: 6,
                  sm: 6,
                  xs: 12,
                  isRootLevelKey: columns.isRootLevelKey == 0 ? false : true,
                  disable: columns.disable == 0 ? false : true,
                  disableTrue: columns.disableTrue == 0 ? false : true,
                  dataKeyToSave: columns.dataKeyToSave,
                  isMandatory: columns.isMandatory,
                  maxLength: columns.maxLength,
                  inputTypeData: columns.inputTypeData,
                  validName: columns.validName,
                  validCondition: columns.validCondition,
                  validText: columns.validText,
                  valid: columns.valid,
                };
              });
              this.getDropdownList(rowObject);
              const reportColumnNames =
                JSON.parse(rowObject.tableColumns) != null
                  ? JSON.parse(rowObject.tableColumns)
                  : [];

              const ColumnNames = reportColumnNames.filter(
                (row) => row.isShow == 1
              );
              console.log(ColumnNames);
              let tableColumns = ColumnNames.map((columns) => {
                return {
                  ...columns,
                  title: columns.columnName,
                  name: columns.fieldName,
                  formDataKey: columns.fieldName,
                  align: columns.allignment,
                  isTotal: columns.isTotal ? columns.isTotal : 0,
                  showInExcel: true,
                  canSearch: true,
                  isChecked: true,
                  columnDisplayName: columns.columnName,
                  columnKeyName: columns.fieldName,
                  isChecked: true,
                };
              });
              const parameterRequired =
                JSON.parse(rowObject.parameterRequired) != null
                  ? JSON.parse(rowObject.parameterRequired)
                  : [];
              console.log(parameterRequired);
              let searchList = parameterRequired.map((columns) => {
                return {
                  ...columns,
                  label: columns.label,
                  controlType: columns.controlType,
                  placeHolder: columns.placeHolder,
                  masterName: columns.masterName,
                  dataKey: columns.dataKey,
                  md: 6,
                  lg: 6,
                  sm: 6,
                  xs: 12,
                  isRootLevelKey: false,
                  isMandatory: true,
                };
              });
              const dataToSet = this.setFormData(searchList);

              this.getTableList(rowObject, tableColumns);
              this.setState({
                reportName: rowObject,
                fieldNames: fieldNames,
                tableColumns: tableColumns,
                tableColumnsToFilter: tableColumns,
                searchList: searchList,
                fieldDataForSearch: dataToSet,
              });
            }
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
  };
  getTableList = (rowObject, tableColumns) => {
    const { showLoador, showNotification } = this.props;
    showLoador({ loador: true });
    apiGet({
      url: "/api/account/" + rowObject.apiName,
    }).then(({ data, success }) => {
      if (!success) {
        showLoador({ loador: false });
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        showLoador({ loador: false });
        let tableData = [];
        const reportformFields =
          JSON.parse(rowObject.formFields) != null
            ? JSON.parse(rowObject.formFields)
            : [];
        // if (reportName.isSubreport == 0) {
        tableData = data.data.map((reportData, index) => {
          let reportObj = { index: index + 1 };
          const reportInfo = tableColumns.map(
            (reportColumnNamesData, index) => {
              const filterData = reportformFields.filter(
                (rowData) =>
                  rowData.localList &&
                  rowData.localList.length != 0 &&
                  reportColumnNamesData.pojoFiledName == rowData.dataKey
              );
              if (filterData.length == 0) {
                let strData = reportColumnNamesData.pojoFiledName.split(".");
                let strD = "";
                strData.map((objData, index) => {
                  if (index == 0) {
                    strD =
                      reportData[objData] != null ? reportData[objData] : "";
                  } else {
                    strD = strD[objData] ? strD[objData] : "";
                  }
                });
                reportObj = {
                  ...reportObj,
                  [reportColumnNamesData.fieldName]:
                    reportColumnNamesData.isTotal &&
                      reportColumnNamesData.isTotal == 1
                      ? (+strD).toFixed(2)
                      : strD,
                };
              } else {
                let localFilter =
                  filterData[0].localList &&
                  filterData[0].localList.filter(
                    (row) =>
                      row.key == reportData[reportColumnNamesData.fieldName]
                  );
                reportObj = {
                  ...reportObj,
                  [reportColumnNamesData.fieldName]:
                    localFilter.length != 0 ? localFilter[0].label : "",
                };
              }
            }
          );
          reportObj = { ...reportObj, ...reportData };
          return reportObj;
        });

        this.setState({
          tableDataObj: tableData,
        });
      }
    });
  };


  rowEdit = (masterId, mainId) => {
    this.setState({
      editData: {
        masterId: masterId,
        mainId: mainId,
      },
    });
  };
  callApi = () => {
    const { searchInfo, reportName, tableColumns } = this.state;
    this.getTableList(reportName, tableColumns);
    this.rowReset();
  };
  rowLink = (rowData) => {
    this.setState({
      viewData: rowData,
    });
  };
  rowReset = () => {
    this.setState({
      viewData: {},
      editData: {},
    });
  };
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onDelete = async () => {
    const { deleteMsgAuto = false } = this.props;
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiDelete({
        url: CashReceiptJSON.apiBaseURL + "/" + this.state.viewData.id,
      }).then(({ data, success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({
            msg: deleteMsgAuto ? data.data.message : deletemsg,
            severity: data.data.error ? "error" : "success",
          });

          this.callApi();
        } else {
          this.props.showNotification({
            msg: deleteFailedMsg,
            severity: "error",
          });

          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.map(
      (item) => {
        if (item.columnKeyName === selectedOption.columnKeyName) {
          return { ...item, isChecked: !item.isChecked }
        }
        return item
      });
    this.setState({
      tableColumnsToFilter: selectedItem,
    });
  };
  render() {
    const { cashRecieptList } = this.props;
    const {
      editData,
      viewData,
      tableDataObj,
      fieldData,
      formErrors,
      reportName,
      reportTableCheckedInfo,
      openFilter,
      dynamicMasterData,
      tableData,
      isLoading,
      tableColumnsToFilter = [],
      tableColumns,
      fieldDataForSearch,
      searchList,
      fieldNames,
    } = this.state;
    // console.log(cashRecieptList?.cashReciept);
    return (
      <>
        {/* <Paper
                    sx={{
                        p: 2,
                        pt: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                > */}
        <Grid sx={{ height: "100vh" }} container spacing={1}>
          <Grid item xs={12} sm={7} md={7}>
            <DynamicMasterForm
              fieldNames={fieldNames}
              reportName={reportName}
              dynamicMasterData={dynamicMasterData}
              rowReset={this.rowReset}
              callApi={this.callApi}
              editData={editData}
            />
          </Grid>
          <Grid
            sx={{ borderLeft: "2px solid #CFCFCF" }}
            item
            xs={12}
            sm={5}
            md={5}
          >
            <DynamicMainScreen
              rowEdit={this.rowEdit}
              reportName={reportName}
              fieldNames={fieldNames}
              tableColumns={tableColumns}
              tableColumnsToFilter={tableColumnsToFilter}
              searchList={searchList}
              callApi={this.callApi}
              tableDataObj={tableDataObj}
              fieldDataForSearch={fieldDataForSearch}
              onRTSelectMultipleChange={this.onRTSelectMultipleChange}
            />
          </Grid>
        </Grid>
        {/* </Paper> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subVoucherList: state.subVoucher,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getSubVoucherByType,
  getCashReciept,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicMasterIndex);
