import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    type: [],
    date:{},
};
let URL = endpoints.type;
 let URL1= endpoints.yearEnd
const typeSlice = createSlice({
    name: "type",
    initialState,
    reducers: {
        typeSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                type: row,

            };
        },
        dateSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                date: row,

            };
        },
        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { typeSuccess, dateSuccess,resetState } = typeSlice.actions;

export default typeSlice.reducer;

export const getType = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + '?sort={"insertDateTime": "DESC"}',
        }).then(({ data, success }) => {
            if (success) {
                let index = 0;
                const { content } = data.data;
                const row = content.map((typeObject, index) => {
                    let typeData = {
                        index: index + 1,
                        id: typeObject.id === null ? "" : typeObject.id,
                        name: typeObject.name === null ? "" : typeObject.name,

                    };
                    return typeData;
                });
                dispatch(typeSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};

export const getDate = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1 +"/get-year-fromDate-toDate",
        }).then(({ data, success }) => {
            if (success) {
               
                
                const row = data.data
                   
                dispatch(dateSuccess({ row }));
                return { response: row, success };
            }
            return { response: {}, success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};