import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { balanceSheetJson } from "../../DynamicFormsJson/Report/balanceSheet";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GroupSummarySubReportByGroup from "./GroupSummarySubReportByGroup";
import MFGAndPL from "./MFGAndPL";
import LeftRightAdjustTable from "./LeftRightAdjustTable";
import BalanceSheetTable from "./BalanceSheetTable";
import {
    Grid
} from "@mui/material";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import RTTable from "../../components/Comman/RT/RTTable";
import MonthlyBarChart from "../../components/MonthlyBarChart";
const BalanceSheet = ({ showLoador, showNotification }) => {
    const [searchValue, setSearchValue] = useState("");
    const [dynamicMasterData, setDynamicMasterData] = useState({});
    const [formData, setFormData] = React.useState({});
    const [cashList, setCashbookList] = React.useState([]);
    const [MFGList, setMFGList] = React.useState({});
    const[detailList,setDetailList]=React.useState([]);
    const [popUpData, setPopupdata] = useState({})
    const [openTable, setOpenTable] = useState(false);
  const[searchFlag,setSearchFlag]=useState(false);
    const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
    const [fieldData, setFieldData] = React.useState({});
    useEffect(() => {
        getDropDownList()
    }, []);

    const getDropDownList = async () => {
        showLoador({ loador: true });
        const yearMasterDetail = await apiGet({
            url: endpoints.year,
        }).then(({ data, success }) => {
            if (success) {
                showLoador({ loador: false });
                let rowData = data.data;


                return rowData.map((rowData) => {
                    return { ...rowData, name: rowData.yearName };
                })
            }
            else {
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }
            return []
        });
        showLoador({ loador: true });
        let CompanyDetails = await apiGet({
            url: endpoints.company,
        }).then(({ data, success }) => {
            if (success) {
                showLoador({ loador: false });
                let rowData = data.data;
                const location=localStorage.getItem("location");
                const locationList =localStorage.getItem("locationIdList");
               const locallist=JSON.parse(locationList)
               const locationlist=locallist.filter((data)=>data.id==location)
              
                   if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
                     
                     setFieldData({ companyId: locationlist[0].company.id })
                   }
                return rowData.map((rowData) => {
                    return { ...rowData, name: rowData.companyName };
                })
            } else {
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }
            return []
        });
        setDynamicMasterData({
            yearMasterDetail: yearMasterDetail,
            CompanyDetails: CompanyDetails
        });
    }
    const getList = (companyId, yearId) => {
        setSearchFlag(false);

        showLoador({ loador: true });
        apiGet({
            url: endpoints.balanceSheet + "?companyIds=" + companyId + "&yearId=" + yearId
        }).then(({ data, success }) => {
            if (success) {
                let rowList = []
                let leftAmount = 0
                let rightAmount = 0
              
                let rowData = data.data

                let RightAlign = rowData.dynamicData !== null ? rowData.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R") : []
                let LeftAlign = rowData.dynamicData !== null ? rowData.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R") : []
                let RightAlignProfitStatic = Object.keys(rowData).length != 0 && rowData.staticData.length != 0 ? rowData.staticData.filter((rowData) => rowData.alligment == "R") : [];
                let LeftAlignProfitStatic = Object.keys(rowData).length != 0 && rowData.staticData.length != 0 ? rowData.staticData.filter((rowData) => rowData.alligment != "R") : [];

                let rIndex = 0
                let isDynamic = true
                if (RightAlign.length >= LeftAlign.length) {
                    RightAlign.map((right, index) => {
                        let lAmt = right.creditAmt + right.debitAmt
                        rightAmount = rightAmount + lAmt
                        let dataToPush = {
                            groupNameR: right.groupDetail != null ? right.groupDetail.group_name : "",
                            groupIdR: right.groupDetail != null ? right.groupDetail.id : "",
                            amountL: Math.abs(lAmt).toFixed(2),
                        }
                        if (isDynamic) {
                            let dynamicInfo = LeftAlign[index] ? LeftAlign[index] : {}

                            if (Object.keys(dynamicInfo).length != 0) {
                                let lAmt = dynamicInfo.creditAmt + dynamicInfo.debitAmt
                                leftAmount = leftAmount + lAmt
                                dataToPush = {
                                    groupId: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.id : "",
                                    groupName: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.group_name : "",
                                    amount: Math.abs(lAmt).toFixed(2),
                                    ...dataToPush
                                }
                            } else {
                                isDynamic = false

                                let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                                rIndex = rIndex + 1
                                if (Object.keys(dynamicInfo).length != 0) {
                                    let lAmt = dynamicInfo.amt
                                    leftAmount = leftAmount + lAmt
                                    dataToPush = {
                                        groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                                        amount: Math.abs(lAmt).toFixed(2),
                                        ...dataToPush
                                    }
                                } else {
                                    dataToPush = {
                                        groupName: "",
                                        amount: '',
                                        ...dataToPush
                                    }
                                }
                            }
                        } else {
                            {

                                isDynamic = false

                                let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                                rIndex = rIndex + 1
                                if (Object.keys(dynamicInfo).length != 0) {
                                    let lAmt = dynamicInfo.amt
                                    leftAmount = leftAmount + lAmt
                                    dataToPush = {
                                        groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                                        amount: Math.abs(lAmt).toFixed(2),
                                        ...dataToPush
                                    }
                                } else {
                                    dataToPush = {
                                        groupName: "",
                                        amount: '',
                                        ...dataToPush
                                    }
                                }

                            }
                        }

                        rowList.push({...dataToPush, flag:0,})
                    });
                    RightAlignProfitStatic.map((right, index) => {
                        let lAmt = right.amt
                        rightAmount = rightAmount + lAmt
                        let dataToPush = {
                            groupNameR: right.name != null ? right.name : "",
                            amountL: Math.abs(lAmt).toFixed(2),
                        }
                        let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                        rIndex = rIndex + 1
                        if (Object.keys(dynamicInfo).length != 0) {
                            let lAmt = dynamicInfo.amt
                            leftAmount = leftAmount + lAmt
                            dataToPush = {
                                groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                                amount: Math.abs(lAmt).toFixed(2),
                                ...dataToPush
                            }
                        } else {
                            dataToPush = {
                                groupName: "",
                                amount: '',
                                ...dataToPush
                            }
                        }
                        rowList.push({...dataToPush, flag:0,})
                    });
                } else {
                    LeftAlign.map((right, index) => {
                        let lAmt = right.creditAmt + right.debitAmt
                        leftAmount = leftAmount + lAmt
                        let dataToPush = {
                            groupName: right.groupDetail != null ? right.groupDetail.group_name : "",
                            groupId: right.groupDetail != null ? right.groupDetail.id : "",
                            amount: Math.abs(lAmt).toFixed(2),
                        }
                        if (isDynamic) {
                            let dynamicInfo = RightAlign[index] ? RightAlign[index] : {}
                            if (Object.keys(dynamicInfo).length != 0) {
                                let lAmt = dynamicInfo.creditAmt + dynamicInfo.debitAmt
                                rightAmount = rightAmount + lAmt
                                dataToPush = {
                                    ...dataToPush,
                                    groupIdR: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.id : "",
                                    groupNameR: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.group_name : "",
                                    amountL: Math.abs(lAmt).toFixed(2),

                                }
                            } else {
                                isDynamic = false

                                let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                                rIndex = rIndex + 1
                                if (Object.keys(dynamicInfo).length != 0) {
                                    let lAmt = dynamicInfo.amt
                                    rightAmount = rightAmount + lAmt
                                    dataToPush = {
                                        ...dataToPush,
                                        groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                                        amountL: Math.abs(lAmt).toFixed(2),

                                    }
                                } else {
                                    dataToPush = {
                                        ...dataToPush,
                                        groupNameR: "",
                                        amountL: '',

                                    }
                                }
                            }
                        } else {
                            {

                                isDynamic = false

                                let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                                rIndex = rIndex + 1
                                if (Object.keys(dynamicInfo).length != 0) {
                                    let lAmt = dynamicInfo.amt
                                    rightAmount = rightAmount + lAmt
                                    dataToPush = {
                                        ...dataToPush,
                                        groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                                        amountL: Math.abs(dynamicInfo.amt).toFixed(2),
                                    }
                                } else {
                                    dataToPush = {
                                        ...dataToPush,
                                        groupNameR: "",
                                        amountL: '',
                                    }
                                }

                            }
                        }

                        rowList.push({...dataToPush, flag:0,})
                    });
                    LeftAlignProfitStatic.map((right, index) => {
                        let lAmt = right.amt
                            leftAmount = leftAmount + lAmt
                        let dataToPush = {
                            groupName: right.name != null ? right.name : "",
                            amount: Math.abs(lAmt).toFixed(2),
                        }
                        let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                        rIndex = rIndex + 1
                        if (Object.keys(dynamicInfo).length != 0) {
                            let lAmt = dynamicInfo.amt
                            rightAmount = rightAmount + lAmt
                            dataToPush = {
                                ...dataToPush,
                                groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                                amountL: Math.abs(lAmt).toFixed(2),

                            }
                        } else {
                            dataToPush = {
                                ...dataToPush,
                                groupNameR: "",
                                amountL: '',
                            }
                        }
                        rowList.push({...dataToPush, flag:0,})
                    });
                }
               
               let dataToPush = {
                    groupName: "Total",
                    amount: Math.abs(leftAmount).toFixed(2),
                    groupNameR: "",
                    amountL:Math.abs(rightAmount).toFixed(2),
                    groupNameRLink: false,
                    groupNameLink: false,
                   
                }
                rowList.push({...dataToPush, flag:1,})
                setCashbookList(rowList)
                showLoador({ loador: false });
                let DynamicData = Object.keys(data.data).length != 0 && data.data.dynamicData.length != 0 ? data.data.dynamicData : [];
                let staticData = Object.keys(data.data).length != 0 && data.data.staticData.length != 0 ? data.data.staticData : [];
                setMFGList({ DynamicData: DynamicData, staticData: staticData })
              
            } else {
                setCashbookList([])
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }

        });
    }


    const onSave = (dataToSearch) => {
        let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
        let filterYearMasterDetail = dynamicMasterData["yearMasterDetail"].filter((row) => row.id === dataToSearch.yearId)


        setFormData({
            ...dataToSearch,
            companyName: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
            yearName: filterYearMasterDetail.length != 0 ? filterYearMasterDetail[0].name : "",
            fromDate: filterYearMasterDetail.length != 0 ? filterYearMasterDetail[0].fromDate : "",
            toDate: filterYearMasterDetail.length != 0 ? filterYearMasterDetail[0].toDate : "",
        })
        getList(dataToSearch.companyId, dataToSearch.yearId)
        //   getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
    }

    const showDetail = (dataToSearch) => {
        setSearchFlag(true)
        let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
        let filterYearMasterDetail = dynamicMasterData["yearMasterDetail"].filter((row) => row.id === dataToSearch.yearId)


        setFormData({
            ...dataToSearch,
            companyName: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
            yearName: filterYearMasterDetail.length != 0 ? filterYearMasterDetail[0].name : "",
            fromDate: filterYearMasterDetail.length != 0 ? filterYearMasterDetail[0].fromDate : "",
            toDate: filterYearMasterDetail.length != 0 ? filterYearMasterDetail[0].toDate : "",
        })
        getDeatilList(dataToSearch.companyId, dataToSearch.yearId)
        //   getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
    }
    const onSearch = (searchV) => {
        setSearchValue(searchV)
    };

    const generatePDFMethod = () => {
        generatePDFExcelData()
        var columns = [["Group Name", "Amount", "Group Name", "Amount"]];

        let tableList = []
        cashList.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            tableData.push(rowData.groupNameR)

            tableData.push(rowData.amountL)
            tableList.push(tableData)
        }))

        const columnStyles = {
            0: { cellWidth: 150 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        const columnStylesPlain = {
            0: { cellWidth: 80 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        generatePDF({
            columnStylesPlain: columnStylesPlain,
            rows: tableList,
            columns,
            headerList: [
                {
                    label: "Company Name",
                    value: formData.companyName
                },
                {
                    label: "Year Name",
                    value: formData.yearName
                },
            ],
            pdfTitle: balanceSheetJson.screenTitle,
        });

    };
    const generateDetailPDFMethod = () => {
        // generatePDFExcelData()
        var columns = [["Liabilities","Amount", "Assets","Amount"]];
console.log("detail pdf")
        let tableList = []
        


        // detailList.map(((rowData, index) => {

        //     let tableData = []

        //     tableData.push(rowData.groupName+"\n"+rowData.amount)

        //     // tableData.push(rowData.amount)
        //     tableData.push(rowData.groupNameR+"\n"+rowData.amountL)

        //     // tableData.push(rowData.amountL)
        //     tableList.push(tableData)
        // }))


        detailList.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            tableData.push(rowData.groupNameR)

            tableData.push(rowData.amountL)
            tableList.push(tableData)
            if( rowData.resultR&&rowData.resultL){
                if ( rowData.resultR.length >= rowData.resultL.length) {
                    rowData.resultR.map((data, index) => {
                    let leftInfo= rowData.resultL[index]?rowData.resultL[index]:{}
                         let creditAmt = 0;
                         let debitAmt = 0;
                         creditAmt = data.creditAmt == null ? creditAmt :data.creditAmt
                         debitAmt = data.debitAmt == null ? debitAmt :data.debitAmt
                         let creditAmtL = 0;
                         let debitAmtL = 0;
                         creditAmtL = leftInfo.creditAmt!=undefined&&leftInfo.creditAmt != null ? leftInfo.creditAmt : ""
                         debitAmtL =leftInfo.debitAmt!=undefined&&leftInfo.debitAmt != null ? leftInfo.debitAmt : ""
                         let tableData = [];
                         tableData.push(leftInfo.name?leftInfo.name:"")    
                         tableData.push(creditAmtL !=""&&debitAmtL!=""?(Math.abs( creditAmtL + debitAmtL)).toFixed(2):"")
                       
                         tableData.push(data.name)    
                     tableData.push((Math.abs( creditAmt + debitAmt)).toFixed(2))
                     tableList.push(tableData)
                 })
                
                 }else{
                     rowData.resultL.map((data, indexData) => {
                         let leftInfo= rowData.resultR[indexData]?rowData.resultR[indexData]:{}
                              let creditAmt = 0;
                              let debitAmt = 0;
                              creditAmt = data.creditAmt == null ? creditAmt :data.creditAmt
                              debitAmt = data.debitAmt == null ? debitAmt :data.debitAmt
                              let creditAmtL = 0;
                              let debitAmtL = 0;
                              creditAmtL = leftInfo.creditAmt!=undefined&&leftInfo.creditAmt != null ? leftInfo.creditAmt : ""
                              debitAmtL =leftInfo.debitAmt!=undefined&&leftInfo.debitAmt != null ? leftInfo.debitAmt : ""
                              let tableData = [];
                              tableData.push(data.name)    
                          tableData.push((Math.abs( creditAmt + debitAmt)).toFixed(2))
                          tableData.push(leftInfo.name?leftInfo.name:"")   
                          console.log((Math.abs( creditAmtL + debitAmtL)).toFixed(2)) 
                          console.log((creditAmtL !=""&&debitAmtL!="")||((creditAmtL ==0||debitAmtL ==0)&&(creditAmtL !=""||debitAmtL !=""))?(Math.abs( creditAmtL + debitAmtL)).toFixed(2):"") 
                          tableData.push((creditAmtL !=""&&debitAmtL!="")||((creditAmtL ==0||debitAmtL ==0)&&(creditAmtL !=""||debitAmtL !=""))?(Math.abs( creditAmtL + debitAmtL)).toFixed(2):"")
                          tableList.push(tableData)
                      })
                 }
            }
           
                
            
        });

        const columnStyles = {
            0: { cellWidth: 150 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        const columnStylesPlain = {
            0: { cellWidth: 80 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        generatePDF({
            columnStylesPlain: columnStylesPlain,
            rows: tableList,
            columns,
            headerList: [
                {
                    label: "Company Name",
                    value: formData.companyName
                },
                {
                    label: "Year Name",
                    value: formData.yearName
                },
            ],
            pdfTitle: balanceSheetJson.screenTitle,
        });

    };
    const generatePDFExcelData = () => {
        let tableList = []
        MFGList.DynamicData && MFGList.DynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R").map((rowData) => {
           let leftTotal=0;
            leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt
            let tableData = []
            tableData.push(rowData.groupDetail.group_name)
            tableData.push(Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2))
        })
        MFGList.staticData && MFGList.staticData.filter((rowData) => rowData.alligment != "R").map((rowData) => {
            let leftTotal=0;
            leftTotal = leftTotal + rowData.amt
            let tableData = []
            tableData.push(rowData.name)
            tableData.push(Math.abs(rowData.amt).toFixed(2))
        })
        MFGList.DynamicData && MFGList.DynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R").map((rowData) => {
            let leftTotal=0;
            leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt
            let tableData = []
            tableData.push(rowData.groupDetail.group_name)
            tableData.push(Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2))
        })
        MFGList.staticData && MFGList.staticData.filter((rowData) => rowData.alligment == "R").map((rowData) => {
            let leftTotal=0;
            leftTotal = leftTotal + rowData.amt
            let tableData = []
            tableData.push(rowData.name)
            tableData.push(Math.abs(rowData.amt).toFixed(2))
        })

    }

    const generateExcelMethod = () => {
        var columns = ["Group Name", "Amount", "Group Name", "Amount"];
        let alignList = [];

        let rows = []
        cashList.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            tableData.push(rowData.groupNameR)

            tableData.push(rowData.amountL)
            rows.push(tableData)
        });
        generateExcel({
            columns,
            rows,
            alignList,
            headerList: ["Company Name", formData.companyName, "Year Name", formData.yearName],
            title: balanceSheetJson.screenTitle,
        });

    };
    const generateDetailExcelMethod = () => {
        var columns = ["Liabilities","Amount", "Assets","Amount"];
        let alignList = [];
        let groupNameL="";
        let amountL=0;
        let groupNameR="";
        let amountR=0;
        let rows = []
        let tableData = [];
        detailList.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            tableData.push(rowData.groupNameR)

            tableData.push(rowData.amountL)
            rows.push(tableData)
            if( rowData.resultR && rowData.resultL){
                if ( rowData.resultR.length >= rowData.resultL.length) {
                    rowData.resultR.map((data, index) => {
                    let leftInfo= rowData.resultL[index]?rowData.resultL[index]:{}
                         let creditAmt = 0;
                         let debitAmt = 0;
                         creditAmt = data.creditAmt == null ? creditAmt :data.creditAmt
                         debitAmt = data.debitAmt == null ? debitAmt :data.debitAmt
                         let creditAmtL = 0;
                         let debitAmtL = 0;
                         creditAmtL = leftInfo.creditAmt!=undefined&&leftInfo.creditAmt != null ? leftInfo.creditAmt : ""
                         debitAmtL =leftInfo.debitAmt!=undefined&&leftInfo.debitAmt != null ? leftInfo.debitAmt : ""
                         let tableData = [];
                         tableData.push(leftInfo.name?leftInfo.name:"")    
                         tableData.push(creditAmtL !=""&& debitAmtL!=""?(Math.abs( creditAmtL + debitAmtL)).toFixed(2):"")
                       
                         tableData.push(data.name)    
                     tableData.push((Math.abs( creditAmt + debitAmt)).toFixed(2))
                     rows.push(tableData)
                 })
                
                 }else{
                     rowData.resultL.map((data, indexData) => {
                         let leftInfo= rowData.resultR[indexData]?rowData.resultR[indexData]:{}
                              let creditAmt = 0;
                              let debitAmt = 0;
                              creditAmt = data.creditAmt == null ? creditAmt :data.creditAmt
                              debitAmt = data.debitAmt == null ? debitAmt :data.debitAmt
                              let creditAmtL = 0;
                              let debitAmtL = 0;
                              creditAmtL = leftInfo.creditAmt!=undefined&&leftInfo.creditAmt != null ? leftInfo.creditAmt : ""
                              debitAmtL =leftInfo.debitAmt!=undefined&&leftInfo.debitAmt != null ? leftInfo.debitAmt : ""
                              let tableData = [];
                              tableData.push(data.name)    
                          tableData.push((Math.abs( creditAmt + debitAmt)).toFixed(2))
                          tableData.push(leftInfo.name?leftInfo.name:"")   
                          console.log((Math.abs( creditAmtL + debitAmtL)).toFixed(2)) 
                          console.log((creditAmtL !=""&&debitAmtL!="")||((creditAmtL ==0||debitAmtL ==0)&&(creditAmtL !=""||debitAmtL !=""))?(Math.abs( creditAmtL + debitAmtL)).toFixed(2):"") 
                          tableData.push((creditAmtL !=""&&debitAmtL!="")||((creditAmtL ==0||debitAmtL ==0)&&(creditAmtL !=""||debitAmtL !=""))?(Math.abs( creditAmtL + debitAmtL)).toFixed(2):"")
                          rows.push(tableData)
                      })
                 }
            }
           
                
            
        });
        // tableData.push(groupNameL+"       "+amountL)

        // // tableData.push(rowData.amount)
        // tableData.push(groupNameR+"       "+amountR)   
        // rows.push(tableData)
        
        generateExcel({
            columns,
            rows,
            alignList,
            headerList: ["Company Name", formData.companyName, "Year Name", formData.yearName],
            title: balanceSheetJson.screenTitle,
        });

    };
    

    const getFilteredTableData = (tableData) => {

        let listData = tableData.filter((row) => {

            let isValid = false;

            if (row.groupName.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }

            if (row.groupNameR.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }
            if (row.amount.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }

            if (row.amountL.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }
            return isValid;

        });
        return listData
    };
    const rowLink = (rowData, key) => {
        if (key == "groupNameR") {
            if (rowData.groupIdR) {
                setPopupdata({ groupId: rowData.groupIdR, groupName: rowData.groupNameR })
                setOpenTable(!openTable);
            }
            else {
                toggleMFGAndProfitAndLoss()
            }


        } else {
            if (rowData.groupId) {
                setPopupdata({ groupId: rowData.groupId, groupName: rowData.groupName })
                setOpenTable(!openTable);
            }
            else {
                toggleMFGAndProfitAndLoss()
            }

        }

    }
    const toggle = () => {
        setOpenTable(!openTable);
        if(formData!=null && Object.keys(formData).length!=0){
            getList(formData.companyId,formData.yearId)
        }
      

    };
    const toggleMFGAndProfitAndLoss = () => {
        setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
        if(formData!=null && Object.keys(formData).length!=0){
            getList(formData.companyId,formData.yearId)
        }
    };
    const getDeatilList=(companyId,yearId)=>{

showLoador({ loador: true });
apiGet({
    url: endpoints.cashFlowSummary+"/balance-sheet-detail?companyIds="+ companyId +"&yearId="+ yearId,
   
}).then(({ data, success }) => {
    if (success) {
        let rowList = []
        let leftAmount = 0
        let rightAmount = 0
      let rowData=data.data;
       

        let RightAlign = rowData.dynamicData !== null ? rowData.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R") : []
        let LeftAlign = rowData.dynamicData !== null ? rowData.dynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R") : []
        let RightAlignProfitStatic = Object.keys(rowData).length != 0 && rowData.staticData.length != 0 ? rowData.staticData.filter((rowData) => rowData.alligment == "R") : [];
        let LeftAlignProfitStatic = Object.keys(rowData).length != 0 && rowData.staticData.length != 0 ? rowData.staticData.filter((rowData) => rowData.alligment != "R") : [];

        let rIndex = 0
        let isDynamic = true
        if (RightAlign.length >= LeftAlign.length) {
            RightAlign.map((right, index) => {
                let lAmt = right.creditAmt + right.debitAmt
                rightAmount = rightAmount + lAmt
                let dataToPush = {
                    groupNameR: right.groupDetail != null ? right.groupDetail.group_name : "",
                    groupIdR: right.groupDetail != null ? right.groupDetail.id : "",
                    amountL: Math.abs(lAmt).toFixed(2),
                    resultR:right.groupDetail != null && right.groupDetail.result?right.groupDetail.result:[],
                }
                if (isDynamic) {
                    let dynamicInfo = LeftAlign[index] ? LeftAlign[index] : {}

                    if (Object.keys(dynamicInfo).length != 0) {
                        let lAmt = dynamicInfo.creditAmt + dynamicInfo.debitAmt
                        leftAmount = leftAmount + lAmt
                        dataToPush = {
                            groupId: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.id : "",
                            groupName: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.group_name : "",
                            amount: Math.abs(lAmt).toFixed(2),
                            resultL:dynamicInfo.groupDetail != null && dynamicInfo.groupDetail.result?dynamicInfo.groupDetail.result:[],
                            ...dataToPush
                        }
                    } else {
                        isDynamic = false

                        let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                        rIndex = rIndex + 1
                        if (Object.keys(dynamicInfo).length != 0) {
                            let lAmt = dynamicInfo.amt
                            leftAmount = leftAmount + lAmt
                            dataToPush = {
                                groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                                amount: Math.abs(lAmt).toFixed(2),
                                manufactureAndProfit:dynamicInfo.manufactureAndProfit!=null?dynamicInfo.manufactureAndProfit:"",
                                ...dataToPush
                            }
                        } else {
                            dataToPush = {
                                groupName: "",
                                amount: '',
                                ...dataToPush
                            }
                        }
                    }
                } else {

                        isDynamic = false

                        let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                        rIndex = rIndex + 1
                        if (Object.keys(dynamicInfo).length != 0) {
                            let lAmt = dynamicInfo.amt
                            leftAmount = leftAmount + lAmt
                            dataToPush = {
                                groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                                amount: Math.abs(lAmt).toFixed(2),
                                manufactureAndProfit:dynamicInfo.manufactureAndProfit!=null?dynamicInfo.manufactureAndProfit:"",

                                ...dataToPush
                            }
                        } else {
                            dataToPush = {
                                groupName: "",
                                amount: '',
                                ...dataToPush
                            }
                        }

                }

                rowList.push({...dataToPush, flag:0})
            });
            RightAlignProfitStatic.map((right, index) => {
                let lAmt = right.amt
                rightAmount = rightAmount + lAmt
                let dataToPush = {
                    groupNameR: right.name != null ? right.name : "",
                    amountL: Math.abs(lAmt).toFixed(2),
                    manufactureAndProfit:right.manufactureAndProfit!=null?right.manufactureAndProfit:"",

                }
                let dynamicInfo = LeftAlignProfitStatic[rIndex] ? LeftAlignProfitStatic[rIndex] : {}
                rIndex = rIndex + 1
                if (Object.keys(dynamicInfo).length != 0) {
                    let lAmt = dynamicInfo.amt
                    leftAmount = leftAmount + lAmt
                    dataToPush = {
                        groupName: dynamicInfo.name != null ? dynamicInfo.name : "",
                        amount: Math.abs(lAmt).toFixed(2),
                        manufactureAndProfit:dynamicInfo.manufactureAndProfit!=null?dynamicInfo.manufactureAndProfit:"",

                        ...dataToPush
                    }
                } else {
                    dataToPush = {
                        groupName: "",
                        amount: '',
                        ...dataToPush
                    }
                }
                rowList.push({...dataToPush, flag:0,})
            });
        } else {
            LeftAlign.map((right, index) => {
                let lAmt = right.creditAmt + right.debitAmt
                leftAmount = leftAmount + lAmt
                let dataToPush = {
                    groupName: right.groupDetail != null ? right.groupDetail.group_name : "",
                    groupId: right.groupDetail != null ? right.groupDetail.id : "",
                    amount: Math.abs(lAmt).toFixed(2),
                    resultL:right.groupDetail != null && right.groupDetail.result?right.groupDetail.result:[],
                }
                if (isDynamic) {
                    let dynamicInfo = RightAlign[index] ? RightAlign[index] : {}
                    if (Object.keys(dynamicInfo).length != 0) {
                        let lAmt = dynamicInfo.creditAmt + dynamicInfo.debitAmt
                        rightAmount = rightAmount + lAmt
                        dataToPush = {
                            ...dataToPush,
                            groupIdR: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.id : "",
                            groupNameR: dynamicInfo.groupDetail != null ? dynamicInfo.groupDetail.group_name : "",
                            amountL: Math.abs(lAmt).toFixed(2),
                            resultR:dynamicInfo.groupDetail != null && dynamicInfo.groupDetail.result?dynamicInfo.groupDetail.result:[],

                        }
                    } else {
                        isDynamic = false

                        let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                        rIndex = rIndex + 1
                        if (Object.keys(dynamicInfo).length != 0) {
                            let lAmt = dynamicInfo.amt
                            rightAmount = rightAmount + lAmt
                            dataToPush = {
                                ...dataToPush,
                                groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                                amountL: Math.abs(lAmt).toFixed(2),
                                manufactureAndProfit:dynamicInfo.manufactureAndProfit!=null?dynamicInfo.manufactureAndProfit:"",
                            }
                        } else {
                            dataToPush = {
                                ...dataToPush,
                                groupNameR: "",
                                amountL: '',

                            }
                        }
                    }
                } else {
                    {

                        isDynamic = false

                        let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                        rIndex = rIndex + 1
                        if (Object.keys(dynamicInfo).length != 0) {
                            let lAmt = dynamicInfo.amt
                            rightAmount = rightAmount + lAmt
                            dataToPush = {
                                ...dataToPush,
                                groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                                amountL: Math.abs(dynamicInfo.amt).toFixed(2),
                                manufactureAndProfit:dynamicInfo.manufactureAndProfit!=null?dynamicInfo.manufactureAndProfit:"",

                            }
                        } else {
                            dataToPush = {
                                ...dataToPush,
                                groupNameR: "",
                                amountL: '',
                            }
                        }

                    }
                }

                rowList.push({...dataToPush, flag:0,})
            });
            LeftAlignProfitStatic.map((right, index) => {
                let lAmt = right.amt
                    leftAmount = leftAmount + lAmt
                let dataToPush = {
                    groupName: right.name != null ? right.name : "",
                    amount: Math.abs(lAmt).toFixed(2),
                    manufactureAndProfit:right.manufactureAndProfit!=null?right.manufactureAndProfit:"",

                }
                let dynamicInfo = RightAlignProfitStatic[rIndex] ? RightAlignProfitStatic[rIndex] : {}
                rIndex = rIndex + 1
                if (Object.keys(dynamicInfo).length != 0) {
                    let lAmt = dynamicInfo.amt
                    rightAmount = rightAmount + lAmt
                    dataToPush = {
                        ...dataToPush,
                        groupNameR: dynamicInfo.name != null ? dynamicInfo.name : "",
                        amountL: Math.abs(lAmt).toFixed(2),
                        manufactureAndProfit:dynamicInfo.manufactureAndProfit!=null?dynamicInfo.manufactureAndProfit:"",


                    }
                } else {
                    dataToPush = {
                        ...dataToPush,
                        groupNameR: "",
                        amountL: '',
                    }
                }
                rowList.push({...dataToPush, flag:0,})
            });
        }
       
       let dataToPush = {
            groupName: "Total",
            amount: Math.abs(leftAmount).toFixed(2),
            groupNameR: "Total",
            amountL:Math.abs(rightAmount).toFixed(2),
            groupNameRLink: false,
            groupNameLink: false,
           
        }
        rowList.push({...dataToPush, flag:1,})
        setDetailList(rowList)
        showLoador({ loador: false });
        let DynamicData = Object.keys(data.data).length != 0 && data.data.dynamicData.length != 0 ? data.data.dynamicData : [];
        let staticData = Object.keys(data.data).length != 0 && data.data.staticData.length != 0 ? data.data.staticData : [];
        setMFGList({ DynamicData: DynamicData, staticData: staticData })
      
    } else {
        setDetailList([])
        showLoador({ loador: false });
        showNotification({
            msg: serverMsg,
            severity: "error",
        });
    }

});
    }
    return (
        <>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

                <DynamicFormWithoutSave
                    PeriodData={formData.fromDate ? formData.fromDate + " To " + formData.toDate : ""}
                    dynamicMasterData={dynamicMasterData}
                    showBackToList={false}
                    DataToSet={[]}
                    fieldData={fieldData}
                    showSaveBtn={balanceSheetJson.showSaveBtn}
                    screenTitle={balanceSheetJson.screenTitle}
                    fieldMeta={balanceSheetJson.fieldMeta}
                    showCancel={balanceSheetJson.showCancel}
                    apiBaseURL={balanceSheetJson.apiBaseURL}
                    showSaveNextBtn={balanceSheetJson.showSaveNextBtn}
                    showSaveBtnMain={true}
                    padding={false}
                    generatePDF={searchFlag?generateDetailPDFMethod:generatePDFMethod}
                    generateExcel={searchFlag?generateDetailExcelMethod:generateExcelMethod}
                    onSearch={onSearch}
                    saveBtnText={"Search"}
                    paddingTop={false}
                    onSave={onSave}
                    showExcelDownload={true}
                    showPDFDownload={true}
                    detailButton={true}
                    onSaveDetail={showDetail}
                />
                <br />

                {searchFlag?( 
                    <>
              <BalanceSheetTable tableHead={[
          {
            title: "Liabilities",

            align: "left"
          },
          {
            title: "Assets",
            align: "left"
          },
          
        ]} rowList={detailList}
        formData={formData} 
        getDeatilList={getDeatilList}/>

                </>
                ):(
                    <>
                <RTTable
                    showPegination={false}
                    columns={[
                        { name: "groupName", title: "Liabilities", linkFlag: true, linkName: "groupNameLink" },
                        { name: "amount", title: "Amount", fontWeight: "bold", align: "right" },
                        { name: "groupNameR", title: "Assets", linkFlag: true, linkName: "groupNameRLink" },
                        { name: "amountL", title: "Amount", fontWeight: "bold", align: "right" }
                    ]}
                    hiddenColumnNames={[]}
                    tableData={getFilteredTableData(cashList)}
                    rowLink={rowLink}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                />
               </>
                )}
                {openTable && (
                    <GroupSummarySubReportByGroup
                        open={openTable}
                        handleCloses={toggle}
                        popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                        dynamicMasterData={formData}
                    />
                )}
                {openMFGAndProfitAndLoss && (
                    <MFGAndPL
                        rowLink={rowLink}
                        open={openMFGAndProfitAndLoss}
                        handleCloses={toggleMFGAndProfitAndLoss}
                        popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                        formData={formData}

                    />
                )}
            </Paper>
            {
                cashList.length != 0 && <Grid sx={{ paddingTop: 2 }} container spacing={1} justifyContent="center">
                    <Grid container xs={12} sm={12} md={10} lg={10}>
                    <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={"Liabilities"}
                                    />
                                </div>

                                <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList.filter((row)=>row.flag==0 && row.amount!="" ).map((rowData) => {
                                
                              
                                return rowData.amount
                               
                                  
                                
                                
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                            //   onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList.filter((row)=>row.flag==0 && row.groupName!="" ).map((rowData,index) => {
                               console.log(rowData)
                                index=index+1;
                              
                                return rowData.groupName  
                                
                            }),
                        }, 
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />
                                
                            </Paper>
                        </Grid>
                        <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>

                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={"Assets"}
                                    />
                                </div>

                               
                    {/* Sales & Manpower Table 2 starts */}
                    <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList.filter((row)=>row.flag==0 && row.amountL!="" ).map((rowData) => {
                               
                                return rowData.amountL
                               
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                            //   onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList.filter((row)=>row.flag==0 && row.groupNameR!="" ).map((rowData,index) => {
                                index=index+1;
                             
                                return rowData.groupNameR
                             
                                
                               
                                   
                                
                                
                            }),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />

                    {/* Sales & Manpower Table 2 end */}
                 


                                {/* <BarChart
                                    series={[{
                                        data: cashList.map((rowData) => {
                                            return rowData.amount
                                        }),
                                    }, {
                                        data: cashList.map((rowData) => {
                                            return rowData.amountL
                                        }),
                                    }]}
                                    options={
                                        {
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },
                                            stroke: {
                                                show: false,
                                                width: 2,
                                                colors: ['transparent']
                                            },
                                            xaxis: {
                                                categories: cashList.map((rowData) => {
                                                    return rowData.groupName
                                                }),
                                            },
                                            fill: {
                                                opacity: 1
                                            },
                                        }
                                    }
                                /> */}
                            </Paper>
                        </Grid>
                        
                    </Grid>
                </Grid>
            }

        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BalanceSheet);
