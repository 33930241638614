import React from "react";
import { connect } from "react-redux";
import { salePurchaseHistotyJSON } from "../../DynamicFormsJson/Report/SalePurchaseHistory";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg} from "../../config/messageconstant";
import { salePurchaseHistoryColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";


import { apiGet } from "../../utils/api_service";

class PurchaseSaleHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        dynamicMasterData: {
            entryTypeDetails: [
              { id: "1", name: "Sale" },
              { id: "2", name: "Purchase" },
            ],
            
          },
    rowList:[],
    openTable:false,
    formData:{},
    };
  }

 onSearchData = async (data) => {
    const { showLoador, showNotification } = this.props;
  
    console.log(data);
    console.log(data.todate)
    const locationId = localStorage.getItem("location");
    if (navigator.onLine) {
       
      showLoador({ loador: true });
      if (data != null) {
        showLoador({ loador: true });
        apiGet({
          
         
          url:
            endpoint.purchaseSaleHeader+"/by-date-and-entry-type?locationId="+locationId+"&fromDate="+data.fromdate+"&toDate="+data.todate+"&entryType="+data.entryType,
        }).then(({ data, success }) => {
         
          showLoador({ loador: true });
          if (success) {
          
           const rowList = data.data.length!=0?data.data.map((row,index) => {
             
             index=index+1
              return {
                
                index:index,
                ...row,
                accountLedgerName:row.accountLedgerName!=null?row.accountLedgerCode!=null?row.accountLedgerName+"("+row.accountLedgerCode+")":row.accountLedgerName:"",
              };
            }):[];
            console.log("rowList")
            console.log(rowList)
           
           
            this.setState({
              rowList: rowList,
              });
            showLoador({ loador: false })
          }else{
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
            showLoador({ loador: false })
          }
        });
       
         
        };
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  
 
  
  
 

  rowView = (rowData) => {
   
    window.location.replace("/sale-purchase-view/"+ rowData.id);
  };
  toggle = () =>  {
   
    this.setState({
      openTable:!this.state.openTable,
      
       
      
    })
    };
  render() {
    const { dynamicMasterData,rowList } = this.state;
  
    return (
      <>
         <DynamicMainScreen
          showDeleteIcon={false}
          dynamicMasterData={dynamicMasterData}
          searchList={salePurchaseHistotyJSON.searchList}
          onSearchData={this.onSearchData}
          formPathView={salePurchaseHistotyJSON.formPathView}
          formPath={salePurchaseHistotyJSON.formPath}
          screenTitle={salePurchaseHistotyJSON.screenTitle}
          fieldMeta={salePurchaseHistotyJSON.fieldMeta}
          getListById={this.getListById}
          showPdfDownload={false}
          showExcelDownload={true}
          isActiveURL={salePurchaseHistotyJSON.isActiveURL}
          showAddButton={false}
          tableColumnsToFilter={salePurchaseHistotyJSON.tableColumnsToFilter}
          tableColumns={salePurchaseHistoryColumns}
          tableData={rowList}
          apiBaseURL={salePurchaseHistotyJSON.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={false}
          rowPDF={this.rowPDF}
          showPdf={false}
          showEditIcon={false}
          showViewButtonInTable={true}
          showPegination={false}
          isNote={false}
          GridSave={1.5}
          buttonCenter={false}
         
         
        />
        
      </>
    );
  }
}

const mapStateToProps = (state) => ({
 
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  
  
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(PurchaseSaleHistory);