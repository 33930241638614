import React from "react";


export const LabelCompo = ({ label, style, onClick, className, isMandatory =false}) => {
  return <label id="amount" style={style} onClick={onClick} className={className}>
    {label}
    {
      isMandatory && <span style={{color:"#ff0000"}}>
        *
      </span>
    }
  </label>;
}