import React,{useEffect} from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";

const CashFlowSummaryTable = ({ rowList = [], tableHead = [], rowLink, classes }) => {


  useEffect(() => {
    console.log(rowList)
}, []);

    return (
        <>
            <div style={{ overflow: "auto" }}>
                <table className={classes.MuiTableWithoutBorder}>
                    <tr >
                        {
                            tableHead.map((head) => {
                                return (<td style={{ textAlign: "center" }} className={classes.MuiTableHeadCell} >{head.title}</td>)
                            })
                        } </tr>
                    {

                        rowList.map((data,index) => {

                            return (
                                <>
                                    <tr>
                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{ fontWeight: fontWeight, fontSize: 20,textDecoration: "underline" }}>
                                            {data.name}
                                        </td>

                                    </tr>
                                    {
                                        data.groupList.map((group) => {
                                         console.log("group")
                                         console.log(group)
                                            return (
                                                <>
                                                    <tr>
                                                        {
                                                            group.groupNameLink == undefined || group.groupNameLink ?
                                                                <td style={{
                                                                    cursor: "pointer",
                                                                    color:"#000",
                                                                    fontWeight:fontWeight,
                                                                }} onClick={(e) => rowLink(group, "groupName",index)} className={classes.MuiTableBodyCellWithoutBorder} >
                                                                    {group.groupName}
                                                                </td>
                                                                : <td className={classes.MuiTableBodyCellWithoutBorder} style={{ color:"#000",fontWeight:fontWeight,}} >
                                                                    {group.groupName}
                                                                </td>
                                                        }

                                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{textAlign:"right"}}>
                                                            {group.amount}
                                                        </td>

                                                    </tr>
                                                    {
                                    group.list && group.list.length!=0 && group.list.map((data) => {
                                        let creditTotal = 0
                                        let debitTotal = 0
                                            let debitAmt = data.openingDebitAmount+data.yearDebitAmount+data.openingCreditAmount+ data.yearCreditAmount
                                           if(debitAmt<0){
                                            debitTotal = debitTotal + debitAmt
                                           }else{
                                            creditTotal = creditTotal + debitAmt
                                           }
                                           

                                            



                                            return (
                                                <>
                                                    <tr>
                                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{ textAlign: "left" }}>
                                                            <>
                                                                {data.accountLedgerCode}
                                                            </>

                                                        </td>
                                                        <td className={classes.MuiTableBodyCellWithoutBorder}>
                                                            <>
                                                                {data.accountLedgerName}

                                                            </>

                                                        </td>

                                                        {/* <td className={classes.MuiTableBodyCellWithoutBorder} style={{ textAlign: "right" }}>
                                                            <>
                                                                {debitAmt< 0 ?Math.abs(debitAmt).toFixed(2):0.00}
                                                            </>

                                                        </td>
                                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{ textAlign: "right", textColor: "#000" }}>
                                                            <>
                                                            {debitAmt > 0 ?Math.abs(debitAmt).toFixed(2):0.00}
                                                            </>

                                                        </td> */}
                                                    </tr>


                                                </>
                                            )
                                        
                                        })
                        
                                    }
                                               
                                                </>

                                            )
                                        })
                                    }
                                         

                                </>
                            )
                        })}

                </table>
            </div>
        </>
    );
};

export default withStyles(styles)(CashFlowSummaryTable);

