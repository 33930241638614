import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { LabelCompo } from "./Comman/Label";
import { Paper, Grid } from "@mui/material";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import { blackColor, greyColor, primaryColor } from "../config/ColorObj";

export default function CustomizedTimeline({ onClickUser, timelineList, documentNo }) {
  return (
    <>
      <Grid sx={{ padding: 1, paddingTop: 3 }} item xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LabelCompo
            className="text-black"
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: primaryColor, // Assuming primaryColor is a variable
            }}
            label={documentNo ? documentNo : "-"}
          />
        </Grid>
      </Grid>
      <Timeline sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}>
        {timelineList.length != 0 && timelineList.map((timeline) => {
          let timeArray = timeline.insertedDateTime != "" ? timeline.insertedDateTime.split(" ") : [];

          let date = timeArray.length != 0 ? timeArray[0] : "";
          let time = timeArray.length != 0 ? timeArray[1] : "";
          // let time = timeArray.length != 0  ? moment(timeArray[0]).format("hh:mm:ss A") : ""
          console.log(time)
          console.log(timeArray)

          return (


            <TimelineItem>

              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <div style={{ flexDirection: "column" }}>

                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 14, fontWeight: 400, color: blackColor }}
                    label={date}
                  />
                  <br />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 12, fontWeight: 400, color: greyColor }}
                    label={time}
                  />
                </div>


              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{
                  backgroundColor: '#295FA6'
                }}>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  <LabelCompo
                    onClick={() => {
                      onClickUser(timeline)
                    }}
                    className="text-black"
                    style={{ fontSize: 14, cursor: "pointer", fontWeight: 400, color: primaryColor }}
                    label={timeline.insertedUserName}
                  />

                </Typography>
                {/* <Typography>Because you need strength</Typography> */}
              </TimelineContent>
            </TimelineItem>

          )

        })}
      </Timeline>
    </>
  );
}
