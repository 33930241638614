import { Grid, Paper, Switch, Tooltip } from "@mui/material";
import React, { Component } from "react";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import Search from "../../components/Search";
import { FontAwesomeIconCompo, MyComponentWithIconProps } from "../Comman/IconButton";
import { ButtonCompo } from "./Button";
import RTSelectMultiple from "./RT/RTSelectMultiple";
import { withRouter } from "../withRouter";
import { compose } from "@reduxjs/toolkit";

import { connect } from "react-redux";
class LandingScreenHeader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      role: "",
    };
  }
  componentDidMount() {
    let role = localStorage.getItem("role");
    if (role) {
      this.setState({
        role: role,
      });
    }
  }
  onAddButtonClick = () => {
    this.props.onAddButtonClick();
  };

  onSearch = (searchvalue) => {
    this.props.onSearch(searchvalue);
  };

  render() {
    const {
      screenTitle,
      subTitle,
      previewText,
      timeData,
      amount,
      importExcel=false,
      importExcelData,
      showPdf = false,
      showExcelDownload = true,
      showPdfDownload=false,
      showAddButton = true,
      onRTSelectMultipleChange,
      tableColumnsToFilter = [],
      searchValue,
      generatePDF,
      generateExcel,
      showTable,
      showSearchBox = true,
      showSearch = true,
      showSwitchButton = false,
      handleChange,
      checked,
      title,
      showEdit = false,
      showDelete = false,
      rowEdit,
      rowDelete,
      rowReset,
      showReset = false,
      addTitle = "",
      showBackButton = false,
      fullWidthButton = false,
      onCancel,
    } = this.props;
    return (
      <>
        <div>
          {screenTitle !== "" && (
            <Paper sx={{ borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                direction="row"
                justifyContent="space-between"
              >
                <TitleAndBreadCrum showEdit={showEdit}
                  showDelete={showDelete}
                  rowEdit={rowEdit}
                  showPdf={showPdf}
                  generatePDF={generatePDF}
                  rowDelete={rowDelete}showBackButton={showBackButton} showReset={showReset} rowReset={rowReset} title={screenTitle} timeData={timeData}previewText={previewText} subTitle={subTitle} amount={amount} />
              </Grid>
            </Paper>
          )}

          {showTable && (
            <>
              {showSearch && (
                <>


                  <Grid sx={{ marginBottom: 1 }} justifyContent={"center"} alignItems={"center"} container spacing={3}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={4}
                      lg={3}
                    >
                      {showSearchBox && (
                        <Search
                          sx={{ width: "100%" }}
                          searchValue={searchValue}
                          filteredRows={this.onSearch}
                        />
                      )}
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      md={8}
                      lg={9}
                    >
                      <Grid sx={{ alignItems: "center" }} container justifyContent="flex-end" item md xs>
                        {showSwitchButton && (
                          <div style={{ padding: "10px" }}>
                            <Tooltip placement="bottom" title={title}>
                              <Switch
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ m: -1 }}
                              />
                            </Tooltip>
                          </div>
                        )}
                        
                        {importExcel && (
                          <>
                            <FontAwesomeIconCompo
                              color="primary"
                              fontSize="small"
                              title="Import Excel"
                              baseClassName="fas"
                              className="fa-file-excel"
                              onClick={importExcelData}
                            />
                            &emsp;
                          </>

                        )}
                        {showPdfDownload && (
                          <>
                            <FontAwesomeIconCompo
                              color="error"
                              fontSize="small"
                              title="PDF"
                              baseClassName="fas"
                              className="fa-file-pdf"
                              onClick={generatePDF}
                            />
                            &emsp;
                          </>

                        )}

                        {showExcelDownload && (
                          <>

                            <FontAwesomeIconCompo
                              color="primary"
                              fontSize="small"
                              title="Excel"
                              baseClassName="fas"
                              className="fa-file-excel"
                              onClick={generateExcel}
                            />
                            &nbsp;
                          </>
                        )}

                      </Grid>
                      {tableColumnsToFilter?.length > 0 && (
                        <Grid
                          item
                          justifyContent="flex-end"
                          xs={6}
                          md={6}
                          lg={6}
                        >
                          <RTSelectMultiple
                            options={tableColumnsToFilter}
                            onChange={onRTSelectMultipleChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default LandingScreenHeader;
