import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";

export default function Highlights({
  sx,
  name,
  options,
  onChange,
  label = "",
  placeHolder = "",
  value = "",
  keyColName = "",
  isError = false,
  errorText = " ",
  disabled,
  filterOptions,
}) {
  return (
    <Autocomplete
   
      id={name}
      sx={sx}
      size="small"
      
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option && option.name}
      renderInput={(params) => (
        
        <TextField
       
          {...params}
          placeholder={placeHolder}
          
          variant="standard"
          margin="0px"
          // label={label}
         
          error={isError}
          helperText={errorText}
        />
      )}
      name={name}
      onChange={(event, newValue) => {
        onChange(name, newValue && newValue.id);
        // setValue(newValue);
      }}
      disabled={disabled}
      value={
        keyColName &&
          options.filter((item) => item[keyColName] === value).length > 0
          ? options.filter((item) => item[keyColName] === value)[0]
          : ""
      }
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <li {...props}  style={{ fontSize: '12px !important'  }}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 600 : 400, 
                    fontSize:"12px"
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}
