import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class DayWiseBeatAssignView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      beatAssignList: [],
      salesTeamId: "",
      
      matrixData: [],
      dayList: [
        {
          dayId: 0,
          name: "Monday",
        },
        {
          dayId: 1,
          name: "Tuesday",
        },
        {
          dayId: 2,
          name: "Wednesday",
        },
        {
          dayId: 3,
          name: "Thursday",
        },
        {
          dayId: 4,
          name: "Friday",
        },
        {
          dayId: 5,
          name: "Saturday",
        },
        {
          dayId: 6,
          name: "Sunday",
        },
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setMatrix(nextProps.beatList?.beat);
      }
    }

  }
  setMatrix = (beatList) => {
    const { dayList, beatAssignList } = this.state;
    const matList = dayList.map((dayObj) => {
      let matObj = {
        ...dayObj,
      };
      let filterDay = [];
      if (beatAssignList.length != 0) {
        filterDay = beatAssignList.filter(
          (rowData) => dayObj.dayId == rowData.day
        );
      }
      const beatListData = beatList.map((beatObj) => {
        let filterBeat = [];
        if (filterDay.length != 0) {
          filterBeat = filterDay[0].beatAssignDaywiseDetailBeat.filter(
            (rowData) => beatObj.beatId == rowData.beat.id
          );
        }
        let beatDataObj = {
          ...beatObj,
          beatName: beatObj.name,
          checked: filterBeat.length != 0 ? true : false,
          disabled: true,
        };
        if (filterBeat.length != 0) {
          beatDataObj = {
            ...beatDataObj,
            id: filterBeat[0].id,
          };
        }
        return beatDataObj;
      });
      matObj = {
        ...matObj,
        beatListData: beatListData,
      };
      if (filterDay.length != 0) {
        matObj = {
          ...matObj,
          id: filterDay[0].id,
        };
      }
      return matObj;
    });
    this.setState({
      matrixData: matList,
    });
  };
  async componentDidMount() {
    this.setMatrix(this.props.headList, this.props.detailList);
  }

  onSearch = (data) => {
    const { getBeatBySalesTeamId, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.setState({
        salesTeamId: data.salesTeam.id,
      });
      showLoador({ loador: true });
      getBeatBySalesTeamId({
        salesTeamId: data.salesTeam.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { headList, detailList } = this.props;
    return (
      <>



        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: "10%" }}>
                  Month
                </TableCell>
                {headList.map((rowData) => {
                  return (
                    <>
                      <TableCell align="center" >

                        {
                          "Taxable " + rowData.tax_per + " %"}
                      </TableCell>
                      <TableCell align="center" >
                        {
                          "CGST " + rowData.cgst_per + " %"}
                      </TableCell>
                      <TableCell align="center" >
                        {
                          "SGST " + rowData.sgst_per + " %"}
                      </TableCell>
                      <TableCell align="center" >
                        {
                          "IGST " + rowData.igst_per + " %"}
                      </TableCell>
                    </>)
                })
                }
                <TableCell align="center" >
                  Cess
                </TableCell>

                <TableCell align="center" >
                  Total
                </TableCell>
                <TableCell align="center" >
                  Tot.Taxable
                </TableCell>
                <TableCell align="center" >
                  Tot.CGST
                </TableCell>
                <TableCell align="center" >
                  Tot.SGST
                </TableCell>
                <TableCell align="center" >
                  Tot.IGST
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailList.map((matrixDataRow, indexRow) => {
                return (
                  <TableRow>
                    <TableCell>{matrixDataRow.monthYear}</TableCell>
                    {headList.map((rowData) => {
                      let filterData = matrixDataRow.dtoGetPurchaseSalesReportDetail.filter((rowInfo) => rowInfo.taxPercentage == rowData.tax_per)
                      if (filterData.length != 0) {
                        return (
                          <>
                            <TableCell align="right">
                              {filterData[0].taxableAmt.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {filterData[0].cgstAmt.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {filterData[0].sgstAmt.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {filterData[0].igstAmt.toFixed(2)}
                            </TableCell>
                          </>

                        );
                      } else {
                        return (
                          <>
                            <TableCell align="right">
                              0.00
                            </TableCell>
                            <TableCell align="right">
                              0.00
                            </TableCell>
                            <TableCell align="right">
                              0.00
                            </TableCell>
                            <TableCell align="right">
                              0.00
                            </TableCell>

                          </>
                        )


                      }

                    })
                    }

                    <TableCell align="right">{matrixDataRow.cessAmt.toFixed(2)}</TableCell>
                    <TableCell align="right">{matrixDataRow.totalAmt.toFixed(2)}</TableCell>
                    <TableCell align="right">{matrixDataRow.taxableAmt.toFixed(2)}</TableCell>
                    <TableCell align="right">{matrixDataRow.cgstAmt.toFixed(2)}</TableCell>
                    <TableCell align="right">{matrixDataRow.sgstAmt.toFixed(2)}</TableCell>
                    <TableCell align="right">{matrixDataRow.igstAmt.toFixed(2)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  beatList: state.beat,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DayWiseBeatAssignView);
