import dayjs from "dayjs";

export const LedgerJson = {
    screenTitle: "Ledger",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta: [
      {
        label: "Date Range",
        controlType: "datepicker",
        placeHolder: "",
        md: 4,
        lg: 4,
        sm: 4,
        xs: 12,
        labelSM: 5,
        labelMD: 5,
        labelLG: 5,
        valueSM: 7,
        valueMD: 7,
        valueLG: 7,
        showLabel:true,
        error:"From Date",
        dataKey: "fromDate",
        isMandatory: true,
        onSubmit: "compare",
        onSubmitParameter: "toDate-le",
      },
      {
        label: "",
        controlType: "datepicker",
        placeHolder: "",
        md: 2.5,
        lg: 2.5,
        sm: 2.5,
        xs: 12,
        hideLabel:true,
        labelSM: 1,
        labelMD: 1,
        labelLG: 1,
        valueSM: 11,
        valueMD: 11,
        valueLG: 11,
        dataKey: "toDate",
        onSubmit: "compare",
        error:"To Date",
        onSubmitParameter: "fromDate-ge",
        isMandatory: true,
        showLabel:true,
        onSubmit: "compare",
        defaultValue: dayjs(new Date()),
      },
      {
        label: "Account",
        controlType: "multiSelect",
        placeHolder: "Select Account",
        md: 11,
        lg: 11,
        sm: 11,
        xs: 12,
        labelSM: 1.8,
        labelMD: 1.8,
        labelLG: 1.8,
        valueSM: 10.2,
        valueMD: 10.2,
        valueLG: 10.2,
        error:"Account",
        masterName: "accountLedgerGroupMasterDetail",
      
        dataKey: "account",
        isRootLevelKey: true,
        isMandatory: true,
      },
    ]
  };
  