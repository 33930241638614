import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { subVoucherDetailJSON } from "../../../DynamicFormsJson/MastersJSON/subVoucherDetail";
import { getAccountLedgerList } from "../../../Slice/subVoucherDetail.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicFormForDocumentUpload from "../../../components/dynamicscreens/DynamicFormForDocumentUpload";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
// import GoogleMap from "./GoogleMap";
class SubVoucherDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        ledgerAccountDetails: this.props.subVoucherDetailList?.accountLedger,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { openMap } = this.state;
    if (this.props.subVoucherDetailList !== nextProps.subVoucherDetailList) {
      if (
        this.props.subVoucherDetailList?.accountLedger !==
        nextProps.subVoucherDetailList?.accountLedger
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            ledgerAccountDetails: nextProps.subVoucherDetailList?.accountLedger,
          },
        });
      }
    }
  }

  async componentDidMount() {
    if (this.props.params.id == undefined) {
      this.setState({
        showLocation: true,
      });
    }
    const {
      getOutletType,
      getAccountLedgerList,
      getStateList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAccountLedgerList().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onCancel = () => {
    this.props.navigate(-1);
  };

  onSaveLocation = () => {
    this.setState({
      openMap: true,
    });
  };

  onRadioChange = (e) => {
    const { value } = e.target;
    this.setState({
      locationValue: value,
    });
    if (value == 1) {
      this.getCurrentLocationData();
    }
  };

  setDataToForm = (data) => {
    const { latitute, longitute } = this.state;
    if (latitute == "" && longitute == "") {
      this.setState({
        latitute: data.latitude,
        longitute: data.longitude,
      });
    }
  };

  render() {
    const {
      dynamicMasterData,
      openMap,
      latitute,
      longitute,
      showLocation,
      locationValue,
    } = this.state;

    return (
      <>
        <>
          <Paper
            sx={{
              p: 2,
              pt: 2,
              borderRadius: 2,
            }}
            elevation={0}
          >
            <LandingScreenHeader
              screenTitle={subVoucherDetailJSON.screenTitle}
              showTable={false}
              showBackButton={true}
              showAddButton={false}
              onCancel={this.onCancel}
            />
            <br />

            <br />
            
            <DynamicForm
              getDataForm={false}
              setDataToForm={this.setDataToForm}
              getExtraData={false}
              // getData={this.getLocationData}
              padding={false}
              paddingTop={false}
              showBackToList={false}
              formPath={subVoucherDetailJSON.formPath}
              fieldMeta={subVoucherDetailJSON.fieldMeta}
              dynamicMasterData={dynamicMasterData}
              apiBaseURL={subVoucherDetailJSON.apiBaseURL}
              showSaveNextBtn={subVoucherDetailJSON.showSaveNextBtn}
              paramKey={subVoucherDetailJSON.paramKey}
            />
          </Paper>
        </>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
});
const mapDispatchToProps = {
  getAccountLedgerList,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SubVoucherDetailForm);
