import endpoint from "../../config/endpoints";

export const subVoucherJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.subVoucher,
  formPath: "/sub-voucher-form",
  openFormPath: "/column-form",
  screenTitle: "Sub-Voucher ",
  showAddButton: true,
  showDeleteIcon: false,
  showPdfDownload: true,
  pdfFileName: "Sub-Voucher",
  showExcelDownload: true,
  excelFileName: "Sub-Voucher",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Code",
      columnKeyName: "subVoucherCode",
      isChecked: true,
    },
    {
      columnDisplayName: "Name",
      columnKeyName: "subVoucherName",
      isChecked: true,
    },
    {
      columnDisplayName: "Voucher",
      columnKeyName: "voucherName",
      isChecked: true,
    },
    {
      columnDisplayName: "Account Posting",
      columnKeyName: "accountPosting",
      isChecked: true,
    },
    {
      columnDisplayName: "Stock Update",
      columnKeyName: "stockUpdate",
      isChecked: true,
    },
    {
      columnDisplayName: "Auto Approve",
      columnKeyName: "autoApprove",
      isChecked: true,
    },
  ],

  fieldMetaToSearch: [],
  fieldMeta: [
    {
      label: "Code",
      controlType: "textfield",
      placeHolder: "Code",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "subVoucherCode",
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "subVoucherName",
      isMandatory: true,
    },
    {
      label: "Voucher",
      controlType: "autocomplete",
      placeHolder: "Select Voucher",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "voucherDetails",
      dataKey: "voucherMaster",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Account Posting",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "accountPosting",
      tableDataKey: "accountPosting",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Stock Update",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "stockUpdate",
      tableDataKey: "stockUpdate",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Auto Approve",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "autoApprove",
      tableDataKey: "autoApprove",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Voucher Nature",
      controlType: "autocomplete",
      placeHolder: "Select Voucher",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "voucherNatureDetails",
      dataKey: "vouNature",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
