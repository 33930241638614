import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CashbalanceJson } from "../../DynamicFormsJson/Report/CashBalance";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CashbookTable from "./CashbookTable";
import RTTable from "../../components/Comman/RT/RTTable";
import moment from "moment";
import CashbookDateWise from "./CashbookDateWise";
import CashBalanebyDate from "./CashBalanebyDate";
import { getAccountLedgerListByType } from "../../Slice/subVoucherDetail.slice";

const CashBalance = ({
  getAccountLedgerListByType,
  showLoador,
  showNotification,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [cashbook, setCashbookList] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [popUpData, setPopupdata] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [popUpDataOfDate, setPopupdataOfDate] = useState({});
  const [openTableOfDate, setOpenTableOfDate] = useState(false);
  const [fieldData, setFieldData] = React.useState({});

  useEffect(() => {
    getDropDownList();
  }, []);
  const getDropDownList = async () => {
    showLoador({ loador: true });
    const accountLedgerGroupMasterDetailForType1 =
      await getAccountLedgerListByType({
        accType: 1,
      }).then(({ response, success }) => {
        if (success) {
          return response.map((rowData) => {
            showLoador({ loador: false });
            return { ...rowData, id: rowData.id1 };
          });
        } else {
          showLoador({ loador: false });
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        return [];
      });
    showLoador({ loador: true });
    const accountLedgerGroupMasterDetailForType2 =
      await getAccountLedgerListByType({
        accType: 2,
      }).then(({ response, success }) => {
        if (success) {
          showLoador({ loador: false });
          return response.map((rowData) => {
            return { ...rowData, id: rowData.id1 };
          });
        } else {
          showLoador({ loador: false });
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        return [];
      });
    showLoador({ loador: true });
    const accountLedgerGroupMasterDetailForType0 =
      await getAccountLedgerListByType({
        accType: 0,
      }).then(({ response, success }) => {
        if (success) {
          showLoador({ loador: false });
          return response.map((rowData) => {
            return { ...rowData, id: rowData.id1 };
          });
        } else {
          showLoador({ loador: false });
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        return [];
      });
    showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoint.company,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data;
        const location=localStorage.getItem("location");
        const locationList =localStorage.getItem("locationIdList");
       const locallist=JSON.parse(locationList)
       const locationlist=locallist.filter((data)=>data.id==location)
        console.log("location")
         console.log(locationlist) 
          
         
           if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
             
             setFieldData({ companyId: locationlist[0].company.id })
           }
        showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.companyName };
        });
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    setDynamicMasterData({
      accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetailForType1,
      accountLedgerGroupMasterDetailForType1:
        accountLedgerGroupMasterDetailForType1,
      accountLedgerGroupMasterDetailForType2:
        accountLedgerGroupMasterDetailForType2,
      accountLedgerGroupMasterDetailForType0:
        accountLedgerGroupMasterDetailForType0,
      CompanyDetails: CompanyDetails,
    });
  };
  const getList = (fromDate, toDate, companyIds, accountLedgerId) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.cashbalance +
        "/monthwise-cashbalance?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&accountLedgerId=" +
        accountLedgerId +
        "&companyId=" +
        companyIds,
    }).then(({ data, success }) => {
      if (success) {
        let closingAmount = 0;
        let openingAmount = 0;
        let index = 0
       let list = []
       data.data.map((rowData,indexData) => {
          if (indexData == 0) {
            closingAmount =
              rowData.openingAmount + rowData.creditTotal + rowData.debitTotal;
            openingAmount = rowData.openingAmount;
          } else {
            openingAmount = closingAmount;
            closingAmount =
              closingAmount + rowData.creditTotal + rowData.debitTotal;
          }
          if (rowData.debitTotal != 0 || rowData.creditTotal != 0) {
            index=index+1
            list.push({
              index:index,
              monthYearName: rowData.monthYear!=null?moment(rowData.monthYear, "MM-yyyy").format(
                "MMM-yyyy"
              ):"-",
              monthYear: rowData.monthYear,
              openingAmount: Math.abs(openingAmount).toFixed(2),
              openingAmountToCal: openingAmount,
              creditTotal: Math.abs(rowData.debitTotal).toFixed(2),
              debitTotal: Math.abs(rowData.creditTotal).toFixed(2),
              closingBalance:
                closingAmount >= 0
                  ? Math.abs(closingAmount).toFixed(2) + " Cr"
                  : Math.abs(closingAmount).toFixed(2) + " Dr",
              id: rowData.id,
            }) ;
          }
        });
        console.log("list")
        console.log(list)
        showLoador({ loador: false });
        setCashbookList(list);
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };
  const rowLink = (rowData) => {
    setPopupdata(rowData);
    setOpenTable(!openTable);
  };
  const rowLinkForDate = (rowData) => {
    setPopupdataOfDate(rowData);
    setOpenTableOfDate(!openTableOfDate);
  };

  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };
  
  const generatePDFMethod = () => {
    var columns = [
      ["Sr.No", "Month", "Op.Balance", "Reciept", "Payments", "CL.Balance"],
    ];
    let tableList = [];
    console.log("cashbook")
    console.log(cashbook)
    cashbook.map((rowData, index) => {
      let tableData = [];
      // let monthYearName= moment(rowData.monthYear, "MM-yyyy").format(
      //   "MMM-yyyy"
      // )
      tableData.push(index + 1);
      tableData.push(rowData.monthYearName);

      tableData.push(Math.abs(rowData.openingAmount));
      tableData.push(Math.abs(rowData.creditTotal));
      tableData.push(Math.abs(rowData.debitTotal));
      tableData.push(rowData.closingBalance);

      tableList.push(tableData);
    });
    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate,
        },
        {
          label: "Company Name",
          value: formData.companyName,
        },
        {
          label: "Account Name",
          value: formData.accName,
        },
      ],
      pdfTitle: CashbalanceJson.screenTitle,
    });
  };

  const getFilteredTableData = (tableData) => {
    if (tableData.length != 0) {
      let listData = tableData.filter((row) => {
        let isValid = false;
       
     
        if(row!=null){
          if (
            row.monthYearName
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
          }
          if (
            row.closingBalance
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
          }
          if (
            row.creditTotal
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
          }
          if (
            row.debitTotal
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
          }
          if (
            row.openingAmount
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
          }
          return isValid;
        }
      
      });
      return listData;
    }
  };
  const generateExcelMethod = () => {
    var columns = [
      "Sr.No",
      "Month",
      "Op.Balance",
      "Reciept",
      "Payments",
      "CL.Balance",
    ];
    let alignList = [];

    let rows = [];

    cashbook.map((reportData, index) => {
     
      let reportObj = [index + 1];
      reportObj.push(reportData.monthYearName);
      reportObj.push(Math.abs(reportData.openingAmount));
      reportObj.push(Math.abs(reportData.creditTotal));
      reportObj.push(Math.abs(reportData.debitTotal));
      reportObj.push(reportData.closingBalance);
      rows.push(reportObj);
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Date Range ",
        formData.fromDate + " To " + formData.toDate,
        "Company Name",
        formData.companyName,
        "Account Name",
        formData.accName,
      ],
      title: CashbalanceJson.screenTitle,
    });
  };
  const onSave = (dataToSearch) => {
    let filterDta = dynamicMasterData["CompanyDetails"].filter(
      (row) => row.id === dataToSearch.companyId
    );
    let filteraccountLedgerGroupMaster = dynamicMasterData[
      "accountLedgerGroupMasterDetail"
    ].filter((row) => row.id === dataToSearch.accountLedgerGroupsId);

    setFormData({
      ...dataToSearch,
      companyName: filterDta.length != 0 ? filterDta[0].name : "",
      accName:
        filteraccountLedgerGroupMaster.length != 0 ? filteraccountLedgerGroupMaster[0].name : "",
    });

    getList(
      dataToSearch.fromDate,
      dataToSearch.toDate,
      dataToSearch.companyId,
      dataToSearch.accountLedgerGroupsId
    );
  };

  const toggle = () => {
    setOpenTable(!openTable);
  };
  const toggleByDate = () => {
    setOpenTableOfDate(!openTableOfDate);
  };
  const getDataForDisabled = (data) => {
    
    setDynamicMasterData({
      ...dynamicMasterData,
      accountLedgerGroupMasterDetail:
        data == 0
          ? dynamicMasterData.accountLedgerGroupMasterDetailForType0
          : data == 1
          ? dynamicMasterData.accountLedgerGroupMasterDetailForType1
          : dynamicMasterData.accountLedgerGroupMasterDetailForType2,
    });
    return { accountLedgerGroupsId: "" };
  };
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithoutSave
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={CashbalanceJson.showSaveBtn}
          screenTitle={CashbalanceJson.screenTitle}
          fieldMeta={CashbalanceJson.fieldMeta}
          showCancel={CashbalanceJson.showCancel}
          apiBaseURL={CashbalanceJson.apiBaseURL}
          showSaveNextBtn={CashbalanceJson.showSaveNextBtn}
          showSaveBtnMain={true}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          padding={false}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          fieldData={fieldData}
          onSave={onSave}
          showExcelDownload={true}
          getDataForDisabled={getDataForDisabled}
        />
        <br />
        <RTTable
          showPegination={false}
          columns={[
            { name: "index", title: "Sr. No.", align: "center" },

            { name: "monthYearName", title: "Month", linkFlag: true },
            { name: "openingAmount", title: "Op. Balance", align: "right" },
            { name: "creditTotal", title: "Receipts", align: "right" },
            { name: "debitTotal", title: "Payments", align: "right" },
            { name: "closingBalance", title: "CL. Balance", align: "right" },
          ]}
          hiddenColumnNames={[]}
          tableData={cashbook.length != 0 ? getFilteredTableData(cashbook) : []}
          rowLink={rowLink}
          isActionColActive={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          // rowCopy={this.rowCopy}
        />
      </Paper>
      {openTable && (
        <CashbookDateWise
          formData={formData}
          rowLink={rowLinkForDate}
          open={openTable}
          handleCloses={toggle}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          // dynamicMasterData={formData}
        />
      )}
      {openTableOfDate && (
        <CashBalanebyDate
          formData={formData}
          rowLink={rowLinkForDate}
          open={openTableOfDate}
          handleCloses={toggleByDate}
          popUpData={
            Object.keys(popUpDataOfDate).length != 0 ? popUpDataOfDate : {}
          }
          dynamicMasterData={formData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  showLoador,
  getAccountLedgerListByType,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashBalance);
