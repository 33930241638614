import endpoint from "../../config/endpoints";

export const AssetJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.asset,
  screenTitle: "Asset Calculation",
  formPath: "/asset-form",
  formPathView: "/asset-view",
  showBackToList: false,
  showTitle: true,
  searchList:[{
    label: "From Date",
    controlType: "datepicker",
    placeHolder: "",
    md: 5,
    lg: 5,
    sm: 5,
    xs: 12,
    dataKey: "fromDate",
    isMandatory: true,
    onSubmit: "compare",
    onSubmitParameter: "toDate-le",
  },
  {
    label: "To Date",
    controlType: "datepicker",
    placeHolder: "",
    md: 5,
    lg: 5,
    sm: 5,
    xs: 12,
    dataKey: "toDate",
    onSubmit: "compare",
    onSubmitParameter: "fromDate-ge",
    isMandatory: true,
    onSubmit: "compare",
  }],
  fieldMeta: [
    {
        label: "Ledger Code",
        controlType: "autocomplete",
        placeHolder: "Select Ledger Code ",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName:"ledgeCodeDetail",
        dataKey: "ledgerCode",
        isRootLevelKey: true,
        isMandatory: true,
      },
   
   
    {
      label: "Depreciation Percentage.",
      controlType: "textfield",
      placeHolder: "Enter Depreciation Percentage.",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "depreciationPercentage",
      inputType: "number",
      isMandatory: true,
      calculateMinus:true
    },
    {
        label: "Opening Value.",
        controlType: "textfield",
        placeHolder: "Enter Opening Value.",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        dataKey: "openingValue",
        inputType: "number",
        isMandatory: true,
        calculateMinus:true
      },
   
    
   
    {
      label: "Purchase In First Half",
      controlType: "textfield",
      placeHolder: "Enter Purchase In First Half",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "purchaseInFirstHalf",
      inputType: "number",
      isMandatory: true,
      calculateMinus:true
    },
    {
        label: "Purchase In Second Half",
        controlType: "textfield",
        placeHolder: "Enter Purchase In Second Half",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        dataKey: "purchaseInSecondHalf",
        inputType: "number",
        isMandatory: true,
        calculateMinus:true
      },
  
    {
      label: "Sale Value",
      controlType: "textfield",
      placeHolder: "Enter Sale Value",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sellValue",
      inputType: "number",
      isMandatory: true,
      calculateMinus:true
    },
    {
        label: "Total",
        controlType: "textfield",
        placeHolder: "Enter Total",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        dataKey: "total",
        inputType: "number",
        isMandatory: false,
        disableTrue: true,
        calculateMinus:true
      },
      {
        label: "Depreciation Value",
        controlType: "textfield",
        placeHolder: "Enter Depreciation Value",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        dataKey: "dvalue",
        inputType: "number",
        isMandatory: false,
        disableTrue: true,
        calculateMinus:true
      },
      {
        label: "Closing Value",
        controlType: "textfield",
        placeHolder: "Enter Closing Value",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        dataKey: "closingValue",
        inputType: "number",
        isMandatory: false,
        disableTrue: true,
        calculateMinus:true
      },
      {
        label: "Profit Center",
        controlType: "autocomplete",
        placeHolder: "Select Profit Center",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName: "profitCenterDetails",
        dataKey: "profitCenter",
        isRootLevelKey: false,
        isMandatory: true,
      },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: true,
    },
  ],
 
 
  
};
