import { TextField } from "@mui/material";
import { onlyNumber } from "./Util/Validations";
export const TextFieldCompo = ({
  autoFocus = false,
  inputRef,
  size,
  inputProps,
  placeHolder,
  name,
  color = "primary",
  value = "",
  disabled = false,
  type = "text",
  multiline = false,
  onChange,
  errorText = "",
  minusValid = true,
  spValid = "",
  onBlur,
}) => {
  const onChangeData = (event) => {
    if (minusValid && type == "number") {
      if (
        event.target.value === "" ||
        (event.target.value !== "e" && +event.target.value >= 0)
      ) {
        onChange(event);
      } else {
        if (event.target.value.length < value.length) {
          onChange(event);
        }
      }
    } else if (!minusValid && type == "number"&&onlyNumber.test(event.target.value)) {
      if (
        event.target.value === "" ||
        (event.target.value !== "e" )
      ) {
        onChange(event);
      } else {
        if (event.target.value.length < value.length) {
          onChange(event);
        }
      }
    } else {
      if(type != "number"){
   onChange(event);
      }
   
    }
  };
  return (
    <TextField
      inputRef={inputRef}
      autoFocus={autoFocus}
      onBlur={onBlur}
      size={size}
      color={color}
      name={name}
      id={name}
      value={value}
      disabled={disabled}
      inputProps={inputProps}
      type={"text"}
      placeholder={placeHolder}
      variant="standard"
      multiline={multiline}
      onChange={onChangeData}
      fullWidth
      InputProps={{
        style: { fontSize: '14px' } // Adjust the font size as needed
      }}
      error={errorText ? true : false}
      helperText={errorText ? errorText : " "}
    ></TextField>
  );
};
