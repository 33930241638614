import endpoint from "../../config/endpoints";

export const subVoucherDetailJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.subVoucherDetail,
  formPath: "/column-form",
  screenTitle: "Column Mapping",
  showAddButton: true,
  showDeleteIcon: false,
  showPdfDownload: false,
  pdfFileName: "Column Mapping",
  showExcelDownload: false,
  excelFileName: "Column Mapping",
  paramKey: "subVoucherMaster",
  tableColumnsToFilter: [
    // {
    //   columnDisplayName: "Code",
    //   columnKeyName: "subVoucherCode",
    //   isChecked: true,
    // },
    {
      columnDisplayName: "Column Name",
      columnKeyName: "columnName",
      isChecked: true,
    },
    {
      columnDisplayName: "Ledger Account",
      columnKeyName: "ledgerAccountNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Pojo Name",
      columnKeyName: "pojoName",
      isChecked: true,
    },
  ],

  fieldMetaToSearch: [],
  fieldMeta: [
    // {
    //   label: "Code",
    //   controlType: "textfield",
    //   placeHolder: "Code",
    //   md: 6,
    //   lg: 5,
    //   sm: 5,
    //   xs: 12,
    //   autoFocus: true,
    //   dataKey: "subVoucherCode",
    //   isMandatory: true,
    // },
    {
      label: "Column Name",
      controlType: "textfield",
      placeHolder: "Column Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "columnName",
      isMandatory: true,
    },
    {
      label: "Ledger Account",
      controlType: "autocomplete",
      placeHolder: "Ledger Account",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "ledgerAccountDetails",
      dataKey: "ledgerAccountNo",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Pojo Name",
      controlType: "textfield",
      placeHolder: "Pojo Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "pojoName",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: false,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "C",
      radioGroupItems: [
        {
          label: "Credit",
          value: "C",
        },
        {
          label: "Debit",
          value: "D",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Sort Order",
      controlType: "textfield",
      placeHolder: "Sort Order",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      autoFocus: true,
      dataKey: "orderBy",
      isMandatory: true,
      inputType: "number",
    },
  ],
};
