export const mandatoryCheck = ({ fieldData ,fieldMeta}) => {
  let formValid = true;

  let formErrors = {};
  fieldMeta.forEach((currentField) => {
    if (currentField.isMandatory) {
      if (
        (currentField.controlType === "datepicker" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")) ||
        (currentField.controlType !== "datepicker" &&
          currentField.controlType !== "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === ""))
      ) {
        formValid = false;

        formErrors = {
          ...formErrors,
          [currentField.dataKey]: {
            isError: true,
            errorText: `*${currentField.label} is required`,
          },
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        (fieldData[currentField.dataKey] === undefined ||
          fieldData[currentField.dataKey] === null ||
          (!currentField.isRootLevelKey &&
            fieldData[currentField.dataKey] != undefined &&
            fieldData[currentField.dataKey] != null &&
            fieldData[currentField.dataKey].id == null))
      ) {
        formValid = false;

        formErrors = {
          ...formErrors,
          [currentField.dataKey]: {
            isError: true,
            errorText: `*${currentField.label} is required`,
          },
        };
      }
    } else if (currentField.controlType === "hideTextfield") {
      if (
        fieldData[currentField.tableDataKey] === currentField.open &&
        (fieldData[currentField.dataKey] === undefined ||
          fieldData[currentField.dataKey] === "")
      ) {
        formValid = false;

        formErrors = {
          ...formErrors,
          [currentField.dataKey]: {
            isError: true,
            errorText: `*${currentField.error} is required`,
          },
        };
      }
    } else if (currentField.controlType === "hideDatepicker") {
      if (
        fieldData[currentField.tableDataKey] === currentField.open &&
        (fieldData[currentField.dataKey] === undefined ||
          fieldData[currentField.dataKey] === null ||
          fieldData[currentField.dataKey].toString().trim() === "")
      ) {
        formValid = false;

        formErrors = {
          ...formErrors,
          [currentField.dataKey]: {
            isError: true,
            errorText: `*${currentField.label} is required`,
          },
        };
      }
    } else if (currentField.controlType === "hideAutocomplete") {
      if (
        fieldData[currentField.tableDataKey] === currentField.open &&
        (fieldData[currentField.dataKey] === undefined ||
          fieldData[currentField.dataKey] === null ||
          fieldData[currentField.dataKey].toString().trim() === "")
      ) {
        formValid = false;

        formErrors = {
          ...formErrors,
          [currentField.dataKey]: {
            isError: true,
            errorText: `*${currentField.label} is required`,
          },
        };
      }
    } else if (currentField.controlType === "hideMultiSelect") {
      if (
        fieldData[currentField.tableDataKey] === currentField.open &&
        (fieldData[currentField.dataKey] === undefined ||
          fieldData[currentField.dataKey] === null ||
          (fieldData[currentField.dataKey] &&
            fieldData[currentField.dataKey].length == 0))
      ) {
        formValid = false;

        formErrors = {
          ...formErrors,
          [currentField.dataKey]: {
            isError: true,
            errorText: `*${currentField.label} is required`,
          },
        };
      }
    }
  });
  return {formValid,formErrors};
};


export const ValidationCheck = ({ fieldData ,fieldMeta}) => {
    let formValid = true;
  
    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    return {formValid,formErrors};
  };