import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { OutstandingJson } from "../../DynamicFormsJson/Report/outstanding";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const OutstandingSubReportByType = ({handleCloses,
  open,
  popUpData,
  formData,
  showLoador,showNotification
 }) => {
  const [rowList, setRowList] = useState([]);
 const[openPopup,setOpenPopup] = useState(false);
 const[accPopupata,setAccPopupata]=useState({});
 const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
   console.log(formData)
    showLoador({ loador: true });
    apiGet({    
      url:endpoint.salePurchaseApproval+"/over-all-bill-outstanding-by-accId/?entryType="+formData.entryType+"&accId="+popUpData.accId,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
       
        const rowlist= data.data.length!=0?data.data.map((row,index)=>{
            index=index+1;
            return{
              index:index,
              date:row.date,
              documentNo:row.documentNo,
              remainingAmt:Math.abs((+row.remainingAmt)).toFixed(2),
              accId:row.accId,
            }
        }):[];
      
                  setRowList(rowlist);
                  showLoador({ loador: false })
       
      }else{
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
        showLoador({ loador: false })
      }
       
    });
  }, []);
  const handleClose = () => {
    handleCloses();
  };

 
  console.log(JSON.stringify(rowList));
  const togglePopup = () =>  {
    setOpenPopup(!openPopup);
    
    };
    const onSearch = (searchV) => {
      setSearchValue(searchV)
    };
    const generatePDFMethod = () => {
 
      var columns = [["Sr No","Date", "Bill No", "Amount"]];
        let tableList = []
        rowList.map(((rowData, index) => {
          
          let tableData = []
      
        
        
         
      
          tableData = []
          tableData.push(rowData.index)
          tableData.push(rowData.date)
         
          tableData.push(rowData.documentNo )
          tableData.push(rowData.remainingAmt )
        
          tableList.push(tableData)
        }))
       
        
       
        generatePDF({
         
          rows: tableList,
          columns,
         
          headerList: [
            {
              label: "Entry Type ",
              value: formData.entryType==1?"Bill Outstanding":"Purchase Outstanding",
            },
           
          ],
          pdfTitle: OutstandingJson.screenTitle,
        });
      
      };
      
      
      
      const generateExcelMethod = () => {
       var columns = ["Sr No","Date", "Bill No", "Amount"];
        let alignList = [];
      
        let rows = []
        rowList.map((reportData, index) => {
          let reportObj = [];
          
       
        
          
      
          
            
            
            reportObj.push(reportData.index)
            reportObj.push(reportData.date)
            reportObj.push(reportData.documentNo)
            reportObj.push(reportData.remainingAmt)
            rows.push(reportObj)
           
          })
          
         
      
        
        generateExcel({
          columns,
          rows,
          alignList,
          headerList: ["Entry Type ", formData.entryType==1?"Bill Outstanding":"Purchase Outstanding",
      
          ],
          title: OutstandingJson.screenTitle,
        });
      
      };
      const getFilteredTableData = (tableData) => {
      
        let listData = tableData.filter((row) => {
      
          let isValid = false;
      
          if (row.documentNo.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
          }
          if (row.date.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        if (row.remainingAmt.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      
          return isValid;
      
        });
        return listData
      };
  return (
    <>


      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
      <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {popUpData.accLedgerName?popUpData.accLedgerName+" ( "+popUpData.accLedgerCode+" )":""}
          </BootstrapDialogTitle>
          <DialogContent dividers>
          <LandingScreenHeader
              screenTitle=""
              showTable={true}
              dynamicMasterData={{}}
              showBackToList={false}
              DataToSet={[]}
              fieldMeta={[]}
              showSaveBtnMain={true}
              onSearch={onSearch}
              showPdfDownload={true}
              showExcelDownload={true}
            generatePDF={generatePDFMethod}
            generateExcel={generateExcelMethod}

            />
          <RTTable
          showPegination={false}
          columns={[
            { name: "index", title: "Sr. No.", align: "center" },
{ name: "date", title: "Date", align: "center" },
            { name: "documentNo", title: "Bill No.", align: "center" },
            { name: "remainingAmt", title: "Amount", align: "right" },
          ]}
          hiddenColumnNames={[]}
          tableData={getFilteredTableData(rowList)}
          isActionColActive={false}
          showEditIcon={false}
          showDeleteIcon={false}
          // rowDelete={rowDelete}
          showCopyIcon={false}
          // rowCopy={this.rowCopy}
         
        />
            <br />
          </DialogContent>
        </BootstrapDialog>

        
      </Paper>
     
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {showLoador,showNotification};
export default connect(mapStateToProps, mapDispatchToProps)(OutstandingSubReportByType);

