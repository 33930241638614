import {
    Grid
} from "@mui/material";
import { Paper } from "@mui/material";
import swal from "sweetalert";
import dayjs from "dayjs";
import { compose } from "@reduxjs/toolkit";
import jsPDF from 'jspdf';
import moment from 'moment';

import React from "react";
import { connect } from "react-redux";
import { CashReceiptJSON } from "../../DynamicFormsJson/Transaction/cashReceipt";
import { getCashReciept } from "../../Slice/cashReceipt.slice";
import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreenNew";
import CashReceiptForm from "./CashReceiptForm"
import CashReceiptFormEdit from "./CashReceiptFormEdit"
import CashRecieptView from "./CashRecieptView"

import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import { deleteFailedMsg, deletemsg, noInternetMsg, serverMsg } from "../../config/messageconstant";
import { CashReceiptColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { apiDelete } from "../../utils/api_service";
class CashReceiptTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
            },
            searchInfo: {},
            editData: {},
            viewData: {},
            setFieldData: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.subVoucherList?.subVoucherByType !==
            nextProps.subVoucherList?.subVoucherByType
        ) {
            let setFieldData = {}
            let subList = nextProps.subVoucherList?.subVoucherByType
            if (subList.length != 0) {
                const currentDate = dayjs();

                setFieldData = {
                    subVoucher: subList[0].id,
                    todate: currentDate,
                    fromdate: currentDate.startOf('month')
                }
                let callApiData = {
                    subVoucher: subList[0].id,
                    todate: currentDate.format("DD-MM-YYYY"),
                    fromdate: currentDate.startOf('month').format("DD-MM-YYYY")
                }
                this.onSearchData(callApiData)
            }
            this.setState({
                dynamicMasterData: {
                    ...this.state.dynamicMasterData,
                    subVoucherDetail: subList,
                },
                setFieldData: setFieldData
            });
        }
    }
    
    async componentDidMount() {
        this.setState({
            rowList: [],
        });
       
        const { showLoador, showNotification, getSubVoucherByType } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getSubVoucherByType({ type: 1 }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                }
            });
        } else {
            showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    }

    onSearchData = async (data) => {
        const { getCashReciept, showLoador, showNotification } = this.props;
        console.log(data);
        this.setState({
            searchInfo: data
        })
        const locationId = localStorage.getItem("location");
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getCashReciept({
                fromDate: data.fromdate,
                toDate: data.todate,
                subVoucherId: data.subVoucher,
                locationId: locationId,
            }).then(({ response,success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }else{
                    console.log(response)
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    rowEdit = () => {
        this.setState(
            {
                editData: this.state.viewData,
                viewData: {},

            }
        )
        // this.props.navigate("/cash-receipt-form/" + rowData.id + "/" + 0);
    };
    callApi = () => {
        const { searchInfo } = this.state
        if (Object.keys(searchInfo).length != 0) {
            this.onSearchData(searchInfo)
        }
        this.rowReset()
    }
    rowLink = (rowData) => {
        this.setState(
            {
                viewData: rowData
            }
        )
    }
    rowReset = () => {
        this.setState(
            {
                viewData: {},
                editData: {}
            }
        )
    }
    rowDelete = (rowData) => {

        swal({
            title: "Are you sure?",
            text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onDelete(rowData);
            }
        });
    };
    onDelete = async () => {
        const {
            deleteMsgAuto = false,
        } = this.props;
        if (navigator.onLine) {
            this.props.showLoador({ loador: true });
            apiDelete({
                url: CashReceiptJSON.apiBaseURL + "/" + this.state.viewData.id,
            }).then(({ data, success }) => {
                if (success) {
                    this.props.showLoador({ loador: false });
                    this.props.showNotification({
                        msg: deleteMsgAuto ? data.data.message : deletemsg,
                        severity: data.data.error ? "error" : "success",
                    });

                    this.callApi();

                } else {
                    this.props.showNotification({
                        msg: deleteFailedMsg,
                        severity: "error",
                    });

                    this.props.showLoador({ loador: false });
                }
            });

        } else {
            this.props.showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };
    render() {
        const { dynamicMasterData, editData, viewData, setFieldData } = this.state;
        const { cashRecieptList } = this.props;
        console.log(viewData);
        return (
            <>
                {/* <Paper
                    sx={{
                        p: 2,
                        pt: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                > */}
                <Grid sx={{ height: "100vh" }} container spacing={1}>

                    <Grid item xs={12} sm={7} md={7}>
                        {
                            Object.keys(viewData).length != 0?
                                 <CashRecieptView rowDelete={this.rowDelete} rowReset={this.rowReset} rowEdit={this.rowEdit} approvalFlag={0} editData={viewData} callApi={this.callApi} />
                                :Object.keys(editData).length != 0
                                    ? <CashReceiptFormEdit rowReset={this.rowReset} approvalFlag={0} editData={editData} callApi={this.callApi} />
                                    : <CashReceiptForm rowReset={this.rowReset} callApi={this.callApi} />

                        }
                         
                
                    </Grid>
                    <Grid sx={{ borderLeft: "2px solid #CFCFCF" }} item xs={12} sm={5} md={5}>
                        <DynamicMainScreen
                            setFieldData={setFieldData}
                            showDeleteIcon={true}
                            dynamicMasterData={dynamicMasterData}
                            searchList={CashReceiptJSON.searchList}
                            onSearchData={this.onSearchData}
                            formPathView={CashReceiptJSON.formPathView}
                            formPath={CashReceiptJSON.formPath}
                            screenTitle={CashReceiptJSON.screenTitle}
                            fieldMeta={CashReceiptJSON.fieldMeta}
                            getListById={this.getListById}
                            rowLink={this.rowLink}
                            isActiveURL={CashReceiptJSON.isActiveURL}
                            showAddButton={CashReceiptJSON.showAddButton}
                            tableColumnsToFilter={CashReceiptJSON.tableColumnsToFilter}
                            tableColumns={CashReceiptColumns}
                            tableData={cashRecieptList?.cashReciept}
                            apiBaseURL={CashReceiptJSON.apiBaseURL}
                            callApi={false}
                            chipCompoColor={true}
                            getTableDataById={true}
                            showPdf={false}
                            showPdfDownload={false}
                            showEditIcon={true}
                            showViewButtonInTable={true}
                            showPegination={false}
                            isNote={false}
                            GridSave={1.5}
                            buttonCenter={false}
                            editConditionValue="0"
                            editConditionName="status"
                            deleteConditionValue="0"
                            deleteConditionName="status"
                            rowClick={true}
                            
                        />
                    </Grid>
                </Grid>
                {/* </Paper> */}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    subVoucherList: state.subVoucher,
    cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getSubVoucherByType,
    getCashReciept,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CashReceiptTable);