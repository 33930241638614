import React from "react";
import { connect } from "react-redux";
import { MasterInfoColumns } from "../../tableColumns/table-columns";
import { MasterInfoJson } from "../../DynamicFormsJson/Transaction/masterInfo";
import { getMasterInfo } from "../../Slice/MasterGenerate.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";

class MasterTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { MasterGenerateList, getMasterInfo } = this.props;
    console.log(MasterGenerateList);
    return (
      <>
        <DynamicMainScreen
          formPath={MasterInfoJson.formPath}
          screenTitle={MasterInfoJson.screenTitle}
          showPdfDownload={false}
          showAddButton={true}
          tableColumnsToFilter={MasterInfoJson.tableColumnsToFilter}
          tableColumns={MasterInfoColumns}
          tableData={MasterGenerateList?.masterForm}
          getTableData={getMasterInfo}
          apiBaseURL={MasterInfoJson.apiBaseURL}
          baseIdColumn={MasterInfoJson.baseIdColumn}
          showPegination={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  MasterGenerateList: state.masterGenerate,
});
const mapDispatchToProps = {
  getMasterInfo,
};
export default connect(mapStateToProps, mapDispatchToProps)(MasterTable);
