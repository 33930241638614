import endpoint from "../../config/endpoints";

export const userJSON = {
  formPath: "/user-form",
  apiBaseURL: endpoint.user1,
  screenTitle: "User",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "User",
  showExcelDownload: true,
  showBackToList:true,
  excelFileName: "User",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "userName",
      isChecked: true,
    },
    {
      columnDisplayName: "User Password",
      columnKeyName: "usrPwd",
      isChecked: true,
    },
    {
      columnDisplayName: "Email",
      columnKeyName: "email",
      isChecked: true,
    },
    {
      columnDisplayName: "Contact No.",
      columnKeyName: "contact",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "userName",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Location",
      controlType: "autocomplete",
      placeHolder: "Select Location",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "locationDetails",
      dataKey: "locationId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Accessible Location",
      controlType: "multiSelect",
      placeHolder: "Select Accessible Location",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2.5,
        labelMD: 2.5,
        labelLG: 2.5,
        valueSM: 9.5,
        valueMD: 9.5,
        valueLG: 9.5,
      dataKey: "accLocationId",
      
      masterName: "accLocationDetails",
      isRootLevelKey: true,
      isMandatory: true,
    },
    
    {
      label: "Email",
      controlType: "textfield",
      placeHolder: "Email",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "email",
      isMandatory: false,
    },
    {
      label: "Contact No.",
      controlType: "textfield",
      placeHolder: "Contact No.",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contact",
      isMandatory: false,
      maxLength: 10,
      inputType: "number",
    },
    {
      label: "User Role",
      controlType: "autocomplete",
      placeHolder: "Select Role",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "roleDetails",
      dataKey: "roleId",
      isRootLevelKey: true,
      isMandatory: false,
    },
  ],
};
