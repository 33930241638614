import { Checkbox, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getLocation, getLocationByIds } from "../../Slice/accountLedger.slice";
import {
  getClosingList,
  getStockCategoryList,
  getYear,
  setClosingList,
} from "../../Slice/stockValue.slice";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { setSalesPurchaseApproval } from "../../Slice/salesPurchaseApproval.slice";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { getAccountLedgerListByType1 } from "../../Slice/subVoucherDetail.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { titleColor } from "../../config/ColorObj";
import TdsApplicablePopup from "./TdsApplicablePopup";
class SalePurchaseApprovalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // rowList: [],
      DataToSet: [],
      setList: [],
      TableList: [],
      tableData: [],
      yearId: "",
      tdsApplicable: 0,
      showTdsApplicable: false,
      beatDataSalesPersonWise: "",
      salesTeamId: "",
      salesTeamHeaderId: "0",
      dynamicMasterData: {
        locationDetails: this.props.locationList?.location,
        yearDetails: this.props.stockValue.year,
        tdsAccountDetails:
          this.props.subVoucherDetailList?.accountLedgerByType1,
      },
    };
  }

  async componentDidMount() {
    const {
      showLoador,
      getAccountLedgerListByType1,
      showNotification,
      rowList,
      salesPurchaseAppovalListData,
    } = this.props;
    console.log(rowList);
    if (rowList.length != 0) {
      this.setState({
        TableList:
          this.props.salesPurchaseAppovalListData.salesPurchaseApprovalList,
      });
    }
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAccountLedgerListByType1({ accType: 0 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          tdsAccountDetails:
            nextProps.subVoucherDetailList.accountLedgerByType1,
        },
      });
    }
  }
  handleCloses = () => {
    this.setState({
      showPopup: false,
    });
  };

  onSubmitData = () => {
    const { TableList } = this.state;
    let checkedList = [];
    this.props.salesPurchaseAppovalListData.salesPurchaseApprovalList.map(
      (row) => {
        if (row.checked) {
          checkedList.push(row.id);
        }
      }
    );
    console.log(checkedList);
    if (checkedList.length != 0) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const { showLoador, showNotification } = this.props;
          if (navigator.onLine) {
            showLoador({ loador: true });
            apiGet({
              url: endpoint.salesPurchase + "/approve?id=" + checkedList,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                this.props.goBackToHeader();
                showNotification({ msg: savemsg });
              } else {
                showNotification({ msg: saveFailedMsg, severity: "error" });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
      });
    } else {
      this.props.showNotification({
        msg: "Please select atleast one record!",
        severity: "error",
      });
    }
  };

  handleClick = (event, name, rowId) => {
    this.onCheckBoxClick(event, name, rowId);
  };

  onCheckBoxClick = (e, row, rowId) => {
    const { TableList } = this.state;
    let changedTableList =
      this.props.salesPurchaseAppovalListData.salesPurchaseApprovalList.map(
        (tableDataObj) => {
          if (row.id === tableDataObj.id) {
            if (e.target.checked) {
              if (tableDataObj.checked) {
                let object = {
                  ...tableDataObj,
                  checked: false,
                };
                return object;
              } else {
                let object = {
                  ...tableDataObj,
                  checked: true,
                };
                return object;
              }
            } else {
              let object = {
                ...tableDataObj,
                checked: false,
              };
              return object;
            }
          } else {
            return tableDataObj;
          }
        }
      );
    this.props.setSalesPurchaseApproval({ row: changedTableList });
  };

  onRadioChange = (name, rowData) => (event) => {
    console.log(event.target.value);
    this.setState({
      [name]: event.target.value,
    });
    if (event.target.value == 1) {
      this.setState({
        showTdsApplicable: !this.state.showTdsApplicable,
        tableData: rowData,
      });
    }
  };

  handleCloses = () => {
    this.setState({
      showTdsApplicable: !this.state.showTdsApplicable,
      tdsApplicable: 0,
    });
  };

  onSaveTdsApplicable = (dataToSave, headerId) => {
    // const { showTdsApplicable, rowList } = this.state;
    // const rowListData = rowList.map((row) => {
    //   if (row.index == headerId) {
    //     return {
    //       ...row,
    //       recieptPaymentDetailCcInfoList: dataToSave,
    //     };
    //   } else {
    //     return row;
    //   }
    // });
    // this.setState({
    //   recieptPaymentDetailCcInfoList: dataToSave,
    //   showTdsApplicable: !showTdsApplicable,
    //   rowList: rowListData,
    // });
  };
  render() {
    const {
      beatData,
      matrixData,
      dynamicMasterData,
      DataToSet,
      setList,
      showPopup,
      tableData,
      tdsApplicable,
      showTdsApplicable,
      TableList,
    } = this.state;
    const { stockValue, locationList, dataToSave, rowList, headerData } =
      this.props;
    return (
      <>
        <>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Approve Selected"
              onClick={this.onSubmitData}
            />
          </Grid>
          <br />
          <TableContainer>
            <Table aria-label="simple table" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={1}
                    style={{ minWidth: "10px" }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    colSpan={1}
                    style={{ minWidth: "30px" }}
                  >
                    Sr. No.
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    // style={{ minWidth: "10px" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.salesPurchaseAppovalListData
                  .salesPurchaseApprovalList.length !== 0 &&
                  this.props.salesPurchaseAppovalListData.salesPurchaseApprovalList.map(
                    (matrixDataRow, indexRow) => {
                      return (
                        <TableRow>
                          <TableCell
                            colSpan={1}
                            align="center"
                            component="td"
                            scope="row"
                          >
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  matrixDataRow,
                                  matrixDataRow.id
                                )
                              }
                              checked={matrixDataRow.checked}
                              // disabled={row.disabled}
                              // inputProps={{
                              //   "aria-labelledby": labelId,
                              // }}
                            />
                          </TableCell>
                          <TableCell
                            colSpan={1}
                            align="center"
                            component="td"
                            scope="row"
                          >
                            {indexRow + 1}
                          </TableCell>
                          <TableCell>
                            <Grid container sx={{ mt: 2 }} spacing={1}>
                              <Grid container xs={12} sm={6} md={6}>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                  &nbsp;&nbsp;
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                    }}
                                    label={
                                      (headerData.entryType != 1
                                        ? "Vendor "
                                        : "Customer ") + "Name :"
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                    }}
                                    label={
                                      matrixDataRow.accLedgerName != null
                                        ? matrixDataRow.accLedgerName
                                        : " - "
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid container xs={12} sm={6} md={6}>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                  &nbsp;&nbsp;
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                    }}
                                    label={
                                      (headerData.entryType != 1
                                        ? "Purchase "
                                        : "Sales ") + "No. :"
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                    }}
                                    label={
                                      matrixDataRow.documentNo != null
                                        ? matrixDataRow.documentNo
                                        : "-"
                                    }
                                  />
                                </Grid>
                              </Grid>

                              <Grid container xs={12} sm={6} md={6}>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                  &nbsp;&nbsp;
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                    }}
                                    label={"Ledger Account Code :"}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                    }}
                                    label={
                                      matrixDataRow.accountLedgerCode != null
                                        ? matrixDataRow.accountLedgerCode
                                        : " - "
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid container xs={12} sm={6} md={6}>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                  &nbsp;&nbsp;
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                    }}
                                    label={
                                      (headerData.entryType != 1
                                        ? "Purchase "
                                        : "Sales ") + "Date :"
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                    }}
                                    label={
                                      matrixDataRow.documentDate != null
                                        ? // ? moment(
                                          matrixDataRow.documentDate
                                        : // ).format("DD-MM-YYYY")
                                          " - "
                                    }
                                  />
                                </Grid>
                              </Grid>

                              <Grid container xs={12} sm={6} md={6}>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                  &nbsp;&nbsp;
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                      color: titleColor,
                                    }}
                                    label={
                                      (headerData.entryType != 1
                                        ? "Purchase "
                                        : "Sales ") + "Amount :"
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 16,
                                    }}
                                    label={matrixDataRow.totalAmt.toFixed(2)}
                                  />
                                </Grid>
                              </Grid>

                              {/* {headerData.entryType != 1 && (
                              <>
                                <Grid container xs={12} sm={6} md={6}>
                                  <Grid item xs={12} sm={4} md={4} lg={4}>
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        color: titleColor,
                                        marginTop: 12,
                                      }}
                                      label={"TDS Applicable :"}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <RadioGroup
                                      row
                                      aria-label={"tdsApplicable"}
                                      name={"tdsApplicable"}
                                      id={"tdsApplicable"}
                                      value={
                                        tdsApplicable
                                        // formData["isLocal"] ? formData["isLocal"] : 0
                                      }
                                      onChange={this.onRadioChange(
                                        "tdsApplicable",
                                        matrixDataRow.postAccJson != null
                                          ? matrixDataRow.postAccJson
                                          : []
                                      )}
                                      size="small"
                                    >
                                      <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label={"Yes"}
                                      />
                                      <FormControlLabel
                                        value={0}
                                        control={<Radio />}
                                        label={"No"}
                                      />
                                    </RadioGroup>
                                  </Grid>
                                </Grid>
                              </>
                            )} */}
                            </Grid>
                            <br />
                            <RTTable
                              showPegination={false}
                              columns={[
                                {
                                  name: "index",
                                  title: "Sr. No.",
                                  align: "center",
                                },
                                {
                                  name: "dabitCode",
                                  title: "ACCD",
                                  align: "center",
                                },
                                {
                                  name: "amount",
                                  title: "Amount",
                                  align: "right",
                                },
                                {
                                  name: "type",
                                  title: "Type",
                                  align: "center",
                                },
                              ]}
                              hiddenColumnNames={[]}
                              tableData={
                                matrixDataRow.postAccJson != null
                                  ? matrixDataRow.postAccJson
                                  : []
                              }
                              isActionColActive={false}
                              showEditIcon={false}
                              showDeleteIcon={false}
                              showCopyIcon={false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        {showTdsApplicable && (
          <TdsApplicablePopup
            open={showTdsApplicable}
            handleCloses={this.handleCloses}
            dynamicMasterData={dynamicMasterData}
            // onSave={this.onSaveTdsApplicable}
            dataToSave={tableData}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locationList: state.accountLedger,
  stockValue: state.stockValueList,
  subVoucherDetailList: state.subVoucherDetail,
  salesPurchaseAppovalListData: state.salesPurchaseAppoval,
});
const mapDispatchToProps = {
  showNotification,
  getClosingList,
  showLoador,
  getStockCategoryList,
  getLocation,
  getLocationByIds,
  setClosingList,
  getYear,
  getAccountLedgerListByType1,
  setSalesPurchaseApproval,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalePurchaseApprovalTable);
