import { Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { stockEvaluationJSON } from "../../DynamicFormsJson/Transaction/stockEvaluation";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  updateMsg,
} from "../../config/messageconstant";
import { getLocation, getLocationByIds } from "../../Slice/accountLedger.slice";
import {
  getClosingList,
  getStockCategoryList,
  getYear,
  setClosingList,
} from "../../Slice/stockValue.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
class StockEvolutionFormNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      DataToSet: [],
      locationNameList: [],
      categoryList: [],
      closingList: [],
      setList: [],
      // matrixData: {},
      beatData: [],
      yearId: "",
      beatDataSalesPersonWise: "",
      salesTeamId: "",
      salesTeamHeaderId: "0",
      dynamicMasterData: {
        locationDetails: this.props.locationList?.location,
        yearDetails: this.props.stockValue.year,
      },
    };
  }

  async componentDidMount() {
    const {
      getLocation,
      getYear,
      getStockCategoryList,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      await getLocation().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getYear().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.locationList !== nextProps.locationList) {
      if (
        this.props.locationList?.location !== nextProps.locationList?.location
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            locationDetails: nextProps.locationList?.location,
          },
        });
      }
    }
    if (this.props.stockValue !== nextProps.stockValue) {
      if (this.props.stockValue?.year !== nextProps.stockValue?.year) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.stockValue?.year,
          },
        });
      }
    }
  }
  handleCloses = () => {
    this.setState({
      showPopup: false,
    });
  };

  getRow = (row, indexRow, e) => {
    this.setState({ setList: e });
  };

  onSearch = async (data) => {
    const {
      getClosingList,
      getLocationByIds,
      getStockCategoryList,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      let locationNameList = await getLocationByIds({
        locationIds: data.locationIds,
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return [];
        } else {
          return response;
        }
      });
      this.setState({ locationNameList: locationNameList });
      showLoador({ loador: true });
      let categoryList = await getStockCategoryList().then(
        ({ success, response }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
            return [];
          } else {
            return response;
          }
        }
      );
      this.setState({ categoryList: categoryList,yearId:data.yearId, });
      showLoador({ loador: true });
      let closingList = await getClosingList({
        yearId: data.yearId,
        locationIds: data.locationIds,
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return [];
        } else {
          return response;
        }
      });
      this.setState({ closingList: closingList });
      if (locationNameList.length != 0) {
        this.setMatrix(categoryList, locationNameList, closingList);
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  setMatrix = (categoryList, locationNameList, closingList) => {
    const matList = categoryList.map((dayObj) => {
      let matObj = {
        ...dayObj,
      };

      const beatListData = locationNameList.map((beatObj) => {
        const changedClosingList = closingList.filter(
          (closingListObj) =>
            closingListObj.location.id == beatObj.id &&
            closingListObj.stock_category_id == dayObj.id
        );
        console.log(changedClosingList);
        beatObj = {
          ...beatObj,
          mainId: changedClosingList.length != 0 ? changedClosingList[0].id : 0,
          amount:
            changedClosingList.length != 0 ? changedClosingList[0].amount : 0,
          year:
            changedClosingList.length != 0 ? changedClosingList[0].year.id : "",
        };

        return beatObj;
      });
      matObj = {
        ...matObj,
        beatListData: beatListData,
      };

      return matObj;
    });
    this.setState({
      matrixData: matList,
    });
  };

  onTextFieldDataChange = (monthId, beatId) => (event) => {
    const { value } = event.target;
    const { matrixData } = this.state;
    const updatedMatrixData = matrixData.map((rowD) => {
      if (rowD.id === beatId) {
        let totalTarget = 0;
        const updatedBeatListData = rowD.beatListData.map((monthData) => {
          if (monthData.name === monthId) {
            monthData = { ...monthData, amount: value };
          }

          return monthData;
        });
        return { ...rowD, beatListData: updatedBeatListData, totalTarget };
      }
      return rowD;
    });
    this.setState({ matrixData: updatedMatrixData });
  };

   onSubmitData = () => {
    const { stockValue, showLoador, showNotification } = this.props;
    const { matrixData, beatListData, yearId } = this.state;
    console.log(matrixData);
    let finalList = []
    matrixData.map((rowData) => {
      rowData.beatListData.map((rowDataList) => {
        let dataObj = {
          amount: rowDataList.amount,
          location: { id: rowDataList.id },
          stock_category_id: rowData.id,
          year:{
            id:yearId
          },
        }
        if (rowDataList.mainId != 0) {
          dataObj = {
            ...dataObj,
            id: rowDataList.mainId,
          }
        }
        finalList.push(dataObj)
      })
    });
    console.log(finalList);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          apiPost({
            url: endpoint.stockClosing + "/save-all",
            postBody: finalList,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
          
              showNotification({ msg: updateMsg });
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };
  render() {
    const {
      beatData,
      matrixData,
      dynamicMasterData,
      DataToSet,
      setList,
      showPopup,
      beatDataSalesPersonWise,
      locationNameList,
    } = this.state;
    console.log(matrixData);
    const { stockValue, locationList } = this.props;
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <DynamicFormWithoutSave
            showBackToList={false}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            // showSaveBtn={stockEvaluationJSON.showSaveBtn}
            showTitle={stockEvaluationJSON.showTitle}
            screenTitle={stockEvaluationJSON.screenTitle}
            fieldMeta={stockEvaluationJSON.fieldMeta}
            showCancel={stockEvaluationJSON.showCancel}
            apiBaseURL={stockEvaluationJSON.apiBaseURL}
            showSaveNextBtn={stockEvaluationJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            // showSaveBtnMain={false}
            getListById={this.getListById}
            onSave={this.onSearch}
          />
          <br />

          {stockValue.stockCategoryList.length !== 0 &&
            locationList?.locationNamesById.length !== 0 && (
              <>
                <br />
                <TableContainer>
                  <Table aria-label="simple table" size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={3}
                          style={{ minWidth: "30px" }}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          style={{ minWidth: "100px" }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          align="center"
                          // colSpan={3}
                          colSpan={locationList?.locationNamesById.length}
                          style={{ minWidth: "100px" }}
                        >
                          Location
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        {locationNameList.map((monthData) => {
                          return <TableCell   align="center">{monthData.name}</TableCell>;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {matrixData.map((row, index) => (
                        <TableRow key={row.name}>
                          <TableCell
                            colSpan={2}
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell colSpan={2} component="th" scope="row">
                            {row.name}
                          </TableCell>

                          {row.beatListData.map((monthData) => {
                            return (
                              <TableCell align="right">
                                <TextFieldCompo
                                  placeHolder={"Target"}
                                  size="small"
                                  color="primary"
                                  type="number"
                                  id={"1"}
                                  value={monthData.amount}
                                  onChange={this.onTextFieldDataChange(
                                    monthData.name,
                                    row.id
                                  )}
                                  fullWidth={false}
                                />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}

                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="Submit"
                    variant="contained"
                    name="Submit"
                    onClick={this.onSubmitData}
                  />
                </Grid>
              </>
            )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locationList: state.accountLedger,
  stockValue: state.stockValueList,
});
const mapDispatchToProps = {
  showNotification,
  getClosingList,
  showLoador,
  getStockCategoryList,
  getLocation,
  getLocationByIds,
  setClosingList,
  getYear,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockEvolutionFormNew);
