import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
const ExcelJS = require("exceljs");
function calculateYPosition(doc, lineHeight) {
  // Get the current Y position
  const y = doc.internal.pageSize.height - doc.autoTable.previous.finalY;

  // Add an extra space based on the line height
  return y + lineHeight;
}
export const generatePDF = ({ rows, columns, headerList = [], columnStylesPlain, columnStyles,pdfTitle, top = 40 }) => {
  var doc = new jsPDF('p', 'pt');

  doc.setFontSize(16);
  doc.setTextColor(40);

  //   doc.setFontStyle('normal');
  const fileName = pdfTitle.split(" ").join("") + "-" + moment().format("DD-MM-YYYY")
  var textWidth = doc.getStringUnitWidth(pdfTitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;

  var pageWidth = doc.internal.pageSize.getWidth();
  var xPos = (pageWidth - textWidth) / 2;

  doc.text(pdfTitle, xPos, 50, { maxWidth: 540 });

  let tableData = []
  let tableList = []
 

  headerList.map((head, index) => {
    if (index % 2 == 0) {
      if (headerList[index + 1] != undefined) {
        tableData.push( {
          content: head.label + " : ",
           styles: { halign: "left" }
        })
        tableData.push(head.value)
      } else {
        tableData.push([
          {
            content: head.label + " : ",
             styles: { halign: "left" }
          },
          {
            content: head.value,
            colSpan: 3
            // styles: { halign: "center" }
          }
        ])
        tableList.push(...tableData)
        tableData = []
      }
    }else{
      
      tableData.push( {
        content: head.label + " : ",
         styles: { halign: "left" }
      })
        tableData.push(head.value)
        
        tableList.push(tableData)
      tableData = []
    }
  })
  var tableStyles = {
    lineColor: "#c1c1c1", // Border color (black in this example)
    lineWidth: 0.5,       // Border line width
    // fontStyle: 'bold'    // Font style for the table
  };
  doc.autoTable({
    startY: 90,
    head: [], 
    body: tableList,
    theme: 'plain',
    columnStyles:  columnStylesPlain
  });
  // doc.autoTable({
  //   startY: doc.autoTable.previous.finalY + 5,
  //   head: columns, 
  //   body: rows,
  //   theme: 'plain',
  //   styles: tableStyles,
  // });

  doc.autoTable({
    head: columns, 
    body: rows,
    tableLineColor: [189, 195, 199],
    tableLineWidth: 0.75,
    startY:  doc.autoTable.previous.finalY + 5,

    margin: { right: 20, left: 50, top: top },
    headStyles: {
      halign: 'center',
    },
    theme: 'plain',
    styles: tableStyles,
    columnStyles: columnStyles,
    bodyStyles: { valign: 'top' },

    showHead: "everyPage",
    didParseCell: (hookData) => {
      if (hookData.row.index === rows.length - 1) {
        // Check if it's the last row
        doc.setFont('helvetica', 'bold'); // Set the font style to bold
        doc.setFontSize(50); // Set the font size (adjust as needed)
      }
    },
    didDrawRow: function (data) {
     
      if (data.row.index === rows.length - 1) {
        // Check if it's the last row
        doc.setFont('helvetica', 'bold'); // Set the font style to bold
        doc.setFontSize(16); // Set the font size (adjust as needed)
      }
    },
    didDrawPage: function (data) {


      var str = "Page " + doc.internal.getNumberOfPages();



      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      let pb = "Powered By Bizintel DMS"
      const compName = "Company Name"
      var textWidthCN = doc.getStringUnitWidth(compName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textWidth = doc.getStringUnitWidth(str) * doc.internal.getFontSize() / doc.internal.scaleFactor;

      // Calculate the X position to align to the right of the page
      var pageWidth = doc.internal.pageSize.getWidth();
      // Adjust for a margin if needed
      doc.setFontSize(10);


      var xPos = pageWidth - textWidth - 10;
      doc.text(pb, 50, pageHeight - 10);

      doc.setFontSize(10);
      doc.text(str , xPos, pageHeight - 10);
    }
  });
  doc.save(fileName + '.pdf');
}
export const generatePDFMultipleTable = ({ rows, columns, headerList = [], columnStylesPlain, columnStyles,pdfTitle, top = 40 }) => {
  var doc = new jsPDF('p', 'pt');

  doc.setFontSize(16);
  doc.setTextColor(40);

  //   doc.setFontStyle('normal');
  const fileName = pdfTitle.split(" ").join("") + "-" + moment().format("DD-MM-YYYY")
  var textWidth = doc.getStringUnitWidth(pdfTitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;

  var pageWidth = doc.internal.pageSize.getWidth();
  var xPos = (pageWidth - textWidth) / 2;

  doc.text(pdfTitle, xPos, 50, { maxWidth: 540 });

  let tableData = []
  let tableList = []
 

  headerList.map((head, index) => {
    if (index % 2 == 0) {
      if (headerList[index + 1] != undefined) {
        tableData.push( {
          content: head.label + " : ",
           styles: { halign: "left" }
        })
        tableData.push(head.value)
      } else {
        tableData.push([
          {
            content: head.label + " : ",
             styles: { halign: "left" }
          },
          {
            content: head.value,
            colSpan: 3,
            // styles: { halign: "left" }
          }
        ])
        tableList.push(...tableData)
        tableData = []
      }
    }else{
      
      tableData.push( {
        content: head.label + " : ",
         styles: { halign: "left" }
      })
        tableData.push(head.value)
        
        tableList.push(tableData)
      tableData = []
    }
  })
  var tableStyles = {
    lineColor: "#c1c1c1", // Border color (black in this example)
    lineWidth: 0.5,       // Border line width
    // fontStyle: 'bold'    // Font style for the table
  };
  doc.autoTable({
    startY: 90,
    head: [], 
    body: tableList,
    theme: 'plain',
    columnStyles:  columnStylesPlain
  });
  // doc.autoTable({
  //   startY: doc.autoTable.previous.finalY + 5,
  //   head: columns, 
  //   body: rows,
  //   theme: 'plain',
  //   styles: tableStyles,
  // });
  rows.map((rowData)=>{
    doc.setFontSize(14);
    doc.text(rowData.title, 20,  doc.autoTable.previous.finalY + 20, { maxWidth: 540 });
    doc.autoTable({
      head: rowData.columns, 
      body: rowData.rows,
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      startY:  doc.autoTable.previous.finalY + 40,
  
      margin: { right: 20, left: 50, top: top },
      headStyles: {
        halign: 'center',
      },
      theme: 'plain',
      styles: tableStyles,
      columnStyles: columnStyles,
      bodyStyles: { valign: 'top' },
  
      showHead: "everyPage",
      didParseCell: (hookData) => {
        if (hookData.row.index === rows.length - 1) {
          // Check if it's the last row
          doc.setFont('helvetica', 'bold'); // Set the font style to bold
          doc.setFontSize(50); // Set the font size (adjust as needed)
        }
      },
      didDrawRow: function (data) {
        if (data.row.index === rows.length - 1) {
          // Check if it's the last row
          doc.setFont('helvetica', 'bold'); // Set the font style to bold
          doc.setFontSize(16); // Set the font size (adjust as needed)
        }
      },
      didDrawPage: function (data) {
  
  
        var str = "Page " + doc.internal.getNumberOfPages();
  
  
  
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        let pb = "Powered By Bizintel DMS"
        const compName = "Company Name"
        var textWidthCN = doc.getStringUnitWidth(compName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        var textWidth = doc.getStringUnitWidth(str) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
        // Calculate the X position to align to the right of the page
        var pageWidth = doc.internal.pageSize.getWidth();
        // Adjust for a margin if needed
        doc.setFontSize(10);
  
  
        var xPos = pageWidth - textWidth - 10;
        doc.text(pb, 50, pageHeight - 10);
  
        doc.setFontSize(10);
        doc.text(str , xPos, pageHeight - 10);
      }
    });
  })
  
  doc.save(fileName + '.pdf');
}
export const generateHeaderPDF = ({ rows, pdfTitle, headerList = [] }) => {
  // Include the jsPDF library in your HTML file
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.4.0/jspdf.umd.min.js"></script>

  var doc = new jsPDF('p', 'pt');
  doc.setFontSize(16);
  doc.setTextColor(40);
  //   doc.setFontStyle('normal');
  const fileName = pdfTitle.split(" ").join("") + "-" + moment().format("DD-MM-YYYY")
  doc.text(pdfTitle, 10, 35, { maxWidth: 540 });
  doc.setFontSize(12);

  headerList.map((head, index) => {
    if (index % 2 == 0) {
      doc.setTextColor(3, 169, 244);
      doc.text(head.label + " : ", 20, calculateYPosition(doc, 10), { maxWidth: 100 });

      doc.setTextColor(0, 0, 0);
      doc.text(head.value, 130, calculateYPosition(doc, 10), { maxWidth: headerList[index + 1] != undefined ? 170 : 480 });

    }
    else {
      doc.setTextColor(3, 169, 244);
      doc.text(head.label + " : ", 300, calculateYPosition(doc, 10), { maxWidth: 100 });

      doc.setTextColor(0, 0, 0);
      doc.text(head.value, 400, calculateYPosition(doc, 10), { maxWidth: 180 });
    }
  })

  rows.map((pdfData, index) => {
    let headInfo = []
    let subHeadInfo = []
    pdfData.reportObj.map((head, index) => {
      if (index % 2 == 0) {
        subHeadInfo = []
        subHeadInfo = [
          { content: head.label + ":" },
          { content: head.value, colSpan: pdfData.reportObj[index + 1] == undefined ? 3 : 0 },
        ]
      }
      else {
        subHeadInfo = [
          ...subHeadInfo,
          { content: head.label + ":" },
          { content: head.value },
        ]
        headInfo.push(subHeadInfo)
      }
    })

    doc.autoTable({
      body: headInfo,
      columnStyles: {
        0: { cellWidth: 80, textColor: [3, 169, 244] },
        1: { cellWidth: 180, textColor: [0, 0, 0] },
        2: { cellWidth: 80, textColor: [3, 169, 244] },
        3: { cellWidth: 180, textColor: [0, 0, 0] },
      },
      theme: "plain"
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 5,
      head: [pdfData.reportColumns], // Header row
      body: pdfData.reportList, // Data rows
      // margin: { left: outerTableX, top: outerTableY },
      tableWidth: 'auto',
      theme: 'grid',
    });
  })




  // Save or display the PDF
  doc.save('nested_table_example.pdf');

}


export const generateExcelForMatrix = ({ columns, headerList, rows, title = "" }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');


  worksheet.getRow(1).font = {
    name: "Comic Sans MS",
    family: 4,
    size: 14,
    bold: true,
    height: 50
  };
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  // worksheet.getColumn(1).alignment = { vertical: 'middle', horizontal: "center", wrapText: true };

  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ']
  columns.map((key, index) => {
    let keyData = alphabets[index] + 1
    worksheet.getCell(keyData).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FFFF00" },
    };
    let headData = alphabets[index] + 3
    worksheet.getCell(headData).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FF0000" },
    };
    worksheet.getCell(headData).value = key;

  });
  const startRow = 1;
  const startColumn = 1;
  const endRow = 1;
  const endColumn = columns.length;

  worksheet.mergeCells(startRow, startColumn, endRow, endColumn);
  worksheet.getCell('A1').value = title;
  headerList.map((key, index) => {
    let keyInfo = alphabets[index] + 2
    worksheet.getCell(keyInfo).font = {
      // name: "Comic Sans MS",
      family: 4,
      size: 14,
      bold: false,
      height: 50,
      color: { argb: index % 2 != 0 ? "004e47cc" : "000" }
    };
    worksheet.getCell(keyInfo).value = key;
  });
  rows.map((dataObj) => {
    worksheet.addRow(dataObj);
  })

  worksheet.columns.forEach((column, colIndex) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      let col = cell._address.split("")
      if (col[1] != 1) {
        const cellText = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellText.length);
      }

    }); 
    column.alignment = { wrapText: true };
    column.width =20; // Set a minimum width
  });

  const fileName = title.split(" ").join("") + " - " + moment().format("DD-MM-YYYY")

  // Save the workbook to a file
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName + ".xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}
export const generateHeaderExcel = ({ rows, title, headerList = [] }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  worksheet.getRow(1).font = {
    name: "Comic Sans MS",
    family: 4,
    size: 14,
    bold: true,
    height: 50
  };
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ']
  const startRow = 1;
  const startColumn = 1;
  const endRow = 1;
  const endColumn = 5;

  worksheet.mergeCells(startRow, startColumn, endRow, endColumn);
  worksheet.getCell('A1').value = title;
  let add = 4
  let addHeader = 3

  rows.map((rowData, index) => {
    worksheet.addRow();
console.log(addHeader+"add"+add)

    let header = []
    rowData.reportObj.map((reportData) => {
      header.push(reportData.label +":")
      header.push(reportData.value)
    })
    header.map((key, index) => {
      let keyInfo = alphabets[index] + addHeader
      worksheet.getCell(keyInfo).font = {
        // name: "Comic Sans MS",
        family: 4,
        size: 14,
        bold: false,
        height: 50,
        color: { argb: index % 2 == 0 ? "004e47cc" : "000" }
      };
      worksheet.getCell(keyInfo).value = key;
    });
    // worksheet.addRow(header);
    
    rowData.reportColumns.map((key, indexData) => {

      let headData = alphabets[indexData] + add
      worksheet.getCell(headData).fill = {
        type: "pattern",
        pattern: "darkVertical",
        fgColor: { argb: "FF0000" },
      };
      worksheet.getCell(headData).value = key;

    });
    rowData.reportList.map((dataObj) => {
      worksheet.addRow(dataObj);
    })
    addHeader = addHeader + rowData.reportList.length + 3
    add = add + rowData.reportList.length + 3
  })


  worksheet.columns.forEach((column, colIndex) => {
    let maxLength = 25;

    column.width = maxLength; // Set a minimum width
  });

  const fileName = moment().format("DD-MM-YYYY")

  // Save the workbook to a file
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName + ".xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}
export const generateExcel = ({ columns, headerList, rows, title = "" }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');


  // worksheet.getRow(1).font = {
  //   name: "Comic Sans MS",
  //   family: 4,
  //   size: 14,
  //   bold: true,
  //   height: 50
  // };
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  // worksheet.getColumn(1).alignment = { vertical: 'middle', horizontal: "center", wrapText: true };

  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ']
  columns.map((key, index) => {
    let keyData = alphabets[index] + 1
    worksheet.getCell(keyData).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FFFFFF" },
    };
    let headData = alphabets[index] + 3
    worksheet.getCell(headData).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FF0000" },
    };
    worksheet.getCell(headData).value = key;

  });
  const startRow = 1;
  const startColumn = 1;
  const endRow = 1;
  const endColumn = columns.length;

  worksheet.mergeCells(startRow, startColumn, endRow, endColumn);
  worksheet.getCell('A1').value = title;
  headerList.map((key, index) => {
    let keyInfo = alphabets[index] + 2
    worksheet.getCell(keyInfo).font = {
      // name: "Comic Sans MS",
      family: 4,
      size: 14,
      bold: false,
      height: 50,
      color: { argb: index % 2 != 0 ? "000" : "000" }
    };
    worksheet.getCell(keyInfo).value = key;
  });
  rows.map((dataObj) => {
    worksheet.addRow(dataObj);
  })

  worksheet.columns.forEach((column, colIndex) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      let col = cell._address.split("")
      if (col[1] != 1) {
        const cellText = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellText.length);
      }

    });
    column.width = maxLength < 10 ? 10 : maxLength + 2; // Set a minimum width
  });

  const fileName = title.split(" ").join("") + " - " + moment().format("DD-MM-YYYY")

  // Save the workbook to a file
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName + ".xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}

export const generateExcelMultiple = ({ columns, headerList, rows, title = "" }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');


  worksheet.getRow(1).font = {
    name: "Comic Sans MS",
    family: 4,
    size: 14,
    bold: true,
    height: 50
  };
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  // worksheet.getColumn(1).alignment = { vertical: 'middle', horizontal: "center", wrapText: true };

  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ']
  columns.map((key, index) => {
    let keyData = alphabets[index] + 1
    worksheet.getCell(keyData).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FFFF00" },
    };
    let headData = alphabets[index] + 3
    worksheet.getCell(headData).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FF0000" },
    };
    worksheet.getCell(headData).value = key;

  });
  const startRow = 1;
  const startColumn = 1;
  const endRow = 1;
  const endColumn = columns.length;

  worksheet.mergeCells(startRow, startColumn, endRow, endColumn);
  worksheet.getCell('A1').value = title;
  headerList.map((key, index) => {
    let keyInfo = alphabets[index] + 2
    worksheet.getCell(keyInfo).font = {
      // name: "Comic Sans MS",
      family: 4,
      size: 14,
      bold: false,
      height: 50,
      color: { argb: index % 2 != 0 ? "004e47cc" : "000" }
    };
    worksheet.getCell(keyInfo).value = key;
  });
  rows.map((dataObj) => {
    worksheet.addRow(dataObj);
  })

  worksheet.columns.forEach((column, colIndex) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      let col = cell._address.split("")
      if (col[1] != 1) {
        const cellText = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellText.length);
      }

    });
    column.width = maxLength < 10 ? 10 : maxLength + 2; // Set a minimum width
  });

  const fileName = title.split(" ").join("") + " - " + moment().format("DD-MM-YYYY")

  // Save the workbook to a file
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName + ".xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}