import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { subVoucherJSON } from "../../../DynamicFormsJson/MastersJSON/subVoucher";
import { getVoucher } from "../../../Slice/subVoucher.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicFormForDocumentUpload from "../../../components/dynamicscreens/DynamicFormForDocumentUpload";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
// import GoogleMap from "./GoogleMap";
class SubVoucherForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        voucherDetails: this.props.subVoucherList?.voucher,
        voucherNatureDetails: [
          { id: 1, name: "Debit" },
          { id: 2, name: "Credit" },
          { id: 3, name: "Contra" },
          { id: 4, name: "None" },
        ],
        stateDetails: this.props.stateList?.stateList,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { openMap } = this.state;
    if (this.props.subVoucherList !== nextProps.subVoucherList) {
      if (
        this.props.subVoucherList?.voucher !== nextProps.subVoucherList?.voucher
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            voucherDetails: nextProps.subVoucherList?.voucher,
          },
        });
      }
    }
  }

  async componentDidMount() {
    if (this.props.params.id == undefined) {
      this.setState({
        showLocation: true,
      });
    }
    const {
      getOutletType,
      getVoucher,
      getStateList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getVoucher().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onCancel = () => {
    this.props.navigate(-1);
  };

  onSaveLocation = () => {
    this.setState({
      openMap: true,
    });
  };

  onRadioChange = (e) => {
    const { value } = e.target;
    this.setState({
      locationValue: value,
    });
    if (value == 1) {
      this.getCurrentLocationData();
    }
  };

  setDataToForm = (data) => {
    const { latitute, longitute } = this.state;
    if (latitute == "" && longitute == "") {
      this.setState({
        latitute: data.latitude,
        longitute: data.longitude,
      });
    }
  };

  render() {
    const {
      dynamicMasterData,
      openMap,
      latitute,
      longitute,
      showLocation,
      locationValue,
    } = this.state;

    return (
      <>
        <>
          <Paper
            sx={{
              p: 2,
              pt: 2,
              borderRadius: 2,
            }}
            elevation={0}
          >
            <LandingScreenHeader
              screenTitle={subVoucherJSON.screenTitle}
              showTable={false}
              showBackButton={true}
              showAddButton={false}
              onCancel={this.onCancel}
            />
            <br />

            <br />
          
            <DynamicForm
              getDataForm={false}
              setDataToForm={this.setDataToForm}
              getExtraData={false}
              // getData={this.getLocationData}
              padding={false}
              paddingTop={false}
              showBackToList={false}
              formPath={subVoucherJSON.formPath}
              fieldMeta={subVoucherJSON.fieldMeta}
              dynamicMasterData={dynamicMasterData}
              apiBaseURL={subVoucherJSON.apiBaseURL}
              showSaveNextBtn={subVoucherJSON.showSaveNextBtn}
            />
          </Paper>
        </>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherList: state.subVoucher,
});
const mapDispatchToProps = {
  getVoucher,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SubVoucherForm);
