import dayjs from "dayjs";

export const CashbalanceJson = {
    screenTitle: "Monthly Balance",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta:
    [
      {
        label: "TDS Applicable",
        controlType: "radiogroup",
        md: 2.7,
        lg: 2.7,
        sm: 2.7,
        xs: 12,
        labelSM: 1,
        labelMD: 1,
        labelLG: 1,
        valueSM: 11,
        valueMD: 11,
        valueLG: 11,
        getData:true,
        hideLabel:true,
        dataKey: "tdsApplicable",
        tableDataKey: "tdsApplicable",
        defaultValue: "1",
        radioGroupItems: [
          {
            label: "Cash",
            value: "1",
          },
          {
            label: "Bank",
            value: "2",
          },
          {
            label: "Other",
            value: "0",
          },
        ],
        isMandatory: true,
      },
      {
        label: "Date Range",
        controlType: "datepicker",
        placeHolder: "",
        md: 2.5,
        lg: 2.5,
        sm: 2.5,
        xs: 12,
        labelSM: 6,
        labelMD: 6,
        labelLG: 6,
        valueSM: 6,
        valueMD: 6,
        valueLG: 6,
        showLabel:true,
        error:"From Date",
        dataKey: "fromDate",
        isMandatory: true,
        onSubmit: "compare",
        onSubmitParameter: "toDate-le",
      },
      {
        label: "",
        controlType: "datepicker",
        placeHolder: "",
        md: 1.3,
        lg: 1.3,
        sm: 1.3,
        xs: 12,
        hideLabel:true,
        labelSM: 1,
        labelMD: 1,
        labelLG: 1,
        valueSM: 11,
        valueMD: 11,
        valueLG: 11,
        dataKey: "toDate",
        onSubmit: "compare",
        error:"To Date",
        onSubmitParameter: "fromDate-ge",
        isMandatory: true,
        showLabel:true,
        onSubmit: "compare",
        defaultValue: dayjs(new Date()),
      },
      {
        label: "",
        controlType: "autocomplete",
        placeHolder: "Select Company",
        md: 2,
        lg: 2,
        sm: 2,
        xs: 12,
        labelSM: 1,
        labelMD: 1,
        labelLG: 1,
        valueSM: 11,
        valueMD: 11,
        valueLG: 11,
        error:"Company",
        masterName: "CompanyDetails",
      hideLabel:true,
        dataKey: "companyId",
        isRootLevelKey: true,
        isMandatory: true,
      },
      {
        label: "",
        controlType: "autocomplete",
        placeHolder: "Select  Account ",
        md: 2,
        lg: 2,
        sm: 2,
        xs: 12,
        labelSM: 1,
        labelMD: 1,
        labelLG: 1,
        valueSM: 11,
        valueMD: 11,
        valueLG: 11,
        error:"accountLedgerGroupsMaster",
        masterName: "accountLedgerGroupMasterDetail",
      hideLabel:true,
        dataKey: "accountLedgerGroupsId",
        isRootLevelKey: true,
        isMandatory: true,
      },
    ]
  };
  