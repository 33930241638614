import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import { saveReport, setSaveReport } from '../../Slice/matrixReport.slice'

import { connect } from "react-redux";
import {
    Grid, RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import RTTable from '../../components/Comman/RT/RTTable'
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import AutoComplete from "../../components/Comman/AutoComplete";
import { validText } from "../../components/Comman/Util/Validations";
import * as msgConstant from "../../config/messageconstant";
import swal from "sweetalert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const AddGraph = ({
    openDialog, setOpenDialouge,
    report,
    columnList = [],
    saveReport,
    showLoador,
    showNotification,
    rowData = {},
    graphColumns = []

}) => {
    const [graphList, setGraphList] = React.useState([
        {
            id: "1",
            name: "Pie chart"
        },
        {
            id: "2",
            name: "Bar Chart"
        },

    ]);
    const [localList, setLocalList] = React.useState([]);
    const [formDataLocal, setFormDataLocal] = React.useState({});
    const [formErrors, setFormError] = React.useState({});

    const [formData, setFormData] = React.useState({});

    const checkValidationAdd = () => {
        let formErrors = {};
        let formIsValid = true;


        if (formDataLocal["name"] === undefined || formDataLocal["name"] == "") {
            formIsValid = false;
            formErrors["yvalue"] = msgConstant.keyMsg;
        }
        if (formDataLocal["label"] === undefined || formDataLocal["label"] == "") {
            formIsValid = false;
            formErrors["ylabel"] = msgConstant.labelMsg;
        }

        setFormError(formErrors)
        return formIsValid;
    };

    React.useEffect(() => {
        if (Object.keys(rowData).length != 0) {
            console.log(rowData)
            if (rowData.yAxis && rowData.yAxis.length != 0 && rowData.graphId == 1) {
                setFormDataLocal(rowData.yAxis[0])
            }

            setFormData({ ...rowData, label: rowData.xAxis.label, value: rowData.xAxis.name })
            setLocalList(rowData.graphId != 1 ? rowData.yAxis.map((localInfo, index) => {
                return { index: index + 1, label: localInfo.label, name: localInfo.name }
            }) : [])
        } else {
            setFormData({})
            setFormDataLocal({})
            setLocalList([])
        }
        setFormError({})
    }, [])
    const handleClose = () => {
        setOpenDialouge(false)

    };
    const onSubmitData = () => {
        if (checkValidation()) {
            let graphData = graphList.filter((row) => row.id == formData.graphId)

            let data = {
                graphName: graphData[0] ? graphData[0].name : "",
                graphId: formData.graphId,
                xAxis: {
                    label: formData["label"],
                    name: formData["value"]
                },
                yAxis: formData["graphId"] != 1 ? localList : [
                    {
                        label: formDataLocal["label"],
                        name: formDataLocal["name"]
                    }
                ]
            }
            console.log(data)
            let parameterList = JSON.parse(report.reportData.graphColumns) ? JSON.parse(report.reportData.graphColumns) : []
            let parameter = ""
            if (formData.index != undefined) {
                const graphList = parameterList.map((graph, index) => {
                    if (formData.index == index) {
                        return data
                    }
                    return graph
                })
                parameter = JSON.stringify([
                    ...graphList
                ])
            } else {
                parameter = JSON.stringify([
                    ...parameterList,
                    data
                ])
            }

            let dataToSave = {
                ...report.reportData,
                graphColumns: parameter
            }

            onSave(dataToSave)
        }

    };
    const onSave = (dataToSave) => {

        if (navigator.onLine) {

            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });

                    handleClose()
                } else {
                    showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };
    const onAddData = () => {
        if (checkValidationAdd()) {
            setFormDataLocal({})
            let localListData = [...localList, { index: localList.length + 1, label: formDataLocal.label, name: formDataLocal.name }]
            setLocalList(localListData)
        }

    };
    const checkValidation = () => {
        let formErrors = {};
        let formIsValid = true;

        if (formData["graphId"] === undefined || formData["graphId"] == "") {
            formIsValid = false;
            formErrors["graphId"] = msgConstant.graphTypeMsg;
        }
        if (formData["label"] === undefined || formData["label"] == "") {
            formIsValid = false;
            formErrors["label"] = msgConstant.labelMsg;
        }
        if (formData["value"] === undefined || formData["value"] == "") {
            formIsValid = false;
            formErrors["value"] = msgConstant.valueMsg;
        }
        if (formData["graphId"] != 2) {
            if (formDataLocal["name"] === undefined || formDataLocal["name"] == "") {
                formIsValid = false;
                formErrors["yvalue"] = msgConstant.valueMsg;
            }
            if (formDataLocal["label"] === undefined || formDataLocal["label"] == "") {
                formIsValid = false;
                formErrors["ylabel"] = msgConstant.labelMsg;
            }
        }


        setFormError(formErrors)
        return formIsValid;
    };
    const onTextFieldDataChange = (event) => {
        if (
            event.target.value === "" ||
            validText.test(event.target.value)
        ) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };
    const onTextFieldLocalDataChange = (event) => {
        if (
            event.target.value === "" ||
            validText.test(event.target.value)
        ) {
            setFormDataLocal({ ...formDataLocal, [event.target.name]: event.target.value });
        }
    };
    const onAutocompleteChange = (name, newValue) => {
        if (newValue != null) {
            setFormData(
                {
                    ...formData,
                    [name]: newValue
                }
            )
        } else {
            setFormData(
                {
                    ...formData,
                    [name]: ""
                }
            )
        }
    };
    const rowDelete = (rowData) => {
        swal({
            title: "Are you sure?",
            text: msgConstant.deleteWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                console.log(rowData)
                const local = localList.filter((row) => row.index != rowData.index)
                setLocalList(
                    local.map((localInfo, index) => { return { index: index + 1, label: localInfo.label, name: localInfo.name } })
                )
            }
        })
    }
    const graphListToShow = () => {
        let graphInfo = []
        const data = graphList.map((graph) => {
            const filterList = graphColumns.filter((row) => row.graphId == graph.id)
            if (filterList.length == 0) {
                graphInfo.push(graph)
            }
        })
        if (formData["index"] != undefined) {
            const filter = graphList.filter((row) => row.id == formData["graphId"])
            if (filter.length != 0) {
                graphInfo.push(filter[0])

            }
        }
        return graphInfo
    }
    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                    fullWidth
                    maxWidth="xl"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Add Graph Details
                    </BootstrapDialogTitle>
                    <DialogContent dividers>

                        <Grid container sx={{ marginTop: 2 }} spacing={1}>
                            <Grid
                                container
                                xs={12}
                                sm={12}
                                md={12}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Graph Type :"}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                >

                                    <AutoComplete
                                        name={"graphId"}
                                        label={"Graph Type"}
                                        placeHolder={"Graph Type"}
                                        keyColName={"id"}
                                        value={formData["graphId"] ? formData["graphId"] : ""}
                                        options={graphListToShow()}
                                        onChange={onAutocompleteChange}
                                        disabled={formData.index == undefined ? false : true}
                                        isError={formErrors["graphId"] ? true : false}
                                        errorText={formErrors["graphId"] ? formErrors["graphId"] : ""}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                sm={12}
                                md={12}
                            >

                                &nbsp;&nbsp;

                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"X-Axis Details :"}
                                />

                                <br />
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                sm={6}
                                md={6}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Label :"}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                >
                                    <TextFieldCompo
                                        placeHolder={"Label"}
                                        size="small"
                                        color="primary"
                                        name={"label"}
                                        id={"label"}
                                        value={formData["label"] ? formData["label"] : ""}
                                        onChange={onTextFieldDataChange}
                                        fullWidth
                                        errorText={formErrors["label"] ? formErrors["label"] : ""}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                sm={6}
                                md={6}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Value :"}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                >
                                    <TextFieldCompo
                                        placeHolder={"Value"}
                                        size="small"
                                        color="primary"
                                        name={"value"}
                                        id={"value"}
                                        value={formData["value"] ? formData["value"] : ""}
                                        onChange={onTextFieldDataChange}
                                        fullWidth
                                        errorText={formErrors["value"] ? formErrors["value"] : ""}
                                    />
                                </Grid>
                            </Grid>



                            <Grid
                                container
                                xs={12}
                                sm={12}
                                md={12}
                            >

                                &nbsp;&nbsp;

                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"Y-Axis Details :"}
                                />

                                <br />
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                sm={6}
                                md={6}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Label :"}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                >
                                    <TextFieldCompo
                                        placeHolder={"Label"}
                                        size="small"
                                        color="primary"
                                        name={"label"}
                                        id={"label"}
                                        value={formDataLocal["label"] ? formDataLocal["label"] : ""}
                                        onChange={onTextFieldLocalDataChange}
                                        fullWidth
                                        errorText={formErrors["ylabel"] ? formErrors["ylabel"] : ""}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                sm={6}
                                md={6}
                            >

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                >
                                    &nbsp;&nbsp;
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label={"*"}
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{
                                            fontSize: 16,
                                            color: titleColor,
                                        }}
                                        label={"Value :"}
                                    />

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                >
                                    <TextFieldCompo
                                        placeHolder={"Value"}
                                        size="small"
                                        color="primary"
                                        name={"name"}
                                        id={"name"}
                                        value={formDataLocal["name"] ? formDataLocal["name"] : ""}
                                        onChange={onTextFieldLocalDataChange}
                                        fullWidth
                                        errorText={formErrors["yvalue"] ? formErrors["yvalue"] : ""}
                                    />
                                </Grid>
                            </Grid>
                            {formData["graphId"] && formData["graphId"] != 1 &&
                                <>
                                    <br />
                                    <Grid
                                        item
                                        sm={12}
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        rowSpacing={2}
                                        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                                        container
                                        justifyContent="right"
                                    >
                                        <ButtonCompo
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            name="Add"
                                            onClick={onAddData}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        sx={{ marginTop: 3 }}
                                    >

                                        <RTTable
                                            columns={[
                                                { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
                                                {
                                                    title: "Label",
                                                    name: "label",
                                                    formDataKey: "label",
                                                    showInExcel: true,
                                                    canSearch: true,
                                                },
                                                {
                                                    title: "Datakey",
                                                    name: "name",
                                                    formDataKey: "name",
                                                    showInExcel: true,
                                                    canSearch: true,
                                                },

                                                { name: "action", title: "Action" },
                                            ]}
                                            hiddenColumnNames={[]}
                                            tableData={localList}
                                            rowDelete={rowDelete}
                                            showPegination={false}
                                            showEditIcon={false}
                                            isActionColActive={true}
                                        />
                                    </Grid>
                                </>
                            }

                        </Grid>
                        <br />
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            rowSpacing={2}
                            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                            container
                            justifyContent="right"
                        >
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="submit"
                                onClick={onSubmitData}
                            />
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
            </Paper>
        </>
    );
};
const mapStateToProps = (state) => ({ report: state.matrixReport });

const mapDispatchToProps = { showLoador, showNotification, setSaveReport, saveReport };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddGraph);
