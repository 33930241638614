import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  requestGRNListData: [],
  requestGRNHeaderDetailsData: {},
  requestGRNHeaderDetails: [],
  requestGRNHeaderData: {},
  pendingGrnList: [],
  grnHistoryByDate: [],
  grnByStakeholderId: [],
  getGrnDataById: [],
};
let URL = endpoints.grnGVNHeader;
let URL1 = endpoints.grnGVNDetail;
const requestGRNSlice = createSlice({
  name: "requestGRN",
  initialState,
  reducers: {
    requestGRNListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNListData: row,
      };
    },

    requestGRNHeaderDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNHeaderDetailsData: row,
      };
    },

    requestGRNHeaderDetailsSucess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNHeaderDetails: row,
      };
    },

    requestGRNHeaderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        requestGRNHeaderData: row,
      };
    },
    GrnHistorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grnHistoryByDate: row,
      };
    },
    grnPendingSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        pendingGrnList: row,
      };
    },

    grnByStakeholderIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grnByStakeholderId: row,
      };
    },

    getGrnSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        getGrnDataById: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  requestGRNListSuccess,
  resetState,
  requestGRNHeaderDetailsSuccess,
  requestGRNHeaderSuccess,
  requestGRNHeaderDetailsSucess,
  grnPendingSuccess,
  GrnHistorySuccess,
  grnByStakeholderIdSuccess,
  getGrnSuccess,
  setGrnDataSuccess,
} = requestGRNSlice.actions;

export default requestGRNSlice.reducer;

export const getRequestGRNList =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-date?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((creditNoteHeaderObject, index) => {
            creditNoteHeaderObject = {
              index: index + 1,
              billId:
                creditNoteHeaderObject.billId == null
                  ? ""
                  : creditNoteHeaderObject.billId,
              cessAmt:
                creditNoteHeaderObject.cessAmt == null
                  ? ""
                  : creditNoteHeaderObject.cessAmt,
              grnGvnNo:
                creditNoteHeaderObject.grnGvnNo == null
                  ? ""
                  : creditNoteHeaderObject.grnGvnNo,
              date:
                creditNoteHeaderObject.date == null
                  ? ""
                  : creditNoteHeaderObject.date,
              toStockStakeHolderOwnerName:
                creditNoteHeaderObject.toStockStakeHolderOwnerName == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolderOwnerName,
              fromStockStakeHolder:
                creditNoteHeaderObject.fromStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolder,
              id:
                creditNoteHeaderObject.id == null
                  ? ""
                  : creditNoteHeaderObject.id,
              insertDateTime:
                creditNoteHeaderObject.insertDateTime == null
                  ? ""
                  : creditNoteHeaderObject.insertDateTime,
              insertedUserId:
                creditNoteHeaderObject.insertedUserId == null
                  ? ""
                  : creditNoteHeaderObject.insertedUserId,
              remark:
                creditNoteHeaderObject.remark == null
                  ? ""
                  : creditNoteHeaderObject.remark,
              status:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status,
              taxableAmt:
                creditNoteHeaderObject.taxableAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxableAmt.toFixed(2),
              totalAmt:
                creditNoteHeaderObject.totalAmt == null
                  ? ""
                  : creditNoteHeaderObject.totalAmt.toFixed(2),
              statusName:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status == 0
                  ? "Pending"
                  : creditNoteHeaderObject.status == 1
                  ? "Send GRN"
                  : creditNoteHeaderObject.status == 2
                  ? "Approved"
                  : "Credit Note Generated",
              taxAmt:
                creditNoteHeaderObject.taxAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxAmt.toFixed(2),
              toStockStakeHolder:
                creditNoteHeaderObject.toStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolder,
            };
            return creditNoteHeaderObject;
          });
          dispatch(requestGRNListSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGRNHeaderDetailsById =
  ({ requestGRNHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + requestGRNHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const grnData = data.data;
          let grnGVNHeaderDetailsData = {
            id: grnData.id === null ? "" : grnData.id,
            billId: grnData.billId === null ? "" : grnData.billId,
            grnGvnNo: grnData.grnGvnNo === null ? "" : grnData.grnGvnNo,
            date: grnData.date === null ? "" : grnData.date,
            fromStockStakeHolder:
              grnData.fromStockStakeHolder === null
                ? ""
                : grnData.fromStockStakeHolder,
            toStockStakeHolder:
              grnData.toStockStakeHolder === null
                ? ""
                : grnData.toStockStakeHolder,
            status: grnData.status === null ? "" : grnData.status,
            statusName: grnData.status === null ? "" : grnData.status,
            taxableAmt:
              grnData.taxableAmt === null ? "" : grnData.taxableAmt.toFixed(2),
            totalAmt:
              grnData.totalAmt === null ? "" : grnData.totalAmt.toFixed(2),
            statusName:
              grnData.status === null
                ? ""
                : grnData.status == 0
                ? "Pending"
                : grnData.status == 1
                ? "Send GRN"
                : grnData.status == 2
                ? "Approved"
                : grnData.status == 3
                ? "Credit Note Generated"
                : "",
            insertedUserId:
              grnData.insertedUserId === null ? "" : grnData.insertedUserId,
            insertDateTime:
              grnData.insertDateTime === null ? "" : grnData.insertDateTime,
            grnGvnDetails:
              grnData.grnGvnDetails === null
                ? ""
                : grnData.grnGvnDetails.map((orderHistoryObject, index) => {
                    let orderHistoryData = {
                      index: index + 1,
                      id:
                        orderHistoryObject.id === null
                          ? ""
                          : orderHistoryObject.id,
                      productName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.name +
                            " ( " +
                            orderHistoryObject.product.shortName +
                            " ) ",
                      uomName:
                        orderHistoryObject.uom === null
                          ? ""
                          : orderHistoryObject.uom.name,
                      outletUomName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.outletUom != null
                          ? orderHistoryObject.product.outletUom.name
                          : "",
                      stakeHolderUomName:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product.stakeHolderUom != null
                          ? orderHistoryObject.product.stakeHolderUom.name
                          : "",

                      batchCode:
                        orderHistoryObject.batchCode === null
                          ? ""
                          : orderHistoryObject.batchCode,
                      rate:
                        orderHistoryObject.rate === null
                          ? ""
                          : orderHistoryObject.rate.toFixed(2),
                      qty:
                        orderHistoryObject.qty === null
                          ? ""
                          : +orderHistoryObject.qty,
                      approvedQty:
                        orderHistoryObject.approvedQty === null
                          ? 0
                          : orderHistoryObject.approvedQty,
                      approvedQtyError: 0,
                      taxableAmt:
                        orderHistoryObject.taxableAmt === null
                          ? ""
                          : orderHistoryObject.taxableAmt.toFixed(2),
                      totalAmt:
                        orderHistoryObject.totalAmt === null
                          ? ""
                          : orderHistoryObject.totalAmt.toFixed(2),
                      totalAmtForPendingGrn:
                        orderHistoryObject.totalAmt === null
                          ? ""
                          : orderHistoryObject.totalAmt.toFixed(2),
                      product:
                        orderHistoryObject.product === null
                          ? ""
                          : orderHistoryObject.product,
                      uom:
                        orderHistoryObject.uom === null
                          ? ""
                          : orderHistoryObject.uom,

                      billDetailId:
                        orderHistoryObject.billDetailId === null
                          ? ""
                          : orderHistoryObject.billDetailId,
                      cessAmt:
                        orderHistoryObject.billDetailId === null
                          ? ""
                          : orderHistoryObject.billDetailId,
                      cessPer:
                        orderHistoryObject.cessPer === null
                          ? ""
                          : orderHistoryObject.cessPer,
                      cgstAmt:
                        orderHistoryObject.cgstAmt === null
                          ? ""
                          : orderHistoryObject.cgstAmt,
                      cgstPer:
                        orderHistoryObject.cgstPer === null
                          ? ""
                          : orderHistoryObject.cgstPer,
                      grnPer:
                        orderHistoryObject.grnPer === null
                          ? ""
                          : orderHistoryObject.grnPer,
                      grnRate:
                        orderHistoryObject.grnRate === null
                          ? ""
                          : orderHistoryObject.grnRate,
                      hsnCode:
                        orderHistoryObject.hsnCode === null
                          ? ""
                          : orderHistoryObject.hsnCode,
                      id:
                        orderHistoryObject.id === null
                          ? ""
                          : orderHistoryObject.id,
                      igstAmt:
                        orderHistoryObject.igstAmt === null
                          ? ""
                          : orderHistoryObject.igstAmt,
                      igstPer:
                        orderHistoryObject.igstPer === null
                          ? ""
                          : orderHistoryObject.igstPer,
                      mrp:
                        orderHistoryObject.mrp === null
                          ? ""
                          : orderHistoryObject.mrp,
                      primaryApprovedQty:
                        orderHistoryObject.primaryApprovedQty === null
                          ? ""
                          : orderHistoryObject.primaryApprovedQty,
                      primaryQty:
                        orderHistoryObject.primaryQty === null
                          ? ""
                          : orderHistoryObject.primaryQty,
                      orderDate:
                        orderHistoryObject.orderDate === null
                          ? ""
                          : orderHistoryObject.orderDate,
                      stockStakeHolder:
                        orderHistoryObject.stockStakeHolder === null
                          ? ""
                          : orderHistoryObject.stockStakeHolder,
                      type:
                        orderHistoryObject.type === null
                          ? ""
                          : orderHistoryObject.type,
                      insertedUserId:
                        orderHistoryObject.insertedUserId === null
                          ? ""
                          : orderHistoryObject.insertedUserId,
                      insertDateTime:
                        orderHistoryObject.insertDateTime === null
                          ? ""
                          : orderHistoryObject.insertDateTime,
                      uomConversionValue:
                        orderHistoryObject.uomConversionValue === null
                          ? ""
                          : orderHistoryObject.uomConversionValue,
                    };
                    return orderHistoryData;
                  }),
          };
          dispatch(
            requestGRNHeaderDetailsSuccess({ row: grnGVNHeaderDetailsData })
          );
          dispatch(
            requestGRNHeaderDetailsSucess({
              row: grnGVNHeaderDetailsData.grnGvnDetails,
            })
          );
          return { response: grnGVNHeaderDetailsData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setRequestGRNDetails =
  ({ row }) =>
  async (dispatch) => {
    const rowList = row.map((rowObject, index) => {
      const addObj = {
        ...rowObject,
        totalAmt: (+rowObject.qty * +rowObject.rate).toFixed(2),
        qty: rowObject.qty,
        outLetUom: rowObject.outLetUom,
        totalAmtForPendingGrn: (
          +rowObject.approvedQty * +rowObject.rate
        ).toFixed(2),
      };
      return addObj;
    });
    dispatch(requestGRNHeaderDetailsSucess({ row: rowList }));
  };

export const getGRNHeaderById =
  ({ requestGRNHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-id?id=" + requestGRNHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const grnData = data.data;
          let grnGVNHeaderData = {
            id: grnData.id === null ? "" : grnData.id,
            billId: grnData.billId === null ? "" : grnData.billId,
            grnGvnNo: grnData.grnGvnNo === null ? "" : grnData.grnGvnNo,
            date: grnData.date === null ? "" : grnData.date,
            fromStockStakeHolder:
              grnData.fromStockStakeHolder === null
                ? ""
                : grnData.fromStockStakeHolder,
            fromStockStakeHolderFirmName:
              grnData.fromStockStakeHolderFirmName === null
                ? ""
                : grnData.fromStockStakeHolderFirmName,
            fromStockStakeHolderOwnerName:
              grnData.fromStockStakeHolderOwnerName === null
                ? ""
                : grnData.fromStockStakeHolderOwnerName,
            toStockStakeHolder:
              grnData.toStockStakeHolder === null
                ? ""
                : grnData.toStockStakeHolder,
            status: grnData.status === null ? "" : grnData.status,
            remark: grnData.remark === null ? "" : grnData.remark,
            taxableAmt:
              grnData.taxableAmt === null ? "" : grnData.taxableAmt.toFixed(2),
            totalAmt:
              grnData.totalAmt === null ? "" : grnData.totalAmt.toFixed(2),
            insertedUserId:
              grnData.insertedUserId === null ? "" : grnData.insertedUserId,
            insertDateTime:
              grnData.insertDateTime === null ? "" : grnData.insertDateTime,
          };
          dispatch(requestGRNHeaderSuccess({ row: grnGVNHeaderData }));
          return { response: grnGVNHeaderData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getPendingGrnList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/pending-to-approve",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((pendingGrnObject, index) => {
          let pendingGrnData = {
            index: index + 1,
            id: pendingGrnObject.id === null ? "" : pendingGrnObject.id,
            billId:
              pendingGrnObject.billId === null ? "" : pendingGrnObject.billId,
            grnGvnNo:
              pendingGrnObject.grnGvnNo === null
                ? ""
                : pendingGrnObject.grnGvnNo,
            date: pendingGrnObject.date === null ? "" : pendingGrnObject.date,
            ownerAndFirmName:
              pendingGrnObject.fromStockStakeHolderOwnerName === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderOwnerName +
                  " (" +
                  pendingGrnObject.fromStockStakeHolderFirmName +
                  ")",
            fromStockStakeHolderOwnerName:
              pendingGrnObject.fromStockStakeHolderOwnerName === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderOwnerName,
            fromStockStakeHolder:
              pendingGrnObject.fromStockStakeHolder === null
                ? ""
                : pendingGrnObject.fromStockStakeHolder,
            fromStockStakeHolderFirmName:
              pendingGrnObject.fromStockStakeHolderFirmName === null
                ? ""
                : pendingGrnObject.fromStockStakeHolderFirmName,

            toStockStakeHolder:
              pendingGrnObject.toStockStakeHolder === null
                ? ""
                : pendingGrnObject.toStockStakeHolder,
            toStockStakeHolderFirmName:
              pendingGrnObject.toStockStakeHolderFirmName === null
                ? ""
                : pendingGrnObject.toStockStakeHolderFirmName,
            toStockStakeHolderOwnerName:
              pendingGrnObject.toStockStakeHolderOwnerName === null
                ? ""
                : pendingGrnObject.toStockStakeHolderOwnerName,
            taxableAmt:
              pendingGrnObject.taxableAmt === null
                ? ""
                : pendingGrnObject.taxableAmt.toFixed(2),
            taxAmt:
              pendingGrnObject.taxAmt === null ? "" : pendingGrnObject.taxAmt,
            cessAmt:
              pendingGrnObject.cessAmt === null ? "" : pendingGrnObject.cessAmt,
            totalAmt:
              pendingGrnObject.totalAmt === null
                ? ""
                : pendingGrnObject.totalAmt.toFixed(2),
          };
          return pendingGrnData;
        });
        dispatch(grnPendingSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getGrnearchList =
  ({ fromdate, todate, holderIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/history?fromDate=" +
          fromdate +
          "&toDate=" +
          todate +
          "&holderIds=" +
          holderIds,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((creditNoteHeaderObject, index) => {
            creditNoteHeaderObject = {
              index: index + 1,
              id:
                creditNoteHeaderObject.id == null
                  ? ""
                  : creditNoteHeaderObject.id,
              grnGvnNo:
                creditNoteHeaderObject.grnGvnNo == null
                  ? ""
                  : creditNoteHeaderObject.grnGvnNo,
              date:
                creditNoteHeaderObject.date == null
                  ? ""
                  : creditNoteHeaderObject.date,
              billId:
                creditNoteHeaderObject.billId == null
                  ? ""
                  : creditNoteHeaderObject.billId,
              cessAmt:
                creditNoteHeaderObject.cessAmt == null
                  ? ""
                  : creditNoteHeaderObject.cessAmt.toFixed(2),
              fromStockStakeHolderOwnerName:
                creditNoteHeaderObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolderFirmName +
                    " ( " +
                    (creditNoteHeaderObject.fromStockStakeHolderOwnerName ===
                    null
                      ? ""
                      : creditNoteHeaderObject.fromStockStakeHolderOwnerName) +
                    " ) ",
              fromStockStakeHolder:
                creditNoteHeaderObject.fromStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.fromStockStakeHolder,

              insertDateTime:
                creditNoteHeaderObject.insertDateTime == null
                  ? ""
                  : creditNoteHeaderObject.insertDateTime,
              insertedUserId:
                creditNoteHeaderObject.insertedUserId == null
                  ? ""
                  : creditNoteHeaderObject.insertedUserId,
              remark:
                creditNoteHeaderObject.remark == null
                  ? ""
                  : creditNoteHeaderObject.remark,
              status:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status,

              statusName:
                creditNoteHeaderObject.status == null
                  ? ""
                  : creditNoteHeaderObject.status == 0
                  ? "Pending"
                  : creditNoteHeaderObject.status == 1
                  ? "Send GRN"
                  : creditNoteHeaderObject.status == 2
                  ? "Approved"
                  : "Credit Note Generated",
              taxAmt:
                creditNoteHeaderObject.taxAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxAmt.toFixed(2),
              taxableAmt:
                creditNoteHeaderObject.taxableAmt == null
                  ? ""
                  : creditNoteHeaderObject.taxableAmt.toFixed(2),
              totalAmt:
                creditNoteHeaderObject.totalAmt == null
                  ? ""
                  : creditNoteHeaderObject.totalAmt.toFixed(2),
              toStockStakeHolder:
                creditNoteHeaderObject.toStockStakeHolder == null
                  ? ""
                  : creditNoteHeaderObject.toStockStakeHolder,
            };
            return creditNoteHeaderObject;
          });
          dispatch(GrnHistorySuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGRNByStakeholderId =
  ({ holderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/approved?holderId=" + holderId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((grnByStakeholderObject, index) => {
            let grnByStakeholderData = {
              index: index + 1,
              id:
                grnByStakeholderObject.id === null
                  ? ""
                  : grnByStakeholderObject.id,
              name:
                grnByStakeholderObject.grnGvnNo === null
                  ? ""
                  : grnByStakeholderObject.grnGvnNo +
                    " - " +
                    grnByStakeholderObject.date,
              billId:
                grnByStakeholderObject.billId === null
                  ? ""
                  : grnByStakeholderObject.billId,
              date:
                grnByStakeholderObject.date === null
                  ? ""
                  : grnByStakeholderObject.date,
              grnGvnNo:
                grnByStakeholderObject.grnGvnNo === null
                  ? ""
                  : grnByStakeholderObject.grnGvnNo,
              fromStockStakeHolder:
                grnByStakeholderObject.fromStockStakeHolder === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                grnByStakeholderObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolderFirmName,
              toStockStakeHolder:
                grnByStakeholderObject.toStockStakeHolder === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolder,
              toStockStakeHolderFirmName:
                grnByStakeholderObject.toStockStakeHolderFirmName === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolderFirmName,
              toStockStakeHolderOwnerName:
                grnByStakeholderObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : grnByStakeholderObject.toStockStakeHolderOwnerName,
              fromStockStakeHolderOwnerName:
                grnByStakeholderObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : grnByStakeholderObject.fromStockStakeHolderOwnerName,
              taxableAmt:
                grnByStakeholderObject.taxableAmt === null
                  ? ""
                  : grnByStakeholderObject.taxableAmt.toFixed(2),
              taxAmt:
                grnByStakeholderObject.taxAmt === null
                  ? ""
                  : grnByStakeholderObject.taxAmt.toFixed(2),
              cessAmt:
                grnByStakeholderObject.cessAmt === null
                  ? ""
                  : grnByStakeholderObject.cessAmt.toFixed(2),
              totalAmt:
                grnByStakeholderObject.totalAmt === null
                  ? ""
                  : grnByStakeholderObject.totalAmt.toFixed(2),
              status:
                grnByStakeholderObject.status === null
                  ? ""
                  : grnByStakeholderObject.status,
              remark:
                grnByStakeholderObject.remark === null
                  ? ""
                  : grnByStakeholderObject.remark,
              insertedUserId:
                grnByStakeholderObject.insertedUserId === null
                  ? ""
                  : grnByStakeholderObject.insertedUserId,
              insertDateTime:
                grnByStakeholderObject.insertDateTime === null
                  ? ""
                  : grnByStakeholderObject.insertDateTime,
            };
            return grnByStakeholderData;
          });
          dispatch(grnByStakeholderIdSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGrnData =
  ({ grnGvnHeaderIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/pending-for-crn?grnGvnHeaderIds=" + grnGvnHeaderIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((grnDataObject, index) => {
            let grnData = {
              index: index + 1,
              id: grnDataObject.id === null ? "" : grnDataObject.id,
              grnGvnHeader:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader,
              grnGvnNo:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader.grnGvnNo,
              date:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader.date,
              product:
                grnDataObject.product === null ? "" : grnDataObject.product,
              productName:
                grnDataObject.product === null
                  ? ""
                  : grnDataObject.product.name,
              outletUom:
                grnDataObject.product === null
                  ? ""
                  : grnDataObject.product.outletUom.name,
              rate:
                grnDataObject.rate === null
                  ? ""
                  : grnDataObject.rate.toFixed(2),
              qty: grnDataObject.qty === null ? "" : grnDataObject.qty,
              approvedQty:
                grnDataObject.approvedQty === null
                  ? ""
                  : grnDataObject.approvedQty,
              totalAmtForPending:
                grnDataObject.totalAmt === null
                  ? ""
                  : grnDataObject.totalAmt.toFixed(2),
              stakeHolderUom:
                grnDataObject.product === null
                  ? ""
                  : grnDataObject.product.stakeHolderUom.name,

              status:
                grnDataObject.grnGvnHeader === null
                  ? ""
                  : grnDataObject.grnGvnHeader.status,

              primaryQty:
                grnDataObject.primaryQty === null
                  ? ""
                  : grnDataObject.primaryQty,
              primaryApprovedQty:
                grnDataObject.primaryApprovedQty === null
                  ? ""
                  : grnDataObject.primaryApprovedQty,
              uomConversionValue:
                grnDataObject.uomConversionValue === null
                  ? ""
                  : grnDataObject.uomConversionValue,
              uom: grnDataObject.uom === null ? "" : grnDataObject.uom,
              hsnCode:
                grnDataObject.hsnCode === null ? "" : grnDataObject.hsnCode,
              mrp: grnDataObject.mrp === null ? "" : grnDataObject.mrp,
              grnPer: grnDataObject.grnPer === null ? "" : grnDataObject.grnPer,
              grnRate:
                grnDataObject.grnRate === null ? "" : grnDataObject.grnRate,
              taxableAmt:
                grnDataObject.taxableAmt === null
                  ? ""
                  : grnDataObject.taxableAmt,
              cgstAmt:
                grnDataObject.cgstAmt === null ? "" : grnDataObject.cgstAmt,
              sgstAmt:
                grnDataObject.sgstAmt === null ? "" : grnDataObject.sgstAmt,
              igstAmt:
                grnDataObject.igstAmt === null ? "" : grnDataObject.igstAmt,
              totalAmt:
                grnDataObject.totalAmt === null
                  ? ""
                  : grnDataObject.totalAmt.toFixed(2),

              cgstPer:
                grnDataObject.cgstPer === null ? "" : grnDataObject.cgstPer,
              sgstPer:
                grnDataObject.sgstPer === null ? "" : grnDataObject.sgstPer,
              igstPer:
                grnDataObject.igstPer === null ? "" : grnDataObject.igstPer,
              cessPer:
                grnDataObject.cessPer === null ? "" : grnDataObject.cessPer,
              cessAmt:
                grnDataObject.cessAmt === null ? "" : grnDataObject.cessAmt,
              billDetailId:
                grnDataObject.billDetailId === null
                  ? ""
                  : grnDataObject.billDetailId,
              batchCode:
                grnDataObject.batchCode === null ? "" : grnDataObject.batchCode,
              insertedUserId:
                grnDataObject.insertedUserId === null
                  ? ""
                  : grnDataObject.insertedUserId,
              insertDateTime:
                grnDataObject.insertDateTime === null
                  ? ""
                  : grnDataObject.insertDateTime,
            };

            return grnData;
          });
          dispatch(getGrnSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const setGrnData =
  ({ row }) =>
  async (dispatch) => {
    const rowList = row.map((rowObject, index) => {
      const addObj = {
        ...rowObject,
        totalAmtForPending: (rowObject.rate * +rowObject.approvedQty).toFixed(
          2
        ),
      };
      return addObj;
    });
    dispatch(getGrnSuccess({ row: rowList }));
  };
