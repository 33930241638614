import endpoint from "../../config/endpoints";

export const stockEvaluationJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.stockValue,
  formPath: "/stock-evaluation-form",
  formPathView: "/day-wise-beat-assign-view",
  screenTitle: "Stock Evaluation ",
  showTitle: true,
  showCancel: false,
  showSaveBtn: true,
  fieldMeta: [
    {
      label: "Year",
      controlType: "autocomplete",
      placeHolder: "Select Year ",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
      labelSM: 3,
      labelMD: 3,
      labelLG: 3,
      valueSM: 9,
      valueMD: 9,
      valueLG: 9,
      masterName: "yearDetails",
      dataKey: "yearId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Location",
      controlType: "multiSelect",
      placeHolder: "Select Location ",
      md:7.5,
        lg:7.5,
        sm:7.5,
        xs: 12,
        labelSM: 1.4,
        labelMD: 1.4,
        labelLG: 1.4,
        valueSM: 10.6,
        valueMD: 10.6,
        valueLG: 10.6,
      masterName: "locationDetails",
      dataKey: "locationIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [],
  searchList: [],
};
