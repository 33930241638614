import { useState, useEffect } from "react";
import RTTable from "../../components/Comman/RT/RTTable";
import { apiGet } from "../../utils/api_service";
import { serverMsg, urlMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { AccountSummaryByPlantJson } from "../../DynamicFormsJson/Report/AccGroupsummaryPlant";
import { Paper,Grid } from "@mui/material";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import moment from "moment";
import GroupSummaryByPlantSubreportByGroup from "./GroupSummaryByPlantSubReportByGroup";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import { connect } from "react-redux";
import { getLocation } from "../../Slice/accountLedger.slice";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import BarChart from '../../components/Charts/BarChart';
const GroupSummaryByPlant = ({ showLoador, showNotification, getLocation }) => {
  const [rowList, setRowList] = useState([]);
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [openTable, setOpenTable] = useState(false);
  const [popUpData, setPopupdata] = useState({});
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [creditTotalAmt, setCreditTotalAmt] = useState("");
  const [debitTotalAmt, setDebitTotalAmt] = useState("");
  const [opBalance, setOpBalance] = useState("");
  const [fieldData, setFieldData] = useState({});

  useEffect( () => {
    
    let valuesArray = [];
    let locationDetails = [];
    const locationIdList =  localStorage.getItem("locationIdList");
    if (locationIdList) {
      valuesArray = JSON.parse(locationIdList);
    }
    valuesArray.length != 0 &&
      valuesArray.map((location) => {
        locationDetails.push({ ...location,
          companyId:
            location.company != null && location.company.id
              ? location.company.id
              : "",
          companyName:
            location.company != null && location.company.companyName
              ? location.company.companyName
              : "",})
         
        })
    if (locationDetails.length != 0) {
      setFieldData({ locationId: locationDetails[0].id });
    }
    console.log("valuesArray");
    console.log(valuesArray);
    setDynamicMasterData({
      locationDetails: locationDetails,
    });
  }, []);
  const onSave = (searchData) => {
    let filterDta = dynamicMasterData["locationDetails"].filter(
      (row) => row.id === searchData.locationId
    );

    let companyId = filterDta.length != 0 ? filterDta[0].companyId : "";
    let locationId = filterDta.length != 0 ? filterDta[0].id : "";
    setFormData({
      ...searchData,
      companyName: filterDta.length != 0 ? filterDta[0].companyName : "",
      companyId: companyId,
      locationId: locationId,
    });
    if (searchData != null) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.AccGroupSummaryByPlant +
          "?companyIds=" +
          companyId +
          "&fromDate=" +
          searchData.fromDate +
          "&toDate=" +
          searchData.toDate +
          "&locationId=" +
          locationId,
      }).then(({ data, success }) => {
        showLoador({ loador: true });
        if (success) {
          console.log(data);
          let rowData = data.data;
          let index = 0;
          let cerditTotal = 0;
          let debitTotal = 0;
          let opBalance = 0;
          let rowList = rowData.map((row) => {
            cerditTotal = cerditTotal + row.creditAmt;
            debitTotal = debitTotal + row.debitAmt;

            return {
              nameLink: true,
              groupId: row.groupId,
              groupName: row.groupName,

              debitAmt: Math.abs(+row.debitAmt).toFixed(2),
              creditAmt: Math.abs(+row.creditAmt).toFixed(2),
              flag:0,
            };
          });
          opBalance = cerditTotal + debitTotal;
          let cA = opBalance >= 0 ? Math.abs(opBalance).toFixed(2) : "";
          let dA = opBalance < 0 ? Math.abs(opBalance).toFixed(2) : "";
          rowList.push({
            nameLink: false,
            groupName: "Total",
            debitAmt: Math.abs(+debitTotal).toFixed(2),
            creditAmt: Math.abs(+cerditTotal).toFixed(2),
            flag:1,
          });
          rowList.push({
            nameLink: false,
            groupName: "Diff. in Op Balance",
            debitAmt: dA,
            creditAmt: cA,
            flag:1,
          });

          setRowList(rowList);
          showLoador({ loador: false });
        } else {
          showLoador({ loador: false });
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
      });
    }
  };

  const rowLink = (rowData) => {
    console.log("rowData");
    console.log(rowData);

    setPopupdata(rowData);
    setOpenTable(!openTable);
  };
  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };
  const generatePDFMethod = () => {
    var columns = [["Account Head", "Debit", "Credit"]];
    let tableList = [];

    rowList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);
      tableData.push(rowData.debitAmt);

      tableData.push(rowData.creditAmt);

      tableList.push(tableData);
    });

    // tableList.push("Total");
    // tableList.push("");
    // tableList.push({creditAmt:creditTotalAmt});
    // tableList.push({debitAmt:debitTotalAmt});

    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    // if(creditTotalAmt>= debitTotalAmt){
    //   tableList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})

    // }else{
    //   tableList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
    // }
    const columnStyles = {
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate,
        },
        {
          label: "Company Name",
          value: formData.companyName,
        },
      ],
      pdfTitle: "Group Summary By Plant",
    });
  };

  const getFilteredTableData = (tableData) => {
    let listData = tableData.filter((row) => {
      let isValid = false;

      if (
        row.groupName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.debitAmt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.creditAmt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      // if (creditTotalAmt.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      // if (debitTotalAmt.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      return isValid;
    });
    return listData;
  };
  const generateExcelMethod = () => {
    var columns = ["Sr No.", "Account Head", "Debit", "Credit"];
    let alignList = [];

    let rows = [];

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.groupName);
      reportObj.push(Math.abs(reportData.debitAmt));
      reportObj.push(Math.abs(reportData.creditAmt));

      rows.push(reportObj);

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Date Range ",
        formData.fromDate + " To " + formData.toDate,
        "Company Name",
        formData.companyName,
      ],
      title: "Group Summary By Plant",
    });
  };

  const toggle = () => {
    setOpenTable(!openTable);
    if(formData!=null && Object.keys(formData).length!=0){
     
      onSave(formData)
  }
  };
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithoutSave
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          showSaveBtn={AccountSummaryByPlantJson.showSaveBtn}
          screenTitle={AccountSummaryByPlantJson.screenTitle}
          fieldMeta={AccountSummaryByPlantJson.fieldMeta}
          showCancel={AccountSummaryByPlantJson.showCancel}
          apiBaseURL={AccountSummaryByPlantJson.apiBaseURL}
          showSaveNextBtn={AccountSummaryByPlantJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          fieldData={fieldData}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
        />

        <RTTable
          showPegination={false}
          columns={[
            {
              name: "groupName",
              title: "Account Head",
              linkFlag: true,
              linkName: "nameLink",
            },

            { name: "debitAmt", title: "Debit", align: "right" },
            { name: "creditAmt", title: "Credit", align: "right" },
          ]}
          hiddenColumnNames={[]}
          tableData={getFilteredTableData(rowList)}
          rowLink={rowLink}
          isActionColActive={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
        />
      </Paper>
      {
                rowList.length != 0 && <Grid sx={{ paddingTop: 2 }} container spacing={1} justifyContent="center">
                    <Grid container xs={12} sm={8} md={8} lg={8}>
                    <Grid sx={{ padding: 1 }} item xs={12} sm={10} md={10} lg={10}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Group Summary'}
                                    />
                                </div>

                                <BarChart
                                height={200}
                                    series={[{
                                      name:"debit amount",
                                        data:rowList.filter((row)=>row.flag==0 && row.debitAmt!="" ).map((rowData) => {
                                            return rowData.debitAmt
                                        }),
                                    }, {
                                      name:"credit amount",
                                        data: rowList.filter((row)=>row.flag==0 && row.creditAmt!="" ).map((rowData) => {
                                            return rowData.creditAmt
                                        }),
                                    }]}
                                    options={
                                        {
                                            chart: {
                                                type: 'bar',
                                                height: 200,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },

                                            xaxis: {
                                                categories: rowList.filter((row)=>row.flag==0 && row.groupName!="" &&row.groupName!="Total").map((rowData) => {
                                                    return rowData.groupName
                                                }),
                                            },
                                            grid: {
                                                show: false,
                                            },


                                        }
                                    }

                                />
                                
                            </Paper>
                        </Grid>
                       
                        
                    </Grid>
                </Grid>
            }
      {openTable && (
        <GroupSummaryByPlantSubreportByGroup
          open={openTable}
          handleCloses={toggle}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          dynamicMasterData={formData}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  locationList: state.accountLedger,
});

const mapDispatchToProps = { showLoador, showNotification, getLocation };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupSummaryByPlant);
