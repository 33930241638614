import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { accessRoleColumns } from "../../tableColumns/table-columns";
import { userAccessJson } from "../../DynamicFormsJson/MastersJSON/userAccess";
import { getAccessRoleList } from "../../Slice/accessRole.slice";

class UserAccess extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { accessRole, getAccessRoleList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={userAccessJson.formPath}
          screenTitle={userAccessJson.screenTitle}
          fieldMeta={userAccessJson.fieldMeta}
          showPdfDownload={userAccessJson.showPdfDownload}
          showExcelDownload={userAccessJson.showExcelDownload}
          pdfFileName={userAccessJson.pdfFileName}
          excelFileName={userAccessJson.excelFileName}
          showAddButton={userAccessJson.showAddButton}
          tableColumnsToFilter={userAccessJson.tableColumnsToFilter}
          tableColumns={accessRoleColumns}
          showDeleteIcon={false}
          tableData={accessRole?.accessRole}
          getTableData={getAccessRoleList}
          onDelete={this.onDelete}
          onSave={this.onSave}
          apiBaseURL={userAccessJson.apiBaseURL}
          baseIdColumn={userAccessJson.baseIdColumn}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accessRole: state.accessRole,
});
const mapDispatchToProps = {
  getAccessRoleList,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
