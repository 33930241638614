import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  journalVoucher: [],
  journalVoucherPendingList: [],
  costCenter: [],
  jvById: {},
};
let URL = endpoints.journalEntryHeader;
const jvSlice = createSlice({
  name: "journalVoucher",
  initialState,
  reducers: {
    journalVoucherSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        journalVoucher: row,
      };
    },
    JVPendingListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        journalVoucherPendingList: row,
      };
    },
    costCenterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        costCenter: row,
      };
    },
    jvByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        jvById: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  journalVoucherSuccess,
  jvByIdSuccess,
  costCenterSuccess,
  JVPendingListSuccess,
  resetState,
} = jvSlice.actions;

export default jvSlice.reducer;

export const getJVById =
  ({ id }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url: URL + "/" + id,
        }).then(({ data, success }) => {
          if (success) {
            let row = {
              ...data.data,
              journalEntryDetailList: data.data.journalEntryDetailList.map(
                (rowData, index) => {
                  let recieptPayData = {
                    index: index + 1,
                    ...rowData,
                  };
                  return recieptPayData;
                }
              ),
            };
            console.log(row);
            dispatch(jvByIdSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getJVWithAccNameById =
  ({ id }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url: URL + "/get-journal-entry-header-with-accName/" + id,
        }).then(({ data, success }) => {
          if (success) {
            let row = {
              ...data.data,
              journalEntryDetailList: data.data.journalEntryDetailList.map(
                (rowData, index) => {
                  console.log(rowData)
                  let recieptPayData = {
                    index: index + 1,
                    mainAccountId: rowData.mainAccountId,
                    accountLedgerName: rowData.accountLedgerName,
                    amt: rowData.amt,
                    type: rowData.type,
                    journalEntryDetailCcInfoList: rowData.journalEntryDetailCcInfoList != null ? rowData.journalEntryDetailCcInfoList : [],

                    // ...rowData,
                    voucherAmount: rowData.voucherAmount,
                  };
                  return recieptPayData;
                }
              ),
            };
            console.log(row);
            dispatch(jvByIdSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getJV =
  ({ subVoucherId, locationId, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url:
            URL +
            "/get-journal-entry-header-list?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate +
            "&subVoucherId=" +
            subVoucherId +
            "&locationId=" +
            locationId,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const content = data.data;
            const row = content.map((rowData, index) => {
              let areaForBeatData = {
                index: index + 1,
                ...rowData,
                jvNo: rowData.jvNo != null ? rowData.jvNo : " - ",
                date: rowData.date != null ? rowData.date : "",
                accountLedgerName:
                  rowData.accountLedgerName != null
                    ? rowData.accountLedgerName
                    : "",
                voucherAmount:
                  rowData.voucherAmount != null
                    ? Math.abs(+rowData.voucherAmount)
                    : "",
                voucherName:
                  rowData.voucherName != null ? rowData.voucherName : "",
                statusName:
                  rowData.status != null
                    ? rowData.status == 1
                      ? "Approved"
                      : "Pending"
                    : "",
                color:
                  rowData.status != null
                    ? rowData.status == 1
                      ? "success"
                      : "error"
                    : "",
              };
              return areaForBeatData;
            });
            dispatch(journalVoucherSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getJVPendingList =
  ({ type, locationId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url: URL + "/pending-list/" + type + "/" + locationId,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const content = data.data;
            const row = content.map((rowData, index) => {
              let areaForBeatData = {
                index: index + 1,
                jvNo: rowData.jvNo != null ? rowData.jvNo : " - ",
                date: rowData.date != null ? rowData.date : "",
                // accountLedgerName:
                //   rowData.accLedgerName != null ? rowData.accLedgerName : "",
                voucherAmount:
                  rowData.voucherAmount != null
                    ? Math.abs(+rowData.voucherAmount)
                    : "",
                voucherName:
                  rowData.voucherName != null ? rowData.voucherName : "",
                ...rowData,
                locationName:
                  rowData.location != null ? rowData.location.name : "",
                statusName:
                  rowData.status != null
                    ? rowData.status == 1
                      ? "Approved"
                      : "Pending"
                    : "",
              };
              return areaForBeatData;
            });
            dispatch(JVPendingListSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
