import { useState, useEffect } from "react";
import RTTable from "../../components/Comman/RT/RTTable";
import { apiGet } from "../../utils/api_service";
import { serverMsg, urlMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { AccountSummaryJson } from "../../DynamicFormsJson/Report/AccountSummary";
import { Paper } from "@mui/material";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicDetails";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const CostCenterSubReport = ({
  handleCloses,
  popUpData,
  formData,
  accPopupata,
  showLoador,
  showNotification,
}) => {
  const [rowList, setRowList] = useState([]);
  const[costCenterWise,setCostCenterWise]=useState([
    { name: "index", title: "Sr. No.", align: "center" },

    { name: "ccName", title: "CC Name" ,linkFlag:true},
    { name: "ccCode", title: "Code ", align: "center" },
    // { name: "date", title: "Date", align: "center" },
    { name: "amount", title: "Amount", align: "right" },
  ])
  const[accountWise,setAccountWise]=useState([
    { name: "index", title: "Sr. No.", align: "center" },

    { name: "accountLedgerName", title: "Account Name"},
    // { name: "date", title: "Date", align: "center" },
    { name: "amount", title: "Amount", align: "right" },
  ]);
  const [searchValue, setSearchValue] = useState("");
 
  useEffect(() => {
    const locationId = localStorage.getItem("location");
    showLoador({ loador: true });
    apiGet({
      url:
      formData.type==0? endpoint.accountReport +"/get-total-amount-account-wise-by-cc?locationId="+locationId+"&fromDate="+formData.fromDate+"&toDate="+formData.toDate+"&ccId="+popUpData.ccId:
      endpoint.accountReport+ "/get-total-amount-cc-wise-by-account?locationId="+locationId+"&fromDate="+formData.fromDate+"&toDate="+formData.toDate+"&accId="+popUpData.accountLedgerId
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let rowData = data.data;

        
        let rowListData = rowData.map((row,index) => {
            index = index + 1;
           
            return {
              index: index,
              ...row,
              amount:Math.abs((row.amount)).toFixed(2),
            };
          });
        
     
         
          setRowList(rowListData);

        // setRowList(accList);
        showLoador({ loador: false });
      } else {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
        showLoador({ loador: false });
      }
    });
  }, []);
  const handleClose = () => {
    handleCloses();
  };
  //   const onSave = (searchData) => {
  //     let date1 =
  //       searchData != null
  //         ? moment(searchData.fromDate).format("YYYY-MM-DD")
  //         : "";
  //     let date2 =
  //       searchData != null ? moment(searchData.toDate).format("YYYY-MM-DD") : "";
  //     if (searchData != null) {
  //       apiGet({
  //         url:
  //           endpoint.accountGroupSummary +
  //           "?companyIds=" +
  //           searchData.companyId.id +
  //           "&fromDate=" +
  //           "2023-11-01" +
  //           "&toDate=" +
  //           "2023-11-30",
  //       }).then(({ data, success }) => {
  //         if (success) {
  //           console.log(data);
  //           let rowData = data.data;
  //           let index = 0;
  //           let cerditTotal = 0;
  //           let debitTotal = 0;
  //           let opBalance = 0;

  //           // cretditTotal:cerditTotal?(cerditTotal).toFixed(2):"0.00",
  //           // debitTotal:debitTotal?(debitTotal).toFixed(2):"0.00",
  //           // opBalance:cerditTotal>debitTotal?(+cerditTotal)-(+debitTotal):(+debitTotal)-(cerditTotal),
  //           let rowList = rowData.map((row) => {
  //             index = index + 1;
  //             cerditTotal = cerditTotal + row.creditAmt;
  //             debitTotal = debitTotal + row.debitAmt;
  //             opBalance=cerditTotal-cerditTotal;
  //             return {
  //               index: index,
  //               ...row,
  //             };
  //           });
  //           rowList.push({index:"Total",groupName:"",creditAmt:cerditTotal,debitAmt:debitTotal})
  //           if(cerditTotal>= debitTotal){
  //             rowList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})
  //           }else{
  //             rowList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
  //           }

  //           setRowList(rowList);
  //         }
  //       });
  //     }
  //   };

  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };
  const generatePDFMethod = () => {
    var columns = [
      [
        "Sr.No",
        "Date",
        "Naration",
        "Bill No.",
        "Bill Date",
        "Credit",
        "Debit",
        "Closing Bal",
        "Type",
        "Book",
      ],
    ];
    let tableList = [];

    rowList.map((rowData, index) => {
      let tableData = [];
      tableData.push(index + 1);
      tableData.push(rowData.documentDate);
      tableData.push(rowData.refAccName);
      tableData.push(rowData.documentNo);
      tableData.push(rowData.transactionDate);

      tableData.push(Math.abs(rowData.openingCreditAmt));
      tableData.push(Math.abs(rowData.openingDdebitAmt));
      tableData.push(Math.abs(rowData.documentAmt));

      tableData.push(rowData.type);
      tableData.push(rowData.bookType);

      tableList.push(tableData);
    });

    // tableList.push("Total");
    // tableList.push("");
    // tableList.push({creditAmt:creditTotalAmt});
    // tableList.push({debitAmt:debitTotalAmt});

    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    // if(creditTotalAmt>= debitTotalAmt){
    //   tableList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})

    // }else{
    //   tableList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
    // }
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
   
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Date Range ",
          value: dynamicMasterData.fromDate + " To " + dynamicMasterData.toDate,
        },
        {
          label: "Company Name",
          value: dynamicMasterData.companyName,
        },
        { label: "Group", value: popUpData.groupName },
        { label: "From Leger", value: accPopupata.name },
        {
          label: "To Leger",
          value: accPopupata.name,
        },
      ],
      pdfTitle: "Group Summary",
    });
  };

  const getFilteredTableData = (tableData) => {
    let listData = tableData.filter((row) => {
      let isValid = false;

      if (
        row.accountLedgerName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.amount
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      return isValid;
    });
    return listData;
  };
  const generateExcelMethod = () => {
    var columns = [
      "Sr.No",
      "Date",
      "Naration",
      "Bill No.",
      "Bill Date",
      "Credit",
      "Debit",
      "Closing Bal",
      "Type",
      "Book",
    ];
    let alignList = [];

    let rows = [];

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.documentDate);
      reportObj.push(reportData.refAccName);
      reportObj.push(reportData.documentNo);
      reportObj.push(reportData.transactionDate);

      reportObj.push(Math.abs(reportData.openingCreditAmt));
      reportObj.push(Math.abs(reportData.openingDdebitAmt));
      reportObj.push(Math.abs(reportData.documentAmt));

      reportObj.push(reportData.type);
      reportObj.push(reportData.bookType);

      rows.push(reportObj);

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Date Range ",
        dynamicMasterData.fromDate + " To " + dynamicMasterData.toDate,
        "Company Name",
        dynamicMasterData.companyName,
        "Group",
        popUpData.groupName,

        "From Leger",
        accPopupata.name,

        "To Leger",
        accPopupata.name,
      ],
      title: "Group Summary",
    });
  };
  console.log(JSON.stringify(rowList));
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {""}
            <DynamicFormWithoutSave
              showBackToList={false}
              DataToSet={formData.type==0?
                [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Cost Center Name",
                  value:popUpData.ccName,
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Amount",
                  value:popUpData.amount,
                },
                // {
                //   xs: 12,
                //   sm: 6,
                //   md: 6,
                //   lg: 6,
                //   label: "Date",
                //   value:popUpData.date,
                // },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "From Date",
                  value:formData.fromDate,
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "To Date",
                  value:formData.toDate,
                },
              ]:[{
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Account Name",
                value:popUpData.accountLedgerName,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Amount",
                value:popUpData.amount,
              },
              // {
              //   xs: 12,
              //   sm: 6,
              //   md: 6,
              //   lg: 6,
              //   label: "Date",
              //   value:popUpData.date,
              // },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "From Date",
                value:formData.fromDate,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "To Date",
                value:formData.toDate,
              },]}
              showSaveBtnMain={false}
              padding={false}
              saveBtnText={"Search"}
              paddingTop={false}
              showExcelDownload={true}
            />
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <LandingScreenHeader
              screenTitle=""
              showTable={true}
              dynamicMasterData={{}}
              showBackToList={false}
              DataToSet={[]}
              fieldMeta={[]}
              showSaveBtnMain={true}
              onSearch={onSearch}
              generatePDF={generatePDFMethod}
              generateExcel={generateExcelMethod}
            />
            <RTTable
              showPegination={false}
              columns={ formData.type==0?accountWise:costCenterWise}
              hiddenColumnNames={[]}
              tableData={getFilteredTableData(rowList)}
              isActionColActive={false}
              showEditIcon={false}
              showDeleteIcon={false}
              // rowDelete={rowDelete}
              showCopyIcon={false}
              generatePDF={generatePDFMethod}
              generateExcel={generateExcelMethod}
            />
            <br />
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostCenterSubReport);
