import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  masterForm: [],
  // subReportWithoutLink: [],
  masterData: {},
};
let URL = endpoints.formBuilder;
const masterGenerateSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    masterFormSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        masterForm: row,
      };
    },
    masterFormSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        masterData: data,
      };
    },
    // subReportWithoutLinkSuccess: (state = cloneDeep(initialState), action) => {
    //   const { row } = action.payload;
    //   return {
    //     ...state,
    //     subReportWithoutLink: row,
    //   };
    // },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  reportSuccess,
  masterFormSaveSuccess,
  resetState,
  masterFormSuccess,
} = masterGenerateSlice.actions;

export default masterGenerateSlice.reducer;

export const getReportName =
  ({ locationPath }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-type?type=" + locationPath,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((reportObject, index) => {
            const reportColumnNames =
              JSON.parse(reportObject.tableColumns) != null
                ? JSON.parse(reportObject.tableColumns)
                : [];
            const graphColumns =
              JSON.parse(reportObject.graphColumns) != null
                ? JSON.parse(reportObject.graphColumns)
                : [];
            const ColumnNames = reportColumnNames.filter(
              (row) => row.isShow == 1
            );

            reportObject = {
              ...reportObject,
              name: reportObject.reportName,
              reportColumnNames: ColumnNames.map((columns) => {
                return {
                  ...columns,
                  title: columns.columnName,
                  name: columns.fieldName,
                  formDataKey: columns.fieldName,
                  align: columns.allignment,
                  isTotal: columns.isTotal ? columns.isTotal : 0,
                  showInExcel: true,
                  canSearch: true,
                  isChecked: true,
                };
              }),
              graphColumns: graphColumns,
              reportParams: reportObject.params,
            };
            return reportObject;
          });
          dispatch(masterFormSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getMasterInfo = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
      // + '?filters={"isDynamic":"0","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      console.log(data);
      let row;
      if (success) {
        const content = data.data;
        console.log(content);
        let reportList = content.map((reportObject, index) => {
          let reportData = {
            index: index + 1,
            ...reportObject,

            id: reportObject.id === null ? "" : reportObject.id,
            reportType:
              reportObject.reportType === null ? "" : reportObject.reportType,
            reportId:
              reportObject.reportId === null ? "" : reportObject.reportId,
            reportName:
              reportObject.reportName === null ? "" : reportObject.reportName,
            name:
              reportObject.reportName === null ? "" : reportObject.reportName,
            isPdf:
              reportObject.isPdf === null
                ? ""
                : reportObject.isPdf == 1
                ? "Yes"
                : "No",
            isExcel:
              reportObject.isExcel === null
                ? ""
                : reportObject.isExcel == 1
                ? "Yes"
                : "No",
            showAddButton:
              reportObject.showAddButton === null
                ? ""
                : reportObject.showAddButton == 1
                ? "Yes"
                : "No",
            showSearchBox:
              reportObject.showSearchBox === null
                ? ""
                : reportObject.showSearchBox == 1
                ? "Yes"
                : "No",
            // isGraph:
            //   reportObject.isGraph === null
            //     ? ""
            //     : reportObject.isGraph === 1
            //     ? "Yes"
            //     : "No",
            // isSubreport:
            //   reportObject.isSubreport === null
            //     ? ""
            //     : reportObject.isSubreport === 1
            //     ? "Yes"
            //     : "No",
          };
          return reportData;
        });
        dispatch(masterFormSuccess({ row: reportList }));
        return { response: reportList, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getReportInfo1 = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"isDynamic":"1","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      let row;
      if (success) {
        const { content } = data.data;
        let reportList = content.map((reportObject, index) => {
          let reportData = {
            index: index + 1,
            ...reportObject,

            id: reportObject.id === null ? "" : reportObject.id,
            reportType:
              reportObject.reportType === null ? "" : reportObject.reportType,
            reportId:
              reportObject.reportId === null ? "" : reportObject.reportId,
            reportName:
              reportObject.reportName === null ? "" : reportObject.reportName,
            name:
              reportObject.reportName === null ? "" : reportObject.reportName,
            isPdf:
              reportObject.isPdf === null
                ? ""
                : reportObject.isPdf === 1
                ? "Yes"
                : "No",
            isExcel:
              reportObject.isExcel === null
                ? ""
                : reportObject.isExcel === 1
                ? "Yes"
                : "No",
            showAddButton:
              reportObject.showAddButton === null
                ? ""
                : reportObject.showAddButton === 1
                ? "Yes"
                : "No",
            showSearchBox:
              reportObject.showSearchBox === null
                ? ""
                : reportObject.showSearchBox === 1
                ? "Yes"
                : "No",
            isGraph:
              reportObject.isGraph === null
                ? ""
                : reportObject.isGraph === 1
                ? "Yes"
                : "No",
            isSubreport:
              reportObject.isSubreport === null
                ? ""
                : reportObject.isSubreport === 1
                ? "Yes"
                : "No",
          };
          return reportData;
        });
        dispatch(masterFormSuccess({ row: reportList }));
        return { response: reportList, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getSubReportWithoutLinkInfo =
  ({ flag }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/without-link-sub-report?flag=" + flag,
      }).then(({ data, success }) => {
        let row;
        if (success) {
          let subReportList = data.data.map((reportObject, index) => {
            let subReportData = {
              index: index + 1,
              ...reportObject,

              id: reportObject.id === null ? "" : reportObject.id,
              reportType:
                reportObject.reportType === null ? "" : reportObject.reportType,
              reportId:
                reportObject.reportId === null ? "" : reportObject.reportId,
              reportName:
                reportObject.reportName === null ? "" : reportObject.reportName,
              name:
                reportObject.reportName === null ? "" : reportObject.reportName,
              apiName:
                reportObject.apiName === null ? "" : reportObject.apiName,
              isPdf:
                reportObject.isPdf === null
                  ? ""
                  : reportObject.isPdf === 1
                  ? "Yes"
                  : "No",
              isExcel:
                reportObject.isExcel === null
                  ? ""
                  : reportObject.isExcel === 1
                  ? "Yes"
                  : "No",
              isGraph:
                reportObject.isGraph === null
                  ? ""
                  : reportObject.isGraph === 1
                  ? "Yes"
                  : "No",
              isSubreport:
                reportObject.isSubreport === null
                  ? ""
                  : reportObject.isSubreport === 1
                  ? "Yes"
                  : "No",
              reportMaster:
                reportObject.reportMaster === null
                  ? ""
                  : reportObject.reportMaster,
            };
            return subReportData;
          });
          dispatch(masterFormSuccess({ row: subReportList }));
          return { response: subReportList, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveMasterGenerate =
  ({ dataToSave }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          console.log(data.data);
          dispatch(masterFormSaveSuccess({ data: data.data }));
          return { response: data.data, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setSaveMasterGenerate =
  ({ rowObject }) =>
  async (dispatch) => {
    dispatch(masterFormSaveSuccess({ data: rowObject }));
  };
