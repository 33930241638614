import React from "react";
import { connect } from "react-redux";
import MaterialUIRTTable from "../../../components/Comman/RT/MaterialUIRTTable";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { Paper } from "@mui/material";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import { accountLedgerBankInformationColumns } from "../../../tableColumns/table-columns";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  deletemsg,
  deleteFailedMsg,
  saveFailedMsg,
  deleteConfirmMsg,
  savemsg,
} from "../../../config/messageconstant";

import { apiGet, apiDelete, apiPost } from "../../../utils/api_service";

class AccountBankInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      DataToSet: [],
      fieldData: {},
      flag: false,
    };
  }

  async componentDidMount() {
    await this.getByProductId();
    await this.getList();
  }
  getList = async () => {
    const { showLoador, showNotification } = this.props;
    if (this.props.params.id) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiGet({
          url:
            endpoint.accbankInfo +
            "/by-account-ledger?accId=" +
            this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                tableData: data.data.map((mrpConf, index) => {
                  return { index: index + 1, ...mrpConf };
                }),
              });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  getByProductId = async () => {
    const { showLoador, showNotification } = this.props;
    if (this.props.params.id) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.accountLedger + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                DataToSet: [
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Account Code",
                    value:
                      data.data.accountLedgerCode !== null
                        ? data.data.accountLedgerCode
                        : "",
                  },
                  {
                    xs: 14,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Account Ledger Name",
                    value:
                      data.data.accountLedgerName !== null
                        ? data.data.accountLedgerName
                        : "",
                  },
                ],
              });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  onSearch = (dataToSave, id) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.accbankInfo,
        postBody: {
          ...dataToSave,
          accountLedger: { id: this.props.params.id },
          insertedUserId: "3050b3e3-0324-41d1-90e8-1374f4b736a1",
        },
      }).then(({ data, success }) => {
        this.setState({ flag: id !== "" ? false : true, fieldData: {} });
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.getList();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowEdit = (rowData) => {
    const { showLoador, showNotification } = this.props;
    showLoador({ loador: true });
    const dataToSend = {
      bankName: rowData.bankName,
      accountNumber: rowData.accountNumber,
      branchName: rowData.branchName,
      address: rowData.address,
      ifscCode: rowData.ifscCode,
      id: rowData.id,
    };
    this.setState({
      fieldData: dataToSend,
    });
    showLoador({ loador: false });
  };

  rowDelete = (rowData) => {
    const { tableData } = this.state;
    const { showNotification } = this.props;
    if (tableData.length >= 1) {
      swal({
        title: "Are you sure?",
        text: deleteConfirmMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onDelete(rowData);
        }
      });
    } else {
      showNotification({
        msg: serverMsg,
        severity: "error",
      });
    }
  };

  onDelete = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiDelete({
        url: endpoint.accbankInfo + "/" + rowData.id,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({ msg: deletemsg });
          this.getList();
        } else {
          this.props.showNotification({
            msg: deleteFailedMsg,
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { DataToSet, tableData, fieldData, flag } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            // resetAfterSave={true}
             showBackToList={false}
            formPath={AccountLedgerJson.formPath}
            DataToSet={DataToSet}
            showTitle={AccountLedgerJson.showTitle}
            screenTitle={"Account Bank Information"}
            fieldMeta={AccountLedgerJson.fieldMetaForBankInfo}
            showCancel={AccountLedgerJson.showCancel}
            apiBaseURL={AccountLedgerJson.apiBaseURL}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Add"}
            onSave={this.onSearch}
            tableColumns={accountLedgerBankInformationColumns}
            isActionColActive={true}
            setFieldData={fieldData}
            resetAfterSave={flag}
          />

          <MaterialUIRTTable
            tableHead={accountLedgerBankInformationColumns}
            rowDelete={this.rowDelete}
            rowEdit={this.rowEdit}
            showHeadDelete={true}
            showHeadEdit={true}
            data={tableData}
            showPegination={false}
            isActionColActive={true}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  mrpConfigration: state.mrpConfigration,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AccountBankInformation);
