
export const OutstandingJson = {
    screenTitle: "Outstanding Report",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta:
    [
      {
        label: "Outstanding Report",
        controlType: "radiogroup",
        md: 4.5,
        lg: 4.5,
        sm: 4.5,
        xs: 12,
        labelSM: 1,
        labelMD: 1,
        labelLG: 1,
        valueSM: 11,
        valueMD: 11,
        valueLG: 11,
        getData:true,
        hideLabel:true,
        dataKey: "entryType",
        tableDataKey: "entryType",
        defaultValue: "1",
        radioGroupItems: [
          {
            label: "Bill Outstanding",
            value: "1",
          },
          {
            label: "Purchase Outstanding",
            value: "2",
          },
         
        ],
        isMandatory: true,
      },
     
    ]
  };
  