// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import GroupSummarySubReportByGroup from "../AccountReport/GroupSummarySubReportByGroup";
import { useState } from "react";

// ** Icon Imports

// ** Custom Components Imports
// import CustomAvatar from 'src/@core/components/mui/avatar'

const SimpleT = ({ RatioAnalysis = {}, toggle, rowLink, isTotal, rowList }) => {
  const onClickLink = (params, key) => {
    rowLink(params, key);
  };
let capitalTurn=  RatioAnalysis['Sales Account']&&RatioAnalysis['workingCapital']?(RatioAnalysis['Sales Account']/RatioAnalysis['workingCapital']):0
let inventry=RatioAnalysis['Sales Account']&&RatioAnalysis['Stock-In-Hand']?(RatioAnalysis['Sales Account']/RatioAnalysis['Stock-In-Hand']):0
let CurrentRatio=RatioAnalysis['Current Assets']&&RatioAnalysis['Current Liabilities']?(RatioAnalysis['Current Assets']/RatioAnalysis['Current Liabilities']):0
    
let QuickRatio=RatioAnalysis['Current Assets']&&RatioAnalysis['Current Liabilities']&&RatioAnalysis['Stock-In-Hand']?((RatioAnalysis['Current Assets']-RatioAnalysis['Stock-In-Hand'] )/RatioAnalysis['Current Liabilities']):0

let WkgCapital=RatioAnalysis['Profit And Loss A/c']&&RatioAnalysis['workingCapital']?(RatioAnalysis['Profit And Loss A/c'] /RatioAnalysis['workingCapital'])*100:0

let sumData=RatioAnalysis['Profit And Loss A/c']+RatioAnalysis['Capital Accounts']

let returnInv=RatioAnalysis['Profit And Loss A/c']&&sumData?(RatioAnalysis['Profit And Loss A/c'] /sumData)*100:0

let grossProfit=RatioAnalysis['Sales Account']&&RatioAnalysis['Purchase Accounts']?(RatioAnalysis['Sales Account'] /RatioAnalysis['Purchase Accounts'])*100:0
let netProfit=RatioAnalysis['Sales Account']&&RatioAnalysis['Profit And Loss A/c']?(RatioAnalysis['Sales Account'] /RatioAnalysis['Profit And Loss A/c'])*100:0
let loan=RatioAnalysis['Loans (Liability)']&&sumData?(RatioAnalysis['Loans (Liability)'] /sumData):0
let opration=RatioAnalysis['Indirect Expenses']&&RatioAnalysis['Sales Account']?(RatioAnalysis['Indirect Expenses'] /RatioAnalysis['Sales Account'])*100:0
console.log(opration)  
return (
    <>
      <CardContent>
        <table style={{ width: "100%" }}>
        <tr style={{ height: 30 }}>
        <td     colSpan={2}   >
                    <Typography
                      sx={{
                        fontWeight:500,
                        color: "#000000",
                        fontSize: "1rem",
                      }}
                    >
                     Principal Group
                    </Typography>
                  </td>
                  <td      colSpan={2}       >
                    <Typography
                      sx={{
                        fontWeight:500,
                        color: "#000000",
                        fontSize: "1rem",
                      }}
                    >
                     Principal Ratios
                    </Typography>
                  </td>
          </tr>
          <tr style={{ height: 30 }}>
                 <td
                 style={{ width: "25%" }} >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                    Working Capital
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 ( Current Assets-Current Liabilities )
                    </Typography>
                  </td>
               

                <td style={{ width: "25%" }} align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {RatioAnalysis.workingCapital&&RatioAnalysis.workingCapital > 0?RatioAnalysis.workingCapital.toFixed(2)+ " Cr":RatioAnalysis.workingCapital? Math.abs(RatioAnalysis.workingCapital).toFixed(2)+ " Dr":0}
                 &emsp;
                  </Typography>
                </td>
            
                  <td style={{    width: "25%" }} >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                   Current Ratio
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 ( Current Assets : Current Liabilities )
                    </Typography>
                  </td>
               

                <td style={{ width: "25%",paddignRight:5 }} align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                
                {CurrentRatio?Math.abs(CurrentRatio).toFixed(2) +" : 1" :+"0 : 1"}
                
                 &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                 style={{  display: 'flex'}}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                    Cash in hand
                       </Typography>
                   
                  </td>
               

                <td  align="right">
                  <Typography sx={{   fontWeight: 500 }}>
                  {RatioAnalysis['Cash in hand']&&RatioAnalysis['Cash in hand'] > 0?RatioAnalysis['Cash in hand'].toFixed(2)+ " Cr":RatioAnalysis['Cash in hand']? Math.abs(RatioAnalysis['Cash in hand']).toFixed(2)+ " Dr":0}
                  &emsp; </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                    Quick Ratio
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 ( Current Assets - Stock-in-Hand : Current Liabilities )
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {QuickRatio?Math.abs(QuickRatio).toFixed(2) +" : 1" :+"0 : 1"}
                &emsp;
                  </Typography>
                </td>
              </tr>

              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                 Bank Accounts
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {RatioAnalysis['Bank Accounts']&&RatioAnalysis['Bank Accounts'] > 0?RatioAnalysis['Bank Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Bank Accounts']? Math.abs(RatioAnalysis['Bank Accounts']).toFixed(2)+ " Dr":0}
                  &emsp;
                  </Typography>
                </td>
            
                  <td>
                  <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                   Debt/Equity Ratio
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 ( Loan ( Liability ) : Capital Account + Net Profit )
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {loan?Math.abs(loan).toFixed(2)+" %":0} &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                 Bank OD Accounts
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                 
                  {RatioAnalysis['Bank OD Accounts']&&RatioAnalysis['Bank OD Accounts'] > 0?RatioAnalysis['Bank OD Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Bank OD Accounts']? Math.abs(RatioAnalysis['Bank OD Accounts']).toFixed(2)+ " Dr":0}
                  &emsp; </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                   Gross Profit %
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                
                {grossProfit?Math.abs(grossProfit).toFixed(2)+" %":0}
              &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                 Sundry Debtors
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {RatioAnalysis['Sundry Debtors']&&RatioAnalysis['Sundry Debtors'] > 0?RatioAnalysis['Sundry Debtors'].toFixed(2)+ " Cr":RatioAnalysis['Sundry Debtors']? Math.abs(RatioAnalysis['Sundry Debtors']).toFixed(2)+ " Dr":0}
                  &emsp;
                  </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                   Net Profit %
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                   {netProfit?Math.abs(netProfit).toFixed(2)+" %":0} &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                 Sundry Creditors
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {RatioAnalysis['Sundry Creditors']&&RatioAnalysis['Sundry Creditors'] > 0?RatioAnalysis['Sundry Creditors'].toFixed(2)+ " Cr":RatioAnalysis['Sundry Creditors']? Math.abs(RatioAnalysis['Sundry Creditors']).toFixed(2)+ " Dr":0}
                  &emsp; </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                  Operating Cost %
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 ( As percentage of Sales Accounts )
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {opration?Math.abs(opration).toFixed(2)+" %":0} 
                 &emsp;  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                 Sales Account
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {RatioAnalysis['Sales Account']&&RatioAnalysis['Sales Account'] > 0?RatioAnalysis['Sales Account'].toFixed(2)+ " Cr":RatioAnalysis['Sales Account']? Math.abs(RatioAnalysis['Sales Account']).toFixed(2)+ " Dr":0}
                  &emsp;  </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                  Recv. Turnover in days
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 ( Payment performance of Debtors )
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  0 Days
                 &emsp;  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                 Purchase Accounts
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                 
                  {RatioAnalysis['Purchase Accounts']&&RatioAnalysis['Purchase Accounts'] > 0?RatioAnalysis['Purchase Accounts'].toFixed(2)+ " Cr":RatioAnalysis['Purchase Accounts']? Math.abs(RatioAnalysis['Purchase Accounts']).toFixed(2)+ " Dr":0}
                  &emsp;
                  </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                  Return on Inventment %
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 (Net profit / Captial Account + Net profit)
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {returnInv?Math.abs(returnInv).toFixed(2)+" %":0}  &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                 Stock-In-Hand
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  
                  {RatioAnalysis['Stock-In-Hand']&&RatioAnalysis['Stock-In-Hand'] > 0?RatioAnalysis['Stock-In-Hand'].toFixed(2)+ " Cr":RatioAnalysis['Stock-In-Hand']? Math.abs(RatioAnalysis['Stock-In-Hand']).toFixed(2)+ " Dr":0}
                  &emsp; </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                      Return on Wkg. Capital %
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
                 (Net profit / Working Captial )
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                 {WkgCapital?Math.abs(WkgCapital).toFixed(2)+" %":0} &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
               Net Profit
                       </Typography>
                   
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {RatioAnalysis['Profit And Loss A/c']&&RatioAnalysis['Profit And Loss A/c'] > 0?RatioAnalysis['Profit And Loss A/c'].toFixed(2)+ " Cr":RatioAnalysis['Profit And Loss A/c']? Math.abs(RatioAnalysis['Profit And Loss A/c']).toFixed(2)+ " Dr":0}
                  &emsp;
                  </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                  
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
              Wkg. Capital Turnover
                       </Typography>
                       <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
              &emsp; ( Sales Account / Working Capital )
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                   {capitalTurn&&capitalTurn > 0?capitalTurn.toFixed(2):capitalTurn? Math.abs(capitalTurn).toFixed(2):0}
                   &emsp;
                  </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                  
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  &emsp;
                  </Typography>
                </td>
              </tr>
              <tr style={{ height: 30 }}>
                 <td
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
              Inventry Turnover
                       </Typography>
                       <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.675rem",
                      }}
                    >
              &emsp; ( Sales Account / Closing Stock )
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  {inventry&&inventry > 0?inventry.toFixed(2):inventry? Math.abs(inventry).toFixed(2):0}
                  &emsp;
                  </Typography>
                </td>
            
                  <td>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#666665",
                        fontSize: "0.875rem",
                      }}
                    >
                  
                    </Typography>
                  </td>
               

                <td align="right">
                  <Typography sx={{ fontWeight: 500 }}>
                  &emsp;
                  </Typography>
                </td>
              </tr>
              {/* {
                rowList.map((row)=>{
             
                  return (
                    <tr style={{ height: 30 }}>
                    <td
                     >
                       <Typography
                         sx={{
                           fontWeight: 400,
                           color: "#666665",
                           fontSize: "0.875rem",
                         }}
                       >
                      {row.groupDetail.group_name}
                       </Typography>
                       <Typography
                         sx={{
                           fontWeight: 400,
                           color: "#666665",
                           fontSize: "0.875rem",
                         }}
                       >
                       Working Capital
                       </Typography>
                     </td>
                  
   
                   <td align="right">
                     <Typography sx={{ fontWeight: 500 }}>
                     fgd
                     </Typography>
                   </td>
               
                     <td>
                       <Typography
                         sx={{
                           fontWeight: 400,
                           color: "#666665",
                           fontSize: "0.875rem",
                         }}
                       >
                     fdff
                       </Typography>
                     </td>
                  
   
                   <td align="right">
                     <Typography sx={{ fontWeight: 500 }}>
                    fg
                     </Typography>
                   </td>
                 </tr>
                  )
                })
              } */}
        </table>
      </CardContent>
    </>
  );
};

export default SimpleT;
