import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import {
    Grid,
    Paper
} from "@mui/material";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor, primaryColor, white } from "../../config/ColorObj";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
class CardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { label } = this.props
        return (
            <>  <LabelCompo style={{  
                fontWeight: 500,
                fontSize: "14px",
                padding:"2px 0px",
                boxShadow: '0px 6px 10px -8px #a6a6a6',
                color: blackColor }} label={label} />
                 </>
        );
    }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CardComponent);
