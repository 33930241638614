import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { blackColor, checkboxColor, redColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { salePurchaseSettleJSON } from "../../DynamicFormsJson/Transaction/salePurchaseSettle";


import { getAccountLedgerListByType1 } from "../../Slice/subVoucherDetail.slice";
import {
  getSalesPurchase,
  setSalesPurchase,
} from "../../Slice/salesPurchase.slice";
import { getTransactionPendingList } from "../../Slice/cashReceipt.slice";
// import {
//   getPaymentReceiptTransaction,
//   getSettleBillWithPaymentList,
//   resetData,
//   setBillData,
// } from "../../Slice/settleBill.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import moment from "moment";
// import PaymentModeDialog from "./PaymentModeDialog";
class SalePurchaseSettle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outletId: "",
      transactionId: "",
      isErrorListEmpty: false,
      DataToSet: [],
      dynamicMasterData: {
        entryTypeDetails: [
          { id: "1", name: "Sale" },
          { id: "2", name: "Purchase" },
        ],
        accountDetails: this.props.subVoucherDetailList?.accountLedgerByType1,
        transactionDetails: this.props.cashRecieptList?.transactionPendingList,
      },
      formErrors: {},
      fieldData: {},
      openOutletDialog: false,
      value: "0",
      totalRemainingAmt: 0,
      totalReceivedAmt: 0,
      billHeaderList: [],
      remAmt: 0,
    };
  }

  handleChange = (event) => {
    this.props.setSalesPurchase({ row: [] });
    this.setState({
      value: event.target.value,
      formErrors: {},
      fieldData: {},
      DataToSet: [
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Remaining Amount ",
          value: 0,
        },
      ],
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.cashRecieptList?.transactionPendingList !==
      nextProps.cashRecieptList?.transactionPendingList
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          transactionDetails: nextProps.cashRecieptList?.transactionPendingList,
        },
      });
    }
    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          accountDetails: nextProps.subVoucherDetailList.accountLedgerByType1,
        },
      });
    }
  }

  getListById = async (data) => {
    const { showLoador, showNotification, getTransactionPendingList } =
      this.props;
    console.log(data);
    if (navigator.onLine) {
      if (data.entryType && data.accountLedgerCode) {
        showLoador({ loador: true });
        await getTransactionPendingList({
          voucherType: data.entryType == "1" ? "1,3" : "2,4",
          accountLedgerCode: data.accountLedgerCode,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
        this.setState({
          DataToSet: [
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Remaining Amount ",
              value: 0,
            },
          ],
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  async componentDidMount() {
    const {
      getAccountLedgerListByType1,
      showLoador,
      showNotification,
      setSalesPurchase,
    } = this.props;
    if (navigator.onLine) {
      setSalesPurchase({ row: [] });
      showLoador({ loador: true });
      await getAccountLedgerListByType1({ accType: "1,2,0" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearch = async (data) => {
    const { showLoador, showNotification, getSalesPurchase } = this.props;
    const { value } = this.state;
    this.setState({
      transactionId: data.transactionId,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getSalesPurchase({
        entryType: data.entryType,
        accountLedgerCode: data.accountLedgerCode,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          console.log(response);
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Remaining Amount ",
                value: 0,
              },
            ],
            totalRemainingAmt: 0,
            isErrorListEmpty: false,
          });
          // const filteredList = response.filter((rowData) => {
          //   if (rowData.accountLedgerCode == data.accountLedgerCode) {
          //     let amountData = {
          //       settleAmt: rowData.remainingAmt,
          //     };
          //     return amountData;
          //   }
          // });
          // if (filteredList.length != 0) {
          //   this.setState({
          //     DataToSet: [
          //       {
          //         xs: 12,
          //         sm: 4,
          //         md: 4,
          //         lg: 4,
          //         label: "Remaining Amount ",
          //         value: (+filteredList[0].remainingAmt).toFixed(2),
          //       },
          //     ],
          //     totalRemainingAmt: filteredList[0].remainingAmt,
          //     isErrorListEmpty: false,
          //   });
          // }
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData, value } = this.state;
    const metaData = salePurchaseSettleJSON.fieldMeta;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: metaData,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: metaData,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  onFormSave = () => {
    if (this.checkValidationOnSubmit()) {
      const { fieldData, value } = this.state;
      const metaData = salePurchaseSettleJSON.fieldMeta;
      const dataToSave = {
        isActive: 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };

      if (fieldData["id"]) {
        dataToSave["id"] = fieldData["id"];
      }

      metaData.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (fieldData[currentField.tableDataKey] == currentField.open) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                  "HH:mm"
                );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });

      this.onSearch(dataToSave);
    }
  };

  onCheckBoxClick = (billHeaderId, e) => {
    const { totalRemainingAmt, totalReceivedAmt } = this.state;
    const { salesPurchase, setSalesPurchase } = this.props;
    let remAmt = 0;
    let billHeaderIdList = [];
    const setBeatList = salesPurchase?.salesPurchaseList.map(
      (billListObject) => {
        if (billHeaderId == billListObject.id) {
          if (e.target.checked) {
            remAmt = +totalRemainingAmt + +billListObject.remainingAmt;
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Remaining Amount ",
                  value: remAmt <= 0 ? 0 : remAmt.toFixed(2),
                },
              ],
              totalRemainingAmt: remAmt,
            });
            billListObject = {
              ...billListObject,
              checked: true,
            };
            return billListObject;
          } else {
            remAmt = +totalRemainingAmt - +billListObject.remainingAmt;
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Remaining Amount ",
                  value: remAmt <= 0 ? 0 : remAmt.toFixed(2),
                },
              ],
              totalRemainingAmt: remAmt,
            });
            billListObject = {
              ...billListObject,
              checked: false,
            };
            return billListObject;
          }
        } else {
          return billListObject;
        }
      }
    );
    let checkedList = [];
    checkedList = setBeatList.map((billListObject) => {
      if (remAmt <= 0) {
        if (billListObject.checked) {
          billListObject = {
            ...billListObject,
            disabled: false,
          };
          return billListObject;
        } else {
          billListObject = {
            ...billListObject,
            disabled: true,
          };
          return billListObject;
        }
      } else {
        if (billListObject.checked) {
          billListObject = {
            ...billListObject,
            disabled: false,
          };
          return billListObject;
        } else {
          billListObject = {
            ...billListObject,
            disabled: false,
          };
          return billListObject;
        }
      }
    });
    checkedList.filter((rowData) => {
      if (rowData.checked == true) {
        billHeaderIdList.push(rowData.id);
      }
    });
    this.setState({
      billHeaderList: billHeaderIdList,
    });
    this.setState({
      remAmt: remAmt,
    });
    setSalesPurchase({ row: checkedList });
  };

  saveData = (DialogBoxData) => {
    const {
      openOutletDialog,
      billHeaderList,
      totalRemainingAmt,
      transactionId,
    } = this.state;
    console.log(billHeaderList);
    const filterList =
      this.props.cashRecieptList?.transactionPendingList.filter((row) => {
        return row.id == transactionId;
      });
    if (billHeaderList.length != 0) {
      const saveList = billHeaderList.map((rowData) => {
        let saveObj = {
          recieptPaymentDetailId: filterList[0].detail_id,
          type: 1,
          billId: rowData,
          billNo: filterList[0].documentNo,
          amount: totalRemainingAmt,
        };
        return saveObj;
      });
      console.log(saveList);
    }
    // this.onSave(dataToSave, billHeaderList);
  };

  onSave = async (objectToSave, billHeaderIds) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      await showLoador({ loador: true });
      apiPost({
        url: endpoint.receiptBillInfo,
        postBody: objectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg, severity: "success" });
          window.location.replace("/sale-purchase-settle");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSubmit = () => {
    const { openOutletDialog, billHeaderList, value, paymentRecTransId } =
      this.state;
    if (this.props.salesPurchase?.salesPurchaseList.length != 0) {
      if (billHeaderList.length != 0) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            if (value == 0) {
              const { showLoador, showNotification } = this.props;
              if (navigator.onLine) {
                showLoador({ loador: true });
                await apiPost({
                  url:
                    endpoint.outletBillHeader +
                    "/settle-bill?outletBillHeaderIds=" +
                    billHeaderList.join(",") +
                    "&paymentRecieptOutletId=" +
                    paymentRecTransId,
                }).then(({ data, success }) => {
                  showLoador({ loador: false });
                  if (success) {
                    showNotification({ msg: savemsg, severity: "success" });
                    window.location.replace("/settle-bill");
                  } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                  }
                  return success;
                });
              } else {
                this.props.showNotification({
                  msg: noInternetMsg,
                  severity: "error",
                });
              }
            } else {
              this.setState({
                openOutletDialog: !openOutletDialog,
              });
            }
          }
        });
      } else {
        this.setState({
          isErrorListEmpty: true,
        });
      }
    } else {
      this.setState({
        isErrorListEmpty: true,
      });
    }
  };

  handleClosesForOutlet = () => {
    const { openOutletDialog } = this.state;
    this.setState({
      openOutletDialog: !openOutletDialog,
    });
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData, value } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    const metaData =
      value == 0
        ? salePurchaseSettleJSON.fieldMeta
        : salePurchaseSettleJSON.fieldMetaForPayment;
    metaData.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  render() {
    const {
      dynamicMasterData,
      openOutletDialog,
      DataToSet,
      value,
      totalReceivedAmt,
      isErrorListEmpty,
      formErrors,
      remAmt,
      fieldData,
    } = this.state;
    const { salesPurchase } = this.props;
    console.log(remAmt);
    console.log(remAmt <= 0);
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={salePurchaseSettleJSON.showTitle}
            screenTitle={salePurchaseSettleJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
          />
          <br />

          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={salePurchaseSettleJSON.searchList}
            showSaveBtn={salePurchaseSettleJSON.showSaveBtn}
            showTitle={false}
            screenTitle={salePurchaseSettleJSON.screenTitle}
            fieldMeta={salePurchaseSettleJSON.fieldMeta}
            showCancel={salePurchaseSettleJSON.showCancel}
            apiBaseURL={salePurchaseSettleJSON.apiBaseURL}
            showSaveNextBtn={salePurchaseSettleJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            onFormSave={this.onFormSave}
            fieldData={fieldData}
            formErrors={formErrors}
            onDataChange={this.onDataChange}
            disableCondition={
              salesPurchase?.salesPurchaseList.filter((row) => row.checked)
                .length != 0
                ? true
                : false
            }
          />
          <DynamicDetails DataToSet={DataToSet} />

          {isErrorListEmpty && (
            <Grid item sm={12} xs={12} md={12} lg={12} justifyContent="right">
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: redColor }}
                label={checkedListEmptyMsg}
              />
            </Grid>
          )}

          {salesPurchase?.salesPurchaseList.length!=0 &&(
            <>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "5%" }}></TableCell>
                  <TableCell align={"center"} sx={{ width: "5%" }}>
                    Sr. No.
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    Bill No.
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    Bill Date
                  </TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    Bill Amount
                  </TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    Remaining Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesPurchase?.salesPurchaseList.map(
                  (matrixDataRow, indexRow) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                               sx={{
                                "& svg": {
                                  width: "0.8em",
                                  height: "0.8em"
                                }
                              }}
                                value="end"
                                control={
                                  <Checkbox
                                    sx={{
                                      color: blackColor,
                                      "&.Mui-checked": {
                                        color: checkboxColor,
                                      },
                                    }}
                                    checked={matrixDataRow.checked}
                                    disabled={matrixDataRow.disabled}
                                  />
                                }
                                label=""
                                onChange={(e) => {
                                  this.onCheckBoxClick(matrixDataRow.id, e);
                                }}
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </FormControl>
                        </TableCell>
                        <TableCell align="center">
                          {matrixDataRow.index}
                        </TableCell>
                        <TableCell align="center">
                          {matrixDataRow.documentNo}
                        </TableCell>
                        <TableCell align="center">
                          {matrixDataRow.documentDate != null
                            ? moment(matrixDataRow.documentDate).format(
                                "DD-MM-YYYY"
                              )
                            : " - "}
                        </TableCell>
                        <TableCell align="right">
                          {matrixDataRow.totalAmt}
                        </TableCell>
                        <TableCell align="right">
                          {matrixDataRow.remainingAmt}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <>
            <br />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                onClick={this.saveData}
              />
            </Grid>
          </>
          </>
          )}
        </Paper>
        {/* {openOutletDialog && (
          <PaymentModeDialog
            open={openOutletDialog}
            handleCloses={this.handleClosesForOutlet}
            saveData={this.saveData}
            totalReceivedAmt={totalReceivedAmt}
          />
        )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  salesPurchase: state.salesPurchase,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  getAccountLedgerListByType1,
  getSalesPurchase,
  getTransactionPendingList,
  setSalesPurchase,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(SalePurchaseSettle);
