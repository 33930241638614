import React from "react";
import { Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  savemsg,
  saveFailedMsg,
  noInternetMsg,
  saveWarningMsg,
  dateExistMsg,
  updateMsg,
} from "../../config/messageconstant";
import DynamicTransactionForm from "../../components/dynamicscreens/DynamicTransactionForm";

import { getProfitCenter } from "../../Slice/profitCenter.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { AssetJSON } from "../../DynamicFormsJson/Transaction/Asset";
import { getAccountLedgerListByType } from "../../Slice/subVoucherDetail.slice";
class AssetFormEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      formErrors: {},
      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        ledgeCodeDetail: this.props.accList?.accountLedgerByType,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.editData.id != nextProps.editData.id) {
      this.getByIdData(nextProps.editData.id)
    }
    if (this.props.accList !== nextProps.accList) {
      if (
        this.props.accList?.accountLedgerByType !==
        nextProps.accList?.accountLedgerByType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            ledgeCodeDetail: nextProps.accList?.accountLedgerByType,
          },
        });
      }
    }
    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }
  }

  async componentDidMount() {
    const {
      getAccountLedgerListByType,
      getProfitCenter,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getProfitCenter().then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );

      showLoador({ loador: true });
      await getAccountLedgerListByType({ accType: "0,1,2" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });

      if (this.props.editData.id) {
        this.getByIdData(this.props.editData.id)
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getByIdData = async (id) => {
    const { showLoador, showNotification } =
      this.props;
    showLoador({ loador: true });
    await apiGet({
      url: endpoint.asset + "/" + id,
    }).then(({ data, success }) => {

      showLoador({ loador: false });
      if (!success) {
      } else {
        console.log("data")
        console.log(data)

        console.log("ledgerCode=" + data.data.ledgerCode ? data.data.ledgerCode : "",)
        let dValue = (((data.data.openingValue) + (data.data.purchaseInFirstHalf)) *
          ((data.data.depreciationPercentage)) /
          100
        ) +
          +(((data.data.purchaseInSecondHalf) * ((data.data.depreciationPercentage) / 2)) / 100)
        this.setState({

          getByIdDataList: {

            id:data.data.id!=null?data.data.id:"",
            ledgerCode: data.data.ledgerCode ? data.data.ledgerCode : "",
            depreciationPercentage: data.data.depreciationPercentage !== null ? data.data.depreciationPercentage : "",
            openingValue: data.data.openingValue != null ? data.data.openingValue : "",
            purchaseInFirstHalf: data.data.purchaseInFirstHalf ? data.data.purchaseInFirstHalf : "",
            purchaseInSecondHalf: data.data.purchaseInSecondHalf != null ? data.data.purchaseInSecondHalf : "",
            sellValue: data.data.sellValue != null ? data.data.sellValue : "",
            total: data.data.total != null ? data.data.total : "",
            closingValue: data.data.closingValue != null ? data.data.closingValue : "",
            remark: data.data.remark !== null
              ? data.data.remark != ""
                ? data.data.remark
                : " - "
              : "",
            dvalue: dValue,
            profitCenter: data.data.profitCenter ? data.data.profitCenter : "",
            postInJv: 0,

          },

        });
      }
    });
  }
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    AssetJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck1 = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    AssetJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    AssetJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck() && this.mandatoryCheck1()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onSubmit = (data) => {

    let location = localStorage.getItem("location");
    let error = false;
    if (data != null) {
      // if (this.checkValidationOnSubmit()) {
      let dataToSave = {
        ...data,
        id:data.id,
        profitCenter: data.profitCenter,
        ledgerCode: data.ledgerCode,
        depreciationPercentage: data.depreciationPercentage,
        openingValue: data.openingValue,
        purchaseInFirstHalf: data.purchaseInFirstHalf,
        purchaseInSecondHalf: data.purchaseInSecondHalf,
        sellValue: data.sellValue,
        total: data.total,
        closingValue: data.closingValue,
        remark: data.remark,
        location: {
          id: location,
        },
        postInJv: 0,
      }
      if (!error) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }

      console.log(dataToSave);
    }
  // }
  };

  getDataForCalculate = (value, fieldData, fieldName) => {
    let depreciationPercentage =
      fieldName == "depreciationPercentage"
        ? +value
        : fieldData.depreciationPercentage
          ? fieldData.depreciationPercentage
          : 0;
    console.log(depreciationPercentage)
    let openingValue =
      fieldName == "openingValue"
        ? +value
        : fieldData.openingValue
          ? fieldData.openingValue
          : 0;
    console.log(openingValue)
    let purchaseInFirstHalf =
      fieldName == "purchaseInFirstHalf"
        ? +value
        : fieldData.purchaseInFirstHalf
          ? fieldData.purchaseInFirstHalf
          : 0;
    console.log(purchaseInFirstHalf)
    let purchaseInSecondHalf =
      fieldName == "purchaseInSecondHalf"
        ? +value
        : fieldData.purchaseInSecondHalf
          ? fieldData.purchaseInSecondHalf
          : 0;
    console.log(purchaseInSecondHalf)
    let sellValue =
      fieldName == "sellValue"
        ? +value
        : fieldData.sellValue
          ? fieldData.sellValue
          : 0;
    console.log(sellValue)



    let dvalue = (((+openingValue) + (+purchaseInFirstHalf)) *
      ((+depreciationPercentage)) /
      100
    ) +
      +(((+purchaseInSecondHalf) * ((+depreciationPercentage) / 2)) / 100)


    console.log(dvalue)
    let total = (+openingValue) +
      (+purchaseInFirstHalf) +
      (+purchaseInSecondHalf) -
      (+sellValue);
    console.log(total)

    let cValue = (+total) - (+dvalue);
    console.log(cValue)




    return {
      total: total,
      dvalue: dvalue.toFixed(2),
      closingValue: cValue

    };
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.asset,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          this.props.callApi();
         
          showNotification({ msg:updateMsg });

        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData, getByIdDataList, formErrors ,fieldData} = this.state;
    const { rowReset ,approvalFlag} = this.props
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >

          <DynamicFormWithoutSave
            showReset={true}
            rowReset={rowReset}
            resetAfterSave={true}
             showBackToList={false}
            getByIdDataList={getByIdDataList}
            dynamicMasterData={dynamicMasterData}
            showTitle={AssetJSON.showTitle}
            screenTitle={AssetJSON.screenTitle}
            fieldMeta={AssetJSON.fieldMeta}
            showCancel={AssetJSON.showCancel}
            apiBaseURL={AssetJSON.apiBaseURL}
            showSaveNextBtn={AssetJSON.showSaveNextBtn}
            getDataForCalculate={this.getDataForCalculate}
            saveBtnText={"Save"}
            onSave={this.onSubmit}

            callApi={true}

            getDataById={this.getDataById}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accList: state.subVoucherDetail,
  profitCenter: state.profitCenter,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  getAccountLedgerListByType,
  showLoador,
  showNotification,
  getProfitCenter,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssetFormEdit);
