import { Grid } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  saveMasterGenerate
} from "../../Slice/MasterGenerate.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import * as msgConstant from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import AddFormFields from "./AddFormFields";
class AddFormFieldsComponents extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      type: "",
      isGraph: "1",
      isSubReport: "0",
      formValidErrors: {},
      fieldData: {},
      fieldDataEdit: {},
      formErrors: {},
      editData: {},
      openDialog: false,
      rowData: {},
    };
  }

  onCancel = () => {
    this.props.navigate(-1);
  };

  onFormSave = () => {
    this.props.changeTab(2);
  };

  toggleFilter = () => {
    if (this.state.openDialog) {
      this.setState({
        rowData: {},
      });
    }
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.masterGenerateData != this.props.masterGenerateData) {
      if (
        nextProps.masterGenerateData.masterData !=
        this.props.masterGenerateData.masterData
      ) {
        console.log(nextProps.masterGenerateData.masterData);
        this.setState({
          formFields:
            JSON.parse(nextProps.masterGenerateData.masterData.formFields) !=
            null
              ? JSON.parse(
                  nextProps.masterGenerateData.masterData.formFields
                ).map((parameter, index) => {
                  return {
                    index: index + 1,
                    label: parameter.label,
                    dataKey: parameter.dataKey,
                    controlType: parameter.controlType,
                    url: parameter.url,
                    ...parameter,
                    index: index + 1,
                  };
                })
              : [],
        });
      }
    }
  }
  componentDidMount() {
    console.log(
      JSON.parse(this.props.masterGenerateData.masterData.formFields)
    );
    this.setState({
      formFields:
        JSON.parse(this.props.masterGenerateData.masterData.formFields) != null
          ? JSON.parse(this.props.masterGenerateData.masterData.formFields).map(
              (parameter, index) => {
                return {
                  index: index + 1,
                  label: parameter.label,
                  dataKey: parameter.dataKey,
                  controlType: parameter.controlType,
                  url: parameter.url,
                  ...parameter,
                  index: index + 1,
                };
              }
            )
          : [],
    });
  }
  rowDelete = (rowData) => {
    const { showNotification, showLoador } = this.props;
    swal({
      title: "Are you sure?",
      text: msgConstant.deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          let filterData = this.state.formFields.filter(
            (row) => row.dataKey != rowData.dataKey
          );
          let filterList = filterData.map((data, index) => {
            return { index: index + 1, ...data };
          });
          this.onSave({
            ...this.props.masterGenerateData.masterData,
            formFields: JSON.stringify(filterList),
          });
        } else {
          showNotification({
            msg: msgConstant.noInternetMsg,
            severity: "error",
          });
        }
      }
    });
  };

  rowEdit = (rowData) => {
    this.setState({
      rowData: rowData,
    });
    this.toggleFilter();
  };
  onSave = (dataToSave) => {
    if (navigator.onLine) {
      const { saveMasterGenerate, showLoador, showNotification } = this.props;
      showLoador({ loador: true });
      saveMasterGenerate({ dataToSave }).then(({ success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.deletemsg : msgConstant.savemsg,
          });
        } else {
          showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
        }
      });
    } else {
      showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  onFormSave = () => {
    this.props.navigate(-1);
  };

  render() {
    const { report } = this.props;

    const { formFields, rowData, openDialog } = this.state;

    return (
      <>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Add Form Fields"
            // fullWidth={true}
            onClick={this.toggleFilter}
          />
        </Grid>
        <br />
        <RTTable
          columns={[
            {
              title: "Sr. No.",
              name: "index",
              showInExcel: true,
              align: "center",
            },
            {
              title: "Label",
              name: "label",
              formDataKey: "label",
              showInExcel: true,
              canSearch: true,
            },
            {
              title: "Datakey",
              name: "dataKey",
              formDataKey: "dataKey",
              showInExcel: true,
              canSearch: true,
            },
            {
              title: "Control type",
              name: "controlType",
              formDataKey: "controlType",
              showInExcel: true,
              canSearch: true,
              align: "right",
            },
            {
              title: "URL",
              name: "url",
              formDataKey: "url",
              showInExcel: true,
              canSearch: true,
              align: "right",
            },
            { name: "action", title: "Action" },
          ]}
          hiddenColumnNames={[]}
          tableData={formFields}
          rowDelete={this.rowDelete}
          showPegination={false}
          showEditIcon={true}
          rowEdit={this.rowEdit}
          isActionColActive={true}
        />
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Submit"
            // fullWidth={true}
            onClick={this.onFormSave}
          />
        </Grid>
        {openDialog && (
          <AddFormFields
            rowData={rowData}
            formFields={formFields}
            openDialog={openDialog}
            setOpenDialouge={this.toggleFilter}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  report: state.report,
  masterGenerateData: state.masterGenerate,
});
const mapDispatchToProps = { showLoador, showNotification, saveMasterGenerate };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddFormFieldsComponents);
