import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { UserActionTypes } from ".././CommonActions/User.action";
import commonSlice from "../CommonActions/common.slice";
import LandingSlice from "../Pages/Landing/Landing.slice";
import loginSlice from "../Pages/Login/login.slice";
import MasterGenerateSlice from "../Slice/MasterGenerate.slice";
import accessRoleSlice from "../Slice/accessRole.slice";
import accountLedgerSlice from "../Slice/accountLedger.slice";
import cashReceiptSlice from "../Slice/cashReceipt.slice";
import journalVoucherSlice from "../Slice/journalVoucher.slice";
import locationSlice from "../Slice/location.slice";
import matrixReportSlice from "../Slice/matrixReport.slice";
import payModeSlice from "../Slice/payMode.slice";
import profitCenterSlice from "../Slice/profitCenter.slice";
import reportSlice from "../Slice/report.slice";
import requestGRNSlice from "../Slice/requestGRN.slice";
import salesPurchaseSlice from "../Slice/salesPurchase.slice";
import salesPurchaseApprovalSlice from "../Slice/salesPurchaseApproval.slice";
import stockValueSlice from "../Slice/stockValue.slice";
import subVoucherSlice from "../Slice/subVoucher.slice";
import subVoucherDetailSlice from "../Slice/subVoucherDetail.slice";
import timelineSlice from "../Slice/timeline.slice";
import typeSlice from "../Slice/type.slice";
import userSlice from "../Slice/user.slice";
import { localStorageMiddleware } from "../middleware/storage";
const reducer = combineReducers({
  user: loginSlice,
  common: commonSlice,
  landing: LandingSlice,
  accessRole: accessRoleSlice,
  requestGRN: requestGRNSlice,
  location: locationSlice,
  report: reportSlice,
  matrixReport: matrixReportSlice,
  type: typeSlice,
  masterGenerate: MasterGenerateSlice,
  subVoucher: subVoucherSlice,
  subVoucherDetail: subVoucherDetailSlice,
  cashReciept: cashReceiptSlice,
  journalVoucher: journalVoucherSlice,
  accountLedger: accountLedgerSlice,
  userList: userSlice,
  stockValueList: stockValueSlice,
  salesPurchase: salesPurchaseSlice,
  payMode: payModeSlice,
  salesPurchaseAppoval: salesPurchaseApprovalSlice,
  profitCenter:profitCenterSlice,
  timeline:timelineSlice
});

const rootReducer = (state, action) => {
  if (action.type === UserActionTypes.LOGOUT) {
    state = undefined;
  }
  if (action.type === UserActionTypes.ReserRedux) {
    state = undefined;
  } else {
  }
  return reducer(state, action);
};
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;
