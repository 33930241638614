import React,{ useState } from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";
import GroupSummaryByPlantSubreportByGroup from "./GroupSummaryByPlantSubReportByGroup";
import MFGAndPLByPlant from "./MFGAndPLByPlant";
const BalanceSheetPlantVerticalTable = ({ rowList = [],formData={},getDeatilList, tableHead = [], classes }) => {
    console.log("rowList")
console.log(rowList)
const [openPopup, setOpenPopup] = useState(false);
const [openTable, setOpenTable] = useState(false);
const [popUpData, setPopupdata] = useState({})
const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
const toggle = () => {
  
  setOpenTable(!openTable);
  if(Object.keys(formData).length!=0){
    console.log("formData")
    getDeatilList(formData.companyId,formData.yearId)
  }
  

};

const toggleMFGAndProfitAndLoss = () => {
  setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
  if(formData!=null && Object.keys(formData).length!=0){
    getDeatilList(formData.companyId,formData.yearId)
  }
};

console.log("rowList")
console.log(rowList)
const rowLink = (rowData,flag) => {
  console.log(rowData)
  if(flag==0){
    setPopupdata({...rowData,groupId:rowData.id,groupName:rowData.name})
    setOpenTable(!openTable);
  }else {
    console.log("in else")
          toggleMFGAndProfitAndLoss()
      }


  

}

    return (
        <>
            <div style={{ overflow: "auto" }}>
                <table className={classes.MuiTableWithoutBorder}>
                    <tr >
                        {
                            tableHead.map((head) => {
                                return (<td style={{ textAlign: "center" }} className={classes.MuiTableHeadCell} >{head.title}</td>)
                            })
                        } </tr>
                    {

                        rowList.map((data) => {

                            return (
                                <>
                                    <tr>
                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{ fontWeight: fontWeight, fontSize: 16, }}>
                                            {data.name}
                                        </td>

                                    </tr>
                                    {
                                        data.groupList.map((group) => {

                                            return (
                                                <>
                                                    <tr>
                                                       
                                                          
                                                                <td style={{
                                                                  
                                                                    fontWeight:fontWeight,
                                                                    cursor: "pointer",
                                                                }}
                                                                
                                                                onClick={() => {
                                                                  if(  group.
                                        manufactureAndProfit==0 || group.
                                        manufactureAndProfit==1){
                                                                    console.log(group)
                                                                    rowLink(
                                                                        group,1
                                                                     
                                                                    )
                                                                  }
                                                               
                                                                }}  className={classes.MuiTableBodyCellWithoutBorder} >
                                                                    {group.groupName}
                                                                </td>
                                                               
                                                       

                                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{textAlign:"right"}}>
                                                            {group.amount}
                                                        </td>

                                                    </tr>

                                                    <tr style={{ flexDirection: "row", justifyContent: "space-between",width:"100%", alignItems: "center",}} >
                 
                    {group.result && group.result.length != 0 && group.result.map((data, index) => {
                      let creditTotal = 0;
                      let creditAmt = 0;
                      let debitAmt = 0;
                      creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                      debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                      creditTotal = creditTotal + creditAmt + debitAmt
                      return (

                        // <tr style={{ flexDirection: "row", justifyContent: "space-between",width:"100%", alignItems: "center",}}>
                        
                           <>
                                <td style={{
                                    flexDirection: "row", justifyContent: "flex-start",width:"50%", alignItems: "center",
                                    cursor: "pointer",
                                }} onClick={(e) =>  rowLink(
                                    data,0
                                   
                                  )} className={classes.MuiTableBodyCellWithoutBorder} >
                                    {data.name}
                                </td>
                               
                        

                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{textAlign:"right",flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end",width:"50%"}}>
                        {creditTotal == null ? "" : (Math.abs(creditTotal)).toFixed(2)}
                        </td>

                    </>
                       
                      )
                    })}
                 
                  



                </tr>
                                                </>

                                            )
                                        })
                                    }
                                </>
                            )
                        })}

                </table>
            </div>

            {openTable && (
          <GroupSummaryByPlantSubreportByGroup
            open={openTable}
            handleCloses={toggle}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            dynamicMasterData={formData}
          />
        )}
        {openMFGAndProfitAndLoss && (
          <MFGAndPLByPlant
            rowLink={rowLink}
            open={openMFGAndProfitAndLoss}
            handleCloses={toggleMFGAndProfitAndLoss}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            formData={formData}
          />
        )}
        </>
    );
};

export default withStyles(styles)(BalanceSheetPlantVerticalTable);

