import { useState, useEffect } from "react";
import RTTable from "../../components/Comman/RT/RTTable";
import { apiGet } from "../../utils/api_service";
import { serverMsg, urlMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { AccountSummaryJson } from "../../DynamicFormsJson/Report/AccountSummary";
import { Paper } from "@mui/material";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicDetails";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const CashbookDateWise = ({ handleCloses,
  open,
  popUpData,
  formData,
  rowLink,
  showLoador, showNotification
}) => {
  const [rowList, setRowList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [dataToSet, setDataToSet] = useState([
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Month",
      value: popUpData.monthYear,
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "OP.Balance",
      value: popUpData.totalAmount,
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Receipts",
      value: popUpData.creditTotal,
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Payments",
      value: popUpData.debitTotal,
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "CL. Balance",
      value: popUpData.closingBalance,
    },
  ]);
  useEffect(() => {
    showLoador({ loador: true });
    apiGet({
      // url: endpoint.cashbalanceByDate + "?date=04-11-2023&companyId=f8fa0540-5397-11ee-8c99-0242ac120002&accountLedgerId=4ff5a98c-f888-4972-958d-4922e9c6f651",
      url: endpoint.cashbalanceByDate + "?date=" + popUpData.monthYear + "&companyId=" + formData.companyId + "&accountLedgerId=" + formData.accountLedgerGroupsId,
    }).then(({ data, success }) => {
      if (success) {
      
        let rowData = data.data;


        let closingAmount = 0
        let openingAmount = 0
        let index = 0
        let rowList = [];
        rowData.map((row, indexData) => {
          if (indexData == 0) {
            closingAmount = +popUpData.openingAmountToCal + row.creditTotal + row.debitTotal
            openingAmount = +popUpData.openingAmountToCal
          } else {
            openingAmount = closingAmount
            closingAmount = closingAmount + row.creditTotal + row.debitTotal
          }
          if (row.creditTotal != 0 || row.debitTotal != 0) {
            index = index + 1
            rowList.push ({
              index: index,
              monthYear: row.monthYear,
              totalAmount: Math.abs(openingAmount).toFixed(2),
              openingAmountToCal: openingAmount,
              creditTotal: Math.abs(row.debitTotal).toFixed(2),
              debitTotal: Math.abs(row.creditTotal).toFixed(2),
              closingBalance: closingAmount >= 0 ? Math.abs(closingAmount).toFixed(2) + " Cr" : Math.abs(closingAmount).toFixed(2) + " Dr",
              id: row.id,
            });
          } 

        });
        //   rowList.push({index:"Total",name:"",creditAmt:cerditTotal,debitAmt:debitTotal})


        setRowList(rowList);
        showLoador({ loador: false })

      } else {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
        showLoador({ loador: false })
      }

    });
  }, []);
  const handleClose = () => {
    handleCloses();
  };
  const onSearch = (searchvalue) => {
    setSearchValue(searchvalue);
  };
  const getFilteredTableData = (tableData) => {
    if (tableData.length != 0) {
      let listData = tableData.filter((row) => {

        let isValid = false;

        if (row.monthYear.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        if (row.closingBalance.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        if (row.creditTotal.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        if (row.debitTotal.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        if (row.totalAmount.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
        }
        return isValid;

      });
      return listData
    }

  };

  const generatePDFMethod = () => {
    var columns = [["Sr.No", "Month", "Op.Balance", "Reciept", "Payments", "CL.Balance"]];
    let tableList = []

    rowList.map(((rowData, index) => {

      let tableData = [];
      tableData.push(index + 1)
      tableData.push(rowData.monthYear)

      tableData.push(Math.abs(rowData.totalAmount))
      tableData.push(Math.abs(rowData.creditTotal))
      tableData.push(Math.abs(rowData.debitTotal))
      tableData.push(rowData.closingBalance)


      tableList.push(tableData)
    }))
    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate
        },
        {
          label: "Company Name",
          value: formData.companyName
        },
        {
          label: "Month",
          value: popUpData.monthYear,
        },
        {
          label: "OP.Balance",
          value: popUpData.totalAmount,
        },
        {
          label: "Receipts",
          value: popUpData.creditTotal,
        },
        {
          label: "Payments",
          value: popUpData.debitTotal,
        },
        {
          label: "CL. Balance",
          value: popUpData.closingBalance,
        },
      ],
      pdfTitle: "Cash Balance",
    });

  };
  const generateExcelMethod = () => {
    var columns = ["Sr.No", "Month", "Op.Balance", "Reciept", "Payments", "CL.Balance"];
    let alignList = [];

    let rows = []

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.monthYear)
      reportObj.push(Math.abs(reportData.totalAmount))
      reportObj.push(Math.abs(reportData.creditTotal))
      reportObj.push(Math.abs(reportData.debitTotal))
      reportObj.push(reportData.closingBalance)
      rows.push(reportObj)



      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate, "Company Name", formData.companyName,
        "OP.Balance",
        popUpData.totalAmount,

        "Receipts",
        popUpData.creditTotal,

        "Payments",
        popUpData.debitTotal,

        "CL. Balance",
        popUpData.closingBalance,
      ],
      title: "Cash Balance",
    });

  };
  return (
    <>


      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >

            Date wise
            <DynamicFormWithoutSave
              showBackToList={false}
              DataToSet={dataToSet}
              showSaveBtnMain={false}
              padding={false}
              saveBtnText={"Search"}
              paddingTop={false}
              showPdfDownload={true}
              showExcelDownload={true}
            />
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <LandingScreenHeader
              screenTitle=""
              showTable={true}
              dynamicMasterData={{}}
              showBackToList={false}
              DataToSet={[]}
              fieldMeta={[]}
              showSaveBtnMain={true}
              showPdfDownload={true}
              showExcelDownload={true}
              onSearch={onSearch}
              generatePDF={generatePDFMethod}
              generateExcel={generateExcelMethod}

            />
            <RTTable
              showPegination={false}
              columns={[
                { name: "index", title: "Sr. No.", align: "center" },

                { name: "monthYear", title: "Date" },
                // { name: "nashik", title: "City" },
                { name: "totalAmount", title: "Op. Balance", align: "right" },
                { name: "creditTotal", title: "Receipts", align: "right" },
                { name: "debitTotal", title: "Payments", align: "right" },
                { name: "closingBalance", title: "CL. Balance", align: "right" },
              ]}
              rowLink={rowLink}
              hiddenColumnNames={[]}
              tableData={rowList.length != 0 ? getFilteredTableData(rowList) : []}
              isActionColActive={false}
              showEditIcon={false}
              showDeleteIcon={false}
              // rowDelete={rowDelete}
              showCopyIcon={false}
            // rowCopy={this.rowCopy}
            />
            <br />
          </DialogContent>
        </BootstrapDialog>


      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };
export default connect(mapStateToProps, mapDispatchToProps)(CashbookDateWise);

