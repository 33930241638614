import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";

export default function DatePickerValue({
  label,
  value,
  isError = false,
  errorText = " ",
  maxDate,
  minDate,
  onChange,
  disabled,
  addOne = false,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      
      <DatePicker
     

     
        label={label}
        value={value || null}
        maxDate={maxDate}
        format={"DD/MM/YYYY"}
        // minDate={new Date()}
        minDate={minDate}
        onChange={(newValue) => onChange(newValue)}
        disabled={disabled}
        slotProps={{
          input: { sx: { fontSize: '12px !important' } },
          textField: {
          
            fullWidth: true,
            variant: "standard",
            error: isError,
            helperText: errorText,
            value: value,
          },
        }}
      />
    </LocalizationProvider>
  );
}
