import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import AutoComplete from "../../components/Comman/AutoComplete";

import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { onlyFloatNumberPlus } from "../../components/Comman/Util/Validations";
import { NoLuggageOutlined } from "@mui/icons-material";

class CreditNoteTable extends React.Component {
 
 
  onTextFieldDataChange = (event, row) => {
    const { name,value } = event.target
    if (!onlyFloatNumberPlus.test(value)) {
      return
    }
    let productDataListNew =[]
    let filterData = this.props.productDataList.map((productInfo, MainIndex) => {
        if (row.id == productInfo.id) {
            productInfo = {
                ...productInfo,
                [name]: value
              }
              
              productInfo = {
                ...productInfo,
                amount:( +productInfo.returnRate *+productInfo.grnQty).toFixed(2)
              } 
        return productInfo
      }
      return productInfo
    })

    this.props.setStockConsumptionList(filterData)
  }
  render() {
    const { productDataList ,taxList=[]} = this.props;

    return (
      <>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align={"center"} sx={{ width: "5%" }}>
                  Sr. No.
                </TableCell>
                <TableCell align="center" sx={{ width: "30%" }}>
                  Product Name
                </TableCell>
                <TableCell align="center" sx={{ width: "20%" }}>
                  UOM
                </TableCell>
                <TableCell align="center" sx={{ width: "10%" }}>
                Rate
                </TableCell>
              
              
                <TableCell align="center" >
                  Quantity
                </TableCell>
                <TableCell align="center" sx={{ width: "10%" }}>
                  Total
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {productDataList.map((matrixDataRow, indexRow) => {
             
               return (
                  <TableRow>
                    <TableCell align="center">{indexRow+1}</TableCell>
                    <TableCell>{matrixDataRow.productName}</TableCell>
                    <TableCell>{matrixDataRow.storeUomName}</TableCell>
                    <TableCell align="right"><TextFieldCompo
                        placeHolder={"Rate"}
                        size="small"
                        color="primary"
                        name={"returnRate"}
                        id={"returnRate"}
                        value={matrixDataRow.returnRate}
                        onChange={(event) =>
                          this.onTextFieldDataChange(event, matrixDataRow,'returnRate')
                        }
                        fullWidth
                        errorText={matrixDataRow.returnRateErr}
                      /> </TableCell>
                   <TableCell align="right">
                      
                      <TextFieldCompo
                        placeHolder={"Quantity"}
                        size="small"
                        color="primary"
                        name={"grnQty"}
                        id={"grnQty"}
                        value={matrixDataRow.grnQty}
                        onChange={(event) =>
                          this.onTextFieldDataChange(event, matrixDataRow,'grnQty')
                        }
                        fullWidth
                        errorText={matrixDataRow.grnQtyErr}
                      />
                    </TableCell>
                    <TableCell align="right">{matrixDataRow.returnRate&&matrixDataRow.grnQty?(matrixDataRow.grnQty*matrixDataRow.returnRate).toFixed(2):0}</TableCell>
                 
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default CreditNoteTable;
