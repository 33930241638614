import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ContraEntryJSON } from "../../DynamicFormsJson/Transaction/contraEntry";
import { getCostCenter } from "../../Slice/cashReceipt.slice";
import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import {
  getAccountLedgerListByType1,
} from "../../Slice/subVoucherDetail.slice";
import { getProfitCenter } from "../../Slice/profitCenter.slice";

import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  deleteWarningMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import dayjs from "dayjs";
import { getLedgerDataByAccId } from "../../Slice/ledger.slice";
import { getPayMode } from "../../Slice/payMode.slice";
import DynamicTransactionForm from "../../components/dynamicscreens/DynamicTransactionForm";
import { apiPost } from "../../utils/api_service";
import CostCenterMappingDialouge from "../CashReceipt/CostCenterMappingDialouge";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class ContraEntryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      headerData: {},
      fieldData: { type: "1", date: dayjs() },
      voucherAmountShow: 0,
      flag: 1,
      setFieldDataFromApi: {},
      recieptPaymentDetailCcInfoList: [],
      recieptPaymentDetailBillInfoList: [],
      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        paymentModeDetails: this.props.payList?.payMode,
        ledgerList: this.props.subVoucherDetailList.accountLedgerByType1,
        creditAccountDetails:
          this.props.subVoucherDetailList?.accountLedgerByType1,
        cashBankDetails: this.props.subVoucherDetailList?.accountLedgerByType,
        costCenterDetail: this.props.cashRecieptList?.costCenter,
        typeDetails: [
          { id: "C", name: "Credit" },
          { id: "D", name: "Debit" },
        ],
        contraEntryTypeDetails: [
          { id: "1", name: "Bank To Bank" },
          { id: "2", name: "Cash Deposit To Bank" },
          { id: "3", name: "Cash Withdrawl From Bank" },
        ],
        subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
        voucherAmount: 0,
        openCostCenter: false,
        openBillMapping: false,
      },
    };
  }
  componentWillReceiveProps(nextProps) {

    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          ledgerList: nextProps.subVoucherDetailList.accountLedgerByType1,
          creditAccountDetails:
            nextProps.subVoucherDetailList.accountLedgerByType1.filter(
              (row) => row.accType == 2
            ),
          cashBankDetails: nextProps.subVoucherDetailList.accountLedgerByType1.filter(
            (row) => row.accType == 2
          ),
        },
      });
    }
    if (
      this.props.cashRecieptList?.costCenter !==
      nextProps.cashRecieptList?.costCenter
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          costCenterDetail: nextProps.cashRecieptList?.costCenter,
        },
      });
    }
    if (
      this.props.subVoucherList?.subVoucherByType !==
      nextProps.subVoucherList?.subVoucherByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subVoucherDetail: nextProps.subVoucherList?.subVoucherByType,
        },
      });
    }

    if (
      this.props.payList?.payMode !==
      nextProps.payList?.payMode
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          paymentModeDetails: nextProps.payList?.payMode,
        },
      });
    }

    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }

  }

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const {
      showLoador,
      showNotification,
      getAccountLedgerListByType1,
      getSubVoucherByType,
      getCostCenter,
      getPayMode,
      getProfitCenter,
    } = this.props;
    console.log(this.state.fieldData);
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getProfitCenter().then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );
      showLoador({ loador: true });
      await getAccountLedgerListByType1({ accType: "0,1,2" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getCostCenter().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });

      showLoador({ loador: true });
      await getPayMode().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  getListById = async (data) => {
    const {
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      if (data.contraEntryType != null) {
        if (data.contraEntryType == 1) {

          this.setState({
            dynamicMasterData: {
              ...this.state.dynamicMasterData,
              creditAccountDetails: this.state.dynamicMasterData.ledgerList.filter(
                (row) => row.accType == 2
              ),
              cashBankDetails: this.state.dynamicMasterData.ledgerList.filter(
                (row) => row.accType == 2
              )
            },
          });
        } else if (data.contraEntryType == 3) {
          this.setState({
            dynamicMasterData: {
              ...this.state.dynamicMasterData,
              creditAccountDetails: this.state.dynamicMasterData.ledgerList.filter(
                (row) => row.accType == 1
              ),
              cashBankDetails: this.state.dynamicMasterData.ledgerList.filter(
                (row) => row.accType == 2
              )
            },
          });
        } else if (data.contraEntryType == 2) {
          this.setState({
            dynamicMasterData: {
              ...this.state.dynamicMasterData,
              creditAccountDetails: this.state.dynamicMasterData.ledgerList.filter(
                (row) => row.accType == 2
              ),
              cashBankDetails: this.state.dynamicMasterData.ledgerList.filter(
                (row) => row.accType == 1
              )
            },
          });
        }
      }

      if (data.mainAccountId != null && data.mainAccountId != "") {
        this.getList(data.mainAccountId, 1);
      }
      if (data.cashBankId != null && data.cashBankId != "") {
        //    this.setState({
        //   dynamicMasterData: {
        //     ...this.state.dynamicMasterData,
        //     creditAccountDetails:this.state.dynamicMasterData.creditAccountDetails.filter((row)=>row.id!=data.cashBankId),
        //   },
        // });
        this.getList(data.cashBankId, 0);
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getList = async (accId, type) => {
    const {
      showLoador,
      showNotification,
      getLedgerDataByAccId,
      getSubVoucherByType,
      getCostCenter,
    } = this.props;
    const locationIdList = localStorage.getItem("locationIdList");
    let valuesArray = [];
    if (locationIdList != null) {
      valuesArray = JSON.parse(locationIdList);
      valuesArray = valuesArray.filter(
        (row) => row.id === localStorage.getItem("location")
      );
    }
    showLoador({ loador: true });
    await getLedgerDataByAccId({
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
      companyIds: valuesArray.length != 0 ? valuesArray[0].company.id : 0,
      accId: accId,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if (response != null) {

          if (type == 1) {
            this.setState({

              fieldData: {
                ...this.state.fieldData,
                debitBalance: response.closingAmt != null && response.closingAmt >= 0 ? Math.abs(response.closingAmt).toFixed(2) + " " + "Cr" : Math.abs(response.closingAmt).toFixed(2) + " " + "Dr"
              },
            });
          } else {
            this.setState({
              fieldData: {
                ...this.state.fieldData,
                balance: response.closingAmt != null && response.closingAmt >= 0 ? Math.abs(response.closingAmt).toFixed(2) + " " + "Cr" : Math.abs(response.closingAmt).toFixed(2) + " " + "Dr"
              },
            });
          }
        }
      }
    });
  };

  onSearch = (data) => {
    const { rowList, dynamicMasterData, recieptPaymentDetailCcInfoList } =
      this.state;
    console.log(data);
    let catList = dynamicMasterData["creditAccountDetails"]
      ? dynamicMasterData["creditAccountDetails"].filter(
        (row) => row.id == data.mainAccountId
      )
      : [];
    // let detailData = {}
    const detailData = {
      mainAccountCode: catList.length != 0 ? catList[0].accountLedgerCode : "-",
      mainAccountName: catList.length != 0 ? catList[0].accountLedgerName : "-",
      recieptPaymentDetailCcInfoList: recieptPaymentDetailCcInfoList,
      ...data,
    };
    return detailData;
  };

  onSubmitData = () => {
    const { showNotification } = this.props;
    const location = localStorage.getItem("location");
    if (this.checkValidationOnSubmit()) {
      const {
        rowList,
        fieldData,
        recieptPaymentDetailCcInfoList,
        recieptPaymentDetailBillInfoList,
        dynamicMasterData,
      } = this.state;
      console.log(fieldData);
      let totalAmt = 0;
      // if (rowList.length != 0) {
      //   let creditAmount = 0;
      //   let debitAmount = 0;
      //   const recieptPaymentDetailList = rowList.map((rowData) => {
      //     if (rowData.type == "C") {
      //       creditAmount = creditAmount + +rowData.amt;
      //     }
      //     if (rowData.type == "D") {
      //       debitAmount = debitAmount + -Math.abs(+rowData.amt);
      //     }
      //     totalAmt = creditAmount + debitAmount;
      //     console.log(totalAmt);
      //     const rowObj = {
      //       sortNo: 1,
      //       mainAccId: fieldData.cashBankId,
      //       amt: rowData.amt,
      //       type: rowData.type,
      //       remark: rowData.remark,
      //       recieptPaymentDetailCcInfoList:
      //         rowData.recieptPaymentDetailCcInfoList,
      //       recieptPaymentDetailBillInfoList: recieptPaymentDetailBillInfoList,
      //     };

      //     return rowObj;
      //   });
      //   console.log(totalAmt);
      let catList = dynamicMasterData["subVoucherDetail"]
        ? dynamicMasterData["subVoucherDetail"].filter(
          (row) => row.id == fieldData.subVoucherMaster.id
        )
        : [];
      if (+fieldData.amt > 0) {
        const dataToSave = {
          ...fieldData,
          profitCenter: fieldData.profitCenter,
          subVoucherMaster: fieldData.subVoucherMaster,
          mainAccountId: fieldData.mainAccountId,
          location: {
            id: location,
          },
          remark: fieldData.remark ? fieldData.remark : null,
          voucherAmount: fieldData.amt,
          cashBankId: fieldData.cashBankId,
          paidTo: fieldData.paidTo,
          clearanceDays: fieldData.clearanceDays ? fieldData.clearanceDays : 0,
          passDate: fieldData.passDate
            ? fieldData.passDate.format("DD-MM-YYYY")
            : null,
          drawnOn: fieldData.drawnOn
            ? fieldData.drawnOn.format("DD-MM-YYYY")
            : null,
          chequeNo: fieldData.chequeNo ? fieldData.chequeNo : null,
          chequeDate: fieldData.chequeDate
            ? fieldData.chequeDate.format("DD-MM-YYYY")
            : null,
          contraEntryType: fieldData.contraEntryType,
          paymentMode: fieldData.paymentMode != null ? fieldData.paymentMode : "",
          recieptPaymentDetailList: [
            {
              sortNo: 1,
              mainAccId: fieldData.mainAccountId,
              amt: fieldData.amt,
              type: "D",
            },
          ],
          date: fieldData.date.format("DD-MM-YYYY"),
          status: catList[0].autoApproveStatus == 0 ? 0 : 1,
          bookType: 5,
        };
        console.log(dataToSave);
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.recieptPaymentHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.setState({
            fieldData: {},
            rowList: []
          })
          this.props.callApi();
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: data.response.data.errorMessage, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    ContraEntryJSON.fieldMetaAdd.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        (currentField.isMandatory || currentField.isMandatory == undefined)
      ) {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (
        currentField.controlType === "hideDatepicker" &&
        (currentField.isMandatory || currentField.isMandatory == undefined)
      ) {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (
        currentField.controlType === "hideAutocomplete" &&
        (currentField.isMandatory || currentField.isMandatory == undefined)
      ) {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (
        currentField.controlType === "hideMultiSelect" &&
        (currentField.isMandatory || currentField.isMandatory == undefined)
      ) {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    ContraEntryJSON.fieldMetaAdd.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    ContraEntryJSON.fieldMetaAdd.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue) {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }

                break;
              case "le":
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }

                break;
              case "g":
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }

                break;
              case "ge":
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }

                break;
              case "eq":
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }

                break;
              default:
                break;
            }

            if (isError) {
              const compareWithFieldLabel = ContraEntryJSON.fieldMetaAdd.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    ContraEntryJSON.fieldMetaAdd.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        this.setState({
          rowList: row,
        });
      }
    });
  };

  rowCopy = (rowData) => {
    const { openCostCenter } = this.state;
    this.setState({
      openCostCenter: !openCostCenter,
      headerData: rowData,
    });
    console.log(rowData);
  };

  OnSaveCostCenter = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    this.setState({
      recieptPaymentDetailCcInfoList: dataToSave,
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };
  // handleClosesForQuestion = () => {
  //   const { openCostCenter } = this.state;
  //   this.setState({
  //     openCostCenter: !openCostCenter,
  //   });
  // };
  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      openCostCenter,
      headerData,
      setFieldDataFromApi,
    } = this.state;
    console.log(fieldData.contraEntryType)
    const { rowReset } = this.props
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicTransactionForm
            amount={fieldData.voucherAmount ? fieldData.voucherAmount : "0"}
            dynamicMasterData={{
              ...dynamicMasterData,
              creditAccountDetails:
                dynamicMasterData.creditAccountDetails.filter(
                  (row) => row.id != fieldData.cashBankId
                ),
            }}
            showReset={true}
            rowReset={rowReset}
            screenTitle={ContraEntryJSON.screenTitle}
            fieldMeta={ContraEntryJSON.fieldMetaAdd.map((rowData) => {
              return { ...rowData, label: rowData.dataKey == 'cashBankId' && fieldData.contraEntryType == 1 ? 'From Bank Account' : rowData.dataKey == 'cashBankId' && fieldData.contraEntryType == 2 ? 'Cash A/C' :rowData.dataKey == 'mainAccountId' && fieldData.contraEntryType == 1 ? 'To Bank Account' : rowData.dataKey == 'mainAccountId' && fieldData.contraEntryType == 2 ? 'Bank A/C' : rowData.label }
            })}
            apiBaseURL={ContraEntryJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={ContraEntryJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            setFieldDataFromApi={setFieldDataFromApi}

          />
          <br />
          {/* <Divider sx={{ borderWidth: 1, color: "#000000" }} /> */}
          {/* <br />
          <LabelCompo
            className="text-black"
            style={{ fontSize: "24px", color: DarkBlue }}
            label={"Contra Entry Details"}
          />
          <br /> */}
          {/* <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            sx={{ paddingTop: 2 }}
          > */}
          {/* <DynamicFormWithoutSave
            showSaveBtn={true}
            showSaveBtnMain={false}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={ContraEntryJSON.searchList}
            showTitle={ContraEntryJSON.showTitle}
            screenTitle={ContraEntryJSON.screenTitle}
            fieldMeta={ContraEntryJSON.fieldMetaDetails}
            showCancel={ContraEntryJSON.showCancel}
            showBackToList={ContraEntryJSON.showBackToList}
            apiBaseURL={ContraEntryJSON.apiBaseURL}
            showSaveNextBtn={ContraEntryJSON.showSaveNextBtn}
            saveBtnText={"ADD"}
            onSave={this.onSearch}
            resetAfterSave={true}
            getDataForDisabled={this.getDataForDisabled}
          /> */}
          {/* </Grid> */}

          {/* <br />
          <Divider sx={{ borderWidth: 1, color: "#000000" }} />
          <br /> */}
          {/* <RTTable
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "mainAccountCode", title: "ACCD" },
              { name: "mainAccountName", title: "Account Head" },
              { name: "amt", title: "Amount", align: "right" },
              { name: "type", title: "Type", align: "center" },
              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            isActionColActive={true}
            showEditIcon={false}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
            showCopyIcon={true}
            rowCopy={this.rowCopy}
          />
          <br /> */}
          {/* <DynamicTransactionForm
            dynamicMasterData={dynamicMasterData}
            screenTitle={ContraEntryJSON.screenTitle}
            fieldMeta={ContraEntryJSON.fieldMeta1}
            apiBaseURL={ContraEntryJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={ContraEntryJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showTitle={false}
            getDataForDisabled={this.getDataForDisabled}
          /> */}
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
        {openCostCenter && (
          <CostCenterMappingDialouge
            open={openCostCenter}
            handleCloses={this.rowCopy}
            dynamicMasterData={dynamicMasterData}
            onSave={this.OnSaveCostCenter}
            dataToSave={headerData}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  subVoucherList: state.subVoucher,
  cashRecieptList: state.cashReciept,
  payList: state.payMode,
  profitCenter: state.profitCenter
});
const mapDispatchToProps = {
  showLoador,
  showNotification,

  getAccountLedgerListByType1,
  getSubVoucherByType,
  getCostCenter,
  getPayMode,
  getLedgerDataByAccId,
  getProfitCenter,
  //   getProductByCategoryForStakeHolder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ContraEntryForm);
