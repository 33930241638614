import { Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";

import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  saveMasterGenerate,
  setSaveMasterGenerate,
  getSubReportWithoutLinkInfo,
} from "../../Slice/MasterGenerate.slice";
import { redColor, titleColor } from "../../config/ColorObj";
import * as msgConstant from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { withRouter } from "../../components/withRouter";
import { LabelCompo } from "../../components/Comman/Label";
import { validText } from "../../components/Comman/Util/Validations";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import RTTable from "../../components/Comman/RT/RTTable";
import AddFilter from "./AddFilter";
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
// import { getType } from "../../Slice/type.slice";
import MultipleSelect from "../../components/Comman/MultipleSelect";
class MasterForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {
        isPdf: 1,
        isExcel: 1,
        isSubreport: 0,
        showAddButton: 1,
        showSearchBox: 1,
      },
      formErrors: {},
      openDialog: false,
      options: [
        { id: "1", name: "Edit" },
        { id: "2", name: "Delete" },
        { id: "3", name: "View" },
        { id: "4", name: "PDF" },
        { id: "5", name: "Excel" },
      ],
    };
  }
  checkValidation = () => {
    const { fieldData } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (
      fieldData["reportName"] === undefined ||
      fieldData["reportName"] == ""
    ) {
      formIsValid = false;
      formErrors["reportName"] = msgConstant.ReportMsg;
    }
    if (fieldData["apiName"] === undefined || fieldData["apiName"] == "") {
      formIsValid = false;
      formErrors["apiName"] = msgConstant.apiNameMsg;
    }
    // if (
    //   fieldData["action"] === undefined ||
    //   fieldData["action"] == "" ||
    //   fieldData["action"].length == 0
    // ) {
    //   formIsValid = false;
    //   formErrors["action"] = msgConstant.actionMsg;
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };
  async componentDidMount() {
    const {
      getSubReportWithoutLinkInfo,
      // getType,
      setSaveMasterGenerate,
      showLoador,
      showNotification,
    } = this.props;
    if (this.props.params.id) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.formBuilder + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              let rowObject = data.data;

              setSaveMasterGenerate({ rowObject });
            }
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getSubReportWithoutLinkInfo({ flag: "0" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    // if (navigator.onLine) {
    //   showLoador({ loador: true });
    //   await getType().then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    // } else {
    //   this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    // }
  }

  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        fieldData: {
          ...this.state.fieldData,
          [name]: newValue,
        },
      });
    } else {
      this.setState({
        fieldData: {
          ...this.state.fieldData,
          [name]: "",
        },
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.masterGenerateData != this.props.masterGenerateData) {
      if (
        nextProps.masterGenerateData.masterData !=
        this.props.masterGenerateData.masterData
      ) {
        console.log(nextProps.masterGenerateData.masterData);
        let params = JSON.parse(nextProps.masterGenerateData.masterData.params)
          ? JSON.parse(nextProps.masterGenerateData.masterData.params)
          : {};
        let fieldData = {
          ...nextProps.masterGenerateData.masterData,
          paramsKey: params.paramsKey ? params.paramsKey : "",
          paramsValue: params.paramsValue ? params.paramsValue : "",
        };
        this.setState({
          fieldData: fieldData,
          // type: nextProps.masterGenerateData.masterData.reportType,
          reportName: nextProps.masterGenerateData.masterData.reportName,
          // action: nextProps.masterGenerateData.masterData.action.join(),
        });
      }
    }
  }
  onMultiSelectChange = (value, name) => {
    let dataValue = typeof value === "string" ? value.split(",") : value;
    this.setState({
      fieldData: {
        ...this.state.fieldData,
        [name]: dataValue,
      },
    });
  };
  onTextFieldDataChange = (event) => {
    if (event.target.value === "" || validText.test(event.target.value)) {
      this.setState({
        fieldData: {
          ...this.state.fieldData,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  onTextFieldDataChangeForNumber = (event) => {
    this.setState({
      fieldData: {
        ...this.state.fieldData,
        [event.target.name]: event.target.value,
      },
    });
  };
  onRadioChange = (dataKey) => (event) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [dataKey]: event.target.value },
    });
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  onFormSave = () => {
    if (this.checkValidation()) {
      const { fieldData } = this.state;
      let params = {};
      let formFields = [];
      let dataToSave = {
        reportType: "1",
        parameterRequired: "[]",
        reportId: null,
        queryParam: "[]",
        params: "[]",
        tableColumns: "[]",
        isDynamic: 0,
        query: "",
        sortOrder: "1",
        formFields: JSON.stringify(formFields),
        ...fieldData,
        params: JSON.stringify(params),
      };

      this.onSave(dataToSave);
    }
  };
  onSave = (dataToSave) => {
    const { saveMasterGenerate, showLoador, showNotification } = this.props;
    console.log(dataToSave);
    if (navigator.onLine) {
      showLoador({ loador: true });
      saveMasterGenerate({ dataToSave }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
          });
          if (dataToSave.id) {
            this.props.changeTab(1);
          } else {
            window.location.replace("/master-generate/" + response.id);
          }
        } else {
          this.props.showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  toggleFilter = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  render() {
    const { type, masterGenerateData } = this.props;

    const { fieldData, formErrors, options } = this.state;

    return (
      <>
        <Grid container sx={{ marginTop: 2 }} spacing={1}>
          {/* <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Type :"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >

                            <AutoComplete
                                name={"reportType"}
                                label={"reportType"}
                                placeHolder={"Report Type"}
                                keyColName={"id"}
                                value={fieldData["reportType"] ? fieldData["reportType"] : ""}
                                options={type.type}
                                onChange={this.onAutocompleteChange}
                                isError={formErrors["reportType"] ? true : false}
                                errorText={formErrors["reportType"] ? formErrors["reportType"] : ""}
                            />
                        </Grid>
                    </Grid> */}
          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Master Name :"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <TextFieldCompo
                placeHolder={"Master Name"}
                size="small"
                color="primary"
                name={"reportName"}
                id={"reportName"}
                value={fieldData["reportName"] ? fieldData["reportName"] : ""}
                onChange={this.onTextFieldDataChange}
                fullWidth
                errorText={
                  formErrors["reportName"] ? formErrors["reportName"] : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"API URL :"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <TextFieldCompo
                placeHolder={"API URL"}
                size="small"
                color="primary"
                name={"apiName"}
                id={"apiName"}
                value={fieldData["apiName"] ? fieldData["apiName"] : ""}
                onChange={this.onTextFieldDataChange}
                fullWidth
                errorText={formErrors["apiName"] ? formErrors["apiName"] : ""}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Save URL :"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <TextFieldCompo
                placeHolder={"Save URL"}
                size="small"
                color="primary"
                name={"saveUrl"}
                id={"saveUrl"}
                value={fieldData["saveUrl"] ? fieldData["saveUrl"] : ""}
                onChange={this.onTextFieldDataChange}
                fullWidth
                errorText={formErrors["saveUrl"] ? formErrors["saveUrl"] : ""}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Edit URL :"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <TextFieldCompo
                placeHolder={"Edit URL"}
                size="small"
                color="primary"
                name={"editUrl"}
                id={"editUrl"}
                value={fieldData["editUrl"] ? fieldData["editUrl"] : ""}
                onChange={this.onTextFieldDataChange}
                fullWidth
                errorText={formErrors["editUrl"] ? formErrors["saveUrl"] : ""}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Onload URL :"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <TextFieldCompo
                placeHolder={"Onload URL"}
                size="small"
                color="primary"
                name={"formOnloadUrl"}
                id={"formOnloadUrl"}
                value={
                  fieldData["formOnloadUrl"] ? fieldData["formOnloadUrl"] : ""
                }
                onChange={this.onTextFieldDataChange}
                fullWidth
                errorText={
                  formErrors["formOnloadUrl"] ? formErrors["formOnloadUrl"] : ""
                }
              />
            </Grid>
          </Grid>
          {/* <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Table Action :"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <MultipleSelect
                label={"Action"}
                er={formErrors["action"] ? formErrors["action"] : ""}
                name1={"action"}
                value1={fieldData["action"] ? fieldData["action"] : []}
                names={options}
                onChange={this.onMultiSelectChange}
              />
            </Grid>
          </Grid> */}

          {/* <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Sort Order :"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <TextFieldCompo
                placeHolder={"Sort Order"}
                size="small"
                color="primary"
                name={"sortOrder"}
                id={"sortOrder"}
                value={fieldData["sortOrder"] ? fieldData["sortOrder"] : ""}
                onChange={this.onTextFieldDataChangeForNumber}
                fullWidth
                errorText={
                  formErrors["sortOrder"] ? formErrors["sortOrder"] : ""
                }
              />
            </Grid>
          </Grid> */}
          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Is PDF:"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <RadioGroup
                row
                aria-label={"isPdf"}
                name={"isPdf"}
                id={"isPdf"}
                value={fieldData["isPdf"] ? fieldData["isPdf"] : "0"}
                onChange={this.onRadioChange("isPdf")}
                size="small"
              >
                <FormControlLabel 
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                value={1} control={<Radio />} label={"Yes"} />
                <FormControlLabel 
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                value={0} control={<Radio />} label={"No"} />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Is Excel:"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <RadioGroup
                row
                aria-label={"isExcel"}
                name={"isExcel"}
                id={"isExcel"}
                value={fieldData["isExcel"] ? fieldData["isExcel"] : "0"}
                onChange={this.onRadioChange("isExcel")}
                size="small"
              >
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={1} control={<Radio />} label={"Yes"} />
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={0} control={<Radio />} label={"No"} />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Show Add Button:"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <RadioGroup
                row
                aria-label={"showAddButton"}
                name={"showAddButton"}
                id={"showAddButton"}
                value={
                  fieldData["showAddButton"] ? fieldData["showAddButton"] : "0"
                }
                onChange={this.onRadioChange("showAddButton")}
                size="small"
              >
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={1} control={<Radio />} label={"Yes"} />
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={0} control={<Radio />} label={"No"} />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Show Search:"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <RadioGroup
                row
                aria-label={"showSearchBox"}
                name={"showSearchBox"}
                id={"showSearchBox"}
                value={
                  fieldData["showSearchBox"] ? fieldData["showSearchBox"] : "0"
                }
                onChange={this.onRadioChange("showSearchBox")}
                size="small"
              >
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={1} control={<Radio />} label={"Yes"} />
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={0} control={<Radio />} label={"No"} />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"isActive:"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <RadioGroup
                row
                aria-label={"isActive"}
                name={"isActive"}
                id={"isActive"}
                value={fieldData["isActive"] ? fieldData["isActive"] : "0"}
                onChange={this.onRadioChange("isActive")}
                size="small"
              >
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={1} control={<Radio />} label={"Yes"} />
                <FormControlLabel
                 sx={{
                  "& svg": {
                    width: "0.8em",
                    height: "0.8em"
                  }
                }}
                 value={0} control={<Radio />} label={"No"} />
              </RadioGroup>
            </Grid>
          </Grid>
          {fieldData["isActive"] == 1 && (
            <>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Active URL:"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Active URL"}
                    size="small"
                    color="primary"
                    name={"activeUrl"}
                    id={"activeUrl"}
                    value={fieldData["activeUrl"] ? fieldData["activeUrl"] : ""}
                    onChange={this.onTextFieldDataChange}
                    fullWidth
                    errorText={
                      formErrors["activeUrl"] ? formErrors["activeUrl"] : ""
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
          {/* <Grid container xs={12} sm={6} md={6}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              &nbsp;&nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{
                  fontSize: 16,
                  color: titleColor,
                }}
                label={"Is Sub Report:"}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <RadioGroup
                row
                aria-label={"isSubreport"}
                name={"isSubreport"}
                id={"isSubreport"}
                value={
                  fieldData["isSubreport"] ? fieldData["isSubreport"] : "0"
                }
                onChange={this.onRadioChange("isSubreport")}
                size="small"
              >
                <FormControlLabel value={1} control={<Radio />} label={"Yes"} />
                <FormControlLabel value={0} control={<Radio />} label={"No"} />
              </RadioGroup>
            </Grid>
          </Grid>
          {fieldData["isSubreport"] == 1 && (
            <>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Select Report :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <AutoComplete
                    name={"reportId"}
                    label={"reportId"}
                    placeHolder={"Report "}
                    keyColName={"id"}
                    value={fieldData["reportId"] ? fieldData["reportId"] : ""}
                    options={report.report}
                    onChange={this.onAutocompleteChange}
                    isError={formErrors["reportId"] ? true : false}
                    errorText={
                      formErrors["reportId"] ? formErrors["reportId"] : ""
                    }
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Parameter Key:"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Report Parameter"}
                    size="small"
                    color="primary"
                    name={"paramsKey"}
                    id={"paramsKey"}
                    value={fieldData["paramsKey"] ? fieldData["paramsKey"] : ""}
                    onChange={this.onTextFieldDataChange}
                    fullWidth
                    errorText={
                      formErrors["paramsKey"] ? formErrors["paramsKey"] : ""
                    }
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Parameter Value:"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Report Parameter"}
                    size="small"
                    color="primary"
                    name={"paramsValue"}
                    id={"paramsValue"}
                    value={
                      fieldData["paramsValue"] ? fieldData["paramsValue"] : ""
                    }
                    onChange={this.onTextFieldDataChange}
                    fullWidth
                    errorText={
                      formErrors["paramsValue"] ? formErrors["paramsValue"] : ""
                    }
                  />
                </Grid>
              </Grid>
            </>
          )} */}
        </Grid>
        <br />

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Save and Next"
            onClick={this.onFormSave}
          />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  masterGenerateData: state.masterGenerate,
  type: state.type,
});
const mapDispatchToProps = {
  showLoador,
  getSubReportWithoutLinkInfo,
  showNotification,
  setSaveMasterGenerate,
  saveMasterGenerate,
  // getType,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MasterForm);
