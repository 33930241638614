import {
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
import React, { Component } from "react";
import { saveReport, setSaveReport } from '../../Slice/matrixReport.slice'

import { connect } from "react-redux";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import { redColor, titleColor } from "../../config/ColorObj";
import * as msgConstant from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { withRouter } from "../../components/withRouter";
import { LabelCompo } from "../../components/Comman/Label";
import { validText } from "../../components/Comman/Util/Validations";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { ButtonCompo } from "../../components/Comman/Button";
import GraphTable from './Graphtable'
import AddGraph from './GraphPopUp'
class ReportForm extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            isGraph: "1",
            openDialog: false,
            graphColumns: [],
            rowData: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.report != this.props.report) {
            if (nextProps.report.reportData != this.props.report.reportData) {
                console.log(nextProps.report.reportData)
                this.setState({
                    isGraph: nextProps.report.reportData.isGraph,
                    graphColumns: JSON.parse(nextProps.report.reportData.graphColumns) != null ? JSON.parse(nextProps.report.reportData.graphColumns) : [],

                });
            }
        }
    }
    componentDidMount() {
        console.log(JSON.parse(this.props.report.reportData.graphColumns))
        this.setState({
            graphColumns: JSON.parse(this.props.report.reportData.graphColumns) != null ? JSON.parse(this.props.report.reportData.graphColumns) : [],

        });
    }
    onFormSave = () => {
        this.props.navigate(-1)
    }
    onRadioChange = (event) => {

        this.onSave({
            ...this.props.report.reportData,
            isGraph: event.target.value
        }
        )
    };
    onSave = (dataToSave) => {

        if (navigator.onLine) {
            const {
                saveReport,
                showLoador,
                showNotification,
            } = this.props;
            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });

                } else {
                    showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };

    toggleFilter = () => {
        if (this.state.openDialog) {
            this.setState({
                rowData: {},
            })
        }
        this.setState({
            openDialog: !this.state.openDialog
        })
    }
    rowEdit = (rowData) => {

        this.setState({
            rowData: rowData
        })
        this.toggleFilter()
    }
    render() {
        const {

        } = this.props;

        const { isGraph, graphColumns, rowData, openDialog } = this.state;

        return (
            <>

                <Grid container sx={{ marginTop: 2 }} spacing={1}>

                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Is Graph:"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >
                            <RadioGroup
                                row
                                aria-label={"isGraph"}
                                name={"isGraph"}
                                id={"isGraph"}
                                value={isGraph}
                                onChange={this.onRadioChange}
                                size="small"
                            >
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={1}
                                    control={<Radio />}
                                    label={"Yes"}
                                />
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={0}
                                    control={<Radio />}
                                    label={"No"}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    {
                        isGraph == 1 && <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="Add"
                                // fullWidth={true}
                                onClick={this.toggleFilter}
                            />
                        </Grid>
                    }

                </Grid>
                {
                    isGraph == 1 &&
                    <>
                        <br />
                        <GraphTable rowEdit={this.rowEdit} graphColumns={graphColumns} />
                    </>}
                <br />

                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                >
                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Submit"
                        // fullWidth={true}
                        onClick={this.onFormSave}
                    />


                </Grid>
                {openDialog && <AddGraph graphColumns={graphColumns} rowData={rowData} openDialog={openDialog} setOpenDialouge={this.toggleFilter}
                />}
            </>
        );
    }
}
const mapStateToProps = (state) => ({ report: state.matrixReport });
const mapDispatchToProps = { showLoador, showNotification, saveReport };
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ReportForm);
