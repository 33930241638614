import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getCashRecieptWithAccNameById,
  getCostCenter,
} from "../../Slice/cashReceipt.slice";
import { getJVWithAccNameById } from "../../Slice/journalVoucher.slice";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CashReciptPopupForReport from '../CashReceipt/CashReciptPopupForReport'
import BankReciptPopupForReport from '../BankReciept/BankReciptPopupForReport'
import CashPaymentPopupForReport from '../CashPayment/CashPaymentPopupForReport'
import BankPaymentPopupForReport from "../BankPayment/BankPaymentPopupForReport"
import JouranlEntryPopupForReport from "../JournalEntry/JournalEntryPopupForReport"
import ContraEntryPopupForReport from "../ContraEntry/ContraEntryPopupForReport";
import DebiteNotePopupForReport from "../DebitNote/DebiteNotePopupForReport";
import CreditNotePopupForReport from "../CreditNote/CrediteNotePopupForReport";
const LedgerTable = ({ rowList = [], tableHead = [], classes,getCashRecieptWithAccNameById,getJVWithAccNameById,showLoador,showNotification }) => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openBankReceiptPopup, setOpenBankReceiptPopup] = useState(false);
  const [openCashPaymentPopup, setOpenCashPaymentPopup] = useState(false);
  const [openBankPaymentPopup, setOpenBankPaymentPopup] = useState(false);
  const [openJouranlEntryPopup, setOpenJouranlEntryPopup] = useState(false);
  const [openContraEntryPopup, setOpenContraEntryPopup] = useState(false);
  const [openDebiteNotePopup, setOpenDebiteNotePopup] = useState(false);
  const [openCreditNotePopup, setOpenCreditNotePopup] = useState(false);
  
  const [editData, setEditData] = useState({});
  const [journalEditData, setJournalEditData] = useState({});
  
  const togglePopup = () => {
  
    setOpenPopup(!openPopup);

  };
const toggleBankReceiptPopup = ()=>{

  setOpenBankReceiptPopup(!openBankReceiptPopup)
}
const toggleCashPaymentPopup = () => {
  
  setOpenCashPaymentPopup(!openCashPaymentPopup);

};
const toggleBankPaymentPopup = ()=>{
  setOpenBankPaymentPopup(!openBankPaymentPopup)
}
const toggleJouranlEntryPopup = ()=>{
  setOpenJouranlEntryPopup(!openJouranlEntryPopup)
}

const toggleContraEntryPopup = ()=>{
  setOpenContraEntryPopup(!openContraEntryPopup)
}
const toggleDebiteNotePopup = ()=>{
  setOpenDebiteNotePopup(!openDebiteNotePopup)
}

const toggleCreditNotePopup = ()=>{
  setOpenCreditNotePopup(!openCreditNotePopup)
}
 const getByIdData = async (id,tranctionTable) => {
   if(tranctionTable==2){
    showLoador({ loador: true });
    await getCashRecieptWithAccNameById({
        id: id,
    }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
        } else {

         
           
            setEditData(response);
            if(response.voucherType!=null){
              if(response.voucherType==1){
                setOpenPopup(!openPopup);
              }
              if(response.voucherType==2){
                setOpenCashPaymentPopup(!openCashPaymentPopup);
              }
              if(response.voucherType==3){
                setOpenBankReceiptPopup(!openBankReceiptPopup)
              }
              if(response.voucherType==4){
                setOpenBankPaymentPopup(!openBankPaymentPopup)
              }
             
              if(response.voucherType==6){
                setOpenCreditNotePopup(!openCreditNotePopup)
              }
              if(response.voucherType==7){
                setOpenDebiteNotePopup(!openDebiteNotePopup)
              }
              if(response.voucherType==8){
                setOpenContraEntryPopup(!openContraEntryPopup)
              }
            }
          
          
        }
       
    });
   }
    
   if(tranctionTable==3){
    showLoador({ loador: true });
    await getJVWithAccNameById({
        id: id,
    }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
        } else {

         
           
            setJournalEditData(response);
           
                setOpenJouranlEntryPopup(!openJouranlEntryPopup)
              
           
            
          
          
        }
       
    });
  }
}
  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr className={classes.MuiTableHeadRoot}>
            {tableHead.map((head) => {
              return (
                <td
                  style={{ textAlign: "center" }}
                  className={classes.MuiTableHeadCell}
                >
                  {head.title}
                </td>
              );
            })}{" "}
          </tr>
          {rowList.map((rowData) => {
            let openingAmt =
              rowData.openingDdebitAmt + rowData.openingCreditAmt;
            let balance = 0;
            return (
              <>
                <tr>
                  <td

                    className={classes.MuiTableBodyCell}
                    style={{ fontWeight: fontWeight }}
                  >
                    <u>
                      {rowData.accDetail != null
                        ? rowData.accDetail.account_ledger_name +
                        " - " +
                        rowData.accDetail.account_ledger_code
                        : ""}
                    </u>
                  </td>
                  <td className={classes.MuiTableBodyCell}></td>
                  <td className={classes.MuiTableBodyCell}></td>
                  <td className={classes.MuiTableBodyCell}></td>
                  <td className={classes.MuiTableBodyCell}></td>
                  <td className={classes.MuiTableBodyCell}></td>
                </tr>

                <tr>
                  <td className={classes.MuiTableBodyCell}></td>
                  <td className={classes.MuiTableBodyCell}></td>
                  <td className={classes.MuiTableBodyCell}>
                    Opening Balance
                  </td>
                  <td className={classes.MuiTableBodyCell}></td>
                  <td
                    className={classes.MuiTableBodyCell}
                    style={{ textAlign: "right" }}
                  >
                    {Math.abs(rowData.openingDdebitAmt).toFixed(2)}
                  </td>
                  <td
                    className={classes.MuiTableBodyCell}
                    style={{ textAlign: "right" }}
                  >
                    {Math.abs(rowData.openingCreditAmt).toFixed(2)}
                  </td>

                  <td
                    className={classes.MuiTableBodyCell}
                    style={{ textAlign: "right" }}
                  >
                    {rowData.openingCreditAmt + rowData.openingDdebitAmt >=
                      0
                      ? Math.abs(
                        rowData.openingCreditAmt +
                        rowData.openingDdebitAmt
                      ).toFixed(2) + " Cr"
                      : Math.abs(
                        rowData.openingCreditAmt +
                        rowData.openingDdebitAmt
                      ).toFixed(2) + " Dr"}
                  </td>
                </tr>


                {rowData.accLedgerList.map((data, index) => {
                  let creditTotal = 0;
                  let debitTotal = 0;
                  if (data.type == "D") {
                    debitTotal = debitTotal + data.amount;
                  } else {
                    creditTotal = creditTotal + data.amount;
                  }

                  if (index == 0) {
                    balance = openingAmt + data.amount;
                  } else {
                    balance = balance + data.amount;
                  }

                  return (
                    <tr>
                      <td className={classes.MuiTableBodyCell}>
                        {data.documentDate}
                      </td>
                      <td
                        className={classes.MuiTableBodyCell}
                        style={{ textAlign: "center", width: "20%" }}
                      >
                        <>{data.documentNo == null ? "" : data.documentNo}</>
                      </td>
                      {data.tranctionTable==1?( <td  className={classes.MuiTableBodyCell}>
                        <>
                          {data.refAccName != null ? data.refAccName : ""}
                          <br />
                          {data.remark != null ? data.remark : ""}
                        </>
                      </td>):( <td  style={{ cursor: 'pointer' }} onClick={() => {
                        getByIdData(data.documentId,data.tranctionTable)
                      }} className={classes.MuiTableBodyCell}>
                        <>
                          {data.refAccName != null ? data.refAccName : ""}
                          <br />
                          {data.remark != null ? data.remark : ""}
                        </>
                      </td>)}
                     
                      <td
                        className={classes.MuiTableBodyCell}
                        style={{ textAlign: "center" }}
                      >
                        <>-</>
                      </td>
                      <td
                        className={classes.MuiTableBodyCell}
                        style={{ textAlign: "right" }}
                      >
                        <>
                          {data.type == "D"
                            ? Math.abs(data.amount).toFixed(2)
                            : ""}
                        </>
                      </td>
                      <td
                        className={classes.MuiTableBodyCell}
                        style={{ textAlign: "right" }}
                      >
                        <>
                          {data.type == "C"
                            ? Math.abs(data.amount).toFixed(2)
                            : ""}
                        </>
                      </td>
                      <td
                        className={classes.MuiTableBodyCell}
                        style={{ textAlign: "right" }}
                      >
                        <>
                          {balance >= 0
                            ? Math.abs(balance).toFixed(2) + " Cr"
                            : Math.abs(balance).toFixed(2) + " Dr"}
                        </>
                      </td>
                    </tr>
                  );

                })}
              </>
            );
          })}
        </table>
      </div>

      {openPopup && (
        <CashReciptPopupForReport
          editData={editData}
          open={openPopup}
          handleCloses={togglePopup}
        />
      )}
       {openBankReceiptPopup && (
        <BankReciptPopupForReport
          editData={editData}
          open={openBankReceiptPopup}
          handleCloses={toggleBankReceiptPopup}
        />
      )}
      {openCashPaymentPopup && (
        <CashPaymentPopupForReport
          editData={editData}
          open={openCashPaymentPopup}
          handleCloses={toggleCashPaymentPopup}
        />
      )}
      {openBankPaymentPopup && (
        <BankPaymentPopupForReport
          editData={editData}
          open={openBankPaymentPopup}
          handleCloses={toggleBankPaymentPopup}
        />
      )}

{openJouranlEntryPopup && (
        <JouranlEntryPopupForReport
          editData={journalEditData}
          open={openJouranlEntryPopup}
          handleCloses={toggleJouranlEntryPopup}
        />
      )}


{openContraEntryPopup && (
        <ContraEntryPopupForReport
          editData={editData}
          open={openContraEntryPopup}
          handleCloses={toggleContraEntryPopup}
        />
      )}


{openDebiteNotePopup && (
        <DebiteNotePopupForReport
          editData={editData}
          open={openDebiteNotePopup}
          handleCloses={toggleDebiteNotePopup}
        />
      )}
      

{openCreditNotePopup && (
        <CreditNotePopupForReport
          editData={editData}
          open={openCreditNotePopup}
          handleCloses={toggleCreditNotePopup}
        />
      )}
      
      
    </>
  );
};

const mapStateToProps = (state) => ({
   cashRecieptList: state.cashReciept,
   journalVoucherList: state.journalVoucher,
});

const mapDispatchToProps = {
  getCashRecieptWithAccNameById,
  getJVWithAccNameById,
  showLoador,showNotification
};


export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(LedgerTable);
