import SearchIcon from '@mui/icons-material/Search';
import { Card, Grid, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Search from "../../components/SearchAll";

import { compose } from "@reduxjs/toolkit";
import moment from "moment";
import GroupSummarySubReportByGroup from '../AccountReport/GroupSummarySubReportByGroup'

import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import RTTable from "../../components/Comman/RT/RTTable";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import DoubleBarChart from "../../components/DoubleBarChart";

import { withRouter } from "../../components/withRouter";
import { blackColor, primaryColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CardComponent from "./CardComponent";
import TitleCompo from "./TitleCompo";
import SubTitle from './SubTitle'
import ApprovalPending from './ApprovalPending'
import SimpleT from './SimpleT';
class Dashbourd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTable:false,
      popUpData:{},
      accountName:"",
      accountNameMain:"",
      dashboardData: {},
      salesGraphDateWise: [],
      monthWisePurchaseList: [],
      accountWiseSaleList: [],
      accountWisePurchaseList: [],
      yearMasterDetail: [],
      CompanyDetails: [],
      formErrors: {},
      companyId: "",
      yearId: "",
      fromDate: "",
      toDate: "",
      searchValue: "",
      searchValueSales:"",
      searchValuePurchase:"",
      searchValueOutSales:"",
      searchValueOutPurchase:"",
      profitAndLoss: [],
      profit:[],
      loss:[],
      MFM: [],
      monthWiseSaleTableList: [],
      monthWisePurchaseTableList: [],
    };
  }
  componentDidMount() {
    const location=localStorage.getItem("location")
    if(location!=null){
    this.props.showLoador({ loador: true });
    apiGet({
      url: endpoint.dash + "?locationIds=" + location,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data.data);
        this.props.showLoador({ loador: false });

        this.setState({
          dashboardData: data.data,
        });
      
        if (Object.keys(data.data).length != 0) {
          console.log(data.data)
          if (data.data.salesGraphMonthWise.length != 0 && data.data.salesGraphMonthWise[0] != null) {

            this.onClickLineChart(data.data.salesGraphMonthWise, 0,1);
          }
          if (data.data.purchaseGraphMonthWise.length != 0 && data.data.purchaseGraphMonthWise[0] != null) {
           
            this.onClickLineChart(data.data.purchaseGraphMonthWise, 0,2);
          }
          if (data.data.salesOutstandingAmountAccWise.length != 0 && data.data.salesOutstandingAmountAccWise[0] != null) {

            this.onClickAccountWiseSaleLineChart(data.data.salesOutstandingAmountAccWise, 0,1);
          }
          if (data.data.purchaseOutstandingAmountAccWise.length != 0 && data.data.purchaseOutstandingAmountAccWise[0] != null) {
            this.onClickAccountWiseSaleLineChart(data.data.purchaseOutstandingAmountAccWise, 0,2);
          }
        }
       
      } else {
        this.props.showLoador({ loador: false });
        this.props.showNotification({ msg: serverMsg, severity: "error" });
      
      }
    });
   
  }
  this.getDropDownList();
  }
  getDropDownList = async () => {
    this.props.showLoador({ loador: true });
    const yearMasterDetail = await apiGet({
      url: endpoint.year,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let rowData = data.data;

        this.props.showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.yearName };
        })
      } else {

        this.props.showLoador({ loador: false });
        this.props.showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

      return []
    });
    this.props.showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoint.company,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data;
        const location=localStorage.getItem("location");
 const locationList =localStorage.getItem("locationIdList");
 const locallist=JSON.parse(locationList)
 const locationlist=locallist.filter((data)=>data.id==location)
 
 if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
 
 this.setState({companyId:locationlist[0].company.id })
 }
        this.props.showLoador({ loador: false });
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.companyName };
        })
      } else {

        this.props.showLoador({ loador: false });
        this.props.showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });

    this.setState({

      yearMasterDetail: yearMasterDetail
    });
    this.setState({

      CompanyDetails: CompanyDetails
    });

  }
  onSave = () => {

    const { companyId, yearId, formErrors } = this.state;
    if (companyId != "" && yearId != "") {
     
      let yearData=this.state.yearMasterDetail.find((rowData)=>rowData.id===yearId)
     
      this.setState({
        fromDate:yearData.fromDate,
        toDate:yearData.toDate,
        formErrors: {}
      })
      this.getList(companyId, yearId)
    } else {
      this.setState({
        formErrors: {

          companyError: companyId == "" ? "company is required" : "",
          yearError: yearId != "" ? "" : "year is required"
        }
      })
    }
    //   getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
  }

  getList = (companyId, yearId) => {
    this.props.showLoador({ loador: true });
    apiGet({
      url: endpoint.MFGAndPf + "?companyIds=" + companyId + "&yearId=" + yearId
      //    "?companyId=" + companyId + "&fromDate=" + "fromDate" + "&toDate=" + toDate + "&groupIds=" + accId

    }).then(({ data, success }) => {

      if (success) {
        let rowList = []
        let mfmList = []
      
        let rowData = Object.keys(data.data).length != 0 &&
          data.data.profitAndLoss != null ? data.data.profitAndLoss : {}
        rowData.dynamicData.map((rowDataInfo) => {
         let value= (rowDataInfo.creditAmt + rowDataInfo.debitAmt)
       let allignment=  rowDataInfo.groupDetail.allignment
       let amt=(allignment=='L'&&value>0)|| (allignment=='R'&&value<0)?"("+Math.abs(value).toFixed(2)+")":Math.abs(value).toFixed(2)
          rowList.push({
            name: rowDataInfo.groupDetail.group_name,
            id:rowDataInfo.groupDetail.id,
          value: amt ,
          amount:value,
            alligment:allignment
          })
        })
        rowData.staticData.map((rowDataInfo) => {
          let value=rowDataInfo.amt
          let allignment= rowDataInfo.alligment
          let amt=(allignment=='L'&&value>0)|| (allignment=='R'&& value < 0)?"("+Math.abs(value).toFixed(2)+")":Math.abs(value).toFixed(2)
          rowList.push({
            name: rowDataInfo.name,
            value: amt,
            amount:value,
            alligment:allignment
          })
        })

        // let manufacturingAndTradingData = Object.keys(data.data).length != 0 &&
        //   data.data.manufacturingAndTrading != null ? data.data.manufacturingAndTrading : {}
        // manufacturingAndTradingData.dynamicData.map((rowDataInfo) => {
        //   let value= (rowDataInfo.creditAmt + rowDataInfo.debitAmt)
        //   let allignment=  rowDataInfo.groupDetail.allignment
        //   let amt=(allignment=='L'&&value>0)|| (allignment=='R'&&value<0)?"("+Math.abs(value).toFixed(2)+")":Math.abs(value).toFixed(2)
        //      mfmList.push({
        //     name: rowDataInfo.groupDetail.group_name,
        //     value: amt,
        //     amount:value,
        //     alligment:rowDataInfo.groupDetail.allignment
        //   })
        // })
        // manufacturingAndTradingData.staticData.map((rowDataInfo) => {
        //   let value=rowDataInfo.amt
        //   let allignment= rowDataInfo.alligment
        //   let amt=(allignment=='L'&&value>0)|| (allignment=='R'&&value<0)?"("+Math.abs(value).toFixed(2)+")":Math.abs(value).toFixed(2)
        //   mfmList.push({
        //     name: rowDataInfo.name,
        //     value: amt,
        //     amount:value,
        //     alligment:allignment
        //   })
        // })
        let right=  rowList.filter((rowData)=>rowData.alligment!='L')
      let left=  rowList.filter((rowData)=>rowData.alligment=='L')
     
      if(right.length>left.length){
       let extraList= [...Array(right.length-left.length)].map((x, i) => ({ name: "",value:"" }))
       
       left=[
        ...left,
        ...extraList
       ]
      }else{
        let extraList= [...Array(left.length-right.length)].map((x, i) => ({ name: "",value:"" }))
        
        right=[
         ...right,
         ...extraList
        ]
      }
      this.setState({
          profitAndLoss: rowList,
          profit: left ,
          loss: right,
          MFM: mfmList
        })

        this.props.showLoador({ loador: false });

      } else {
        this.setState({
          profitAndLoss: [],
          profit: [],
          loss: [],
          MFM: []
        })
        this.props.showLoador({ loador: false });
        this.props.showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  }
  onSearchPurchase = (searchV) => {
    this.setState({ searchValuePurchase: searchV })

  };
  onSearchOutPurchase = (searchV) => {
    this.setState({ searchValueOutPurchase: searchV })

  };
  onSearchOutSales = (searchV) => {
    this.setState({ searchValueOutSales: searchV })
  };
  onSearchSales = (searchV) => {
    this.setState({ searchValueSales: searchV })
  };
  
   toggle = () => {
    this.setState({
      openTable: !this.state.openTable
    })
    
        this.getList(this.state.companyId,this.state.yearId)
    

};
   rowLink = (rowData, key) => {
    if (rowData.id) {
      this.setState({
        openTable:!this.state.openTable,
        popUpData:{ groupId: rowData.id, groupName: rowData.name }
      })   
    }

}
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  onClickLineChart = (list, index,type) => {
    const { dashboardData } = this.state;
    const { showLoador, showNotification } = this.props;
    console.log("index")
    console.log(index)
    let catOBJ = list[index];
    console.log("catOBJ");
    console.log(catOBJ);
    if (Object.keys(catOBJ).length != 0) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.salesPurchase +
            "/get-purchase-sale-amount-datewise?locationIds=" +
            localStorage.getItem("location") +
            "&type="+type+"&monthYear=" +
            catOBJ.month_year,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if(type==1){
              this.setState({
                salesGraphDateWise: data.data.length != 0 ? data.data : [],
              });
            }
            if(type==2){
              this.setState({
                monthWisePurchaseList: data.data.length != 0 ? data.data : [],
              });
            }
           
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

 
  onClickDateWiseSaleChart = (list, index,type) => {
    const { dashboardData } = this.state;
    const { showLoador, showNotification } = this.props;
    let catOBJ = list[index];
    // dashboardData.purchaseGraphMonthWise[index];

    console.log("catOBJ");
    console.log(catOBJ);
    if (Object.keys(catOBJ).length != 0) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.salesPurchase +
            "/get-purchase-sale-amount-by-date?locationIds=" +
            localStorage.getItem("location") +
            "&type="+type+"&monthYear=" +
            catOBJ.month_year,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if(type==1){
              this.setState({
                monthWiseSaleTableList: data.data.length != 0 ? data.data.map((rowData) => {
                 
                  return {
                    document_no: rowData.document_no,
                    document_date:rowData.doc_date,
                    account_ledger_code: rowData.account_ledger_code,
                    account_ledger_name: rowData.account_ledger_name,
                    total_amt: rowData.total_amt.toFixed(2),
                  }
                }) : [],
              });
            }
            if(type==2){
              this.setState({
                monthWisePurchaseTableList: data.data.length != 0 ? data.data.map((rowData) => {
                  return {
                    
                    document_no: rowData.document_no,
                    document_date: rowData.doc_date,
                    account_ledger_code: rowData.account_ledger_code,
                    account_ledger_name: rowData.account_ledger_name,
                    total_amt: rowData.total_amt.toFixed(2),
                  }
                }) : [],
              });
            }
            
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  
  onClickAccountWiseSaleLineChart = (list, index,entryType) => {
    const { dashboardData } = this.state;
    const { showLoador, showNotification } = this.props;
    let catOBJ = list[index];
    console.log("catOBJ");
    console.log(catOBJ);
    if (Object.keys(catOBJ).length != 0) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.salePurchaseApproval +
            "/over-all-bill-outstanding-by-accId/?entryType="+entryType+"&accId=" +
            catOBJ.account_ledger_id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if(entryType==1){
              this.setState({
                accountName:catOBJ.ledger_code+"-"+catOBJ.account_ledger_name,
                accountWiseSaleList:
                  data.data.length != 0
                    ? data.data.map((account) => {
                      return {
                        documentNo:account.documentNo != "" ? account.documentNo : "",
                        date: account.date != "" ? account.date : "",
                        accLedgerCode:
                          account.accLedgerCode != null
                            ? account.accLedgerCode
                            : "",
                        accLedgerName:
                          account.accLedgerName != null
                            ? account.accLedgerName
                            : "",
                            agingDays:account.agingDays,
                        remainingAmt:
                          account.remainingAmt != null
                            ? (+account.remainingAmt).toFixed(2)
                            : "",
                            totalAmount:
                            account.voucherCode != null
                              ? (+account.voucherCode).toFixed(2)
                              : "",
                      };
                    })
                    : [],
              });
            }
           if(entryType==2){
            this.setState({
              accountNameMain:catOBJ.ledger_code+"-"+catOBJ.account_ledger_name,
              accountWisePurchaseList:
                data.data.length != 0
                  ? data.data.map((account) => {
                    return {
                      documentNo:account.documentNo != "" ? account.documentNo : "",
                      date: account.date != "" ? account.date : "",
                      accLedgerCode:
                        account.accLedgerCode != null
                          ? account.accLedgerCode
                          : "",
                      accLedgerName:
                        account.accLedgerName != null
                          ? account.accLedgerName
                          : "",
                          agingDays:account.agingDays,
                      remainingAmt:
                        account.remainingAmt != null
                          ? (+account.remainingAmt).toFixed(2)
                          : "",
                          totalAmount:
                            account.voucherCode != null
                              ? (+account.voucherCode).toFixed(2)
                              : "",
                    };
                  })
                  : [],
            });
           }
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  getFilteredTableDataSales = (monthList,searchValue) => {
  
    return monthList.filter((currentRow) => {
      let isValid = false;
        if (
          currentRow.document_no.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.document_date.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.account_ledger_code.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.account_ledger_name.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.total_amt.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        
      return isValid;
    });
  };
  getFilteredTableDataOutSalesPurchase = (monthList,searchValue) => {
  
    return monthList.filter((currentRow) => {
      let isValid = false;
        if (
          currentRow.documentNo.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.date.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.agingDays.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.remainingAmt.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        if (
          currentRow.totalAmount.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        
      return isValid;
    });
  };
  render() {
    const {
      dashboardData,
      salesGraphDateWise,
      monthWisePurchaseList,
      accountWiseSaleList,
      accountWisePurchaseList,
      accountName,
      accountNameMain,
      yearMasterDetail,
      CompanyDetails,
      companyId,
      toDate,
      fromDate,
      yearId,
      openTable,
      popUpData,
      profit,
      loss,
      MFM,
      monthWisePurchaseTableList,
      monthWiseSaleTableList,
      searchValueSales,
      searchValuePurchase,
      searchValueOutSales,
      searchValueOutPurchase
    } = this.state;
    const { companyError, yearError } = this.state.formErrors;
    
    return (
      <>
        <Paper
          sx={{

            background: "#F5F5F7",
          }}
          elevation={0}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={2}>
              <CardComponent
                backgroundColor={"#28C66F"}
                description={"description"}
                amount={
                  Object.keys(dashboardData).length != 0
                    ? dashboardData.purchaseSaleAmount.sales_amount
                    : 0
                }
                label={"Sales Amount"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <CardComponent
                backgroundColor={"#00D0E7"}
                description={"description"}
                amount={
                  Object.keys(dashboardData).length != 0
                    ? dashboardData.purchaseSaleAmount.purchase_amount
                    : 0
                }
                label={"Purchase Amount"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <CardComponent
                backgroundColor={"#4266CA"}
                description={"description"}
                amount={
                  Object.keys(dashboardData).length != 0
                    ? dashboardData.purchaseSaleAmount.sales_return_amount
                    : 0
                }
                label={"Sales Return"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <CardComponent
                backgroundColor={"#DA6F6F"}
                description={"description"}
                amount={
                  Object.keys(dashboardData).length != 0
                    ? dashboardData.purchaseSaleAmount.purchase_return_amount
                    : 0
                }
                label={"Purchase Return"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <CardComponent
                backgroundColor={"#FE9F43"}
                description={"description"}
                amount={
                  Object.keys(dashboardData).length != 0
                    ? dashboardData.purchaseSaleOutstandingAmount
                      .sales_remaining_amount
                    : 0
                }
                label={"Outstanding Sales"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <CardComponent
                backgroundColor={"#169DFF"}
                description={"description"}
                amount={
                  Object.keys(dashboardData).length != 0
                    ? dashboardData.purchaseSaleOutstandingAmount
                      .purchase_remaining_amount
                    : 0
                }
                label={"Outstanding Purchase"}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        color: primaryColor,
                      }}
                      label={"Monthwise Sales"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {Object.keys(dashboardData).length != 0 && (
                      <MonthlyBarChart
                        // radioValue={onChangeRadioValue}
                        height={350}
                        series={[
                          {
                            name: "Amount",
                            data: dashboardData.salesGraphMonthWise.map(
                              (month) => {
                                return month.sales_purchase_total_amount;
                              }
                            ),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            type: "bar",
                            height: 365,
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickLineChart(dashboardData.salesGraphMonthWise, config.dataPointIndex,1);
                              },
                            },
                          },

                          // plotOptions: {
                          //     bar: {
                          //         columnWidth: "40%",
                          //         distributed: true,
                          //     },
                          // },
                          // stroke: {
                          //     show: true,
                          //     width: 2,
                          //     // colors: ["transparent"],
                          // },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories: dashboardData.salesGraphMonthWise.map(
                              (month) => {
                                return month.month_year;
                              }
                            ),
                          },
                          yaxis: {
                            title: {
                              text: "$ (thousands)",
                            },
                          },
                          // fill: {
                          //     opacity: 1,
                          // },
                          colors: dashboardData.salesGraphMonthWise.map(
                            (month) => {
                              return primaryColor;
                            }
                          ),

                          // tooltip: {
                          //     theme: "light",
                          // },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"DateWise Sales"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {salesGraphDateWise.length != 0 && (
                      <MonthlyBarChart
                        // radioValue={onChangeRadioValue}
                        height={350}
                        series={[
                          {
                            name: "Amount",
                            data: salesGraphDateWise.map((month) => {
                              return month.sales_purchase_amount;
                            }),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            type: "bar",
                            height: 365,
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickDateWiseSaleChart(salesGraphDateWise, config.dataPointIndex,1);
                              },
                            },
                          },

                          // plotOptions: {
                          //     bar: {
                          //         columnWidth: "40%",
                          //         distributed: true,
                          //     },
                          // },
                          // stroke: {
                          //     show: true,
                          //     width: 2,
                          //     // colors: ["transparent"],
                          // },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories: salesGraphDateWise.map((month) => {
                              return month.month_year;
                            }),
                          },
                          yaxis: {
                            title: {
                              text: "$ (thousands)",
                            },
                          },
                          // fill: {
                          //     opacity: 1,
                          // },
                          colors: dashboardData.salesGraphMonthWise.map(
                            (month) => {
                              return "#28C66F";
                            }
                          ),

                          // tooltip: {
                          //     theme: "light",
                          // },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container xs={12} sm={12} md={12}>
                  <Grid item xs={4} sm={4} md={4}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Sales List"}
                    />
                   
                  </Grid>
                  <Grid sx={{paddingBottom:1, display:"flex",justifyContent:"flex-end"}}item xs={8} sm={8} md={8}>
                  
                     <Search
                          sx={{ width: "100%" }}
                          searchValue={searchValueSales}
                        filteredRows={this.onSearchSales}
                        />
                  </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <RTTable
                      columns={[
                        {
                          name: "document_no",
                          title: "Document No",
                          align: "center",
                        },
                        {
                          name: "document_date",
                          title: "Date",
                          align: "center",
                        },
                        {
                          name: "account_ledger_code",
                          title: "ACC Code ",
                          align: "center",
                        },
                        {
                          name: "account_ledger_name",
                          title: "ACCD ",

                          align: "left",
                        },
                        {
                          name: "total_amt",
                          title: "Amount",

                          align: "right",
                        },
                      ]}
                      showPegination={false}
                      showEditIcon={false}
                      showViewButtonInTable={true}
                      tableData={this.getFilteredTableDataSales(monthWiseSaleTableList,searchValueSales)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Monthwise Purchase"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {Object.keys(dashboardData).length != 0 && (
                      <MonthlyBarChart
                        // radioValue={onChangeRadioValue}
                        height={350}
                        series={[
                          {
                            name: "Amount",
                            data: dashboardData.purchaseGraphMonthWise.map(
                              (month) => {
                                return month.sales_purchase_total_amount;
                              }
                            ),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            type: "bar",
                            height: 365,
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickLineChart(
                                  dashboardData.purchaseGraphMonthWise, config.dataPointIndex,2
                                );
                              },
                            },
                          },

                          // plotOptions: {
                          //     bar: {
                          //         columnWidth: "40%",
                          //         distributed: true,
                          //     },
                          // },
                          // stroke: {
                          //     show: true,
                          //     width: 2,
                          //     // colors: ["transparent"],
                          // },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories: dashboardData.purchaseGraphMonthWise.map(
                              (month) => {
                                return month.month_year;
                              }
                            ),
                            // dashboardData.purchaseGraphMonthWise.map((month) => {
                            //     return month.month_year
                            // }),
                          },
                          yaxis: {
                            title: {
                              text: "$ (thousands)",
                            },
                          },
                          // fill: {
                          //     opacity: 1,
                          // },
                          colors: dashboardData.purchaseGraphMonthWise.map(
                            (month) => {
                              return primaryColor;
                            }
                          ),

                          // tooltip: {
                          //     theme: "light",
                          // },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"DateWise Purchase"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {monthWisePurchaseList.length != 0 && (
                      <MonthlyBarChart
                        // radioValue={onChangeRadioValue}
                        height={350}
                        series={[
                          {
                            name: "Amount",
                            data: monthWisePurchaseList.map((month) => {
                              return month.sales_purchase_amount;
                            }),
                            // dashboardData.purchaseGraphMonthWise.map((month) => {
                            //     return month.sales_purchase_total_amount
                            // }),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            type: "bar",
                            height: 365,
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickDateWiseSaleChart(monthWisePurchaseList, config.dataPointIndex,2);
                              },
                            },
                          },

                          // plotOptions: {
                          //     bar: {
                          //         columnWidth: "40%",
                          //         distributed: true,
                          //     },
                          // },
                          // stroke: {
                          //     show: true,
                          //     width: 2,
                          //     // colors: ["transparent"],
                          // },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories: monthWisePurchaseList.map((month) => {
                              return month.month_year;
                            }),
                            // dashboardData.purchaseGraphMonthWise.map((month) => {
                            //     return month.month_year
                            // }),
                          },
                          yaxis: {
                            title: {
                              text: "$ (thousands)",
                            },
                          },
                          // fill: {
                          //     opacity: 1,
                          // },
                          colors: dashboardData.purchaseGraphMonthWise.map(
                            (month) => {
                              return '#28C66F'
                            }
                          ),

                          // tooltip: {
                          //     theme: "light",
                          // },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                <Grid container xs={12} sm={12} md={12}>
                  <Grid item xs={4} sm={4} md={4}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Purchase List"}
                    />
                   
                  </Grid>
                  <Grid sx={{paddingBottom:1, display:"flex",justifyContent:"flex-end"}}item xs={8} sm={8} md={8}>
                  
                     <Search
                          sx={{ width: "100%" }}
                          searchValue={searchValuePurchase}
                        filteredRows={this.onSearchPurchase}
                        />
                  </Grid>
                </Grid>
               
                  <Grid item xs={12} sm={12} md={12}>
                    <RTTable
                      columns={[
                        
                        {
                          name: "document_no",
                          title: "Document No",
                          align: "center",
                        },
                        {
                          name: "document_date",
                          title: "Date",
                          align: "center",
                        },
                        {
                          name: "account_ledger_code",
                          title: "ACC Code ",
                          align: "center",
                        },
                        {
                          name: "account_ledger_name",
                          title: "ACCD ",

                          align: "left",
                        },
                        {
                          name: "total_amt",
                          title: "Amount",

                          align: "right",
                        },
                      ]}
                      showPegination={false}
                      showEditIcon={false}
                      showViewButtonInTable={true}
                      tableData={this.getFilteredTableDataSales(monthWisePurchaseTableList,searchValuePurchase)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Outstanding Accountwise Sale"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {Object.keys(dashboardData).length != 0 && (
                      <MonthlyBarChart
                        // radioValue={onChangeRadioValue}
                        height={400}
                        series={[
                          {
                            name: "Amount",
                            data: dashboardData.salesOutstandingAmountAccWise.map(
                              (month) => {
                                return month.purchase_sales_remaining_amount;
                              }
                            ),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            type: "bar",
                            height: 365,
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickAccountWiseSaleLineChart(
                                  dashboardData.salesOutstandingAmountAccWise, config.dataPointIndex,1
                                );
                              },
                            },
                          },

                          // plotOptions: {
                          //     bar: {
                          //         columnWidth: "40%",
                          //         distributed: true,
                          //     },
                          // },
                          // stroke: {
                          //     show: true,
                          //     width: 2,
                          //     // colors: ["transparent"],
                          // },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories:
                              dashboardData.salesOutstandingAmountAccWise.map(
                                (month) => {
                                  return month.account_ledger_name
                                    ;
                                }
                              ),
                          },
                          yaxis: {
                            title: {
                              text: "$ (thousands)",
                            },
                          },
                          // fill: {
                          //     opacity: 1,
                          // },
                          colors:
                            dashboardData.salesOutstandingAmountAccWise.map(
                              (month) => {
                                return "#DA6F6F";
                              }
                            ),

                          // tooltip: {
                          //     theme: "light",
                          // },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                 
                  <Grid container xs={12} sm={12} md={12}>
                  <Grid item xs={8} sm={8} md={8}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Outstanding Sale List ( "+accountName+" )"}
                    />
                   
                  </Grid>
                  <Grid sx={{paddingBottom:1, display:"flex",justifyContent:"flex-end"}}item xs={4} sm={4} md={4}>
                  
                     <Search
                          sx={{ width: "100%" }}
                          searchValue={searchValueOutSales}
                        filteredRows={this.onSearchOutSales}
                        />
                  </Grid>
                </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <RTTable
                      columns={[
                        { name: "documentNo", title: "Document No", align: "center", },
                        { name: "date", title: "Date", align: "center", },
                        { name: "agingDays", title: "Aging Days", align: "center", },
                        {
                          name: "remainingAmt",
                          title: "Remaining Amount",

                          align: "right",
                        },
                        {
                          name: "totalAmount",
                          title: "Total Amount",

                          align: "right",
                        },
                        
                      ]}
                      showPegination={false}
                      showEditIcon={false}
                      showViewButtonInTable={true}
                     
                      tableData={this.getFilteredTableDataOutSalesPurchase(accountWiseSaleList,searchValueOutSales)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Outstanding Accountwise Purchase"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {Object.keys(dashboardData).length != 0 && (
                      <MonthlyBarChart
                        // radioValue={onChangeRadioValue}
                        height={400}
                        series={[
                          {
                            name: "Amount",
                            data: dashboardData.purchaseOutstandingAmountAccWise.map(
                              (month) => {
                                return month.purchase_sales_remaining_amount;
                              }
                            ),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                            type: "bar",
                            height: 365,
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickAccountWiseSaleLineChart(
                                  dashboardData.purchaseOutstandingAmountAccWise, config.dataPointIndex,2
                                );
                              },
                            },
                          },

                          // plotOptions: {
                          //     bar: {
                          //         columnWidth: "40%",
                          //         distributed: true,
                          //     },
                          // },
                          // stroke: {
                          //     show: true,
                          //     width: 2,
                          //     // colors: ["transparent"],
                          // },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories:
                              dashboardData.purchaseOutstandingAmountAccWise.map(
                                (month) => {
                                  return month.account_ledger_name
                                }
                              ),
                          },
                          yaxis: {
                            title: {
                              text: "$ (thousands)",
                            },
                          },
                          // fill: {
                          //     opacity: 1,
                          // },
                          colors:
                            dashboardData.purchaseOutstandingAmountAccWise.map(
                              (month) => {
                                return "#28C66F";
                              }
                            ),

                          // tooltip: {
                          //     theme: "light",
                          // },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                 
                  <Grid container xs={12} sm={12} md={12}>
                  <Grid item xs={8} sm={8} md={8}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Outstanding Purchase List ( "+accountNameMain+" )"}
                    />
                   
                  </Grid>
                  <Grid sx={{paddingBottom:1, display:"flex",justifyContent:"flex-end"}}item xs={4} sm={4} md={4}>
                  
                     <Search
                          sx={{ width: "100%" }}
                          searchValue={searchValueOutPurchase}
                        filteredRows={this.onSearchOutPurchase}
                        />
                  </Grid>
                </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <RTTable
                      columns={[
                        { name: "documentNo", title: "Document No", align: "center", },
                        { name: "date", title: "Date", align: "center", },
                        { name: "agingDays", title: "Aging Days", align: "center", },

                        {
                          name: "remainingAmt",
                          title: "Remaining Amount",

                          align: "right",
                        },
                        {
                          name: "totalAmount",
                          title: "Total Amount",

                          align: "right",
                        },
                      ]}
                      showPegination={false}
                      showEditIcon={false}
                      showViewButtonInTable={true}
                     
                      tableData={this.getFilteredTableDataOutSalesPurchase(accountWisePurchaseList,searchValueOutPurchase)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
            <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Profit & Loss"}
                    />
                  </Grid>
                  <Grid 
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly">
                    <Grid item xs={12} sm={4} md={4}>
                      {/* <h4
                          className="text-uppercase card-title mb-0 flex-grow-1"
                          style={{
                            marginTop: 2,
                          }}
                        > */}
                      <AutoComplete
                        keyColName={"id"}
                        placeHolder="Select Company"
                        value={companyId}
                        name={"companyId"}
                        options={
                          CompanyDetails
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={companyError ? true : false}
                        errorText={companyError ? companyError : " "}
                        sx={{
                          marginTop: 1.2,
                          marginRight: 5,
                        }}
                      />
                      {/* </h4> */}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      {/* <h4
                          className="text-uppercase card-title mb-0 flex-grow-1"
                          style={{
                            marginTop: 2,
                          }}
                        > */}
                      <AutoComplete
                        keyColName={"id"}
                        placeHolder="Select Year"
                        value={yearId}
                        name={"yearId"}
                        options={
                          yearMasterDetail
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={yearError ? true : false}
                        errorText={yearError ? yearError : " "}
                        sx={{
                          marginTop: 1.2,
                          marginRight: 5,
                        }}
                      />
                      {/* </h4> */}

                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      lg={4}
                    // container
                    // justifyContent="space-evenly"
                    >


                      <Button style={{ width: "5%", height: "50%" }} onClick={this.onSave} variant="contained" color={"primary"}>
                        <SearchIcon />
                      </Button>



                    </Grid>

                  </Grid>
                  <Grid 
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly">
                  {profit.length!=0 &&  (
            <Grid item xs={12} sm={6} md={6}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
              
                      <Grid item xs={12} sm={12} md={12}>
                        <SubTitle
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: blackColor,
                          }}
                          label={"Profit"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <SimpleT
                          rowLink={this.rowLink}
                          isTotal={true}
                          rowList={profit}
                        />
                      </Grid>
                    
              </Paper>
            </Grid>
            )}
            {loss.length!=0 &&  (
            <Grid item xs={12} sm={6} md={6}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
              
                  
                    
                      <Grid item xs={12} sm={12} md={12}>
                        <SubTitle
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: blackColor,
                          }}
                          label={"Loss"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <SimpleT
                        rowLink={this.rowLink}
                        getList={this.getList}
                        formData={{}}
                        isTotal={true}
                          rowList={loss}
                        />
                      </Grid>
              </Paper>
            </Grid>
            )}
            </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <Paper
                sx={{
                  p: 2,
                  pt: 2,
                  borderRadius: 2,
                  background: "#fff",
                  height: "100%",
                }}
                elevation={0}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TitleCompo
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: blackColor,
                      }}
                      label={"Approval Pending List"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ApprovalPending 
                      isTotal={false}
                      rowList={[
                        {
                          index: 1,
                          name: "Bank Receipt Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null&&dashboardData.allApprovalPendingCount.bank_reciept_count!=null ? dashboardData.allApprovalPendingCount.bank_reciept_count : 0,
                        },
                        {
                          index: 2,
                          name: "Bank Payment Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null && dashboardData.allApprovalPendingCount.bank_payment_count!=null? dashboardData.allApprovalPendingCount.bank_payment_count : 0,
                        },
                        {
                          index: 3,
                          name: "Cash Payment Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null&&dashboardData.allApprovalPendingCount.cash_payment_count!=null ? dashboardData.allApprovalPendingCount.cash_payment_count : 0,
                        },
                        {
                          index: 4,
                          name: "Cash Receipt Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null &&dashboardData.allApprovalPendingCount.cash_reciept_count!=null? dashboardData.allApprovalPendingCount.cash_reciept_count : 0,
                        },
                        {
                          index: 5,
                          name: "Contra Entry Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null &&dashboardData.allApprovalPendingCount.contra_entry_count!=null? dashboardData.allApprovalPendingCount.contra_entry_count : 0,
                        },
                        {
                          index: 6,
                          name: "Debit Note Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null &&dashboardData.allApprovalPendingCount.debit_note_count!=null? dashboardData.allApprovalPendingCount.debit_note_count : 0,
                        },
                        {
                          index: 7,
                          name: "Credit Note Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null &&dashboardData.allApprovalPendingCount.debit_note_count!=null? dashboardData.allApprovalPendingCount.credit_note_count : 0,
                        },
                        {
                          index: 8,
                          name: "Journal Entry Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null &&dashboardData.allApprovalPendingCount.journal_entry_count!=null? dashboardData.allApprovalPendingCount.journal_entry_count : 0,
                        },
                        {
                          index: 9,
                          name: "Purchase Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null &&dashboardData.allApprovalPendingCount.purchase_count!=null? dashboardData.allApprovalPendingCount.purchase_count : 0,
                        },
                        {
                          index: 10,
                          name: "Sale Count",
                          value: dashboardData.allApprovalPendingCount && dashboardData.allApprovalPendingCount != null&&dashboardData.allApprovalPendingCount.sale_count!=null ? dashboardData.allApprovalPendingCount.sale_count : 0,
                        },
                      ]}/>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            
          </Grid>
        </Paper>
        {openTable && (
                <GroupSummarySubReportByGroup


                    open={openTable}
                    handleCloses={this.toggle}
                    popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                    dynamicMasterData={{companyId:companyId, yearId:yearId, fromDate:fromDate, toDate:toDate}}

                />
            )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Dashbourd);
