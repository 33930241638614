import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import { withRouter } from "../../components/withRouter";
import { blackColor } from "../../config/ColorObj";
class SubTitle extends React.Component {
 
    render() {
        const { label } = this.props
        return (
            <>  <LabelCompo style={{  
                fontWeight: 400,
                fontSize: "14px",
               
                color: blackColor }} label={label} />
                 </>
        );
    }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SubTitle);
