import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import {
    Grid,
    Paper
} from "@mui/material";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { LabelCompo } from "../../components/Comman/Label";
import { white } from "../../config/ColorObj";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { ConvertAmount } from "../../components/ConvertValueWithDenominator";
class CardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { label,amount,description,backgroundColor } = this.props
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        pt: 2,
                        borderRadius: 2,
                        background: backgroundColor,
                        height:'100%'
                    }}
                    elevation={0}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid item xs={12} sm={12} md={12}>
                                <LabelCompo style={{ fontWeight: 600, fontSize: "14px", color: white }} label={label} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <LabelCompo style={{ fontWeight: 600, fontSize: "20px", color: white }} label={ConvertAmount({
amount:amount
                                }) } />
                                
                            </Grid>
                        </Grid>
{/*                         
                        <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo style={{ fontWeight: 400, fontSize: "12px", color: white }} label={description} />
                        </Grid> */}
                    </Grid>

                </Paper>


            </>
        );
    }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CardComponent);
