import { Route, Routes } from "react-router-dom";
import NewLogin from "./Pages/Login/NewLogin";
import Login from "./Pages/Login/index";

import { connect } from "react-redux";
import Landing from "./Pages/Landing";
import { closeNotification } from "./Pages/Landing/Landing.slice";
import ForgotPasswordForm from "./Pages/Master/ForgotPassword/ForgotPasswordNewForm";
import NotificationToast from "./components/Comman/NotificationToast";
import { Loading } from "./components/Loader";
import { Loading1 } from "./components/Loading1";
import PrivateRoute from "./routes/index";

const App = ({ landingData, closeNotification }) => {
  const closeSnak = () => {
    closeNotification();
  };

  return (
    <>
      <NotificationToast
        open={landingData.isShowNotification}
        severity={
          landingData.severity === undefined ? "success" : landingData.severity
        }
        msg={landingData.msg}
        closeSnak={closeSnak}
      />
      {landingData.loador && <Loading1 />}
      {landingData.loador1 && <Loading />}
      {/* <Loading1 /> */}
      <Routes>
        <Route path="/login-Old" element={<NewLogin />} />
        <Route path="/login" element={<Login  />} />
        <Route path="/forgotPassword" element={<ForgotPasswordForm />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Landing />
            </PrivateRoute>
          }
        />
      
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  landingData: state.landing,
});

const mapDispatchToProps = { closeNotification };
export default connect(mapStateToProps, mapDispatchToProps)(App);

