import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { JournalbookJson } from "../../DynamicFormsJson/Report/JournalBookJson";
import { generatePDF ,generateExcel} from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import JournalBookTable from "./JournalBookTable";
import RTTable from "../../components/Comman/RT/RTTable";
import moment from "moment";
import CashbookDateWise from './CashbookDateWise'
import CashBalanebyDate from './CashBalanebyDate'

const JournalBook = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [cashbook, setCashbookList] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [popUpData, setPopupdata] = useState({})
  const [openTable, setOpenTable] = useState(false);
  const [popUpDataOfDate, setPopupdataOfDate] = useState({})
  const [openTableOfDate, setOpenTableOfDate] = useState(false);

  useEffect(() => {
   
   
  }, [])

  const getList = (fromDate, toDate) => {
    let location = localStorage.getItem("location");
    console.log("location");
    console.log(location)
    showLoador({ loador: true });
    apiGet({
      url:
      endpoint.salePurchaseApproval+"/get-journal-book-list?fromDate="+fromDate+"&toDate="+toDate+"&locationId="+location,
        // endpoint.cashbalance + "/monthwise-cashbalance?fromDate=" + fromDate + "&toDate=" + toDate + "&accountLedgerId=" + accountLedgerId + "&companyId=" + companyIds,
    }).then(({ data, success }) => {
      if (success) {
        let rowData = data.data;
   
        showLoador({ loador: false });
        setCashbookList(rowData)
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
  }
  const rowLink = (rowData) => {

    setPopupdata(rowData)
    setOpenTable(!openTable);
  }


  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };


  const getFilteredTableData = (tableData) => {

    let listData = []
    tableData.map((row) => {
      let tableDataList = []
      row["journalHeaderList"].map((currentRow) => {
        const subList = currentRow["journalEntryDetailList"].filter((detailData) => {
          let isValid = false;
  
          if (
            detailData["accountLedgerName"] &&
            detailData["accountLedgerName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())|| detailData["accountLedgerName"]==null
          ) {
            isValid = true;
          }
          return isValid;
        });
        if (subList.length != 0) {
          currentRow = {
            ...currentRow,
            ["journalEntryDetailList"]: subList,
          };
          tableDataList.push(currentRow);
        }
      });
      if (tableDataList.length != 0) {
        row = {
          ...row,
          ["journalHeaderList"]: tableDataList,
        };
        listData.push(row);
      }
    });
    return listData
  };

  const generatePDFMethod = () => {
    var columns = [[ "Vou No.", "Particular", "Credit ","Debit"]];
    let tableList = []
   let rowList= getFilteredTableData(cashbook)
   rowList.map(((rowData, index) => {
    
   
     rowData.journalHeaderList.map((headerList,index)=>{
      let creditTotal = 0
      let debitTotal = 0
      let VouNo=index==0?rowData.date+"\n":""
       let perticuler="\n"
       let credit="\n"
       let debit="\n"
      let jvNo=headerList.jvNo!=null?headerList.jvNo:"-"
      VouNo=VouNo+ jvNo
      headerList.journalEntryDetailList.map((detailData,index)=>{
        if (detailData.type == "D") {
          debitTotal = debitTotal + detailData.amt
      } else {
          creditTotal = creditTotal + detailData.amt
      }

        let newLine=index== (headerList.journalEntryDetailList.length-1)?"\n":"\n"
        let newLine1=index== (headerList.journalEntryDetailList.length-1)?"":"\n"
        VouNo=VouNo+ newLine1
       let perti=detailData.type == "D" ? "To " + detailData.accountLedgerName : "By " + detailData.accountLedgerName
        perticuler=perticuler+ perti+newLine
     
        let  d=detailData.type == "D" ? Math.abs(detailData.amt).toFixed(2):""
        let  c= detailData.type == "D" ? "" : Math.abs(detailData.amt).toFixed(2)  
        debit = debit +d+newLine
        credit = credit +c+newLine


       })
       let tableData=[];
       tableData.push(VouNo )
       
   
       tableData.push(perticuler)
       tableData.push(debit +debitTotal)
       tableData.push(credit+creditTotal)
       
     
       tableList.push(tableData)
     })
    
    }))
   console.log(tableList)
    const columnStyles = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      // columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: formData.fromDate + " To " + formData.toDate
        }
       
      ],
      pdfTitle:JournalbookJson.screenTitle ,
    });

  };
  const generateExcelMethod = () => {
    var columns = ["Vou No.", "Particular", "Credit ","Debit"];
    let alignList = [];

    let tableList = []
    
    cashbook.map((rowData, index) => {
      let tableData=[];
      tableData.push(rowData.date )
         
     
      tableData.push("")
      tableData.push("") 
      tableData.push("")
      tableList.push(tableData)
      rowData.journalHeaderList.map((headerList,index)=>{
        let creditTotal = 0
        let debitTotal = 0
      
        let jvNo=headerList.jvNo!=null?headerList.jvNo:"-"
      
        headerList.journalEntryDetailList.map((detailData,index)=>{
          if (detailData.type == "D") {
            debitTotal = debitTotal + detailData.amt
        } else {
            creditTotal = creditTotal + detailData.amt
        }
         let perti=detailData.type == "D" ? "To " + detailData.accountLedgerName : "By " + detailData.accountLedgerName
          let  d=detailData.type == "D" ? Math.abs(detailData.amt).toFixed(2):""
          let  c= detailData.type == "D" ? "" : Math.abs(detailData.amt).toFixed(2)  
        
         
          tableData=[];
          tableData.push(index==0?jvNo:"")
          tableData.push(perti) 
          tableData.push(c )
          tableData.push(d)
          tableList.push(tableData)
         })
         tableData=[];
          tableData.push("")
          tableData.push("") 
          tableData.push(creditTotal )
          tableData.push(debitTotal)
          tableList.push(tableData)
        
       })

    });
    generateExcel({
      columns,
      rows:tableList,
      alignList,
      headerList: ["Date Range ",formData.fromDate + " To " + formData.toDate,
        
      ],
      title: JournalbookJson.screenTitle,
    });

  };
  const onSave = (dataToSearch) => {
    setFormData({
      ...dataToSearch,
     
    })
    
    getList(dataToSearch.fromDate, dataToSearch.toDate);
  }

  const toggle = () => {
    setOpenTable(!openTable);
  };
  const toggleByDate = () => {
    setOpenTableOfDate(!openTableOfDate);
  };
  console.log(cashbook)
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>



        <DynamicFormWithoutSave
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
         
          showSaveBtn={JournalbookJson.showSaveBtn}
          screenTitle={JournalbookJson.screenTitle}
          fieldMeta={JournalbookJson.fieldMeta}
          showCancel={JournalbookJson.showCancel}
          apiBaseURL={JournalbookJson.apiBaseURL}
          showSaveNextBtn={JournalbookJson.showSaveNextBtn}
          showSaveBtnMain={true}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          padding={false}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br />
       
         <JournalBookTable tableHead={[
           {
            title: "Vou No",

            align: "left"
          },
          {
            title: "Perticuler",

            align: "left"
          },
          {
            title: "Debit",
            align: "right"
          },
          {
            title: "Credit",
            align: "right"
          }
        ]} 
        rowList={getFilteredTableData(cashbook)}
        getList={getList}
        formData={formData}
        />
      </Paper>
      
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(JournalBook);

