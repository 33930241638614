import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { SubVoucherColumns } from "../../../tableColumns/table-columns";
import { subVoucherJSON } from "../../../DynamicFormsJson/MastersJSON/subVoucher";
import { getSubVoucher } from "../../../Slice/subVoucher.slice";
class SubVoucherTable extends React.Component {
  rowAdd = (rowData) => {
    console.log(rowData);
  };
  render() {
    const { subVoucherList, getSubVoucher } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={subVoucherJSON.formPath}
          openFormPath={subVoucherJSON.openFormPath}
          screenTitle={subVoucherJSON.screenTitle}
          fieldMeta={subVoucherJSON.fieldMeta}
          showPdfDownload={subVoucherJSON.showPdfDownload}
          showExcelDownload={subVoucherJSON.showExcelDownload}
          pdfFileName={subVoucherJSON.pdfFileName}
          excelFileName={subVoucherJSON.excelFileName}
          showAddButton={subVoucherJSON.showAddButton}
          tableColumnsToFilter={subVoucherJSON.tableColumnsToFilter}
          tableColumns={SubVoucherColumns}
          tableData={subVoucherList?.subVoucher}
          getTableData={getSubVoucher}
          apiBaseURL={subVoucherJSON.apiBaseURL}
          showAddButtonInTable={true}
          iconTitle={"Column Mapping"}
          rowAdd={this.rowAdd}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherList: state.subVoucher,
});
const mapDispatchToProps = {
  getSubVoucher,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubVoucherTable);
