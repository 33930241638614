import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";
import React, { useEffect, useState } from "react";
import moment from "moment";
const BankRecoReportTable = ({ rowList = [], tableHead = [], classes }) => {
  useEffect(() => {}, []);
  let creditTotal = 0;
  let debitTotal = 0;
  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr className={classes.MuiTableHeadRoot}>
            {tableHead.map((head) => {
              return (
                <th
                  style={{ textAlign: "center" }}
                  className={classes.MuiTableHeadCell}
                >
                  {" "}
                  {head.title}
                </th>
              );
            })}{" "}
          </tr>
          {
            rowList.length!=0 && (
            <>
            <tr>
                    <td
                      style={{
                        textDecoration: "underline",
                        fontWeight: fontWeight,
                      }}
                      colSpan={5}
                      className={classes.MuiTableBodyCell}
                    >
                   Cheques Deposit But Not Cleared 
                    </td>
                  </tr>
            { 
            rowList.map((data, index) => {
              debitTotal = +debitTotal + +data.voucherAmount;

              return (
                <>
                  

                  <tr>
                    <td
                      className={classes.MuiTableBodyCell}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.date != null ? data.date : ""}
                    </td>
                    <td className={classes.MuiTableBodyCell}>
                      {data.locationName}
                    </td>
                    <td
                      className={classes.MuiTableBodyCell}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.chequeNo}
                    </td>
                    <td
                      className={classes.MuiTableBodyCell}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.passDate ? data.passDate : "/ /"}
                    </td>
                    <td
                      className={classes.MuiTableBodyCell}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {(+data.voucherAmount).toFixed(2)}
                    </td>
                  </tr>
                </>
              );
            })}
          <tr>
          <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, }}>
                                            Total
                                        </td>
            <td className={classes.MuiTableBodyCell}></td>
            <td className={classes.MuiTableBodyCell}></td>
            <td className={classes.MuiTableBodyCell}></td>
            <td
              className={classes.MuiTableBodyCell}
              style={{
                fontWeight: fontWeight,
                borderTop: border,
                textAlign: "right",
                
              }}
            >
              {(Math.abs(debitTotal)).toFixed(2)}
            </td>
          </tr> 
          {/* {rowList.dtoReceiptPaymentHeaderBankPayment &&
            rowList.dtoReceiptPaymentHeaderBankPayment.map((data, index) => {
              creditTotal = +creditTotal + +data.voucherAmount;
              return (
                <>
                  <tr>
                    <td
                      style={{
                        textDecoration: "underline",
                        fontWeight: fontWeight,
                      }}
                      className={classes.MuiTableBodyCell}
                    >
                      {index == 0 ? "Checeques Issued But Not Cleared" : ""}
                      {}
                    </td>
                    <td className={classes.MuiTableBodyCell}></td>
                    <td className={classes.MuiTableBodyCell}></td>
                  </tr>

                  <tr>
                    <td
                      className={classes.MuiTableBodyCell}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.date != null ? data.date : ""}
                    </td>
                    <td className={classes.MuiTableBodyCell}>
                      {data.locationName}
                    </td>
                    <td className={classes.MuiTableBodyCell} style={{
                        textAlign: "center",
                      }}>
                      {data.chequeNo}
                    </td>
                    <td
                      className={classes.MuiTableBodyCell}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.passDate ? data.passDate : "/ /"}
                    </td>
                    <td
                      className={classes.MuiTableBodyCell}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {(+data.voucherAmount).toFixed(2)}
                    </td>
                  </tr>
                </>
              );
            })} */}
          {/* <tr>
          <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, }}>
                                            Total
                                        </td>
            <td className={classes.MuiTableBodyCell}></td>
            <td className={classes.MuiTableBodyCell}></td>
            <td className={classes.MuiTableBodyCell}></td>
            <td
              className={classes.MuiTableBodyCell}
              style={{
                fontWeight: fontWeight,
                borderTop: border,
                textAlign: "right",
                
              }}
            >
              {(Math.abs(creditTotal)).toFixed(2)}
            </td>
          </tr> */}
            </>
            )
          }
          
        </table>
      </div>
    </>
  );
};

export default withStyles(styles)(BankRecoReportTable);
