import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  accountLedger: [],
  location: [],
  locationNamesById: [],
  accountLedgerGroupMaster: [],
  accountLedgerData: {},
  deductType: [],
  accountNature: [],
  gstType: [],
  taxType: [],
  subTaxType: [],
  subTaxTypeByTaxType:[],
};
let URL = endpoints.accountLedger;
let URL1 = endpoints.locationForAccountLedger;
let URL2 = endpoints.accountLedgerGroupMaster;
let URL3 = endpoints.tdsDedctyType;
let URL4 = endpoints.tdsAccountNature;
let URL5 = endpoints.gstType;
let URL6 = endpoints.taxType;
let URL7 = endpoints.subTaxType;


const accountLedgerSlice = createSlice({
  name: "accountLedger",
  initialState,
  reducers: {
    accountLedgerSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        accountLedger: row,
      };
    },

    locationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        location: row,
      };
    },

    locationNamesByIdsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        locationNamesById: row,
      };
    },

    deductDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        deductType: row,
      };
    },

    accountNatureSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        accountNature: row,
      };
    },

    accountLedgerGroupMasterSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        accountLedgerGroupMaster: row,
      };
    },

    accountLedgerSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        accountLedgerData: data,
      };
    },

    gstTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        gstType: row,
      };
    },

    taxTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        taxType: row,
      };
    },

    subTaxTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subTaxType: row,
      };
    },
    subTaxTypeByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subTaxTypeByTaxType: row,
      };
    },
   
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  accountLedgerSuccess,
  locationSuccess,
  accountLedgerGroupMasterSuccess,
  accountLedgerSaveSuccess,
  deductDataSuccess,
  accountNatureSuccess,
  gstTypeSuccess,
  taxTypeSuccess,
  subTaxTypeSuccess,
  locationNamesByIdsSuccess,
  subTaxTypeByIdSuccess,
  resetState,
} = accountLedgerSlice.actions;

export default accountLedgerSlice.reducer;

export const getAccountLedgerList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((accountLedgerListObject, index) => {
          let accountLedgerData = {
            index: index + 1,
            id:
              accountLedgerListObject.id === null
                ? ""
                : accountLedgerListObject.id,
            accountLedgerCode:
              accountLedgerListObject.accountLedgerCode === null
                ? ""
                : accountLedgerListObject.accountLedgerCode,
            accountLedgerName:
              accountLedgerListObject.accountLedgerName === null
                ? ""
                : accountLedgerListObject.accountLedgerName,
            locationIds:
              accountLedgerListObject.locationIds === null
                ? ""
                : accountLedgerListObject.locationIds,
            accountLedgerGroupsMasterID:
              accountLedgerListObject.accountLedgerGroupsMaster === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster.id,
            accountLedgerGroupsMasterGroupName:
              accountLedgerListObject.accountLedgerGroupsMaster === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster.groupName,
            accountLedgerGroupsMasterCategory:
              accountLedgerListObject.accountLedgerGroupsMaster === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster.category,
            accountLedgerGroupsMasterAccType:
              accountLedgerListObject.accountLedgerGroupsMaster.accType === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster.accType
                    .name,
            accountLedgerGroupsMasterDisplayOrderNo:
              accountLedgerListObject.accountLedgerGroupsMaster === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster
                    .displayOrderNo,
            accountLedgerGroupsMasterDbamount:
              accountLedgerListObject.accountLedgerGroupsMaster === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster.dbamount,
            accountLedgerGroupsMasterCramount:
              accountLedgerListObject.accountLedgerGroupsMaster === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster.cramount,
            accountLedgerGroupsMasterRemark:
              accountLedgerListObject.accountLedgerGroupsMaster === null
                ? ""
                : accountLedgerListObject.accountLedgerGroupsMaster.remark,
            printName:
              accountLedgerListObject.printName === null
                ? ""
                : accountLedgerListObject.printName,
            address:
              accountLedgerListObject.address === null
                ? ""
                : accountLedgerListObject.address,

            city:
              accountLedgerListObject.city === null
                ? ""
                : accountLedgerListObject.city,
            gstNo:
              accountLedgerListObject.gstNo === null
                ? ""
                : accountLedgerListObject.gstNo,
            contactNo:
              accountLedgerListObject.contactNo === null
                ? ""
                : accountLedgerListObject.contactNo,
            mail:
              accountLedgerListObject.mail === null
                ? ""
                : accountLedgerListObject.mail,
            panNo:
              accountLedgerListObject.panNo === null
                ? ""
                : accountLedgerListObject.panNo,
            costCenterApplicable:
              accountLedgerListObject.costCenterApplicable === null
                ? ""
                : accountLedgerListObject.costCenterApplicable,
            fssiNo:
              accountLedgerListObject.fssiNo === null
                ? ""
                : accountLedgerListObject.fssiNo,
            fssiExpiryDate:
              accountLedgerListObject.fssiExpiryDate === null
                ? ""
                : accountLedgerListObject.fssiExpiryDate,
            drugLiecNo:
              accountLedgerListObject.drugLiecNo === null
                ? ""
                : accountLedgerListObject.drugLiecNo,
            drugLiecExpiryDate:
              accountLedgerListObject.drugLiecExpiryDate === null
                ? ""
                : accountLedgerListObject.drugLiecExpiryDate,
            tdsApplicable:
              accountLedgerListObject.tdsApplicable === null
                ? ""
                : accountLedgerListObject.tdsApplicable,
            tdsAccountNature:
              accountLedgerListObject.tdsAccountNature === null
                ? ""
                : accountLedgerListObject.tdsAccountNature.name,
            tdsAutoDeduct:
              accountLedgerListObject.tdsAutoDeduct === null
                ? ""
                : accountLedgerListObject.tdsAutoDeduct,
            deductyType:
              accountLedgerListObject.deductyType === null
                ? ""
                : accountLedgerListObject.deductyType.deductionTypeName,
            tcsApplicable:
              accountLedgerListObject.tcsApplicable === null
                ? ""
                : accountLedgerListObject.tcsApplicable,
            billWiseBalance:
              accountLedgerListObject.billWiseBalance === null
                ? ""
                : accountLedgerListObject.billWiseBalance,
            creditDays:
              accountLedgerListObject.creditDays === null
                ? ""
                : accountLedgerListObject.creditDays,
            alertDays:
              accountLedgerListObject.alertDays === null
                ? ""
                : accountLedgerListObject.alertDays,
            creditLimit:
              accountLedgerListObject.creditLimit === null
                ? ""
                : accountLedgerListObject.creditLimit,
            creditBills:
              accountLedgerListObject.creditBills === null
                ? ""
                : accountLedgerListObject.creditBills,
            gstType:
              accountLedgerListObject.gstType === null
                ? ""
                : accountLedgerListObject.gstType.name,
            pinCode:
              accountLedgerListObject.pinCode === null
                ? ""
                : accountLedgerListObject.pinCode,
            distance:
              accountLedgerListObject.distance === null
                ? ""
                : accountLedgerListObject.distance,
            adharNo:
              accountLedgerListObject.adharNo === null
                ? ""
                : accountLedgerListObject.adharNo,
            companyVendorCode:
              accountLedgerListObject.companyVendorCode === null
                ? ""
                : accountLedgerListObject.companyVendorCode,
            thirdPartyLinkCode:
              accountLedgerListObject.thirdPartyLinkCode === null
                ? ""
                : accountLedgerListObject.thirdPartyLinkCode,
            taxType:
              accountLedgerListObject.taxType === null
                ? ""
                : accountLedgerListObject.taxType.name,
            subTaxType:
              accountLedgerListObject.subTaxType === null
                ? ""
                : accountLedgerListObject.subTaxType.name,
            // isActive:
            //   accountLedgerListObject.active === null
            //     ? ""
            //     : accountLedgerListObject.active == "false"
            //     ? "0"
            //     : "1",
            isActive:
              accountLedgerListObject.isActive === null
                ? ""
                : accountLedgerListObject.isActive,
            hold:
              accountLedgerListObject.hold === null
                ? ""
                : accountLedgerListObject.hold,
            taxRelatedAccount:
              accountLedgerListObject.taxRelatedAccount === null
                ? ""
                : accountLedgerListObject.taxRelatedAccount,
          };
          return accountLedgerData;
        });
        dispatch(accountLedgerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getLocation = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((locationObject, index) => {
          let locationData = {
            index: index + 1,
            id: locationObject.id === null ? "" : locationObject.id,
          
            name: locationObject.name === null ? "" : locationObject.name,
            shortName:
              locationObject.shortName === null ? "" : locationObject.shortName,
            code: locationObject.code === null ? "" : locationObject.code,
            companyId:
            locationObject.company === null
              ? ""
              : locationObject.company.id,
            company:
              locationObject.company === null
                ? ""
                : locationObject.company.companyName,
            address:
              locationObject.address === null ? "" : locationObject.address,
            city: locationObject.city === null ? "" : locationObject.city,
            state:
              locationObject.state === null
                ? ""
                : locationObject.state.stateName,
            contactNo:
              locationObject.contactNo === null ? "" : locationObject.contactNo,
            email: locationObject.email === null ? "" : locationObject.email,
          };
          return locationData;
        });
        dispatch(locationSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getLocationByIds =
  ({ locationIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/by-ids?ids=" + locationIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((locationObject, index) => {
            let locationData = {
              index: index + 1,
              id: locationObject.id === null ? "" : locationObject.id,
              name: locationObject.name === null ? "" : locationObject.name,
              shortName:
                locationObject.shortName === null
                  ? ""
                  : locationObject.shortName,
              code: locationObject.code === null ? "" : locationObject.code,
              company:
                locationObject.company === null
                  ? ""
                  : locationObject.company.companyName,
              address:
                locationObject.address === null ? "" : locationObject.address,
              city: locationObject.city === null ? "" : locationObject.city,
              state:
                locationObject.state === null
                  ? ""
                  : locationObject.state.stateName,
              contactNo:
                locationObject.contactNo === null
                  ? ""
                  : locationObject.contactNo,
              email: locationObject.email === null ? "" : locationObject.email,
            };
            return locationData;
          });
          dispatch(locationNamesByIdsSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAccountLedgerGroupMaster = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((accountLedgerListObject, index) => {
          let accountLedgerData = {
            ...accountLedgerListObject,
            index: index + 1,
            id:
              accountLedgerListObject.id === null
                ? ""
                : accountLedgerListObject.id,
            name:
              accountLedgerListObject.groupName === null
                ? ""
                : accountLedgerListObject.groupName,
            groupName:
              accountLedgerListObject.groupName === null
                ? ""
                : accountLedgerListObject.groupName,
          };
          return accountLedgerData;
        });
        dispatch(accountLedgerGroupMasterSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const saveAccountLedger =
  ({ dataToSave }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          dispatch(accountLedgerSaveSuccess({ data: data.data }));
          return { response: data.data, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setSaveAccountLedger =
  ({ rowObject }) =>
  async (dispatch) => {
    dispatch(accountLedgerSaveSuccess({ data: rowObject }));
  };

export const getDeductType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL3,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((deductTypeObject, index) => {
          let deductTypeData = {
            index: index + 1,
            id: deductTypeObject.id === null ? "" : deductTypeObject.id,
            name:
              deductTypeObject.deductionTypeName === null
                ? ""
                : deductTypeObject.deductionTypeName,
            deductionTypeName:
              deductTypeObject.deductionTypeName === null
                ? ""
                : deductTypeObject.deductionTypeName,
            insertedUserId:
              deductTypeObject.insertedUserId === null
                ? ""
                : deductTypeObject.insertedUserId,
          };
          return deductTypeData;
        });
        dispatch(deductDataSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccountNature = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL4,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((accountNatureObject, index) => {
          let accountNatureData = {
            ...accountNatureObject,
            index: index + 1,
            id: accountNatureObject.id === null ? "" : accountNatureObject.id,
            name:
              accountNatureObject.name === null ? "" : accountNatureObject.name,
          };
          return accountNatureData;
        });
        dispatch(accountNatureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getGstType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL5,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((gstTypeObject, index) => {
          let gstTypeData = {
            index: index + 1,
            id: gstTypeObject.id === null ? "" : gstTypeObject.id,
            name: gstTypeObject.name === null ? "" : gstTypeObject.name,
          };
          return gstTypeData;
        });
        dispatch(gstTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTaxType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL6,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((taxTypeObject, index) => {
          let taxTypeData = {
            index: index + 1,
            id: taxTypeObject.id === null ? "" : taxTypeObject.id,
            name: taxTypeObject.name === null ? "" : taxTypeObject.name,
            insertedUserId:
              taxTypeObject.insertedUserId === null
                ? ""
                : taxTypeObject.insertedUserId,
          };
          return taxTypeData;
        });
        dispatch(taxTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubTaxType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL7,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((subTaxTypeObject, index) => {
          let subTaxTypeData = {
            index: index + 1,
            id: subTaxTypeObject.id === null ? "" : subTaxTypeObject.id,
            name: subTaxTypeObject.name === null ? "" : subTaxTypeObject.name,
            taxType:
              subTaxTypeObject.taxType === null
                ? ""
                : subTaxTypeObject.taxType.name,
            insertedUserId:
              subTaxTypeObject.insertedUserId === null
                ? ""
                : subTaxTypeObject.insertedUserId,
          };
          return subTaxTypeData;
        });
        dispatch(subTaxTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubTaxTypeByTaxType = ({taxTypeId}) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL7+"/tax-type?taxTypeId="+taxTypeId,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((subTaxTypeObject, index) => {
          let subTaxTypeData = {
            index: index + 1,
            id: subTaxTypeObject.id === null ? "" : subTaxTypeObject.id,
            name: subTaxTypeObject.name === null ? "" : subTaxTypeObject.name,
            taxType:
              subTaxTypeObject.taxType === null
                ? ""
                : subTaxTypeObject.taxType.name,
            insertedUserId:
              subTaxTypeObject.insertedUserId === null
                ? ""
                : subTaxTypeObject.insertedUserId,
          };
          return subTaxTypeData;
        });
        dispatch(subTaxTypeByIdSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};