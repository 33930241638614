import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { isActionColActive, isConditionalActions, order, tableHead, orderBy } =
        props;

    return (
        <TableHead>
            <TableRow>
                {tableHead.map((headCell) => {
                    if (headCell.showInscreen && headCell.title !== "Action") {
                        return (
                            <TableCell
                                key={headCell.name}
                                align={"center"}
                                style={{ minWidth: headCell.width }}
                            // padding={headCell.disablePadding ? 'none' : 'normal'}
                            // sortDirection={orderBy === headCell.name ? order : false}
                            >
                                {headCell.title}
                                {orderBy === headCell.name ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableCell>
                        );
                    } else if (
                        headCell.showInscreen &&
                        (isActionColActive || isConditionalActions) &&
                        headCell.title === "Action"
                    ) {
                        return (
                            <TableCell
                                key={headCell.name}
                                align={"center"}
                                style={{ minWidth: headCell.width }}
                            // padding={headCell.disablePadding ? 'none' : 'normal'}
                            // sortDirection={orderBy === headCell.name ? order : false}
                            >
                                {headCell.title}
                                {orderBy === headCell.name ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableCell>
                        );
                    }
                    return null;
                })}
            </TableRow>
        </TableHead>
    );
}
function Row({
    row,
    rowList = [],
    setList,
}) {

    const onTextFieldDataChange = (dataKey, rowObj, rowDetailObj) => (event) => {
        const { value } = event.target;
        const ListToSet = rowList.map((rowD) => {
            let dtoYearEndDetail = rowD.dtoYearEndDetail
            if (rowObj.accountLedgerGroupId == rowD.accountLedgerGroupId) {
                dtoYearEndDetail = row.dtoYearEndDetail.map((rowInfo, index) => {
                    index = index + 1;
                    return { ...rowInfo, [dataKey]: rowInfo.id === rowDetailObj.id ? value : rowInfo[dataKey] }
                })
            }
            return {
                ...rowD,
                dtoYearEndDetail: dtoYearEndDetail
            };
        });

        setList({ row: ListToSet, name: dataKey, rowObj: rowObj, rowDetailObj: rowDetailObj });


    };
    let credit = 0
    let debit = 0
    if (row.dtoYearEndDetail.length != 0) {
        return (
            <>
                <TableRow key={row.name}>
                    <TableCell colSpan={4}>
                        {row.groupName}
                    </TableCell>
                </TableRow>
                {
                    row.dtoYearEndDetail.map((accDetails, index) => {
                        credit = credit + +accDetails.openingAmtCredit
                        debit = debit + +accDetails.openingAmtDebit
                        return (
                            <><TableRow key={row.name}>
                                <TableCell >
                                    {index + 1}
                                </TableCell>
                                <TableCell >
                                    {accDetails.accountName}
                                </TableCell>
                                <TableCell >
                                    <TextFieldCompo
                                        placeHolder={""}
                                        size="small"
                                        color="primary"
                                        type="number"
                                        id={"1"}
                                        value={accDetails.openingAmtCredit}
                                        onChange={onTextFieldDataChange("openingAmtCredit", row, accDetails)}
                                        fullWidth={false}
                                        errorText={
                                            ""
                                        }
                                    />
                                </TableCell>
                                <TableCell >
                                    <TextFieldCompo
                                        placeHolder={""}
                                        size="small"
                                        color="primary"
                                        type="number"
                                        id={"1"}
                                        value={accDetails.openingAmtDebit}
                                        onChange={onTextFieldDataChange("openingAmtDebit", row, accDetails)}
                                        fullWidth={false}
                                        errorText={
                                            ""
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                            </>

                        )
                    })
                }
                <TableRow key={row.name}>
                    <TableCell colSpan={2}>
                    </TableCell>
                    <TableCell >
                        {credit}
                    </TableCell>
                    <TableCell >
                        {debit}
                    </TableCell>
                </TableRow>
            </>

        );
    }

}
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function MaterialUITableWithTextField({
    tableHead = [],
    setList,
    rowStatus,
    statusChange,
    rowDelete,
    rowEdit,
    data = [],
    showHeadDelete,
    studListEmpty = false,
    msg,
    showHeadEdit,
    rowId,
    showHeadAdd,
    rowView,
    rowViewData,
    showApply,
    showView,
    showRoundDetails,
    showRoundDetailsNew,
    showPegination = false,
    rounds,
    isActionColActive = true,
    isConditionalActions = false,
    rowFeesPaid,
    rowAppliedList,
    rowPaymentDetails,
    showPayFees,
    showPaymentDetails,
    showAppliedList,
    showAppliedListNew,
    showDetails,
    showLinkIcon,
    showSchoolIcon,
    showPersonAdd,
    rowDetails,
    rowSchoolDetails,
    generateDetails,
    rowPersonAddDetails,
    showHeadPdf,
    marginTop,
    showHeadExcel,
    pdfDetailsId,
    excelDetailsId,
    condition,
    rowSelectCompo,
    showSelectCompo,
    showComment,
    rowComment,
    alertMail,
    deleteConditionName = "",
    deleteConditionValue = "",
    editConditionName = "",
    editConditionValue = "",
    onChangeStatusStatic,
    credentialConditionName = "",
    credentialConditionValue = "",
    showSendRequestIcon,
    rowSendRequest,
    sendRequestIconName = "",
    sendRequestIconValue = "",
    showSend,
    getName,
}) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                    <EnhancedTableHead
                        tableHead={tableHead}
                        numSelected={selected.length}
                        isConditionalActions={isConditionalActions}
                        isActionColActive={isActionColActive}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                        {showPegination
                            ? stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <Row
                                            getName={getName}
                                            key={row.id}
                                            row={row}
                                            setList={setList}
                                            rowList={data}
                                            tableHead={tableHead}
                                            showHeadDelete={showHeadDelete}
                                            showHeadEdit={showHeadEdit}
                                            rowEdit={rowEdit}
                                            rowStatus={rowStatus}
                                            statusChange={statusChange}
                                            rowDelete={rowDelete}
                                            showHeadAdd={showHeadAdd}
                                            showHeadPdf={showHeadPdf}
                                            marginTop={marginTop}
                                            showHeadExcel={showHeadExcel}
                                            condition={condition}
                                            rowId={rowId}
                                            rowView={rowView}
                                            rowViewData={rowViewData}
                                            showApply={showApply}
                                            alertMail={alertMail}
                                            showView={showView}
                                            showComment={showComment}
                                            rowComment={rowComment}
                                            showRoundDetails={showRoundDetails}
                                            showRoundDetailsNew={showRoundDetailsNew}
                                            showPegination={showPegination}
                                            rounds={rounds}
                                            isConditionalActions={isConditionalActions}
                                            isActionColActive={isActionColActive}
                                            rowFeesPaid={rowFeesPaid}
                                            rowAppliedList={rowAppliedList}
                                            rowDetails={rowDetails}
                                            rowPaymentDetails={rowPaymentDetails}
                                            showPayFees={showPayFees}
                                            showPaymentDetails={showPaymentDetails}
                                            showAppliedList={showAppliedList}
                                            showAppliedListNew={showAppliedListNew}
                                            showDetails={showDetails}
                                            showSchoolIcon={showSchoolIcon}
                                            showLinkIcon={showLinkIcon}
                                            rowSchoolDetails={rowSchoolDetails}
                                            generateDetails={generateDetails}
                                            pdfDetailsId={pdfDetailsId}
                                            excelDetailsId={excelDetailsId}
                                            showPersonAdd={showPersonAdd}
                                            rowPersonAddDetails={rowPersonAddDetails}
                                            rowSelectCompo={rowSelectCompo}
                                            showSelectCompo={showSelectCompo}
                                            deleteConditionName={deleteConditionName}
                                            deleteConditionValue={deleteConditionValue}
                                            editConditionName={editConditionName}
                                            editConditionValue={editConditionValue}
                                            credentialConditionName={credentialConditionName}
                                            credentialConditionValue={credentialConditionValue}
                                            onChangeStatusStatic={onChangeStatusStatic}
                                            showSendRequestIcon={showSendRequestIcon}
                                            rowSendRequest={rowSendRequest}
                                            sendRequestIconName={sendRequestIconName}
                                            sendRequestIconValue={sendRequestIconValue}
                                            showSend={showSend}
                                        //   tableSubHead={tableSubHead}
                                        //   tableSubList={tableSubList}
                                        />
                                    );
                                })
                            : stableSort(data, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    return (
                                        <Row
                                            getName={getName}
                                            key={row.id}
                                            rowList={data}
                                            row={row}
                                            setList={setList}
                                            tableHead={tableHead}
                                            showHeadDelete={showHeadDelete}
                                            showHeadEdit={showHeadEdit}
                                            rowEdit={rowEdit}
                                            rowStatus={rowStatus}
                                            statusChange={statusChange}
                                            rowDelete={rowDelete}
                                            showHeadAdd={showHeadAdd}
                                            showHeadPdf={showHeadPdf}
                                            marginTop={marginTop}
                                            showHeadExcel={showHeadExcel}
                                            condition={condition}
                                            rowId={rowId}
                                            rowView={rowView}
                                            rowViewData={rowViewData}
                                            showApply={showApply}
                                            alertMail={alertMail}
                                            showView={showView}
                                            showComment={showComment}
                                            rowComment={rowComment}
                                            showRoundDetails={showRoundDetails}
                                            showRoundDetailsNew={showRoundDetailsNew}
                                            showPegination={showPegination}
                                            rounds={rounds}
                                            isConditionalActions={isConditionalActions}
                                            isActionColActive={isActionColActive}
                                            rowFeesPaid={rowFeesPaid}
                                            rowAppliedList={rowAppliedList}
                                            rowDetails={rowDetails}
                                            rowPaymentDetails={rowPaymentDetails}
                                            showPayFees={showPayFees}
                                            showPaymentDetails={showPaymentDetails}
                                            showAppliedList={showAppliedList}
                                            showDetails={showDetails}
                                            showSchoolIcon={showSchoolIcon}
                                            showLinkIcon={showLinkIcon}
                                            rowSchoolDetails={rowSchoolDetails}
                                            generateDetails={generateDetails}
                                            pdfDetailsId={pdfDetailsId}
                                            excelDetailsId={excelDetailsId}
                                            showPersonAdd={showPersonAdd}
                                            rowPersonAddDetails={rowPersonAddDetails}
                                            rowSelectCompo={rowSelectCompo}
                                            showSelectCompo={showSelectCompo}
                                            deleteConditionName={deleteConditionName}
                                            deleteConditionValue={deleteConditionValue}
                                            editConditionName={editConditionName}
                                            editConditionValue={editConditionValue}
                                            credentialConditionName={credentialConditionName}
                                            credentialConditionValue={credentialConditionValue}
                                            onChangeStatusStatic={onChangeStatusStatic}
                                            showSendRequestIcon={showSendRequestIcon}
                                            rowSendRequest={rowSendRequest}
                                            sendRequestIconName={sendRequestIconName}
                                            sendRequestIconValue={sendRequestIconValue}
                                            showSend={showSend}
                                        //   tableSubHead={tableSubHead}
                                        //   tableSubList={tableSubList}
                                        />
                                    );
                                }
                            )}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={tableHead.length} />
                            </TableRow>
                        )}
                        {data.length === 0 && (
                            <TableRow
                                style={{
                                    height: 53 * 1,
                                }}
                            >
                                <TableCell align="center" colSpan={tableHead.length}>
                                    No Record Found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {showPegination && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Box>
    );
}
