import React from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";

const PurchaseSaleSummaryTable = ({
  rowList = [],
  tableHead = [],
  classes,
}) => {
  let TotalTaxableamt = 0;
  let TotalCgst = 0;
  let totalIgst = 0;
  let totalSgst = 0;
  let cessTotal = 0;
  let totalPurchase = 0;

  let saleTotalTaxableamt = 0;
  let saleTotalCgst = 0;
  let saleTotalIgst = 0;
  let saleTotalSgst = 0;
  let saleCessTotal = 0;
  let saleTotalPurchase = 0;
  return (
    <>
      <div style={{ overflow: "auto" }}>
        {
          Object.keys(rowList).length == 0 &&
          <br />
        }

        <table className={classes.MuiTable}>
          {Object.keys(rowList).length != 0 ?

            <>
              <tr className={classes.MuiTableHeadRoot}>
                {tableHead.map((head) => {
                  return (
                    <td
                      style={{ textAlign: "center", width: head.width }}
                      className={classes.MuiTableHeadCell}
                      colSpan={head.colSpan}
                    >
                      {head.title}
                    </td>
                  );
                })}
              </tr>

              {

                rowList.purchaseList && rowList.purchaseList.map((data, index) => {

                  TotalTaxableamt = TotalTaxableamt + data.taxableAmt
                  TotalCgst = TotalCgst + data.cgstAmt
                  totalIgst = totalIgst + data.igstAmt
                  totalSgst = totalSgst + data.sgstAmt
                  cessTotal = cessTotal + data.cessAmt
                  totalPurchase = totalPurchase + data.total
                  return (
                    <>

                      <tr>

                        <td className={classes.MuiTableBodyCell} >
                          {
                            index == 0 ? "INPUT" : ""
                          }
                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder}>
                          {
                            index == 0 ? "Add" : ""
                          }

                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder}>
                          {
                            index == 0 ? "Purchase" : ""
                          }
                        </td>


                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          GST {data.gstPer.toFixed(2)} %
                        </td>



                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.taxableAmt).toFixed(2)}
                        </td>

                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.sgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.igstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cessAmt).toFixed(2)}
                        </td>
                        <td
                          className={classes.MuiTableBodyCell}
                          style={{ textAlign: "right" }}
                        >
                          {Math.abs(data.total).toFixed(2)}
                        </td>
                      </tr>


                    </>
                  );
                })}
              {

                rowList.purchaseReturnList && rowList.purchaseReturnList.map((data, index) => {

                  TotalTaxableamt = TotalTaxableamt + data.taxableAmt
                  TotalCgst = TotalCgst + data.cgstAmt
                  totalIgst = totalIgst + data.igstAmt
                  totalSgst = totalSgst + data.sgstAmt
                  cessTotal = cessTotal + data.cessAmt
                  totalPurchase = totalPurchase + data.total
                  return (
                    <>

                      <tr>

                        <td className={classes.MuiTableBodyCell} >

                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder} >

                          {
                            index == 0 ? "Less" : ""
                          }
                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder}>

                          {
                            index == 0 ? "Purchase Return" : ""
                          }
                        </td>


                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          GST {data.gstPer.toFixed(2)} %
                        </td>

                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.taxableAmt).toFixed(2)}
                        </td>

                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.sgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.igstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cessAmt).toFixed(2)}
                        </td>
                        <td
                          className={classes.MuiTableBodyCell}
                          style={{ textAlign: "right" }}
                        >
                          {Math.abs(data.total).toFixed(2)}
                        </td>
                      </tr>


                    </>
                  );
                })}
              <tr style={{ border: border }}>

                <td ></td>

                <td ></td>
                <td ></td>
                <td className={classes.MuiTableBodyCell}></td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" }}
                >
                  {Math.abs(TotalTaxableamt).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" }}
                >
                  {Math.abs(TotalCgst).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" }}
                >
                  {Math.abs(totalSgst).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" }}
                >
                  {Math.abs(totalIgst).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" }}
                >
                  {Math.abs(cessTotal).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" }}
                >
                  {Math.abs(totalPurchase).toFixed(2)}
                </td>

              </tr>

              {

                rowList.saleList && rowList.saleList.map((data, index) => {

                  saleTotalTaxableamt = saleTotalTaxableamt + data.taxableAmt
                  saleTotalCgst = saleTotalCgst + data.cgstAmt
                  saleTotalIgst = saleTotalIgst + data.igstAmt
                  saleTotalSgst = saleTotalSgst + data.sgstAmt
                  saleCessTotal = saleCessTotal + data.cessAmt
                  saleTotalPurchase = saleTotalPurchase + data.total
                  return (
                    <>

                      <tr>

                        <td className={classes.MuiTableBodyCell} >
                          {
                            index == 0 ? "OUTPUT" : ""
                          }
                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder}>

                          {
                            index == 0 ? "Add" : ""
                          }
                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder}>  {
                          index == 0 ? "Sales" : ""
                        }
                        </td>


                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          GST {data.gstPer.toFixed(2)} %
                        </td>



                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.taxableAmt).toFixed(2)}
                        </td>

                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.sgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.igstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cessAmt).toFixed(2)}
                        </td>
                        <td
                          className={classes.MuiTableBodyCell}
                          style={{ textAlign: "right" }}
                        >
                          {Math.abs(data.total).toFixed(2)}
                        </td>
                      </tr>


                    </>
                  );
                })}
              {

                rowList.saleReturnList && rowList.saleReturnList.map((data, index) => {

                  saleTotalTaxableamt = saleTotalTaxableamt + data.taxableAmt
                  saleTotalCgst = saleTotalCgst + data.cgstAmt
                  saleTotalIgst = saleTotalIgst + data.igstAmt
                  saleTotalSgst = saleTotalSgst + data.sgstAmt
                  saleCessTotal = saleCessTotal + data.cessAmt
                  saleTotalPurchase = saleTotalPurchase + data.total
                  return (
                    <>

                      <tr>

                        <td className={classes.MuiTableBodyCell} >

                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder}>

                          {
                            index == 0 ? "Less" : ""
                          }
                        </td>
                        <td className={classes.MuiTableBodyCellWithoutBorder}> {
                          index == 0 ? "Sales Return" : ""
                        }
                        </td>


                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          GST {data.gstPer.toFixed(2)} %
                        </td>

                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.taxableAmt).toFixed(2)}
                        </td>

                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                          {Math.abs(data.sgstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.igstAmt).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>

                          {Math.abs(data.cessAmt).toFixed(2)}
                        </td>
                        <td
                          className={classes.MuiTableBodyCell}
                          style={{ textAlign: "right" }}
                        >
                          {Math.abs(data.total).toFixed(2)}
                        </td>
                      </tr>


                    </>
                  );
                })}
              <tr style={{ border: border }}>

              <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, }}>
                                            Total
                                        </td>

                <td className={classes.MuiTableBodyCellWithoutBorder}></td>
                <td className={classes.MuiTableBodyCellWithoutBorder}></td>
                <td className={classes.MuiTableBodyCell}></td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" , }}
                >
                  {Math.abs(saleTotalTaxableamt).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right", }}
                >
                  {Math.abs(saleTotalCgst).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" ,}}
                >
                  {Math.abs(saleTotalSgst).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right",fontSize:16, }}
                >
                  {Math.abs(saleTotalIgst).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right", }}
                >
                  {Math.abs(saleCessTotal).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" ,}}
                >
                  {Math.abs(saleTotalPurchase).toFixed(2)}
                </td>

              </tr>

            </>
            : <>

              <tr className={classes.MuiTableHeadRoot}>

                <td
                  style={{ textAlign: "center" }}
                  className={classes.MuiTableHeadCell}
                >
                  No Data Found
                </td>

              </tr></>

          }
        </table>
      </div>
    </>
  );
};

export default withStyles(styles)(PurchaseSaleSummaryTable);
