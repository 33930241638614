import { Divider, Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {getPayMode} from "../../Slice/payMode.slice";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  deleteWarningMsg,
} from "../../config/messageconstant";
import dayjs from "dayjs";
import { BankPaymentJSON } from "../../DynamicFormsJson/Transaction/bankPayment";
import {
  getAccountLedgerListByType1,
} from "../../Slice/subVoucherDetail.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicTransactionForm from "../../components/dynamicscreens/DynamicTransactionForm";
import {
  getCashRecieptWithAccNameById,
  getCostCenter,
} from "../../Slice/cashReceipt.slice";
import CostCenterMappingDialouge from "../CashReceipt/CostCenterMappingDialouge";
import { getSalesPurchase } from "../../Slice/salesPurchase.slice";
import BillMappingDialouge from "../CashReceipt/BillMappingDialouge";
import { getLedgerDataByAccId } from "../../Slice/ledger.slice";
import { getProfitCenter } from "../../Slice/profitCenter.slice";
class BankPaymentFormEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: { type: "1" },
      setFieldDataFromApi:{},
      voucherAmountShow: 0,

      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        paymentModeDetails:this.props.payList?.payMode,
        creditAccountDetails:
          this.props.subVoucherDetailList?.accountLedgerByType1,
        cashBankDetails: this.props.subVoucherDetailList?.accountLedgerByType,
        costCenterDetail: this.props.cashRecieptList?.costCenter,
        billDetail: this.props.salesPurchase?.salesPurchaseList,
        typeDetails: [
          { id: "C", name: "Credit" },
          { id: "D", name: "Debit" },
        ],
        subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
        voucherAmount: 0,
      },
      headerData: {},
      DataToSet: [],
      recieptPaymentDetailCcInfoList: [],
      recieptPaymentDetailBillInfoList: [],
      openCostCenter: false,
      openBillMapping: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.editData.id != nextProps.editData.id) {
      this.getByIdData(nextProps.editData.id)
    }
    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }

    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          creditAccountDetails:
          nextProps.subVoucherDetailList.accountLedgerByType1.filter(
            (row) => row.accType == 0
          ),
            cashBankDetails: nextProps.subVoucherDetailList.accountLedgerByType1.filter((row)=>row.accType==2)
        },
      });
    }
    if (
      this.props.salesPurchase.salesPurchaseList !==
      nextProps.salesPurchase.salesPurchaseList
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          billDetail: nextProps.salesPurchase.salesPurchaseList,
        },
      });
    }
    if (
      this.props.cashRecieptList?.costCenter !==
      nextProps.cashRecieptList?.costCenter
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          costCenterDetail: nextProps.cashRecieptList?.costCenter,
        },
      });
    }
    if (
      this.props.subVoucherList?.subVoucherByType !==
      nextProps.subVoucherList?.subVoucherByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subVoucherDetail: nextProps.subVoucherList?.subVoucherByType,
        },
      });
    }

    if (
      this.props.payList?.payMode !==
      nextProps.payList?.payMode
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          paymentModeDetails: nextProps.payList?.payMode,
        },
      });
    }
  }

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const {
      showLoador,
      showNotification,
      getAccountLedgerListByType1,
      getProfitCenter,
      getCostCenter,
      getPayMode,
    } = this.props;
    const { dynamicMasterData } = this.state;
    if (navigator.onLine) {
    
      showLoador({ loador: true });
      const ledgerList = await getAccountLedgerListByType1({
        accType: "0,1,2",
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return { response, success };
        } else {
          return { response, success };
        }
      });
      showLoador({ loador: true });
      await getProfitCenter().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return { response, success };
        } else {
          return { response, success };
        }
      });
     
      showLoador({ loador: true });
      await getCostCenter().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
      showLoador({ loador: true });
      await getPayMode().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });

      if (ledgerList.success && this.props.editData.id) {
        this.getByIdData(this.props.editData.id)
      }
       
      
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  getByIdData = async (id) => {
    const {
      showLoador,
      showNotification,
      getCashRecieptWithAccNameById,
    } = this.props;
    showLoador({ loador: true });
    await  getCashRecieptWithAccNameById({
      id: id,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        
      } else {
       
      
      
        let amt = 0;
        let rowList = response.dtoRecieptPaymentDetailList.map(
          (rowData, index) => {
          
            amt =
              rowData.type == "C" ? amt + +rowData.amt : amt - +rowData.amt;
            return {
              index: index + 1,
              mainAccountCode: rowData.mainAccId,
                mainAccountName: rowData.accountLedgerName,
              amt: rowData.amt!=null?rowData.amt:"",
              type: rowData.type!=null?rowData.type:"",
              sortNo: rowData.sortNo!=null?rowData.sortNo:"",
              mainAccountId: rowData.mainAccId,
              remark: rowData.remark!=null?rowData.remark:"",
              creditAmount: rowData.type == "C" ? (+rowData.amt).toFixed(2) : "",
              debitAmount: rowData.type == "C" ? "" : (+rowData.amt).toFixed(2),
              ...rowData,
            };
          }
        );
        this.setState({
          rowList: rowList,
          fieldData: {
            ...response,
            date: response.date!=null && response.date!=""?dayjs(response.date, "DD-MM-YYYY"):"",
            subVoucherMaster: { id: response.subVoucherId },
            cashBankId: response.cashBankId?response.cashBankId:"",
            paidTo: response.paidTo!=null? response.paidTo:"",
            clearanceDays: response.clearanceDays!=null?response.clearanceDays:"",
            passDate: response.passDate!=null && response.passDate!==""?dayjs(response.passDate, "DD-MM-YYYY"):"",
            drawnOn: response.drawnOn!=null && response.drawnOn!=""?dayjs(response.drawnOn, "DD-MM-YYYY"):"",
            chequeNo: response.chequeNo!=null?response.chequeNo:"",
            chequeDate:  response.chequeDate!=null && response.chequeDate!==""?dayjs(response.chequeDate, "DD-MM-YYYY"):"",
            voucherAmount: amt > 0 ? amt : amt * -1,
            remark: response.remark!=null?response.remark:'',
            paymentMode:response.paymentMode!=null?response.paymentMode:"",
            // isAdvance: response.isAdvance.toString(),
            profitCenter:response.profitCenter!=null?response.profitCenter:"",
          },
          voucherAmountShow: amt,
        });
      }
      this.getList(response.cashBankId, 0);
    });
  }

  onSearch = (data) => {
    const {
      rowList,
      dynamicMasterData,
      headerData,
      fieldData,
      recieptPaymentDetailCcInfoList,
      recieptPaymentDetailBillInfoList,
    } = this.state;

    let productList = dynamicMasterData["typeDetails"]
      ? dynamicMasterData["typeDetails"].filter((row) => row.id == data.type)
      : [];
    let catList = dynamicMasterData["creditAccountDetails"]
      ? dynamicMasterData["creditAccountDetails"].filter(
          (row) => row.id == data.mainAccountId
        )
      : [];
    let amt = 0;
    rowList.map((row) => {
      amt = row.type == "C" ? amt + +row.amt : amt - +row.amt;
    });
    amt = data.type == "C" ? amt + +data.amt : amt - +data.amt;
    console.log(recieptPaymentDetailCcInfoList);
    if(+data.amt >0){
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          mainAccountCode:
            catList.length != 0 ? catList[0].accountLedgerCode : "-",
          mainAccountName:
            catList.length != 0 ? catList[0].accountLedgerName : "-",
          recieptPaymentDetailCcInfoList: recieptPaymentDetailCcInfoList,
          recieptPaymentDetailBillInfoList: recieptPaymentDetailBillInfoList,
          ...data,
          amt:(+data.amt).toFixed(2),
          type: data.type ? data.type : "",
          creditAmount: data.type == "C" ? (+data.amt).toFixed(2) : "",
            debitAmount: data.type == "C" ? "" : (+data.amt).toFixed(2),
        },
      ],
      fieldData: {
        ...fieldData,
        voucherAmount: amt > 0 ? (+amt).toFixed(2) :  (+amt * -1).toFixed(2),
      },
      voucherAmountShow: amt,
    });
  }
  };

  onSubmitData = (costMappingList) => {
    const { showNotification } = this.props;
    console.log(costMappingList);
    const location = localStorage.getItem("location");
    console.log(this.state.rowList);
    if (this.checkValidationOnSubmit()) {
      const {
        rowList,
        fieldData,
        recieptPaymentDetailCcInfoList,
        recieptPaymentDetailBillInfoList,
        dynamicMasterData,
      } = this.state;
      let totalAmt = 0;
      if (rowList.length != 0) {
        let creditAmount = 0;
        let debitAmount = 0;
        const recieptPaymentDetailList = rowList.map((rowData) => {
          if (rowData.type == "C") {
            creditAmount = creditAmount + +rowData.amt;
          }
          if (rowData.type == "D") {
            debitAmount = debitAmount + -Math.abs(+rowData.amt);
          }
          totalAmt = creditAmount + debitAmount;
          console.log(totalAmt);
          const rowObj = {
            sortNo: 1,
            mainAccId: rowData.mainAccountCode!=null?rowData.mainAccountCode:"",
            amt: rowData.amt!=null? rowData.amt:"",
            type: rowData.type!=null?rowData.type:"",
            remark: rowData.remark!=null?rowData.remark:"",
            recieptPaymentDetailCcInfoList:rowData.recieptPaymentDetailCcInfoList.length!=null?
              rowData.recieptPaymentDetailCcInfoList:[],
            recieptPaymentDetailBillInfoList:
              rowData.recieptPaymentDetailBillInfoList!=null?rowData.recieptPaymentDetailBillInfoList:[],
          };

          return rowObj;
        });
        const dataToSave = {
          ...fieldData,
          id: this.props.editData.id ? this.props.editData.id : "",
          subVoucherMaster: fieldData.subVoucherMaster?fieldData.subVoucherMaster:"",
          mainAccountId: rowList[0].mainAccountId?rowList[0].mainAccountId:"",
          location: {
            id: fieldData.locationId
          },
          remark: fieldData.remark!=null?fieldData.remark:"",
          voucherAmount: totalAmt > 0 ? totalAmt : totalAmt * -1,
          cashBankId: fieldData.cashBankId?fieldData.cashBankId:"",
          paidTo: fieldData.paidTo!=null? fieldData.paidTo:"",
          clearanceDays: fieldData.clearanceDays!=null?fieldData.clearanceDays:"",
          passDate: fieldData.passDate
            ? fieldData.passDate.format("DD-MM-YYYY")
            :" ",
          drawnOn: fieldData.drawnOn
            ? fieldData.drawnOn.format("DD-MM-YYYY")
            : "",
          chequeNo: fieldData.chequeNo,
          chequeDate: fieldData.chequeDate
            ? fieldData.chequeDate.format("DD-MM-YYYY")
            : "",
          recieptPaymentDetailList: recieptPaymentDetailList!=null?recieptPaymentDetailList:[],
          date:fieldData.date!=null && fieldData.date!=""? fieldData.date.format("DD-MM-YYYY"):"",
          status: this.props.approvalFlag == 0 ? 0 : 1,
          bookType: 2,
          contraEntryType: 0,
          paymentMode:fieldData.paymentMode!=null?fieldData.paymentMode:'',
        };
       
        console.log(dataToSave);
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            if (fieldData.vouNature == 1 && totalAmt > 0) {
              showNotification({
                msg: "Voucher Amount must be negative",
                severity: "error",
              });
            } else if (fieldData.vouNature == 1 && totalAmt < 0) {
              this.onSave(dataToSave);
            } else if (fieldData.vouNature == 2 && totalAmt < 0) {
              showNotification({
                msg: "Voucher Amount must be positive",
                severity: "error",
              });
            } else if (fieldData.vouNature == 2 && totalAmt > 0) {
              this.onSave(dataToSave);
            }
          }
        });
      } else {
        showNotification({
          msg: "Please add atleast one product",
          severity: "error",
        });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.recieptPaymentHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (this.props.approvalFlag == 0) {
            this.props.callApi();
            this.setState({rowList:[],
              fieldData:{}
             })
          } else {
            this.props.callApi();
            this.setState({rowList:[],
              fieldData:{}
             })
            this.props.navigate("/bank-payment-approval");
          }
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: data.response.data.errorMessage, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    BankPaymentJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck1 = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    BankPaymentJSON.fieldMeta1.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    BankPaymentJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck() && this.mandatoryCheck1()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    BankPaymentJSON.fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue && dataKeyValue!="" && compareWithValue!="") {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }

                break;
              case "le":
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }

                break;
              case "g":
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }

                break;
              case "ge":
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }

                break;
              case "eq":
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }

                break;
              default:
                break;
            }

            if (isError) {
              const compareWithFieldLabel = BankPaymentJSON.fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    BankPaymentJSON.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData } = this.state;
    console.log(value);
    let debitAmount = 0;
    let creditAmount = 0;
    if (value.type == "C") {
      creditAmount = +value.amt;
    }
    if (value.type == "D") {
      debitAmount = -Math.abs(+value.amt);
    }
    let totalAmount = (creditAmount + debitAmount).toFixed(2);
    console.log(totalAmount);
    return {
      voucherAmount: (creditAmount + debitAmount).toFixed(2),
    };
    // let productList = dynamicMasterData["productDetail"]
    //   ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
    //   : [];
    // return {
    //   rate: productList[0].rate,
    //   mrp: productList[0].mrp,
    //   uom: productList[0].outletUomName,
    //   uomConversionValue: productList[0].uomConversionValue,
    // };
  };
  getListById = async (data) => {
    const { showNotification } = this.props;
    if (navigator.onLine) {
     
        if (data.mainAccountId!=null && data.mainAccountId!="") {
          this.getList(data.mainAccountId, 1);
        }
        if (data.cashBankId != null && data.cashBankId!="") {
          //    this.setState({
          //   dynamicMasterData: {
          //     ...this.state.dynamicMasterData,
          //     creditAccountDetails:this.state.dynamicMasterData.creditAccountDetails.filter((row)=>row.id!=data.cashBankId),
          //   },
          // });
          this.getList(data.cashBankId, 0);
        }
     
     
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  getList = async (accId, type) => {
    const {
      showLoador,
      showNotification,
      getLedgerDataByAccId,
      getCostCenter,
    } = this.props;
    const locationIdList = localStorage.getItem("locationIdList");
    let valuesArray = [];
    if (locationIdList != null) {
      valuesArray = JSON.parse(locationIdList);
      valuesArray = valuesArray.filter(
        (row) => row.id === localStorage.getItem("location")
      );
    }
    if(accId!=null ){
      showLoador({ loador: true });
      await getLedgerDataByAccId({
        fromDate: dayjs().format("DD-MM-YYYY"),
        toDate: dayjs().format("DD-MM-YYYY"),
        companyIds: valuesArray.length != 0 ? valuesArray[0].company.id : 0,
        accId: accId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (response!=null) {
            
            if (type == 1) {
              this.setState({
                setFieldDataFromApi: {
                  balance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt)+" "+"Cr": Math.abs(response.closingAmt)+" "+"Dr"
                },
              });
            } else {
              this.setState({
                fieldData: {
                  ...this.state.fieldData,
                  balance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt)+" "+"Cr": Math.abs(response.closingAmt)+" "+"Dr"
                },
              });
            }
          }
        }
      });
    }
    
  };
  rowDelete = (rowData) => {
    console.log(this.state.voucherAmountShow);
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        let finalAmt =
          rowData.type == "C"
            ? +this.state.voucherAmountShow - +rowData.amt
            : +this.state.voucherAmountShow + +rowData.amt;
        this.setState({
          rowList: row,
          fieldData: {
            ...this.state.fieldData,
            voucherAmount: 
              finalAmt > 0 ? (+finalAmt).toFixed(2) : (+finalAmt * -1).toFixed(2)
            ,
          },
          voucherAmountShow: finalAmt,
        });
      }
    });
  };

  rowCopy = (rowData) => {
    const { openCostCenter } = this.state;
    console.log(rowData);
    this.setState({
      openCostCenter: !openCostCenter,
      headerData: rowData,
    });
    console.log(rowData);
  };

  OnSaveCostCenter = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    console.log(headerId);
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  handleCloseCC = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  handleCloseBill = (dataToSave, headerId) => {
    const { openBillMapping, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailBillInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailBillInfoList: [],
      openBillMapping: !openBillMapping,
      rowList: rowListData,
    });
  };

  rowAdd = async (rowData) => {
    const { openBillMapping } = this.state;
    const { getSalesPurchase, showLoador, showNotification } = this.props;
    console.log(rowData);
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getSalesPurchase({
        entryType: 2,
        accountLedgerCode: rowData.mainAccountCode,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            openBillMapping: !openBillMapping,
            headerData: rowData,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
    console.log(rowData);
  };

  onSaveBillMapping = (dataToSave, headerId) => {
    const { openBillMapping, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailBillInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    this.setState({
      recieptPaymentDetailBillInfoList: [],
      openBillMapping: !openBillMapping,
      rowList: rowListData,
    });
  };

  goBackToList = () => {
    if (this.props.approvalFlag == 0) {
      this.props.navigate("/bank-payment");
    } else {
      this.props.navigate("/bank-payment-approval");
    }
  };
  getListData = () => {
    const {
      rowList
    } = this.state;
    let rowListData = ["Total", ""]

    let creditAmount = 0
    let debitAmount = 0
    rowList.map((rowListInfo) => {
      creditAmount = creditAmount + +rowListInfo.creditAmount
      debitAmount = debitAmount + +rowListInfo.debitAmount
      return rowListInfo
    })
    rowListData = [
      ...rowListData,
      creditAmount.toFixed(2),
      debitAmount.toFixed(2),
      ""
    ]
    if (rowList.length != 0)
      return rowListData
    return []
  }
  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      openCostCenter,
      openBillMapping,
      headerData,
      setFieldDataFromApi,
    } = this.state;
    const {rowReset} =this.props
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicTransactionForm
           subTitle={fieldData.documentNo ? fieldData.documentNo : "-"}
           amount={fieldData.voucherAmount ? fieldData.voucherAmount : "0"}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={BankPaymentJSON.screenTitle}
            fieldMeta={BankPaymentJSON.fieldMeta}
            apiBaseURL={BankPaymentJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={BankPaymentJSON.showSaveBtn}
            showSaveBtnMain={false}
            showReset={true}
            rowReset={rowReset}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            backToList={true}
            goBackToList={this.goBackToList}
          />
          <br />
          <Divider sx={{ borderWidth: 1, color: "#000000" }} />
          <br />
          <DynamicFormWithoutSave
            
            dynamicMasterData={{
              ...dynamicMasterData,
              creditAccountDetails:
                dynamicMasterData.creditAccountDetails.filter(
                  (row) => row.id != fieldData.cashBankId
                )
            }}
            getListById={this.getListById}
            searchList={BankPaymentJSON.searchList}
            showTitle={BankPaymentJSON.showTitle}
            screenTitle={BankPaymentJSON.screenTitle}
            fieldMeta={BankPaymentJSON.fieldMetaDetails}
            showCancel={BankPaymentJSON.showCancel}
            showBackToList={BankPaymentJSON.showBackToList}
            apiBaseURL={BankPaymentJSON.apiBaseURL}
            showSaveNextBtn={BankPaymentJSON.showSaveNextBtn}
            saveBtnText={"ADD"}
            onSave={this.onSearch}
            resetAfterSave={true}
            getDataForDisabled={this.getDataForDisabled}
            setFieldDataFromApi={setFieldDataFromApi}
          />

          <br />
          <RTTable
          totalList={this.getListData()}
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "mainAccountCode", title: "ACCD" },
              { name: "mainAccountName", title: "Account Head" },
              {
                name: "creditAmount",
                title: "Credit Amount",
                align: "right",
              },
              {
                name: "debitAmount",
                title: "Debit Amount",
                align: "right",
              },
              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            isActionColActive={true}
            showEditIcon={false}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
            showCopyIcon={true}
            rowCopy={this.rowCopy}
            showAddButtonInTable={true}
            rowAdd={this.rowAdd}
            iconTitle="Map Sell Bill"
            iconTitleForCopy="Cost Center"
          />
          <br />
          <DynamicTransactionForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={BankPaymentJSON.screenTitle}
            fieldMeta={BankPaymentJSON.fieldMeta1}
            apiBaseURL={BankPaymentJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={BankPaymentJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showTitle={false}
            getDataForDisabled={this.getDataForDisabled}
          />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name={this.props.approvalFlag == 0  ? "Submit" : "Approve"}
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
        {openCostCenter && (
          <CostCenterMappingDialouge
            open={openCostCenter}
            handleCloses={this.handleCloseCC}
            dynamicMasterData={dynamicMasterData}
            onSave={this.OnSaveCostCenter}
            dataToSave={headerData}
          />
        )}
        {openBillMapping && (
          <BillMappingDialouge
            open={openBillMapping}
            handleCloses={this.handleCloseBill}
            dynamicMasterData={dynamicMasterData}
            onSave={this.onSaveBillMapping}
            dataToSave={headerData}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  subVoucherList: state.subVoucher,
  cashRecieptList: state.cashReciept,
  salesPurchase: state.salesPurchase,
  payList:state.payMode,
  profitCenter: state.profitCenter
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getAccountLedgerListByType1,
  getCashRecieptWithAccNameById,
  getCostCenter,
  getSalesPurchase,
  getPayMode,
  getLedgerDataByAccId,
  getProfitCenter,
  //   getProductByCategoryForStakeHolder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BankPaymentFormEdit);
