import {
  Grid
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { showLoador, showNotification } from "../../Pages/Landing/Landing.slice";
import { titleColor } from "../../config/ColorObj";
import { LabelCompo } from "../Comman/Label";
import { withRouter } from "../withRouter";

class DynamicForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {

    };
  }

 



  render() {
    const {
      screenTitle = "",
      DataToSet = [],
    } = this.props;


    return (
      <>


        {DataToSet.length !== 0 && (
          <Grid container sx={{ margin: 2, marginTop: 0 }} spacing={1}>
            {DataToSet.map((dropDownData) => (
              <Grid container xs={dropDownData.xs} sm={dropDownData.sm} md={dropDownData.md} lg={dropDownData.lg}>
                <Grid item xs={dropDownData.labelXS ? dropDownData.labelXS : 4} sm={dropDownData.labelSM ? dropDownData.labelSM : 4} md={dropDownData.labelMD ? dropDownData.labelMD : 4} lg={dropDownData.labelLG ? dropDownData.labelLG : 4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 14, color: titleColor }}
                    label={dropDownData.label + ":"}
                  />
                </Grid>
                <Grid item xs={dropDownData.valueXS ? dropDownData.valueXS : 8} sm={dropDownData.valueSM ? dropDownData.valueSM : 8} md={dropDownData.valueMD ? dropDownData.valueMD : 8} lg={dropDownData.valueLG ? dropDownData.valueLG : 8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 14,flexWrap:"wrap" }}
                    label={dropDownData.value}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {  showLoador, showNotification };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicForm);
