import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTableWithoutBorder from "../../components/Preview/DynamicPreview";
import { withRouter } from "../../components/withRouter";
import ClosingBalanceTable from "./ClosingBalanceTable";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import { ClosingBalanceJson } from "../../DynamicFormsJson/Report/ClosingBalance";
import endpoints from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { serverMsg } from "../../config/messageconstant";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";

const ClosingBalance = ({ showLoador, showNotification }) => {
    const [searchValue, setSearchValue] = useState("");
    const [dynamicMasterData, setDynamicMasterData] = useState({});
    const [formData, setFormData] = React.useState({});
    const [cashList, setCashbookList] = React.useState([]);
    const [fieldData, setFieldData] = React.useState({});
    useEffect(() => {
        getDropDownList()
    }, []);

    const getDropDownList = async () => {
        const accountLedgerGroupMasterDetail = await apiGet({
            url: endpoints.accountLedgerGroupMaster,
        }).then(({ data, success }) => {
            if (success) {
                console.log(data);
                let rowData = data.data;


                return rowData.map((rowData) => {
                    return { ...rowData, name: rowData.groupName };
                })
            }
            return []
        });
        let CompanyDetails = await apiGet({
            url: endpoints.company,
        }).then(({ data, success }) => {
            if (success) {
                console.log(data.data);
                let rowData = data.data;
                const location=localStorage.getItem("location");
                const locationList =localStorage.getItem("locationIdList");
               const locallist=JSON.parse(locationList)
               const locationlist=locallist.filter((data)=>data.id==location)
              
                   if(locationlist.length!=0 && locationlist[0]!=null && locationlist[0].company!=null){
                     
                     setFieldData({ companyId: locationlist[0].company.id })
                   }
                return rowData.map((rowData) => {
                    return { ...rowData, name: rowData.companyName };
                })
            }
            return []
        });
        setDynamicMasterData({
            accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetail,
            CompanyDetails: CompanyDetails
        });
    }
    const getList = (fromDate, toDate, companyId, accId) => {
        showLoador({ loador: true });
        apiGet({
            url: endpoints.trailBalance +  "?companyId=" + companyId + "&fromDate=" + fromDate + "&toDate=" + toDate + "&groupIds=" + accId+"&flag=2",

        }).then(({ data, success }) => {
            if (success) {

                showLoador({ loador: false });
                setCashbookList(data.data)
            } else {
                setCashbookList([])
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }

        });
    }

    const onSave = (dataToSearch) => {
        let account = dataToSearch.accountLedgerGroupsId.split(",")
        let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
        let filteraccountLedgerGroupMaster = []
        dynamicMasterData["accountLedgerGroupMasterDetail"].map((row) => {

            account.map((accData) => {
                if (row.id === accData) {
                    filteraccountLedgerGroupMaster.push(row.name)
                }
            })
        })
        console.log(filteraccountLedgerGroupMaster.join())
        setFormData({
            ...dataToSearch,
            companyName: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
            accName: filteraccountLedgerGroupMaster.join(),
        })
        getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId, dataToSearch.accountLedgerGroupsId)
 }
    const onSearch = (searchV) => {
        setSearchValue(searchV)
    };

    const generatePDFMethod = () => {

        var columns = [["Accd", "Account Head", "Debit", "Credit"]];
        let tableList = []
        cashList.map(((rowData, index) => {
            if(rowData.showGroup==1){
            let creditTotal = 0
            let debitTotal = 0
            let tableData = []

            let accountLedgerCode = ""
            let accountLedgerName = ""
            let debit = ""
            let Credit = ""
            rowData.getTrialBalanceReport.map((data) => {

                let debitAmt = data.openingDebitAmount - data.yearDebitAmount
                let creditAmt = data.openingCreditAmount - data.yearCreditAmount
                debitTotal = debitTotal + debitAmt

                creditTotal = creditTotal + creditAmt

                accountLedgerCode = data.accountLedgerCode!=null?accountLedgerCode + data.accountLedgerCode+ "\n":accountLedgerCode + "\n"
                accountLedgerName = data.accountLedgerName!=null?accountLedgerName + data.accountLedgerName+ "\n":accountLedgerName + "\n"
                debit = debit + Math.abs(debitTotal) + "\n"
                Credit = Credit + Math.abs(creditAmt) + "\n"
            })

            tableData = []

            tableData.push(rowData.groupName + "\n" + accountLedgerCode)

            tableData.push("\n" + accountLedgerName)
            tableData.push("\n" + debit + "\n" + Math.abs(debitTotal))
            tableData.push("\n" + Credit + "\n" + Math.abs(creditTotal))
            tableList.push(tableData)
        }
        }))

        const columnStyles = {
            0: { cellWidth: 100 },
            2: { cellWidth: 90 },
           3: { cellWidth: 90 },
        };
        const columnStylesPlain = {
            0: { cellWidth: 80 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        console.log(formData.accName)
        generatePDF({
            columnStylesPlain: columnStylesPlain,
            rows: tableList,
            columns,
            columnStyles: columnStyles,
            headerList: [
                {
                    label: "Date Range ",
                    value: formData.fromDate + " To " + formData.toDate
                },
                {
                    label: "Company Name",
                    value: formData.companyName
                },
                {
                    label: "Group Name",
                    value: formData.accName
                },
            ],
            pdfTitle: ClosingBalanceJson.screenTitle,
        });

    };



    const generateExcelMethod = () => {
        var columns = ["Accd", "Account Head", "Debit", "Credit"];
        let alignList = [];

        let rows = []
        cashList.map((reportData, index) => {
            if(reportData.showGroup==1){
            let reportObj = [];
            reportObj.push(reportData.groupName)
            reportObj.push("")
            reportObj.push("")
            reportObj.push("")
            rows.push(reportObj)
            let creditTotal = 0
            let debitTotal = 0

            reportData.getTrialBalanceReport.map((data) => {
                let debitAmt = data.openingDebitAmount - data.yearDebitAmount
                let creditAmt = data.openingCreditAmount - data.yearCreditAmount
                debitTotal = debitTotal + debitAmt

                creditTotal = creditTotal + creditAmt

                reportObj = [];

                reportObj.push(data.accountLedgerCode)
                reportObj.push(data.accountLedgerName)
                reportObj.push( Math.abs(debitAmt))
                reportObj.push( Math.abs(creditAmt) )
                rows.push(reportObj)

            })
            reportObj = [];
            reportObj.push("")
            reportObj.push("")
            reportObj.push(Math.abs(debitTotal))
            reportObj.push(Math.abs(creditTotal))
            rows.push(reportObj)
        }
        });
        generateExcel({
            columns,
            rows,
            alignList,
            headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate, "Company Name", formData.companyName, "Group Name", formData.accName

            ],
            title: ClosingBalanceJson.screenTitle,
        });

    };
    const getFilteredTableData = (tableData) => {

        let listData = tableData.filter((row) => {

            let isValid = false;

            if (row.groupName.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }


            return isValid;

        });
        return listData
    };
    const getDataForDisabled = (data) => {
        console.log(data)
        return {}
    }
    return (
        <>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

                <DynamicFormWithoutSave
                    dynamicMasterData={dynamicMasterData}
                    showBackToList={false}
                    DataToSet={[]}
                    showSaveBtn={ClosingBalanceJson.showSaveBtn}
                    screenTitle={ClosingBalanceJson.screenTitle}
                    fieldMeta={ClosingBalanceJson.fieldMeta}
                    showCancel={ClosingBalanceJson.showCancel}
                    apiBaseURL={ClosingBalanceJson.apiBaseURL}
                    showSaveNextBtn={ClosingBalanceJson.showSaveNextBtn}
                    showSaveBtnMain={true}
                    padding={false}
                    generatePDF={generatePDFMethod}
                    generateExcel={generateExcelMethod}
                    searchName="Group Name"
                    onSearch={onSearch}
                    saveBtnText={"Search"}
                    paddingTop={false}
                    fieldData={fieldData}
                    onSave={onSave}
                    showExcelDownload={true}
                    getDataForDisabled={getDataForDisabled}
                />
                <br />

                <ClosingBalanceTable
                    tableHead={[
                        {
                            title: "Accd",

                            align: "left",
                        },
                        {
                            title: "Account Head",
                            align: "left",
                        },
                        {
                            title: "Debit",
                            align: "left",
                        },
                        {
                            title: "Credit",
                            align: "left",
                        },
                    ]}
                    rowList={getFilteredTableData(cashList)}
                    formData={formData}
                    getList={getList}
                    dynamicMasterData={formData}
                />
            </Paper>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(ClosingBalance);
