import { blackColor, lightGray, mediumGray, primaryColor } from "../../config/ColorObj";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ButtonCompo } from "./Button";
import { LabelCompo } from "./Label";
import { MyComponentWithIconProps,FontAwesomeIconCompo } from "./IconButton";
import { Grid, Paper, Switch, Tooltip } from "@mui/material";
export const TitleAndBreadCrum = ({ onCancel,showBackButton=false,showEdit = false,
  showDelete = false,
  rowEdit,
  previewText='',
  timeData='',
  rowDelete, title, subTitle = "", PeriodData = "", showReset = false, rowReset, amount = "",showPdf=false,generatePDF, }) => {
  return (
    <Grid container direction="row" >
      <Grid item xs={12} sm={8} md={8}>
        <LabelCompo
          className="text-black"
          style={{ fontSize: "16px", fontWeight: 500, color: blackColor, borderBottom: "3px solid #295FA6" }}
          label={title}
        />

      </Grid>
    { showBackButton&& <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12} sm={4} md={4}>
          
      <ButtonCompo
                          size="small"
                          type="button"
                          variant="outlined"
                          name=" Back to List"
                          onClick={onCancel}
                        />
        </Grid>}
      {
        PeriodData != "" && <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12} sm={4} md={4}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12} sm={4} md={4}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: "14px", color: primaryColor }}
              label={'Period : '}
            />

          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12} sm={8} md={8}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: "16px", fontWeight: 500, color: blackColor }}
              label={PeriodData}
            />
          </Grid>
        </Grid>
      }

      {
        (showReset || showDelete || showEdit || showPdf) &&
        <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12} sm={4} md={4}>
          {showReset && (

            <MyComponentWithIconProps
              statusImage={RestartAltIcon}
              color="primary"
              fontSize="medium"
              title="Reset"
              onClick={rowReset}
            />
          )}
          {showDelete && (
            <>
              &emsp;
              <MyComponentWithIconProps
                statusImage={DeleteIcon}
                color="error"
                fontSize="medium"
                title="Delete"
                onClick={rowDelete}
              />
            </>
          )}
          {showEdit && (

            <>
              &emsp;
              <MyComponentWithIconProps
                statusImage={EditIcon}
                color="primary"
                fontSize="medium"
                title="Edit"
                onClick={rowEdit}
              />
            </>
          )}

{showPdf && (
                          <>
                            &emsp;
                            <FontAwesomeIconCompo
                              color="error"
                              fontSize="small"
                              title="PDF"
                              baseClassName="fas"
                              className="fa-file-pdf"
                              onClick={generatePDF}
                            />
                           
                          </>

                        )}
        </Grid>
      }
      {
        previewText!=""&&
        <Grid item xs={12} sm={6} md={6}>

          <div>
            <LabelCompo
              className="text-black"
              style={{ fontSize: "14px", fontWeight: 400, color: mediumGray }}
              label={previewText}
            />
          </div>

        </Grid>
      }

{
        timeData!=""&&
      <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12} sm={6} md={6}>

        <div>
          <LabelCompo
            className="text-black"
            style={{ fontSize: "12px", fontWeight: 400, color: lightGray }}
            label={timeData}
          />
        </div>

      </Grid>
  }
      {
        (subTitle != "" || amount != "") &&
        <Grid sx={{ paddingTop: 1 }} item xs={12} sm={6} md={6}>

          {
            subTitle != "" && <div>
              <LabelCompo
                className="text-black"
                style={{ fontSize: "16px", padding: "3px 8px", borderRadius: "3px", boxShadow: "3px 3px 3px 3px #EDECEC", fontWeight: 600, color: primaryColor }}
                label={subTitle}
              />
            </div>
          }




        </Grid>
      }
      {
        amount != "" &&
        <Grid sx={{ paddingTop: 1, display: "flex", justifyContent: "flex-end" }} item xs={12} sm={6} md={6}>

          <div>
            <LabelCompo
              className="text-black"
              style={{ fontSize: "18px", padding: 3, borderRadius: "3px", boxShadow: "3px 3px 3px 3px #EDECEC", fontWeight: 500, color: primaryColor }}
              label={"₹ " + amount}
            />
          </div>

        </Grid>
      }


    </Grid>

  );
};