import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";

import { ButtonCompo } from "../../components/Comman/Button";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";

import swal from "sweetalert";
import DatePickerCompo from "../../components/Comman/DatePickerEx";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoints from "../../config/endpoints";

import { BankRecoJson } from "../../DynamicFormsJson/Report/BankReco";
import { getAccountLedgerListByType } from '../../Slice/subVoucherDetail.slice';
import { showLoador, showNotification } from "../Landing/Landing.slice";
const BankReco = ({ showLoador, showNotification, getAccountLedgerListByType }) => {
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [DataToSet, setDataToset] = useState([]);
  const [bankRecoList, setBankRecoList] = useState([])
  useEffect(() => {

    getDropDownList()
  }, [])
  const getDropDownList = async () => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      const accountLedgerGroupMasterDetailForType1 = await getAccountLedgerListByType({
        accType: 2,
      }).then(({ response, success }) => {
        if (success) {
          return response.map((rowData) => {
            showLoador({ loador: false });
            return { ...rowData, id: rowData.id1 }
          })
        } else {
          showLoador({ loador: false });
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        return []
      });

      setDynamicMasterData({
        accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetailForType1,

      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  const onSubmit = async () => {

    if (navigator.onLine) {
      let productList = [];
      let error = false;
      let formattedBankRecoList = [];
      const banklist = bankRecoList.map((data) => {
       
        
        const formattedDate = data.passDate != null && data.passDate != "" ? dayjs(data.passDate).format("DD-MM-YYYY") : "";
        return {
          ...data,
          passDate: formattedDate != null && formattedDate != "" ? formattedDate : null,
        }

      });




      if (!error) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            onSave(banklist);


          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }

  };

  const onSave = async (dataToSave) => {



    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.recieptPaymentHeader + "/update-pass-date",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg, severity: "success" });

        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }

        return success;
      });
      // window.location.replace("/bank-reco");
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  const onSearch = async (data) => {

    let location = localStorage.getItem("location");

    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url: endpoints.getRecieptPaymentHeaderList + "/get-payment-reciept-by-date?locationId=" + location + "&accountLedgerId=" + data.accountLedgerGroupsId + "&fromDate=" + data.fromDate + "&toDate=" + data.toDate + "&voucherType=3,4,8",

        // url: endpoints.getRecieptPaymentHeaderList+"/get-payment-reciept-by-date?locationId=7f11afa5-49b9-40e1-b9cb-6b407d04eb6a&accountLedgerId=f76347fe-3eb9-4991-a3bf-0acfd90ffae1&fromDate=01-11-2023&toDate=30-11-2023&voucherType=3,4",
      }).then(({ data, success }) => {
        if (success) {
          let rowData = data.data;
          const list = rowData.map((row) => {
            return {
              ...row,
              passDate: row.passDate != null && row.passDate != "null" ? dayjs(
                row.passDate,
                "DD-MM-YYYY"
              ) : null,

            }
          })
          setBankRecoList(list);
          showLoador({ loador: false });
          return rowData;
        } else {
          showLoador({ loador: false });
          setBankRecoList([]);
        }
        return []
      });


    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  const onChange = (e, row, name) => {


    let list = bankRecoList.map((rowData) => {
      return {
        ...rowData, [name]: rowData.id === row.id ? e : rowData[name],
      }
    }
    )
    setBankRecoList(
      list
    );
  };

  return (
    <>
      <Paper
        sx={{
          padding: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <DynamicFormWithoutSave
           showBackToList={false}
          DataToSet={DataToSet}
          dynamicMasterData={dynamicMasterData}
          getByIdApi={true}
          callApi={true}
          // showSaveBtn={true}
          showTitle={BankRecoJson.showTitle}
          screenTitle={BankRecoJson.screenTitle}
          fieldMeta={BankRecoJson.fieldMeta}
          showCancel={BankRecoJson.showCancel}
          apiBaseURL={BankRecoJson.apiBaseURL}
          showSaveNextBtn={BankRecoJson.showSaveNextBtn}
          // showSaveBtnMain={false}

          saveBtnText={"Search"}
          onSave={onSearch}
        />

        {bankRecoList.length != 0 && (
          <>

            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell  style={{
                minWidth: 150,
              }}align="center">Vou No.</TableCell>
                    <TableCell   style={{
                minWidth: 100,
              }}align="center">Type</TableCell>

                    <TableCell style={{
                minWidth: 100,
              }}align="center">Accd</TableCell>
                    <TableCell style={{
                minWidth: 250,
              }}align="center">Account Head</TableCell>
                    <TableCell style={{
                minWidth: 120,
              }}align="center">Date</TableCell>
                    <TableCell style={{
                minWidth: 120,
              }}align="center">Cheq No</TableCell>
                    <TableCell style={{
                minWidth: 150,
              }}align="center">Cheq Date</TableCell>
                    <TableCell style={{
                minWidth: 150,
              }}align="center">Credit</TableCell>
                    <TableCell style={{
                minWidth: 150,
              }}align="center">Debit</TableCell>
                    <TableCell style={{
                minWidth: 200,
              }}align="center">Pass Date</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankRecoList.map((row) => (
                    <TableRow>
                      <TableCell align="center">{row.documentNo}</TableCell>
                      <TableCell align="left">{row.paymentMethod}</TableCell>
                      <TableCell align="left">{row.voucherType==8&&row.flag==1 ?row.cashBankId:row.mainAccountId}</TableCell>
                      <TableCell align="left">{row.voucherType==8&&row.flag==1 ?row.accountLedgerName:row.locationName}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="left">{row.chequeNo}</TableCell>
                      <TableCell align="center">{row.chequeDate}</TableCell>
                      <TableCell align="right">{row.voucherType==3||(row.voucherType==8&&row.flag==1)?(+row.voucherAmount).toFixed(2):"-"}</TableCell>
                      <TableCell align="right">{row.voucherType==4||(row.voucherType==8&&row.flag==0)?(+row.voucherAmount).toFixed(2):"-"}</TableCell>

                      <TableCell>
                        <DatePickerCompo
                          color="primary"
                          name="dob"
                          size="small"
                          value={row.passDate}
                          disabled={
                            false
                          }
                          fullWidth
                          onChange={(e) => {
                            onChange(e, row, "passDate")
                          }}
                        // errorText={
                        //     formErrors["nameError"]
                        //         ? formErrors["nameError"]
                        //         : ""
                        // }
                        /></TableCell>


                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />

            {bankRecoList.length != 0 && (
              <>
                <br />
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Submit"
                    onClick={onSubmit}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, getAccountLedgerListByType, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(BankReco);