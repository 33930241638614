import {
    Grid
} from "@mui/material";
import { Paper } from "@mui/material";
import swal from "sweetalert";
import dayjs from "dayjs";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import { getAccountLedgerList } from "../../../Slice/accountLedger.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import CashReceiptForm from "./index"
import CashReceiptFormEdit from "./index"
import CashRecieptView from "./index"

import { withRouter } from "../../../components/withRouter";
import { deleteFailedMsg, deletemsg, noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { AccountLedgerColumns } from "../../../tableColumns/table-columns";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiDelete } from "../../../utils/api_service";
class MainIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
            },
            searchInfo: {},
            editData: {},
            viewData: {},
            setFieldData: {}
        };
    }


    rowEdit = (rowData) => {
        this.setState(
            {
                editData: rowData,
                viewData: {},

            }
        )
        // this.props.navigate("/cash-receipt-form/" + rowData.id + "/" + 0);
    };
    callApi = () => {

        this.rowReset()
    }
    rowLink = (rowData) => {
        this.setState(
            {
                viewData: rowData
            }
        )
    }
    rowReset = () => {
        this.setState(
            {
                viewData: {},
                editData: {}
            }
        )
    }
    rowDelete = (rowData) => {

        swal({
            title: "Are you sure?",
            text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onDelete(rowData);
            }
        });
    };
    onDelete = async () => {
        const {
            deleteMsgAuto = false,
        } = this.props;
        if (navigator.onLine) {
            this.props.showLoador({ loador: true });
            apiDelete({
                url: AccountLedgerJson.apiBaseURL + "/" + this.state.viewData.id,
            }).then(({ data, success }) => {
                if (success) {
                    this.props.showLoador({ loador: false });
                    this.props.showNotification({
                        msg: deleteMsgAuto ? data.data.message : deletemsg,
                        severity: data.data.error ? "error" : "success",
                    });

                    this.callApi();

                } else {
                    this.props.showNotification({
                        msg: deleteFailedMsg,
                        severity: "error",
                    });

                    this.props.showLoador({ loador: false });
                }
            });

        } else {
            this.props.showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };
    render() {
        const { dynamicMasterData, editData, viewData, setFieldData } = this.state;
        const { accountLedgerList, getAccountLedgerList } = this.props;
        // console.log(cashRecieptList?.cashReciept);
        return (
            <>
                {/* <Paper
                    sx={{
                        p: 2,
                        pt: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                > */}
                <Grid sx={{ height: "100vh" }} container spacing={1}>

                    <Grid item xs={12} sm={7} md={7}>
                       <CashReceiptForm rowReset={this.rowReset} callApi={this.callApi} editData={editData} />
                    </Grid>
                    <Grid sx={{ borderLeft: "2px solid #CFCFCF" }} item xs={12} sm={5} md={5}>
                        <DynamicMainScreen
                            formPath={AccountLedgerJson.formPath}
                            screenTitle={AccountLedgerJson.screenTitle}
                            openContactPath={AccountLedgerJson.openContactPath}
                            openBankPath={AccountLedgerJson.openBankPath}
                            showPdfDownload={false}
                            importExcel={true}
                            importExcelPath={'/import-account-ledger'}
                            showContactInfo={AccountLedgerJson.showContactInfo}
                            showBankInfo={true}
                            editAuto={false}
                            showExcelDownload={true}
                            showAddButton={true}
                            tableColumnsToFilter={AccountLedgerJson.tableColumnsToFilter}
                            tableColumns={AccountLedgerColumns}
                            tableData={accountLedgerList?.accountLedger}
                            getTableData={getAccountLedgerList}
                            apiBaseURL={AccountLedgerJson.apiBaseURL}
                            baseIdColumn={AccountLedgerJson.baseIdColumn}
                            showPegination={false}
                            rowEdit={this.rowEdit}
                        />
                    </Grid>
                </Grid>
                {/* </Paper> */}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accountLedgerList: state.accountLedger,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAccountLedgerList,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MainIndex);