import React from "react";
import { connect } from "react-redux";

import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { ButtonCompo } from "../../components/Comman/Button";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import MasterForm from "./MasterForm";
import TableColumnsDetails from "./TableColumns";
import AddFilterForm from "./AddFilterForm";
import { compose } from "@reduxjs/toolkit";

import GraphDetails from "./GraphDetails";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { ReportInfoJson } from "../../DynamicFormsJson/Transaction/reportInfo";
import { withRouter } from "../../components/withRouter";
import AddFormFieldsComponents from "./AddFormFieldsComponents";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class ReportGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 25.5,
      open: false,
      orderList: [],
      salesPersonId: "",
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  changeTab = (value) => {
    this.setState({
      value: value,
    });
  };
  onCancel = () => {
    this.props.navigate(-1);
  };
  render() {
    const { value } = this.state;
    const { masterGenerateData } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={ReportInfoJson.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            showTable={false}
            showBackButton={true}
            onCancel={this.onCancel}
          />
          <br />
          <>
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label={"Add Basic Info"} />
              <Tab
                disabled={
                  Object.keys(masterGenerateData.masterData).length == 0
                    ? true
                    : false
                }
                label={"Add List Filter"}
              />
              <Tab
                disabled={
                  Object.keys(masterGenerateData.masterData).length == 0
                    ? true
                    : false
                }
                label={"Add Table Colums"}
              />
              <Tab
                disabled={
                  Object.keys(masterGenerateData.masterData).length == 0
                    ? true
                    : false
                }
                label={"Add Form Fields"}
              />
            </Tabs>
            <TabPanel index={0} value={value}>
              <MasterForm changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={1} value={value}>
              <AddFilterForm changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={2} value={value}>
              <TableColumnsDetails changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={3} value={value}>
              <AddFormFieldsComponents />
            </TabPanel>
          </>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  reportList: state.report,
  masterGenerateData: state.masterGenerate,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReportGenerator);
