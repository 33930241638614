import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import { ManageStockJSON } from "../../DynamicFormsJson/Transaction/manageStock";
import { Grid } from "@mui/material";
import { getTimelineById } from "../../Slice/timeline.slice";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicDetails";
// import CashReciptFormForReport from "./CashReciptFormForReport";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BillMappingViewDialouge from "./BillMappingViewDialouge";
import CostCenterMappingViewDialouge from "./CostCenterMappingViewDialouge";
import { serverMsg } from "../../config/messageconstant";
import {TitleAndBreadCrum} from "../../components/Comman/TitleAndBreadCrum";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class CashReceiptPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousData: {},
      updatedJson: {},
      formData: {},
      manageStockList: [],
      timelineList: [],
      openCostCenterView: false,
      openBillView: false,
      headerData: {},
    };
    this.columns = [
      { name: "index", title: "Sr. No.", align: "center" },
      { name: "mainAccId", title: "ACCD", align: "center" },
      { name: "accountLedgerName", title: "Account Name" },
      {
        name: "creditAmount",
        title: "Credit Amount",
        align: "right",
      },
      {
        name: "debitAmount",
        title: "Debit Amount",
        align: "right",
      },
      { name: "action", title: "Action", align: "center" },
    ];
  }
  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps).length != 0) {
      if (this.props.id != nextProps.id) {
        this.getByIdData(nextProps.id);
      }
    }
  }
  async componentDidMount() {
    const { showNotification } = this.props;
    if (navigator.onLine) {
      this.getByIdData(this.props.id);
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getByIdData = async (id) => {
    const { getTimelineById, showLoador, showNotification } = this.props;

    showLoador({ loador: true });
    await getTimelineById({
      transactionId: id,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if(response && response!=null){
        console.log(JSON.parse(response.previousJson));
        this.setState({
          previousData:
            response.previousJson != null &&
            JSON.parse(response.previousJson) != null
              ? JSON.parse(response.previousJson)
              : {},
          updatedJson:
            response.updatedJson != null &&
            JSON.parse(response.updatedJson) != null
              ? JSON.parse(response.updatedJson)
              : {},
        });
      }
      }
    });
  
  };
  rowView = (rowData) => {
    this.setState({
      openCostCenterView: !this.state.openCostCenterView,
      headerData: rowData,
    });
  };

  rowOutlet = (rowData) => {
    this.setState({
      openBillView: !this.state.openBillView,
      headerData: rowData,
    });
  };
  getListData = (timelineList) => {
    let rowList = timelineList.map((rowData) => {
      return {
        ...rowData,
        creditAmount: rowData.type == "C" ? (+rowData.amt).toFixed(2) : "",
        debitAmount: rowData.type == "C" ? "" : (+rowData.amt).toFixed(2),
      };
    });
    let rowListData = ["Total", ""];

    let creditAmount = 0;
    let debitAmount = 0;
    rowList.map((rowListInfo) => {
      creditAmount = creditAmount + +rowListInfo.creditAmount;
      debitAmount = debitAmount + +rowListInfo.debitAmount;
      return rowListInfo;
    });
    rowListData = [
      ...rowListData,
      creditAmount.toFixed(2),
      debitAmount.toFixed(2),
      "",
    ];
    if (rowList.length != 0) return rowListData;
    return [];
  };
  onCancel = () => {
    this.props.navigate(-1);
  };

  render() {
    const { value } = this.state;

    const {
      previousData,
      updatedJson,
      formData,
      openCostCenterView,
      headerData,
      openBillView,
      timelineList,
    } = this.state;
    const {
      cashRecieptList,
      rowEdit,
      rowDelete,
      approvalFlag,
      rowReset,
      open,
      handleCloses,
    } = this.props;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={handleCloses}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth="xl"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloses}
            >
           
             
              <>
              
             
              <TitleAndBreadCrum
              title={"Cash Receipt View"}
              subTitle={Object.keys(updatedJson).length != 0 &&
                updatedJson.documentNo != null ?updatedJson.documentNo: ""}
                 
                  /> 
              </>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              
              <Grid sx={{ height: "50vh" }} container spacing={1}>
                
                <Grid sx={{ paddingRight: 1 }} item xs={12} sm={6} md={6}>
                  {Object.keys(previousData).length != 0 && (
                    <>
                     <TitleAndBreadCrum
              amount={
                Object.keys(previousData).length != 0 &&
                previousData.voucherAmount &&
                previousData.voucherAmount != null
                  ? previousData.voucherAmount.toFixed(2)
                  : ""
              } /> 
                      <DynamicFormWithoutSave
                        
                        
                      
                      
                      
                        showBackToList={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        DataToSet={[
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Sub-Voucher Name",
                            value:
                            previousData.subVoucherMaster != null && previousData.subVoucherMaster.subVoucherName!=null
                                ? previousData.subVoucherMaster.subVoucherName
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Profit Center",
                            value:
                              previousData.profitCenter !== null
                                ? previousData.profitCenter.name
                                : "",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Date",
                            value:
                              previousData.date !== null
                                ? previousData.date
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Cash A/C",
                            value:
                              previousData.cashBankId !== null &&
                              previousData.cashBankCode != null
                                ? previousData.cashBankCode +
                                  " ( " +
                                  previousData.cashBankId +
                                  " )"
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Received From",
                            value:
                              previousData.receivedFrom !== null
                                ? previousData.receivedFrom
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Remark",
                            value:
                              previousData.remark != null &&
                              previousData.remark != ""
                                ? previousData.remark
                                : " - ",
                          },
                        ]}
                        showSaveBtn={ManageStockJSON.showSaveBtn}
                        showTitle={true}
                        fieldMeta={[]}
                        
                       
                        showCancel={ManageStockJSON.showCancel}
                        apiBaseURL={ManageStockJSON.apiBaseURL}
                        showSaveNextBtn={ManageStockJSON.showSaveNextBtn}
                        showSaveBtnMain={false}
                        padding={false}
                        paddingTop={false}
                      />
                      <RTTable
                        columns={this.columns}
                        tableData={previousData.recieptPaymentDetailList.map(
                          (rowData, index) => {
                            return {
                              index: index + 1,
                              ...rowData,
                              creditAmount:
                                rowData.type == "C"
                                  ? (+rowData.amt).toFixed(2)
                                  : "",
                              debitAmount:
                                rowData.type == "C"
                                  ? ""
                                  : (+rowData.amt).toFixed(2),
                            };
                          }
                        )}
                        totalList={this.getListData(
                          previousData.recieptPaymentDetailList
                        )}
                        isActionColActive={true}
                        showPegination={false}
                        showDeleteIcon={false}
                        showEditIcon={false}
                        showCopyIcon={true}
                        iconTitle="Map Sell Bill"
                        iconTitleForCopy="Cost Center"
                        rowCopy={this.rowView}
                        showAddButtonInTable={true}
                        rowAdd={this.rowOutlet}
                      />
                    </>
                  )}
                  {openCostCenterView && (
                    <CostCenterMappingViewDialouge
                      open={openCostCenterView}
                      handleCloses={this.rowView}
                      dataToSave={headerData}
                    />
                  )}
                  {openBillView && (
                    <BillMappingViewDialouge
                      open={openBillView}
                      handleCloses={this.rowOutlet}
                      dataToSave={headerData}
                    />
                  )}
                </Grid>
                <Grid
                  sx={{ borderLeft: "2px solid #CFCFCF" }}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  {Object.keys(updatedJson).length != 0 && (
                    <>
                     <TitleAndBreadCrum
               amount={
                Object.keys(updatedJson).length != 0 &&
                updatedJson.voucherAmount &&
                updatedJson.voucherAmount != null
                  ? updatedJson.voucherAmount.toFixed(2)
                  : ""
              }/> 
                      <DynamicFormWithoutSave
                       
                        showBackToList={false}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        DataToSet={[
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Sub-Voucher Name",
                            value:
                            updatedJson.subVoucherMaster!= null &&  updatedJson.subVoucherMaster.subVoucherName!=null
                                ?  updatedJson.subVoucherMaster.subVoucherName
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Profit Center",
                            value:
                              updatedJson.profitCenter !== null 
                                ? updatedJson.profitCenter.name
                                : "",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Date",
                            value:
                              updatedJson.date !== null
                                ? updatedJson.date
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Cash A/C",
                            value:
                              updatedJson.cashBankId !== null &&
                              updatedJson.cashBankCode != null
                                ? updatedJson.cashBankCode +
                                  " ( " +
                                  updatedJson.cashBankId +
                                  " )"
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Received From",
                            value:
                              updatedJson.receivedFrom !== null
                                ? updatedJson.receivedFrom
                                : "-",
                          },
                          {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            label: "Remark",
                            value:
                              updatedJson.remark != null &&
                              updatedJson.remark != ""
                                ? updatedJson.remark
                                : " - ",
                          },
                        ]}
                        showSaveBtn={ManageStockJSON.showSaveBtn}
                        showTitle={true}
                        screenTitle={""}
                        fieldMeta={[]}
                        // subTitle={
                        //   Object.keys(updatedJson).length != 0 &&
                        //   updatedJson.documentNo &&
                        //   updatedJson.documentNo != null
                        //     ? updatedJson.documentNo
                        //     : ""
                        // }
                      
                        showCancel={ManageStockJSON.showCancel}
                        apiBaseURL={ManageStockJSON.apiBaseURL}
                        showSaveNextBtn={ManageStockJSON.showSaveNextBtn}
                        showSaveBtnMain={false}
                        padding={false}
                        paddingTop={false}
                      />
                      <RTTable
                        columns={this.columns}
                        tableData={updatedJson.recieptPaymentDetailList.map(
                          (rowData, index) => {
                            return {
                              index: index + 1,
                              ...rowData,
                              creditAmount:
                                rowData.type == "C"
                                  ? (+rowData.amt).toFixed(2)
                                  : "",
                              debitAmount:
                                rowData.type == "C"
                                  ? ""
                                  : (+rowData.amt).toFixed(2),
                            };
                          }
                        )}
                        totalList={this.getListData(
                          updatedJson.recieptPaymentDetailList
                        )}
                        isActionColActive={true}
                        showPegination={false}
                        showDeleteIcon={false}
                        showEditIcon={false}
                        showCopyIcon={true}
                        iconTitle="Map Sell Bill"
                        iconTitleForCopy="Cost Center"
                        rowCopy={this.rowView}
                        showAddButtonInTable={true}
                        rowAdd={this.rowOutlet}
                      />
                    </>
                  )}
                  {openCostCenterView && (
                    <CostCenterMappingViewDialouge
                      open={openCostCenterView}
                      handleCloses={this.rowView}
                      dataToSave={headerData}
                    />
                  )}
                  {openBillView && (
                    <BillMappingViewDialouge
                      open={openBillView}
                      handleCloses={this.rowOutlet}
                      dataToSave={headerData}
                    />
                  )}
                </Grid>
              </Grid>

              {/* </Paper> */}
            </DialogContent>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accountLedgerList: state.accountLedger,
  manageStockList: state.manageStockByDate,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,

  getTimelineById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CashReceiptPreview);
