import { Divider, Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {getPayMode} from "../../Slice/payMode.slice";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  deleteWarningMsg,
} from "../../config/messageconstant";
import dayjs from "dayjs";
import { ContraEntryJSON } from "../../DynamicFormsJson/Transaction/contraEntry";
import { getProfitCenter } from "../../Slice/profitCenter.slice";

import { getLedgerDataByAccId } from "../../Slice/ledger.slice";
import {
  getAccountLedgerListByType1,
} from "../../Slice/subVoucherDetail.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicTransactionForm from "../../components/dynamicscreens/DynamicTransactionForm";
import {
  getCashRecieptWithAccNameById,
  getCostCenter,
} from "../../Slice/cashReceipt.slice";
import CostCenterMappingDialouge from "../CashReceipt/CostCenterMappingDialouge";

class ContraEntryFormForReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      formData: {},
      fieldData: { type: "1" },
      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        paymentModeDetails:this.props.payList?.payMode,
        creditAccountDetails:
          this.props.subVoucherDetailList?.accountLedgerByType1,
        cashBankDetails: this.props.subVoucherDetailList?.accountLedgerByType,
        costCenterDetail: this.props.cashRecieptList?.costCenter,
        typeDetails: [
          { id: "C", name: "Credit" },
          { id: "D", name: "Debit" },
        ],
        contraEntryTypeDetails: [
          { id: "1", name: "Bank To Bank" },
          { id: "2", name: "Cash Deposit To Bank" },
          { id: "3", name: "Cash Withdrawl From Bank" },
        ],
        subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
        voucherAmount: 0,
      },
      headerData: {},
      DataToSet: [],
      recieptPaymentDetailCcInfoList: [],
      recieptPaymentDetailBillInfoList: [],
      openCostCenter: false,
      openBillMapping: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    // if (this.props.editData.id != nextProps.editData.id) {
    //   this.getByIdData(nextProps.editData.id)
    // }
    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }

    if (
      this.props.subVoucherDetailList.accountLedgerByType !==
      nextProps.subVoucherDetailList.accountLedgerByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          cashBankDetails: nextProps.subVoucherDetailList.accountLedgerByType,
        },
      });
    }
    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          creditAccountDetails:
            nextProps.subVoucherDetailList.accountLedgerByType1,
        },
      });
    }
    if (
      this.props.cashRecieptList?.costCenter !==
      nextProps.cashRecieptList?.costCenter
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          costCenterDetail: nextProps.cashRecieptList?.costCenter,
        },
      });
    }
    if (
      this.props.subVoucherList?.subVoucherByType !==
      nextProps.subVoucherList?.subVoucherByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subVoucherDetail: nextProps.subVoucherList?.subVoucherByType,
        },
      });
    }

    if (
      this.props.payList?.payMode !==
      nextProps.payList?.payMode
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          paymentModeDetails: nextProps.payList?.payMode,
        },
      });
    }
  }

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const {
      showLoador,
      showNotification,
      getAccountLedgerListByType1,
      getCashRecieptWithAccNameById,
      getCostCenter,
      getPayMode,
      getProfitCenter,
    } = this.props;
    const { dynamicMasterData } = this.state;
    if (navigator.onLine) {
     
    
      showLoador({ loador: true });
      await getCostCenter().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });

      showLoador({ loador: true });
      await getProfitCenter().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return { response, success };
        } else {
          return { response, success };
        }
      });
      showLoador({ loador: true });
      await getPayMode().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
     
      if (this.props.editData) {
        this.getByIdData(this.props.editData)
      }
     
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  getByIdData = async (editData) => {
    const {
      showLoador,
      showNotification,
     
      getAccountLedgerListByType1,
    } = this.props;
    let ledgerList = {};
   if(editData) {
        let contraEntryType=editData.contraEntryType
          showLoador({ loador: true });
          ledgerList = await getAccountLedgerListByType1({
            accType: "1,2",
          }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
              return { response, success };
            } else {

              if (contraEntryType == 1) {

                this.setState({
                  dynamicMasterData: {
                    ...this.state.dynamicMasterData,
                    creditAccountDetails: response.filter(
                      (row) => row.accType == 2
                    ),
                    cashBankDetails: response.filter(
                      (row) => row.accType == 2
                    )
                  },
                });
              } else if (contraEntryType == 3) {
                this.setState({
                  dynamicMasterData: {
                    ...this.state.dynamicMasterData,
                    creditAccountDetails: response.filter(
                      (row) => row.accType == 1
                    ),
                    cashBankDetails: response.filter(
                      (row) => row.accType ==2
                    )
                  },
                });
              } else if (contraEntryType == 2) {
                this.setState({
                  dynamicMasterData: {
                    ...this.state.dynamicMasterData,
                    creditAccountDetails: response.filter(
                      (row) => row.accType == 2
                    ),
                    cashBankDetails: response.filter(
                      (row) => row.accType ==1 
                    )
                  },
                });
              }
              return { response, success };
            }
          });
        
        if(editData){
        
          let amt = 0;
          let rowList = editData.dtoRecieptPaymentDetailList.map(
            (rowData, index) => {
             
              amt =
              rowData.type && rowData.type == "C" ? amt + +rowData.amt : amt - +rowData.amt;
              return {
                index: index + 1,
                mainAccountCode: rowData.mainAccId,
                mainAccountName: rowData.accountLedgerName,
                amt: rowData.amt?rowData.amt:"",
                type: rowData.type?rowData.type:"",
                sortNo: rowData.sortNo?rowData.sortNo:"",
                mainAccountId: editData.cashBankId?editData.cashBankId:"",
                remark: rowData.remark? rowData.remark:"",
                ...rowData,
              };
            }
          );
          this.setState({
            fieldData: {
              ...editData,
              date:editData.date!=null && editData.date!=""? dayjs(editData.date, "DD-MM-YYYY"):"",
              subVoucherMaster: { id: editData.subVoucherId },
              cashBankId:editData.cashBankId!=null?editData.cashBankId:"",
              paidTo:editData.paidTo!=null? editData.paidTo:"",
              clearanceDays: editData.clearanceDays!=null?editData.clearanceDays:"",
              passDate:editData.passDate!=null && editData.passDate!=""?dayjs(editData.passDate, "DD-MM-YYYY"):"",
              drawnOn: editData.drawnOn!=null && editData.drawnOn!=""?dayjs(editData.drawnOn, "DD-MM-YYYY"):"",
              chequeNo: editData.chequeNo!=null?editData.chequeNo:"",
              chequeDate: editData.chequeDate!=null&&editData.chequeDate!=""?dayjs(editData.chequeDate, "DD-MM-YYYY"):"",
              voucherAmount:  editData.voucherAmount,
              remark: editData.remark!=null?editData.remark:"",
              contraEntryType: editData.contraEntryType!=null?editData.contraEntryType.toString():"",
              mainAccountId: editData.mainAccountId?editData.mainAccountId:"",
              amt: editData.voucherAmount,
              // rowList[0]!=null && rowList[0].amt?rowList[0].amt:"",
              
            },
          });
        }
        this.getListById(editData);
      }
     
      }
   

 
  getListById = async (data) => {
    const {
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      

      if (data.mainAccountId != null && data.mainAccountId != "") {
        this.getList(data.mainAccountId, 1);
      }
      if (data.cashBankId != null && data.cashBankId != "") {
        //    this.setState({
        //   dynamicMasterData: {
        //     ...this.state.dynamicMasterData,
        //     creditAccountDetails:this.state.dynamicMasterData.creditAccountDetails.filter((row)=>row.id!=data.cashBankId),
        //   },
        // });
        this.getList(data.cashBankId, 0);
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
 

  getList = async (accId, type) => {
    const {
      showLoador,
      showNotification,
      getLedgerDataByAccId,
      getCostCenter,
    } = this.props;
    const locationIdList = localStorage.getItem("locationIdList");
    let valuesArray = [];
    if (locationIdList != null) {
      valuesArray = JSON.parse(locationIdList);
      valuesArray = valuesArray.filter(
        (row) => row.id === localStorage.getItem("location")
      );
    }
    showLoador({ loador: true });
    await getLedgerDataByAccId({
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
      companyIds: valuesArray.length != 0 ? valuesArray[0].company.id : 0,
      accId: accId,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        console.log(response)
        console.log(response)
        if (response!=null) {
          
          if (type == 1) {
            this.setState({
             
              fieldData: {
                ...this.state.fieldData,
                debitBalance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt).toFixed(2) +" "+"Cr": Math.abs(response.closingAmt).toFixed(2) +" "+"Dr"
              },
            });
          } else {
            this.setState({
              fieldData: {
                ...this.state.fieldData,
                balance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt).toFixed(2) +" "+"Cr": Math.abs(response.closingAmt).toFixed(2) +" "+"Dr"
              },
            });
          }
        }
      }
    });
  };
  onSearch = (data) => {
    const {
      rowList,
      dynamicMasterData,
      headerData,
      fieldData,
      recieptPaymentDetailCcInfoList,
    } = this.state;

    let productList = dynamicMasterData["typeDetails"]
      ? dynamicMasterData["typeDetails"].filter((row) => row.id == data.type)
      : [];
    let catList = dynamicMasterData["creditAccountDetails"]
      ? dynamicMasterData["creditAccountDetails"].filter(
          (row) => row.id == data.mainAccountId
        )
      : [];
    let amt = 0;
    rowList.map((row) => {
      amt = row.type == "C" ? amt + +row.amt : amt - +row.amt;
    });
    amt = data.type == "C" ? amt + +data.amt : amt - +data.amt;
    console.log(recieptPaymentDetailCcInfoList);
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          mainAccountCode:
            catList.length != 0 ? catList[0].accountLedgerCode : "-",
          mainAccountName:
            catList.length != 0 ? catList[0].accountLedgerName : "-",
          recieptPaymentDetailCcInfoList: recieptPaymentDetailCcInfoList,
          ...data,
        },
      ],
      fieldData: {
        ...fieldData,
        voucherAmount: amt,
      },
    });
  };

  onSubmitData = (costMappingList) => {
    const { showNotification } = this.props;
    console.log(costMappingList);
    const location = localStorage.getItem("location");
    console.log(this.state.rowList);
    if (this.checkValidationOnSubmit()) {
      const {
        rowList,
        fieldData,
        recieptPaymentDetailCcInfoList,
        recieptPaymentDetailBillInfoList,
        dynamicMasterData,
      } = this.state;
      // let totalAmt = 0;
      // if (rowList.length != 0) {
      //   let creditAmount = 0;
      //   let debitAmount = 0;
      //   const recieptPaymentDetailList = rowList.map((rowData) => {
      //     if (rowData.type == "C") {
      //       creditAmount = creditAmount + +rowData.amt;
      //     }
      //     if (rowData.type == "D") {
      //       debitAmount = debitAmount + -Math.abs(+rowData.amt);
      //     }
      //     totalAmt = creditAmount + debitAmount;
      //     console.log(totalAmt);
      //     const rowObj = {
      //       sortNo: 1,
      //       mainAccId: fieldData.cashBankId,
      //       amt: rowData.amt,
      //       type: rowData.type,
      //       remark: rowData.remark,
      //       recieptPaymentDetailCcInfoList:
      //         rowData.recieptPaymentDetailCcInfoList,
      //       recieptPaymentDetailBillInfoList: recieptPaymentDetailBillInfoList,
      //     };

      //     return rowObj;
      //   });
      if(+fieldData.amt > 0){
      const dataToSave = {
        ...fieldData,
        recieptPaymentDetailList: [
          {
            sortNo: 1,
            mainAccId: fieldData.mainAccountId,
            amt: fieldData.amt,
            type: "D",
          }
        ],
        id: this.props.editData.id ? this.props.editData.id : "",
        subVoucherMaster: fieldData.subVoucherMaster,
        mainAccountId: fieldData.mainAccountId,
        location: {
          id: location != null ? location : "",
        },
        remark: fieldData.remark,
        voucherAmount: fieldData.amt,
        cashBankId: fieldData.cashBankId,
        paidTo: fieldData.paidTo,
        clearanceDays: fieldData.clearanceDays ? fieldData.clearanceDays : 0,
        passDate: fieldData.passDate
          ? fieldData.passDate.format("DD-MM-YYYY")
          : null,
        drawnOn: fieldData.drawnOn
          ? fieldData.drawnOn.format("DD-MM-YYYY")
          : null,
        chequeNo: fieldData.chequeNo ? fieldData.chequeNo : null,
        chequeDate: fieldData.chequeDate
          ? fieldData.chequeDate.format("DD-MM-YYYY")
          : null,
        contraEntryType: fieldData.contraEntryType,
        paymentMode:fieldData.paymentMode!=null?fieldData.paymentMode:"",
        date: fieldData.date.format("DD-MM-YYYY"),
        status:  this.props.approvalFlag == 0 ? 0 : 1,
        bookType: 5,
      };
      console.log(dataToSave);
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
          // if (catList[0].vouNature == 1 && totalAmt > 0) {
          //   showNotification({
          //     msg: "Voucher Amount must be negative",
          //     severity: "error",
          //   });
          // } else if (catList[0].vouNature == 1 && totalAmt < 0) {
          //   this.onSave(dataToSave);
          // } else if (catList[0].vouNature == 2 && totalAmt < 0) {
          //   showNotification({
          //     msg: "Voucher Amount must be positive",
          //     severity: "error",
          //   });
          // } else if (catList[0].vouNature == 2 && totalAmt > 0) {
          //   this.onSave(dataToSave);
          // }
        }
      });
    }
      // } else {
      //   showNotification({
      //     msg: "Please add atleast one product",
      //     severity: "error",
      //   });
      // }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.recieptPaymentHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (this.props.approvalFlag == 0) {
           
            this.setState({rowList:[],
              fieldData:{}
             })
           
          } else {
           
            this.setState({rowList:[],
              fieldData:{}
             })
             
          }
          showNotification({ msg: savemsg });
          this.props.handleCloses();
        } else {
          showNotification({ msg: data.response.data.errorMessage, severity: "error" });
          this.props.handleCloses();
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    ContraEntryJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.isMandatory&&currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.isMandatory&&currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.isMandatory&&currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.isMandatory&&currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    ContraEntryJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    ContraEntryJSON.fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
       
        if (onSubmitParameter ) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];

          if (dataKeyValue && compareWithValue && dataKeyValue!="" && compareWithValue!="") {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }

                break;
              case "le":
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }

                break;
              case "g":
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }

                break;
              case "ge":
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }

                break;
              case "eq":
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }

                break;
              default:
                break;
            }

            if (isError) {
              const compareWithFieldLabel = ContraEntryJSON.fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    ContraEntryJSON.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData } = this.state;
    console.log(value);
    let debitAmount = 0;
    let creditAmount = 0;
    if (value.type == "C") {
      creditAmount = +value.amt;
    }
    if (value.type == "D") {
      debitAmount = -Math.abs(+value.amt);
    }
    let totalAmount = (creditAmount + debitAmount).toFixed(2);
    console.log(totalAmount);
    return {
      voucherAmount: (creditAmount + debitAmount).toFixed(2),
    };
    // let productList = dynamicMasterData["productDetail"]
    //   ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
    //   : [];
    // return {
    //   rate: productList[0].rate,
    //   mrp: productList[0].mrp,
    //   uom: productList[0].outletUomName,
    //   uomConversionValue: productList[0].uomConversionValue,
    // };
  };

  rowDelete = (rowData) => {
    console.log(rowData);
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        let amt = 0;
        const row = rowListData.map((rowListDataObj, index) => {
          amt =
            rowListDataObj.type == "C"
              ? amt + +rowListDataObj.amt
              : amt - +rowListDataObj.amt;
          return { ...rowListDataObj, index: index + 1 };
        });
        amt = rowData.type == "C" ? amt + +rowData.amt : amt - +rowData.amt;
        cosole.lg(rowData.type == "C" ? amt + +rowData.amt : amt - +rowData.amt)
        this.setState({
          rowList: row,
          fieldData: {
            ...this.state.fieldData,
            voucherAmount: amt,
          },
        });
      }
    });
  };

  rowCopy = (rowData) => {
    const { openCostCenter } = this.state;
    console.log(rowData);
    this.setState({
      openCostCenter: !openCostCenter,
      headerData: rowData,
    });
    console.log(rowData);
  };

  rowTransfer = (rowData) => {
    const { openBillMapping } = this.state;
    this.setState({
      openBillMapping: !openBillMapping,
      headerData: rowData,
    });
    console.log(rowData);
  };

  OnSaveCostCenter = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    console.log(headerId);
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailCcInfoList: dataToSave,
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  onSaveBillMapping = (dataToSave) => {
    const { openCostCenter } = this.state;
    console.log(dataToSave);
    this.setState({
      recieptPaymentDetailBillInfoList: dataToSave,
      openBillMapping: !openBillMapping,
    });
  };

  goBackToList = () => {
    if (this.props.approvalFlag == 0) {
      this.props.navigate("/contra-entry");
    } else {
      this.props.navigate("/contra-entry-approval");
    }
  };
  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      openCostCenter,
      headerData,
    } = this.state;
    const {rowReset} =this.props
 
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicTransactionForm
          subTitle={fieldData.documentNo ? fieldData.documentNo : "-"}
          amount={fieldData.voucherAmount ? fieldData.voucherAmount : "0"}
            getListById={this.getListById}
            dynamicMasterData={{
              ...dynamicMasterData,
              creditAccountDetails:
                dynamicMasterData.creditAccountDetails.filter(
                  (row) => row.id != fieldData.cashBankId
                ),
            }}
            screenTitle={ContraEntryJSON.screenTitle}
            fieldMeta={ContraEntryJSON.fieldMeta.map((rowData) => {
              return { ...rowData, label: rowData.dataKey == 'cashBankId' && fieldData.contraEntryType == 1 ? 'From Bank Account' : rowData.dataKey == 'cashBankId' && fieldData.contraEntryType == 2 ? 'Cash A/C' :rowData.dataKey == 'mainAccountId' && fieldData.contraEntryType == 1 ? 'To Bank Account' : rowData.dataKey == 'mainAccountId' && fieldData.contraEntryType == 2 ? 'Bank A/C' : rowData.label }
            })}
            apiBaseURL={ContraEntryJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={ContraEntryJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            backToList={true}
            goBackToList={this.goBackToList}
          />
          {/* <br />
          <Divider sx={{ borderWidth: 1, color: "#000000" }} />
          <br /> */}
          {/* <LabelCompo
            className="text-black"
            style={{ fontSize: "20px", color: DarkBlue }}
            label={"Add Product"}
          /> */}
          {/* <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            sx={{ paddingTop: 2 }}
          > */}
          {/* <DynamicFormWithoutSave
            showSaveBtn={true}
            showSaveBtnMain={false}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={ContraEntryJSON.searchList}
            showTitle={ContraEntryJSON.showTitle}
            screenTitle={ContraEntryJSON.screenTitle}
            fieldMeta={ContraEntryJSON.fieldMetaDetails}
            showCancel={ContraEntryJSON.showCancel}
            showBackToList={ContraEntryJSON.showBackToList}
            apiBaseURL={ContraEntryJSON.apiBaseURL}
            showSaveNextBtn={ContraEntryJSON.showSaveNextBtn}
            saveBtnText={"ADD"}
            onSave={this.onSearch}
            resetAfterSave={true}
            getDataForDisabled={this.getDataForDisabled}
          /> */}
          {/* </Grid> */}

          {/* <br />
          <RTTable
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "mainAccountCode", title: "ACCD" },
              { name: "mainAccountName", title: "Account Head" },
              { name: "amt", title: "Amount", align: "right" },
              { name: "type", title: "Type", align: "center" },
              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            isActionColActive={true}
            showEditIcon={false}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
            showCopyIcon={true}
            rowCopy={this.rowCopy}
            showTransferButtonInTable={false}
            rowTransfer={this.rowTransfer}
          />
          <br />
          <DynamicTransactionForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={ContraEntryJSON.screenTitle}
            fieldMeta={ContraEntryJSON.fieldMeta1}
            apiBaseURL={ContraEntryJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={ContraEntryJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showTitle={false}
            getDataForDisabled={this.getDataForDisabled}
          />
          <br /> */}
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name={'Submit'}
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
        {openCostCenter && (
          <CostCenterMappingDialouge
            open={openCostCenter}
            handleCloses={this.rowCopy}
            dynamicMasterData={dynamicMasterData}
            onSave={this.OnSaveCostCenter}
            dataToSave={headerData}
          />
        )}
        {/* {openBillMapping && (
          <BillMappingDialouge
            open={openBillMapping}
            handleCloses={this.rowTransfer}
            dynamicMasterData={dynamicMasterData}
            onSave={this.onSaveBillMapping}
            dataToSave={headerData}
          />
        )} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  subVoucherList: state.subVoucher,
  cashRecieptList: state.cashReciept,
  payList:state.payMode,
  profitCenter: state.profitCenter,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getAccountLedgerListByType1,
  getCashRecieptWithAccNameById,
  getCostCenter,
  getPayMode,
  getLedgerDataByAccId,
  getProfitCenter,
  //   getProductByCategoryForStakeHolder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ContraEntryFormForReport);
