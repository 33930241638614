import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LabelCompo } from './Comman/Label';
import { primaryColor, titleColor, white } from '../config/ColorObj';
import { connect } from "react-redux";
import { setModuleNameData } from "../Slice/accessRole.slice";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { MyComponentWithIconProps } from './Comman/IconButton';
import { useNavigate } from "react-router";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



const DrawerTab = ({
  setModuleNameData
}) => {
  
  const scrollContainerRef = React.useRef(null);
  const navigate = useNavigate();

  const handleScroll = (direction) => {
    const scrollAmount = 70; // Adjust this value as needed
    const container = scrollContainerRef.current;

    if (container) {
      if (direction === 'left') {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    }
  };
  const [menuList, setMenuList] = React.useState([]);
  const [menuUpdatedList, setMenuUpdatedList] = React.useState([]);
  const handleClick = (itemData, toggle) => {
    setModuleNameData({ name:  itemData.moduleName })
    if( itemData.moduleName=='Dashboard'){
      navigate(itemData.subModuleList[0].subModuleMapping)
    }
    
    const rows = menuList.map((module) => {

      if (module.moduleName === itemData.moduleName) {
        localStorage.setItem("moduleName", itemData.moduleName);
        module = {
          ...module,
          checked: true,
        };
      } else {
        module = {
          ...module,
          checked: false
        };
      }
      return module;
    });
    setMenuList(rows);
  };
  React.useEffect(() => {
    const moduleSubModuleList = localStorage.getItem("moduleList");
    if (moduleSubModuleList) {
      const valuesArray = JSON.parse(moduleSubModuleList);
      // setMenuList(valuesArray);
      let row = [];
      let moduleName = localStorage.getItem("moduleName");
      valuesArray.map((data1, index) => {

        let bData = {
          baseClassName: data1.baseClassName,
          checked: data1.checked,
          className: data1.className,
          color: data1.color,
          delStatus: data1.delStatus,
          iconDiv: data1.iconDiv,
          moduleDesc: data1.moduleDesc,
          moduleId: data1.moduleId,
          moduleName: data1.moduleName,
          orderBy: data1.orderBy,
          subModuleList: data1.subModuleList.map((data2) => {
            let bData = {
              moduleId: data1.moduleId,
              addApproveConfig: data2.addApproveConfig,
              checked: data2.checked,
              deleteRejectApprove: data2.deleteRejectApprove,
              editReject: data2.editReject,
              isDelete: data2.isDelete,
              orderBy: data2.orderBy,
              subModuleDesc: data2.subModuleDesc,
              subModuleId: data2.subModuleId,
              subModuleMapping: data2.subModuleMapping,
              subModuleName: data2.subModuleName,
              type: data2.type,
              view: data2.view,
            };
            return bData;
          }),
        };
        if (moduleName == null && bData.moduleName == 'Dashboard') {
          setModuleNameData({ name: bData.moduleName })
          bData = {
            ...bData,
            checked: true,
          }
        } else {
          if (moduleName != null) {
          setModuleNameData({ name: moduleName })
          }
          bData = {
            ...bData,
            checked: moduleName == bData.moduleName
          }
        }

        row.push(bData);
        return data1;
      });
      setMenuList(row);
      setMenuUpdatedList(row);

      if (moduleName != null) {

      }

    }

 
  }, []);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: "row" }}>
        <div>
          <MyComponentWithIconProps
            statusImage={ArrowLeftIcon}
            color="#fff"
            title=""
            fontSize="large"
            onClick={() => handleScroll('left')}
          />
        </div>

        <div
          ref={scrollContainerRef}
          style={{
            display:"flex",
            width: '100%',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            height:"34px",
          }}
        >

          {
            menuList.map((rowData) => {
              return (
                <>
                  <LabelCompo
                    // className="text-black"
                    onClick={() => handleClick(rowData, false)}
                    style={{cursor: 'pointer', borderRadius: "3px 3px 0px 0px", padding: "6px 10px",  fontSize: "14px", backgroundColor: rowData.checked ? white : primaryColor, color: rowData.checked ? primaryColor : white, fontWeight: rowData.checked ? 600 : 400  }}
                    label={rowData.moduleName}
                  />

                </>
              )
            })
          }
        </div>
        <div>
          <MyComponentWithIconProps
            statusImage={ArrowRightIcon}
            color="#fff"
            title=""
            fontSize="large"
            onClick={() => handleScroll('right')}
          />
        </div>

      </div>
    </>

  );
}
const mapStateToProps = (state) => ({
  common: state.common,
  hrData: state.hrDetails,
  departmentList: state.department,
  parentChildList: state.parentProfile,
});

const mapDispatchToProps = {
  setModuleNameData
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerTab);