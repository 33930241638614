import { withStyles } from "@mui/styles";
import { React } from "react";
import { blackColor, cyanBlue } from "../../config/ColorObj";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    // border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    // padding: "8px",
    // border: "1px solid #ddd",
  },
});

const BasicTable = ({ row, tableHead,title, changeHandler,classes }) => {
  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
        <tr>
                        {
                            tableHead.map((head) => {
                                return (<th style={{fontSize:20 }}className={classes.MuiTableHeadRoot} > &nbsp; {head.title}</th>)
                            })
                        } </tr>
          {

row.map((data)=>{
  return(
<>
{
    title.map((titleData, index) => {
      if (index % 2 === 0) {
        return (
          <tr >
            {titleData.map((previewRow) => {
              
              return (
                <>
                  
                  <td
                    style={{ color: blackColor ,fontSize:20,...previewRow.style}}
                    className={classes.MuiTableHeadRoot}
                    colSpan={previewRow.colSpan}
                    
                  >
                   &nbsp;  {previewRow.clickeble?
                      <div
                     
                      style={{
                        
                          color: cyanBlue,
                          cursor: "pointer",
                      }}
                  >
                      {  "sdfs"+data[previewRow.name]}
                  </div>:
                  data[previewRow.name]
                    }
                    
                  </td>
                </>
              );
            })}
          </tr>
        );
      }
     else{
        return (
          <tr >
            {titleData.map((previewRow) => {
              return (
                <>
                  
                  <td
                    style={{ color: blackColor ,fontSize:20,...previewRow.style}}
                    className={classes.MuiTableHeadRoot}
                  >
                    &nbsp; {previewRow.clickeble?
                      <div
                      onClick={(e) =>
                          changeHandler(data,previewRow.name)
                      }
                      style={{
                          color: cyanBlue,
                          cursor: "pointer",
                      }}
                  >
                      { "sdfs"+ data[previewRow.name]}
                  </div>:
                  data[previewRow.name]
                    }
                  </td>
                </>
              );
            })}
          </tr>
        );
      }
    })}
</>
     
  )
})}
         
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(BasicTable);
