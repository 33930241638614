export const styles = (theme) => ({
    MuiTable: {
        borderCollapse: "collapse",
        borderSpacing: 0,
        width: "100%",
         border: "1px solid #c1c1c1",
    },
    
    MuiTableWithoutBorder: {
        borderCollapse: "collapse",
        borderSpacing: 0,
        width: "100%",
      
    },
    MuiTableHeadRoot: {
        textAlign: "left",
        // padding: "8px",
        border: "1px solid #c1c1c1",
    },
    MuiTableHeadCell: {
        textTransform:"uppercase",
        fontWeight: 600 ,
        paddingTop:10,
        paddingBottom:10,
        borderRight: "1px solid #c1c1c1",
        fontSize: 14 
    },
    MuiTableBodyCell: {
        padding:5,
        borderRight: "1px solid #c1c1c1",
        fontSize: 14 ,
        verticalAlign:"top"
    },
    MuiTableBodyCellNew: {
        padding:5,
        fontSize: 14 ,
        verticalAlign:"top"
    },
    MuiTableBodyCellWithoutBorder: {
        padding:5,
        fontSize: 14 ,
        verticalAlign:"top"
    },
    borderBottom: {
        borderRight: "1px solid #c1c1c1",
    },
    borderTop: "1px solid #c1c1c1"
});

export const border="1px solid #c1c1c1"
export const fontWeight=600