import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const token = localStorage.getItem("token");
  if (token == null || location.pathname === "/") {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // const moduleSubModuleList = localStorage.getItem("moduleSubModule");
  // if (moduleSubModuleList && token != null && location.pathname !== "/" && location.pathname != "/errorPage") {
  //   const moduleList = JSON.parse(moduleSubModuleList);
  //   let moduleData = []
  //   moduleList.map((module) => {
  //     const filterData = module.moduleSubs.filter((row) => row.subModuleMapping == location.pathname)
  //     if (filterData.length != 0) {
  //       moduleData.push(filterData)
  //     }
  //   })
  //   if (moduleData.length == 0) {
  //     return <Navigate to="/errorPage" state={{ from: location }} />;
  //   }
  // }

  return children;
};

export default PrivateRoute;
