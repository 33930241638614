import {
  Grid,
  Paper
} from "@mui/material";
import React from "react";
import swal from "sweetalert";

import { connect } from "react-redux";
import { billBookJSON } from "../../DynamicFormsJson/Transaction/billBook";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { ValidationCheck, mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg
} from "../../config/messageconstant";

import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BillBookTable from "./BillBookTable";
// import PaymentModeDialog from "./PaymentModeDialog";
class BillBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      dynamicMasterData: {
        tax: [],
        VendorDetails: [],
      },
      formErrors: {},
      fieldData: {},
      mainData: {},
    };
  }

  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      const locationId = localStorage.getItem("location");
      await showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.mrnHead +
          "/get-apis-for-add-bill-book?locationId="+locationId,
      }).then(({ data, success }) => {
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            dynamicMasterData: {
              tax: data.data.tax.map((row) => {
                return { ...row, name: row.hsnCode };
              }),
              VendorDetails: data.data.vendor,
            },
          });
        }
        return success;
      });
      showLoador({ loador: false });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearch = async (data) => {
    const { showLoador, showNotification } = this.props;
    const locationId = localStorage.getItem("location");
    if (navigator.onLine) {
      await showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.mrnHead +
          "/get-mrn-pending-for-bill-book-by-vendor?locationId="+locationId+"&vendorId=" +
          data.vendor,
      }).then(({ data, success }) => {
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            ...this.state,
            mainData: data.data,
            rows: data.data.mrnDetail.map((row, index) => {
              let product = row.product != null ? row.product : "";
              if (product != "") {
                let cgstPer = row.product.cgstPer;
                let sgstPer = row.product.sgstPer;
                let igstPer = row.product.igstPer;
                let amt = row.mrnRate * row.billQty;
                const cgstAmount = ((amt * cgstPer) / 100).toFixed(2);
                const sgstAmount = ((amt * sgstPer) / 100).toFixed(2);
                const igstAmount = ((amt * igstPer) / 100).toFixed(2);
                const amount = (amt + (+cgstAmount + +sgstAmount)).toFixed(2)
                return {
                  ...row,
                  productName: row.product.name,
                  index: index + 1,
                  cgstPer: cgstPer,
                  amount:amount,
                  sgstPer: sgstPer,
                  igstPer: igstPer,
                  cgstAmount: cgstAmount,
                  sgstAmount: sgstAmount,
                  igstAmount: igstAmount,
                  taxId: row.product.taxId,
                };
              }
            }),
          });
        }
        return success;
      });
      showLoador({ loador: false });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;
    const metaData = billBookJSON.fieldMeta;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: metaData,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: metaData,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  onFormSave = () => {
    if (this.checkValidationOnSubmit()) {
      this.onSearch(this.state.fieldData);
    }
  };

  saveData = () => {
    const { dynamicMasterData,rows, mainData,fieldData } = this.state;
    const locationId = localStorage.getItem("location");
   
    let vendorInfo=dynamicMasterData.VendorDetails.find((vendor)=>vendor.id==fieldData.vendor)
      let storeId = [];
    let qcMrnHeaderId = mainData.mrnHeaderAndStoreId.map((rowInfo) => {
      storeId.push(rowInfo.storeId);
      return rowInfo.qcMrnHeaderId;
    });
    let error=false
    let purchaseSalesVoucherDetailList = rows.map((rowObj) => {
    if(rowObj.mrnRate==""&&rowObj.mrnRate==0){
      error=true
    }
    console.log(rowObj)
      return {
        ...rowObj,
        recivedQty: rowObj.receivedQty,
        rejectedQty: rowObj.rejectQty,
        qty: rowObj.billQty,
        rate: rowObj.mrnRate,
        itemCode:rowObj.product!=null?rowObj.product.code:"",
      };
    });
    let dataToSave = {
      accountLedgerCode:vendorInfo&& vendorInfo.code?vendorInfo.code:"",
      text1:vendorInfo&& vendorInfo.stateId?vendorInfo.stateId:"",
      locationId: locationId,
      text2: storeId.join(","),
      text3: qcMrnHeaderId.join(","),
      entryType: 2,
      purchaseSalesVoucherDetailList: purchaseSalesVoucherDetailList,
    };
    console.log(dataToSave)
    if(!error){
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
         
          this.onSave(dataToSave);
        }
      });
   
    }else{
      this.props.showNotification({ msg: 'Rate should be entered', severity: "error" });
    }
   
  };

  onSave = async (objectToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      await showLoador({ loador: true });
      apiPost({
        url: endpoint.purchaseSaleHeader + "/bill-book-save",
        postBody: objectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg, severity: "success" });
          // window.location.replace("/sale-purchase-settle");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDataChange = (fieldName, newValue) => {
    const dataToReset = { ...this.state.fieldData };
    let vendor=  this.state.dynamicMasterData.VendorDetails.find((taxRow)=>taxRow.id==newValue)

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: {...dataToReset,
      ...vendor},
    });
  };
  setRow = (rowList) => {
    this.setState({
      rows: rowList,
    });
  };
  render() {
    const {
      dynamicMasterData,

      rows,
      formErrors,
      fieldData,
    } = this.state;
    const { salesPurchase } = this.props;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={billBookJSON.showTitle}
            screenTitle={billBookJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
          />
          <br />

          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            searchList={billBookJSON.searchList}
            showSaveBtn={billBookJSON.showSaveBtn}
            showTitle={false}
            screenTitle={billBookJSON.screenTitle}
            fieldMeta={billBookJSON.fieldMeta}
            showCancel={billBookJSON.showCancel}
            apiBaseURL={billBookJSON.apiBaseURL}
            showSaveNextBtn={billBookJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            onFormSave={this.onFormSave}
            fieldData={fieldData}
            formErrors={formErrors}
            onDataChange={this.onDataChange}
            disableCondition={
              salesPurchase?.salesPurchaseList.filter((row) => row.checked)
                .length != 0
                ? true
                : false
            }
          />
          {
            fieldData.code&&
            <>
              <DynamicDetails DataToSet={[
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Vendor Name ",
          value:fieldData.code + " - "+fieldData.name,
        },
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Contact No. ",
          value:fieldData.contactNo,
        },
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Email",
          value:fieldData.email,
        },
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Contact Person Name",
          value:fieldData.contactPersonName,
        },
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Contact Person No",
          value:fieldData.contactPersonNo,
        }]} />
            </>
          }
        

         
{
  rows.length!=0&&<>
   <BillBookTable
            taxList={dynamicMasterData.tax}
            rows={rows}
            setRow={this.setRow}
          />
  <br />

<Grid
  item
  sm={12}
  xs={12}
  md={12}
  lg={12}
  rowSpacing={2}
  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
  container
  justifyContent="right"
>
  <ButtonCompo
    size="medium"
    type="submit"
    variant="contained"
    name="Submit"
    onClick={this.saveData}
  />
</Grid></>
}
          
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  salesPurchase: state.salesPurchase,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(BillBook);
