import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BankRecReportoJson } from "../../DynamicFormsJson/Report/BankRecoReport";
import { getAccountLedgerListByType } from '../../Slice/subVoucherDetail.slice';
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BankRecoReportTable from "./BankRecoReportTable";
const BankRecoReport = ({ showLoador,getAccountLedgerListByType, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState([]);

  useEffect(() => {
    getDropDownList()
  }, []);
  
  const getDropDownList = async () => {
    showLoador({ loador: true });
    const accountLedgerGroupMasterDetailForType1 = await getAccountLedgerListByType({
      accType: 2,
    }).then(({ response, success }) => {
      if (success) {
        return response.map((rowData) => {
          showLoador({ loador: false });
          return { ...rowData, id: rowData.id1 }
        })
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return []
    });
    showLoador({ loador: true });
    const yearMasterDetail = await apiGet({
        url: endpoints.year,
    }).then(({ data, success }) => {
        if (success) {
            showLoador({ loador: false });
            let rowData = data.data;


            return rowData.map((rowData) => {
                return { ...rowData, name: rowData.yearName };
            })
        }
        else {
            showLoador({ loador: false });
            showNotification({
                msg: serverMsg,
                severity: "error",
            });
        }
        return []
    });
    setDynamicMasterData({
      accountLedgerGroupMasterDetail: accountLedgerGroupMasterDetailForType1,
      yearMasterDetail: yearMasterDetail
    });
  }
 
  const getList = (monthYear, accountLedgerGroupsId) => {
    let location =  localStorage.getItem("location");
   
    showLoador({ loador: true });
    apiGet({

       url:endpoints.getRecieptPaymentHeaderList+"/get-payment-reciept-by-month-year?locationId="+location+"&accountLedgerId="+accountLedgerGroupsId+"&monthYear="+monthYear,
    
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        setCashbookList(data.data != null ? data.data : [])
      } else {
        setCashbookList({})
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }

    });
  }

  const onSave = (dataToSearch) => {
    
    let account = dataToSearch.accountLedgerGroupsId.split(",")
    let filteraccountLedgerGroupMaster = []
    dynamicMasterData["accountLedgerGroupMasterDetail"].map((row) => {
     
      account.map((accData) => {
        if (row.id === accData) {
          filteraccountLedgerGroupMaster.push(row.name)
        }
      })     
    })
  setFormData({
    ...dataToSearch,
    accName: filteraccountLedgerGroupMaster.join(),
  })
  getList(dataToSearch.monthYear.format("MM")+"-"+dataToSearch.monthYear.format("YYYY"), dataToSearch.accountLedgerGroupsId )
}
const onSearch = (searchV) => {
  setSearchValue(searchV)
};

const generatePDFMethod = () => {
 
 
  var columns = [["Date","Account Head", "Cheq No", "Pass Date", "Amount"]];
 
  let tableList = []
  let date = ""
  let accountLedgerName = ""
  let chequeNo=""
  let passDate=""
  let voucherAmount = ""
  let Credit = ""
  let tableData=[]
    let debitTotal = 0
  
    if( cashList.length!=0){
  tableData.push("Cheques Deposit But Not Cleared")
  tableData.push("")
  tableData.push("")
  tableData.push("")
  tableData.push("")
  tableList.push(tableData)
  cashList.map(((rowData, index) => {
    
   

    debitTotal = +debitTotal + +rowData.voucherAmount;
   let date = rowData.date!=null?rowData.date: ""+"\n" 
   let voucherAmount = rowData.voucherAmount!=null?rowData.voucherAmount: ""+"\n" 
   
    passDate =rowData.passDate!=null?rowData.passDate:"//" +"\n" 
    chequeNo = rowData.chequeNo!=null?rowData.chequeNo: "" +"\n" 
    accountLedgerName = rowData.locationName!=null?rowData.locationName: "" +"\n" 
    tableData = [];
    tableData.push(date )
   
    tableData.push(accountLedgerName)
    tableData.push(chequeNo)
    tableData.push(passDate )
    tableData.push(voucherAmount )
    tableList.push(tableData)
  }))
  
  

  //  date = ""
  //  accountLedgerName = ""
  //  chequeNo=""
  //  passDate=""
  //  voucherAmount = ""
  //  Credit = ""
  //  debitTotal = 0
  //  tableData=[]
  // cashList.map(((rowData, index) => {
   

  //   debitTotal = +debitTotal + +rowData.voucherAmount;
  //   date = date=="" && rowData.date!=null?date + rowData.date: date+"\n" 
  //   voucherAmount = voucherAmount==""  && rowData.voucherAmount!=null?voucherAmount + rowData.voucherAmount: voucherAmount+"\n" 
   
  //   passDate = passDate=="" && rowData.passDate!=null?passDate + rowData.passDate: passDate +"\n" 
  //   chequeNo = chequeNo==""&& rowData.chequeNo!=null?chequeNo + rowData.chequeNo: chequeNo +"\n" 
  //   accountLedgerName = accountLedgerName=="" && rowData.accountLedgerName!=null?accountLedgerName + rowData.accountLedgerName: accountLedgerName +"\n" 
  // }))
  // tableData = []
    
  // tableData.push(date )
 
  // tableData.push(accountLedgerName)
  // tableData.push(chequeNo)
  // tableData.push(passDate )
  // tableData.push(voucherAmount )
  // tableList.push(tableData)
  // tableData = []
    
  // tableData.push("" )
 
  // tableData.push("")
  // tableData.push("")
  // tableData.push("" )
  // tableData.push(debitTotal )
  // tableList.push(tableData)
  tableData = []
    
  tableData.push("Total" )
 
  tableData.push("")
  tableData.push("")
  tableData.push("" )
  tableData.push(debitTotal )
  tableList.push(tableData)
}
  const columnStyles = {
    0: { cellWidth: 150 },
    1: { cellWidth: 150 },
    2: { cellWidth: 90 },
  };
  const columnStylesPlain = {
    0: { cellWidth: 80 },
    1: { cellWidth: 150 },
    2: { cellWidth: 90 },
  };
  generatePDF({
    columnStylesPlain: columnStylesPlain,
    rows: tableList,
    columns,
    columnStyles: columnStyles,
    headerList: [
      {
        label: "Month-Year",
        value:formData.monthYear.format("MMM")+"-"+formData.monthYear.format("YYYY")
      },
      {
        label: "Account Name",
        value: formData.accName
      },
    ],
    pdfTitle: BankRecReportoJson.screenTitle,
  });

};
// const generateExcelMethod = () => {
//  var columns = ["Date","Account Head", "Cheq No", "Pass Date", "Amount"];
//   let alignList = [];
  
//   let tableList = []
//   let date = ""
//   let accountLedgerName = ""
//   let chequeNo=""
//   let passDate=""
//   let voucherAmount = ""
//   let Credit = ""
//   let tableData=[]
//     let debitTotal = 0
  
  
//   tableData.push("Cheques Deposit But Not Cleared")
//   tableData.push("")
//   tableData.push("")
//   tableData.push("")
//   tableData.push("")
//   tableList.push(tableData)
//   cashList.map(((rowData, index) => {
    
   

//     debitTotal = +debitTotal + +rowData.voucherAmount;
//    let date = rowData.date!=null?rowData.date: ""+"\n" 
//    let voucherAmount = rowData.voucherAmount!=null?rowData.voucherAmount: ""+"\n" 
   
//     passDate =rowData.passDate!=null?rowData.passDate:"//" +"\n" 
//     chequeNo = rowData.chequeNo!=null?rowData.chequeNo: "" +"\n" 
//     accountLedgerName = rowData.locationName!=null?rowData.locationName: "" +"\n" 
//     tableData = [];
//     tableData.push(date )
   
//     tableData.push(accountLedgerName)
//     tableData.push(chequeNo)
//     tableData.push(passDate )
//     tableData.push(voucherAmount )
//     tableList.push(tableData)
//   }))
  
  

//   tableData = []
    
//   tableData.push("Total" )
 
//   tableData.push("")
//   tableData.push("")
//   tableData.push("" )
//   tableData.push(debitTotal )
//   tableList.push(tableData)
//   generateExcel({
//     columns,
//     tableList,
//     alignList,
// headerList: ["Date Range ", formData.fromDate + " To " + formData.toDate,"Account Name", formData.accName

//     ],
//     title: BankRecReportoJson.screenTitle,
//   });

// };


const generateExcelMethod = () => {
    
  var columns = ["Date","Account Head", "Cheq No", "Pass Date", "Amount"];
  let alignList = [];

  let rows = []
  let tableList = []
  let date = ""
  let accountLedgerName = ""
  let chequeNo=""
  let passDate=""
  let voucherAmount = ""
  let Credit = ""
  let tableData=[]
    let debitTotal = 0
    if( cashList.length!=0){
  tableData.push("Cheques Deposit But Not Cleared")
  tableData.push("")
  tableData.push("")
  tableData.push("")
  tableData.push("")
  rows.push(tableData)
 
   cashList.map(((rowData, index) => {
    
   

    debitTotal = +debitTotal + +rowData.voucherAmount;
   let date = rowData.date!=null?rowData.date: ""+"\n" 
   let voucherAmount = rowData.voucherAmount!=null?rowData.voucherAmount: ""+"\n" 
   
    passDate =rowData.passDate!=null?rowData.passDate:"//" +"\n" 
    chequeNo = rowData.chequeNo!=null?rowData.chequeNo: "" +"\n" 
    accountLedgerName = rowData.locationName!=null?rowData.locationName: "" +"\n" 
    tableData = [];
    tableData.push(date )
   
    tableData.push(accountLedgerName)
    tableData.push(chequeNo)
    tableData.push(passDate )
    tableData.push(voucherAmount )
    rows.push(tableData)
  }))
  
  

  tableData = []
    
  tableData.push("Total" )
 
  tableData.push("")
  tableData.push("")
  tableData.push("" )
  tableData.push(debitTotal )
  rows.push(tableData)
    }
  generateExcel({
    columns,
    rows,
    alignList,
    headerList: ["Month-Year ",formData!=null && formData.monthYear!=null?formData.monthYear.format("MMM")+"-"+formData.monthYear.format("YYYY"):"","Account Name", formData!=null?formData.accName:""

  ],
  title: BankRecReportoJson.screenTitle,
});

 

};

const getFilteredTableData = (tableData) => {

  let listData = tableData.filter((row) => {

    let isValid = false;

    if (row.groupName.toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase())
    ) {
      isValid = true;
    }


    return isValid;

  });
  return listData
};
const onDataChange = (fieldName, newValue) => {
  const { fieldMeta = [], getDataForDisabled, getDataForCalculate } = this.props;
  const { fieldData } = this.state;
  const dataToSearch = {};
  let dataToReset = { ...this.state.fieldData };
  let listError = false;
  BankRecReportoJson.fieldMeta.map((currentField) => {
    if (currentField.dataKey === fieldName && currentField.getData) {
      const dataStatic = getDataForDisabled(newValue);
      dataToReset = {
        ...dataToReset,
        ...dataStatic,
      };
    }
    if (currentField.dataKey === fieldName && currentField.calculateMinus) {
      const dataStatic = getDataForCalculate(newValue, dataToReset);
      dataToReset = {
        ...dataToReset,
        ...dataStatic,
      };
    }
    if (currentField.dataKey === fieldName && currentField.getListId) {
      // dataToSearch[fieldName] = newValue;
      const masterKeyList = currentField.getListId.split(",");
      masterKeyList.map((key) => {
        if (fieldName === key) {
          dataToSearch[key] = newValue;
        } else if (fieldData[key]) {
          dataToSearch[key] = fieldData[key];
        }
        return null;
      });

      this.props.getListById(dataToSearch);
    }

    if (currentField.getListFrom) {
      const masterKeyList = currentField.getListFrom.split(",");
      console.log(currentField.getListFrom);
      masterKeyList.map((key) => {
        if (key == fieldName && dataToReset[key]) {
          delete dataToReset[currentField.dataKey];
        }
      });
    }
    if (
      !listError &&
      fieldData[currentField.dataKey] &&
      !currentField.disableTrue &&
      currentField.getListFrom == undefined
    ) {
      dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
    }
    return null;
  });

  dataToReset[fieldName] = newValue;
  this.setState({
    fieldData: dataToReset,
  });
};
return (
  <>
    <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

      <DynamicFormWithoutSave
        dynamicMasterData={dynamicMasterData}
        showBackToList={false}
        DataToSet={[]}
        showSaveBtn={BankRecReportoJson.showSaveBtn}
        screenTitle={BankRecReportoJson.screenTitle}
        fieldMeta={BankRecReportoJson.fieldMeta}
        showCancel={BankRecReportoJson.showCancel}
        apiBaseURL={BankRecReportoJson.apiBaseURL}
        showSaveNextBtn={BankRecReportoJson.showSaveNextBtn}
        showSaveBtnMain={true}
        padding={false}
        generatePDF={generatePDFMethod}
        generateExcel={generateExcelMethod}
        searchName=""
        onSearch={onSearch}
        saveBtnText={"Search"}
        paddingTop={false}
        onSave={onSave}
        onDataChange={onDataChange}
        showExcelDownload={true}
        
      />
      <br />

      <BankRecoReportTable
        tableHead={[
          {
            title: "Date",

            align: "center",
          },
          {
            title: "Account Head",
            align: "left",
          },
          {
            title: "Cheq No",
            align: "center",
          },
          {
            title: "Pass Date",
            align: "center",
          },
          {
            title: "Amount",
            align: "right",
          },
        ]}
        rowList={cashList}
      />
    </Paper>
  </>
);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador,getAccountLedgerListByType, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(BankRecoReport);
