import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    profitCenter: [],
};
let URL = endpoints.profitCenter;
const profitCenterSlice = createSlice({
  name: "profitCenter",
  initialState,
  reducers: {
    profitCenterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        profitCenter: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { profitCenterSuccess, resetState } = profitCenterSlice.actions;

export default profitCenterSlice.reducer;

export const getProfitCenter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+"/get-profit-center-by-location?locationId=7f11afa5-49b9-40e1-b9cb-6b407d04eb6a",
    }).then(({ data, success }) => {
      if (success) {
       const content=data.data;
      
        // const row = data.data.length!=0?data.data.map((payListObject, index) => {
        //   let payData = {
        //     index: index + 1,
        //     id: payListObject.id === null ? "" : payListObject.id,
        //     name: payListObject.name === null ? "" : payListObject.name,
        //     payName:
        //     payListObject.name === null ? "" : payListObject.name,
            
        //   };
        //   return payData;
        // }):[];
        dispatch(profitCenterSuccess({ row:content }));
        return { response: content, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
