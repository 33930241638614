import InfoIcon from "@mui/icons-material/Info";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { compose } from "@reduxjs/toolkit";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import dayjs from "dayjs";

import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import MultipleSelect from "../Comman/MultipleSelect";
import { TextFieldCompo } from "../Comman/TextFieldCompo";
import TimePickerCompo from "../Comman/TimePicker";
import { withRouter } from "../withRouter";
import { ButtonCompo } from "../Comman/Button";
import {
  blackColor,
  titleColor,
  redColor,
  greyColor,
} from "../../config/ColorObj";
class DynamicFormWithoutValidation extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  onAutocompleteChange = (dataKey, isRootLevelKey) => (name, newValue) => {
    if (newValue != null) {
      let value = "";
      if (isRootLevelKey) {
        value = newValue;
      } else {
        value = {
          id: newValue,
        };
      }
      this.props.onDataChange(dataKey, value);
    } else {
      let value = "";
      this.props.onDataChange(dataKey, value);
    }
  };

  onMultiSelectChange = (dataKey, masterName) => (value, name) => {
    const { dynamicMasterData } = this.props;

    let dataValue = typeof value === "string" ? value.split(",") : value;
  
    this.props.onDataChange(dataKey, dataValue);
  };

  onTextFieldDataChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey) => (date) => {
    // date.setHours(0, 0, 0, 0);
    this.props.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  render() {
    const {
      showCancel = false,
      showSaveBtn = false,
      showSaveBtnMain = true,
      showTitle = true,
      fieldMeta = [],
      dynamicMasterData = {},
      showAddButton = false,
      screenTitle = "",
      DataToSet = [],
      showBackToList = false,
      showTable = false,
      paddingTop = false,
      padding = false,
      formErrors,
      fieldData,
      onFormSave,
      GridSave = 0,
      saveBtnText = "",
      disableCondition,
    } = this.props;

    return (
      <>
        <Paper
          sx={{
            p: padding ? 2 : 0,
            pt: paddingTop ? 2 : 0,
            borderRadius: 2,
          }}
          elevation={0}
        >
          {showTitle && (
            <LandingScreenHeader
              screenTitle={screenTitle}
              showTable={showTable}
              showBackButton={showBackToList}
              showAddButton={showAddButton}
              onCancel={this.onCancel}
            />
          )}
          {showTitle && <br />}

          {DataToSet.length !== 0 && (
            <>
              <Grid container sx={{ margin: 1 }} spacing={1}>
                {DataToSet.map((dropDownData) => (
                  <Grid
                    container
                    xs={dropDownData.xs}
                    sm={dropDownData.sm}
                    md={dropDownData.md}
                    lg={dropDownData.lg}
                  >
                    <Grid
                      item
                      xs={dropDownData.labelXS ? dropDownData.labelXS : 4}
                      sm={dropDownData.labelSM ? dropDownData.labelSM : 4}
                      md={dropDownData.labelMD ? dropDownData.labelMD : 4}
                      lg={dropDownData.labelLG ? dropDownData.labelLG : 4}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 14, color: titleColor }}
                        label={dropDownData.label + ":"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={dropDownData.valueXS ? dropDownData.valueXS : 8}
                      sm={dropDownData.valueSM ? dropDownData.valueSM : 8}
                      md={dropDownData.valueMD ? dropDownData.valueMD : 8}
                      lg={dropDownData.valueLG ? dropDownData.valueLG : 8}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={dropDownData.value}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* <br /> */}
            </>
          )}

          <Grid container spacing={1}>
            {fieldMeta.map((currentField) => {
              if (currentField.hideGrid == undefined ||
                (currentField.hideGrid &&
                  fieldData[currentField.tableDataKey] ===
                  currentField.open)
              ) {
                return (
                  <Grid
                    container
                    xs={currentField.xs}
                    sm={currentField.sm}
                    md={currentField.md}
                    sx={{
                      marginTop: currentField.marginTop
                        ? currentField.marginTop
                        : 1,
                    }}
                  //  rowSpacing={16}
                  >
                    {currentField.toolTip && currentField.toolTip !== "" && (
                      <>
                        <Tooltip placement="top" title={currentField.toolTip}>
                          <InfoIcon color="disabled" />
                        </Tooltip>
                      </>
                    )}
                    <Grid item 
                       xs={12}
                       sm={currentField.labelSM ? currentField.labelSM : 4}
                       md={currentField.labelMD ? currentField.labelMD : 4}
                       lg={currentField.labelLG ? currentField.labelLG : 4}
                   
                    >
                      {currentField.controlType !== "hideDatepicker" &&
                        currentField.controlType !== "hideTextfield" &&
                        currentField.controlType !== "hideMultiSelect" &&
                        currentField.controlType !== "hideAutocomplete" && currentField.controlType !== "hideRadiogroup" && (
                          <>
                           
                            <LabelCompo
                            isMandatory={currentField.isMandatory}
                              className="text-black"
                              style={{
                                fontSize: 14,
                                color: currentField.isMandatory
                                  ? titleColor
                                  : blackColor,
                              }}
                              label={currentField.label + " :"}
                            />
                          </>
                        )}
                      {(currentField.controlType === "hideMultiSelect" ||
                        currentField.controlType === "hideAutocomplete" ||
                        currentField.controlType === "hideRadiogroup" ||
                        currentField.controlType === "hideDatepicker") &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <>
                            <LabelCompo
                            isMandatory={currentField.isMandatory}
                              className="text-black"
                              style={{
                                fontSize: 14,
                                color: currentField.isMandatory
                                  ? titleColor
                                  : blackColor,
                              }}
                              label={currentField.label + ":"}
                            />
                          </>
                        )}
                    </Grid>
                    <Grid item xs={12} 
                      sm={currentField.valueSM ? currentField.valueSM : 8}
                      md={currentField.valueMD ? currentField.valueMD : 8}
                      lg={currentField.valueLG ? currentField.valueLG : 8}
                    >
                      {currentField.controlType === "textfield" && (
                        <>
                          <TextFieldCompo
                            placeHolder={currentField.placeHolder}
                            size="small"
                            color="primary"
                            name={currentField.label}
                            id={currentField.label}
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : ""
                            }
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : currentField.disableTrue
                                  ? true
                                  : currentField.disableWithCondition &&
                                    disableCondition
                                    ? true
                                    : false
                            }
                            inputProps={{
                              maxLength:
                                currentField.maxLength && currentField.maxLength,
                              min: currentField.minNum && currentField.minNum,
                            }}
                            type={
                              currentField.inputType
                                ? currentField.inputType
                                : "text"
                            }
                            onChange={this.onTextFieldDataChange(
                              currentField.dataKey
                            )}
                            fullWidth
                            errorText={
                              formErrors[currentField.dataKey]?.isError
                                ? formErrors[currentField.dataKey].errorText
                                : ""
                            }
                          />
                          {currentField.note &&
                            currentField.space &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 12,
                                      color: greyColor,
                                      marginLeft: 2,
                                    }}
                                    label={note}
                                  />
                                  {currentField.space === "br" ? (
                                    <br></br>
                                  ) : currentField.space === "&nbsp;" ? (
                                    " "
                                  ) : (
                                    ""
                                  )}
                                  {/* <br></br> */}
                                </>
                              );
                            })}
                        </>
                      )}
                      {currentField.controlType === "textarea" && (
                        <TextFieldCompo
                          placeHolder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          disabled={
                            fieldData["id"] && currentField.disable ? true : false
                          }
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                          }}
                          type={
                            currentField.inputType ? currentField.inputType : "text"
                          }
                          multiline
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey
                          )}
                          fullWidth
                          errorText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : ""
                          }
                        />
                      )}
                      {currentField.controlType === "hideTextfield" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <TextFieldCompo
                            placeHolder={currentField.placeHolder}
                            size="small"
                            color="primary"
                            name={currentField.label}
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : currentField.disableTrue
                                  ? true
                                  : currentField.disableWithCondition &&
                                    disableCondition
                                    ? true
                                    : false
                            }
                            id={currentField.label}
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : ""
                            }
                            inputProps={{
                              maxLength:
                                currentField.maxLength && currentField.maxLength,
                            }}
                            type={
                              currentField.inputType
                                ? currentField.inputType
                                : "text"
                            }
                            onChange={this.onTextFieldDataChange(
                              currentField.dataKey
                            )}
                            fullWidth
                            errorText={
                              formErrors[currentField.dataKey]?.isError
                                ? formErrors[currentField.dataKey].errorText
                                : ""
                            }
                          />
                        )}
                      {currentField.controlType === "hideRadiogroup" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <>
                            <RadioGroup
                              row
                              aria-label={currentField.label}
                              name={currentField.label}
                              id={currentField.label}
                              disabled={
                                fieldData["id"] && currentField.disable ? true : false
                              }
                              value={
                                fieldData[currentField.dataKey]
                                  ? fieldData[currentField.dataKey]
                                  : ""
                              }
                              onChange={this.onRadioChange(currentField.dataKey)}
                              size="small"
                            >
                              {currentField.radioGroupItems.map((radioItem) => (
                                <FormControlLabel
                                sx={{
                                  "& svg": {
                                    width: "0.8em",
                                    height: "0.8em"
                                  }
                                }}
                                  disabled={
                                    fieldData["id"] && currentField.disable
                                      ? true
                                      : false
                                  }
                                  value={radioItem.value}
                                  control={<Radio />}
                                  label={radioItem.label}
                                />
                              ))}
                            </RadioGroup>
                            {currentField.note &&
                              currentField.note.split("@").map((note) => {
                                return (
                                  <>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 12, color: greyColor }}
                                      label={note}
                                    />
                                    <br></br>
                                  </>
                                );
                              })}
                          </>
                        )}
                      {currentField.controlType === "datepicker" && (
                        <BasicDatePicker
                          color="primary"
                          name="dob"
                          size="small"
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : null
                          }
                          {...(currentField.isMAxDate && {
                            maxDate: dayjs(),
                          })}
                          {...(currentField.isMinDate && {
                            minDate: currentField.addOne
                              ? dayjs().add(1, "day")
                              : dayjs(),
                          })}
                          disabled={
                            fieldData["id"] && currentField.disable ? true : false
                          }
                          fullWidth
                          onChange={this.onDateChange(currentField.dataKey)}
                          isError={formErrors[currentField.dataKey]?.isError}
                          errorText={formErrors[currentField.dataKey]?.errorText}
                        />
                      )}
                      {currentField.controlType === "hideDatepicker" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <BasicDatePicker
                            color="primary"
                            name="dob"
                            size="small"
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : null
                            }
                            {...(currentField.isMAxDate && {
                              maxDate: dayjs(),
                            })}
                            {...(currentField.isMinDate && {
                              minDate: dayjs(),
                            })}
                            disabled={
                              fieldData["id"] && currentField.disable ? true : false
                            }
                            fullWidth
                            onChange={this.onDateChange(currentField.dataKey)}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={formErrors[currentField.dataKey]?.errorText}
                          />
                        )}
                      {currentField.controlType === "hideAutocomplete" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <AutoComplete
                            label={currentField.label}
                            placeHolder={currentField.placeHolder}
                            keyColName={"id"}
                            value={
                              currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : !currentField.isRootLevelKey &&
                                  fieldData[currentField.dataKey]
                                  ? fieldData[currentField.dataKey].id
                                  : ""
                            }
                            options={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            disabled={
                              fieldData["id"] && currentField.disable ? true : false
                            }
                            onChange={this.onAutocompleteChange(
                              currentField.dataKey,
                              currentField.isRootLevelKey
                            )}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={formErrors[currentField.dataKey]?.errorText}
                          />
                        )}
                      {currentField.controlType === "autocomplete" && (
                        <>
                          <AutoComplete
                            label={currentField.label}
                            placeHolder={currentField.placeHolder}
                            keyColName={"id"}
                            value={
                              currentField.isRootLevelKey &&
                                fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : !currentField.isRootLevelKey &&
                                  fieldData[currentField.dataKey]
                                  ? fieldData[currentField.dataKey].id
                                  : ""
                            }
                            options={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : currentField.disableTrue
                                  ? true
                                  : currentField.disableWithCondition &&
                                    disableCondition
                                    ? true
                                    : false
                            }
                            onChange={this.onAutocompleteChange(
                              currentField.dataKey,
                              currentField.isRootLevelKey
                            )}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={formErrors[currentField.dataKey]?.errorText}
                          />
                          {currentField.note &&
                            currentField.space &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12, color: greyColor }}
                                    label={note}
                                  />
                                  {currentField.space === "br" ? (
                                    <br></br>
                                  ) : currentField.space === "&nbsp;" ? (
                                    " "
                                  ) : (
                                    ""
                                  )}
                                  {/* <br></br> */}
                                </>
                              );
                            })}
                        </>
                      )}
                      {currentField.controlType === "autocomplete/textfield" && (
                        <>
                          {fieldData["id"] ? (
                            <TextFieldCompo
                              placeHolder={currentField.placeHolder}
                              size="small"
                              color="primary"
                              name={currentField.label}
                              id={currentField.label}
                              value={
                                fieldData[currentField.dataKeyName] &&
                                  fieldData[currentField.dataKeyName]
                                  ? fieldData[currentField.dataKeyName]
                                  : ""
                              }
                              disabled={true}
                              inputProps={{
                                maxLength:
                                  currentField.maxLength && currentField.maxLength,
                                min: currentField.minNum && currentField.minNum,
                              }}
                              type={
                                currentField.inputType
                                  ? currentField.inputType
                                  : "text"
                              }
                              onChange={this.onTextFieldDataChange(
                                currentField.dataKey
                              )}
                              fullWidth
                              errorText={
                                formErrors[currentField.dataKey]?.isError
                                  ? formErrors[currentField.dataKey].errorText
                                  : ""
                              }
                            />
                          ) : (
                            <>
                              <AutoComplete
                                label={currentField.label}
                                placeHolder={currentField.placeHolder}
                                keyColName={"id"}
                                value={
                                  currentField.isRootLevelKey &&
                                    fieldData[currentField.dataKey]
                                    ? fieldData[currentField.dataKey]
                                    : !currentField.isRootLevelKey &&
                                      fieldData[currentField.dataKey]
                                      ? fieldData[currentField.dataKey].id
                                      : ""
                                }
                                options={
                                  dynamicMasterData[currentField["masterName"]]
                                    ? dynamicMasterData[currentField["masterName"]]
                                    : []
                                }
                                disabled={
                                  fieldData["id"] && currentField.disable
                                    ? true
                                    : currentField.disableTrue
                                      ? true
                                      : currentField.disableWithCondition &&
                                        disableCondition
                                        ? true
                                        : false
                                }
                                onChange={this.onAutocompleteChange(
                                  currentField.dataKey,
                                  currentField.isRootLevelKey
                                )}
                                isError={formErrors[currentField.dataKey]?.isError}
                                errorText={
                                  formErrors[currentField.dataKey]?.errorText
                                }
                              />
                              {currentField.note &&
                                currentField.space &&
                                currentField.note.split("@").map((note) => {
                                  return (
                                    <>
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 12, color: greyColor }}
                                        label={note}
                                      />
                                      {currentField.space === "br" ? (
                                        <br></br>
                                      ) : currentField.space === "&nbsp;" ? (
                                        " "
                                      ) : (
                                        ""
                                      )}
                                      {/* <br></br> */}
                                    </>
                                  );
                                })}
                            </>
                          )}
                        </>
                      )}
                      {currentField.controlType === "multiSelect" && (
                        <MultipleSelect
                          label={currentField.placeHolder}
                          er={formErrors[currentField.dataKey]?.errorText}
                          name1={currentField.dataKey}
                          value1={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : []
                          }
                          names={
                            dynamicMasterData[currentField["masterName"]] &&
                              dynamicMasterData[currentField["masterName"]].length !=
                              0
                              ? [
                                { id: 0, name: "All" },
                                ...dynamicMasterData[currentField["masterName"]],
                              ]
                              : []
                          }
                          onChange={this.onMultiSelectChange(
                            currentField.dataKey,
                            currentField["masterName"]
                          )}
                        />
                      )}
                      {currentField.controlType === "hideMultiSelect" &&
                        fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                          <MultipleSelect
                            label={currentField.placeHolder}
                            placeHolder={currentField.placeHolder}
                            name1={currentField.dataKey}
                            value1={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : []
                            }
                            names={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            onChange={this.onMultiSelectChange(
                              currentField.dataKey
                            )}
                            er={formErrors[currentField.dataKey]?.errorText}
                          />
                        )}
                      {currentField.controlType === "timePicker" && (
                        <TimePickerCompo
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          onChange={this.timeChangeHandler(currentField.dataKey)}
                        />
                      )}
                      {currentField.controlType === "radiogroup" && (
                        <>
                          <RadioGroup
                            row
                            aria-label={currentField.label}
                            name={currentField.label}
                            id={currentField.label}
                            disabled={
                              fieldData["id"] && currentField.disable ? true : false
                            }
                            value={
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : ""
                            }
                            onChange={this.onRadioChange(currentField.dataKey)}
                            size="small"
                          >
                            {currentField.radioGroupItems.map((radioItem) => (
                              <FormControlLabel
                                disabled={
                                  fieldData["id"] && currentField.disable
                                    ? true
                                    : false
                                }
                                value={radioItem.value}
                                control={<Radio />}
                                label={radioItem.label}
                              />
                            ))}
                          </RadioGroup>
                          {currentField.note &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12, color: greyColor }}
                                    label={note}
                                  />
                                  <br></br>
                                </>
                              );
                            })}
                        </>
                      )}
                    </Grid>
                  </Grid>
                )
              }
            })}
            {showSaveBtnMain && (
              <Grid
                container
                sm={GridSave != 0 ? GridSave : 1}
                md={GridSave != 0 ? GridSave : 1}
                lg={GridSave != 0 ? GridSave : 1}
                xs={12}

              //  rowSpacing={16}
              >
                <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={saveBtnText !== "" ? saveBtnText : "Save"}
                    // fullWidth={true}
                    onClick={onFormSave}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          {/* <br></br> */}
          {(showSaveBtn || showCancel) && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                {showSaveBtn && (
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={saveBtnText !== "" ? saveBtnText : "Save"}
                    // fullWidth={true}
                    onClick={onFormSave}
                  />
                )}
                {/* {showSubmit && (
                  <>
                    &nbsp; &nbsp;
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Submit"}
                      // fullWidth={true}
                      onClick={onFormSubmit}
                    />
                  </>
                )} */}
                {showCancel && (
                  <>
                    &nbsp; &nbsp;
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={this.onCancel}
                    // fullWidth={true}
                    />
                  </>
                )}
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicFormWithoutValidation);
