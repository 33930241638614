import { Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiGetWithoutHeader } from "../../../utils/api_service";
import { showLoador1, showNotification } from "../../Landing/Landing.slice";
import * as msgConstant from "../../../config/messageconstant";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    enterOtpFlag: false,
    externalToken: false,
    userName: "",
    enterPassWord: false,
    enterOtp: "",
    confirmPassword: "",
    newPassword: "",
    formErrors: [],
    token: "",
    showPassword:false,
  };
  handleClickShowPassword = () => setShowPassword((show) => !show);

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  generateOtp = () => {
    const { userName } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (this.handleUserNameValidation()) {
      if (navigator.onLine) {
        showLoador1({ loador: true });
        apiGetWithoutHeader({
          url: endpoint.forgotPassword + "/generate-otp?userName=" + userName,
        }).then(({ data, success }) => {
          showLoador1({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                enterOtpFlag: true,
                enterPassWord: false,
              });
            } else {
              let formErrors = {};
              let formIsValid = true;
              formIsValid = false;
              formErrors["userNameErr"] = data.message;
              this.setState({ formErrors: formErrors });
            }
          } else {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: noInternetMsg,
          severity: "error",
        });
      }
    }
  };
  validateOtp = () => {
    const { enterOtp, userName } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (this.handleOTPValidation()) {
      if (navigator.onLine) {
        showLoador1({ loador: true });
        apiGetWithoutHeader({
          url:
            endpoint.forgotPassword +
            "/validate-otp?userName=" +
            userName +
            "&" +
            "otp=" +
            enterOtp,
        }).then(({ data, success }) => {
          showLoador1({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                token: data.externalToken,
                enterPassWord: true,
              });
            } else {
              if (data.error) {
                showNotification({ msg: data.errorMessage, severity: "error" });
              }
            }
          } else {
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
  };

  handleUserNameValidation() {
    const { userName, enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (userName === "") {
      formIsValid = false;
      formErrors["userNameErr"] = myConstClass.userNameEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleOTPValidation() {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpEmptyErr"] = myConstClass.otpEmptyMsg;
    } else if (!/^\d{6}$/.test(enterOtp)) {
      formIsValid = false;
      formErrors["otpFormatErr"] = myConstClass.otpFormatMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  passwordFormValidation(name) {
    const { newPassword, confirmPassword } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (newPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }
    if (
      confirmPassword !== "" &&
      newPassword !== "" &&
      confirmPassword != newPassword
    ) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
    const { token, confirmPassword } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.passwordFormValidation()) {
        showLoador1({ loador: true });
        apiGetWithoutHeader({
          url:
            endpoint.forgotPassword +
            "/update-password?token=" +
            token +
            "&password=" +
            confirmPassword,
        }).then(({ data, success }) => {
          showLoador1({ loador: false });
          if (success) {
            if (!data.error) {
              showNotification({ msg: "Password changed successfully" });
              window.location.replace("/login");
            }
          } else {
            showNotification({
              msg: myConstClass.serverMsg,
              severity: "error",
            });
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
  };

  onCancel = () => {
    window.location.replace("/login");
  };
  changeUserName = () => {
    this.setState({
      enterOtpFlag: false,
      enterOtp: "",
    });
  };
  render() {
    const {
      userName,
      enterOtp,
      newPassword,
      confirmPassword,
      enterOtpFlag,
      enterPassWord,
      showPassword,
    } = this.state;

    const {
      otpEmptyErr,
      otpFormatErr,
      userNameErr,
      enterPasswordErr,
      confirmPasswordErr,
      incorrectPasswordErr,
    } = this.state.formErrors;

    return (
      <>
        <div className="main_login_div">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "absolute",
              top: "5%",
              gap: "20px",
              left: "5%",
            }}
          >
            <div className="new_logo_content" />
            <div className="new_title_content">Accounts</div>
          </div>
          <div className="new_login_content">
            <div className="new_login_box3">
              <div className="new_login_box">
                <div>
                  <div className="new_login_welcome">Forgot Password</div>
                </div>
                <div style={{ alignItems: "flex-between" }}>
                  {!enterPassWord && (
                    <div className="user_lgn_new">
                      <div className="new_user_lable">Username</div>
                      <TextField
                        color="primary"
                        name="userName"
                        size="small"
                        value={userName}
                        onChange={this.changedHandler}
                        //  onKeyPress={(e) => e.key === "Enter" && Login()}
                        fullWidth
                        helperText={userNameErr ? userNameErr : " "}
                        error={userNameErr ? true : false}
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #DEDEDE",
                          background: "#ECECEC",
                          width: "360px",
                          height: "41px",
                          flexShrink: "0",
                          marginBottom: "20px",
                          // borderRadius: "17px 17px 17px 17px",
                        }}
                      />
                    </div>
                  )}

                  {!enterOtpFlag && (
                    <>
                     <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"flex-end", gap:"10px" }}>
                      <button
                        type="button"
                        onClick={this.generateOtp}
                        // onKeyPress={(e) => e.key === "Enter" && Login()}
                        className="new_login_btn"
                        style={{
                          color: "#FFF",
                          /* button text poppins */
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          width:"30%",
                          justifyContent:"center"
                        }}
                      >
                        Send OTP
                      </button>
                      <button
                        type="button"
                        onClick={this.onCancel}
                        // onKeyPress={(e) => e.key === "Enter" && Login()}
                        className="outline-btn"
                        style={{
                          
                          /* button text poppins */
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          width:"30%",
                          justifyContent:"center"
                        }}
                      >
                        Cancel
                      </button>
                      </div>
                    </>
                  )}
                  {enterOtpFlag && (
                    <>
                      {!enterPassWord && (
                        <>
                          <div className="new_user_lable">*Enter OTP :</div>
                          <TextField
                            color="primary"
                            name="name"
                            size="small"
                            value={enterOtp}
                            onChange={this.changedHandler}
                            // onKeyPress={(e) => e.key === "Enter" && Login()}
                            fullWidth
                            error={otpEmptyErr || otpFormatErr ? true : false}
                            helperText={
                              otpEmptyErr
                                ? otpEmptyErr
                                : otpFormatErr
                                ? otpFormatErr
                                : " "
                            }
                            style={{
                              borderRadius: "4px",
                              border: "1px solid #DEDEDE",
                              background: "#ECECEC",
                              width: "360px",
                              height: "41px",
                              flexShrink: "0",
                              marginBottom: "20px",
                              // borderRadius: "17px 17px 17px 17px",
                            }}
                          />

<div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" }}>
                            <button
                              type="button"
                              onClick={this.validateOtp}
                              // onKeyPress={(e) => e.key === "Enter" && Login()}
                              className="new_login_btn"
                              style={{
                                color: "#FFF",
                                /* button text poppins */
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "normal",
                                width:"20%",
                                justifyContent:"center",
                              }}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              onClick={this.generateOtp}
                              // onKeyPress={(e) => e.key === "Enter" && Login()}
                              className="new_login_btn"
                              style={{
                                color: "#FFF",
                                /* button text poppins */
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "normal",
                                width:"25%",
                                // justifyContent:"center",
                              }}
                            >
                              Resend OTP
                            </button>
                            <button
                              type="button"
                              onClick={this.changeUserName}
                              // onKeyPress={(e) => e.key === "Enter" && Login()}
                              className="new_login_btn"
                              style={{
                                color: "#FFF",
                                /* button text poppins */
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "normal",
                                width:"25%",
                                // justifyContent:"center",
                              }}
                            >
                              Change Username
                            </button>
                            <button
                              type="button"
                              onClick={this.onCancel}
                              // onKeyPress={(e) => e.key === "Enter" && Login()}
                              className="outline-btn"
                              style={{
                                
                                /* button text poppins */
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "normal",
                                width:"20%",
                                // justifyContent:"center",
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}
                      {enterPassWord && (
                        <>
                          <div className="user_lgn">
                            <div className="user_label">*Enter Password :</div>
                            <OutlinedInput
                              color="primary"
                              name="newPassword"
                              size="small"
                              type="password"
                             
                              value={newPassword}
                              onChange={this.changedHandler}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              fullWidth
                             
                            error={enterPasswordErr ? true : false}
                            helperText={
                              enterPasswordErr ? enterPasswordErr : " "
                            }
                              style={{
                                borderRadius: "4px",
                                border: "1px solid #DEDEDE",
                                background: "#ECECEC",
                                width: "360px",
                                height: "43px",
                                flexShrink: "0",

                                // borderRadius: "17px 17px 17px 17px",
                              }}
                            />
                          </div>
                          <div className="user_lgn">
                            <div className="user_label">
                              *Confirm Password :
                            </div>
                            <OutlinedInput
                              color="primary"
                              name="password"
                              size="small"
                              type="password"
                                    value={confirmPassword}
                                    onChange={this.changedHandler}
                                    fullWidth
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    error={
                                      confirmPasswordErr || incorrectPasswordErr
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      confirmPasswordErr
                                        ? confirmPasswordErr
                                        : incorrectPasswordErr
                                        ? incorrectPasswordErr
                                        : " "
                                    }
                             
                              style={{
                                borderRadius: "4px",
                                border: "1px solid #DEDEDE",
                                background: "#ECECEC",
                                width: "360px",
                                height: "43px",
                                flexShrink: "0",

                                // borderRadius: "17px 17px 17px 17px",
                              }}
                            />
                          </div>

                          <button
                    type="button"
                    onClick={this.submitHandler}
                   
                    className="new_login_btn"
                    style={{
                      color: "#FFF",
                      /* button text poppins */
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={this.onCancel}
                   
                    className="new_login_btn"
                    style={{
                      color: "#FFF",
                      /* button text poppins */
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                   Cancel
                  </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  passwordData: state.forgotPassword,
});

const mapDispatchToProps = {
  showLoador1,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
