import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadF } from "../../Pages/PDF/PDFDownload";
import { serverMsg } from "../../config/messageconstant";
import ColumnFilterTablePopUp from "../Comman/ColumnFilterPopup";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import { Loading1 } from "../Loading1";
import DynamicForm from "./DynamicFormForTableFilter";
// import DynamicForm from "./DynamicMasterForm";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { getReportName } from "../../Slice/report.slice";
import * as msgConstant from "../../config/messageconstant";
import { apiDelete, apiGet } from "../../utils/api_service";
import RTTable from "../Comman/RT/TableForDynamicMaster";
import {
  generateExcel,
  generateHeaderExcel,
  generateHeaderPDF,
  generatePDF,
} from "../GeneratePDFExcel";

import endpoint from "../../config/endpoints";
import { withRouter } from "../withRouter";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMasterMain extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      reportName: {},
      graphName: {},
      formErrors: {},
      dynamicMasterData: {},
      tableData: [],
      reportTableCheckedInfo: {},
      openFilter: false,
      searchList: [],
      fieldDataForSearch: {},
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData, reportName } = this.state;
    const fieldMeta = JSON.parse(reportName.parameterRequired);

    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        // this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    console.log(dataToReset);
    this.setState({
      fieldData: dataToReset,
    });
  };

  onDataChangeForSearch = (fieldName, newValue) => {
    const { fieldDataForSearch, searchList } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldDataForSearch };
    let listError = false;
    searchList.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldDataForSearch[key]) {
            dataToSearch[key] = fieldDataForSearch[key];
          }
          return null;
        });

        // this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldDataForSearch[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] =
          fieldDataForSearch[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldDataForSearch: dataToReset,
    });
  };

  setFormData = (rowData) => {
    const { searchList = [], dynamicMasterData } = this.state;

    let fieldData = {};

    searchList.forEach((currentField) => {
      let dataToSearch = {};
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey];
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          // this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "autocomplete/textfield") {
        if (!currentField.isRootLevelKey) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[tableDataKey].id,
            },
          };
        } else {
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
        fieldData = {
          ...fieldData,
          [currentField.dataKeyName]: rowData[tableDataKey].name,
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: {
            id: rowData[tableDataKey].id,
          },
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          // this.props.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "autocomplete" &&
        currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: rowData[tableDataKey],
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          // this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          fieldData[currentField.dataKey] = dayjs(
            rowData[tableDataKey],
            "DD-MM-YYYY"
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] = rowData.isActive;
    console.log(fieldData);
    return fieldData;
  };

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     this.props.params != nextProps.params &&
  //     this.props.params.id != nextProps.params.id
  //   ) {
  //     this.getMasterInfo(nextProps.params.id);
  //   }
  // }



  getDynamicList = async (fieldObj) => {
    if (fieldObj.localList && fieldObj.localList.length != 0) {
      let LocalList = fieldObj.localList.map((dataObj) => {
        return { id: dataObj.key, name: dataObj.label };
      });
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          [fieldObj.masterName]: LocalList,
        },
      });
      return true;
    } else {
      const { showLoador, showNotification } = this.props;
      showLoador({ loador: true });
      let response = await apiGet({
        url: "/api/account/" + fieldObj.url,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        }
        return { data: data.data, success };
      });
      if (response.success) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            [fieldObj.masterName]:
              response.data &&
              response.data.map((dataObj) => {
                return {
                  ...dataObj,
                  id:
                    fieldObj.selectKey && dataObj[fieldObj.selectKey]
                      ? dataObj[fieldObj.selectKey]
                      : dataObj.id,
                  name:
                    fieldObj.selectLabel && dataObj[fieldObj.selectLabel]
                      ? dataObj[fieldObj.selectLabel]
                      : dataObj.name,
                };
              }),
          },
        });
      }

      return response;
    }
  };
  getDropdownList = async (report) => {
    const {
      showLoador,
      showNotification,
    } = this.props;
    const { dynamicMasterData } = this.state;
    if (navigator.onLine) {
      const fieldMeta = JSON.parse(report.parameterRequired);
      console.log(fieldMeta);

      let filterList = fieldMeta.filter(
        (fieldObj) =>
          (fieldObj.localList && fieldObj.localList.length != 0) ||
          (fieldObj.url && fieldObj.url != "")
      );
      Promise.all(filterList.map((fieldObj) => this.getDynamicList(fieldObj)));
    } else {
      showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
    }
  };

  onFormSearch = (flag) => {
    const { fieldData, reportName } = this.state;
    const fieldMeta = JSON.parse(reportName.parameterRequired);
    if (fieldMeta != null) {
      const dataToSave = {};
      fieldMeta.forEach((currentField) => {
        if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].format("DD-MM-YYYY")
              : "";
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });
      if ((flag = 1)) {
        this.getSearchList(dataToSave, reportName);
      }
      return dataToSave;
    }
  };

  getSearchList = (dataToSearch, reportName) => {
    const { showLoador, showNotification } = this.props;
    let str = "";
    console.log(dataToSearch);

    Object.keys(dataToSearch).forEach((k, i) => {
      console.log(k);
      if (str == "") {
        str = str + k + "=" + dataToSearch[k] + "&";
      } else {
        str = str + k + "=" + dataToSearch[k] + "&";
      }
    });
    showLoador({ loador: true });
    apiGet({
      url: "/api/account/" + reportName.apiName + "?" + str,
    }).then(({ data, success }) => {
      if (!success) {
        showLoador({ loador: false });
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        showLoador({ loador: false });
        let tableData = [];
        // if (reportName.isSubreport == 0) {
        tableData = data.data.map((reportData, index) => {
          let reportObj = { index: index + 1, ...reportData };
          const reportInfo = reportName.reportColumnNames.map(
            (reportColumnNamesData, index) => {
              let strData = reportColumnNamesData.pojoFiledName.split(".");
              let strD = "";
              strData.map((objData, index) => {
                if (index == 0) {
                  strD = reportData[objData];
                } else {
                  strD = strD[objData];
                }
              });
              reportObj = {
                ...reportObj,
                [reportColumnNamesData.fieldName]:
                  reportColumnNamesData.isTotal &&
                    reportColumnNamesData.isTotal == 1
                    ? (+strD).toFixed(2)
                    : strD,
              };
            }
          );

          return reportObj;
        });

        this.setState({
          tableData: [
            {
              reportName: reportName,
              graphName: {},
              openGraph: false,
              tableData: tableData,
            },
          ],
        });
      }
    });
  };

  getFilteredTableData = (tableCols) => {
    const { searchValue  } = this.state;
    const { tableColumns,tableDataObj } = this.props;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableDataObj.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };



  getTotalData = (tableData, tableColumns) => {
    let totalList = [];
    if (tableColumns.length != 0) {

      tableColumns.map((row) => {
        if (row.isChecked && row.isList != 1) {
          if (row.isTotal == 1) {
            let total = 0;
            tableData.map((rowData) => {
              total = total + +rowData[row.name];
            });
            totalList.push(total.toFixed(2));
          } else {
            totalList.push("");
          }
        }
      });
      return totalList;
    }
  };

  checkedIsList = (reportName, tableColumns, tableList, type) => {
    const tableData = this.getFilteredTableData(tableList, tableColumns);

    let filterForList = tableColumns.filter((rowData) => rowData.isList == 1);
    if (type == "pdf") {
      this.generatePDFMethod(reportName, tableColumns, tableList, tableData);
    } else {
      this.generateExcelMethod(reportName, tableColumns, tableList, tableData);
    }
  };
  generatePDFHeaderDetailsMethod = (
    reportName,
    filterForList,
    tableList,
    tableData
  ) => {
    const reportColumnNames =
      JSON.parse(reportName.tableColumns) != null
        ? JSON.parse(reportName.tableColumns)
        : [];
    let rows = tableData.map((reportData, index) => {
      let reportObj = [];
      let reportList = [];
      let reportColumns = [];
      const reportInfo = reportColumnNames.map(
        (reportColumnNamesData, index) => {
          if (reportColumnNamesData.isList != 1) {
            reportObj.push({
              label: reportColumnNamesData.columnName,
              value: reportData[reportColumnNamesData.fieldName],
            });
          } else {
            let getSubData = this.getList(reportColumnNamesData, reportData);
            reportObj = [...reportObj, ...getSubData.reportObj];
            reportList = [...reportList, ...getSubData.reportList];
            reportColumns = [...reportColumns, ...getSubData.reportColumns];
          }
        }
      );

      return {
        reportObj: reportObj,
        reportColumns: reportColumns,
        reportList: reportList,
      };
    });
    console.log(rows);

    generateHeaderPDF({ rows, pdfTitle: reportName.name });
  };
  getList = (reportColumnNames, tableData) => {
    let reportObj = [];
    let reportList = [];
    let reportColumns = [];
    const filterData =
      reportColumnNames.subList &&
      reportColumnNames.subList.filter((col) => col.isList == 1);

    let suListData =
      tableData[reportColumnNames.fieldName] &&
      tableData[reportColumnNames.fieldName].map((reportData, index) => {
        if (filterData.length != 0) {
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportObj.push({
                label: reportColumnNamesData.columnName,
                value: reportData[reportColumnNamesData.fieldName],
              });
            } else {
              let getSubData = this.getList(reportColumnNamesData, reportData);
              reportObj = [...reportObj, ...getSubData.reportObj];
              reportList = [...reportList, ...getSubData.reportList];
            }
          });
        } else {
          let reportOBJ = [];
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportOBJ.push(reportData[reportColumnNamesData.fieldName]);
            }
          });
          reportList = [...reportList, reportOBJ];
        }
      });
    if (filterData.length == 0) {
      reportColumnNames.subList.map((reportColumnNamesData, index) => {
        if (reportColumnNamesData.isList != 1) {
          reportColumns = [...reportColumns, reportColumnNamesData.columnName];
        }
      });
    }
    return {
      reportObj: reportObj,
      reportColumns: reportColumns,
      reportList: reportList,
    };
  };
  generatePDFMethod = (reportName, tableColumns, tableList, tableData) => {
    var columns = [];
    var col = ["Sr. No."];
    var align = { 0: { halign: "center" } };
    tableColumns.map((reportColumnNamesData, index) => {
      if (
        reportColumnNamesData.isChecked &&
        reportColumnNamesData.isList != 1
      ) {
        align = {
          ...align,
          [index + 1]: { halign: reportColumnNamesData.align },
        };
        col.push(reportColumnNamesData.title);
      }
    });
    columns.push(col);
    let rows = []
    tableData.map((reportData, index) => {

      let reportObj = [index + 1];
      const reportInfo = tableColumns.map((reportColumnNamesData, index) => {
        if (reportColumnNamesData.isChecked) {
          reportObj.push(reportData[reportColumnNamesData.fieldName])
        }
      });
      rows.push(reportObj)
      return null;
    });
    console.log(rows);


    const fieldMeta = JSON.parse(this.state.reportName.parameterRequired);
    let headerList = fieldMeta.map((fieldInfo) => {
      return {
        label: fieldInfo.label,
        value:
          fieldInfo.controlType == "datepicker" &&
            this.state.fieldData[fieldInfo.dataKey]
            ? this.state.fieldData[fieldInfo.dataKey].format("DD-MM-YYYY")
            : fieldInfo.controlType == "multiSelect" &&
              this.state.fieldData[fieldInfo.dataKey]
              ? this.getName(
                this.state.fieldData[fieldInfo.dataKey],
                fieldInfo.dataKey
              )
              : fieldInfo.controlType == "autocomplete" &&
                this.state.fieldData[fieldInfo.dataKey]
                ? this.getName(
                  [this.state.fieldData[fieldInfo.dataKey]],
                  fieldInfo.dataKey
                )
                : this.state.fieldData[fieldInfo.dataKey],
      };
    });
    if (columns.length > 6) {
      this.props.showNotification({
        msg: "Plase Select only 6 columns",
        severity: "error",
      });
    } else {
      generatePDF({
        rows,
        columns,
        columnStyles: align,
        headerList,
        pdfTitle: reportName.reportName,
      });
    }
  };
  getName = (List, fieldName) => {
    const nameList = List.map((ListData) => {
      const filterData = this.state.dynamicMasterData[fieldName].filter(
        (dynamicList) => dynamicList.id == ListData
      );
      if (filterData.length != 0) {
        return filterData[0].name;
      }
      return "";
    });
    return nameList.join(", ");
  };

  getListForExcel = (reportColumnNames, tableData) => {
    let reportObj = [];
    let reportList = [];
    let reportColumns = [];
    const filterData =
      reportColumnNames.subList &&
      reportColumnNames.subList.filter((col) => col.isList == 1);

    let suListData =
      tableData[reportColumnNames.fieldName] &&
      tableData[reportColumnNames.fieldName].map((reportData, index) => {
        if (filterData.length != 0) {
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportObj.push({
                label: reportColumnNamesData.columnName,
                value: reportData[reportColumnNamesData.fieldName],
              });
            } else {
              let getSubData = this.getListForExcel(
                reportColumnNamesData,
                reportData
              );
              reportObj = [...reportObj, ...getSubData.reportObj];
              reportList = [...reportList, ...getSubData.reportList];
            }
          });
        } else {
          let reportOBJ = [];
          reportColumnNames.subList.map((reportColumnNamesData, index) => {
            if (reportColumnNamesData.isList != 1) {
              reportOBJ.push(reportData[reportColumnNamesData.fieldName]);
            }
          });
          reportList = [...reportList, reportOBJ];
        }
      });
    if (filterData.length == 0) {
      reportColumnNames.subList.map((reportColumnNamesData, index) => {
        if (reportColumnNamesData.isList != 1) {
          reportColumns = [...reportColumns, reportColumnNamesData.columnName];
        }
      });
    }
    return {
      reportObj: reportObj,
      reportColumns: reportColumns,
      reportList: reportList,
    };
  };
  generateExcelMethod = (reportName, tableColumns, tableList, tableData) => {
    var columns = ["Sr. No."];
    let alignList = [];
    tableColumns.map((reportColumnNamesData, index) => {
      if (reportColumnNamesData.isChecked) {
        alignList.push({
          index: index + 1,
          allignment: reportColumnNamesData.allignment,
        });
        columns.push(reportColumnNamesData.columnName);
      }
    });
    let rows = tableData.map((reportData, index) => {
      let reportObj = [index + 1];
      const reportInfo = tableColumns.map((reportColumnNamesData, index) => {
        if (reportColumnNamesData.isChecked) {
          reportObj.push(reportData[reportColumnNamesData.fieldName]);
        }
      });
      return reportObj;
    });

    const fieldMeta = JSON.parse(this.state.reportName.parameterRequired);

    let headerList = [""];
    fieldMeta.map((fieldInfo) => {
      headerList.push(fieldInfo.label);
      if (fieldInfo.controlType == "datepicker") {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.state.fieldData[fieldInfo.dataKey].format("DD-MM-YYYY")
            : ""
        );
      } else if (fieldInfo.controlType == "multiSelect") {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.getName(
              this.state.fieldData[fieldInfo.dataKey],
              fieldInfo.dataKey
            )
            : ""
        );
      } else if (fieldInfo.controlType == "autocomplete") {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.getName(
              [this.state.fieldData[fieldInfo.dataKey]],
              fieldInfo.dataKey
            )
            : ""
        );
      } else {
        headerList.push(
          this.state.fieldData[fieldInfo.dataKey]
            ? this.state.fieldData[fieldInfo.dataKey]
            : ""
        );
      }
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList,
      title: reportName.reportName,
    });
  };

  setReportFilterList = (reportList) => {
    const tableList = this.state.tableData.map((reportData) => {
      if (this.state.reportTableCheckedInfo.id == reportData.reportName.id) {
        return {
          ...reportData,
          reportName: {
            ...reportData.reportName,
            reportColumnNames: reportList,
          },
        };
      }
      return reportData;
    });
    console.log(tableList);
    this.setState({
      openFilter: false,
      tableData: tableList,
      reportTableCheckedInfo: {},
    });
  };

  onAddButtonClick = () => {
    if (this.props.params.id) {
      this.props.navigate("/dynamic-master-form/" + this.props.params.id);
    }
  };

  rowEdit = (rowData) => {
    this.props.rowEdit(this.props.params.id, rowData.id);
  };

  rowDelete = (rowData) => {
    const { deleteURLStatic = false, onDeleteStatic } = this.props;

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (deleteURLStatic) {
          onDeleteStatic(rowData);
        } else {
          this.onDelete(rowData);
        }
      }
    });
  };

  onDelete = async (rowdata) => {
    const { getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (getTableDataById) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    const {
      apiBaseURL,
      deleteURL = "",
      deleteMsgAuto = false,
      deleteMapping = true,
    } = this.props;
    if (navigator.onLine) {
      if (deleteMapping) {
        let url = this.props.reportName.apiName;
        if (
          this.props.reportName.saveUrl != "" &&
          this.props.reportName.saveUrl != null
        ) {
          url = this.props.reportName.saveUrl;
        }
        this.props.showLoador({ loador: true });
        apiDelete({
          url:
            "/api/account/" +
            // "/" +
            url +
            "/" +
            rowdata.id,
          // deleteURL != ""
          //   ? deleteURL + "/" + rowdata.id
          //   : apiBaseURL + "/" + rowdata.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });

            this.props.callApi()
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });

            this.props.showLoador({ loador: false });
          }
        });
      } else {
        this.props.showLoador({ loador: true });
        apiGet({
          url:
            "/api/account/" +
            // "/" +
            this.props.reportName.apiName +
            "/" +
            rowdata.id +
            "/delete-by-id",
        }).then(({ success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });
            this.props.callApi()
                        // const { getTableDataById = false } = this.props;
            // if (getTableDataById) {
            //   this.props.onSearchData(dataToSearchList);
            // } else {
            //   const { callApi = true } = this.props;
            //   if (callApi) {
            //     this.getData();
            //   }
            // }
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });
            this.props.showLoador({ loador: false });
          }
        });
      }
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };



  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  generateExcelFunction = () => {
    const { tableData } = this.state;
    const { tableColumns, reportName } = this.props;
    this.checkedIsList(reportName, tableColumns, tableData, "excel");
  };

  generatePDFFunction = () => {
    const { tableData, } = this.state;
    const { tableColumns, reportName } = this.props;

    this.checkedIsList(reportName, tableColumns, tableData, "pdf");
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const { fieldMeta, isActiveURL = "" } = this.props;
        if (isActiveURL == "") {
          const dataToSave = {
            ...rowData,
            id: rowData["id"],
          };

          fieldMeta.forEach((currentField) => {
            if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              dataToSave[currentField.dataKey] = {};
              dataToSave[currentField.dataKey]["id"] = rowData[
                currentField.dataKey
              ]
                ? rowData[currentField.dataKey]
                : "";
            } else {
              dataToSave[currentField.dataKey] = rowData[currentField.dataKey]
                ? rowData[currentField.dataKey]
                : "";
            }
          });

          dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
          this.setState({
            currentOperationMode: OperationMode.Status,
          });
          this.onSave(dataToSave, false);
        } else {
          const dataToSave = {
            id: rowData["id"],
          };
          dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
          this.setState({
            currentOperationMode: OperationMode.Status,
          });
          this.onStatusChange(dataToSave, false);
        }
      }
    });
  };

  render() {
    const {
      screenTitle = "",
      PDFButton,
      buttonCenter,
      EXCELButton,
      marginTop,
      searchButton,
      tableColumnsToFilter = [],
      tableColumns,
      onRTSelectMultipleChange,
      reportName,
    } = this.props;
    const {
      fieldData,
      formErrors,
      reportTableCheckedInfo,
      openFilter,
      dynamicMasterData,
      tableData,
      isLoading,

      searchList,
    } = this.state;
    return (
      <>
        {/* <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}> */}
        
          <LandingScreenHeader
            tableColumnsToFilter={tableColumnsToFilter}
            onRTSelectMultipleChange={onRTSelectMultipleChange}
            screenTitle={""}
            showSearchBox={true}
            showPdfDownload={reportName.isPdf}
            generatePDF={this.generatePDFFunction}
            showExcelDownload={reportName.isExcel}
            generateExcel={this.generateExcelFunction}
            showAddButton={reportName.showAddButton}
            onAddButtonClick={this.onAddButtonClick}
            onSearch={this.onSearch}
            showTable={true}
          />

          {Object.keys(reportName).length != 0 && (
            <>
              {JSON.parse(reportName.parameterRequired) != null &&
                JSON.parse(reportName.parameterRequired).length != 0 && (
                  <>
                    <DynamicForm
                      reportDetails={reportName}
                      dynamicMasterData={dynamicMasterData}
                      fieldData={fieldData}
                      formErrors={formErrors}
                      onDataChange={this.onDataChange}
                      buttonCenter={buttonCenter}
                      searchButton={searchButton}
                      PDFButton={PDFButton}
                      EXCELButton={EXCELButton}
                      marginTop={marginTop}
                      showSaveBtnMain={true}
                      onFormSave={this.onFormSearch}
                      saveBtnText={"Search"}
                    />
                  </>
                )}
            </>
          )}
          <>
            <RTTable
              columns={
                [
                  ...tableColumns,
                  {
                    title: "Action",
                    name: "action",
                    formDataKey: "action",
                    showInExcel: true,
                    canSearch: true,
                  },
                ]
              }
              hiddenColumnNames={tableColumnsToFilter.map((item) => {
                return !item.isChecked ? item.columnKeyName : "";
              })}
              showEditIcon={true}
              showDeleteIcon={true}
              tableData={this.getFilteredTableData()}
              totalList={this.getTotalData(tableColumns, tableData)}
              isActionColActive={true}
              showReportIcon={false}
              showPegination={false}
              rowEdit={this.rowEdit}
              rowDelete={this.rowDelete}
              onChangeStatus={this.onChangeStatus}
            />
          </>
        {/* </Paper> */}
        {isLoading && <Loading1 />}

        {openFilter && (
          <ColumnFilterTablePopUp
            columnList={reportTableCheckedInfo.reportColumnNames}
            setReportData={this.setReportFilterList}
            openDialog={openFilter}
            setOpenDialouge={(openDia) => {
              this.setState({
                openFilter: openDia,
              });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({ report: state.report });
const mapDispatchToProps = {
  downloadF,
  getReportName,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicMasterMain);
