import dayjs from "dayjs";

export const CashbookJson = {
  screenTitle: "Cash Book",
  showPdfDownload: true,
  showExcelDownload: false,
  fieldMeta: [
    {
      label: "Date Range",
      controlType: "datepicker",
      placeHolder: "",
      md:3.5,
      lg:3.5,
      sm:3.5,
      xs: 12,
      labelSM: 5,
      labelMD: 5,
      labelLG: 5,
      valueSM: 7,
      valueMD: 7,
      valueLG: 7,
      showLabel:true,
      error:"From Date",
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
     
    },
    {
      label: "",
      controlType: "datepicker",
      placeHolder: "",
      md: 2,
      lg: 2,
      sm: 2,
      xs: 12,
      hideLabel:true,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM: 11,
      valueMD: 11,
      valueLG: 11,
      dataKey: "toDate",
      onSubmit: "compare",
      error:"To Date",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      showLabel:true,
      onSubmit: "compare",
      defaultValue: dayjs(new Date()),
    },
    {
      label: "",
      controlType: "autocomplete",
      placeHolder: "Select Company",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM: 11,
      valueMD: 11,
      valueLG: 11,
      error:"Company",
      masterName: "CompanyDetails",
    hideLabel:true,
      dataKey: "companyId",
      isRootLevelKey: true,
      isMandatory: true,
    },
   
    
  ]
};
