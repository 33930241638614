import { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";
import GroupSummarySubreportByAcc from "../AccountReport/AccGroupSumarySubReportByAccount";

const OpeningBalanceTable = ({ rowList = [], tableHead = [], formData={},classes,getList,dynamicMasterData}) => {
    const [openPopup, setOpenPopup] = useState(false);
    // const[dynamicMasterData,setDynamicMasterData]=useState({});
    const[accPopupata,setAccPopupata]=useState({})
 
    
    const togglePopup = () => {
        setOpenPopup(!openPopup);
        getList(formData.fromDate, formData.toDate, formData.companyId, formData.accountLedgerGroupsId)
      };

      const getByIdData = async (data) => {
        console.log("data")
        console.log(data)
        setOpenPopup(!openPopup);
       
        setAccPopupata({...data,name:data.accountLedgerName,accCode:data.accountLedgerCode,
            id:data.accountLedgerId})
      }

    return (
        <>
            <div style={{ overflow: "auto" }}>
                <table className={classes.MuiTable}>
                    <tr className={classes.MuiTableHeadRoot}>
                        {
                            tableHead.map((head) => {
                                return (<th style={{ textAlign:"center"}}  className={classes.MuiTableHeadCell} >{head.title}</th>)
                            })
                        } </tr>
                    {

                        rowList.map((data) => {
                           let creditTotal=0
    let debitTotal=0
                            return (
                                <>
                                 {
                                 data.showGroup==1?
                                    <tr >
                                        <td  style={{ textDecoration: "underline",fontWeight:fontWeight}} className={classes.MuiTableBodyCell} >
                                      {data.groupName}
                                        </td>
                                        <td   className={classes.MuiTableBodyCell}>

                                        </td>
                                        <td  className={classes.MuiTableBodyCell}>

                                        </td>
                                    </tr>:null}
                                    
                                    {
                                        data.getTrialBalanceReport.map((data) => {
                                            
                                                debitTotal=debitTotal+data.openingDebitAmount
                                          
                                                creditTotal=creditTotal+data.openingCreditAmount 
                                            
                                        
if(data.openingDebitAmount!=0||data.openingCreditAmount!=0){
    return (
        <>
        <tr>
        <td className={classes.MuiTableBodyCell} style={{textAlign:"left"}}>
                <>
                   {data.accountLedgerCode }
                </>

            </td>
            <td className={classes.MuiTableBodyCell}
            style={{
                cursor: "pointer",
                fontWeight: fontWeight,
              }}
            onClick={() => {
                                getByIdData(
                                    data
                             
                                );
                              }}>
                <>
                  { data.accountLedgerName }
                   
                </>

            </td>

            <td className={classes.MuiTableBodyCell} style={{textAlign:"right"}}>
                <>
                    {Math.abs( data.openingDebitAmount).toFixed(2)}
                </>

            </td>
            <td className={classes.MuiTableBodyCell} style={{textAlign:"right",textColor:"#000"}}>
                <>
                {Math.abs( data.openingCreditAmount).toFixed(2)}
                </>

            </td>
        </tr>
      
         
     </>
    )
}
                                           
                                              
                                           
                                          
                                        })
                                    }
                               {
                                creditTotal!=0||debitTotal!=0?
                                <tr >
                                 <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight , borderTop: border,borderBottom: border,}}>
                                          Total
                                       </td>
                                       <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight , borderTop: border,borderBottom: border,}}>
                                       </td>
                                       <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, borderTop: border, textAlign: "right" ,borderBottom: border,}}>
                                           {Math.abs(debitTotal).toFixed(2)}
                                       </td>
                                       <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, borderTop: border, textAlign: "right", borderBottom: border,}}>
                                           { Math.abs(creditTotal).toFixed(2)}
                                       </td>

                                   </tr>:null
                               }
                                
                     
                                  
                                 
                    
                                             
                                </>

                            )
                        })}
                       

                </table>
            </div>
            {openPopup && (
        <GroupSummarySubreportByAcc
          openPopup={openPopup}
          handleCloses={togglePopup}
          popUpData={Object.keys(accPopupata).length != 0 ? accPopupata : {}}
          dynamicMasterData={dynamicMasterData}
          accPopupata={Object.keys(accPopupata).length != 0 ? accPopupata : {}}

        />
      )}
        </>
    );
};



export default withStyles(styles)(OpeningBalanceTable);
