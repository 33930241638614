import React from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";

const LeftRightAdjustTable = ({ tableData = [], rowLink, classes }) => {


    let leftTotal = 0;
    let rightTotal = 0
    return (
        <>
            <div style={{ overflow: "auto" }}>
                <table style={{ width: "100%", border: "1px solid #c1c1c1", }}>
                    <tr style={{ borderBottom: "1px solid #c1c1c1" }}>
                        <td style={{ borderRight: "1px solid #c1c1c1", }}  >
                            <table style={{ width: "100%" }}>

                                <tr>
                                    <th align={"center"} className={classes.MuiTableBodyCellNew} style={{ borderRight: "1px solid #c1c1c1", textAlign: "center", width: "50%" }}>
                                        Group Name
                                    </th>
                                    <th align={"center"} className={classes.MuiTableBodyCellNew} style={{ textAlign: "center", width: "50%" }}>
                                        Amount
                                    </th>
                                </tr>

                            </table>
                        </td>
                        <td  >
                            <table style={{ width: "100%" }}>

                                <tr>
                                    <th align={"center"} className={classes.MuiTableBodyCellNew} style={{ borderRight: "1px solid #c1c1c1", textAlign: "center", width: "50%" }}>
                                        Group Name
                                    </th>
                                    <th textAlign={"center"} className={classes.MuiTableBodyCellNew} style={{ textAlign: "center", width: "50%" }}>
                                        Amount
                                    </th>

                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style={{
                            verticalAlign: 'top',
                            borderRight: "1px solid #c1c1c1"
                        }}>
                            <table style={{ width: "100%" }}>
                                {
                                    tableData.DynamicData && tableData.DynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment != "R").map((rowData) => {
                                        leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt
                                        return (
                                            <tr>
                                                <td onClick={() => {
                                                    rowLink(rowData, 'groupName')
                                                }} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                    {rowData.groupDetail.group_name}
                                                </td>
                                                <td align={"right"} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                    {Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)}
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                                {
                                    tableData.staticData && tableData.staticData.filter((rowData) => rowData.alligment != "R").map((rowData) => {
                                        leftTotal = leftTotal + rowData.amt
                                        return (
                                            <tr>
                                                {
                                                    rowData.manufactureAndProfit == 1 ?
                                                        <td onClick={() => {
                                                            rowLink(rowData)
                                                        }} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                            {rowData.name}
                                                        </td> : <td className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                            {rowData.name}
                                                        </td>
                                                }

                                                <td align={"right"} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                    {Math.abs(rowData.amt).toFixed(2)}
                                                </td>

                                            </tr>
                                        )
                                    })
                                }

                            </table>
                        </td>
                        <td style={{
                            verticalAlign: 'top'
                        }}>
                            <table style={{ width: "100%" }}>

                                {
                                    tableData.DynamicData && tableData.DynamicData.filter((rowData) => rowData.groupDetail != null && rowData.groupDetail.allignment == "R").map((rowData) => {
                                        rightTotal = rightTotal + rowData.creditAmt + rowData.debitAmt
                                        return (
                                            <tr>
                                                <td onClick={() => {
                                                    rowLink(rowData, 'groupName')
                                                }} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                    {rowData.groupDetail.group_name}
                                                </td>
                                                <td align={"right"} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                    {Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)}
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                                {
                                    tableData.staticData && tableData.staticData.filter((rowData) => rowData.alligment == "R").map((rowData) => {
                                        rightTotal = rightTotal + rowData.amt
                                        return (
                                            <tr>
                                                {
                                                    rowData.manufactureAndProfit == 1 ?
                                                        <td onClick={() => {
                                                            rowLink(rowData)
                                                        }} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                            {rowData.name}
                                                        </td> : <td className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                            {rowData.name}
                                                        </td>
                                                }

                                                <td align={"right"} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                                    {Math.abs(rowData.amt).toFixed(2)}
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </td>
                    </tr>
                    {
                        tableData.DynamicData && <tr style={{ borderTop: "1px solid #c1c1c1" }}>
                            <td style={{ borderRight: "1px solid #c1c1c1", }}>
                                <table style={{ width: "100%" }}>

                                    <tr>
                                        <td className={classes.MuiTableBodyCellNew} style={{ borderRight: "1px solid #c1c1c1", width: "50%" }}>
                                            Total
                                        </td>
                                        <td align={"right"} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                            {Math.abs(leftTotal).toFixed(2)}

                                        </td>

                                    </tr>

                                </table>
                            </td>
                            <td>
                                <table style={{ width: "100%" }}>

                                    <tr>
                                        <td className={classes.MuiTableBodyCellNew} style={{ borderRight: "1px solid #c1c1c1", width: "50%" }}>
                                            Total
                                        </td>
                                        <td align={"right"} className={classes.MuiTableBodyCellNew} style={{ width: "50%" }}>
                                            {Math.abs(rightTotal).toFixed(2)}
                                        </td>

                                    </tr>

                                </table>
                            </td>
                        </tr>
                    }


                </table>
            </div >
        </>
    );
};

export default withStyles(styles)(LeftRightAdjustTable);

