import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from 'react';
import { useEffect, useState } from "react";
import { LabelCompo } from "../../components/Comman/Label";
import { Paper ,Grid} from "@mui/material";
import BarChart from '../../components/Charts/BarChart';
import { blackColor } from "../../config/ColorObj";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import GroupSummarySubreportByAcc from "../AccountReport/AccGroupSumarySubReportByAccount";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const GroupSummarySubreportByGroup = ({ handleCloses,
  open,
  popUpData,
  dynamicMasterData,
  showLoador, showNotification
}) => {
  const [rowList, setRowList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [accPopupata, setAccPopupata] = useState({});
  const [groupName, setGroupName] = useState("");
  const [groupList, setGroupList] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    getDataByAPI(dynamicMasterData.companyId, dynamicMasterData.fromDate, dynamicMasterData.toDate, popUpData.groupId, popUpData.groupName)
  }, []);
  
  const getDataByAPI = (companyId, fromDate, toDate, groupId, groupNameData) => {
    setGroupName(groupNameData)
    showLoador({ loador: true });
    apiGet({

      url: endpoint.accountGroupSummaryByGroup + "?companyIds=" + companyId + "&fromDate=" + fromDate + "&toDate=" + toDate + "&groupId=" + groupId,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let rowData = data.data;

        let cerditTotal = 0;
        let debitTotal = 0;


       let rowList = rowData.map((row) => {
        debitTotal = ((+debitTotal) + (+row.debitAmt)).toFixed(2);

          cerditTotal = ((+cerditTotal) + (+row.creditAmt)).toFixed(2);
         
          return {
            nameLink:true,
            gruopOrAcc: row.gruopOrAcc,
            accCode: row.accCode,
            id: row.id,
            name: row.name,
            city:row.city==null?"-":row.city,
            debitAmt: Math.abs((+row.debitAmt)).toFixed(2),
            creditAmt: Math.abs((+row.creditAmt)).toFixed(2),
            flag:0,
          };
        });
        
        setRowList([...rowList,{nameLink:false,gruopOrAcc:"",accCode:"",id:"",name:"Total",city:"",debitAmt:Math.abs(debitTotal).toFixed(2),creditAmt:Math.abs(cerditTotal).toFixed(2),flag:1,}]);
        showLoador({ loador: false })

      } else {
        setRowList([])
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
        showLoador({ loador: false })
      }

    });
  }
  const rowLink = (rowData) => {
    if (rowData.gruopOrAcc == 0) {
      let filterGroupList = groupList.filter((row) => row.id === rowData.id)
      if (filterGroupList.length == 0) {
        setGroupList([...groupList, {
          id: rowData.id,
          groupName: rowData.name,
        }])
        setAccPopupata(rowData)
        getDataByAPI(dynamicMasterData.companyId, dynamicMasterData.fromDate, dynamicMasterData.toDate, rowData.id, rowData.name)
      }

    } else {

      setAccPopupata(rowData)
      setOpenPopup(!openPopup);
    }

  }

  const handleClose = () => {
    console.log(groupList);
    if (groupList.length == 0) {
      handleCloses();
    }
     else if (groupList.length == 1) {
      setGroupList([])
      getDataByAPI(dynamicMasterData.companyId, dynamicMasterData.fromDate, dynamicMasterData.toDate, popUpData.groupId, popUpData.groupName)
    } 
     else {

      let filterGroup = groupList[groupList.length - 1] ? groupList[groupList.length - 1] : {}
       if (Object.keys(filterGroup).length != 0) {
        getDataByAPI(dynamicMasterData.companyId, dynamicMasterData.fromDate, dynamicMasterData.toDate, filterGroup.id ? filterGroup.id : 0, filterGroup.groupName ? filterGroup.groupName : "")
      }
     }

  };

  const togglePopup = () => {
    console.log(groupList);
    setOpenPopup(!openPopup);
    if (groupList.length == 0) {
      getDataByAPI(dynamicMasterData.companyId, dynamicMasterData.fromDate, dynamicMasterData.toDate, popUpData.groupId, popUpData.groupName)

    }
    console.log(groupList);
    let filterGroup = groupList[groupList.length - 1] ? groupList[groupList.length - 1] : {}
    if (Object.keys(filterGroup).length != 0) {
     getDataByAPI(dynamicMasterData.companyId, dynamicMasterData.fromDate, dynamicMasterData.toDate, filterGroup.id ? filterGroup.id : 0, filterGroup.groupName ? filterGroup.groupName : "")
   }
    }

  const onSearch = (searchV) => {
    setSearchValue(searchV)
  };
  const getFilteredTableData = (tableData) => {

    let listData = tableData.filter((row) => {

      let isValid = false;

      if (row.name.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (row.city.toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase())
    ) {
      isValid = true;
    }
    if (row.debitAmt.toString()
    .toLowerCase()
    .includes(searchValue.toLowerCase())
  ) {
    isValid = true;
  }
      if (row.creditAmt.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
     
      // if (creditTotal.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      // if (debitTotalAmt.toString()
      //   .toLowerCase()
      //   .includes(searchValue.toLowerCase())
      // ) {
      //   isValid = true;
      // }
      return isValid;

    });
    return listData
  };

  const generateExcelMethod = () => {
    var columns = ["Sr No.","Account Head","City", "Debit" ,"Credit"];
    let alignList = [];

    let rows = []

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.name)
      reportObj.push(reportData.city)
      reportObj.push(reportData.debitAmt)
      reportObj.push(reportData.creditAmt)
      


      rows.push(reportObj)



      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: ["Date Range ", dynamicMasterData.fromDate + " To " + dynamicMasterData.toDate, "Company Name", dynamicMasterData.companyName

      ],
      title: "Group Summary",
    });

  };
  const generatePDFMethod = () => {
    var columns = [["Account Head","City", "Debit", "Credit"]];
    let tableList = []

    rowList.map(((rowData, index) => {

      let tableData = [];
     
      tableData.push(rowData.name)
      tableData.push(rowData.city)
      tableData.push(Math.abs(rowData.debitAmt))
      tableData.push(Math.abs(rowData.creditAmt))
     




      tableList.push(tableData)
    }))

    // tableList.push("Total");
    // tableList.push("");
    // tableList.push({creditAmt:creditTotalAmt});
    // tableList.push({debitAmt:debitTotalAmt});


    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    // if(creditTotalAmt>= debitTotalAmt){
    //   tableList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})

    // }else{
    //   tableList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
    // }
    const columnStyles = {
     
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      columnStyles: columnStyles,
      headerList: [
        {
          label: "Date Range ",
          value: dynamicMasterData.fromDate + " To " + dynamicMasterData.toDate
        },
        {
          label: "Company Name",
          value: dynamicMasterData.companyName
        },
      ],
      pdfTitle: "Group Summary",
    });

  };
  return (
    <>
    
    <BootstrapDialog
          onClose={handleCloses}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
         maxWidth="xl"
        >
<BootstrapDialogTitle
            id="customized-dialog-title"
            // onClose={handleCloses}
          >
          <MyComponentWithIconProps
            statusImage={KeyboardBackspaceIcon}
            color="primary"
            fontSize="medium"
            title="Back"
            onClick={(e) => handleClose()}
          /> &nbsp;&nbsp;
          {groupName}  </BootstrapDialogTitle>
        <DialogContent dividers>
          <LandingScreenHeader
            screenTitle=""
            showTable={true}
            dynamicMasterData={{}}
            showBackToList={false}
            DataToSet={[]}
            fieldMeta={[]}
            showSaveBtnMain={true}
            onSearch={onSearch}
            showPdfDownload={true}
              showExcelDownload={true}
            generatePDF={generatePDFMethod}
            generateExcel={generateExcelMethod}

          />
          <RTTable
            showPegination={false}
            columns={[
            

              { name: "name", title: "Account Head", linkFlag: true ,linkName:"nameLink"},
              { name: "city", title: "City" },
              { name: "debitAmt", title: "Debit", align: "right" ,fontWeight: "bold"},
              { name: "creditAmt", title: "Credit", align: "right",fontWeight: "bold" }
             
            ]}
            hiddenColumnNames={[]}
            tableData={getFilteredTableData(rowList)}
            isActionColActive={false}
            showEditIcon={false}
            showDeleteIcon={false}
            // rowDelete={rowDelete}
            showCopyIcon={false}
            // rowCopy={this.rowCopy}
            rowLink={rowLink}
          />
           {
                rowList.length != 0 && <Grid sx={{ paddingTop: 2 }} container spacing={1} justifyContent="center">
                    <Grid container xs={12} sm={8} md={8} lg={8}>
                    <Grid sx={{ padding: 1 }} item xs={12} sm={10} md={10} lg={10}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Group Summary By Group'}
                                    />
                                </div>

                                <BarChart
                                height={200}
                                    series={[{
                                      name:"debit amount",
                                        data: rowList.filter((row)=>row.flag==0 && row.debitAmt!="" ).map((rowData) => {
                                            return rowData.debitAmt
                                        }),
                                    }, {
                                      name:"credit amount",
                                        data: rowList.filter((row)=>row.flag==0 && row.creditAmt!="" ).map((rowData) => {
                                            return rowData.creditAmt
                                        }),
                                    }]}
                                    options={
                                        {
                                            chart: {
                                                type: 'bar',
                                                height: 200,
                                                toolbar: {
                                                    show: false,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },

                                            xaxis: {
                                                categories:  rowList.filter((row)=>row.flag==0 && row.name!="" && row.name!="Total").map((rowData) => {
                                                    return rowData.name
                                                }),
                                            },
                                            grid: {
                                                show: false,
                                            },


                                        }
                                    }

                                />
                                
                            </Paper>
                        </Grid>
                       
                        
                    </Grid>
                </Grid>
            }
        </DialogContent>
       
        </BootstrapDialog>
      
      {openPopup && (
        <GroupSummarySubreportByAcc
          openPopup={openPopup}
          handleCloses={togglePopup}
          popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
          dynamicMasterData={dynamicMasterData}
          accPopupata={Object.keys(accPopupata).length != 0 ? accPopupata : {}}

        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };
export default connect(mapStateToProps, mapDispatchToProps)(GroupSummarySubreportByGroup);
