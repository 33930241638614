import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  subVoucherDetail: [],
  voucher: [],
  accountLedger: [],
  accountLedgerByType: [],
  accountLedgerByType1: [],
};
let URL = endpoints.subVoucherDetail;
let URL1 = endpoints.accountLedger;
const subVoucherSlice = createSlice({
  name: "subVoucherDetail",
  initialState,
  reducers: {
    subVoucherDetailSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subVoucherDetail: row,
      };
    },

    voucherSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        voucher: row,
      };
    },

    accountLedgerSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        accountLedger: row,
      };
    },

    accountLedgerByTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        accountLedgerByType: row,
      };
    },
    accountLedgerByType1Success: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        accountLedgerByType1: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  subVoucherDetailSuccess,
  voucherSuccess,
  accountLedgerSuccess,
  accountLedgerByTypeSuccess,
  accountLedgerByType1Success,
  resetState,
} = subVoucherSlice.actions;

export default subVoucherSlice.reducer;

// export const getVoucher = () => async (dispatch) => {
//   try {
//     const response = apiGet({
//       //   url: URL + '?sort={"sortOrder": "ASC"}',
//       url: URL1,
//     }).then(({ data, success }) => {
//       if (success) {
//         const content = data.data;
//         const row = content.map((voucherObject, index) => {
//           let areaData = {
//             ...voucherObject,
//             // index: index + 1,
//             id: voucherObject.id === null ? "" : voucherObject.id,
//             name:
//               voucherObject.voucherName === null
//                 ? ""
//                 : voucherObject.voucherName,
//           };
//           return areaData;
//         });
//         dispatch(voucherSuccess({ row }));
//         return { response: row, success };
//       }
//       return { response: [], success };
//     });
//     return response;
//   } catch (e) {
//     return console.error(e.message);
//   }
// };

export const getSubVoucherDetail = () => async (dispatch) => {
  try {
    const response = apiGet({
      //   url: URL + '?sort={"sortOrder": "ASC"}',
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const row = content.map((subVoucherDetailObject, index) => {
          let areaData = {
            index: index + 1,
            id:
              subVoucherDetailObject.id === null
                ? ""
                : subVoucherDetailObject.id,
            subVoucherName:
              subVoucherDetailObject.subVoucherMaster !== null &&
              subVoucherDetailObject.subVoucherMaster.subVoucherName
                ? subVoucherDetailObject.subVoucherMaster.subVoucherName
                : "",
            columnName:
              subVoucherDetailObject.columnName === null
                ? ""
                : subVoucherDetailObject.columnName,
            ledgerAccountName:
              subVoucherDetailObject.ledgerAccountNo === null
                ? ""
                : subVoucherDetailObject.ledgerAccountNo,
            pojoName:
              subVoucherDetailObject.pojoName === null
                ? ""
                : subVoucherDetailObject.pojoName,
            subVoucherMaster:
              subVoucherDetailObject.subVoucherMaster !== null &&
              subVoucherDetailObject.subVoucherMaster
                ? subVoucherDetailObject.subVoucherMaster
                : "",
          };
          return areaData;
        });
        dispatch(subVoucherDetailSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// http://localhost:8091/v1/sub-voucher-detail/by-sub-voucher?subVoucherId=c7caf967-c2da-472e-837a-6c0b1f1900f5

export const getSubVoucherDetailById =
  ({ subVoucherId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        //   url: URL + '?sort={"sortOrder": "ASC"}',
        url: URL + "/by-sub-voucher?subVoucherId=" + subVoucherId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((subVoucherDetailObject, index) => {
            let areaData = {
              index: index + 1,
              id:
                subVoucherDetailObject.id === null
                  ? ""
                  : subVoucherDetailObject.id,
              subVoucherName:
                subVoucherDetailObject.subVoucherMaster !== null &&
                subVoucherDetailObject.subVoucherMaster.subVoucherName
                  ? subVoucherDetailObject.subVoucherMaster.subVoucherName
                  : "",
              columnName:
                subVoucherDetailObject.columnName === null
                  ? ""
                  : subVoucherDetailObject.columnName,
              ledgerAccountName:
                subVoucherDetailObject.ledgerAccountNo === null
                  ? ""
                  : subVoucherDetailObject.ledgerAccountNo,
              pojoName:
                subVoucherDetailObject.pojoName === null
                  ? ""
                  : subVoucherDetailObject.pojoName,
                  type:subVoucherDetailObject.type === null
                  ? ""
                  : subVoucherDetailObject.type,
                  orderBy:subVoucherDetailObject.orderBy === null
                  ? ""
                  : subVoucherDetailObject.orderBy,
              subVoucherMaster:
                subVoucherDetailObject.subVoucherMaster !== null &&
                subVoucherDetailObject.subVoucherMaster
                  ? subVoucherDetailObject.subVoucherMaster
                  : "",
                 
            };
            return areaData;
          });
          dispatch(subVoucherDetailSuccess({ row }));
          return { response: row, success };
        }
        dispatch(subVoucherDetailSuccess({ row: [] }));
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getAccountLedgerList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((accountLedgerListObject, index) => {
          let accountLedgerData = {
            // index: index + 1,
            ...accountLedgerListObject,
            id1:
              accountLedgerListObject.id === null
                ? ""
                : accountLedgerListObject.id,
            id:
              accountLedgerListObject.accountLedgerCode === null
                ? ""
                : accountLedgerListObject.accountLedgerCode,
            name:
              (accountLedgerListObject.accountLedgerCode === null
                ? ""
                : accountLedgerListObject.accountLedgerCode) +
              (accountLedgerListObject.accountLedgerName === null
                ? ""
                : " ( " + accountLedgerListObject.accountLedgerName + " )"),
          };
          return accountLedgerData;
        });
        dispatch(accountLedgerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccountLedgerListByType =
  ({ accType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/get-all-by-accType?accType=" + accType,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((accountLedgerListObject, index) => {
            let accountLedgerData = {
              // index: index + 1,
              ...accountLedgerListObject,
              id1:
                accountLedgerListObject.id === null
                  ? ""
                  : accountLedgerListObject.id,
              id:
                accountLedgerListObject.accountLedgerCode === null
                  ? ""
                  : accountLedgerListObject.accountLedgerCode,
              name: (accountLedgerListObject.accountLedgerName === null
                ? ""
                :  accountLedgerListObject.accountLedgerName )+
                (accountLedgerListObject.accountLedgerCode === null
                  ? ""
                  :" ( " + accountLedgerListObject.accountLedgerCode + " )") 
               ,
                  
            };
            return accountLedgerData;
          });
          dispatch(accountLedgerByTypeSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAccountLedgerListByType1 =
  ({ accType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/get-all-by-accType?accType=" + accType,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((accountLedgerListObject, index) => {
            let accountLedgerData = {
              // index: index + 1,
              ...accountLedgerListObject,
              id1:
                accountLedgerListObject.id === null
                  ? ""
                  : accountLedgerListObject.id,
              id:
                accountLedgerListObject.accountLedgerCode === null
                  ? ""
                  : accountLedgerListObject.accountLedgerCode,
              name: (accountLedgerListObject.accountLedgerName === null
                ? ""
                : accountLedgerListObject.accountLedgerName )+
                (accountLedgerListObject.accountLedgerCode === null
                  ? ""
                  : "(" + accountLedgerListObject.accountLedgerCode+ " )") 
               ,
            };
            return accountLedgerData;
          });
          dispatch(accountLedgerByType1Success({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getAreaByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((area, index) => {
          let areaForBeatData = {
            index: index + 1,
            id: area.id === null ? "" : area.id,
            name: area.name === null ? "" : area.name,
            shortName: area.shortName === null ? "" : area.shortName,
            sortOrder: area.sortOrder === null ? "" : area.sortOrder,
          };
          return areaForBeatData;
        });
        dispatch(subVoucherDetailSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAreaByZoneIds =
  ({ zoneIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-zones?zoneIds=" + zoneIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((area, index) => {
            let areaForBeatData = {
              index: index + 1,
              id: area.id === null ? "" : area.id,
              name: area.name === null ? "" : area.name,
              shortName: area.shortName === null ? "" : area.shortName,
              sortOrder: area.sortOrder === null ? "" : area.sortOrder,
            };
            return areaForBeatData;
          });
          dispatch(subVoucherDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
