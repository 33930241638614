import {
  Grid
} from "@mui/material";
import { Paper } from "@mui/material";
import swal from "sweetalert";
import dayjs from "dayjs";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { CreditNoteJSON } from "../../DynamicFormsJson/Transaction/accCreditNote";
import { getCashRecieptPendingList } from "../../Slice/cashReceipt.slice";
import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreenNew";
import CreditNoteForm from "./CreditNoteForm";
import CreditNoteFormEdit from "./CreditNoteFormEdit";
import CreditNoteView from "./CreditNoteView";
import NoDataFound from "../../components/NoDataFound"
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import { deleteFailedMsg, deletemsg, noInternetMsg, serverMsg } from "../../config/messageconstant";
import { creditColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { apiDelete } from "../../utils/api_service";
class CreditNoteApprovalTable extends React.Component {
  constructor(props) { 
    super(props);
    this.state = {
      editData: {},
      viewData: {},
      setFieldData: {}
    };
  }

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    this.onSearchData()
  }

  onSearchData = async () => {
    const { getCashRecieptPendingList, showLoador, showNotification } =
      this.props;
    const locationId = localStorage.getItem("location");
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getCashRecieptPendingList({
        type: 6,
        locationId: locationId,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (response.length != 0) {
            this.rowLink(response[0])
          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  rowEdit = () => {
    this.setState(
      {
        editData: this.state.viewData,
        viewData: {},
      }
    )
  };
  callApi = () => {
    this.onSearchData()
    this.rowReset()
  }
  rowLink = (rowData) => {
    this.setState(
      {
        viewData: rowData
      }
    )
  }
  rowReset = () => {
    this.setState(
      {
        editData: {},
        viewData: {},
      }
    )
  }
  rowDelete = (rowData) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onDelete = async () => {
    const {
      deleteMsgAuto = false,
    } = this.props;
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiDelete({
        url: CreditNoteJSON.apiBaseURL + "/" + this.state.viewData.id,
      }).then(({ data, success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({
            msg: deleteMsgAuto ? data.data.message : deletemsg,
            severity: data.data.error ? "error" : "success",
          });

          this.callApi();

        } else {
          this.props.showNotification({
            msg: deleteFailedMsg,
            severity: "error",
          });

          this.props.showLoador({ loador: false });
        }
      });

    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  render() {
    const { dynamicMasterData, editData, viewData, setFieldData } = this.state;
    const { cashRecieptList } = this.props;
    // console.log(cashRecieptList?.cashReciept);
    return (
      <>
        {/* <Paper
                  sx={{
                      p: 2,
                      pt: 2,
                      borderRadius: 2,
                  }}
                  elevation={0}
              > */}
        <Grid sx={{ height: "100vh" }} container spacing={1}>

          <Grid item xs={12} sm={7} md={7}>
            {
              Object.keys(editData).length != 0
                  ? <CreditNoteFormEdit rowReset={this.rowReset} approvalFlag={1} editData={editData} callApi={this.callApi} />
                  : <CreditNoteView rowDelete={this.rowDelete} rowReset={this.rowReset} rowEdit={this.rowEdit} approvalFlag={1} editData={viewData} callApi={this.callApi} />

            }
          </Grid>
          <Grid sx={{ borderLeft: "2px solid #CFCFCF" }} item xs={12} sm={5} md={5}>
            <DynamicMainScreen
              showSearch={true}
              showDeleteIcon={true}
              dynamicMasterData={dynamicMasterData}
              searchList={[]}
              onSearchData={this.onSearchData}
              formPathView={CreditNoteJSON.formPathView}
              formPath={CreditNoteJSON.formPath}
              screenTitle={"Credit Note Approval List"}
              fieldMeta={CreditNoteJSON.fieldMeta}
              getListById={this.getListById}
              rowLink={this.rowLink}
              showPdfDownload={false}
              showExcelDownload={false}
              isActiveURL={CreditNoteJSON.isActiveURL}
              showAddButton={false}
              tableColumnsToFilter={CreditNoteJSON.tableColumnsToFilter}
              tableColumns={creditColumns}
              tableData={cashRecieptList?.cashRecieptPendingList}
              apiBaseURL={CreditNoteJSON.apiBaseURL}
              callApi={false}
              getTableDataById={true}
              showSearchBox={true}
              rowPDF={this.rowPDF}
              showPdf={false}
              showEditIcon={false}
              showViewButtonInTable={false}
              showPegination={false}
              isNote={false}
              buttonCenter={false}
              isNoteValue={"Manage Stock For Product"}
              editConditionValue="0"
              editConditionName="status"
              showApprovalIcon={true}
              searchButton={false}
              onChangeApprovalStatus={this.onChangeApprovalStatus}
            />
          </Grid>
        </Grid>
        {/* </Paper> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subVoucherList: state.subVoucher,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getSubVoucherByType,
  getCashRecieptPendingList,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreditNoteApprovalTable);