import {
    Grid,
    Paper
} from "@mui/material";
import React from "react";
import NoData from '../Images/No data Image.jpg'
class NoDataFound extends React.Component {
    render() {
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        pt: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <Grid sx={{ height: "100vh" }} container spacing={1}>
                        <img src={NoData} alt="No Data" width="80%" height="50%" />
                    </Grid>
                </Paper>
            </>
        );
    }
}

export default NoDataFound