import { useState, useEffect } from "react";
import RTTable from "../../components/Comman/RT/RTTable";
import { apiGet, apiPost } from "../../utils/api_service";
import {
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  urlMsg,
} from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { AccountSummaryJson } from "../../DynamicFormsJson/Report/AccountSummary";
import { Paper } from "@mui/material";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicDetails";
import moment from "moment";
import swal from "sweetalert";

import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { generatePDF, generateExcel } from "../../components/GeneratePDFExcel";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor, grayColor } from "../../config/ColorObj";
import BarChart from "../../components/Charts/BarChart";
import {
  getCashRecieptWithAccNameById,
  getCostCenter,
} from "../../Slice/cashReceipt.slice";
import CashReciptPopupForReport from "../CashReceipt/CashReciptPopupForReport";
import BankReciptPopupForReport from "../BankReciept/BankReciptPopupForReport";
import CashPaymentPopupForReport from "../CashPayment/CashPaymentPopupForReport";
import BankPaymentPopupForReport from "../BankPayment/BankPaymentPopupForReport";
import JouranlEntryPopupForReport from "../JournalEntry/JournalEntryPopupForReport";
import ContraEntryPopupForReport from "../ContraEntry/ContraEntryPopupForReport";
import DebiteNotePopupForReport from "../DebitNote/DebiteNotePopupForReport";
import CreditNotePopupForReport from "../CreditNote/CrediteNotePopupForReport";
import { getJVWithAccNameById } from "../../Slice/journalVoucher.slice";
import { ButtonCompo } from "../../components/Comman/Button";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const GroupSummarySubreportByAcc = ({
  handleCloses,
  popUpData,
  showLoador,
  showNotification,
  products,
}) => {
  const [dataToSet, setDataToSet] = useState([
    {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
      label: "Type",
      value: popUpData.type != 0 ? "With GRN" : "Without GRN",
    },

    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Customer Name",
      value: popUpData.name,
    },
  ]);

  const handleClose = () => {
    handleCloses();
  };
  //   const onSave = (searchData) => {
  //     let date1 =
  //       searchData != null
  //         ? moment(searchData.fromDate).format("YYYY-MM-DD")
  //         : "";
  //     let date2 =
  //       searchData != null ? moment(searchData.toDate).format("YYYY-MM-DD") : "";
  //     if (searchData != null) {
  //       apiGet({
  //         url:
  //           endpoint.accountGroupSummary +
  //           "?companyIds=" +
  //           searchData.companyId.id +
  //           "&fromDate=" +
  //           "2023-11-01" +
  //           "&toDate=" +
  //           "2023-11-30",
  //       }).then(({ data, success }) => {
  //         if (success) {
  //           let rowData = data.data;
  //           let index = 0;
  //           let cerditTotal = 0;
  //           let debitTotal = 0;
  //           let opBalance = 0;

  //           // cretditTotal:cerditTotal?(cerditTotal).toFixed(2):"0.00",
  //           // debitTotal:debitTotal?(debitTotal).toFixed(2):"0.00",
  //           // opBalance:cerditTotal>debitTotal?(+cerditTotal)-(+debitTotal):(+debitTotal)-(cerditTotal),
  //           let rowList = rowData.map((row) => {
  //             index = index + 1;
  //             cerditTotal = cerditTotal + row.creditAmt;
  //             debitTotal = debitTotal + row.debitAmt;
  //             opBalance=cerditTotal-cerditTotal;
  //             return {
  //               index: index,
  //               ...row,
  //             };
  //           });
  //           rowList.push({index:"Total",groupName:"",creditAmt:cerditTotal,debitAmt:debitTotal})
  //           if(cerditTotal>= debitTotal){
  //             rowList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})
  //           }else{
  //             rowList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
  //           }

  //           setRowList(rowList);
  //         }
  //       });
  //     }
  //   };

  const generatePDFMethod = () => {
    var columns = [
      [
        "Date",
        "Naration",
        "Bill No.",
        "Bill Date",
        "Debit",
        "Credit",

        "Closing Bal",
        "Type",
        "Book",
      ],
    ];
    let tableList = [];

    rowList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.documentDate);
      tableData.push(rowData.refAccName);
      tableData.push(rowData.documentNo);
      tableData.push(rowData.transactionDate);
      tableData.push(Math.abs(rowData.openingDdebitAmt));
      tableData.push(Math.abs(rowData.openingCreditAmt));

      tableData.push(Math.abs(rowData.documentAmt));

      tableData.push(rowData.type);
      tableData.push(rowData.bookType);

      tableList.push(tableData);
    });

    // tableList.push("Total");
    // tableList.push("");
    // tableList.push({creditAmt:creditTotalAmt});
    // tableList.push({debitAmt:debitTotalAmt});

    // const columnStylesPlain = {
    //   0: { textColor: [3, 169, 244], columnWidth: 20, cellWidth: "wrap" }, // Red text color for column 0
    //   1: { textColor: "#000", columnWidth: 60, cellWidth: "wrap" }, // Blue text color for column 1
    //   2: { textColor: [3, 169, 244],columnWidth: 40, cellWidth: "wrap" }, // Green text color for column 2
    //   3: { textColor: "#000", columnWidth: 40, cellWidth: "wrap" } // Red text color for column 0
    // };
    // if(creditTotalAmt>= debitTotalAmt){
    //   tableList.push({index:"Op Balance",groupName:"",creditAmt:opBalance,debitAmt:""})

    // }else{
    //   tableList.push({index:"Diff in Op Balance",groupName:"",creditAmt:"",debitAmt:opBalance})
    // }
    const columnStyles = {
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };

    generatePDF({
      columnStylesPlain: columnStyles,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Date Range ",
          value: popUpData.fromDate + " To " + popUpData.toDate,
        },
        {
          label: "Company Name",
          value: popUpData.companyName,
        },
        { label: "Group", value: popUpData.groupName },
        { label: "From Leger", value: popUpData.name },
        {
          label: "To Leger",
          value: popUpData.name,
        },
      ],
      pdfTitle: "Group Summary",
    });
  };

  const generateExcelMethod = () => {
    var columns = [
      "Sr No.",
      "Date",
      "Naration",
      "Bill No.",
      "Bill Date",
      "Debit",
      "Credit",

      "Closing Bal",
      "Type",
      "Book",
    ];
    let alignList = [];

    let rows = [];

    rowList.map((reportData, index) => {
      let reportObj = [index + 1];
      reportObj.push(reportData.documentDate);
      reportObj.push(reportData.refAccName);
      reportObj.push(reportData.documentNo);
      reportObj.push(reportData.transactionDate);
      reportObj.push(Math.abs(reportData.openingDdebitAmt));

      reportObj.push(Math.abs(reportData.openingCreditAmt));
      reportObj.push(Math.abs(reportData.documentAmt));

      reportObj.push(reportData.type);
      reportObj.push(reportData.bookType);

      rows.push(reportObj);

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("")
      // reportObj.push(debitTotal==0?"": Math.abs(debitTotal) )
      // reportObj.push(creditTotal==0?"":Math.abs(creditTotal) )
      // rows.push(reportObj)

      // reportObj = [];
      // reportObj.push("")
      // reportObj.push("Day Closing")
      // reportObj.push("")
      // reportObj.push(Math.abs(reportData.closingAmt) )
      // rows.push(reportObj)
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Date Range ",
        popUpData.fromDate + " To " + popUpData.toDate,
        "Company Name",
        popUpData.companyName,
        "Group",
        popUpData.groupName,

        "From Leger",
        popUpData.name,

        "To Leger",
        popUpData.name,
      ],
      title: "Group Summary",
    });
  };

  const rowLink = async (rowData) => {
    console.log(rowData);
  };
  const saveData = () => {
    console.log(popUpData);
    console.log(products);
    const locationId = localStorage.getItem("location");
    const purchaseSalesVoucherDetailList = products.map((row) => {
      return {
        productId: row.productId,
        billQty: row.qty,
        mrnRate: row.returnRate,
        itemCode: row.productCode,
        cgstPer: row.cgstPer,
        sgstPer: row.sgstPer,
        igstPer: row.igstPer,
        taxId: row.taxId,

        qty: row.qty,
        rate: row.returnRate,
        rateType: row.rateType,
      };
    });
    let dataToSet = {
      accountLedgerCode: popUpData.code,
      text1: popUpData.stateId ? popUpData.stateId : "",

      locationId: locationId,
      text4: popUpData.grn,
      // "text2": "1476cc6d-0220-4e9a-81d5-6fdc17212d5d",
      // "text3": "5c1e96cf-7c30-419b-adfd-7ab3e9f4eff2",
      customerCode: popUpData.code,
      entryType: 4,
      purchaseSalesVoucherDetailList: purchaseSalesVoucherDetailList,
    };

    console.log(dataToSet);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        onSave(dataToSet);
      }
    });
  };

  const onSave = async (objectToSave) => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.purchaseSaleHeader + "/credit-note-save",
        postBody: objectToSave,
      }).then(({ data, success }) => {
        if (success) {
          showNotification({ msg: savemsg, severity: "success" });
          handleCloses(true);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
      showLoador({ loador: false });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Credit Note View
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid container xs={12} sm={12} md={12} lg={12}>
                <DynamicFormWithoutSave
                  showBackToList={false}
                  DataToSet={dataToSet}
                  showSaveBtnMain={false}
                  padding={false}
                  saveBtnText={"Search"}
                  paddingTop={false}
                  showExcelDownload={true}
                />

                <RTTable
                  showPegination={false}
                  columns={
                    popUpData.type == 1
                      ? [
                          { name: "index", title: "Sr No." },
                          { name: "productName", title: "Product Name" },
                          { name: "storeUomName", title: "UOM" },
                          { name: "returnRate", title: "Rate", align: "right" },
                          { name: "qty", title: "Quantity", align: "right" },
                          { name: "amount", title: "Amount", align: "right" },
                        ]
                      : [
                          { name: "index", title: "Sr No." },
                          { name: "name", title: "Product Name" },
                          { name: "storeUomName", title: "UOM" },
                          {
                            name: "productRate",
                            title: "Rate",
                            align: "right",
                          },
                          { name: "qty", title: "Quantity", align: "right" },
                          { name: "amount", title: "Amount", align: "right" },
                        ]
                  }
                  hiddenColumnNames={[]}
                  tableData={products}
                  isActionColActive={false}
                  showEditIcon={false}
                  showDeleteIcon={false}
                  rowLink={rowLink}
                  showCopyIcon={false}
                  generatePDF={generatePDFMethod}
                  generateExcel={generateExcelMethod}
                />
              </Grid>
              <br />
              <br />
              <Grid
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ paddingTop: 2 }}
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={saveData}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupSummarySubreportByAcc);
