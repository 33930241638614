import {
    Grid
} from "@mui/material";
import { Paper } from "@mui/material";
import swal from "sweetalert";
import dayjs from "dayjs";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { getCashReciept } from "../../Slice/cashReceipt.slice";
import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreenNew";
import AssetForm from "./AssetCalculationForm";
import { apiGet } from "../../utils/api_service";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import { deleteFailedMsg, deletemsg, noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { apiDelete } from "../../utils/api_service";
import { AssetJSON } from "../../DynamicFormsJson/Transaction/Asset";
import { AssetColumns } from "../../tableColumns/table-columns";
import AssetView from "./AssetView";
import AssetFormEdit from "./AssetCalculationFormEdit"
class AssetTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
            },
            searchInfo: {},
            editData: {},
            viewData: {},
            openTable: false,
            rowList: [],
            formData: {},
            setFieldData: {},

        
        };
    }


    



    onSearchData = async (data) => {
        const { showLoador, showNotification } = this.props;
        this.setState({
            searchInfo: data
        })
        console.log(data);
        const locationId = localStorage.getItem("location");
        if (navigator.onLine) {

            showLoador({ loador: true });
            if (data != null) {
                showLoador({ loador: true });
                apiGet({


                    url:
                        endpoint.asset + "/by-date?fromDate=" + data.fromDate + "&toDate=" + data.toDate + "&locationId=" + locationId,
                }).then(({ data, success }) => {

                    showLoador({ loador: true });
                    if (success) {

                        const rowList = data.data.length != 0 ? data.data.map((row, index) => {

                            index = index + 1
                            return {

                                index: index,
                                id: row.id,
                                ledgerCode: row.ledgerCode,
                                date: row.date,
                                depreciationPercentage: row.depreciationPercentage ? row.depreciationPercentage.toFixed(2) : "",
                                openingValue: row.openingValue ? row.openingValue.toFixed(2) : "",

                                purchaseInFirstHalf: row.purchaseInFirstHalf,
                                purchaseInSecondHalf: row.purchaseInSecondHalf,

                                sellValue: row.sellValue,
                                closingValue: row.closingValue ? row.closingValue.toFixed(2) : "",

                                locationName: row.location != null && row.location.name ? row.location.name : "",
                                dvalue: row.openingValue + row.purchaseInFirstHalf + row.purchaseInSecondHalf - row.sellValue,
                            };
                        }) : [];
                        console.log("rowList")
                        console.log(rowList)


                        this.setState({
                            rowList: rowList,
                        });
                        // if( rowList.length!=0){
                        //     this.setState({viewData:rowList[0]})
                        //    }
                        showLoador({ loador: false })
                    } else {
                        showNotification({
                            msg: serverMsg,
                            severity: "error",
                        });
                        showLoador({ loador: false })
                    }
                });


            };
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    rowEdit = () => {
        this.setState(
            {
                editData: this.state.viewData,
                viewData: {},

            }
        )
        // this.props.navigate("/cash-receipt-form/" + rowData.id + "/" + 0);
    };
    callApi = () => {
        const { searchInfo } = this.state
        if (Object.keys(searchInfo).length != 0) {
            this.onSearchData(searchInfo)
        }
        this.rowReset()
    }
    rowLink = (rowData) => {
        this.setState(
            {
                viewData: rowData
            }
        )
    }
    rowReset = () => {
        this.setState(
            {
                viewData: {},
                editData: {}
            }
        )
    }
    rowDelete = (rowData) => {

        swal({
            title: "Are you sure?",
            text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onDelete(rowData);
            }
        });
    };
    onDelete = async () => {
        const {
            deleteMsgAuto = false,
        } = this.props;
        if (navigator.onLine) {
            this.props.showLoador({ loador: true });
            apiDelete({
                url: AssetJSON.apiBaseURL + "/" + this.state.viewData.id,
            }).then(({ data, success }) => {
                if (success) {
                    this.props.showLoador({ loador: false });
                    this.props.showNotification({
                        msg: deleteMsgAuto ? data.data.message : deletemsg,
                        severity: data.data.error ? "error" : "success",
                    });

                    this.callApi();

                } else {
                    this.props.showNotification({
                        msg: deleteFailedMsg,
                        severity: "error",
                    });

                    this.props.showLoador({ loador: false });
                }
            });

        } else {
            this.props.showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };
    componentDidMount() {
        const currentDate = dayjs();
        let setFieldData = {

        }
        let callApiData = {
            toDate: currentDate.format("DD-MM-YYYY"),
            fromDate: currentDate.startOf('month').format("DD-MM-YYYY")
        }
        this.setState({
            setFieldData: {
                toDate: currentDate,
                fromDate: currentDate.startOf('month')
            }
        });
        this.onSearchData(callApiData)
    }
    rowView = (rowData) => {
        const { viewData } = this.state
        this.setState({
            openTable: true,
            formData: rowData,
        })
        // window.location.replace("/asset-form-view/"+ rowData.id);
    };
    toggle = () => {

        this.setState({
            openTable: !this.state.openTable,



        })
    }

    render() {
        const { dynamicMasterData, setFieldData, editData, viewData, rowList, openTable, formData } = this.state

        const { cashRecieptList } = this.props;
        // console.log(cashRecieptList?.cashReciept);
        return (
            <>
                {/* <Paper
                    sx={{
                        p: 2,
                        pt: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                > */}
                <Grid sx={{ height: "100vh" }} container spacing={1}>

                    <Grid item xs={12} sm={7} md={7}>
                        {
                            Object.keys(viewData).length != 0

                                ? <AssetView rowDelete={this.rowDelete} rowReset={this.rowReset} rowEdit={this.rowEdit} approvalFlag={0} editData={viewData} callApi={this.callApi} open={openTable}
                                    handleCloses={this.toggle}
                                    popUpData={Object.keys(formData).length != 0 ? formData : {}} />
                                : Object.keys(editData).length != 0
                                    ? <AssetFormEdit rowReset={this.rowReset} approvalFlag={0} editData={editData} callApi={this.callApi} />
                                    : <AssetForm rowReset={this.rowReset} callApi={this.callApi} />

                        }
                        
                    </Grid>

                    <Grid sx={{ borderLeft: "2px solid #CFCFCF" }} item xs={12} sm={5} md={5}>
                        <DynamicMainScreen
                            setFieldData={setFieldData}
                            showDeleteIcon={true}
                            dynamicMasterData={dynamicMasterData}
                            searchList={AssetJSON.searchList}
                            onSearchData={this.onSearchData}
                            formPathView={AssetJSON.formPathView}
                            formPath={AssetJSON.formPath}
                            screenTitle={AssetJSON.screenTitle}
                            fieldMeta={AssetJSON.fieldMeta}
                            getListById={this.getListById}
                            rowLink={this.rowLink}
                            isActiveURL={AssetJSON.isActiveURL}
                            showAddButton={AssetJSON.showAddButton}
                            tableColumnsToFilter={AssetJSON.tableColumnsToFilter}
                            tableColumns={AssetColumns}
                            tableData={rowList}
                            apiBaseURL={AssetJSON.apiBaseURL}
                            callApi={false}
                            chipCompoColor={true}
                            getTableDataById={true}
                            showPdf={false}
                            showEditIcon={true}
                            showViewButtonInTable={true}
                            showPegination={false}
                            isNote={false}
                            GridSave={1.5}
                            buttonCenter={false}
                            editConditionValue="0"
                            editConditionName="status"
                            deleteConditionValue="0"
                            deleteConditionName="status"

                        />
                    </Grid>
                </Grid>

                {/* </Paper> */}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    subVoucherList: state.subVoucher,
    cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getSubVoucherByType,
    getCashReciept,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AssetTable);