import endpoint from "../../config/endpoints";

export const userAccessJson = {
  showSaveNextBtn: true,
  formPath: "/user-access-form",
  apiBaseURL: endpoint.userAccess,
  screenTitle: "User Access",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "User Access",
  showExcelDownload: true,
  excelFileName: "User Access",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Type",
      columnKeyName: "type",
      isChecked: true,
    },
  ],
};
