import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText, Paper } from "@mui/material";
import {
  saveMasterGenerate,
  setSaveMasterGenerate,
} from "../../Slice/MasterGenerate.slice";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import * as msgConstant from "../../config/messageconstant";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import RTTable from "../../components/Comman/RT/RTTable";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import AutoComplete from "../../components/Comman/AutoComplete";
import {
  onlyNumber,
  validText,
} from "../../components/Comman/Util/Validations";
import MultipleSelect from "../../components/Comman/MultipleSelect";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddFormFields = ({
  openDialog,
  setOpenDialouge,
  masterGenerateData,
  formFields = [],
  saveMasterGenerate,
  showLoador,
  showNotification,
  rowData = {},
}) => {
  const [localList, setLocalList] = React.useState([]);
  const [formDataLocal, setFormDataLocal] = React.useState({});

  const [formData, setFormData] = React.useState({});
  const [formErrors, setFormError] = React.useState({});

  const getTableColums = () => {
    const filterData = formFields.filter((parameter) => {
      return (
        parameter.controlType == "autocomplete" ||
        parameter.controlType == "multiSelect" ||
        parameter.controlType == "radiogroup"
      );
    });
    return filterData.map((parameter) => {
      return { id: parameter.dataKey, name: parameter.label };
    });
  };
  React.useEffect(() => {
    console.log(rowData);
    if (Object.keys(rowData).length != 0) {
      console.log(rowData);
      setFormData({
        ...rowData,
        labelName: rowData.label,
        isLocal: rowData.localList && rowData.localList.length != 0 ? "1" : "0",
        getListFrom: rowData.getListFrom.split(","),
      });
      setLocalList(rowData.localList ? rowData.localList : []);
    } else {
      setFormData({});
      setFormDataLocal({});
      setLocalList([]);
    }
    setFormError({});
  }, []);
  const handleClose = () => {
    setOpenDialouge(false);
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (
      formData["controlType"] === undefined ||
      formData["controlType"] == ""
    ) {
      formIsValid = false;
      formErrors["controlType"] = msgConstant.controlTypeMsg;
    }
    if (formData["labelName"] === undefined || formData["labelName"] == "") {
      formIsValid = false;
      formErrors["labelName"] = msgConstant.labelMsg;
    }
    if (
      formData["placeHolder"] === undefined ||
      formData["placeHolder"] == ""
    ) {
      formIsValid = false;
      formErrors["placeHolder"] = msgConstant.placeHolderMsg;
    }
    if (formData["dataKey"] === undefined || formData["dataKey"] == "") {
      formIsValid = false;
      formErrors["dataKey"] = msgConstant.fieldNameMsg;
    }
    const filterData = formFields.filter((parameter) => {
      return parameter.dataKey == formData["dataKey"];
    });

    if (filterData.length != 0 && formData.index == undefined) {
      formIsValid = false;
      formErrors["dataKey"] = msgConstant.fieldNameUniqueMsg;
    }

    if (
      formData["controlType"] == "radiogroup" ||
      formData["controlType"] == "multiSelect" ||
      formData["controlType"] == "autocomplete"
    ) {
      if (formData["isLocal"] == "1") {
        if (localList.length == 0) {
          formIsValid = false;
          formErrors["localList"] = msgConstant.localListMsg;
        }
      } else {
        if (
          formData["selectKey"] === undefined ||
          formData["selectKey"] == ""
        ) {
          formIsValid = false;
          formErrors["selectKey"] = msgConstant.keyMsg;
        }
        if (
          formData["selectLabel"] === undefined ||
          formData["selectLabel"] == ""
        ) {
          formIsValid = false;
          formErrors["selectLabel"] = msgConstant.labelMsg;
        }
        if (formData["url"] === undefined || formData["url"] == "") {
          formIsValid = false;
          formErrors["url"] = msgConstant.urlMsg;
        }
      }
    }

    setFormError(formErrors);
    return formIsValid;
  };
  const checkValidationAdd = () => {
    let formErrors = {};
    let formIsValid = true;

    if (formDataLocal["key"] === undefined || formDataLocal["key"] == "") {
      formIsValid = false;
      formErrors["key"] = msgConstant.keyMsg;
    }
    if (formDataLocal["label"] === undefined || formDataLocal["label"] == "") {
      formIsValid = false;
      formErrors["label"] = msgConstant.labelMsg;
    }

    setFormError(formErrors);
    return formIsValid;
  };
  const onSubmitData = () => {
    if (checkValidation()) {
      let dataToSave = {
        label: formData.labelName,
        controlType: formData.controlType,
        placeHolder: formData.placeHolder,
        masterName: formData.dataKey,
        getListFrom: formData.getListFrom ? formData.getListFrom.join() : "",
        dataKey: formData.dataKey,
        tableDataKey: formData.tableDataKey,
        getListId: formData.getListId ? formData.getListId : "",
        url: formData.url ? formData.url : "",
        selectKey: formData.selectKey ? formData.selectKey : "",
        selectLabel: formData.selectLabel ? formData.selectLabel : "",
        localList: localList,
        isRootLevelKey: formData.isRootLevelKey ? formData.isRootLevelKey : "",
        validCondition: formData.validCondition ? formData.validCondition : "",
        validText: formData.validText ? formData.validText : "",
        inputTypeData: formData.inputTypeData ? formData.inputTypeData : "",
        validName: formData.validName ? formData.validName : "",
        disable: formData.disable ? formData.disable : 0,
        maxLength: formData.maxLength ? formData.maxLength : "",
        valid: formData.valid ? (formData.valid == 1 ? true : false) : false,
        isMandatory: formData.isMandatory
          ? formData.isMandatory == 1
            ? true
            : false
          : true,
        disableTrue: formData.disableTrue ? formData.disableTrue : 0,
        // isMandatory: formData.isMandatory
        //   ? formData.isMandatory == 0
        //     ? false
        //     : true
        //   : true,
      };

      if (formData.controlType == "selectImage") {
        dataToSave = {
          ...dataToSave,
          dataKeyToSave: formData.dataKey,
          dataKey: "imageToShow",
        };
      }
      if (formData.validName != "" || formData.validName != null) {
        dataToSave = {
          ...dataToSave,
          valid: true,
        };
      }

      let parameterList = JSON.parse(masterGenerateData.masterData.formFields)
        ? JSON.parse(masterGenerateData.masterData.formFields)
        : [];
      if (formData.getListFrom) {
        const parameterData = parameterList.map((parameter) => {
          const filterData = formData.getListFrom.filter(
            (listData) => parameter.dataKey == listData
          );
          if (filterData.length != 0) {
            return { ...parameter, getListId: formData.getListFrom.join() };
          }
          return parameter;
        });
        console.log(parameterData);
      }
      let parameter = "";
      if (formData.index) {
        const parameterInfoList = parameterList.map((parameterInfo, index) => {
          const indexData = index + 1;
          if (indexData == formData.index) {
            return dataToSave;
          }
          return parameterInfo;
        });
        console.log(parameterInfoList);
        parameter = JSON.stringify([...parameterInfoList]);
      } else {
        parameter = JSON.stringify([...parameterList, dataToSave]);
      }

      dataToSave = {
        ...masterGenerateData.masterData,
        formFields: parameter,
      };
      console.log(dataToSave);
      onSave(dataToSave);
    }
  };
  const onSave = (dataToSave) => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      saveMasterGenerate({ dataToSave }).then(({ success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
          });

          handleClose();
        } else {
          showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
        }
      });
    } else {
      showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  const onAddData = () => {
    if (checkValidationAdd()) {
      setFormDataLocal({});
      console.log(formDataLocal);
      let localListData = [
        ...localList,
        { index: localList.length + 1, ...formDataLocal },
      ];
      setLocalList(localListData);
    }
  };

  const onRadioChange = (name) => (event) => {
    setFormData({
      ...formData,
      [name]: event.target.value,
    });
  };
  const onTextFieldDataChange = (inputType) => (event) => {
    if (inputType == "number") {
      if (event.target.value === "" || onlyNumber.test(event.target.value)) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      }
    } else if (inputType == "text") {
      if (event.target.value === "" || validText.test(event.target.value)) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      }
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
      // validAll
    }
  };
  const onTextFieldLocalDataChange = (event) => {
    setFormDataLocal({
      ...formDataLocal,
      [event.target.name]: event.target.value,
    });
  };
  const onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      setFormData({
        ...formData,
        [name]: newValue,
        isLocal: newValue == "radiogroup" ? "1" : "0",
        isRootLevelKey: newValue == "radiogroup" ? "1" : "0",
      });
    } else {
      setFormData({
        ...formData,
        [name]: "",
      });
    }
  };
  const onMultiSelectChange = (value, name) => {
    let dataValue = typeof value === "string" ? value.split(",") : value;

    setFormData({
      ...formData,
      [name]: dataValue,
    });
  };
  const rowDelete = (rowData) => {
    console.log(localList);
    console.log(rowData);
    const filterData = localList.filter((row) => row.index != rowData.index);

    let localListData = filterData.map((data, index) => {
      return { ...data, index: index + 1 };
    });
    setLocalList(localListData);
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Filter
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container sx={{ marginTop: 2 }} spacing={1}>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Control Type :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <AutoComplete
                    name={"controlType"}
                    label={"Control Type"}
                    placeHolder={"Control Type"}
                    keyColName={"id"}
                    value={
                      formData["controlType"] ? formData["controlType"] : ""
                    }
                    options={[
                      {
                        id: "autocomplete",
                        name: "Single Select Dropdown",
                      },
                      {
                        id: "multiSelect",
                        name: "Multiple Select",
                      },
                      {
                        id: "datepicker",
                        name: "Datepicker",
                      },
                      {
                        id: "textfield",
                        name: "Textfield",
                      },
                      {
                        id: "radiogroup",
                        name: "Radio Button",
                      },
                      {
                        id: "selectImage",
                        name: "Select Image",
                      },
                    ]}
                    onChange={onAutocompleteChange}
                    isError={formErrors["controlType"] ? true : false}
                    errorText={
                      formErrors["controlType"] ? formErrors["controlType"] : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Label Name :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Label"}
                    size="small"
                    color="primary"
                    name={"labelName"}
                    id={"labelName"}
                    value={formData["labelName"] ? formData["labelName"] : ""}
                    onChange={onTextFieldDataChange("text")}
                    fullWidth
                    errorText={
                      formErrors["labelName"] ? formErrors["labelName"] : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Placeholder :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Placeholder"}
                    size="small"
                    color="primary"
                    name={"placeHolder"}
                    id={"placeHolder"}
                    value={
                      formData["placeHolder"] ? formData["placeHolder"] : ""
                    }
                    onChange={onTextFieldDataChange("text")}
                    fullWidth
                    errorText={
                      formErrors["placeHolder"] ? formErrors["placeHolder"] : ""
                    }
                  />
                </Grid>
              </Grid>
              {formData["controlType"] &&
                formData["controlType"] == "textfield" && (
                  <>
                    <Grid container xs={12} sm={6} md={6}>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Input Datatype :"}
                        />
                      </Grid>

                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <AutoComplete
                          name={"inputTypeData"}
                          label={"Input Datatype"}
                          placeHolder={"Input Datatype"}
                          keyColName={"id"}
                          value={
                            formData["inputTypeData"]
                              ? formData["inputTypeData"]
                              : ""
                          }
                          options={[
                            {
                              id: "text",
                              name: "Text",
                            },
                            {
                              id: "number",
                              name: "Number",
                            },
                            {
                              id: "all",
                              name: "Symbols",
                            },
                          ]}
                          onChange={onAutocompleteChange}
                          isError={formErrors["inputTypeData"] ? true : false}
                          errorText={
                            formErrors["inputTypeData"]
                              ? formErrors["inputTypeData"]
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid container xs={12} sm={6} md={6}>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Max length :"}
                        />
                      </Grid>

                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <TextFieldCompo
                          placeHolder={"Max length"}
                          size="small"
                          color="primary"
                          name={"maxLength"}
                          id={"maxLength"}
                          value={
                            formData["maxLength"] ? formData["maxLength"] : ""
                          }
                          inputType={"number"}
                          onChange={onTextFieldDataChange("number")}
                          fullWidth
                          errorText={
                            formErrors["maxLength"]
                              ? formErrors["maxLength"]
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Field Name :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Field Name"}
                    size="small"
                    color="primary"
                    name={"dataKey"}
                    id={"dataKey"}
                    value={formData["dataKey"] ? formData["dataKey"] : ""}
                    onChange={onTextFieldDataChange("text")}
                    fullWidth
                    errorText={
                      formErrors["dataKey"] ? formErrors["dataKey"] : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 14, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 14,
                      color: titleColor,
                    }}
                    label={"Edit Data Key :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Edit Data Key"}
                    size="small"
                    color="primary"
                    name={"tableDataKey"}
                    id={"tableDataKey"}
                    value={formData["tableDataKey"] ? formData["tableDataKey"] : ""}
                    onChange={onTextFieldDataChange("text")}
                    fullWidth
                    errorText={
                      formErrors["tableDataKey"] ? formErrors["tableDataKey"] : ""
                    }
                  />
                </Grid>
              </Grid>

              {formData["controlType"] != "radiogroup" && (
                <>
                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Is Disable during edit:"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <RadioGroup
                        row
                        aria-label={"disable"}
                        name={"disable"}
                        id={"disable"}
                        value={formData["disable"] ? formData["disable"] : 0}
                        onChange={onRadioChange("disable")}
                        size="small"
                      >
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={1}
                          control={<Radio />}
                          label={"Yes"}
                        />
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={0}
                          control={<Radio />}
                          label={"No"}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Is Valid :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <RadioGroup
                    row
                    aria-label={"valid"}
                    name={"valid"}
                    id={"valid"}
                    value={formData["valid"] ? formData["valid"] : 0}
                    onChange={onRadioChange("valid")}
                    size="small"
                  >
                    <FormControlLabel
                     sx={{
                      "& svg": {
                        width: "0.8em",
                        height: "0.8em"
                      }
                    }}
                      value={1}
                      control={<Radio />}
                      label={"True"}
                    />
                    <FormControlLabel
                     sx={{
                      "& svg": {
                        width: "0.8em",
                        height: "0.8em"
                      }
                    }}
                      value={0}
                      control={<Radio />}
                      label={"False"}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>

              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Special Validations :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <AutoComplete
                    name={"validName"}
                    label={"Special Validations"}
                    placeHolder={"Special Validations"}
                    keyColName={"id"}
                    value={formData["validName"] ? formData["validName"] : ""}
                    options={[
                      {
                        id: "email",
                        name: "Email",
                      },
                      {
                        id: "gstNo",
                        name: "GST No.",
                      },
                      {
                        id: "phoneNo",
                        name: "Phone No.",
                      },
                      {
                        id: "greater-less",
                        name: "Greater Less",
                      },
                      {
                        id: "greater-than",
                        name: "Greater Than",
                      },
                    ]}
                    onChange={onAutocompleteChange}
                    isError={formErrors["validName"] ? true : false}
                    errorText={
                      formErrors["validName"] ? formErrors["validName"] : ""
                    }
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Valid condition :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Label"}
                    size="small"
                    color="primary"
                    name={"validCondition"}
                    id={"validCondition"}
                    value={
                      formData["validCondition"]
                        ? formData["validCondition"]
                        : ""
                    }
                    onChange={onTextFieldDataChange("text")}
                    fullWidth
                    errorText={
                      formErrors["validCondition"]
                        ? formErrors["validCondition"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Valid Text :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Label"}
                    size="small"
                    color="primary"
                    name={"validText"}
                    id={"validText"}
                    value={formData["validText"] ? formData["validText"] : ""}
                    onChange={onTextFieldDataChange("text")}
                    fullWidth
                    errorText={
                      formErrors["validText"] ? formErrors["validText"] : ""
                    }
                  />
                </Grid>
              </Grid>

              {formData["controlType"] != "radiogroup" && (
                <>
                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Is Disable :"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <RadioGroup
                        row
                        aria-label={"disableTrue"}
                        name={"disableTrue"}
                        id={"disableTrue"}
                        value={
                          formData["disableTrue"] ? formData["disableTrue"] : 0
                        }
                        onChange={onRadioChange("disableTrue")}
                        size="small"
                      >
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={1}
                          control={<Radio />}
                          label={"Yes"}
                        />
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={0}
                          control={<Radio />}
                          label={"No"}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </>
              )}

              {formData["controlType"] != "radiogroup" && (
                <>
                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Is Mandatory :"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <RadioGroup
                        row
                        aria-label={"isMandatory"}
                        name={"isMandatory"}
                        id={"isMandatory"}
                        value={
                          formData["isMandatory"]
                            ? formData["isMandatory"] == true
                              ? 1
                              : 0
                            : 1
                        }
                        onChange={onRadioChange("isMandatory")}
                        size="small"
                      >
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={1}
                          control={<Radio />}
                          label={"True"}
                        />
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={0}
                          control={<Radio />}
                          label={"False"}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </>
              )}

              {formData["controlType"] &&
                (formData["controlType"] == "autocomplete" ||
                  formData["controlType"] == "multiSelect" ||
                  formData["controlType"] == "radiogroup") && (
                  <>
                    {formData["controlType"] != "radiogroup" && (
                      <>
                        <Grid container xs={12} sm={6} md={6}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            &nbsp;&nbsp;
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, color: redColor }}
                              label={"*"}
                            />
                            <LabelCompo
                              className="text-black"
                              style={{
                                fontSize: 16,
                                color: titleColor,
                              }}
                              label={"Is Local List:"}
                            />
                          </Grid>

                          <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RadioGroup
                              row
                              aria-label={"isLocal"}
                              name={"isLocal"}
                              id={"isLocal"}
                              value={
                                formData["isLocal"] ? formData["isLocal"] : 0
                              }
                              onChange={onRadioChange("isLocal")}
                              size="small"
                            >
                              <FormControlLabel
                               sx={{
                                "& svg": {
                                  width: "0.8em",
                                  height: "0.8em"
                                }
                              }}
                                value={1}
                                control={<Radio />}
                                label={"Yes"}
                              />
                              <FormControlLabel
                                value={0}
                                sx={{
                                  "& svg": {
                                    width: "0.8em",
                                    height: "0.8em"
                                  }
                                }}
                                control={<Radio />}
                                label={"No"}
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {formData["controlType"] != "radiogroup" && (
                      <>
                        <Grid container xs={12} sm={6} md={6}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            &nbsp;&nbsp;
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, color: redColor }}
                              label={"*"}
                            />
                            <LabelCompo
                              className="text-black"
                              style={{
                                fontSize: 16,
                                color: titleColor,
                              }}
                              label={"Is Rootlevel Key:"}
                            />
                          </Grid>

                          <Grid item xs={12} sm={8} md={8} lg={8}>
                            <RadioGroup
                              row
                              aria-label={"isRootLevelKey"}
                              name={"isRootLevelKey"}
                              id={"isRootLevelKey"}
                              value={
                                formData["isRootLevelKey"]
                                  ? formData["isRootLevelKey"]
                                  : 0
                              }
                              onChange={onRadioChange("isRootLevelKey")}
                              size="small"
                            >
                              <FormControlLabel
                               sx={{
                                "& svg": {
                                  width: "0.8em",
                                  height: "0.8em"
                                }
                              }}
                                value={1}
                                control={<Radio />}
                                label={"Yes"}
                              />
                              <FormControlLabel
                               sx={{
                                "& svg": {
                                  width: "0.8em",
                                  height: "0.8em"
                                }
                              }}
                                value={0}
                                control={<Radio />}
                                label={"No"}
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {formData["controlType"] != "radiogroup" &&
                      formData["isLocal"] == 0 && (
                        <>
                          <Grid container xs={12} sm={6} md={6}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                              &nbsp;&nbsp;
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                              />
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 16,
                                  color: titleColor,
                                }}
                                label={"Dropdown Id :"}
                              />
                            </Grid>

                            <Grid item xs={12} sm={8} md={8} lg={8}>
                              <TextFieldCompo
                                placeHolder={"Key"}
                                size="small"
                                color="primary"
                                name={"selectKey"}
                                id={"selectKey"}
                                value={
                                  formData["selectKey"]
                                    ? formData["selectKey"]
                                    : ""
                                }
                                onChange={onTextFieldDataChange("text")}
                                fullWidth
                                errorText={
                                  formErrors["selectKey"]
                                    ? formErrors["selectKey"]
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container xs={12} sm={6} md={6}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                              &nbsp;&nbsp;
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                              />
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 16,
                                  color: titleColor,
                                }}
                                label={"Drowdown Value :"}
                              />
                            </Grid>

                            <Grid item xs={12} sm={8} md={8} lg={8}>
                              <TextFieldCompo
                                placeHolder={"Label"}
                                size="small"
                                color="primary"
                                name={"selectLabel"}
                                id={"selectLabel"}
                                value={
                                  formData["selectLabel"]
                                    ? formData["selectLabel"]
                                    : ""
                                }
                                onChange={onTextFieldDataChange("text")}
                                fullWidth
                                errorText={
                                  formErrors["selectLabel"]
                                    ? formErrors["selectLabel"]
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container xs={12} sm={6} md={6}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                              &nbsp;&nbsp;
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                              />
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 16,
                                  color: titleColor,
                                }}
                                label={"URL :"}
                              />
                            </Grid>

                            <Grid item xs={12} sm={8} md={8} lg={8}>
                              <TextFieldCompo
                                placeHolder={"URL"}
                                size="small"
                                color="primary"
                                name={"url"}
                                id={"url"}
                                value={formData["url"] ? formData["url"] : ""}
                                onChange={onTextFieldDataChange("text")}
                                fullWidth
                                errorText={
                                  formErrors["url"] ? formErrors["url"] : ""
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container xs={12} sm={6} md={6}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                              &nbsp;&nbsp;
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                              />
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 16,
                                  color: titleColor,
                                }}
                                label={"Dependent On :"}
                              />
                            </Grid>

                            <Grid item xs={12} sm={8} md={8} lg={8}>
                              <MultipleSelect
                                label={"Dependent On"}
                                er={""}
                                name1={"getListFrom"}
                                value1={
                                  formData["getListFrom"]
                                    ? formData["getListFrom"]
                                    : []
                                }
                                names={getTableColums()}
                                onChange={onMultiSelectChange}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                    {formData["isLocal"] && formData["isLocal"] == 1 && (
                      <>
                        <Grid container xs={12} sm={6} md={6}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            &nbsp;&nbsp;
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, color: redColor }}
                              label={"*"}
                            />
                            <LabelCompo
                              className="text-black"
                              style={{
                                fontSize: 16,
                                color: titleColor,
                              }}
                              label={"Key :"}
                            />
                          </Grid>

                          <Grid item xs={12} sm={8} md={8} lg={8}>
                            <TextFieldCompo
                              placeHolder={"Key"}
                              size="small"
                              color="primary"
                              name={"key"}
                              id={"key"}
                              value={
                                formDataLocal["key"] ? formDataLocal["key"] : ""
                              }
                              onChange={onTextFieldLocalDataChange}
                              fullWidth
                              errorText={
                                formErrors["selectKey"]
                                  ? formErrors["selectKey"]
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container xs={12} sm={6} md={6}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            &nbsp;&nbsp;
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, color: redColor }}
                              label={"*"}
                            />
                            <LabelCompo
                              className="text-black"
                              style={{
                                fontSize: 16,
                                color: titleColor,
                              }}
                              label={"Label :"}
                            />
                          </Grid>

                          <Grid item xs={12} sm={8} md={8} lg={8}>
                            <TextFieldCompo
                              placeHolder={"Label"}
                              size="small"
                              color="primary"
                              name={"label"}
                              id={"label"}
                              value={
                                formDataLocal["label"]
                                  ? formDataLocal["label"]
                                  : ""
                              }
                              onChange={onTextFieldLocalDataChange}
                              fullWidth
                              errorText={
                                formErrors["label"] ? formErrors["label"] : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <br />
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          rowSpacing={2}
                          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                          container
                          justifyContent="right"
                        >
                          <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name="Add"
                            onClick={onAddData}
                          />
                        </Grid>
                        <FormHelperText error>
                          {formErrors["localList"]
                            ? formErrors["localList"]
                            : ""}
                        </FormHelperText>
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ marginTop: 3 }}
                        >
                          <RTTable
                            columns={[
                              {
                                title: "Sr. No.",
                                name: "index",
                                showInExcel: true,
                                align: "center",
                              },
                              {
                                title: "Datakey",
                                name: "key",
                                formDataKey: "key",
                                showInExcel: true,
                                canSearch: true,
                              },
                              {
                                title: "Label",
                                name: "label",
                                formDataKey: "label",
                                showInExcel: true,
                                canSearch: true,
                              },

                              { name: "action", title: "Action" },
                            ]}
                            hiddenColumnNames={[]}
                            tableData={localList}
                            rowDelete={rowDelete}
                            showPegination={false}
                            showEditIcon={false}
                            isActionColActive={true}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
            </Grid>
            <br />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="submit"
                onClick={onSubmitData}
              />
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  report: state.report,
  masterGenerateData: state.masterGenerate,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  setSaveMasterGenerate,
  saveMasterGenerate,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFormFields);
