import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicPreview from '../../components/Preview/DynamicPreview'
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTableWithoutBorder from "../../components/Preview/DynamicPreview";
import { apiGet } from "../../utils/api_service";
const Sample = ({ }) => {
    const [open, setOpen] = useState(false);

    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
// useEffect(()=>
// {
//      apiGet({
//       url:
//         "/v1/report/cashbook?companyIds=f8fa0540-5397-11ee-8c99-0242ac120002&fromDate=2023-10-02&toDate=2023-11-05",
//     }).then(({ data, success }) => {
//       console.log(JSON.parse(data.data[0].transactionDetail[0].headerData))
//     });
// },[])
   
    const title = [
        [
            {
                name: "name",
                clickeble: true,
                colSpan: 4,
              },
        ],
        [ {
            name: "date",
            title: "Candidate Name",
            colSpan: 3,
          },
          {
            name: "accNo",
            title: "Gender",
            colSpan: 0,
            clickeble: true,
          },
          {
            name: "cheqNo",
            title: "Date of Birth",
            colSpan: 0,
          },
          {
            name: "passDate",
            title: "Date of Birth",
            colSpan: 0,
            style:{
                textAlign:"right"
            }
          },
        ],
        [ {
           
          },
          {
         
          },
          {
            
          },
          {
            name: "passDate",
            title: "Date of Birth",
            colSpan: 0,
            style:{
                borderTop: "1px solid #000",
                textAlign:"right"
            }
          },
        ],
        
      ];
    const data = [
        {
            
            cheqNo: "Opening Balance",
            accNo: "sdfg2",
            date: "7 june",
            passDate: "234",
            payment: "8677657",
            received: "53",
            name:"Day Closing",
           
        },
        {
            cheqNo: "Closing Balance",
            accNo: "AJH",
            date: "23 june",
            amt: "2315",
            cpBal: "234",
            passDate: "8677657",
            received: "53",
            name:"Day Closing",
           
        }
    ]
const changeHandler=(row,data)=>{
console.log(row)
console.log(data)

}

    return (
        <>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                <LandingScreenHeader
                    screenTitle={"Report"}
                    showTable={false}
                    showBackButton={false}
                    showAddButton={false}

                />
                <br />
                <br />
                <DynamicTableWithoutBorder tableHead={[{

                    title: "Date",
                    name:"date"

                }, {

                    title: "Account Head",
                    name:"date"


                }, {

                    title: "Cheq No",
                     name:"date"


                },{

                    title: "Pass Date",
                    name:"date"


                },]}changeHandler={changeHandler} row={data} title={title} />
            </Paper>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sample);