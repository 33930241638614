import React from "react";
import { connect } from "react-redux";
import {
    Grid
} from "@mui/material";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import {
    setSaveAccountLedger,
} from "../../../Slice/accountLedger.slice";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { withRouter } from "../../../components/withRouter";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { LabelCompo } from "../../../components/Comman/Label";
import AttachFile from "../../../components/Comman/AttachFile";
import { ButtonCompo } from "../../../components/Comman/Button";
import { redColor } from "../../../config/ColorObj";
import { serverMsg } from "../../../config/messageconstant";
import endpoint from "../../../config/endpoints";
import { apiPostForImage } from "../../../utils/api_service";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

class AccountLedger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: "",
            error: false
        };
    }
    onCancel = () => {
        this.props.navigate(-1);
    };
    changeH = (fileName, name, index) => {
        this.setState({
            ...this.state,
            fileName: fileName,
        });
    };
    onFormUpdate = () => {
        const { fileName } = this.state
        if (fileName != "") {
            const formData = new FormData();
            formData.append("dataFile", fileName);
            this.props.showLoador({ loador: true });
            apiPostForImage({
                url: endpoint.importData + "/account-ledger?locationId=7f11afa5-49b9-40e1-b9cb-6b407d04eb6a" ,
                postBody: formData,
            }).then(({ data, success }) => {
               
                if (success) {
                    if(data.data.error){
                        this.props.showNotification({ msg: data.data.message, severity: "error" });
                        
                    }else{
                        this.props.showNotification({
                            msg: "Data imported successfully",
                        });
                    }
                    this.props.showLoador({ loador: false });
                    
                } else {
                    this.props.showNotification({ msg: serverMsg, severity: "error" });
                    this.props.showLoador({ loador: false });
                }
            });
        } else {
            this.setState({
                error: true
            })
        }
        console.log(fileName)
    };
    render() {
        const { fileName, error } = this.state
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <TitleAndBreadCrum
                        tableColumnsToFilter={[]}
                        title={AccountLedgerJson.screenTitle}
                        showPdfDownload={false}
                        showExcelDownload={false}
                        showAddButton={false}
                        showTable={false}
                        showBackButton={true}
                        onCancel={this.onCancel}
                    />
                    <Grid container sx={{ marginTop: 2 }} spacing={1}>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            direction="row"
                            justifyContent="space-between"
                        >

                            <Grid
                                item
                                xs={12}
                                sm={2}
                                md={2}
                                lg={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                >
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label={'Import '}
                                    />
                                </Grid>
                                {
                                    error && <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 8, color: redColor }}
                                            label={'*Required '}
                                        />
                                    </Grid>
                                }

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                            >
                                <AttachFile
                                    name={"file"}
                                    fileName={this.changeH}
                                    allow={false}
                                />
                                &nbsp; {fileName !== "" ? fileName.name : ""}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={2}
                                md={2}
                                lg={2}
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    name="Import Data"
                                    // fullWidth={true}
                                    onClick={this.onFormUpdate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    accountLedgerList: state.accountLedger,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    setSaveAccountLedger
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AccountLedger);
