// import * as React from "react";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { YearCalendar } from "@mui/x-date-pickers/YearCalendar";
// import { MonthCalendar } from "@mui/x-date-pickers/MonthCalendar";
// import dayjs from "dayjs";

// export default function YearMonthCalendar({
//   type = "month",
//   name = "",
//   value = "",
//   onChange,
// }) {
//   const onChangeValue = (e) => {
//     onChange(e, name);
//   };
//   const onChangeYear = (e) => {
//     onChange(e, name);
//   };
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={["YearCalendar", "MonthCalendar"]}>
//         {type == "month" ? (
//           <DemoItem label="Year ">
//             <YearCalendar
//               value={value != "" ? value : dayjs()}
//               onChange={onChangeYear}
//             />
//           </DemoItem>
//         ) : (
//           <DemoItem label="Month ">
//             <MonthCalendar
//               value={value != "" ? value : dayjs()}
//               onChange={onChangeValue}
//             />
//           </DemoItem>
//         )}
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }
import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function YearMonthCalendar({
  type = "month",
  name = "",
  value = "",
  title = "",
  onChange,
  isError = false,
  errorText = " ",
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]}>
        <DatePicker
          onChange={(e) => {
            onChange(e, name);
          }}
          label={title}
          value={value}
          views={["month", "year"]}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "standard",
              error: isError,
              helperText: errorText,
              //   value: value,
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
