import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";

const initialState = {
  location: {},
};
let url = endpoints.location;
const locationSlice = createSlice({
  name: "locationSlice",
  initialState,
  reducers: {
    locationListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        location: row,
      };
    },
  },
});

export const { locationListSuccess } = locationSlice.actions;

export default locationSlice.reducer;

export const getMapData =
  ({ salesTeamId, date }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          url +
          "/by-sales-team-and-date?salesTeamId=" +
          salesTeamId +
          "&date=" +
          date,
      }).then(({ data, success }) => {
        if (success) {
          let locationDetails = data.data;
          let outletList =
            locationDetails.outletDetails != null
              ? locationDetails.outletDetails.map((outlet) => {
                  return {
                    id: outlet.id,
                    address: outlet.firmName,
                    lat: +outlet.latitude,
                    lng: +outlet.longitude,
                    icon: "https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png",
                  };
                })
              : [];
          let firebaseList = [];
          locationDetails.firebaseDetails != null &&
          locationDetails.firebaseDetails.salesPersonLocationDetails != null
            ? locationDetails.firebaseDetails.salesPersonLocationDetails.map(
                (outlet) => {
                  if (outlet.isOrder == 1) {
                    const locData = {
                      id: outlet.id,
                      address: outlet.outletName,
                      lat: +outlet.latitude,
                      lng: +outlet.longitude,
                      icon:
                        outlet.type == 1
                          ? "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png"
                          : "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
                    };
                    firebaseList.push(locData);
                  }
                }
              )
            : [];
          locationDetails = {
            ...locationDetails,
            outletList: [...outletList, ...firebaseList],
            firebaseList:
              locationDetails.firebaseDetails != null &&
              locationDetails.firebaseDetails.salesPersonLocationDetails != null
                ? locationDetails.firebaseDetails.salesPersonLocationDetails.map(
                    (outlet) => {
                      const locData = {
                        id: outlet.id,
                        lat: +outlet.latitude,
                        lng: +outlet.longitude,
                      };
                      return locData;
                    }
                  )
                : [],
          };
          console.log();
          dispatch(locationListSuccess({ row: locationDetails }));
          return { response: locationDetails, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
