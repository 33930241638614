import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadF } from "../../Pages/PDF/PDFDownload";
import swal from "sweetalert";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import * as msgConstant from "../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import RTTable from "../Comman/RT/RTTable";
import { withRouter } from "../withRouter";
import DynamicFormWithTable from "./DynamicFormWithTable";
import dayjs from "dayjs";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      fieldDataForSearch: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      fromDate: "",
      toDate: "",
    };
  }
  componentWillReceiveProps(nextProps) {

    if (this.props.setFieldData !== nextProps.setFieldData) {
      this.setState({
        fieldDataForSearch: nextProps.setFieldData,
      });
    }

  }
  componentDidMount() {
    const { callApi = true } = this.props;
    if (callApi) {
      this.getData();
    }

  }
  getData = async () => {
    const { getTableData, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getTableData().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: msgConstant.serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
    }
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  // onDataChangeForSearch = (fieldName, newValue) => {
  //   this.setState({
  //     fieldDataForSearch: {
  //       ...this.state.fieldDataForSearch,
  //       [fieldName]: newValue,
  //     },
  //   });
  // };
  onDataChangeForSearch = (fieldName, newValue) => {
    const { searchList } = this.props;
    const { fieldDataForSearch } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldDataForSearch };
    let listError = false;
    searchList.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldDataForSearch[key]) {
            dataToSearch[key] = fieldDataForSearch[key];
          }
          return null;
        });

        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldDataForSearch[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] =
          fieldDataForSearch[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldDataForSearch: dataToReset,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    const { formPath, paramsId } = this.props;
    if (paramsId) {
      this.props.navigate(formPath + "/" + paramsId);
    } else {
      this.props.navigate(formPath);
    }
  };
  rowAdd = (rowData) => {
    const { openFormPath } = this.props;
    this.props.navigate(openFormPath + "/" + rowData.id);
  };

  rowContact = (rowData) => {
    const { openContactPath } = this.props;
    this.props.navigate(openContactPath + "/" + rowData.id);
  };

  rowBank = (rowData) => {
    const { openBankPath } = this.props;
    this.props.navigate(openBankPath + "/" + rowData.id);
  };

  rowEdit = (rowData) => {
    const { formPath, rowEdit, editAuto = true } = this.props;
    if (editAuto) {
      this.props.navigate(formPath + "/" + rowData.id);
    } else {
      rowEdit(rowData);
    }
  };
  rowCopy = (rowData) => {
    const { formPath, rowCopy, editAuto = true } = this.props;
    if (editAuto) {
      this.props.navigate(formPath + "/" + rowData.id + "/1");
    } else {
      rowCopy(rowData);
    }
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  rowView = (rowData) => {
    const { formPathView, staticView = false, rowView } = this.props;
    if (staticView) {
      rowView(rowData)
    } else {
      this.props.navigate(formPathView + "/" + rowData.id);
    }

  };

  rowSend = (rowData) => {
    const { formPathForsend } = this.props;
    this.props.navigate(formPathForsend + "/" + rowData.id);
  };

  rowOutlet = (rowData) => {
    const { formPathForOutlet } = this.props;
    this.props.navigate(formPathForOutlet + "/" + rowData.id);
  };
  onSave = async (dataToSave, isToggle = true) => {
    const { getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (getTableDataById) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    const { apiBaseURL } = this.props;
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url: apiBaseURL,
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          const { currentOperationMode } = this.state;
          this.props.showNotification({
            msg:
              currentOperationMode === OperationMode.Add
                ? msgConstant.savemsg
                : currentOperationMode === OperationMode.Status
                  ? msgConstant.statusmsg
                  : msgConstant.updateMsg,
          });
          const { getTableDataById = false } = this.props;
          if (getTableDataById) {
            this.props.onSearchData(dataToSearchList);
          } else {
            this.getData();
          }
          if (isToggle) {
            this.setState({
              fieldData: {},
              currentOperationMode: "",
            });
          }
          if (!isToggle) {
            let fieldData = {};
            const { fieldMeta } = this.props;
            fieldMeta.map((currentField) => {
              if (currentField.defaultValue) {
                fieldData[currentField.dataKey] = currentField.defaultValue;
              }
              if (
                currentField.controlType === "autocomplete" &&
                !currentField.isRootLevelKey
              ) {
                fieldData[currentField.dataKey] = dataToSave[
                  currentField.dataKey
                ]["id"]
                  ? dataToSave[currentField.dataKey]["id"]
                  : "";
              } else if (
                currentField.controlType === "autocomplete" &&
                !currentField.isRootLevelKey
              ) {
                fieldData[currentField.dataKey] = dataToSave[
                  currentField.dataKey
                ]
                  ? dataToSave[currentField.dataKey]
                  : "";
              }
              return null;
            });

            this.setState({
              fieldData: fieldData,
              currentOperationMode: OperationMode.Add,
            });
          }
        } else {
          this.props.showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  onStatusChange = async (dataToSave) => {
    const { isActiveURL = "", getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (getTableDataById) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url:
          isActiveURL +
          "?id=" +
          dataToSave.id +
          "&status=" +
          dataToSave.isActive,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({
            msg: "Status updated",
          });
          const { getTableDataById = false } = this.props;
          if (getTableDataById) {
            this.props.onSearchData(dataToSearchList);
          } else {
            const { callApi = true } = this.props;
            if (callApi) {
              this.getData();
            }
          }
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        } else {
          this.props.showNotification({
            msg: msgConstant.statusFailedMsg,
            severity: "error",
          });

          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  rowDelete = (rowData) => {
    const { deleteURLStatic = false, onDeleteStatic } = this.props;

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (deleteURLStatic) {
          onDeleteStatic(rowData);
        } else {
          this.onDelete(rowData);
        }
      }
    });
  };

  onDelete = async (rowdata) => {
    const { getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (getTableDataById) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    const {
      apiBaseURL,
      deleteURL = "",
      deleteMsgAuto = false,
      deleteMapping = true,
    } = this.props;
    if (navigator.onLine) {
      if (deleteMapping) {
        this.props.showLoador({ loador: true });
        apiDelete({
          url:
            deleteURL != ""
              ? deleteURL + "/" + rowdata.id
              : apiBaseURL + "/" + rowdata.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });

            if (getTableDataById) {
              this.props.onSearchData(dataToSearchList);
            } else {
              const { callApi = true } = this.props;
              if (callApi) {
                this.getData();
              }
            }
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });

            this.props.showLoador({ loador: false });
          }
        });
      } else {
        this.props.showLoador({ loador: true });
        apiGet({
          url: apiBaseURL + "/" + rowdata.id + "/delete-by-id",
        }).then(({ success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });
            const { getTableDataById = false } = this.props;
            if (getTableDataById) {
              this.props.onSearchData(dataToSearchList);
            } else {
              const { callApi = true } = this.props;
              if (callApi) {
                this.getData();
              }
            }
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });
            this.props.showLoador({ loador: false });
          }
        });
      }
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };

  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const { fieldMeta = [], isActiveURL = "" } = this.props;
        if (isActiveURL == "") {
          const dataToSave = {
            ...rowData,
            id: rowData["id"],
          };

          fieldMeta.forEach((currentField) => {
            if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              dataToSave[currentField.dataKey] = {};
              dataToSave[currentField.dataKey]["id"] = rowData[
                currentField.dataKey
              ]
                ? rowData[currentField.dataKey]
                : "";
            } else {
              dataToSave[currentField.dataKey] = rowData[currentField.dataKey]
                ? rowData[currentField.dataKey]
                : "";
            }
          });

          dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
          this.setState({
            currentOperationMode: OperationMode.Status,
          });
          this.onSave(dataToSave, false);
        } else {
          const dataToSave = {
            id: rowData["id"],
          };
          dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
          this.setState({
            currentOperationMode: OperationMode.Status,
          });
          this.onStatusChange(dataToSave, false);
        }
      }
    });
  };

  generateExcel = () => {
    const { changeExcelURL = "" } = this.props;

    this.downloadPDF(
      "xls",
      changeExcelURL != "" ? changeExcelURL : "excel-report"
    );
  };

  generatePDF = () => {
    const { changePDFURL = "" } = this.props;

    this.downloadPDF("pdf", changePDFURL != "" ? changePDFURL : "report");
  };
  downloadPDF = (ext, addURL) => {
    const { apiBaseURL } = this.props;

    downloadF({
      url: apiBaseURL + "/" + addURL + "?documentType=1",
      ext: ext,
      openNewTab: true,
    }).then((response) => {
      if (!response) {
        this.props.showNotification({
          msg: msgConstant.serverMsg,
          severity: "error",
        });
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  setFormData = (rowData) => {
    const { searchList = [] } = this.props;

    let fieldData = {};

    searchList.forEach((currentField) => {
      let dataToSearch = {};
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey];
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "autocomplete/textfield") {
        if (!currentField.isRootLevelKey) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[tableDataKey].id,
            },
          };
        } else {
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
        fieldData = {
          ...fieldData,
          [currentField.dataKeyName]: rowData[tableDataKey].name,
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: {
            id: rowData[tableDataKey].id,
          },
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "autocomplete" &&
        currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: rowData[tableDataKey],
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });

          this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          fieldData[currentField.dataKey] = dayjs(
            rowData[tableDataKey],
            "DD-MM-YYYY"
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] = rowData.isActive;

    return fieldData;
  };
  // setFormData = (fieldDataForSearch) => {
  //   const { searchList } = this.props;
  //   const dataToSave = {};
  //   searchList.forEach((currentField) => {
  //     if (currentField.controlType === "datepicker") {
  //       if (fieldDataForSearch[currentField.dataKey]) {
  //         dataToSave[currentField.dataKey] = fieldDataForSearch[
  //           currentField.dataKey
  //         ]
  //           ? dayjs(fieldDataForSearch[currentField.dataKey])
  //           : "";
  //       } else {
  //         dataToSave[currentField.dataKey] = "";
  //       }
  //     } else {
  //       dataToSave[currentField.dataKey] = fieldDataForSearch[
  //         currentField.dataKey
  //       ]
  //         ? fieldDataForSearch[currentField.dataKey]
  //         : "";
  //     }
  //   });
  //   return dataToSave;
  // };
  setFromDataForSearch = () => {
    const { fieldDataForSearch } = this.state;
    const { searchList = [], today } = this.props;
    const dataToSave = {};
    searchList.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        if (fieldDataForSearch[currentField.dataKey]) {
          if (!today) {
            dataToSave[currentField.dataKey] = fieldDataForSearch[
              currentField.dataKey
            ]
              ? fieldDataForSearch[currentField.dataKey].format("DD-MM-YYYY")
              : "";
          } else {
            dataToSave[currentField.dataKey] =
              fieldDataForSearch[currentField.dataKey] !== ""
                ? fieldDataForSearch[currentField.dataKey].format("DD-MM-YYYY")
                : dayjs().format("DD-MM-YYYY");
          }
        } else {
          if (!today) {
            dataToSave[currentField.dataKey] = "";
          } else {
            dataToSave[currentField.dataKey] = dayjs().format("DD-MM-YYYY");
          }
        }
      } else {
        dataToSave[currentField.dataKey] = fieldDataForSearch[
          currentField.dataKey
        ]
          ? fieldDataForSearch[currentField.dataKey]
          : "";
      }
    });
    return dataToSave;
  };
  onFormSearch = async () => {
    const dataToSave = await this.setFromDataForSearch();
    localStorage.setItem("formData", JSON.stringify(dataToSave));
    this.props.onSearchData(dataToSave);
  };
  render() {
    const {
      screenTitle = "",
      showAddButtonInTable = false,
      iconTitle,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      dynamicMasterData = {},
      tableColumns = [],
      showSearchBox = true,
      showInPaper = true,
      searchList = [],
      isActionColActive = false,
      showDeleteIcon = true,
      showCopyIcon,
      copyConditionValue,
      copyConditionName,
      setData,
      condition,
      showSend = false,
      conditionDelete = false,
      showOutlet = false,
      showViewButtonInTable = false,
      showApply = false,
      showApplyTitle = "",
      rowApply,
      rowPDF,
      showPdf = false,
      showEditIcon = true,
      showSearch = true,
      showPegination = true,
      deleteConditionName = "",
      deleteConditionValue = "",
      editConditionName = "",
      editConditionValue = "",
      onChangeStatusStatic,
      credentialConditionName = "",
      credentialConditionValue = "",
      showSendRequestIcon,
      rowSendRequest,
      sendRequestIconName = "",
      sendRequestIconValue = "",
      rowEdit,
      rowCopy,
      searchButton = true,
      chipCompoColor,
      today = false,
      showBackButton,
      paramsId,
      showContactInfo = false,
      rowLink,
      showBankInfo,
      GridSave,
      showApprovalIcon,
      onChangeApprovalStatus,
      rowClick = false
    } = this.props;
    const {
      tableColumnsToFilter = [],
      fieldDataForSearch,
      showTable,
    } = this.state;
    return (
      <>



        {showTable && (
          <>
            {searchList.length !== 0 && (
              <div style={{ paddingLeft: '5px' }}>
                <DynamicFormWithTable
                  GridSave={GridSave}
                  fieldMeta={searchList}
                  dynamicMasterData={dynamicMasterData}
                  fieldData={fieldDataForSearch}
                  onDataChange={this.onDataChangeForSearch}
                  onSave={this.onFormSearch}
                  searchButton={searchButton}
                  today={today}
                  buttonCenter={this.props.buttonCenter}
                />
              </div>
            )}
            <LandingScreenHeader
              tableColumnsToFilter={tableColumnsToFilter}
              onRTSelectMultipleChange={this.onRTSelectMultipleChange}
              screenTitle={""}
              showSearchBox={showSearchBox}
              showPdfDownload={showPdfDownload}
              generatePDF={this.generatePDF}
              showExcelDownload={showExcelDownload}
              generateExcel={this.generateExcel}
              showAddButton={showAddButton}
              paramsId={paramsId}
              onAddButtonClick={this.onAddButtonClick}
              onSearch={this.onSearch}
              showTable={showSearch ? showTable : false}
              showBackButton={showBackButton}
              onCancel={this.onCancel}
            />
            <RTTable
              columns={tableColumns}
              hiddenColumnNames={tableColumnsToFilter.map((item) => {
                return !item.isChecked ? item.columnKeyName : "";
              })}
              chipCompoColor={chipCompoColor}
              showViewButtonInTable={showViewButtonInTable}
              onChangeStatusStatic={onChangeStatusStatic}
              showOutlet={showOutlet}
              showSend={showSend}
              showEditIcon={showEditIcon}
              rowSend={this.rowSend}
              showDeleteIcon={showDeleteIcon}
              showCopyIcon={showCopyIcon}
              showContactInfo={showContactInfo}
              showBankInfo={showBankInfo}
              rowContact={this.rowContact}
              rowBank={this.rowBank}
              copyConditionName={copyConditionName}
              copyConditionValue={copyConditionValue}
              showAddButtonInTable={showAddButtonInTable}
              iconTitle={iconTitle}
              tableData={this.getFilteredTableData()}
              rowEdit={rowEdit ? rowEdit : this.rowEdit}
              rowCopy={rowCopy ? rowCopy : this.rowCopy}
              rowView={this.rowView}
              rowLink={rowLink}
              rowOutlet={this.rowOutlet}
              rowDelete={this.rowDelete}
              onChangeStatus={this.onChangeStatus}
              rowAdd={this.rowAdd}
              isActionColActive={isActionColActive}
              setData={setData}
              condition={condition}
              conditionDelete={conditionDelete}
              showApplyTitle={showApplyTitle}
              showApply={showApply}
              rowApply={rowApply}
              rowPDF={rowPDF}
              showPdf={showPdf}
              showPegination={showPegination}
              deleteConditionName={deleteConditionName}
              deleteConditionValue={deleteConditionValue}
              editConditionName={editConditionName}
              credentialConditionName={credentialConditionName}
              credentialConditionValue={credentialConditionValue}
              editConditionValue={editConditionValue}
              showSendRequestIcon={showSendRequestIcon}
              rowSendRequest={rowSendRequest}
              sendRequestIconName={sendRequestIconName}
              sendRequestIconValue={sendRequestIconValue}
              showApprovalIcon={showApprovalIcon}
              onChangeApprovalStatus={onChangeApprovalStatus}
              rowClick={rowClick}
            />
          </>
        )}

      </>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = { showNotification, showLoador };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicMainScreen);
