
export const profitAndLossPlantWiseJson = {
    screenTitle: "Profit And Loss",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta: [
     
        {
            label: "",
            controlType: "autocomplete",
            placeHolder: "Select Location",
            md: 3,
            lg: 3,
            sm: 3,
            xs: 12,
            labelSM: 1,
            labelMD: 1,
            labelLG: 1,
            valueSM: 11,
            valueMD: 11,
            valueLG: 11,
            error: "Location",
            masterName: "locationDetails",
            hideLabel: true,
            dataKey: "locationId",
            isRootLevelKey: true,
            isMandatory: true,
        },
      {
        label: "",
        controlType:"autocomplete",
        placeHolder: "Select  year ",
        md: 3,
        lg: 3,
        sm: 3,
        xs: 12,
        labelSM: 1,
        labelMD: 1,
        labelLG: 1,
        valueSM: 11,
        valueMD: 11,
        valueLG: 11,
        error:"year",
        masterName: "yearMasterDetail",
      hideLabel:true,
        dataKey: "yearId",
        isRootLevelKey: true,
        isMandatory: true,
      },
    
    ]
  };
  