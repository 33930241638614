import { Paper,Grid } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { cashFlowSummaryJson } from "../../DynamicFormsJson/Report/cashFlowSummary";
import RTTable from "../../components/Comman/RT/RTTable";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GroupSummarySubReportByGroup from "./CashFlowSubReportByGroup";
import MFGAndPL from "./MFGAndPL";
import MFMPALTable from "./MFM&PALTable";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import Cashbook from "./Cashbook";
import CashFlowSummaryTable from "./CashFlowSummaryTable";
import CashFlowDetailTable from "./CashFlowSummaryVerticalTable";
const CashFlowSummaryVertical = ({ showLoador, showNotification }) => {
    const [searchValue, setSearchValue] = useState("");
    const [dynamicMasterData, setDynamicMasterData] = useState({});
    const [formData, setFormData] = React.useState({});
    const [cashList, setCashbookList] = React.useState([]);
    const [cashList1, setCashbookList1] = React.useState([]);
    const [popUpData, setPopupdata] = useState({})
    const [openTable, setOpenTable] = useState(false);
    const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
    const [fieldData, setFieldData] = React.useState({});
    const[searchFlag,setSearchFlag]=useState(false);
    const[detailList,setDetailList]=React.useState([]);
    const[detailList1,setDetailList1]=React.useState([]);
    const [MFGList, setMFGList] = React.useState({});
    useEffect(() => {
        getDropDownList()
    }, []);

    const getDropDownList = async () => {
       
        showLoador({ loador: true });
        let CompanyDetails = await apiGet({
            url: endpoints.company,
        }).then(({ data, success }) => {
            if (success) {
                showLoador({ loador: false });
                let rowData = data.data;
                if (rowData.length == 1) {
                    setFieldData({ companyId: rowData[0].id })
                }
                return rowData.map((rowData) => {
                    return { ...rowData, name: rowData.companyName };
                })
            } else {
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }
            return []
        });
        setDynamicMasterData({
            CompanyDetails: CompanyDetails
        });
    }
    const getList = (fromDate,toDate,companyId) => {
        setSearchFlag(false);
        showLoador({ loador: true });
        apiGet({
            url: endpoints.cashFlowSummary+"/cash-flow-summary?companyIds="+companyId+"&fromDate="+fromDate+"&toDate="+toDate
          }).then(({ data, success }) => {
            if (success) {
                let rowData = data.data;
                let leftAmount = 0;
                let rightAmount = 0;

                let LeftAlign  = rowData.inflow !== null ? rowData.inflow: []
                let  RightAlign= rowData.outflow !== null ? rowData.outflow : []

                let LeftAlignData = []
                LeftAlignData = LeftAlign.map((left, index) => {
                    let amount = left.creditAmt 
                    leftAmount = leftAmount + amount
                    return {
                        groupId: left.groupDetail != null ? left.groupDetail.id : "",
                        groupName: left.groupDetail != null ? left.groupDetail.group_name : "",
                        amount: amount < 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                        // list:list,
                    }
                })
                let RightAlignData = []
                RightAlignData = RightAlign.map((right, index) => {
                    let amount =  right.debitAmt
                    rightAmount = rightAmount + amount
                    return {
                        groupId: right.groupDetail != null ? right.groupDetail.id : "",
                        groupName: right.groupDetail != null ? right.groupDetail.group_name : "",
                        // amount: Math.abs(amount).toFixed(2),
                        amount: amount > 0 ? "( " + Math.abs(amount).toFixed(2) + " )" : Math.abs(amount).toFixed(2),
                        // list:list,
                    }
                })
               
                showLoador({ loador: false });
               
 


                setCashbookList([...LeftAlignData, {
                    groupName: "Total",
                    amount: Math.abs(leftAmount).toFixed(2),
                    list:[],
                    groupNameRLink: false,
                    groupNameLink: false
                }])
               
                setCashbookList1([...RightAlignData, {
                    groupName: "Total",
                    amount: Math.abs(rightAmount).toFixed(2),
                    list:[],
                    groupNameRLink: false,
                    groupNameLink: false
                }])
            } else {
                setCashbookList([])
                setCashbookList1([])
                showLoador({ loador: false });
                showNotification({
                    msg: serverMsg,
                    severity: "error",
                });
            }

        });
    }

    const onSave = (dataToSearch) => {
        let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter((row) => row.id === dataToSearch.companyId)
        setFormData({
            ...dataToSearch,
            companyName: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
        })
        getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId )
    }
    const onSearch = (searchV) => {
        setSearchValue(searchV)
    };

    const generatePDFMethod = () => {

        var columns = [["Group Name", "Amount",]];

        let tableList = []
        tableList.push(["Inflow of Cash"])
        cashList.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            tableList.push(tableData)
        }))
        tableList.push(["Outflow of Cash"])
        cashList1.map(((rowData, index) => {

            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            tableList.push(tableData)
        }))
       
       
        const columnStylesPlain = {
            0: { cellWidth: 80 },
            1: { cellWidth: 150 },
            2: { cellWidth: 90 },
        };
        generatePDF({
            columnStylesPlain: columnStylesPlain,
           
            rows: tableList,
            columns,
            headerList: [
                
                {
                    label: "From Date",
                    value: formData.fromDate
                },
                {
                    label: "To Date",
                    value: formData.toDate
                },
                {
                    label: "Company Name",
                    value: formData.companyName
                },
            ],
            pdfTitle: "Cash Flow Summary (Vertical)",
        });

    };

    const generateExcelMethod = () => {
        var columns = ["Group Name", "Amount"];
        let alignList = [];

        let rows = []
        rows.push(["Inflow of Cash"])

        cashList.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            rows.push(tableData)
        });
        rows.push(["Outflow of Cash"])
        cashList1.map((rowData, index) => {
            let tableData = []

            tableData.push(rowData.groupName)

            tableData.push(rowData.amount)
            // tableData.push(rowData.groupNameR)

            // tableData.push(rowData.amountL)
            rows.push(tableData)
        });
       
        generateExcel({
            columns,
            rows,
            alignList,
            headerList: ["From Date",formData.fromDate,"To Date",formData.toDate,"Company Name", formData.companyName],
            title: "Cash Flow Summary (Vertical)",
        });

    };
    const getFilteredTableData = (tableData) => {

        let listData = tableData.filter((row) => {

            let isValid = false;

            if (row.groupName.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }

          
            if (row.amount.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }
            if(row.result && row.result.length!=0){
                row.result.filter((data)=>{
                    let creditTotal = 0;
                    let creditAmt = 0;
                      let debitAmt = 0;
                    creditAmt = data.creditAmt == null ? creditAmt : creditAmt + data.creditAmt
                    debitAmt = data.debitAmt == null ? debitAmt : debitAmt + data.debitAmt
                    creditTotal = creditTotal + creditAmt + debitAmt
                    if (data.name.toString()
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                ) {
                    isValid = true;
                }
                if (creditTotal.toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ) {
                isValid = true;
            }
            return isValid;
                })
               
            }
            

            
            return isValid;

        });
        return listData
    };
    const rowLink = (rowData, key,index) => {
        if (key == "groupNameR") {
            if (rowData.groupIdR) {
                setPopupdata({ groupId: rowData.groupIdR, groupName: rowData.groupNameR ,flag: 0, })
                setOpenTable(!openTable);
            }
            else {
                toggleMFGAndProfitAndLoss()
            }


        } else {
            if (rowData.groupId) {
                setPopupdata({ groupId: rowData.groupId, groupName: rowData.groupName ,flag:index==0?1:0, })
                setOpenTable(!openTable);
            }
            else {
                toggleMFGAndProfitAndLoss()
            }

        }

    }
    const toggle = () => {
        setOpenTable(!openTable);
        if(formData!=null && Object.keys(formData).length!=0){
     
            getList(formData.fromDate, formData.toDate, formData.companyId )

        }
    };

    const toggleMFGAndProfitAndLoss = () => {
        setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
        if(formData!=null && Object.keys(formData).length!=0){
            getList(formData.fromDate, formData.toDate, formData.companyId )
        }
    };
    


 

    return (
       
        <>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

                <DynamicFormWithoutSave
                    dynamicMasterData={dynamicMasterData}
                    showBackToList={false}
                    DataToSet={[]}
                    fieldData={fieldData}
                    showSaveBtn={cashFlowSummaryJson.showSaveBtn}
                    screenTitle={"Cash Flow Summary(Vertical)"}
                    fieldMeta={cashFlowSummaryJson.fieldMeta}
                    showCancel={cashFlowSummaryJson.showCancel}
                    apiBaseURL={cashFlowSummaryJson.apiBaseURL}
                    showSaveNextBtn={cashFlowSummaryJson.showSaveNextBtn}
                    showSaveBtnMain={true}
                    padding={false}
                    generatePDF={generatePDFMethod}
                    generateExcel={generateExcelMethod}
                    onSearch={onSearch}
                    saveBtnText={"Search"}
                    paddingTop={false}
                    onSave={onSave}
                    showExcelDownload={true}
                    detailButton={false}
                />
                <br />
                {searchFlag?( 
                    <>
              <CashFlowDetailTable 
        showPegination={false}

        hiddenColumnNames={[]}
        rowList={[
            {
                name: detailList.length!=0?"Inflow of Cash":"",
                groupList: getFilteredTableData(detailList),
            },
            {
                name: detailList1.length!=0?"Outflow of Cash":"",
                groupList: getFilteredTableData(detailList1)
            }
          

        ]}
        rowLink={rowLink}
        isActionColActive={false}
        formData={formData}
       />

                </>
                ):( <CashFlowSummaryTable
                    showPegination={false}

                    hiddenColumnNames={[]}
                    rowList={[
                        {
                            name: cashList.length!=0?"Inflow of Cash :":"",
                            groupList: getFilteredTableData(cashList)
                        },
                        {
                            name: cashList1.length!=0?"Outflow of Cash :":"",
                            groupList: getFilteredTableData(cashList1)
                        }

                    ]}
                    rowLink={rowLink}
                    isActionColActive={false}
                // rowCopy={this.rowCopy}
                />)}
               
              
                {openTable && (
                    <GroupSummarySubReportByGroup


                        open={openTable}
                        handleCloses={toggle}
                        popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                        dynamicMasterData={formData}

                    />
                )}
                {openMFGAndProfitAndLoss && (
                    <MFGAndPL
                    rowLink={rowLink}
                        open={openMFGAndProfitAndLoss}
                        handleCloses={toggleMFGAndProfitAndLoss}
                        popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
                        formData={formData}

                    />   
                )}
            </Paper>
            <Grid sx={{ paddingTop: 2 }} container spacing={1} justifyContent="center">
                    <Grid container xs={12} sm={10} md={10} lg={10} >
                        <>
                    {
                cashList.length != 0 && <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6} >
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>
                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Inflow of Cash'}
                                    />
                                </div>

                                <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList.filter((row)=>row.groupName!="Total" && row.amount!="" ).map((rowData) => {
                                return rowData.amount
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                            //   onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList.filter((row)=>row.groupName!="Total" && row.groupName!="" ).map((rowData,index) => {
                                index=index+1;
                               
                                return rowData.groupName
                                
                            }),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />
                                
                            </Paper>
                        </Grid>}
                        </>
                        {
                cashList1.length != 0 &&  <Grid sx={{ padding: 1 }} item xs={12} sm={6} md={6} lg={6}>
                            <Paper sx={{ borderRadius: 2 }} elevation={0}>

                                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 14, color: blackColor }}
                                        label={'Outflow of Cash'}
                                    />
                                </div>

                               
                    {/* Sales & Manpower Table 2 starts */}
                    <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={200}
                      series={[
                        {
                            name:"Amount",
                            data: cashList1.filter((row)=>row.groupName!="Total" && row.amount!="" ).map((rowData) => {
                                return rowData.amount
                            }),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                            //   onClickLineChart(config.dataPointIndex);
                            },
                          },
                        },

                        plotOptions: {
                          bar: {
                            columnWidth: "40%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                            categories: cashList1.filter((row)=>row.groupName!="Total" && row.groupName!="" ).map((rowData,index) => {
                                index=index+1;
                             
                                return rowData.groupName
                               
                                   
                                
                                
                            }),
                        },
                        yaxis: {
                          title: {
                            text: "$ (thousands)",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />

                   
                            </Paper>
                        </Grid>
                         }
                    </Grid>
                </Grid>
           
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CashFlowSummaryVertical);
