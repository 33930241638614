import React from "react";
import { connect } from "react-redux";

import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import TableColumnsDetails from "../../MatricReportGenerator/TableColumnsDetails";
import { compose } from "@reduxjs/toolkit";
import {
  setSaveAccountLedger,
} from "../../../Slice/accountLedger.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../../components/withRouter";
import BasicInformation from "./BasicInformation";
import AdditionalInformation from "./AdditionalInformation";
import StatutoryForm from "./StatutoryForm";
import TaxInformationForm from "./TaxInformationForm";
import FifthTabInformation from "./FifthTabInformation";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class AccountLedger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 25.5,
      open: false,
      orderList: [],
      salesPersonId: "",
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  changeTab = (value) => {
    this.setState({
      value: +value,
    });
  };
  callApi = () => {
    const { callApi } = this.props;
    this.setState({
      value: 0,
    });
    callApi()
  };
  onCancel = () => {
    this.props.navigate(-1);
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.editData != nextProps.editData
    ) {
      if (
        this.props.editData.id != nextProps.editData.id
      ) {
        this.setState({
          value: 0,
        });
        this.getDataById(nextProps.editData.id);
      } else {
        this.props.setSaveAccountLedger({
          rowObject: {},
        });

      }
    }
  }
  getDataById = (id) => {
    const { showLoador, setSaveAccountLedger, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: endpoint.accountLedger + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;
            rowData = {
              ...rowData,
              accType: rowData.accType.toString(),
            };
            setSaveAccountLedger({
              rowObject: rowData,
            });

            return {
              data: rowData,
              success,
            };
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
          return { data: {}, success: false };
        }
        return { response: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { value } = this.state;
    const { accountLedgerList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={AccountLedgerJson.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            showTable={false}
            showBackButton={false}
            onCancel={this.onCancel}
          />
          <br />
          <>
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label={"Basic"} />
              <Tab
                disabled={
                  Object.keys(accountLedgerList.accountLedgerData).length == 0
                    ? true
                    : false
                }
                label={"Additional"}
              />
              <Tab
                disabled={
                  Object.keys(accountLedgerList.accountLedgerData).length == 0
                    ? true
                    : false
                }
                label={"Statutory"}
              />
              <Tab
                disabled={
                  Object.keys(accountLedgerList.accountLedgerData).length == 0
                    ? true
                    : false
                }
                label={"Tax"}
              />
              {/* <Tab
                disabled={
                  Object.keys(accountLedgerList.accountLedgerData).length ==
                    0 &&
                  Object.keys(accountLedgerList.accountLedgerData)
                    .accountLevel == 0
                    ? true
                    : false
                }
                label={"Fifth Tab Information"}
              /> */}
              {
                Object.keys(accountLedgerList.accountLedgerData).length !==
                0 && accountLedgerList.accountLedgerData.accountLevel != 0 &&
                <Tab
                  disabled={
                    Object.keys(accountLedgerList.accountLedgerData).length ===
                    0 || accountLedgerList.accountLedgerData.accountLevel === 0
                  }
                  label={"Location and Shadow Account"}
                />
              }
            </Tabs>
            <TabPanel index={0} value={value}>
              <BasicInformation changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={1} value={value}>
              <AdditionalInformation changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={2} value={value}>
              <StatutoryForm changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={3} value={value}>
              <TaxInformationForm changeTab={this.changeTab} callApi={this.callApi} />
            </TabPanel>
            <TabPanel index={4} value={value}>
              <FifthTabInformation callApi={this.callApi} />
            </TabPanel>
          </>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accountLedgerList: state.accountLedger,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  setSaveAccountLedger
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AccountLedger);
