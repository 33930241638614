import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import {
  onlyNumber,
  validText,
} from "../../components/Comman/Util/Validations";
import {
  saveMasterGenerate,
  setSaveMasterGenerate,
} from "../../Slice/MasterGenerate.slice";
import * as msgConstant from "../../config/messageconstant";

import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ColumnFilterTablePopUp = ({
  openDialog,
  setOpenDialouge,
  masterGenerateData,
  rowData = {},
  rowDataEdit = {},
  rowInfo = {},
  saveMasterGenerate,
  showLoador,
  showNotification,
}) => {
  const [formErrors, setFormError] = React.useState({});
  const [formData, setFormData] = React.useState({
    isList: 0,
    isShow: 1,
    isTotal: 0,
    allignment: "left",
  });
  React.useEffect(() => {
    if (Object.keys(rowDataEdit).length != 0) {
      console.log(rowDataEdit);
      setFormData({
        ...rowDataEdit,
        isList: rowDataEdit.isList ? rowDataEdit.isList : "0",
        isTotal: rowDataEdit.isTotal ? rowDataEdit.isTotal : "0",
      });
    } else {
      setFormError({});
      setFormData({ isList: "0", isShow: 1, isTotal: "0", allignment: "left" });
    }
    setFormError({});
  }, []);
  const handleClose = () => {
    setOpenDialouge(false);
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (formData["columnName"] === undefined || formData["columnName"] == "") {
      formIsValid = false;
      formErrors["columnName"] = msgConstant.columnNameMsg;
    }
    if (formData["fieldName"] === undefined || formData["fieldName"] == "") {
      formIsValid = false;
      formErrors["fieldName"] = msgConstant.fieldNameMsg;
    }
    if (
      formData["pojoFiledName"] === undefined ||
      formData["pojoFiledName"] == ""
    ) {
      formIsValid = false;
      formErrors["pojoFiledName"] = msgConstant.pojoFieldNameMsg;
    }
    if (formData.isList == 0) {
      if (formData["width"] === undefined || formData["width"] == "") {
        formIsValid = false;
        formErrors["width"] = msgConstant.widthMsg;
      }
      if (formData["sortNo"] === undefined || formData["sortNo"] == "") {
        formIsValid = false;
        formErrors["sortNo"] = msgConstant.sortNoMsg;
      }
    }

    setFormError(formErrors);
    return formIsValid;
  };
  const onSubmitData = () => {
    if (checkValidation()) {
      let dataToSave = { ...masterGenerateData.masterData };
      let parameterList = JSON.parse(masterGenerateData.masterData.tableColumns)
        ? JSON.parse(masterGenerateData.masterData.tableColumns)
        : [];

      if (Object.keys(rowData).length == 0) {
        if (formData.index == undefined) {
          let parameter = JSON.stringify([...parameterList, formData]);
          dataToSave = {
            ...dataToSave,
            tableColumns: parameter,
          };
        } else {
          const parameterInfoList = parameterList.map(
            (parameterInfo, index) => {
              if (index == formData.index) {
                return formData;
              }
              return parameterInfo;
            }
          );
          console.log(parameterInfoList);
          let parameter = JSON.stringify([...parameterInfoList]);
          dataToSave = {
            ...dataToSave,
            tableColumns: parameter,
          };
        }
      } else {
        let tableColumnsList = [];
        console.log(rowInfo);
        console.log(rowData);

        tableColumnsList = parameterList.map((tableCol) => {
          if (rowInfo.fieldName == tableCol.fieldName) {
            let subList = tableCol.subList ? tableCol.subList : [];

            if (rowData.fieldName == tableCol.fieldName) {
              return {
                ...tableCol,
                subList: [...subList, formData],
              };
            } else {
              const createdSubList = addSubList(subList);
              return {
                ...tableCol,
                subList: createdSubList,
              };
            }
          }
          return tableCol;
        });

        console.log(tableColumnsList);
        dataToSave = {
          ...dataToSave,
          tableColumns: JSON.stringify(tableColumnsList),
        };
      }
      onSave(dataToSave);
    }
  };
  const addSubList = (subList) => {
    const subListInfo = subList.map((subListData) => {
      let subList = subListData.subList ? subListData.subList : [];

      if (rowData.fieldName == subListData.fieldName) {
        return {
          ...subListData,
          subList: [...subList, formData],
        };
      }
      return subListData;
    });
    return subListInfo;
  };
  const onSave = (dataToSave) => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      saveMasterGenerate({ dataToSave }).then(({ success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
          });

          handleClose();
        } else {
          showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
        }
      });
    } else {
      showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  const onRadioChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const onTextFieldDataChange = (event) => {
    if (event.target.value === "" || validText.test(event.target.value)) {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };
  const onTextFieldDataChangeForNumber = (event) => {
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Table Columns
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container sx={{ marginTop: 2 }} spacing={1}>
              <Grid container xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Is List:"}
                  />
                </Grid>

                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <RadioGroup
                    row
                    aria-label={"isList"}
                    name={"isList"}
                    id={"isList"}
                    value={formData["isList"] ? formData["isList"] : ""}
                    onChange={onRadioChange}
                    size="small"
                  >
                    <FormControlLabel
                     sx={{
                      "& svg": {
                        width: "0.8em",
                        height: "0.8em"
                      }
                    }}
                      value={1}
                      control={<Radio />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                     sx={{
                      "& svg": {
                        width: "0.8em",
                        height: "0.8em"
                      }
                    }}
                      value={0}
                      control={<Radio />}
                      label={"No"}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Column Name :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Column Name"}
                    size="small"
                    color="primary"
                    name={"columnName"}
                    id={"columnName"}
                    value={formData["columnName"] ? formData["columnName"] : ""}
                    onChange={onTextFieldDataChange}
                    fullWidth
                    errorText={
                      formErrors["columnName"] ? formErrors["columnName"] : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Field Name :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Field Name"}
                    size="small"
                    color="primary"
                    name={"fieldName"}
                    id={"fieldName"}
                    value={formData["fieldName"] ? formData["fieldName"] : ""}
                    onChange={onTextFieldDataChange}
                    fullWidth
                    errorText={
                      formErrors["fieldName"] ? formErrors["fieldName"] : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={6} md={6}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  &nbsp;&nbsp;
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Pojo Field Name :"}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <TextFieldCompo
                    placeHolder={"Pojo field Name"}
                    size="small"
                    color="primary"
                    name={"pojoFiledName"}
                    id={"pojoFiledName"}
                    value={
                      formData["pojoFiledName"] ? formData["pojoFiledName"] : ""
                    }
                    onChange={onTextFieldDataChange}
                    fullWidth
                    errorText={
                      formErrors["pojoFiledName"]
                        ? formErrors["pojoFiledName"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              {formData["isList"] == 0 && (
                <>
                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Width :"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <TextFieldCompo
                        placeHolder={"Width"}
                        size="small"
                        color="primary"
                        name={"width"}
                        id={"width"}
                        value={formData["width"] ? formData["width"] : ""}
                        onChange={onTextFieldDataChangeForNumber}
                        fullWidth
                        errorText={
                          formErrors["width"] ? formErrors["width"] : ""
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Sort Number :"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <TextFieldCompo
                        placeHolder={"Sort Number"}
                        size="small"
                        color="primary"
                        name={"sortNo"}
                        id={"sortNo"}
                        value={formData["sortNo"] ? formData["sortNo"] : ""}
                        onChange={onTextFieldDataChangeForNumber}
                        fullWidth
                        errorText={
                          formErrors["sortNo"] ? formErrors["sortNo"] : ""
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Is Show:"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <RadioGroup
                        row
                        aria-label={"isShow"}
                        name={"isShow"}
                        id={"isShow"}
                        value={formData["isShow"] ? formData["isShow"] : ""}
                        onChange={onRadioChange}
                        size="small"
                      >
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={1}
                          control={<Radio />}
                          label={"Yes"}
                        />
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={0}
                          control={<Radio />}
                          label={"No"}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Is Total:"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <RadioGroup
                        row
                        aria-label={"isTotal"}
                        name={"isTotal"}
                        id={"isTotal"}
                        value={formData["isTotal"] ? formData["isTotal"] : ""}
                        onChange={onRadioChange}
                        size="small"
                      >
                        <FormControlLabel
                          value={1}
                          sx={{
                            "& svg": {
                              width: "0.8em",
                              height: "0.8em"
                            }
                          }}
                          control={<Radio />}
                          label={"Yes"}
                        />
                        <FormControlLabel
                          value={0}
                          sx={{
                            "& svg": {
                              width: "0.8em",
                              height: "0.8em"
                            }
                          }}
                          control={<Radio />}
                          label={"No"}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} sm={6} md={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      &nbsp;&nbsp;
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label={"*"}
                      />
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          color: titleColor,
                        }}
                        label={"Alignment:"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <RadioGroup
                        row
                        aria-label={"allignment"}
                        name={"allignment"}
                        id={"allignment"}
                        value={
                          formData["allignment"] ? formData["allignment"] : ""
                        }
                        onChange={onRadioChange}
                        size="small"
                      >
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={"left"}
                          control={<Radio />}
                          label={"Left"}
                        />
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={"center"}
                          control={<Radio />}
                          label={"Center"}
                        />
                        <FormControlLabel
                         sx={{
                          "& svg": {
                            width: "0.8em",
                            height: "0.8em"
                          }
                        }}
                          value={"right"}
                          control={<Radio />}
                          label={"Right"}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="submit"
                onClick={onSubmitData}
              />
            </Grid>
            <br />
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  report: state.report,
  masterGenerateData: state.masterGenerate,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  saveMasterGenerate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColumnFilterTablePopUp);
