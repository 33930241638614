// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

// ** Icon Imports

// ** Custom Components Imports
// import CustomAvatar from 'src/@core/components/mui/avatar'

const data = [
  {
    imgWidth: 20,
    imgHeight: 22,
    trendDir: 'up',
    title: 'Paypal',
    imgAlt: 'paypal',
    amount: '+$24,820',
    avatarColor: 'error',
    subtitle: 'Received Money',
    imgSrc: '/images/cards/paypal.png'
  },
  {
    imgWidth: 20,
    imgHeight: 15,
    trendDir: 'down',
    amount: '-$1,250',
    title: 'Credit Card',
    imgAlt: 'credit-card',
    avatarColor: 'success',
    subtitle: 'Digital Ocean',
    imgSrc: '/images/cards/credit-card.png'
  },
  {
    imgWidth: 20,
    imgHeight: 15,
    trendDir: 'down',
    amount: '-$99',
    imgAlt: 'atm-card',
    title: 'Mastercard',
    subtitle: 'Netflix',
    avatarColor: 'warning',
    imgSrc: '/images/cards/atm-card.png'
  },
  {
    imgWidth: 20,
    imgHeight: 18,
    amount: '-$82',
    title: 'Wallet',
    imgAlt: 'wallet',
    trendDir: 'down',
    subtitle: "Mac'D",
    avatarColor: 'primary',
    imgSrc: '/images/cards/wallet.png'
  },
  {
    imgWidth: 20,
    imgHeight: 12,
    trendDir: 'up',
    title: 'Transfer',
    amount: '+$8,349',
    subtitle: 'Refund',
    avatarColor: 'info',
    imgAlt: 'arrow-growth',
    imgSrc: '/images/cards/arrow-growth.png'
  }
]

const EcommerceTransactions = ({  isTotal,rowList}) => {
  let total=0
  return (
  
      <CardContent>
        {rowList.map((item, index) => {
          if(isTotal){
            total=total+ +item.amount
          }
         
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1.6 
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box sx={{ mr: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 400, color:"#666665",fontSize: '0.875rem' }}>{item.name}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': { color: item.trendDir === 'down' ? 'error.main' : 'success.main' }
                  }}
                >
                  <Typography sx={{ mr:4, fontWeight: 500 }}>{item.value}</Typography>
                </Box>
              </Box>
            </Box>
          )
        })}
        {isTotal&&
          
          <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ mr: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 400, color:"#666665",fontSize: '0.875rem' }}>Total</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ mr:4, fontWeight: 500 }}>{Math.abs(total).toFixed(2)}</Typography>
            </Box>
          </Box>
        </Box>
        }
      </CardContent>
  )
}

export default EcommerceTransactions
