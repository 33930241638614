import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import MultipleSelect from "../Comman/MultipleSelect";
import dayjs from "dayjs";
import TimePickerCompo from "../Comman/TimePicker";
import { titleColor, redColor, blackColor } from "../../config/ColorObj";

class DynamicForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
    };
  }

  componentDidMount() {}

  onAutocompleteChange = (dataKey) => (name, newValue) => {
    this.props.onDataChange(dataKey, newValue);
  };
  // multiSelectHandler = (value, name) => {
  //   this.setState({
  //     ...this.state,
  //     [name]: typeof value === "string" ? value.split(",") : value,
  //   });
  // };
  onMultiSelectChange = (dataKey) => (value, name) => {
    this.props.onDataChange(
      dataKey,
      typeof value === "string" ? value.split(",") : value
    );
  };
  onTextFieldDataChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey) => (date) => {
    // date.setHours(0, 0, 0, 0);
    this.props.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onSave = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onFormSave(1);
    }
  };

  ValidationCheck = () => {
    let formValid = true;
    const { fieldData, reportDetails } = this.props;
    const fieldMeta = JSON.parse(reportDetails.parameterRequired);
    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      const parameterRequired = reportDetails.parameterRequired.split(",");

      if (
        currentField.valid &&
        parameterRequired.filter((row) => row == currentField.parameterValue)
          .length != 0
      ) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] == 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (currentField.validName === "greater-less") {
          if (currentField.zeroValid && fieldData[currentField.dataKey] == 0) {
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} is Invalid`,
              },
            };
          } else {
            const validArr = currentField.validCondition.split("-");
            const errorData =
              validArr[0] == "less"
                ? +fieldData[currentField.dataKey] > +fieldData[validArr[1]] &&
                  +fieldData[currentField.dataKey] != +fieldData[validArr[1]]
                : +fieldData[currentField.dataKey] < +fieldData[validArr[1]] &&
                  +fieldData[currentField.dataKey] != +fieldData[validArr[1]];
            console.log(errorData);
            if (errorData) {
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText:
                    validArr[0] == "less"
                      ? currentField.label +
                        ` shound be less than ` +
                        currentField.validText
                      : currentField.label +
                        ` shound be greater than ` +
                        currentField.validText,
                },
              };
            }
          }
        }
        if (
          currentField.validName === "greater-thanHundered" &&
          fieldData[currentField.dataKey] > 100
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} should not be gretaer than 100 per.`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };
  onSubmitCheck = () => {
    let formValid = true;
    const { fieldData, reportDetails } = this.props;
    const fieldMeta = JSON.parse(reportDetails.parameterRequired);

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      const parameterRequired = reportDetails.parameterRequired.split(",");

      if (
        parameterRequired.filter((row) => row == currentField.parameterValue)
          .length != 0 &&
        currentField.onSubmit === "compare"
      ) {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue) {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }

                break;
              case "le":
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }

                break;
              case "g":
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }

                break;
              case "ge":
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }

                break;
              case "eq":
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }

                break;
              default:
                break;
            }

            if (isError) {
              const compareWithFieldLabel = fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData, reportDetails } = this.props;
    const fieldMeta = JSON.parse(reportDetails.parameterRequired);

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (
        (currentField.controlType === "datepicker" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")) ||
        (currentField.controlType !== "datepicker" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === ""))
      ) {
        formValid = false;
        formErrors = {
          ...formErrors,
          [currentField.dataKey]: {
            isError: true,
            errorText: `*${currentField.label} is required`,
          },
        };
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onSaveAndNext = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSaveAndNext();
    }
  };
  render() {
    const {
      dynamicMasterData = {},
      fieldData,
      reportDetails,
      marginTop,
      GridSave = 0,
      showSaveBtnMain,
      showSaveBtn,
      saveBtnText,
      onFormSave,
    } = this.props;
    const { formErrors } = this.state;
    const fieldMeta = JSON.parse(reportDetails.parameterRequired);
    if (fieldMeta != null) {
      return (
        <>
          <Grid container spacing={1}>
            {fieldMeta.map((currentField, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  //   sx={{ marginTop: currentField.marginTop }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    {currentField.controlType !== "hideDatepicker" &&
                      currentField.controlType !== "hideTextfield" &&
                      currentField.controlType !== "hideAutocomplete" && (
                        <>
                          
                          <LabelCompo
                          isMandatory={currentField.isMandatory}
                            className="text-black"
                            style={{
                              fontSize: 14,
                              color: currentField.isMandatory
                                ? titleColor
                                : blackColor,
                            }}
                            label={currentField.label + ":"}
                          />
                        </>
                      )}
                    {currentField.controlType === "hideDatepicker" &&
                      fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                        <>
                        
                          <LabelCompo
                          isMandatory={currentField.isMandatory}
                            className="text-black"
                            style={{
                              fontSize: 14,
                              color: currentField.isMandatory
                                ? titleColor
                                : blackColor,
                            }}
                            label={currentField.label + ":"}
                          />
                        </>
                      )}
                    {currentField.controlType === "hideAutocomplete" &&
                      fieldData[currentField.tableDataKey] ===
                        currentField.open && (
                        <>
                          <LabelCompo
                          isMandatory={currentField.isMandatory}
                            className="text-black"
                            style={{
                              fontSize: 14,
                              color: currentField.isMandatory
                                ? titleColor
                                : blackColor,
                            }}
                            label={currentField.label + ":"}
                          />
                        </>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    {currentField.controlType === "textfield" && (
                      <TextField
                        placeholder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey
                        )}
                        fullWidth
                        error={
                          formErrors[currentField.dataKey]?.isError
                            ? true
                            : false
                        }
                        helperText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : " "
                        }
                      ></TextField>
                    )}
                    {currentField.controlType === "hideTextfield" &&
                      fieldData[currentField.tableDataKey] ==
                        currentField.open && (
                        <TextField
                          placeholder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                          }}
                          type={
                            currentField.inputType
                              ? currentField.inputType
                              : "text"
                          }
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey
                          )}
                          fullWidth
                          error={
                            formErrors[currentField.dataKey]?.isError
                              ? true
                              : false
                          }
                          helperText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : " "
                          }
                        ></TextField>
                      )}
                    {currentField.controlType === "datepicker" && (
                      <BasicDatePicker
                        color="primary"
                        name="dob"
                        size="small"
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : null
                        }
                        {...(currentField.isMAxDate && {
                          maxDate: dayjs(),
                        })}
                        {...(currentField.isMinDate && {
                          minDate: dayjs(),
                        })}
                        fullWidth
                        onChange={this.onDateChange(currentField.dataKey)}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                    {currentField.controlType === "autocomplete" && (
                      <AutoComplete
                        keyColName={"id"}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        options={
                          currentField.localList &&
                          currentField.localList.length != 0
                            ? currentField.localList.map((dataObj) => {
                                return {
                                  id: dataObj.key,
                                  name: dataObj.label,
                                };
                              })
                            : dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        onChange={this.onAutocompleteChange(
                          currentField.dataKey
                        )}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                    {currentField.controlType === "multiSelect" && (
                      <MultipleSelect
                        er={formErrors[currentField.dataKey]?.errorText}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                        name1={currentField.dataKey}
                        value1={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : []
                        }
                        names={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        //onChange={this.multiSelectHandler}
                        onChange={this.onMultiSelectChange(
                          currentField.dataKey
                        )}
                      />
                    )}
                    {currentField.controlType === "timePicker" && (
                      <TimePickerCompo
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        onChange={this.timeChangeHandler(currentField.dataKey)}
                      />
                    )}
                    {currentField.controlType === "radiogroup" && (
                      <>
                        <RadioGroup
                          row
                          aria-label={currentField.label}
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          onChange={this.onRadioChange(currentField.dataKey)}
                          size="small"
                        >
                          {currentField.localList.map((radioItem) => (
                            <FormControlLabel
                            sx={{
                              "& svg": {
                                width: "0.8em",
                                height: "0.8em"
                              }
                            }}
                              value={radioItem.key}
                              control={<Radio />}
                              label={radioItem.label}
                            />
                          ))}
                        </RadioGroup>
                        {currentField.note &&
                          currentField.note.split("@").map((note) => {
                            return (
                              <>
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 12, color: labelColor }}
                                  label={note}
                                />
                                <br></br>
                              </>
                            );
                          })}
                      </>
                    )}
                  </Grid>
                  {/* </Grid> */}
                  {/* {currentField.controlType === "textfield" && (
                      <TextField
                        placeholder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey
                        )}
                        fullWidth
                        error={
                          formErrors[currentField.dataKey]?.isError
                            ? true
                            : false
                        }
                        helperText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : " "
                        }
                      ></TextField>
                  )}
                  {currentField.controlType === "hideTextfield" &&
                    fieldData[currentField.tableDataKey] ==
                      currentField.open && (
                      <TextField
                        placeholder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey
                        )}
                        fullWidth
                        error={
                          formErrors[currentField.dataKey]?.isError
                            ? true
                            : false
                        }
                        helperText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : " "
                        }
                      ></TextField>
                    )}
                  {currentField.controlType === "datepicker" && (
                    <BasicDatePicker
                      color="primary"
                      name="dob"
                      size="small"
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : null
                      }
                      {...(currentField.isMAxDate && {
                        maxDate: dayjs(),
                      })}
                      {...(currentField.isMinDate && {
                        minDate: dayjs(),
                      })}
                      fullWidth
                      onChange={this.onDateChange(currentField.dataKey)}
                      isError={formErrors[currentField.dataKey]?.isError}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                    />
                  )}
                  {currentField.controlType === "autocomplete" && (
                    <AutoComplete
                      keyColName={"id"}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      options={
                        currentField.localList &&
                        currentField.localList.length != 0
                          ? currentField.localList.map((dataObj) => {
                              return { id: dataObj.key, name: dataObj.label };
                            })
                          : dynamicMasterData[currentField["masterName"]]
                          ? dynamicMasterData[currentField["masterName"]]
                          : []
                      }
                      onChange={this.onAutocompleteChange(currentField.dataKey)}
                      isError={formErrors[currentField.dataKey]?.isError}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                    />
                  )}
                  {currentField.controlType === "multiSelect" && (
                    <MultipleSelect
                      er={formErrors[currentField.dataKey]?.errorText}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                      name1={currentField.dataKey}
                      value1={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : []
                      }
                      names={
                        dynamicMasterData[currentField["masterName"]]
                          ? dynamicMasterData[currentField["masterName"]]
                          : []
                      }
                      //onChange={this.multiSelectHandler}
                      onChange={this.onMultiSelectChange(currentField.dataKey)}
                    />
                  )}
                  {currentField.controlType === "timePicker" && (
                    <TimePickerCompo
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      onChange={this.timeChangeHandler(currentField.dataKey)}
                    />
                  )}
                  {currentField.controlType === "radiogroup" && (
                    <>
                      <RadioGroup
                        row
                        aria-label={currentField.label}
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        onChange={this.onRadioChange(currentField.dataKey)}
                        size="small"
                      >
                        {currentField.localList.map((radioItem) => (
                          <FormControlLabel
                            value={radioItem.key}
                            control={<Radio />}
                            label={radioItem.label}
                          />
                        ))}
                      </RadioGroup>
                      {currentField.note &&
                        currentField.note.split("@").map((note) => {
                          return (
                            <>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 12, color: labelColor }}
                                label={note}
                              />
                              <br></br>
                            </>
                          );
                        })}
                    </>
                  )} */}
                </Grid>
              );
            })}
            {showSaveBtnMain && (
              <Grid
                container
                sm={GridSave != 0 ? GridSave : 1}
                md={GridSave != 0 ? GridSave : 1}
                lg={GridSave != 0 ? GridSave : 1}
                xs={12}

                //  rowSpacing={14}
              >
                <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={saveBtnText !== "" ? saveBtnText : "Save"}
                    // fullWidth={true}
                    onClick={onFormSave}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* {(showPDF || showExcel||showGraph) && ( */}
          <>
            <br />
            {showSaveBtn && (
              <>
                <br />
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  {showSaveBtn && (
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={saveBtnText !== "" ? saveBtnText : "Save"}
                      // fullWidth={true}
                      onClick={onFormSave}
                    />
                  )}
                  {/* {showSubmit && (
                  <>
                    &nbsp; &nbsp;
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Submit"}
                      // fullWidth={true}
                      onClick={onFormSubmit}
                    />
                  </>
                )} */}
                  {/* {showCancel && (
                  <>
                    &nbsp; &nbsp;
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={this.onCancel}
                      // fullWidth={true}
                    />
                  </>
                )} */}
                </Grid>
              </>
            )}
            {/* <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name={"Search"}
                // fullWidth={true}
                onClick={this.onSave}
              />
            </Grid> */}
          </>
          {/* )} */}
          {/* {(showReset) && (
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
    
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Reset"
                    fullWidth={true}
                    onClick={resetData}
                  />
                </Grid>
    
    
              </Grid>
            )} */}
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
