import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { JournalEntryJSON } from "../../DynamicFormsJson/Transaction/journalEntry";
import { ButtonCompo } from "../../components/Comman/Button";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { deleteFailedMsg } from "../../config/messageconstant";
import { apiDelete } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";

import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CostCenterMappingDialouge = ({
  open,
  getAuditQestionList,
  handleCloses,
  onSave,
  dataToSave,
  showLoador,
  showNotification,
  cashRecieptList,
  dynamicMasterData,
}) => {
  const [fieldData, setFieldData] = React.useState({});
  const [costMappingList, setCostMappingList] = React.useState([]);

  useEffect(() => {
    //
    async () => {};
    console.log("dataToSave");
    console.log(dataToSave);
    if (
      dataToSave && dataToSave.journalEntryDetailCcInfoList &&
      dataToSave.journalEntryDetailCcInfoList.length !== 0 &&
      costMappingList.length == 0
    ) {
      console.log(dataToSave.journalEntryDetailCcInfoList);
      let rowList = dataToSave.journalEntryDetailCcInfoList.length!=0?dataToSave.journalEntryDetailCcInfoList.map(
        (rowData, index) => {
          let catList = dynamicMasterData["costCenterDetail"]
            ? dynamicMasterData["costCenterDetail"].filter(
                (row) => row.id == rowData.costCenter.id
              )
            : [];
          console.log(catList);
          return {
            index: index + 1,
            costCenterName: catList[0].ccName,
            costCenter: {
              id: rowData.costCenter.id,
            },
            amount: +rowData.amount,
            type: 1,
          };
        }
      ):[]
      setCostMappingList(rowList);
    }
  }, []);

  const handleClose = () => {
    if (dataToSave && dataToSave.journalEntryDetailCcInfoList.length == 0) {
      setCostMappingList([]);
    }
    handleCloses(costMappingList,
      dataToSave && dataToSave.index
    );
  };
 
  const submitHandler = (dataToSet) => {
    let amount=0;
    costMappingList.map((cost)=>{
      amount=amount+cost.amount;
    })
   console.log("amount+(+dataToSet.amount)")
   console.log(amount+(+dataToSet.amount))
    if (amount+(+dataToSet.amount) > (dataToSave && +dataToSave.amt)) {
      
      showNotification({
        msg: "Total cost center amount should be less than detail amount",
        severity: "error",
      })
      setCostMappingList(costMappingList);
    }else{
    console.log(dataToSave);
    console.log(dynamicMasterData);

    let catList = dynamicMasterData["costCenterDetail"]
      ? dynamicMasterData["costCenterDetail"].filter(
          (row) => row.id == dataToSet.costCenterMaster
        )
      : [];
     
      console.log("amount")
      console.log(amount)
     
       
    
        const costMappingdata = {
          index: costMappingList.length + 1,
          costCenter: {
            id: catList[0].id,
          },
          costCenterName: catList[0].ccName,
          amount: +dataToSet.amount,
          type: 0,
        };
        console.log(costMappingList);
        let costMapping = [...costMappingList, costMappingdata];
        console.log(costMapping);
        setCostMappingList(costMapping.sort((a, b) => a.orderNo - b.orderNo));
        
      }
   
  };
  // const submitHandler = (dataToSet) => {
  //   console.log(dataToSave);
  //   console.log(dynamicMasterData);
  //   let catList = dynamicMasterData["costCenterDetail"]
  //     ? dynamicMasterData["costCenterDetail"].filter(
  //         (row) => row.id == dataToSet.costCenterMaster
  //       )
  //     : [];
  //   const costMappingdata = {
  //     index: costMappingList.length + 1,
  //     costCenter: {
  //       id: catList[0].id,
  //     },
  //     costCenterName: catList[0].ccName,
  //     amount: +dataToSet.amount,
  //     type: 1,
  //   };
  //   console.log(costMappingList);
  //   let costMapping = [...costMappingList, costMappingdata];
  //   console.log(costMapping);
  //   setCostMappingList(costMapping.sort((a, b) => a.orderNo - b.orderNo));
  // };

  let columns = [
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "costCenterName",
      title: "Cost Center Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "amount",
      title: "Amount",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "action",
      title: "Actions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  const rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const filteredList = costMappingList.filter(
          (row) => row.index !== rowData.index
        );
        setCostMappingList(
          filteredList.map((costMappingData, index) => {
            return { ...costMappingData, index: index + 1 };
          })
        );
      }
    });
  };

  const saveHandler = () => {
    let totalAmt = 0;
    costMappingList.map((rowData, i) => {
      totalAmt = totalAmt + rowData.amount;
    });
    console.log(totalAmt);
    console.log(dataToSave && +dataToSave.amt);
    if (totalAmt > (dataToSave && +dataToSave.amt)) {
      showNotification({
        msg: "Total cost center amount should be less than detail amount",
        severity: "error",
      });
    } else {
      onSave(costMappingList, dataToSave && dataToSave.index);
      setFieldData({});
    }
  };
  const rowEdit = (rowData) => {
    console.log(rowData);
    const dataToSend = {
      ...rowData,
      amt: rowData.amt,
      costCenterMaster: rowData.ccId,
    };

    setFieldData(dataToSend);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Cost Center Mapping
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DynamicFormWithoutSave
            paddingTop={false}
            showTitle={false}
             showBackToList={false}
            // DataToSet={dataToSet}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={false}
            callApi={false}
            screenTitle={JournalEntryJSON.screenTitle}
            fieldMeta={JournalEntryJSON.fieldMetaForOption}
            showCancel={JournalEntryJSON.showCancel}
            apiBaseURL2={JournalEntryJSON.apiBaseURL2}
            showSaveNextBtn={JournalEntryJSON.showSaveNextBtn}
            saveBtnText={"Add"}
            resetAfterSave={true}
            setFieldData={fieldData}
            onSave={submitHandler}
          />
          <br />
          <Grid item xs={12} sm={12} md={12}>
            <DynamicTable
              data={costMappingList}
              tableHead={columns}
              showHeadDelete={true}
              showHeadEdit={false}
              rowEdit={rowEdit}
              showPegination={false}
              rowDelete={rowDelete}
            />
          </Grid>
          <br />

          {/* <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Add Cost Mapping"
              onClick={saveHandler}
            />
          </Grid> */}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  feedBack: state.feedBack,
  cashRecieptList: state.cashReciept,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  // getCostCenter
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostCenterMappingDialouge);
