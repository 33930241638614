import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    timeline: [],
    timelineData:{}
};
let URL = endpoints.timeline;
const timelineSlice = createSlice({
    name: "timeline",
    initialState,
    reducers: {
        timelineSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                timeline: row,

            };
        },
        timelineDataSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                timelineData: row,

            };
        },
    },
});

export const { timelineSuccess, timelineDataSuccess } = timelineSlice.actions;

export default timelineSlice.reducer;

export const getTimeline = ({transactionId}) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL  + "/by-transaction-id?transactionId=" + transactionId
        }).then(({ data, success }) => {
            if (success) {
                
                const row = data.data;
             
                dispatch(timelineSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return { response: [], success };
    }
};

export const getTimelineById = ({transactionId}) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL  + "/" + transactionId
        }).then(({ data, success }) => {
            if (success) {
                
                const row = data.data;
             
                dispatch(timelineDataSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return { response: [], success };
    }
};