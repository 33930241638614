import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ratioAnalysisJson } from "../../DynamicFormsJson/Report/RatioAnalysis";
import { generateExcel, generatePDF } from "../../components/GeneratePDFExcel";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithReport";
import endpoints from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GroupSummarySubReportByGroup from "./GroupSummarySubReportByGroup";
import MFGAndPL from "./MFGAndPL";
import LeftRightAdjustTable from "./LeftRightAdjustTable";

import { Grid } from "@mui/material";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import BarChart from "../../components/Charts/BarChart";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor } from "../../config/ColorObj";
import RTTable from "../../components/Comman/RT/RTTable";
import MonthlyBarChart from "../../components/MonthlyBarChart";
import RatioAnalysisTable from "../AccountReport/RatioAnalysisTable";
const RatioAnalysis = ({ showLoador, showNotification }) => {
  const [searchValue, setSearchValue] = useState("");
  const [dynamicMasterData, setDynamicMasterData] = useState({});
  const [formData, setFormData] = React.useState({});
  const [cashList, setCashbookList] = React.useState([]);
  const [RatioAnalysis, setRatioAnalysis] = React.useState({});

  const [MFGList, setMFGList] = React.useState({});
  const [popUpData, setPopupdata] = useState({});
  const [openTable, setOpenTable] = useState(false);

  const [openMFGAndProfitAndLoss, setOpenMFGAndProfitAndLoss] = useState(false);
  const [fieldData, setFieldData] = React.useState({});
  useEffect(() => {
    getDropDownList();
  }, []);

  const getDropDownList = async () => {
    showLoador({ loador: true });
    const yearMasterDetail = await apiGet({
      url: endpoints.year,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data;

        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.yearName };
        });
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    showLoador({ loador: true });
    let CompanyDetails = await apiGet({
      url: endpoints.company,
    }).then(({ data, success }) => {
      if (success) {
        showLoador({ loador: false });
        let rowData = data.data;
        if (rowData.length == 1) {
          setFieldData({ companyId: rowData[0].id });
        }
        return rowData.map((rowData) => {
          return { ...rowData, name: rowData.companyName };
        });
      } else {
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      return [];
    });
    setDynamicMasterData({
      yearMasterDetail: yearMasterDetail,
      CompanyDetails: CompanyDetails,
    });
  };
  const getList = (companyId, yearId) => {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoints.cashFlowSummary +
        "/ratio-analysis?companyIds=" +
        companyId +
        "&yearId=" +
        yearId,
    }).then(({ data, success }) => {
      if (success) {
        let rowList = [];
        let leftAmount = 0;
        let rightAmount = 0;

        let rowData = data.data;
        let dynamicData = rowData.show_data;
        let dont_show = rowData.dont_show;
        dynamicData=[
            ...dynamicData,
            ...dont_show
        ]
        let staticData = rowData.static_data;
        let currentAsset = dont_show.find(
          (row) =>
            row.groupDetail &&
            row.groupDetail != null &&
            row.groupDetail.group_name == "Current Assets"
        );
        let CurrentLiabilities = dont_show.find(
          (row) =>
            row.groupDetail &&
            row.groupDetail != null &&
            row.groupDetail.group_name == "Current Liabilities"
        );
        let currentAssetAmt = currentAsset.creditAmt + currentAsset.debitAmt;
        let CurrentLiabilitiesAmt =
          CurrentLiabilities.creditAmt + CurrentLiabilities.debitAmt;
  
        let ratioData = {};
        dynamicData.map((row) => {
          let group_name =
            row.groupDetail &&
            row.groupDetail != null &&
            row.groupDetail.group_name
              ? row.groupDetail.group_name
              : "";
          ratioData = {
            ...ratioData,
            [group_name]: row.creditAmt + row.debitAmt,
          };
        });
        staticData.map((row) => {
            let group_name =row.name;
            ratioData = {
              ...ratioData,
              [group_name]: row.amt,
            };
          });
console.log(ratioData)
        setRatioAnalysis({
          workingCapital: currentAssetAmt - CurrentLiabilitiesAmt,
          ...ratioData
        });
        setCashbookList(dynamicData);
        showLoador({ loador: false });
      } else {
        setCashbookList([]);
        showLoador({ loador: false });
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
    });
  };

  const onSave = (dataToSearch) => {
    let filterCompanyDta = dynamicMasterData["CompanyDetails"].filter(
      (row) => row.id === dataToSearch.companyId
    );
    let filterYearMasterDetail = dynamicMasterData["yearMasterDetail"].filter(
      (row) => row.id === dataToSearch.yearId
    );

    setFormData({
      ...dataToSearch,
      companyName: filterCompanyDta.length != 0 ? filterCompanyDta[0].name : "",
      yearName:
        filterYearMasterDetail.length != 0
          ? filterYearMasterDetail[0].name
          : "",
      fromDate:
        filterYearMasterDetail.length != 0
          ? filterYearMasterDetail[0].fromDate
          : "",
      toDate:
        filterYearMasterDetail.length != 0
          ? filterYearMasterDetail[0].toDate
          : "",
    });
    getList(dataToSearch.companyId, dataToSearch.yearId);
    //   getList(dataToSearch.fromDate, dataToSearch.toDate, dataToSearch.companyId,dataToSearch.accountLedgerGroupsId )
  };
  const onSearch = (searchV) => {
    setSearchValue(searchV);
  };

  const generatePDFMethod = () => {
    generatePDFExcelData();
    var columns = [["Principal Group", "Amount", "Principal Ratios", "Amount"]];

    let tableList = [];
    let tableData = [];

    tableData.push('Working Capital');
    tableData.push(RatioAnalysis.workingCapital);
    tableData.push('Current Ratio');
    tableData.push(RatioAnalysis.workingCapital);
    tableList.push(tableData);
   
    tableData = []
    tableData.push('Cash in hand');
    tableData.push(RatioAnalysis.workingCapital);
    tableData.push('Quick Ratio');
    tableData.push(RatioAnalysis.workingCapital);
    tableList.push(tableData);
   
    tableData = []
    tableData.push('Bank Accounts');
    tableData.push(RatioAnalysis.workingCapital);
    tableData.push('Quick Ratio');
    tableData.push(RatioAnalysis.workingCapital);
    tableList.push(tableData);
    const columnStylesPlain = {
      0: { cellWidth: 80 },
      1: { cellWidth: 150 },
      2: { cellWidth: 90 },
    };
    generatePDF({
      columnStylesPlain: columnStylesPlain,
      rows: tableList,
      columns,
      headerList: [
        {
          label: "Company Name",
          value: formData.companyName,
        },
        {
          label: "Year Name",
          value: formData.yearName,
        },
      ],
      pdfTitle: ratioAnalysisJson.screenTitle,
    });
  };
  const generatePDFExcelData = () => {
    let tableList = [];
    MFGList.DynamicData &&
      MFGList.DynamicData.filter(
        (rowData) =>
          rowData.groupDetail != null && rowData.groupDetail.allignment != "R"
      ).map((rowData) => {
        let leftTotal = 0;
        leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt;
        let tableData = [];
        tableData.push(rowData.groupDetail.group_name);
        tableData.push(
          Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)
        );
      });
    MFGList.staticData &&
      MFGList.staticData
        .filter((rowData) => rowData.alligment != "R")
        .map((rowData) => {
          let leftTotal = 0;
          leftTotal = leftTotal + rowData.amt;
          let tableData = [];
          tableData.push(rowData.name);
          tableData.push(Math.abs(rowData.amt).toFixed(2));
        });
    MFGList.DynamicData &&
      MFGList.DynamicData.filter(
        (rowData) =>
          rowData.groupDetail != null && rowData.groupDetail.allignment == "R"
      ).map((rowData) => {
        let leftTotal = 0;
        leftTotal = leftTotal + rowData.creditAmt + rowData.debitAmt;
        let tableData = [];
        tableData.push(rowData.groupDetail.group_name);
        tableData.push(
          Math.abs(rowData.creditAmt + rowData.debitAmt).toFixed(2)
        );
      });
    MFGList.staticData &&
      MFGList.staticData
        .filter((rowData) => rowData.alligment == "R")
        .map((rowData) => {
          let leftTotal = 0;
          leftTotal = leftTotal + rowData.amt;
          let tableData = [];
          tableData.push(rowData.name);
          tableData.push(Math.abs(rowData.amt).toFixed(2));
        });
  };

  const generateExcelMethod = () => {
    var columns = ["Group Name", "Amount", "Group Name", "Amount"];
    let alignList = [];

    let rows = [];
    cashList.map((rowData, index) => {
      let tableData = [];

      tableData.push(rowData.groupName);

      tableData.push(rowData.amount);
      tableData.push(rowData.groupNameR);

      tableData.push(rowData.amountL);
      rows.push(tableData);
    });
    generateExcel({
      columns,
      rows,
      alignList,
      headerList: [
        "Company Name",
        formData.companyName,
        "Year Name",
        formData.yearName,
      ],
      title: ratioAnalysisJson.screenTitle,
    });
  };
  const getFilteredTableData = (tableData) => {
    let listData = tableData.filter((row) => {
      let isValid = false;

      if (
        row.groupName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.groupNameR
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      if (
        row.amount.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }

      if (
        row.amountL.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
      }
      return isValid;
    });
    return listData;
  };
  const rowLink = (rowData, key) => {
    if (key == "groupNameR") {
      if (rowData.groupIdR) {
        setPopupdata({
          groupId: rowData.groupIdR,
          groupName: rowData.groupNameR,
        });
        setOpenTable(!openTable);
      } else {
        toggleMFGAndProfitAndLoss();
      }
    } else {
      if (rowData.groupId) {
        setPopupdata({
          groupId: rowData.groupId,
          groupName: rowData.groupName,
        });
        setOpenTable(!openTable);
      } else {
        toggleMFGAndProfitAndLoss();
      }
    }
  };
  const toggle = () => {
    setOpenTable(!openTable);
    if (formData != null && Object.keys(formData).length != 0) {
      getList(formData.companyId, formData.yearId);
    }
  };
  const toggleMFGAndProfitAndLoss = () => {
    setOpenMFGAndProfitAndLoss(!openMFGAndProfitAndLoss);
    if (formData != null && Object.keys(formData).length != 0) {
      getList(formData.companyId, formData.yearId);
    }
  };
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <DynamicFormWithoutSave
          // PeriodData={formData.fromDate ? formData.fromDate + " To " + formData.toDate : ""}
          dynamicMasterData={dynamicMasterData}
          showBackToList={false}
          DataToSet={[]}
          fieldData={fieldData}
          showSaveBtn={ratioAnalysisJson.showSaveBtn}
          screenTitle={ratioAnalysisJson.screenTitle}
          fieldMeta={ratioAnalysisJson.fieldMeta}
          showCancel={ratioAnalysisJson.showCancel}
          apiBaseURL={ratioAnalysisJson.apiBaseURL}
          showSaveNextBtn={ratioAnalysisJson.showSaveNextBtn}
          showSaveBtnMain={true}
          padding={false}
          showSearchBox={false}
          generatePDF={generatePDFMethod}
          generateExcel={generateExcelMethod}
          onSearch={onSearch}
          saveBtnText={"Search"}
          paddingTop={false}
          onSave={onSave}
          showExcelDownload={true}
        />
        <br />

        {/* <CustomTable/> */}
        {
            Object.keys(RatioAnalysis).length!=0?<RatioAnalysisTable
            showPegination={false}
            // columns={[
            //     { name: "groupName", title: "Principle Groups", linkFlag: true, linkName: "groupNameLink" },
            //     { name: "amount", title: "Amount", fontWeight: "bold", align: "right" },
            //     { name: "groupNameR", title: "Principle Ratios", linkFlag: true, linkName: "groupNameRLink" },
            //     { name: "amountL", title: "Amount", fontWeight: "bold", align: "right" }
            // ]}
            hiddenColumnNames={[]}
            rowList={cashList}
            RatioAnalysis={RatioAnalysis}
            rowLink={rowLink}
            isActionColActive={false}
            // rowCopy={this.rowCopy}
          />
          :null
        }
        
        {openTable && (
          <GroupSummarySubReportByGroup
            open={openTable}
            handleCloses={toggle}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            dynamicMasterData={formData}
          />
        )}
        {openMFGAndProfitAndLoss && (
          <MFGAndPL
            rowLink={rowLink}
            open={openMFGAndProfitAndLoss}
            handleCloses={toggleMFGAndProfitAndLoss}
            popUpData={Object.keys(popUpData).length != 0 ? popUpData : {}}
            formData={formData}
          />
        )}
      </Paper>
      
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showLoador, showNotification };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RatioAnalysis);
