import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { encodeData } from "../../components/Comman/Util/Base64";
import { Loading } from "../../components/Loader";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showNotification } from "../Landing/Landing.slice";
import { login } from "./login.slice";



const initState = { name: "", password: "" };

const Login = ({ login,  showNotification }) => {
  const [auth, setAuth] = useState(initState);
  const [nameErr, setNameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => { }, []);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const Login = () => {
    if (auth.name !== "" && auth.password !== "") {
      setPasswordErr("");
      setNameErr("");
      setLoginErr("");
      const name = `${auth.name}:${auth.password}`;
      const userName = encodeData(name);
      setShowLoading(true);
      login({ username: auth.name, password: auth.password }).then(
        async ({ success }) => {
          if (success) {
            setShowLoading(false);
            setShowLoading(true);
           
            setShowLoading(true);
            await apiGet({
              url: endpoint.setting + "/by-key?key=freez_current_date",
            }).then(({ data, success }) => {
             
              if (success) {
                console.log(data.data.value);

                localStorage.setItem(
                  "freezCurrentDate",
                  data.data.value
                );

              } else {
                showNotification({ msg: serverMsg, severity: "error" });
              }

              return success;
            });
          
            await apiGet({
              url: endpoint.user,
            }).then(({ data, success }) => {
             
              if (success) {
                console.log(data);
                localStorage.setItem("userId", data.data.usrId);
                localStorage.setItem("userName", data.data.usrName);
                localStorage.setItem(
                  "locationIdList",
                  JSON.stringify(data.data.locationIdList)
                );
                if (data.data.locationIdList != null&&data.data.locationIdList.length!=0) {
                  localStorage.setItem("location", data.data.locationIdList[0].id);
                }
                localStorage.setItem(
                  "moduleList",
                  JSON.stringify(data.data.modules)
                );
                window.location.replace("/dashboard");
              } else {
                showNotification({ msg: serverMsg, severity: "error" });
              }

              return success;
            });
            setShowLoading(false);
          } else {
            setShowLoading(false);
            setLoginErr("Invalid Credential");
          }
        }
      );
    } else {
      if (auth.name === "" && auth.password === "") {
        setNameErr("Username required");
        setPasswordErr("Password required");
      } else if (auth.name === "") {
        setNameErr("Username required");
        setPasswordErr("");
      } else if (auth.password === "") {
        setPasswordErr("Password required");
        setNameErr("");
      } else {
        setPasswordErr("");
        setNameErr("");
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setAuth({
      ...auth,
      [name]: value,
    });
  };

  const onApiCalled = (e) => {
    //href="forgot-password-pre-login"
  };

  return (
    <>
      <div className="main_login_div">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', top: '5%', gap: '20px', left: '5%' }} >
          <div className="new_logo_content" />
          <div className="new_title_content">Accounts</div>
        </div>
        <div className="new_login_content">


          <div className="new_login_box3">
            <div className="new_login_box">
              <div>
                <div className="new_login_welcome">Welcome</div>
              </div>
              <div style={{ alignItems: "flex-between" }}>
                <div className="user_lgn_new">
                  <div className="new_user_lable">Username</div>
                  <TextField
                    color="primary"
                    name="name"
                    size="small"
                    value={auth.name}
                    onChange={onChange}
                    onKeyPress={(e) => e.key === "Enter" && Login()}
                    fullWidth
                    helperText={nameErr ? nameErr : " "}
                    error={nameErr ? true : false}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #DEDEDE",
                      background: "#ECECEC",
                      width: "360px",
                      height: "41px",
                      flexShrink: "0",
                      marginBottom: "14px",
                      // borderRadius: "17px 17px 17px 17px",
                    }}
                  />
                  <div className="new_user_lable">Password</div>
                  <OutlinedInput
                    color="primary"
                    name="password"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{
                          width: "18px",
                          height: "18px",
                          flexShrink: "0",
                          marginRight: "12px",
                        }}
                      >
                        <IconButton onClick={handleClickShowPassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={auth.password}
                    onChange={onChange}
                    fullWidth
                    onKeyPress={(e) => e.key === "Enter" && Login()}
                    error={passwordErr ? true : false}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #DEDEDE",
                      background: "#ECECEC",
                      width: "360px",
                      height: "43px",
                      flexShrink: "0",

                      // borderRadius: "17px 17px 17px 17px",
                    }}
                  />
                  <div
                    style={{ marginBottom: "14px" }}
                    className="new_remember_me"
                  >
                    Remember me
                  </div>
                  <FormHelperText error>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {passwordErr !== "" ? passwordErr : " "}
                  </FormHelperText>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end" }}>
                <div style={{ alignItems: "flex-between" }}>
                  <button
                    type="button"
                    onClick={Login}
                    onKeyPress={(e) => e.key === "Enter" && Login()}
                    className="new_login_btn"
                    style={{
                      color: "#FFF", fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    Login
                  </button>
                </div>
                {/* <div className="new_forgot_pswd" onClick={onApiCalled}>
                  <a href="/forgotPassword" style={{ color: "#004969" }}>
                    Forgot Password
                  </a>
                </div> */}
              </div>

              <div style={{ display: 'flex', flexDirection: "row", marginTop: '20px', gap: "10px", alignItems: "center" }} >
                <div className="new_footer">
                  Powered by <div className="new_footer_logo"></div>
                </div>
                <div >
                  <a
                    href="https://www.byteelephants.com/"
                    style={{ color: "#004969" }}
                  >
                    BytesElephants.com
                  </a>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      {showLoading && <Loading />}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state,
  common: state.common,
});

const mapDispatchToProps = {
  login,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

{
  /* <>
      <div align="center">
        <div className="new_content login">
          <div className="dash_login">
            <Grid item container justifyContent="space-between">
              <Grid item md={4}></Grid>
              <Grid item md={4}>
                <div className="lgn_bx">
                  <h3 className="lgn_title">Login Form</h3>

                  <div className="lgn_container">
                    <div className="user_lgn_new">
                      <div className="user_label">Username</div>
                      <div className="user_field">
                        <TextField
                          color="primary"
                          name="name"
                          size="small"
                          value={auth.name}
                          onChange={onChange}
                          onKeyPress={(e) => e.key === "Enter" && Login()}
                          fullWidth
                          helperText={nameErr ? nameErr : " "}
                          error={nameErr ? true : false}
                        />
                      </div>
                    </div>

                    <div className="user_lgn_new">
                      <div className="user_label">Password</div>
                      <div className="user_field">
                        <OutlinedInput
                          color="primary"
                          name="password"
                          size="small"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          value={auth.password}
                          onChange={onChange}
                          fullWidth
                          onKeyPress={(e) => e.key === "Enter" && Login()}
                          error={passwordErr ? true : false}
                        />
                        <FormHelperText error>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {passwordErr !== "" ? passwordErr : " "}
                        </FormHelperText>
                      </div>
                    </div>

                    <div style={{ marginTop: 20 }} className="lgn_btn">
                      <button
                        type="button"
                        onClick={Login}
                        onKeyPress={(e) => e.key === "Enter" && Login()}
                        className="register_btn"
                      >
                        Login
                      </button>
                    </div>
                    <FormHelperText error>
                      &nbsp;&nbsp;
                      {loginErr !== "" ? loginErr : " "}
                    </FormHelperText>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="user_label" onClick={onApiCalled}>
                        <a href="/forgotPassword">Forgot Password</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </div>
        </div>
      </div>
      {showLoading && <Loading />} */
}
