import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { ManageStockJSON } from "../../DynamicFormsJson/Transaction/manageStock";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import{getTimeline}from "../../Slice/timeline.slice";
import TimeLine from '../../components/TimeLine'

class AssetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      formData: {},
      manageStockList: [],
      openCostCenterView: false,
      openBillView: false,
      headerData: {},
    };
   
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.editData.id != nextProps.editData.id) {
      this.getByIdData(nextProps.editData.id)
    }


  }
  async componentDidMount() {
    const { showNotification } =
      this.props;
    if (navigator.onLine) {
      if (this.props.editData.id) {
        this.getByIdData(this.props.editData.id)
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getByIdData = async (id) => {
    const { getCashRecieptWithAccNameById,getTimeline, showLoador, showNotification } =
      this.props;
      showLoador({ loador: true });
      await apiGet({
         url: endpoint.asset + "/" + id ,
     }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
        } else {
          let dValue=((( data.data.openingValue) + ( data.data.purchaseInFirstHalf)) *
          ((data.data.depreciationPercentage)) /
        100
      ) +
      +(((data.data.purchaseInSecondHalf) * ((data.data.depreciationPercentage) / 2)) / 100) 
          this.setState({
            DataToSet: [
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "ledger Code",
                value: data.data.ledgerCode?data.data.ledgerCode
                 
                  : "",
              },

              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Depreciation Percentage",
                value:data.data.depreciationPercentage !== null ? data.data.depreciationPercentage : "",
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Opening Value",
                value:
                data.data.openingValue!=null? data.data.openingValue:""
              },
            
              {
                xs: 12,
                sm:4,
                md:4,
                lg:4,
                label: "Purchase In First Half",
                value:
                 data.data.purchaseInFirstHalf!=null? data.data.purchaseInFirstHalf: "",
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Purchase In Second Half",
                value:  data.data.purchaseInSecondHalf!=null? data.data.purchaseInSecondHalf: "",
              },
              {
                xs: 12,
                sm:4,
                md:4,
                lg:4,
                label: "Sell Value",
                value:
                data.data.sellValue!=null? data.data.sellValue: "",
                
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Total",
                value:  data.data.total!=null? data.data.total: "",
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Closing Value",
                value: data.data.closingValue!=null? data.data.closingValue: "",
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Remark",
                value:
                data.data.remark !== null
                    ? data.data.remark != ""
                      ? data.data.remark
                      : " - "
                    : "",
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "date",
                value:
                data.data.date !== null
                    ?  data.data.date
                    : "",
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label:"Depreciation Value",
                value:dValue,
       
                   
              },
              
            ],
          });
        }
      });
      showLoador({ loador: true });
    await getTimeline({
      transactionId: id,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } 
    });
  }
  rowView = (rowData) => {
    this.setState({
      openCostCenterView: !this.state.openCostCenterView,
      headerData: rowData,
    });
  };

  rowOutlet = (rowData) => {
    this.setState({
      openBillView: !this.state.openBillView,
      headerData: rowData,
    });
  };
  onClickUser = (timelineDetails) => {
    console.log(timelineDetails)
    this.props.navigate("/asset-calculation-preview/"+timelineDetails.id);
  }

  render() {
    const { DataToSet, formData, openCostCenterView, headerData, openBillView } =
      this.state;
    const {  rowEdit, rowDelete,rowReset,timeline } = this.props;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
            paddingTop: 1,
            borderRadius: 0,
            height: "100%"
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showEdit={true}
            showDelete={true}
            rowEdit={rowEdit}
            rowDelete={rowDelete}
            rowReset={rowReset}
            showReset={true}
            showTable={true}
             showBackToList={false}
            showPdfDownload={false}
            showExcelDownload={false}
            DataToSet={DataToSet}
            showSaveBtn={ManageStockJSON.showSaveBtn}
            showTitle={true}
            screenTitle={" Asset Calculation View"}
            fieldMeta={[]}
            // subTitle={formData.documentNo && formData.documentNo != null ? formData.documentNo : ""}
            // amount={formData.voucherAmount && formData.voucherAmount != null ? formData.voucherAmount.toFixed(2) : "-"}
            showCancel={ManageStockJSON.showCancel}
            apiBaseURL={ManageStockJSON.apiBaseURL}
            showSaveNextBtn={ManageStockJSON.showSaveNextBtn}
            showSaveBtnMain={false}
            padding={false}
            paddingTop={false}
          />

<>

<TimeLine onClickUser={this.onClickUser} timelineList={timeline.timeline} documentNo={formData.documentNo ? formData.documentNo : "-"} />
</>
          {/* <RTTable
            columns={this.columns}
            tableData={
              Object.keys(cashRecieptList.cashRecieptById).length != 0
                ? cashRecieptList.cashRecieptById.dtoRecieptPaymentDetailList.map((rowData)=>{
                  return {  ...rowData,  creditAmount: rowData.type == "C" ? (+rowData.amt).toFixed(2) : "",
                  debitAmount: rowData.type == "C" ? "" : (+rowData.amt).toFixed(2),}
                })
                : []
            }
            isActionColActive={true}
            showPegination={false}
            showDeleteIcon={false}
            showEditIcon={false}
            showCopyIcon={true}
            rowCopy={this.rowView}
            showAddButtonInTable={true}
            rowAdd={this.rowOutlet}
          /> */}
        </Paper>
       
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
  cashRecieptList: state.cashReciept,
  timeline:state.timeline
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getTimeline
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssetView);
