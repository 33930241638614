import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import {
  getLocation,
  getAccountLedgerGroupMaster,
  saveAccountLedger,
  setSaveAccountLedger,
} from "../../../Slice/accountLedger.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  saveWarningMsg,
  saveFailedMsg,
  savemsg,
} from "../../../config/messageconstant";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";
import { apiGet } from "../../../utils/api_service";

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dynamicMasterData: {
        locationDetail: this.props.accountLedger?.location,
        accountLedgerGroupMasterDetail:
          this.props.accountLedger?.accountLedgerGroupMaster,
        accTypeMasterDetail: [
          { id: "0", name: "Other" },
          { id: "1", name: "Cash" },
          { id: "2", name: "Bank" },
          { id: "3", name: "Shadow Account" },
        ],
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.accountLedger !== nextProps.accountLedger) {
      if (
        this.props.accountLedger?.location !== nextProps.accountLedger?.location
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            locationDetail: nextProps.accountLedger?.location,
          },
        });
      }
    }
    if (this.props.accountLedger !== nextProps.accountLedger) {
      if (
        this.props.accountLedger?.accountLedgerGroupMaster !==
        nextProps.accountLedger?.accountLedgerGroupMaster
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            accountLedgerGroupMasterDetail:
              nextProps.accountLedger?.accountLedgerGroupMaster,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      getLocation,
      getAccountLedgerGroupMaster,
      setSaveAccountLedger,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      let var1 = localStorage.getItem("location");
      showLoador({ loador: true });
      console.log(var1);
      await getLocation().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getAccountLedgerGroupMaster().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSubmitData = (dataToSave) => {
    const { accountLedger } = this.props;
    dataToSave = {
      ...dataToSave,
    };
    if (this.props.params.id) {
      dataToSave = {
        ...accountLedger.accountLedgerData,
        ...dataToSave,
      };
    }
    // const dataToSave = {
    //     accountLedgerCode: fieldData.accountLedgerCode,
    //     accountLedgerName: fieldData.accountLedgerName,
    //     locationIds: fieldData.location,
    //     printName: fieldData.printName,
    //     address: fieldData.address,
    //     city: fieldData.city,
    //     contactNo: fieldData.contactNo,
    //     mail: fieldData.mail,
    //     pinCode: fieldData.pinCode,
    //     distance: fieldData.distance,
    // };
    console.log("dataToSave");
    console.log(dataToSave);
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(dataToSave);
        this.onSave(dataToSave);
      }
    });
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification, saveAccountLedger } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      saveAccountLedger({ dataToSave }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
          });
          this.props.changeTab("1");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  getDataForDisabled = (printName) => {

    return { printName: printName };
  };
  render() {
    const { dynamicMasterData } = this.state;
    const { accountLedger } = this.props
    return (
      <>
        <DynamicFormWithoutSave
          getByIdDataList={{
            ...accountLedger.accountLedgerData,
            accType: accountLedger.accountLedgerData.accType ? accountLedger.accountLedgerData.accType.toString() : ""
          }}
          getDataForDisabled={this.getDataForDisabled}
          formPath={AccountLedgerJson.formPath}
          fieldMeta={AccountLedgerJson.fieldMeta}
          tableColumnsToFilter={AccountLedgerJson.tableColumnsToFilter}
          apiBaseURL={AccountLedgerJson.apiBaseURL}
          baseIdColumn={AccountLedgerJson.baseIdColumn}
          dynamicMasterData={dynamicMasterData}
          showSaveBtnMain={false}
          showSaveBtn={true}
          saveBtnText={"SAVE & PROCEED"}
          onSave={this.onSubmitData}
          callApi={true}
          getByIdApi={true}
          getDataById={this.getDataById}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accountLedger: state.accountLedger,

});
const mapDispatchToProps = {
  getLocation,
  getAccountLedgerGroupMaster,
  showLoador,
  showNotification,
  saveAccountLedger,
  setSaveAccountLedger,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BasicInformation);
