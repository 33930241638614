import {
    Grid
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";

import { AccountLedgerJson } from "../../../DynamicFormsJson/MastersJSON/accountLedger";
import {
    getLocation,
    saveAccountLedger,
    setSaveAccountLedger
} from "../../../Slice/accountLedger.slice";
import { getAccountLedgerListByType } from "../../../Slice/subVoucherDetail.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { titleColor } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import {
    noInternetMsg,
    saveFailedMsg,
    saveWarningMsg,
    savemsg,
    serverMsg,
} from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

class FifthTabinformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowList: [],
            fieldData: {},
            formErrors: {},
            dynamicMasterData: {
                locationDetail: this.props.accountLedger?.location,
                accountTypeDetail: this.props.accTypeList?.accountLedgerByType,

            },
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.accountLedger !== nextProps.accountLedger) {
            if (
                this.props.accountLedger?.location !== nextProps.accountLedger?.location
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        locationDetail: nextProps.accountLedger?.location,
                    },
                });
            }
        }
        if (this.props.accTypeList !== nextProps.accTypeList) {
            if (
                this.props.accTypeList?.accountLedgerByType !==
                nextProps.accTypeList?.accountLedgerByType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        accountTypeDetail: nextProps.accTypeList?.accountLedgerByType,
                    },
                });
            }
        }
    }
    async componentDidMount() {
        const {
            getAccountLedgerListByType,
            showLoador,
            getLocation,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            this.getDataById()
            showLoador({ loador: true });
            await getLocation().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getAccountLedgerListByType({ accType: 3 }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    getDataById = async () => {

        const {
            showLoador,
            showNotification, accountLedger
        } = this.props;

        if (navigator.onLine) {
            showLoador({ loador: true });
            await apiGet({
                url: endpoint.shadowAccountMapping + "/by-account-id/" + accountLedger.accountLedgerData.id,
            }).then(({ data, success }) => {
                console.log(data.data)
                showLoador({ loador: false });
                if (success) {
                    let dataToSet = {}
                    data.data.map((row) => {
                        dataToSet = {
                            ...dataToSet,
                            [row.locationId]: row.shadowAccId
                        }
                    })
                    this.setState({
                        fieldData: dataToSet,
                        rowList: data.data
                    })

                } else {
                    showNotification({ msg: serverMsg, severity: "error" });
                }

                return success;
            });
            // window.location.replace("/bank-reco");
        } else {
            showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };
    onSave = async (dataToSave) => {

        const {
            showLoador,
            showNotification,
            callApi
        } = this.props;

        if (navigator.onLine) {
            showLoador({ loador: true });
            await apiPost({
                url: endpoint.shadowAccountMapping,
                postBody: dataToSave,
            }).then(({ data, success }) => {
                console.log(success)
                showLoador({ loador: false });
                if (success) {
                    callApi()
                    showNotification({ msg: savemsg, severity: "success" });

                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }

                return success;
            });
            // window.location.replace("/bank-reco");
        } else {
            showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };

    onAutocompleteChange = (locationId, dataKey, isRootLevelKey) => (name, newValue) => {
        if (newValue != null) {

            this.setState({
                fieldData: {
                    ...this.state.fieldData,
                    [locationId]: newValue
                }
            })
        } else {
            let value = "";
            this.onDataChange(dataKey, value);
        }
    };
    onFormSave = () => {
        const { fieldData, rowList, dynamicMasterData } = this.state
        const { accountLedger } = this.props
        let locationList = dynamicMasterData.locationDetail.map((location) => {
            let filterData = dynamicMasterData.accountTypeDetail.filter((rowData) => rowData.id == fieldData[location.id])

            let filterRowListData = rowList.filter((rowData) => rowData.locationId == location.id)
            let dataToSet = {
                mapAccId: accountLedger.accountLedgerData.id,
                mapAccName: accountLedger.accountLedgerData.accountLedgerName,
                mapAccCode: accountLedger.accountLedgerData.accountLedgerCode,
                shadowAccId: fieldData[location.id],
                shadowAccName: filterData.length != 0 ? filterData[0].accountLedgerName : "",
                shadowAccCode: filterData.length != 0 ? filterData[0].accountLedgerCode : "",
                locationId: location.id
            }
            if (filterRowListData.length != 0) {
                dataToSet = {
                    ...dataToSet,
                    id: filterRowListData[0].id
                }
            }
            return dataToSet
        })
        console.log(locationList)
        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onSave(locationList);


            }
        });
    }
    render() {
        const { dynamicMasterData, fieldData, formErrors } = this.state;
        return (
            <>


                {
                    dynamicMasterData.locationDetail.length != 0 &&
                    <Grid container spacing={1}>
                        {dynamicMasterData.locationDetail.map((location) => (
                            <>
                                {AccountLedgerJson.fieldMetaForFifthTab.map((currentField) => (
                                    <Grid
                                        container
                                        xs={currentField.xs}
                                        sm={currentField.sm}
                                        md={currentField.md}
                                        sx={{
                                            marginTop: currentField.marginTop
                                                ? currentField.marginTop
                                                : 1,

                                        }}
                                    //  rowSpacing={12}
                                    >
                                        {currentField.toolTip && currentField.toolTip !== "" && (
                                            <>
                                                <Tooltip placement="top" title={currentField.toolTip}>
                                                    <InfoIcon color="disabled" />
                                                </Tooltip>
                                            </>
                                        )}
                                        <Grid
                                            item
                                            xs={currentField.labelXS ? currentField.labelXS : 5}
                                            sm={currentField.labelSM ? currentField.labelSM : 5}
                                            md={currentField.labelMD ? currentField.labelMD : 5}
                                            lg={currentField.labelLG ? currentField.labelLG : 5}
                                        >
                                            <>
                                               
                                                <LabelCompo
                                                isMandatory={currentField.isMandatory}
                                                    className="text-black"
                                                    style={{
                                                        fontSize: 12,
                                                        color: titleColor
                                                    }}
                                                    label={currentField.label + ":"}
                                                />
                                            </>

                                        </Grid>
                                        <Grid
                                            item
                                            xs={currentField.valueXS ? currentField.valueXS : 7}
                                            sm={currentField.valueSM ? currentField.valueSM : 7}
                                            md={currentField.valueMD ? currentField.valueMD : 7}
                                            lg={currentField.valueLG ? currentField.valueLG : 7}
                                        >
                                            {currentField.controlType === "textfield" && (
                                                <>
                                                    <TextFieldCompo
                                                        placeHolder={currentField.placeHolder}
                                                        size="small"
                                                        color="primary"
                                                        name={currentField.label}
                                                        id={currentField.label}
                                                        value={location.name
                                                        }
                                                        disabled={true
                                                        }
                                                        fullWidth
                                                    />
                                                </>
                                            )}


                                            {currentField.controlType === "autocomplete" && (
                                                <>
                                                    <AutoComplete
                                                        label={currentField.label}
                                                        placeHolder={currentField.placeHolder}
                                                        keyColName={"id"}
                                                        value={fieldData[location.id] ? fieldData[location.id]
                                                            : ""
                                                        }
                                                        options={
                                                            dynamicMasterData[currentField["masterName"]]
                                                                ? dynamicMasterData[currentField["masterName"]]
                                                                : []
                                                        }
                                                        disabled={
                                                            fieldData["id"] && currentField.disable ? true : false
                                                        }
                                                        onChange={this.onAutocompleteChange(
                                                            location.id,
                                                            currentField.dataKey,
                                                            currentField.isRootLevelKey
                                                        )}
                                                        isError={formErrors[currentField.dataKey]?.isError}
                                                        errorText={formErrors[currentField.dataKey]?.errorText}
                                                    />
                                                    {currentField.note &&
                                                        currentField.space &&
                                                        currentField.note.split("@").map((note) => {
                                                            return (
                                                                <>
                                                                    <LabelCompo
                                                                        className="text-black"
                                                                        style={{ fontSize: 12, color: labelColor }}
                                                                        label={note}
                                                                    />
                                                                    {currentField.space === "br" ? (
                                                                        <br></br>
                                                                    ) : currentField.space === "&nbsp;" ? (
                                                                        " "
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {/* <br></br> */}
                                                                </>
                                                            );
                                                        })}
                                                </>
                                            )}

                                        </Grid>
                                    </Grid>
                                ))}
                            </>

                        ))}

                    </Grid>
                }
                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                >
                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name={"Submit"}
                        // fullWidth={true}
                        onClick={this.onFormSave}
                    />


                </Grid>


            </>
        );
    }
}
const mapStateToProps = (state) => ({
    dropDownList: state.accountLedger,
    accountLedger: state.accountLedger,
    accTypeList: state.subVoucherDetail,
});
const mapDispatchToProps = {
    getLocation,
    showLoador,
    showNotification,
    saveAccountLedger,
    setSaveAccountLedger,
    getAccountLedgerListByType,
};
export default connect(mapStateToProps, mapDispatchToProps)(FifthTabinformation);
