import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  subVoucher: [],
  subVoucherByType: [],
  voucher: [],
  subVoucherDetail: [],
};
let URL = endpoints.subVoucher;
let URL1 = endpoints.voucher;
const subVoucherSlice = createSlice({
  name: "subVoucher",
  initialState,
  reducers: {
    subVoucherSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subVoucher: row,
      };
    },

    subVoucherByTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subVoucherByType: row,
      };
    },

    voucherSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        voucher: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  subVoucherSuccess,
  voucherSuccess,
  subVoucherByTypeSuccess,
  resetState,
} = subVoucherSlice.actions;

export default subVoucherSlice.reducer;

export const getVoucher = () => async (dispatch) => {
  try {
    const response = apiGet({
      //   url: URL + '?sort={"sortOrder": "ASC"}',
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const row = content.map((voucherObject, index) => {
          let areaData = {
            ...voucherObject,
            // index: index + 1,
            id: voucherObject.id === null ? "" : voucherObject.id,
            name:
              voucherObject.voucherName === null
                ? ""
                : voucherObject.voucherName,
          };
          return areaData;
        });
        dispatch(voucherSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubVoucher = () => async (dispatch) => {
  try {
    const response = apiGet({
      //   url: URL + '?sort={"sortOrder": "ASC"}',
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const row = content.map((subVoucherObject, index) => {
          let areaData = {
            index: index + 1,
            id: subVoucherObject.id === null ? "" : subVoucherObject.id,
            subVoucherName:
              subVoucherObject.subVoucherName === null
                ? ""
                : subVoucherObject.subVoucherName,
            voucherName:
              subVoucherObject.voucherMaster !== null &&
              subVoucherObject.voucherMaster.voucherName
                ? subVoucherObject.voucherMaster.voucherName
                : "",
            voucherMaster:
              subVoucherObject.voucherMaster !== null &&
              subVoucherObject.voucherMaster
                ? subVoucherObject.voucherMaster
                : "",
            subVoucherCode:
              subVoucherObject.subVoucherCode === null
                ? ""
                : subVoucherObject.subVoucherCode,
            accountPosting:
              subVoucherObject.accountPosting === null
                ? ""
                : subVoucherObject.accountPosting == 1
                ? "Yes"
                : "No",
            stockUpdate:
              subVoucherObject.stockUpdate === null
                ? ""
                : subVoucherObject.stockUpdate == 1
                ? "Yes"
                : "No",
            autoApprove:
              subVoucherObject.autoApprove === null
                ? ""
                : subVoucherObject.autoApprove == 1
                ? "Yes"
                : "No",
            vouNature:
              subVoucherObject.vouNature === null
                ? ""
                : subVoucherObject.vouNature,
          };
          return areaData;
        });
        dispatch(subVoucherSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubVoucherByType =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        //   url: URL + '?sort={"sortOrder": "ASC"}',
        url: URL + "/by-voucher-type/" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((subVoucherObject, index) => {
            let areaData = {
              index: index + 1,
              id: subVoucherObject.id === null ? "" : subVoucherObject.id,
              name:
                (subVoucherObject.subVoucherName === null
                  ? ""
                  : subVoucherObject.subVoucherName) +
                (subVoucherObject.subVoucherCode === null
                  ? ""
                  : " ( " + subVoucherObject.subVoucherCode + " ) "),
              subVoucherName:
                subVoucherObject.subVoucherName === null
                  ? ""
                  : subVoucherObject.subVoucherName,
              voucherName:
                subVoucherObject.voucherMaster !== null &&
                subVoucherObject.voucherMaster.voucherName
                  ? subVoucherObject.voucherMaster.voucherName
                  : "",
              voucherMaster:
                subVoucherObject.voucherMaster !== null &&
                subVoucherObject.voucherMaster
                  ? subVoucherObject.voucherMaster
                  : "",
              subVoucherCode:
                subVoucherObject.subVoucherCode === null
                  ? ""
                  : subVoucherObject.subVoucherCode,
              accountPosting:
                subVoucherObject.accountPosting === null
                  ? ""
                  : subVoucherObject.accountPosting == 1
                  ? "Yes"
                  : "No",
              stockUpdate:
                subVoucherObject.stockUpdate === null
                  ? ""
                  : subVoucherObject.stockUpdate == 1
                  ? "Yes"
                  : "No",
              autoApprove:
                subVoucherObject.autoApprove === null
                  ? ""
                  : subVoucherObject.autoApprove == 1
                  ? "Yes"
                  : "No",
              autoApproveStatus:
                subVoucherObject.autoApprove === null
                  ? ""
                  : subVoucherObject.autoApprove,
              vouNature:
                subVoucherObject.vouNature === null
                  ? ""
                  : subVoucherObject.vouNature,
            };
            return areaData;
          });
          dispatch(subVoucherByTypeSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAreaByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((area, index) => {
          let areaForBeatData = {
            index: index + 1,
            id: area.id === null ? "" : area.id,
            name: area.name === null ? "" : area.name,
            shortName: area.shortName === null ? "" : area.shortName,
            sortOrder: area.sortOrder === null ? "" : area.sortOrder,
          };
          return areaForBeatData;
        });
        dispatch(subVoucherSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAreaByZoneIds =
  ({ zoneIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-zones?zoneIds=" + zoneIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((area, index) => {
            let areaForBeatData = {
              index: index + 1,
              id: area.id === null ? "" : area.id,
              name: area.name === null ? "" : area.name,
              shortName: area.shortName === null ? "" : area.shortName,
              sortOrder: area.sortOrder === null ? "" : area.sortOrder,
            };
            return areaForBeatData;
          });
          dispatch(subVoucherSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
