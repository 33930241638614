export const labelColor = "#808080";
export const titleColor = "#295FA6";
export const redColor = "#ff0000";
export const white = "#fff";
export const DarkBlue = "#2196F3";
export const cyanBlue = "#2196f3";
export const blackColor = "#000";
export const checkboxColor = "#1976d2";
export const greyColor = "#808080";
export const darkgreyColor = "#5a5a5a";
export const yellowishBrownColor = "#E97451";
export const checkedColor = "#d4b69e";
export const DimGrayColor = "#666666";
export const LightYellowColor = "#F4D03F";
export const LightGreenColor = "#58D68D";
export const checkboxShadowColor = "#acacac";
export const yellowColor = "#fbff00";
export const primaryColor = "#295FA6"
export const grayColor = "#4B4B4B"
export const mediumGray = "#595959"
export const lightGray = "#A1A1A1"
