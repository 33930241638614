import { Divider, Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  deleteWarningMsg,
} from "../../config/messageconstant";
import dayjs from "dayjs";
import { DebitNoteJSON } from "../../DynamicFormsJson/Transaction/debitNote";
import {
  getAccountLedgerListByType1,
} from "../../Slice/subVoucherDetail.slice";
import { getLedgerDataByAccId } from "../../Slice/ledger.slice";

import { getSubVoucherByType } from "../../Slice/subVoucher.slice";
import { getCostCenter } from "../../Slice/cashReceipt.slice";
import { apiPost } from "../../utils/api_service";
import { getProfitCenter } from "../../Slice/profitCenter.slice";

import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicTransactionForm from "../../components/dynamicscreens/DynamicTransactionForm";
import CostCenterMappingDialouge from "../CashReceipt/CostCenterMappingDialouge";
class DebitNoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      headerData: {},
      flag: 1,
      setFieldDataFromApi: {},
      // voucherAmountShow: 0,
      fieldData: { type: "C" },
      recieptPaymentDetailCcInfoList: [],
      recieptPaymentDetailBillInfoList: [],
      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        creditAccountDetails:
          this.props.subVoucherDetailList?.accountLedgerByType1,
        cashBankDetails:  this.props.subVoucherDetailList?.accountLedgerByType1,
        costCenterDetail: this.props.cashRecieptList?.costCenter,
        typeDetails: [
          { id: "C", name: "Credit" },
          { id: "D", name: "Debit" },
        ],
        subVoucherDetail: this.props.subVoucherList?.subVoucherByType,
      
        openCostCenter: false,
        openBillMapping: false,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
  
    if (
      this.props.subVoucherDetailList.accountLedgerByType1 !==
      nextProps.subVoucherDetailList.accountLedgerByType1
    ) {
      const list = nextProps.subVoucherDetailList.accountLedgerByType1
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          creditAccountDetails:
            nextProps.subVoucherDetailList.accountLedgerByType1,
            cashBankDetails:  nextProps.subVoucherDetailList.accountLedgerByType1,
        },
      });
      if (this.state.flag == 1 && list.length != 0) {
        let formData = {
          cashBankId: list[0].id,
        };

        this.setState({
          fieldData: {
            ...this.state.fieldData,
            cashBankId: formData.cashBankId,
          },
        });
        this.getListById(formData);
        // mainAccountId: nextProps.subVoucherDetailList?.accountLedgerByType1.length != 0 ? [nextProps.subVoucherDetailList?.accountLedgerByType1[0].id] : [],
      }
    }
    if (
      this.props.cashRecieptList?.costCenter !==
      nextProps.cashRecieptList?.costCenter
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          costCenterDetail: nextProps.cashRecieptList?.costCenter,
        },
      });
    }
    if (
      this.props.subVoucherList?.subVoucherByType !==
      nextProps.subVoucherList?.subVoucherByType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subVoucherDetail: nextProps.subVoucherList?.subVoucherByType,
        },
      });
    }
    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }
  }

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const {
      showLoador,
      showNotification,
      getAccountLedgerListByType1,
      getSubVoucherByType,
      getCostCenter,
      getProfitCenter,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getProfitCenter().then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );


      showLoador({ loador: true });
      await getAccountLedgerListByType1({ accType: 0 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
      // showLoador({ loador: true });
      // await getSubVoucherByType({ type: 7 }).then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     showNotification({ msg: serverMsg, severity: "error" });
      //   } else {
      //   }
      // });
      showLoador({ loador: true });
      await getCostCenter().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  getListById = async (data) => {
    const { showNotification } = this.props;
    if (navigator.onLine) {
      
        if (data.mainAccountId !=null && data.mainAccountId !="") {
          this.getList(data.mainAccountId, 1);
        }
        if (data.cashBankId != null && data.cashBankId !="") {
          //    this.setState({
          //   dynamicMasterData: {
          //     ...this.state.dynamicMasterData,
          //     creditAccountDetails:this.state.dynamicMasterData.creditAccountDetails.filter((row)=>row.id!=data.cashBankId),
          //   },
          // });
          this.getList(data.cashBankId, 0);
        }
     
     
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  getList = async (accId, type) => {
    const {
      showLoador,
      showNotification,
      getLedgerDataByAccId,
      getSubVoucherByType,
      getCostCenter,
    } = this.props;
    const locationIdList = localStorage.getItem("locationIdList");
    let valuesArray = [];
    if (locationIdList != null) {
      valuesArray = JSON.parse(locationIdList);
      valuesArray = valuesArray.filter(
        (row) => row.id === localStorage.getItem("location")
      );
    }
    
    showLoador({ loador: true });
    await getLedgerDataByAccId({
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
      companyIds: valuesArray.length != 0 ? valuesArray[0].company.id : 0,
      accId: accId,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if (response!=null) {
          
          if (type == 1) {
            this.setState({
              setFieldDataFromApi: {
                debitBalance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt)+" "+"Cr": Math.abs(response.closingAmt)+" "+"Dr"
              },
            });
          } else {
            this.setState({
              fieldData: {
                ...this.state.fieldData,
                balance: response.closingAmt!=null && response.closingAmt >=0? Math.abs(response.closingAmt)+" "+"Cr": Math.abs(response.closingAmt)+" "+"Dr"
              },
            });
          }
        }
      }
    });
  };

  onSearch = (data) => {
    const { rowList, dynamicMasterData, recieptPaymentDetailCcInfoList } =
      this.state;
console.log(data)
    let catList = dynamicMasterData["creditAccountDetails"]
      ? dynamicMasterData["creditAccountDetails"].filter(
          (row) => row.id == data.mainAccountId
        )
      : [];
    let amt = 0;
    rowList.map((row) => {
      amt = row.type == "C" ? amt + +row.amt : amt - +row.amt;
    });
    amt = data.type == "C" ? amt + +data.amt : amt - +data.amt;
    if(+data.amt > 0){
    this.setState({
      fieldData: {
        ...this.state.fieldData,
        voucherAmount: amt > 0 ?  (+amt).toFixed(2) : (+amt * -1).toFixed(2),
      },
      voucherAmountShow: amt,
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          mainAccountCode:
            catList.length != 0 ? catList[0].accountLedgerCode : "-",
          mainAccountName:
            catList.length != 0 ? catList[0].accountLedgerName : "-",
          recieptPaymentDetailCcInfoList: recieptPaymentDetailCcInfoList,
          ...data,
          amt:data.amt?(+data.amt).toFixed(2):"",
          type: data.type ? data.type : "",
          creditAmount: data.type == "C" ? (+data.amt).toFixed(2) : "",
          debitAmount: data.type == "C" ? "" : (+data.amt).toFixed(2),
          percentage:data.percentage!=null?data.percentage:0.00,
          HsnCode: data.HsnCode!=null?data.HsnCode:"",
          taxableAmount: data.taxableAmount!=null?data.taxableAmount:"",
        },
      ],
    });
  }
  };
  CheckValidationOnSubmit = () => {
    const { fieldData, value } = this.state;
    console.log(fieldData)
    const metaData =

    DebitNoteJSON.fieldMetaDetails
    let error = true;
    let formErrors = {};
    // if (fieldData.subVoucherMaster == null || fieldData.subVoucherMaster == undefined) {
    //   error = false;

    //   formErrors = {
    //     ...formErrors,
    //     ["subVoucherMaster"]: {
    //       isError: true,
    //       errorText: "subVoucher Master" + errorMsg
    //     }
    //   };
    // }
    if (fieldData.date == null || fieldData.date == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["date"]: {
          isError: true,
          errorText: "date" + errorMsg
        }
      };
    }
    if (fieldData.cashBankId == null || fieldData.cashBankId == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["cashBankId"]: {
          isError: true,
          errorText: "cashBankId" + errorMsg
        }
      };
    }
    if (fieldData.balance == null || fieldData.balance == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["balance"]: {
          isError: true,
          errorText: "balance" + errorMsg
        }
      };
    }
    if (fieldData.balance == null || fieldData.balance == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["balance"]: {
          isError: true,
          errorText: "balance" + errorMsg
        }
      };
    }
    if (fieldData.profitCenter == null || fieldData.profitCenter == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["profitCenter"]: {
          isError: true,
          errorText: "profitCenter" + errorMsg
        }
      };
    }
    if (metaData.mainAccountId == null || metaData.mainAccountId == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["mainAccountId"]: {
          isError: true,
          errorText: "mainAccountId" + errorMsg
        }
      };
    }
    if (metaData.debitBalance == null || metaData.debitBalance == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["debitBalance"]: {
          isError: true,
          errorText: "debitBalance" + errorMsg
        }
      };
    }
    if (metaData.amt == null || metaData.amt == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["amt"]: {
          isError: true,
          errorText: "amt" + errorMsg
        }
      };
    }
    if (metaData.type == null || metaData.type == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["type"]: {
          isError: true,
          errorText: "type" + errorMsg
        }
      };
    }
    if (metaData.isEffectonGstr == null || metaData.isEffectonGstr == undefined) {
      error = false;

      formErrors = {
        ...formErrors,
        ["isEffectonGstr"]: {
          isError: true,
          errorText: "isEffect on Gstr" + errorMsg
        }
      };
    }
    
    if (metaData.isEffectonGstr != null && metaData.isEffectonGstr == 1) {
      if (metaData.percentage == null || metaData.percentage == undefined) {
        error = false;
        formErrors = {
          ...formErrors,
          ["percentage"]: {
            isError: true,
            errorText: "percentage" + errorMsg
          }
        };
      }
      if (metaData.HsnCode == null || metaData.HsnCode == undefined) {
        error = false;

        formErrors = {
          ...formErrors,
          ["HsnCode"]: {
            isError: true,
            errorText: "Hsn Code " + errorMsg
          }
        };
      }
      if (metaData.taxableAmount == null || metaData.taxableAmount == undefined) {
        error = false;
        formErrors = {
          ...formErrors,
          ["taxableAmount"]: {
            isError: true,
            errorText: "taxable Amount " + errorMsg
          }
        };
      }
    }

    if (metaData.remark == null || metaData.remark == undefined) {
      error = false;
      formErrors = {
        ...formErrors,
        ["remark"]: {
          isError: true,
          errorText: "remark " + errorMsg
        }
      };
    }
   
   

      
    
    // const mandatoryCheckErrors = mandatoryCheck({
    //   fieldData,
    //   fieldMeta: metaData,
    // });
    console.log("formErrors")
    console.log(formErrors)
    this.setState({
      formErrors: formErrors,
    });

    return error;
  }

  getListData = () => {
    const {
      rowList
    } = this.state;
    let rowListData = ["Total", ""]

    let creditAmount = 0
    let debitAmount = 0
    rowList.map((rowListInfo) => {
      creditAmount = creditAmount + +rowListInfo.creditAmount
      debitAmount = debitAmount + +rowListInfo.debitAmount
      return rowListInfo
    })
    rowListData = [
      ...rowListData,
      creditAmount.toFixed(2),
      debitAmount.toFixed(2),
      ""
    ]
    if (rowList.length != 0)
      return rowListData
    return []
  }
  onSubmitData = () => {
    const { showNotification } = this.props;
    const location = localStorage.getItem("location");
   
    if (this.checkValidationOnSubmit()) {
      const {
        rowList,
        fieldData,
        recieptPaymentDetailCcInfoList,
        recieptPaymentDetailBillInfoList,
        dynamicMasterData,
      } = this.state;
      let totalAmt = 0;
      if (rowList.length != 0) {
        let creditAmount = 0;
        let debitAmount = 0;
        const recieptPaymentDetailList = rowList.map((rowData) => {
          if (rowData.type == "C") {
            creditAmount = creditAmount + +rowData.amt;
          }
          if (rowData.type == "D") {
            debitAmount = debitAmount + -Math.abs(+rowData.amt);
          }
          totalAmt = creditAmount + debitAmount;
          console.log(totalAmt);
          const rowObj = {
            sortNo: 1,
            mainAccId: rowData.mainAccountCode!=null?rowData.mainAccountCode:"",
            amt: rowData.amt!=null?rowData.amt:"",
            type: rowData.type!=null?rowData.type:"",
            remark: rowData.remark!=null?rowData.remark:"",
            recieptPaymentDetailCcInfoList:
              rowData.recieptPaymentDetailCcInfoList!=null? rowData.recieptPaymentDetailCcInfoList:"",
            recieptPaymentDetailBillInfoList: recieptPaymentDetailBillInfoList!=null?recieptPaymentDetailBillInfoList:[],
           
            isEffectonGstr:rowData.isEffectonGstr!=null?rowData.isEffectonGstr:"",
            percentage:rowData.percentage!=null?rowData.percentage:0.00,
            HsnCode: rowData.HsnCode!=null?rowData.HsnCode:"",
            taxableAmount: rowData.taxableAmount!=null?rowData.taxableAmount:"",
          };

          return rowObj;
        });
        let catList = dynamicMasterData["subVoucherDetail"]
          ? dynamicMasterData["subVoucherDetail"].filter(
              (row) => row.id == fieldData.subVoucherMaster.id
            )
          : [];
        console.log(totalAmt);
        const dataToSave = {
          ...fieldData,
          profitCenter: fieldData.profitCenter,
          subVoucherMaster: fieldData.subVoucherMaster!=null?fieldData.subVoucherMaster:"",
          mainAccountId:rowList[0]!=null && rowList[0].mainAccountId?rowList[0].mainAccountId:"",
          location: {
            id: location!=null?location:"",
          },
          remark: fieldData.remark!=null?fieldData.remark:"",
          voucherAmount: totalAmt > 0 ? totalAmt : totalAmt * -1,
          cashBankId: fieldData.cashBankId!=null?fieldData.cashBankId:"",
          paidTo: fieldData.paidTo!=null?fieldData.paidTo:"",
          recieptPaymentDetailList: recieptPaymentDetailList!=null?recieptPaymentDetailList:[],
          date: fieldData.date!=null && fieldData.date!=""?fieldData.date.format("DD-MM-YYYY"):"",
          status: catList[0].autoApproveStatus && catList[0].autoApproveStatus == 0 ? 0 : 1,
          bookType: 3,
          contraEntryType: 0,
        };
        console.log(dataToSave);
       
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            if (catList[0].vouNature == 1 && totalAmt > 0) {
              // showNotification({
              //   msg: "Voucher Amount must be negative",
              //   severity: "error",
              // });
              this.onSave(dataToSave);
            } else if (catList[0].vouNature == 1 && totalAmt < 0) {
              showNotification({
                  msg:"Voucher Amount must be positive" ,
                  severity: "error",
                });
             
            } else if (catList[0].vouNature == 2 && totalAmt <= 0) {
              showNotification({
                msg: "Voucher Amount must be positive",
                severity: "error",
              });
            } else if (catList[0].vouNature == 2 && totalAmt > 0) {
              this.onSave(dataToSave);
            }
           
          }
        });
      } else {
        showNotification({
          msg: "Please add atleast one product",
          severity: "error",
        });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.recieptPaymentHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.setState({
            fieldData: {},
            rowList: []
          })
          this.props.callApi();
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: data.response.data.errorMessage, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    DebitNoteJSON.fieldMetaAdd.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck1 = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    DebitNoteJSON.fieldMeta1.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    DebitNoteJSON.fieldMetaAdd.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck() && this.mandatoryCheck1()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    DebitNoteJSON.fieldMetaAdd.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getData) {
        const dataStatic = this.getDataForDisabled(newValue);
        dataToReset = {
          ...dataToReset,
          ...dataStatic,
        };
      }
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  rowDelete = (rowData) => {
    console.log(rowData);
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        console.log(rowListData);
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        console.log(row);
        let finalAmt =
          rowData.type == "C"
            ? +this.state.voucherAmountShow - +rowData.amt
            : +this.state.voucherAmountShow + +rowData.amt;
        console.log(finalAmt);
        this.setState({
          rowList: row,
          fieldData: {
            ...this.state.fieldData,
            voucherAmount:finalAmt > 0 ? (+finalAmt).toFixed(2) : (+finalAmt * -1).toFixed(2)
            ,
          },
          voucherAmountShow: finalAmt,
        });
      }
    });
  };

  rowCopy = (rowData) => {
    const { openCostCenter } = this.state;
    this.setState({
      openCostCenter: !openCostCenter,
      headerData: rowData,
    });
    console.log(rowData);
  };

  handleCloseCC = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    console.log(rowListData);
    this.setState({
      recieptPaymentDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  OnSaveCostCenter = (dataToSave, headerId) => {
    const { openCostCenter, rowList } = this.state;
    const rowListData = rowList.map((row) => {
      if (row.index == headerId) {
        return {
          ...row,
          recieptPaymentDetailCcInfoList: dataToSave,
        };
      } else {
        return row;
      }
    });
    this.setState({
      recieptPaymentDetailCcInfoList: [],
      openCostCenter: !openCostCenter,
      rowList: rowListData,
    });
  };

  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      openCostCenter,
      headerData,
      setFieldDataFromApi,
    } = this.state;
    const { rowReset } = this.props
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicTransactionForm
           amount={fieldData.voucherAmount ? fieldData.voucherAmount : "0"}
          showReset={true}
          rowReset={rowReset}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={DebitNoteJSON.screenTitle}
            fieldMeta={DebitNoteJSON.fieldMetaAdd}
            apiBaseURL={DebitNoteJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={DebitNoteJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
          />
          <br />
          <Divider sx={{ borderWidth: 1, color: "#000000" }} />
          <br />
          <DynamicFormWithoutSave
           
            dynamicMasterData={{
              ...dynamicMasterData,
              creditAccountDetails:
                dynamicMasterData.creditAccountDetails.filter(
                  (row) => row.id != fieldData.cashBankId
                ),
            }}
            formErrors={formErrors}
            fieldData={fieldData}
            getListById={this.getListById}
            searchList={DebitNoteJSON.searchList}
            showTitle={DebitNoteJSON.showTitle}
            screenTitle={DebitNoteJSON.screenTitle}
            fieldMeta={DebitNoteJSON.fieldMetaDetails}
            showCancel={DebitNoteJSON.showCancel}
            showBackToList={DebitNoteJSON.showBackToList}
            apiBaseURL={DebitNoteJSON.apiBaseURL}
            showSaveNextBtn={DebitNoteJSON.showSaveNextBtn}
            saveBtnText={"ADD"}
            onSave={this.onSearch}
            resetAfterSave={true}
            getDataForDisabled={this.getDataForDisabled}
            setFieldDataFromApi={setFieldDataFromApi}
          />

          <br />
          <RTTable
          totalList={this.getListData()}
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "mainAccountCode", title: "ACCD" },
              { name: "mainAccountName", title: "Account Head" },
              {
                name: "creditAmount",
                title: "Credit Amount",
                align: "right",
              },
              {
                name: "debitAmount",
                title: "Debit Amount",
                align: "right",
              },
              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            isActionColActive={true}
            showEditIcon={false}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
            showCopyIcon={true}
            rowCopy={this.rowCopy}
            iconTitleForCopy="Cost Center"
          />
          <br />
          <DynamicTransactionForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={DebitNoteJSON.screenTitle}
            fieldMeta={DebitNoteJSON.fieldMeta1}
            apiBaseURL={DebitNoteJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={DebitNoteJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showTitle={false}
            getDataForDisabled={this.getDataForDisabled}
          />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
        {openCostCenter && (
          <CostCenterMappingDialouge
            open={openCostCenter}
            handleCloses={this.handleCloseCC}
            dynamicMasterData={dynamicMasterData}
            onSave={this.OnSaveCostCenter}
            dataToSave={headerData}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
  subVoucherList: state.subVoucher,
  cashRecieptList: state.cashReciept,
  profitCenter: state.profitCenter
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getAccountLedgerListByType1,
  getSubVoucherByType,
  getCostCenter,
  getLedgerDataByAccId,
  getProfitCenter,
  //   getProductByCategoryForStakeHolder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DebitNoteForm);
