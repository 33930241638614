import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, ListItemButton, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { connect } from "react-redux";
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from "react-router";
import Search from "../components/SearchAll";
import config from "../config/config";
import { resetReducList } from "../CommonActions/User.action";
import { setModuleNameData } from "../Slice/accessRole.slice";

import { MyComponentWithIconProps } from "./Comman/IconButton";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const styles = (theme) => ({
  drawer: {
    color: theme.palette.primary.white,
    // overflow: "hidden",
    // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    cursor: "pointer",
    height: "auto",
    overflow: "auto",
    // borderTop: `1px solid ${alpha(theme.palette.primary.black, 0.16)}`,
    "& li": {
      color: theme.palette.primary.black,

      "text-decoration": "none",
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      // borderRadius: "5px",
      // marginBottom: theme.spacing(2),
      // marginTop: theme.spacing(2)
    },
    "& li:hover": {
      "text-decoration": "none",
    },
    "& li:active": {
      color: alpha(theme.palette.primary.white, 3),
      backgroundColor: theme.palette.primary.main,
      "text-decoration": "none",
    },
  },
  paper: {
    // backgroundColor: theme.palette.primary.smokeGrey,
  },
  itemList: {
    "& .MuiTypography-root": {
      fontSize: "12px",
      margin: "0px",
      paddingLeft: "16px",
      paddingLeft: "8px"
    },
  },
  itemList1: {
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  },
  navMenu: {
    color: alpha(theme.palette.primary.black, 0.9),

    cursor: "pointer",
    "text-decoration": "none",
    // marginLeft: "16px !important",
    // marginRight: theme.spacing(1),
    // borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingleft: '16px',
    // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    border: "1px",
    display: "block !important",
    "& :hover": {
      color: theme.palette.primary.main,
      "text-decoration": "none",
      width: "100%",
      display: "block",
    },
    "& :active": {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.white, 3),
      "text-decoration": "none",
    },
    "& .MuiListItemButton-root.Mui-selected": {
      //  backgroundColor: theme.palette.primary.smokeGrey,
      // borderRadius: theme.spacing(2),
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
      // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    },
    "& .MuiListItemButton-root": {
      padding: "3px 0px",
    },
    // "& .MuiListItemButton-root:hover": {
    //   backgroundColor: theme.palette.primary.white,
    // },
  },

  "MuiButtonBase-root": {
    border: "1px !important",
    cursor: "pointer",
    display: "inline-flex",
    position: "relative",
    "align-items": "center",
    "user-select": "none",
    "border-radius": "1px !important",
    "vertical-align": "middle",
    "justify-content": "center",
    "text-decoration": "none",
    "background-color": "transparent",
  },
});

const DrawerNav = ({ accessRole, classes, toggle,setModuleNameData, resetReducList, common }) => {
  const navigate = useNavigate();
  const [menuListNew, setMenuListNew] = React.useState([]);
  const [moduleName, setModuleName] = React.useState("");
  const changePath = (submenu, menu) => {
    if (submenu !== window.location.pathname) {
      resetReducList();
      setModuleNameData({ name:  accessRole.moduleName })    }
    if (submenu === "") {
    } else {
      const rows = menuListNew.map((module) => {
        module = {
          ...module,
          subModuleList: module.subModuleList.map((subModuleName) => {
            if (subModuleName.subModuleMapping === submenu) {
              subModuleName = {
                ...subModuleName,
                checked: true,
              };
              return subModuleName;
            } else {
              subModuleName = {
                ...subModuleName,
                checked: false,
              };
              return subModuleName;
            }
          }),
        };
        return module;

      });
     
      setMenuListNew(rows);
      navigate(submenu);
      localStorage.removeItem("formData");
      if (submenu !== window.location.pathname) {
        resetReducList();
        setModuleNameData({ name:  accessRole.moduleName })  
      }
    }
  };

  React.useEffect(() => {
    if (accessRole.moduleName !== moduleName) {
      if ((menuListNew.length != 0 && accessRole.moduleName != undefined && menuListNew[0].moduleName != accessRole.moduleName) || menuListNew.length == 0) {
        setModuleName(accessRole.moduleName)
        const moduleSubModuleList = localStorage.getItem("moduleList");
        if (moduleSubModuleList) {
          const valuesArray = JSON.parse(moduleSubModuleList);


          let moduleName = localStorage.getItem("moduleName");
          let subMob = valuesArray.filter((row) => row.moduleName == moduleName)
          //  const rows = menuListNew.map((module) => {
          //         if (module.moduleName === menu) {
          //           module = {
          //             ...module,
          //             subModuleList: module.subModuleList.map((subModuleName) => {
          //               if (subModuleName.subModuleMapping === submenu) {
          //                 subModuleName = {
          //                   ...subModuleName,
          //                   checked: true,
          //                 };
          //                 return subModuleName;
          //               } else {
          //                 subModuleName = {
          //                   ...subModuleName,
          //                   checked: false,
          //                 };
          //                 return subModuleName;
          //               }
          //             }),
          //           };
          //           return module;
          //         } else {
          //           return module;
          //         }
          //       });
        
          setMenuListNew(subMob)
        }


      }


    }

  });


  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        anchor="left"
        open={common.toggleNav}
        onClose={toggle}
        variant="persistent"
        sx={{
          width: config.drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: config.drawerWidth,
            boxSizing: "border-box",
            bgcolor: "primary.white",
            border: "none",
            cursor: "pointer",
            borderRight: "2px solid #CFCFCF"
          },
          [`& .MuiListItem-root.Mui-selected`]: {
            color: "primary.white",
            bgcolor: "primary.main",
            border: "none",
          },

          [`& .MuiListItem-root`]: {
            color: "primary.black",
            bgcolor: "primary.white",
            border: "none",
            paddingLeft: '16px',
            paddingTop: '5px',
            paddingBottom: '2px',
            backgroundColor: "#379200",
            color: "#fff"
          },
          [`& .MuiList-root`]: {
            paddingTop: "0px",
          },
          [`& .MuiToolbar-root`]: {
            minHeight: '52px'
          }
        }}
      >
        <Toolbar />

        <List>
          {menuListNew &&
            menuListNew.map((item) => {
              return <>
                <ListItem
                  // selected={item.checked}
                  key={item.moduleName}
                // onClick={() => handleClick(item.moduleName, false)}
                >

                  <ListItemText
                    className={classes.itemList1}
                    sx={{ fontWeight: 600 }}
                    primary={item.moduleName}
                  // onClick={() => handleClick(item.moduleName, false)
                  />

                </ListItem>

                {item.subModuleList.map((nav) => {

                  return (
                    <List
                      component="div"
                      disablePadding
                      className={classes.navMenu}
                    >
                      <ListItemButton
                        selected={nav.checked}
                        name={nav.subModuleName}
                        onClick={() =>
                          changePath(
                            nav.subModuleMapping,
                            item.moduleName
                          )
                        }
                      >



                        <ListItemText
                          className={classes.itemList}
                          primary={nav.subModuleName}
                        />
                      </ListItemButton>
                    </List>
                  );
                  return null;
                })}
              </>

            })}
        </List>
      </Drawer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  student: state.student,
  accessRole: state.accessRole,
});

const mapDispatchToProps = { setModuleNameData,resetReducList };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DrawerNav));
