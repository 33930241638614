import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { encodeData } from "../../components/Comman/Util/Base64";
import { Loading } from "../../components/Loader";
import { DimGrayColor, white } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showNotification } from "../Landing/Landing.slice";
import { login } from "./login.slice";
const styles = (theme) => ({
    root: {
        height: "100vh",
    },
    top: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    bottom: {
        position: "absolute",
        left: 0,
        bottom: 0,
    },
    ellipse: {
        // position: "static",
        // width: "517px",
        // height: "350px",
        // left: "10px",
        // top: "10px",

        // background: "rgba(41, 151, 171, 0.2)",
        // flex: "none",
        // order: "0",
        // flexgrow: "0",
        justifyContent: "center",
    },
    loginBox: {
        display: "flex",
        border: `1px solid ${theme.palette.grey[400]}`,
        margin: "50px",
        width: "auto",
        zIndex: "1",
        backgroundColor: "white",
    },
    text: {
        fontfamily: "Roboto",
        fontstyle: "normal",
        fontweight: "bold",
        fontsize: "32px",
        textAlign: "center",

        color: white,
    },
    bmn: {
        padding: "30px",
        justifyContent: "center",

        // alignSelf:'center',
    },
    left: {
        //  width: "50%",
    },
    hello: {
        textAlign: "center",
        top: "60px",
        left: "50px",
    },
    helper: {
        textAlign: "center",
        height: "56px",
        fontfamily: "Roboto",
        fontstyle: "normal",
        fontweight: "normal",
        fontsize: "18px",
        lineheight: "28px",
        color: DimGrayColor,
    },
    field: {
        padding: "10px 50px",
        margin: "50px",
    },
    stay: {
        fontfamily: "Roboto",
        fontstyle: "normal",
        fontweight: "normal",
        fontsize: "14px",
        lineheight: "28px",
        paddingLeft: "20px",

        color: DimGrayColor,
    },
    ellipse2: {
        width: "504px",
        height: "504px",
        left: "-139px",
        top: "500px",

        background: white,
        border: "80px solid #2997AB",
        boxsizing: "border-box",
    },
    fogot: {
        left: "500px",
    },
    body: { backgroundColor: "#f5f5f5" },
});

const initState = { name: "", password: "" };

const Login = ({ login,  showNotification }) => {
    const [auth, setAuth] = useState(initState);
    const [nameErr, setNameErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [loginErr, setLoginErr] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => { }, []);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const Login = () => {
        if (auth.name !== "" && auth.password !== "") {
            setPasswordErr("");
            setNameErr("");
            setLoginErr("");
            const name = `${auth.name}:${auth.password}`;
            const userName = encodeData(name);
            setShowLoading(true);
            login({ username: auth.name, password: auth.password }).then(
                async ({ success }) => {
                    if (success) {
                        setShowLoading(false);
                        setShowLoading(true);
                      
                        await apiGet({
                            url: endpoint.setting + "/by-key?key=freez_current_date",
                        }).then(({ data, success }) => {
                            setShowLoading(false);
                            if (success) {
                                console.log(data.data.value);

                                localStorage.setItem(
                                    "freezCurrentDate",
                                    data.data.value
                                );

                            } else {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }

                            return success;
                        });
                        await apiGet({
                            url: endpoint.user,
                        }).then(({ data, success }) => {
                            setShowLoading(false);
                            if (success) {
                                console.log(data);
                                localStorage.setItem("userId", data.data.usrId);
                                localStorage.setItem("userName", data.data.usrName);
                                if (data.data.locationIdList == null&&data.data.locationIdList.length!=0) {
                                    localStorage.setItem("location", data.data.locationIdList[0].id);
                                  }
                                localStorage.setItem(
                                    "locationIdList",
                                    JSON.stringify(data.data.locationIdList)
                                );
                                localStorage.setItem(
                                    "moduleList",
                                    JSON.stringify(data.data.modules)
                                );
                                window.location.replace("/master");
                            } else {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }

                            return success;
                        });
                    } else {
                        setShowLoading(false);
                        setLoginErr("Invalid Credential");
                    }
                }
            );
        } else {
            if (auth.name === "" && auth.password === "") {
                setNameErr("Username required");
                setPasswordErr("Password required");
            } else if (auth.name === "") {
                setNameErr("Username required");
                setPasswordErr("");
            } else if (auth.password === "") {
                setPasswordErr("Password required");
                setNameErr("");
            } else {
                setPasswordErr("");
                setNameErr("");
            }
        }
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setAuth({
            ...auth,
            [name]: value,
        });
    };

    const onApiCalled = (e) => {
        //href="forgot-password-pre-login"
    };

    return (
        <>
              <div className="new_login_content">
        <Grid className="new_logo_content">
          <Grid className="new_title_content">Accounts</Grid>
        </Grid>

     
      </div>
      {showLoading && <Loading />}
        </>
    );
};
const mapStateToProps = (state) => ({
    user: state,
    common: state.common,
});

const mapDispatchToProps = {
    login,
    showNotification,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Login));
