import endpoint from "../../config/endpoints";

export const creditNoteERPJSON = {
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  screenTitle: "Credit Note",
  showTitle: true,
  fieldMeta: [
    {
      label: "CRN",
      controlType: "radiogroup",
      md: 12,
      lg: 12,
      sm: 12,     
      xs: 12,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM:11,
      valueMD:11,
      valueLG:11,
      dataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "without GRN ",
          value: "0",
        },
        {
          label: "with GRN ",
          value: "1",
        },
      ],
      isMandatory: true,
    }, {
      label: "Customer",
      controlType: "autocomplete",
      placeHolder: "Select Customer",
      md:4,
      lg:4,
      sm:4,
      xs: 12,
      masterName: "VendorDetails",
      dataKey: "vendor",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "entryType,accountLedgerCode",
    },
  ],
  fieldMetaGRN: [
    {
      label: "CRN",
      controlType: "radiogroup",
      md: 12,
      lg: 12,
      sm: 12,     
      xs: 12,
      labelSM: 1,
      labelMD: 1,
      labelLG: 1,
      valueSM:11,
      valueMD:11,
      valueLG:11,
      dataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "without GRN ",
          value: "0",
        },
        {
          label: "with GRN ",
          value: "1",
        },
      ],
      isMandatory: true,
    }, {
      label: "Customer",
      controlType: "autocomplete",
      placeHolder: "Select Customer",
      md:4,
      lg:4,
      sm:4,
      xs: 12,
      masterName: "VendorDetails",
      dataKey: "vendor",
      isRootLevelKey: true,
      isMandatory: true,
    },{
      label: "GRN",
      controlType: "autocomplete",
      placeHolder: "Select GRN",
      md:4,
      lg:4,
      sm:4,
      xs: 12,
      masterName: "GRNDetails",
      dataKey: "grn",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
