import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../components/withRouter";
import {
    noInternetMsg,
   
  } from "../../config/messageconstant";
  import RTTable from "../../components/Comman/RT/RTTable";
  import { salePurchaseHistotyJSON } from "../../DynamicFormsJson/Report/SalePurchaseHistory";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";

import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";






 


class SalePurchaseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      rowList:[],
    }
      this.columns = [
        
        { name: "index", title: "Sr. No.", align: "center" },
        { name: "itemCode", title: "item Code", align: "center" },
        { name: "qty", title: "Quantity", align: "right" },
        { name: "mrp", title: "MRP",align: "right" },
        { name: "rate", title: "Rate", align: "right" },
        { name: "weight", title: "Weight", align: "right" },
       
      ];
     
    
  }
  async componentDidMount() {
    const {  showLoador, showNotification } = this.props;
    if (navigator.onLine) {
     
      if (this.props.params.id) {
        showLoador({ loador: true });
        showLoador({ loador: true });
        await apiGet({
           url:  endpoint.purchaseSaleHeader + "/" + this.props.params.id ,
       }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
           if(data.data!=null){
       
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Document No",
                  value: data.data.documentNo?data.data.documentNo
                   
                    : "",
                },

                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Document Date",
                  value:data.data.documentDate !== null ? data.data.documentDate : "",
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Account Ledger Code",
                  value:
                  data.data.accountLedgerCode!=null? data.data.accountLedgerCode:""
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Account Ledger Name",
                  value:
                  data.data.accountLedgerName!=null? data.data.accountLedgerName:""
                },
             
                
              ],
            });
            if(data.data.purchaseSalesVoucherDetail.length!=0){
              const list=data.data.purchaseSalesVoucherDetail.map((data,index)=>{
                index=index+1
                return {
                  index:index,
                  
                  itemCode:data.itemCode,
                  qty:data.qty,
                  mrp:data.mrp,
                  rate:data.rate,
                  weight:data.weight,
                  ...data,
                }
              })
              this.setState({
                rowList:list,
              })
            }
          }
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  
  

  
  
  render() {
    const { DataToSet,rowList  } =
      this.state;
   

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
           
         


         
           
         
          <DynamicFormWithoutSave
             showBackToList={false}
            DataToSet={DataToSet}
            showSaveBtn={salePurchaseHistotyJSON.showSaveBtn}
            showTitle={true}
            screenTitle={"Sale Purchase View"}
            fieldMeta={[]}
            showCancel={salePurchaseHistotyJSON.showCancel}
            apiBaseURL={salePurchaseHistotyJSON.apiBaseURL}
            showSaveNextBtn={salePurchaseHistotyJSON.showSaveNextBtn}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <RTTable
            columns={this.columns}
            tableData={
              rowList.length!=0?rowList
                : []
            }
            isActionColActive={false}
            showPegination={false}
            showEditIcon={false}
            showDeleteIcon={false}
            showViewButtonInTable={false}
            // rowView={this.rowView}
            // showOutlet={true}
            // rowOutlet={this.rowOutlet}
          />
      
         
        </Paper>
       
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
  cashRecieptList: state.cashReciept,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,


};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SalePurchaseView);
