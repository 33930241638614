import { Paper, Grid } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { ManageStockJSON } from "../../DynamicFormsJson/Transaction/manageStock";
import { getCashRecieptWithAccNameById } from "../../Slice/cashReceipt.slice";
import{getTimeline}from "../../Slice/timeline.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import CostCenterMappingViewDialouge from "./CostCenterMappingViewDialouge";
import BillMappingViewDialouge from "./BillMappingViewDialouge";
import NoDataFound from "../../components/NoDataFound";
import TimeLine from '../../components/TimeLine'
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { LabelCompo } from "../../components/Comman/Label";
import { blackColor, greyColor, primaryColor } from "../../config/ColorObj";
import NumberToWords from "../../components/Comman/NumberToWords"
import jsPDF from 'jspdf';
import moment from 'moment';
import { ToWords } from 'to-words';
import CashReceiptPreview from "./CashReceiptPreview";
class CashRecieptView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      formData: {},
      manageStockList: [],
      timelineList: [],
      openCostCenterView: false,
      openBillView: false,
      headerData: {},
      locationList:[],
      openPreviewPopup:false,
      id:"",
    };
    this.columns = [
      { name: "index", title: "Sr. No.", align: "center" },
      { name: "mainAccId", title: "ACCD", align: "center" },
      { name: "accountLedgerName", title: "Account Name" },
      {
        name: "creditAmount",
        title: "Credit Amount",
        align: "right",
      },
      {
        name: "debitAmount",
        title: "Debit Amount",
        align: "right",
      },
      { name: "action", title: "Action", align: "center" },
    ];
  }
  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.editData).length != 0) {
      if (this.props.editData.id != nextProps.editData.id) {
        this.getByIdData(nextProps.editData.id)
      }
    }
  }
  async componentDidMount() {
    const { showNotification } =
      this.props;
    if (navigator.onLine) {
      const locationIdList = localStorage.getItem("locationIdList");
      if(locationIdList.length!=0){
        const valuesArray = JSON.parse(locationIdList);
        this.setState({locationList:valuesArray})
      }
      if (Object.keys(this.props.editData).length != 0 && this.props.editData.id) {
        this.getByIdData(this.props.editData.id)
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getByIdData = async (id) => {
    const { getCashRecieptWithAccNameById, getTimeline,showLoador, showNotification } =
      this.props;

    showLoador({ loador: true });
    await getCashRecieptWithAccNameById({
      id: id,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        this.setState({
          formData: response,
          DataToSet: [
            {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              label: "Sub-Voucher Name",
              value: response.subVoucherName
                ? response.subVoucherName
                : "-",
            },
            {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              label: "Profit Center",
              value: response.profitCenter !== null ? response.profitCenter.name : "",
            },
            {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              label: "Date",
              value: response.date !== null ? response.date : "-",
            },
            {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              label: "Cash A/C",
              value: (response.cashBankId !== null && response.cashBankCode != null
                ? response.cashBankCode + " ( " + response.cashBankId + " )" : "-"),
            },
            {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              label: "Received From",
              value:
                response.receivedFrom !== null ? response.receivedFrom : "-",
            },
            {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
              label: "Remark",
              value:
                response.remark != null && response.remark != ""
                  ? response.remark
                  : " - ",
            }
          ],
        });
      }
    });
    showLoador({ loador: true });
    await getTimeline({
      transactionId: id,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } 
    });
 
  }
  rowView = (rowData) => {
    this.setState({
      openCostCenterView: !this.state.openCostCenterView,
      headerData: rowData,
    });
  };

  rowOutlet = (rowData) => {
    this.setState({
      openBillView: !this.state.openBillView,
      headerData: rowData,
    });
  };
  getListData = () => {
    const {
      cashRecieptList
    } = this.props;
    let rowList = Object.keys(cashRecieptList.cashRecieptById).length != 0
      ? cashRecieptList.cashRecieptById.dtoRecieptPaymentDetailList.map((rowData) => {
        return {
          ...rowData, creditAmount: rowData.type == "C" ? (+rowData.amt).toFixed(2) : "",
          debitAmount: rowData.type == "C" ? "" : (+rowData.amt).toFixed(2),
        }
      })
      : []
    let rowListData = ["Total", ""]

    let creditAmount = 0
    let debitAmount = 0
    rowList.map((rowListInfo) => {
      creditAmount = creditAmount + +rowListInfo.creditAmount
      debitAmount = debitAmount + +rowListInfo.debitAmount
      return rowListInfo
    })
    rowListData = [
      ...rowListData,
      creditAmount.toFixed(2),
      debitAmount.toFixed(2),
      ""
    ]
    if (rowList.length != 0)
      return rowListData
    return []
  }
  onClickUser = (timelineDetails) => {
    console.log(timelineDetails)
    this.setState({openPreviewPopup:!this.state.openPreviewPopup})
    this.setState({id:timelineDetails.id})
    // this.props.navigate("/cash-receipt-preview/"+timelineDetails.id);
  }
  generatePDF = ({ rowList, columns, headerList = [], columnStylesPlain, columnStyles,pdfTitle, top = 40 }) => {
   const{ editData}=this.props;
   const{ formData,locationList } = this.state;
   console.log(locationList)
    var doc = new jsPDF('p', 'pt');
    const filterList=locationList.length!=0 && locationList.filter((location)=>location.id==formData.locationId);
    const toWords = new ToWords();
    let title=filterList.length!=0 && filterList[0]!=null && filterList[0].company? filterList[0].company.companyName:"-"
    let address=filterList.length!=0 && filterList[0]!=null && filterList[0].company? filterList[0].company.address:"-"
    doc.setFontSize(16);
    doc.setTextColor(40);
    doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');

    //   doc.setFontStyle('normal');
    
    var textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
    var pageWidth = doc.internal.pageSize.getWidth();
    var xPos = (pageWidth - textWidth) / 2;
  
    doc.text(title, xPos, 50, { maxWidth: 540 });
    doc.setFontSize(8);
    var textWidthAdd = doc.getStringUnitWidth(address) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    var xPosAddr = (pageWidth - textWidthAdd) / 2;
    doc.text(address, xPosAddr, 65, { maxWidth: 540 });
    doc.setFontSize(10);
    var textWidthMainName= doc.getStringUnitWidth(pdfTitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    var xPosMainName = (pageWidth - textWidthMainName) / 2;
    doc.text(pdfTitle, xPosMainName, 90, { maxWidth: 540 });
    let tableData = []
    let tableList = []
    let date= Object.keys(editData).length != 0 && formData.date && formData.date != null ? formData.date : "";
    let documentNo=+ Object.keys(editData).length != 0 && formData.documentNo && formData.documentNo != null ? formData.documentNo : "-";
    let receiveFrom=Object.keys(editData).length != 0 && formData.receivedFrom && formData.receivedFrom != null ? formData.receivedFrom : "-";
   let voucherAmount=Object.keys(editData).length != 0 && formData.voucherAmount && formData.voucherAmount != null ? formData.voucherAmount.toFixed(2) : "-";
  
   
   
let words = formData.voucherAmount!=null && formData.voucherAmount!=""?toWords.convert(formData.voucherAmount):"-";
   doc.line(20, 100, doc.internal.pageSize.width - 20, 100);

    doc.text('Receipt No. : '+documentNo, 30, 120, { maxWidth: 540 });
    
    doc.text('Date : '+date, doc.internal.pageSize.width - 110, 120, { maxWidth: 540 });
    doc.line(20, 130, doc.internal.pageSize.width - 20, 130);
    doc.text('Received with thanks from ', 25, 140, { maxWidth: 540 });
    doc.text(receiveFrom, 150, 140, { maxWidth: 540 });
    doc.text('the sum of Rs. '+voucherAmount, doc.internal.pageSize.width - 130, 140, { maxWidth: 540 });
   
    doc.text('(Rs. '+ words +' )', 25, 155, { maxWidth: 540 });
    doc.text("by Cash", doc.internal.pageSize.width - 130, 155, { maxWidth: 540 });
    doc.text('as per the following details.', 25, 170, { maxWidth: 540 });
    doc.line(20, 175, doc.internal.pageSize.width - 20, 175);
    var tableStyles = {
      lineColor: "#c1c1c1", // Border color (black in this example)
      lineWidth: 0.5,       // Border line width
      // fontStyle: 'bold'    // Font style for the table
    };
    // doc.autoTable({
    //   startY: 90,
    //   head: [], 
    //   body: tableList,
    //   theme: 'plain',
    //   columnStyles:  columnStylesPlain
    // });
    doc.autoTable({
      head: columns, 
      body: rowList,
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      startY:  190,
  
      margin: { right: 30, left: 30},
      headStyles: {
        halign: 'center',
      },
      theme: 'plain',
      styles: tableStyles,
      columnStyles: columnStyles,
      bodyStyles: { valign: 'top' },
  
      showHead: "everyPage",
      didParseCell: (hookData) => {
        if (hookData.row.index === rowList.length - 1) {
          // Check if it's the last row
          doc.setFont('helvetica', 'bold'); // Set the font style to bold
          doc.setFontSize(50); // Set the font size (adjust as needed)
        }
      },
      didDrawRow: function (data) {
       
        if (data.row.index === rowList.length - 1) {
          // Check if it's the last row
          doc.setFont('helvetica', 'bold'); // Set the font style to bold
          doc.setFontSize(16); // Set the font size (adjust as needed)
        }
      },
      didDrawPage: function (data) {
  
  
        var str = "Page " + doc.internal.getNumberOfPages();
  
  
  
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        let pb = "Powered By Bizintel DMS"
        const compName = "Company Name"
        var textWidthCN = doc.getStringUnitWidth(compName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        var textWidth = doc.getStringUnitWidth(str) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
        // Calculate the X position to align to the right of the page
        var pageWidth = doc.internal.pageSize.getWidth();
        // Adjust for a margin if needed
        doc.setFontSize(10);
  
  
        var xPos = pageWidth - textWidth - 10;
        doc.text(pb, 50, pageHeight - 10);
  
        doc.setFontSize(10);
        doc.text(str , xPos, pageHeight - 10);
      }
    });
    const fileName = pdfTitle.split(" ").join("") + "-" + moment().format("DD-MM-YYYY")
    doc.save(fileName + '.pdf');
  }
  showPdfData = () =>{
    const columnStyles = {
     
          
    };
   
    const{cashRecieptList}=this.props;
    var columns = [["Sr. No.", "ACCD", "Account Name","Credit Amount","Debit Amount"]];
    // var rows = [["20-Nov-23", "7733", "9,956.00","20-Nov-23", "7733", "9,956.00","20-Nov-23", "7733", "9,956.00"],
    // ["20-Nov-23", "7733", "9,956.00","20-Nov-23", "7733", "9,956.00","20-Nov-23", "7733", "9,956.00"],
    // ["20-Nov-23", "7733", "9,956.00","20-Nov-23", "7733", "9,956.00","20-Nov-23", "7733", "9,956.00"]];
    let rowList = [];
    let totalCreditAmount=0;
    let totalDebitAmount=0;
   
    Object.keys(cashRecieptList.cashRecieptById).length != 0
    && cashRecieptList.cashRecieptById.dtoRecieptPaymentDetailList.map(((rowData, index) => {
     let creditAmount= rowData.type == "C" ? (+rowData.amt).toFixed(2) : ""
     let debitAmount=rowData.type == "C" ? "" : (+rowData.amt).toFixed(2)
      totalCreditAmount= rowData.type == "C" ? totalCreditAmount+(+rowData.amt) : totalCreditAmount
      totalDebitAmount= rowData.type != "C" ? totalDebitAmount+(+rowData.amt) : totalDebitAmount
            let tableData = []
            index=index+1
            tableData.push(rowData.index)
            tableData.push(rowData.mainAccId)

            tableData.push(rowData.accountLedgerName)
            tableData.push(creditAmount)

            tableData.push(debitAmount)
            rowList.push(tableData)
        }))
        
      rowList.push(["","Total","",(+totalCreditAmount).toFixed(2),(+totalDebitAmount).toFixed(2),])
    
     
     
    this.generatePDF({rowList, columns,headerList : [], columnStylesPlain:columnStyles, columnStyles,pdfTitle:"Cash Receipt", top : 40 })
   
  }
  togglePreviewPopup =()=>{
    this.setState({openPreviewPopup:!this.state.openPreviewPopup})
  }
  render() {
    const { DataToSet,openPreviewPopup,id, formData, openCostCenterView, headerData, openBillView, timelineList } =
      this.state;
    const { cashRecieptList, rowEdit, rowDelete, approvalFlag, rowReset, editData ,timeline} = this.props;

    return (
      <>

        <Paper
          sx={{
            padding: 2,
            paddingTop: 1,
            borderRadius: 0,
            height: "100%"
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showEdit={Object.keys(editData).length != 0 ? true : false}
            showDelete={Object.keys(editData).length != 0 ? true : false}
            showPdf={Object.keys(editData).length != 0 ? true : false}
            generatePDF={this.showPdfData}
            rowEdit={rowEdit}
            rowDelete={rowDelete}
            rowReset={rowReset}
            showReset={approvalFlag == 0 ? true : false}
            showTable={true}
             showBackToList={false}
            showExcelDownload={false}
            DataToSet={Object.keys(editData).length != 0 ? DataToSet : []}
            showSaveBtn={ManageStockJSON.showSaveBtn}
            showTitle={true}
            screenTitle={"Cash Receipt View"}
            fieldMeta={[]}
            subTitle={Object.keys(editData).length != 0 && formData.documentNo && formData.documentNo != null ? formData.documentNo : ""}
            amount={Object.keys(editData).length != 0 && formData.voucherAmount && formData.voucherAmount != null ? formData.voucherAmount.toFixed(2) : ""}
            showCancel={ManageStockJSON.showCancel}
            apiBaseURL={ManageStockJSON.apiBaseURL}
            showSaveNextBtn={ManageStockJSON.showSaveNextBtn}
            showSaveBtnMain={false}
            padding={false}
            paddingTop={false}
          />
          {
            Object.keys(editData).length != 0 ? <RTTable
              columns={this.columns}
              tableData={
                Object.keys(cashRecieptList.cashRecieptById).length != 0
                  ? cashRecieptList.cashRecieptById.dtoRecieptPaymentDetailList.map((rowData) => {
                    return {
                      ...rowData, creditAmount: rowData.type == "C" ? (+rowData.amt).toFixed(2) : "",
                      debitAmount: rowData.type == "C" ? "" : (+rowData.amt).toFixed(2),
                    }
                  })
                  : []
              }
              totalList={this.getListData()}
              isActionColActive={true}
              showPegination={false}
              showDeleteIcon={false}
              showEditIcon={false}
              showCopyIcon={true}
              iconTitle="Map Sell Bill"
              iconTitleForCopy="Cost Center"
              rowCopy={this.rowView}
              showAddButtonInTable={true}
              rowAdd={this.rowOutlet}
            />
              : <NoDataFound />
          }
          <>

            <TimeLine onClickUser={this.onClickUser} timelineList={timeline.timeline} documentNo={formData.documentNo ? formData.documentNo : "-"} />
          </>
        </Paper>
        {openCostCenterView && (
          <CostCenterMappingViewDialouge
            open={openCostCenterView}
            handleCloses={this.rowView}
            dataToSave={headerData}
          />
        )}
        {openBillView && (
          <BillMappingViewDialouge
            open={openBillView}
            handleCloses={this.rowOutlet}
            dataToSave={headerData}
          />
        )}
        {openPreviewPopup && (
        <CashReceiptPreview
          
          id={id}
          open={openPreviewPopup}
          handleCloses={this.togglePreviewPopup}
          rowEdit={rowEdit}
          rowDelete={rowDelete}
          approvalFlag={approvalFlag}
          rowReset={rowReset}
           editData={editData}
            timeline={timeline}

        />
      )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
  cashRecieptList: state.cashReciept,
  timeline:state.timeline
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getTimeline,
  getCashRecieptWithAccNameById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CashRecieptView);
