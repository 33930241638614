import React from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";

const MFMPALTable = ({ rowList = [], tableHead = [], rowLink, classes }) => {




    return (
        <>
            <div style={{ overflow: "auto" }}>
                <table className={classes.MuiTableWithoutBorder}>
                    <tr >
                        {
                            tableHead.map((head) => {
                                return (<td style={{ textAlign: "center" }} className={classes.MuiTableHeadCell} >{head.title}</td>)
                            })
                        } </tr>
                    {

                        rowList.map((data) => {

                            return (
                                <>
                                    <tr>
                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{ fontWeight: fontWeight, fontSize: 16, }}>
                                            {data.name}
                                        </td>

                                    </tr>
                                    {
                                        data.groupList.map((group) => {

                                            return (
                                                <>
                                                    <tr>
                                                        {
                                                            group.groupNameLink == undefined || group.groupNameLink ?
                                                                <td style={{
                                                                    cursor: "pointer",
                                                                }} onClick={(e) => rowLink(group, "groupName")} className={classes.MuiTableBodyCellWithoutBorder} >
                                                                    {group.groupName}
                                                                </td>
                                                                : <td className={classes.MuiTableBodyCellWithoutBorder} >
                                                                    {group.groupName}
                                                                </td>
                                                        }

                                                        <td className={classes.MuiTableBodyCellWithoutBorder} style={{textAlign:"right"}}>
                                                            {group.amount}
                                                        </td>

                                                    </tr>

                                                </>

                                            )
                                        })
                                    }
                                </>
                            )
                        })}

                </table>
            </div>
        </>
    );
};

export default withStyles(styles)(MFMPALTable);

