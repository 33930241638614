// import * as React from "react";
// import CircularProgress from "@material-ui/core/CircularProgress";

// import "../CSS/loading1.css";
// export const Loading1 = ({ size }) => (
//   <div className="loading-shading-mui">
//     <CircularProgress
//       size={size}
//       color="primary"
//       variant="indeterminate"
//       disableShrink={true}
//       thickness={2.5}
//       className="loading1"
//     />
//   </div>



// );

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { white } from '../config/ColorObj';

export const Loading1 = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Backdrop
      sx={{ color: white, zIndex: 9999 }}
      open={true}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}