import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  year: [],
  closingList: [],
  stockCategoryList: [],
};
let URL = endpoints.year;
let URL1 = endpoints.stockClosing;
let URL2 = endpoints.stockCategory;
const stockValue = createSlice({
  name: "stockValue",
  initialState,
  reducers: {
    yearSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        year: row,
      };
    },
    closingListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      console.log(row);
      return {
        ...state,
        closingList: row,
      };
    },
    stockCategoryListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stockCategoryList: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  yearSuccess,
  closingListSuccess,
  stockCategoryListSuccess,
  resetState,
} = stockValue.actions;

export default stockValue.reducer;

export const getYear = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        const content = data.data;
        const row = content.map((typeObject, index) => {
          let typeData = {
            index: index + 1,
            id: typeObject.id === null ? "" : typeObject.id,
            name: typeObject.yearName === null ? "" : typeObject.yearName,
          };
          return typeData;
        });
        dispatch(yearSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStockCategoryList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2,
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        const content = data.data;
        const row = content.map((typeObject, index) => {
          let typeData = {
            index: index + 1,
            id: typeObject.id === null ? "" : typeObject.id,
            name: typeObject.name === null ? "" : typeObject.name,
          };
          return typeData;
        });
        dispatch(stockCategoryListSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getClosingList =
  ({ yearId, locationIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-closing-list?yearId=" +
          yearId +
          "&locationId=" +
          locationIds,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          const content = data.data;
          console.log(content);
          const row = content.map((typeObject, rowIndex) => {
            let typeData = {
              ...typeObject,
              index: index + 1,
              disable: false,
            };
            return typeData;
          });
          dispatch(closingListSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// export const setProductList =
// ({ row }) =>
// async (dispatch) => {
//   const productList = row.map((productObj) => {
//     return {
//       ...productObj,
//       rate:
//         productObj.mrp !== null
//           ? (
//               +productObj.mrp -
//               (+productObj.mrp * +productObj.marginPercentage) / 100
//             ).toFixed(2)
//           : "",
//     };
//   });
//   dispatch(productSuccess({ row: productList }));
// };

export const setClosingList =
  ({ row }) =>
  async (dispatch) => {
    console.log(row);
    dispatch(closingListSuccess({ row }));
  };
