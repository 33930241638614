import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import ContraEntryFormForReport from "./ContraEntryFormForReport";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


class ContraEntryPopupForReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }

    render() {
        const { value } = this.state;
        const { accountLedgerList, handleCloses, open } = this.props;
        return (
            <>

                <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                    <BootstrapDialog
                        onClose={handleCloses}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        fullWidth
                        maxWidth="xl"
                    >
                        <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleCloses}
                        >


                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <ContraEntryFormForReport handleCloses={handleCloses} editData={this.props.editData} />
                        </DialogContent>
                    </BootstrapDialog>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    accountLedgerList: state.accountLedger,
});
const mapDispatchToProps = {

};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ContraEntryPopupForReport);
