import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { SalePurchaseApprovalJSON } from "../../DynamicFormsJson/Transaction/salePurchaseApproval";
import { ButtonCompo } from "../../components/Comman/Button";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { deleteFailedMsg } from "../../config/messageconstant";
import { apiDelete } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";

import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TdsApplicablePopup = ({
  open,
  getAuditQestionList,
  handleCloses,
  onSave,
  dataToSave,
  showLoador,
  showNotification,
  cashRecieptList,
  dynamicMasterData,
}) => {
  const [fieldData, setFieldData] = React.useState({});

  const [costMappingList, setCostMappingList] = React.useState([]);

  useEffect(() => {
    //
    async () => {};
    console.log(dataToSave);
    console.log(dynamicMasterData);
    // if (dataToSave && dataToSave.length !== 0 && costMappingList.length == 0) {
    //   let rowList = dataToSave.map((rowData, index) => {
    //     let catList = dynamicMasterData["tdsAccountDetails"]
    //       ? dynamicMasterData["tdsAccountDetails"].filter(
    //           (row) => row.id == rowData.dabitCode
    //         )
    //       : [];
    //     console.log(catList);
    //     return {
    //       index: index + 1,
    //       // costCenterName: catList[0].ccName,
    //       costCenter: {
    //         id: rowData.dabitCode,
    //       },
    //       amount: +rowData.amount,
    //       type: rowData.type,
    //     };
    //   });
    //   setCostMappingList(rowList);
    // }
  }, []);
  const handleClose = () => {
    handleCloses();
  };

  const submitHandler = (dataToSet) => {
    console.log(dataToSave);
    console.log(dynamicMasterData);
    let catList = dynamicMasterData["tdsAccountDetails"]
      ? dynamicMasterData["tdsAccountDetails"].filter(
          (row) => row.id == dataToSet.tdsAccountId
        )
      : [];
    const costMappingdata = {
      index: costMappingList.length + 1,
      costCenter: {
        id: catList[0].id,
      },
      costCenterName: catList[0].ccName,
      amount: +dataToSet.amount,
      type: 0,
    };
    console.log(costMappingList);
    let costMapping = [...costMappingList, costMappingdata];
    console.log(costMapping);
    setCostMappingList(costMapping.sort((a, b) => a.orderNo - b.orderNo));
  };

  const saveHandler = () => {
    onSave(costMappingList, dataToSave && dataToSave.index);
    setFieldData({});
  };
  const rowEdit = (rowData) => {
    console.log(rowData);
    const dataToSend = {
      ...rowData,
      amt: rowData.amt,
      costCenterMaster: rowData.ccId,
    };

    setFieldData(dataToSend);
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          TDS Applicable
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DynamicFormWithoutSave
            paddingTop={false}
            showTitle={false}
             showBackToList={false}
            // DataToSet={dataToSet}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={false}
            callApi={false}
            screenTitle={SalePurchaseApprovalJSON.screenTitle}
            fieldMeta={SalePurchaseApprovalJSON.fieldMetaForOption}
            showCancel={SalePurchaseApprovalJSON.showCancel}
            apiBaseURL2={SalePurchaseApprovalJSON.apiBaseURL2}
            showSaveNextBtn={SalePurchaseApprovalJSON.showSaveNextBtn}
            saveBtnText={"Submit"}
            resetAfterSave={true}
            setFieldData={fieldData}
            onSave={submitHandler}
          />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  feedBack: state.feedBack,
  cashRecieptList: state.cashReciept,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  // getCostCenter
};

export default connect(mapStateToProps, mapDispatchToProps)(TdsApplicablePopup);
