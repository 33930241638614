import React from "react";
import { Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  savemsg,
  saveFailedMsg,
  noInternetMsg,
  saveWarningMsg,
  dateExistMsg,
} from "../../config/messageconstant";
import { getProfitCenter } from "../../Slice/profitCenter.slice";

import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { AssetJSON } from "../../DynamicFormsJson/Transaction/Asset";
import { getAccountLedgerListByType } from "../../Slice/subVoucherDetail.slice";
class AssetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dynamicMasterData: {
        profitCenterDetails: this.props.profitCenter?.profitCenter,
        ledgeCodeDetail: this.props.accList?.accountLedgerByType,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.accList !== nextProps.accList) {
      if (
        this.props.accList?.accountLedgerByType !==
        nextProps.accList?.accountLedgerByType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            ledgeCodeDetail: nextProps.accList?.accountLedgerByType,
          },
        });
      }
    }
    if (
      this.props.profitCenter.profitCenter !==
      nextProps.profitCenter.profitCenter
    ) {
      if (nextProps.profitCenter.profitCenter.length == 1) {
        this.setState({
          fieldData: {
            ...this.state.fieldData,
            profitCenter: { id: nextProps.profitCenter.profitCenter[0].id }
          }
        })
      }
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          profitCenterDetails: nextProps.profitCenter.profitCenter,
        },
      });
    }
  }

  async componentDidMount() {
    const {
      getAccountLedgerListByType,
      getProfitCenter,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getProfitCenter().then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        }
      );

      showLoador({ loador: true });
      await getAccountLedgerListByType({ accType: "0,1,2" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  onSubmit = (data) => {

    let location = localStorage.getItem("location");
    let error = false;
    if (data != null) {

      let dataToSave = {
        ...data,
        profitCenter: data.profitCenter,
        ledgerCode: data.ledgerCode,
        depreciationPercentage: data.depreciationPercentage,
        openingValue: data.openingValue,
        purchaseInFirstHalf: data.purchaseInFirstHalf,
        purchaseInSecondHalf: data.purchaseInSecondHalf,
        sellValue: data.sellValue,
        total: data.total,
        closingValue: data.closingValue,
        remark: data.remark,
        location: {
          id: location,
        },
        postInJv: 0,
      }
      if (!error) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }

      console.log(dataToSave);
    }
  };

  getDataForCalculate = (value, fieldData, fieldName) => {
    let depreciationPercentage =
      fieldName == "depreciationPercentage"
        ? +value
        : fieldData.depreciationPercentage
          ? fieldData.depreciationPercentage
          : 0;
    console.log(depreciationPercentage)
    let openingValue =
      fieldName == "openingValue"
        ? +value
        : fieldData.openingValue
          ? fieldData.openingValue
          : 0;
    console.log(openingValue)
    let purchaseInFirstHalf =
      fieldName == "purchaseInFirstHalf"
        ? +value
        : fieldData.purchaseInFirstHalf
          ? fieldData.purchaseInFirstHalf
          : 0;
    console.log(purchaseInFirstHalf)
    let purchaseInSecondHalf =
      fieldName == "purchaseInSecondHalf"
        ? +value
        : fieldData.purchaseInSecondHalf
          ? fieldData.purchaseInSecondHalf
          : 0;
    console.log(purchaseInSecondHalf)
    let sellValue =
      fieldName == "sellValue"
        ? +value
        : fieldData.sellValue
          ? fieldData.sellValue
          : 0;
    console.log(sellValue)



    let dvalue = (((+openingValue) + (+purchaseInFirstHalf)) *
      ((+depreciationPercentage)) /
      100
    ) +
      +(((+purchaseInSecondHalf) * ((+depreciationPercentage) / 2)) / 100)


    console.log(dvalue)
    let total = (+openingValue) +
      (+purchaseInFirstHalf) +
      (+purchaseInSecondHalf) -
      (+sellValue);
    console.log(total)

    let cValue = (+total) - (+dvalue);
    console.log(cValue)




    return {
      total: total,
      dvalue: dvalue.toFixed(2),
      closingValue: cValue

    };
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.asset,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          this.props.callApi();
          showNotification({ msg: savemsg });

        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getDataById = (id) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: endpoint.asset + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          let rowData = data.data;
          let dvalue = (((+rowData.openingValue) + (+rowData.purchaseInFirstHalf)) *
            ((+rowData.depreciationPercentage)) /
            100
          ) +
            +(((+rowData.purchaseInSecondHalf) * ((+rowData.depreciationPercentage) / 2)) / 100)


          return {
            data: {
              ...rowData,
              dvalue: dvalue.toFixed(2)
            },
            success,
          };
        }
        return { response: {}, success: false };
      });

      return response;
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

  };
  render() {
    const { dynamicMasterData, fieldData } = this.state;
    const { rowReset } = this.props
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            resetAfterSave={true}
             showBackToList={false}
            fieldData={fieldData}
            dynamicMasterData={dynamicMasterData}
            showTitle={AssetJSON.showTitle}
            screenTitle={AssetJSON.screenTitle}
            fieldMeta={AssetJSON.fieldMeta}
            showCancel={AssetJSON.showCancel}
            apiBaseURL={AssetJSON.apiBaseURL}
            showSaveNextBtn={AssetJSON.showSaveNextBtn}
            getDataForCalculate={this.getDataForCalculate}
            saveBtnText={"Save"}
            onSave={this.onSubmit}

            callApi={true}

            getDataById={this.getDataById}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accList: state.subVoucherDetail,
  profitCenter: state.profitCenter
});
const mapDispatchToProps = {
  getAccountLedgerListByType,
  showLoador,
  showNotification,
  getProfitCenter,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssetForm);
