import endpoint from "../../config/endpoints";

export const ReportInfoJson = {
  formPath: "/report-generate",
  formPath1: "/report-generate-query",
  showSaveNextBtn: true,
  apiBaseURL: endpoint.report,
  screenTitle: "Master Generate",
  showAddButton: false,
  showPdfDownload: false,
  showExcelDownload: false,
  tableColumnsToFilter: [
    {
      columnDisplayName: "Report Name",
      columnKeyName: "reportName",
      isChecked: true,
    },

    {
      columnDisplayName: "Is PDF",
      columnKeyName: "isPdf",
      isChecked: true,
    },
    {
      columnDisplayName: "Is EXCEL",
      columnKeyName: "isExcel",
      isChecked: true,
    },
    {
      columnDisplayName: "IS Graph",
      columnKeyName: "isGraph",
      isChecked: true,
    },
    {
      columnDisplayName: "IS Subreport",
      columnKeyName: "isSubreport",
      isChecked: true,
    },
  ],
};
