import FormControl from '@mui/material/FormControl';
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultipleSelect({ er = '', disabled = false, label, name1, value1, names, onChange }) {
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState([]);

  const handleChange = (event, newValue) => {

    const {
      target: { value },
    } = event;
    const AllFind = newValue.some(option => option.id==0);
    if(AllFind){
     let filterAllData= names.filter(option => option.id!=0);
     const selectedIds = filterAllData.map(option => option.id); 
     setSelectedOptions(filterAllData)
     setSelectedId(selectedIds)
      onChange(selectedIds, name1)
  
    }else{
      const selectedIds = newValue.map(option => option.id);
      onChange(selectedIds, name1)
      setSelectedOptions(newValue)
    }
    
   

    
  };
  React.useEffect(()=>{
if(selectedId.length==0&&value1!=selectedId&&names.length!=0){
 let filterData=value1.map((id)=>{
 let valueData= names.find((rowInfo)=>rowInfo.id==id)
  return valueData?valueData:''
 })
 setSelectedOptions(filterData.filter((row)=>row!=""))
  setSelectedId(value1)
}
  },[value1,names])
  return (
    <>
       {/* <FormControl color={"primary"} fullWidth={true}>
      <Select
        // labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        displayEmpty
        variant="standard"
        value={value1}
        name={name1}
        disabled={disabled}
        className="text-black"
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{label}</em>;
          }
          return selected.map((selectData) => {
            const filterData = names.filter((item) => item.id === selectData)
            if (filterData.length != 0) {
              return filterData[0].name
            }
            return ""
          }).join(', ');
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        size="small"
        {...(er !== '' && { error: true })}
      >
        {names.map((name) => (
          <MenuItem
            key={name.id}
            value={name.id}
          >
            {name.name}
          </MenuItem>
        ))}

      </Select>

      <FormHelperText error>
        {er !== '' ? er : ' '}
      </FormHelperText>

    </FormControl> */}
    <Autocomplete
      multiple
      options={names}
      size="small"
      getOptionLabel={(option) => option.name}
      variant="standard"
      value={selectedOptions}
      onChange={(event, newValue) => handleChange(event, newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
        
          placeholder="Search..."
          error={er !== '' ? true : false}
          margin="0px"
          helperText={er !== '' ? er : ' '}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip label={option.name}size="small" {...getTagProps({ index })} />
        ))
      
      }
    />
    </>
 
  );
}


