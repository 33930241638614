import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";

const initialState = {
  moduleList: [],
  isFetch: false,
  accessRole: [],
  isFetchAccessRole: false,
  accessRoleById: {},
  isFetchAccessRoleById: false,
  accessRoleByUserId: [],
  isFetchAccessRoleByUserId: false,
  moduleName:""
};
let accessRole = endpoints.userAccess;
let module = endpoints.module;
const moduleSubModuleSlice = createSlice({
  name: "moduleSubModule",
  initialState,
  reducers: {
    moduleSubModuleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        moduleList: row,
        isFetch: true,
      };
    },
    accessRoleListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        accessRole: row,
        isFetchAccessRole: true,
      };
    },
    accessRoleByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        accessRoleById: data,
        isFetchAccessRoleById: true,
      };
    },
    accessRoleByUserIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        accessRoleByUserId: data,
        isFetchAccessRoleByUserId: true,
      };
    },
    resetUserAccessSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        accessRoleById: {},
        isFetchAccessRoleById: false,
      };
    },
    setModuleName: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        moduleName: data
      };
    },
  },
});

export const {
  setModuleName,
  moduleSubModuleSuccess,
  accessRoleListSuccess,
  accessRoleByIdSuccess,
  accessRoleByUserIdSuccess,
  resetUserAccessSuccess,
} = moduleSubModuleSlice.actions;

export default moduleSubModuleSlice.reducer;

export const getModuleAndSubModule = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: module,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const row = content.map((module) => {
          module = {
            ...module,
            checked: 0,
          };
          return module;
        });
        dispatch(moduleSubModuleSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const setModuleList =
  ({ row }) =>
    async (dispatch) => {
      dispatch(moduleSubModuleSuccess({ row }));
    };
export const getAccessRoleList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: accessRole + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const row = content.map((accessRoleData, index) => {
          const module = {
            index: index + 1,
            id: accessRoleData.id,
            name: accessRoleData.roleName,
            // +
            // " (" +
            // accessRoleData.roleShortName +
            // " )",
            // type:
            //   accessRoleData.stockStakeHolderType !== null &&
            //   accessRoleData.stockStakeHolderType.name
            //     ? accessRoleData.stockStakeHolderType.name
            //     : "",

            isActive: accessRoleData.isActive,
          };
          return module;
        });
        dispatch(accessRoleListSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return { response: [], success: false };
  }
};

export const getAccessRoleByUserId = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: accessRole + "/by-user-id",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data.modules;
        if (content !== null) {
          const row = content.map((module) => {
            const iconDiv = module.iconDiv.split("@");
            module = {
              ...module,
              checked: false,
              baseClassName: iconDiv[1],
              className: iconDiv[0],
              color: iconDiv[2],
              moduleSubs: module.moduleSubs.map((subModule) => {
                subModule = {
                  ...subModule,
                  checked: false,
                };
                return subModule;
              }),
            };
            return module;
          });
          localStorage.setItem("moduleSubModule", JSON.stringify(row));
          dispatch(accessRoleByUserIdSuccess({ data: row }));
        }
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getAccessRoleById =
  ({ accessRoleId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: accessRole + "/" + accessRoleId,
        }).then(({ data, success }) => {
          if (success) {
            const content = data.data;
            dispatch(accessRoleByIdSuccess({ data: content }));
          }
          return success;
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const resetUserAccessById = () => async (dispatch) => {
  try {
    dispatch(resetUserAccessSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const setModuleNameData = ({ name }) => async (dispatch) => {
  try {
    dispatch(setModuleName({ data: name }));
  } catch (e) {
    return console.error(e.message);
  }
};
