import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#295FA6",
      white: "#fff",
      success: " #00c853",
      lighBlue: "#CFE9ED",
      smokeGrey: "#f8f8f8",
      black: "#625f6e",
    },
    secondary: {
      main: "#0f3f6f",
    },
    ternary: {
      main: "#fff",
    },
    primaryLight: {
      main: "#58D68D",
      contrastText: "#616161",
    },
    
  },
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      fontWeight: 700,
      // textTransform: 'none'
    },
  },
  components: {
    // Name of the component
    MuiDataGrid: {
      styleOverrides: {
        // Name of the slot
        main: {
          // Some CSS
        },
        columnHeaderTitle: {
          color: "#fff",
          fontSize: "1.2em",
          fontFamily: "Poppins",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
  },
});

export default theme;
