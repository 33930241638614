import endpoint from "../../config/endpoints";

export const CashPaymentJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.recieptPaymentHeader,
  screenTitle: "Cash Payment",
  formPath: "/cash-payment-form",
  formPathView: "/cash-payment-view",
  showBackToList: false,
  showTitle: false,
  searchList: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucher",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md:6,
      lg:6,
      sm:6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
    // {
    //   label: "Voucher No.",
    //   controlType: "textfield",
    //   placeHolder: "Enter Voucher No.",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "documentNo",
    //   inputType: "number",
    //   isMandatory: true,
    //   disable:true
    // },
    {
      label: "Sub-voucher",
      controlType: "textfield",
      placeHolder: "Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "subVoucherName",
      isRootLevelKey: false,
      isMandatory: true,
      disable:true
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      freezCurrentDate:true
    },
   
    {
      label: "Cash A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
    },
   
   
    {
      label: "Balance",
      controlType: "textfield",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Advance Payment",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isAdvance",
      tableDataKey: "isAdvance",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value:" 1",
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaAdd: [
    {
      label: "Sub-voucher",
      controlType: "autocomplete",
      placeHolder: "Select Sub-voucher ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subVoucherDetail",
      dataKey: "subVoucherMaster",
      isRootLevelKey: false,
      isMandatory: true,
    },
    
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      freezCurrentDate:true
    },
   
    {
      label: "Cash A/C",
      controlType: "autocomplete",
      placeHolder: "Select Cash Receipt ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cashBankDetails",
      dataKey: "cashBankId",
      getListId: "cashBankId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Profit Center",
      controlType: "autocomplete",
      placeHolder: "Select Profit Center",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "profitCenterDetails",
      dataKey: "profitCenter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Advance Payment",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isAdvance",
      // tableDataKey: "isAdvance",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "0",
        },
        {
          label: "No",
          value:"1",
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Debit Account",
      controlType: "autocomplete",
      placeHolder: "Select Debit Account ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "creditAccountDetails",
      dataKey: "mainAccountId",
      getListId: "mainAccountId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Balance",
      controlType: "label",
      placeHolder: "Balance",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "balance",
      disableTrue:true
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amt",
      inputType: "number",
      isMandatory: true,
    },
    
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeDetails",
      dataKey: "type",
      isRootLevelKey: true,
      isMandatory: true,
      defaultValue: "D",
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
  fieldMetaForOption: [
    {
      label: "Cost Center",
      controlType: "autocomplete",
      placeHolder: "Select Cost Center ",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "costCenterDetail",
      dataKey: "costCenterMaster",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMetaForOption1: [
    {
      label: "Bill",
      controlType: "autocomplete",
      placeHolder: "Select Bill ",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "billDetail",
      dataKey: "billId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      inputType: "number",
      isMandatory: true,
    },
  ],
  fieldMeta1: [
    {
      label: "Paid To",
      controlType: "textfield",
      placeHolder: "Enter Paid To",
      md: 12,
    lg: 12,
    sm: 12,
    xs: 12,
    labelSM: 2.5,
    labelMD: 2.5,
    labelLG: 2.5,
    valueSM: 9.5,
    valueMD: 9.5,
    valueLG: 9.5,
      dataKey: "paidTo",
      isMandatory: true,
      disableTrue: false,
    },
    // {
    //   label: "Voucher Amount",
    //   controlType: "textfield",
    //   placeHolder: "Enter Voucher Amount",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "voucherAmount",
    //   isMandatory: true,
    //   disableTrue: true,
    // },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 12,
    lg: 12,
    sm: 12,
    xs: 12,
    labelSM: 2.5,
    labelMD: 2.5,
    labelLG: 2.5,
    valueSM: 9.5,
    valueMD: 9.5,
    valueLG: 9.5,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};

