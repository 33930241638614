import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  cashReciept: [],
  cashRecieptPendingList: [],
  transactionPendingList: [],
  costCenter: [],
  cashRecieptById: {},
};
let URL = endpoints.recieptPaymentHeader;
let URL1 = endpoints.getRecieptPaymentHeaderList;

let URL2 = endpoints.costCenter;
const cashReceiptSlice = createSlice({
  name: "cashReciept",
  initialState,
  reducers: {
    cashRecieptSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        cashReciept: row,
      };
    },
    cashRecieptPendingListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        cashRecieptPendingList: row,
      };
    },
    transactionPendingListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        transactionPendingList: row,
      };
    },
    costCenterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        costCenter: row,
      };
    },
    cashRecieptByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        cashRecieptById: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  cashRecieptSuccess,
  cashRecieptByIdSuccess,
  costCenterSuccess,
  cashRecieptPendingListSuccess,
  transactionPendingListSuccess,
  resetState,
} = cashReceiptSlice.actions;

export default cashReceiptSlice.reducer;

export const getCashRecieptById =
  ({ id }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url: URL + "/" + id,
        }).then(({ data, success }) => {
          if (success) {
            let row = {
              ...data.data,
              recieptPaymentDetailList: data.data.recieptPaymentDetailList ?data.data.recieptPaymentDetailList.map(
                (rowData, index) => {
                  let recieptPayData = {
                    index: index + 1,
                    mainAccId: rowData.mainAccId != null ? rowData.mainAccId : "",
                    mainAccountId: rowData.mainAccId != null ? rowData.mainAccId : "",
                    accountLedgerName: rowData.accountLedgerName != null ? rowData.accountLedgerName : "",
                    amt: rowData.amt != null ? rowData.amt : "",
                    type: rowData.type != null ? rowData.type : "",
                    ...rowData,
                  };
                  return recieptPayData;
                }
              ):[],
            };
            console.log(row);
            dispatch(cashRecieptByIdSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getCashRecieptWithAccNameById =
  ({ id }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url: URL + "/get-reciept-payment-header-with-accName/" + id,
        }).then(({ data, success }) => {
          if (success) {
            let row = {
              ...data.data,
              dtoRecieptPaymentDetailList: data.data.dtoRecieptPaymentDetailList.map(
                (rowData, index) => {
                  let recieptPayData = {
                    index: index + 1,
                    mainAccId: rowData.mainAccId,
                    accountLedgerName: rowData.accountLedgerName,
                    amt: rowData.amt,
                    amount: rowData.amt.toFixed(2),
                    type: rowData.type,
                    ...rowData,
                  };
                  return recieptPayData;
                }
              ),
            };
            console.log(row);
            dispatch(cashRecieptByIdSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getCashReciept =
  ({ subVoucherId, locationId, fromDate, toDate }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url:
            URL1 +
            "?fromDate=" +
            fromDate +
            "&toDate=" +
            toDate +
            "&subVoucherId=" +
            subVoucherId +
            "&locationId=" +
            locationId,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const content = data.data;
            const row = content.map((rowData, index) => {
              let positiveAmt =
                rowData.voucherAmount != null
                  ? (Math.abs(+rowData.voucherAmount)).toFixed(2)
                  : "";
              let areaForBeatData = {
                index: index + 1,
                documentNo:
                  rowData.documentNo != null ? rowData.documentNo : " - ",
                date: rowData.date != null ? rowData.date : "",
                accountLedgerName:
                  rowData.accountLedgerName != null
                    ? rowData.accountLedgerName + " - " + rowData.mainAccountId
                    : "",
                locationName:
                  rowData.locationName != null
                    ? rowData.locationName + " - " + rowData.mainAccountId
                    : "",
                voucherAmount: positiveAmt,
                voucherName:
                  rowData.voucherName != null ? rowData.voucherName : "",
                receivedFrom: rowData.receivedFrom != null ? rowData.receivedFrom : "",
                paidTo: rowData.paidTo != null ? rowData.paidTo : "",
                statusName:
                  rowData.status != null
                    ? rowData.status == 1
                      ? "Approved"
                      : "Pending"
                    : "",
                color:
                  rowData.status != null
                    ? rowData.status == 1
                      ? "success"
                      : "error"
                    : "",
                ...rowData,
                voucherAmount: positiveAmt,
                accountLedgerName:
                  rowData.accountLedgerName != null
                    ? rowData.accountLedgerName + " - " + rowData.cashBankId
                    : "",
                locationName:
                  rowData.locationName != null
                    ? rowData.locationName + " - " + rowData.mainAccountId
                    : "",
              };
              return areaForBeatData;
            });
            dispatch(cashRecieptSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getCashRecieptPendingList =
  ({ type, locationId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          url: URL1 + "/get-reciept-payment-header-with-accName-by-voucher-type?voucherType=" + type + "&locationId=" + locationId,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const content = data.data;
            const row = content.map((rowData, index) => {
              let areaForBeatData = {
                index: index + 1,
                documentNo: rowData.documentNo != null ? rowData.documentNo : "",
                date: rowData.date != null ? rowData.date : "",
                accountLedgerName:
                  rowData.accLedgerName != null ? rowData.accLedgerName : "",
                locationName:
                  rowData.locationName != null ? rowData.locationName : "",
                voucherAmount:
                  rowData.voucherAmount != null
                    ? Math.abs(rowData.voucherAmount).toFixed(2)
                    : "",
                voucherName:
                  rowData.voucherName != null ? rowData.voucherName : "",
                ...rowData,
                voucherAmount:
                  rowData.voucherAmount != null
                    ? Math.abs(rowData.voucherAmount).toFixed(2)
                    : "",
                statusName:
                  rowData.status != null
                    ? rowData.status == 1
                      ? "Approved"
                      : "Pending"
                    : "",
              };
              return areaForBeatData;
            });
            dispatch(cashRecieptPendingListSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getTransactionPendingList =
  ({ accountLedgerCode, voucherType }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          //   url: URL + '?sort={"sortOrder": "ASC"}',
          //   purchase voucherType = 2 , 4
          // sale voucherType = 1, 3
          url:
            URL1 +
            "/pending-pr-list?accountLedgerCode=" +
            accountLedgerCode +
            "&voucherType=" +
            voucherType,
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            const content = data.data;
            let index = 0;
            const row = content.map((rowData, index) => {
              let areaForBeatData = {
                index: index + 1,
                id: rowData.reciept_payment_detail_id != null ? rowData.reciept_payment_detail_id : "",
                name:
                  rowData.document_no != null && rowData.remaining_amount != null
                    ? rowData.document_no + "( " + rowData.remaining_amount + " )"
                    : " - ",

                NAME: rowData.Name != null ? rowData.Name : "",
                account_ledger_name:
                  rowData.account_ledger_name != null
                    ? rowData.account_ledger_name
                    : "",
                amt: rowData.amt != null ? rowData.amt : "",
                cash_bank_id:
                  rowData.cash_bank_id != null ? rowData.cash_bank_id : "",
                date: rowData.date != null ? rowData.date : "",
                detail_id: rowData.detail_id != null ? rowData.detail_id : "",
                document_no:
                  rowData.document_no != null ? rowData.document_no : " - ",
                header_id: rowData.header_id != null ? rowData.header_id : "",
                reference_name:
                  rowData.reference_name != null ? rowData.reference_name : "",
                sub_voucher_name:
                  rowData.sub_voucher_name != null
                    ? rowData.sub_voucher_name
                    : "",
                type: rowData.type != null ? rowData.type : "",
                voucher_amt:
                  rowData.remaining_amount != null ? rowData.remaining_amount : "",
              };
              return areaForBeatData;
            });
            dispatch(transactionPendingListSuccess({ row }));
            return { response: data.data, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getCostCenter = () => async (dispatch) => {
  try {
    const response = apiGet({
      //   url: URL + '?sort={"sortOrder": "ASC"}',
      url: URL2,
    }).then(({ data, success }) => {
      console.log(data);
      if (success) {
        const content = data.data;
        const row = content.map((rowData, index) => {
          let areaForBeatData = {
            index: index + 1,
            ...rowData,
            id: rowData.id != null ? rowData.id : "",
            name: rowData.ccName != null ? rowData.ccName : "",
          };
          return areaForBeatData;
        });
        dispatch(costCenterSuccess({ row }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
