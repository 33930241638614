import {
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";

import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import { saveReport, setSaveReport, getReportInfo } from '../../Slice/matrixReport.slice'
import { redColor, titleColor } from "../../config/ColorObj";
import * as msgConstant from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { withRouter } from "../../components/withRouter";
import { LabelCompo } from "../../components/Comman/Label";
import { validText } from "../../components/Comman/Util/Validations";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import RTTable from '../../components/Comman/RT/RTTable'
import AddFilter from './AddFilter'
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { getType } from "../../Slice/type.slice";

class ReportForm extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            fieldData: { isPdf: 1, isExcel: 1, isSubreport: 0, apiName: "distribution/v1/get-dynamic-report/get-data-matrix" },
            formErrors: {},
            openDialog: false
        };
    }
    checkValidation = () => {
        const { fieldData } = this.state
        let formErrors = {};
        let formIsValid = true;

        if (fieldData["reportName"] === undefined || fieldData["reportName"] == "") {
            formIsValid = false;
            formErrors["reportName"] = msgConstant.ReportMsg;
        }
        if (fieldData["reportType"] === undefined || fieldData["reportType"] == "") {
            formIsValid = false;
            formErrors["reportType"] = msgConstant.ReportMsg;
        }
        if (fieldData["apiName"] === undefined || fieldData["apiName"] == "") {
            formIsValid = false;
            formErrors["apiName"] = msgConstant.apiNameMsg;
        }
        if (fieldData["sortOrder"] === undefined || fieldData["sortOrder"] == "") {
            formIsValid = false;
            formErrors["sortOrder"] = msgConstant.sortOrderMsg;
        }
        if (fieldData["isSubreport"] == 1) {
            if (fieldData["reportId"] === undefined || fieldData["reportId"] == "") {
                formIsValid = false;
                formErrors["reportId"] = msgConstant.ReportMsg;
            }
            if (fieldData["paramsKey"] === undefined || fieldData["paramsKey"] == "") {
                formIsValid = false;
                formErrors["paramsKey"] = msgConstant.paramsKeyMsg;
            }
            if (fieldData["paramsValue"] === undefined || fieldData["paramsValue"] == "") {
                formIsValid = false;
                formErrors["paramsValue"] = msgConstant.paramsValueMsg;
            }
        }

        this.setState({ formErrors: formErrors });
        return formIsValid;
    };
    async componentDidMount() {
        const {
            getReportInfo,
            getType,
            setSaveReport,
            showLoador,
            showNotification
        } = this.props;
        if (this.props.params.id) {
            if (navigator.onLine) {
                showLoador({ loador: true });
                await apiGet({
                    url: endpoint.matrixReport + "/" + this.props.params.id,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (success) {
                        if (!data.error) {
                            let rowObject = data.data;

                            setSaveReport({ rowObject });
                        }
                    }

                    return success;
                });
            } else {
                this.props.showNotification({
                    msg: msgConstant.noInternetMsg,
                    severity: "error",
                });
            }
        }
        // if (navigator.onLine) {
        //     showLoador({ loador: true });
        //     await getReportInfo().then(({ success }) => {
        //         showLoador({ loador: false });
        //         if (!success) {
        //             showNotification({ msg: serverMsg, severity: "error" });
        //         }
        //     });
        // }
        // else {
        //     this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        // }

        if (navigator.onLine) {
            showLoador({ loador: true });
            await getType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        }
        else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    onAutocompleteChange = (name, newValue) => {
        if (newValue != null) {
            this.setState({
                fieldData: {
                    ...this.state.fieldData,
                    [name]: newValue
                }
            })
        } else {
            this.setState({
                fieldData: {
                    ...this.state.fieldData,
                    [name]: ""
                }
            })
        }
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.report != this.props.report) {
            if (nextProps.report.reportData != this.props.report.reportData) {
                console.log(nextProps.report.reportData)
                let params = JSON.parse(nextProps.report.reportData.params) ? JSON.parse(nextProps.report.reportData.params) : {}
                let fieldData = {
                    ...nextProps.report.reportData,
                    paramsKey: params.paramsKey ? params.paramsKey : "",
                    paramsValue: params.paramsValue ? params.paramsValue : "",
                }
                this.setState({
                    fieldData: fieldData,
                    type: nextProps.report.reportData.reportType,
                    reportName: nextProps.report.reportData.reportName

                });
            }
        }
    }

    onTextFieldDataChange = (event) => {
        if (
            event.target.value === "" ||
            validText.test(event.target.value)
        ) {
            this.setState({ fieldData: { ...this.state.fieldData, [event.target.name]: event.target.value } });
        }
    };

    onTextFieldDataChangeForNumber = (event) => {
        this.setState({ fieldData: { ...this.state.fieldData, [event.target.name]: event.target.value } });
    };
    onRadioChange = (dataKey) => (event) => {

        this.setState({ fieldData: { ...this.state.fieldData, [dataKey]: event.target.value } });

    };

    onCancel = () => {
        this.props.navigate(-1);
    };

    onFormSave = () => {
        if (this.checkValidation()) {
            const { fieldData } = this.state;
            let params = {}
            if (fieldData.isSubreport == 1) {
                params = {
                    paramsValue: fieldData.paramsValue ? fieldData.paramsValue : "",
                    paramsKey: fieldData.paramsKey ? fieldData.paramsKey : ""
                }
            }

            let dataToSave = {
                "parameterRequired": "[]",
                "isGraph": "1",
                "isSubreport": "0",
                "reportId": null,
                queryParam: "[]",
                "params": "[]",
                "graphColumns": "[]",
                "query1": "",
                "query2": "",
                "query3": "",
                "tableColumns1": "[]",
                "tableColumns2": "[]",
                "tableColumns3": "[]",
                "queryParam1": "[]",
                "queryParam2": "[]",
                "queryParam3": "[]",
                parameterCheck: "{}",
                isDynamic: 0,
                ...fieldData,
                params: JSON.stringify(params)
            }

            this.onSave(dataToSave)
        }

    };
    onSave = (dataToSave) => {
        const {
            saveReport,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {

            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });
                    if (dataToSave.id) {
                        this.props.changeTab(1)

                    } else {
                        window.location.replace("/matrix-report-generate-query/" + response.id);
                    }
                } else {
                    this.props.showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            this.props.showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };
    toggleFilter = () => {
        this.setState({
            openDialog: !this.state.openDialog
        })
    }


    render() {
        const {
            type,
            report,
        } = this.props;

        const { fieldData, formErrors } = this.state;

        return (
            <>

                <Grid container sx={{ marginTop: 2 }} spacing={1}>
                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Type :"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >
                            <AutoComplete
                                name={"reportType"}
                                label={"reportType"}
                                placeHolder={"Report Type"}
                                keyColName={"id"}
                                value={fieldData["reportType"] ? fieldData["reportType"] : ""}
                                options={type.type}
                                onChange={this.onAutocompleteChange}
                                isError={formErrors["reportType"] ? true : false}
                                errorText={formErrors["reportType"] ? formErrors["reportType"] : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Report Name :"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >
                            <TextFieldCompo
                                placeHolder={"Report Name"}
                                size="small"
                                color="primary"
                                name={"reportName"}
                                id={"reportName"}
                                value={fieldData["reportName"] ? fieldData["reportName"] : ""} onChange={this.onTextFieldDataChange}
                                fullWidth
                                errorText={formErrors["reportName"] ? formErrors["reportName"] : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"API URL :"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >
                            <TextFieldCompo
                                placeHolder={"API URL"}
                                size="small"
                                color="primary"
                                name={"apiName"}
                                id={"apiName"}
                                value={fieldData["apiName"] ? fieldData["apiName"] : ""}
                                onChange={this.onTextFieldDataChange}
                                fullWidth
                                errorText={formErrors["apiName"] ? formErrors["apiName"] : ""}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Sort Order :"}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >
                            <TextFieldCompo
                                placeHolder={"Sort Order"}
                                size="small"
                                color="primary"
                                name={"sortOrder"}
                                id={"sortOrder"}
                                value={fieldData["sortOrder"] ? fieldData["sortOrder"] : ""}
                                onChange={this.onTextFieldDataChangeForNumber}
                                fullWidth
                                errorText={formErrors["sortOrder"] ? formErrors["sortOrder"] : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Is PDF:"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >

                            <RadioGroup
                                row
                                aria-label={"isPdf"}
                                name={"isPdf"}
                                id={"isPdf"}
                                value={fieldData["isPdf"] ? fieldData["isPdf"] : "0"}
                                onChange={this.onRadioChange("isPdf")}
                                size="small"
                            >
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={1}
                                    control={<Radio />}
                                    label={"Yes"}
                                />
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={0}
                                    control={<Radio />}
                                    label={"No"}
                                />
                            </RadioGroup>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Is Excel:"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >

                            <RadioGroup
                                row
                                aria-label={"isExcel"}
                                name={"isExcel"}
                                id={"isExcel"}
                                value={fieldData["isExcel"] ? fieldData["isExcel"] : "0"}
                                onChange={this.onRadioChange("isExcel")}
                                size="small"
                            >
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={1}
                                    control={<Radio />}
                                    label={"Yes"}
                                />
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={0}
                                    control={<Radio />}
                                    label={"No"}
                                />
                            </RadioGroup>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        sm={6}
                        md={6}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            lg={4}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Is Sub Report:"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={8}
                            lg={8}
                        >

                            <RadioGroup
                                row
                                aria-label={"isSubreport"}
                                name={"isSubreport"}
                                id={"isSubreport"}
                                value={fieldData["isSubreport"] ? fieldData["isSubreport"] : "0"}
                                onChange={this.onRadioChange("isSubreport")}
                                size="small"
                            >
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={1}
                                    control={<Radio />}
                                    label={"Yes"}
                                />
                                <FormControlLabel
                                 sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em"
                                    }
                                  }}
                                    value={0}
                                    control={<Radio />}
                                    label={"No"}
                                />
                            </RadioGroup>

                        </Grid>
                    </Grid>
                    {fieldData["isSubreport"] == 1 && <>
                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                            >
                                &nbsp;&nbsp;
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label={"*"}
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"Select Report :"}
                                />

                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                            >

                                <AutoComplete
                                    name={"reportId"}
                                    label={"reportId"}
                                    placeHolder={"Report "}
                                    keyColName={"id"}
                                    value={fieldData["reportId"] ? fieldData["reportId"] : ""}
                                    options={report.report}
                                    onChange={this.onAutocompleteChange}
                                    isError={formErrors["reportId"] ? true : false}
                                    errorText={formErrors["reportId"] ? formErrors["reportId"] : ""}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                            >
                                &nbsp;&nbsp;
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label={"*"}
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"Parameter Key:"}
                                />

                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                            >

                                <TextFieldCompo
                                    placeHolder={"Report Parameter"}
                                    size="small"
                                    color="primary"
                                    name={"paramsKey"}
                                    id={"paramsKey"}
                                    value={fieldData["paramsKey"] ? fieldData["paramsKey"] : ""}
                                    onChange={this.onTextFieldDataChange}
                                    fullWidth
                                    errorText={formErrors["paramsKey"] ? formErrors["paramsKey"] : ""}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                            >
                                &nbsp;&nbsp;
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label={"*"}
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"Parameter Value:"}
                                />

                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                            >

                                <TextFieldCompo
                                    placeHolder={"Report Parameter"}
                                    size="small"
                                    color="primary"
                                    name={"paramsValue"}
                                    id={"paramsValue"}
                                    value={fieldData["paramsValue"] ? fieldData["paramsValue"] : ""}
                                    onChange={this.onTextFieldDataChange}
                                    fullWidth
                                    errorText={formErrors["paramsValue"] ? formErrors["paramsValue"] : ""}
                                />
                            </Grid>
                        </Grid>
                    </>}
                </Grid>
                <br />

                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                >

                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save and Next"
                        onClick={this.onFormSave}
                    />

                </Grid>

            </>
        );
    }
}
const mapStateToProps = (state) => ({ report: state.matrixReport, type: state.type, });
const mapDispatchToProps = { showLoador, getType, getReportInfo, showNotification, setSaveReport, saveReport };
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ReportForm);
