import React from "react";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { userJSON } from "../../../DynamicFormsJson/MastersJSON/user";
import { getLocation } from "../../../Slice/accountLedger.slice";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { noInternetMsg, serverMsg ,saveFailedMsg} from "../../../config/messageconstant";
import { withRouter } from "../../../components/withRouter";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { getAccessRoleList } from "../../../Slice/accessRole.slice";
import * as msgConstant from "../../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import {saveUser} from "../../../Slice/user.slice";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        locationDetails: this.props.locationList?.location,
        accLocationDetails: this.props.locationList?.location,
        roleDetails: this.props.accessRole?.accessRole,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.locationList !== nextProps.locationList) {
      if (
        this.props.locationList?.location !== nextProps.locationList?.location
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            locationDetails: nextProps.locationList?.location,
            accLocationDetails: nextProps.locationList?.location,
          },
        });
      }
    }
   
    if (this.props.accessRole !== nextProps.accessRole) {
      if (
        this.props.accessRole?.accessRole !== nextProps.accessRole?.accessRole
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            roleDetails: nextProps.accessRole?.accessRole,
          },
        });
      }
    }
  }
  // async componentDidMount() {
  //   const { getLocation, getAccessRoleList, showLoador, showNotification } =
  //     this.props;
  //   if (navigator.onLine) {
  //     showLoador({ loador: true });
  //     await getLocation().then(({ success }) => {
  //       showLoador({ loador: false });
  //       if (!success) {
  //         showNotification({ msg: serverMsg, severity: "error" });
  //       }
  //     });
  //     showLoador({ loador: true });
  //     await getAccessRoleList().then(({ success }) => {
  //       showLoador({ loador: false });
  //       if (!success) {
  //         showNotification({ msg: serverMsg, severity: "error" });
  //       }
  //     });
  //   } else {
  //     showNotification({ msg: noInternetMsg, severity: "error" });
  //   }
  // }


  onSubmitData = (dataToSave) => {
  
   
  
    // const dataToSave = {
    //     accountLedgerCode: fieldData.accountLedgerCode,
    //     accountLedgerName: fieldData.accountLedgerName,
    //     locationIds: fieldData.location,
    //     printName: fieldData.printName,
    //     address: fieldData.address,
    //     city: fieldData.city,
    //     contactNo: fieldData.contactNo,
    //     mail: fieldData.mail,
    //     pinCode: fieldData.pinCode,
    //     distance: fieldData.distance,
    // };
    console.log("dataToSave");
    console.log(dataToSave);
    swal({
      title: "Are you sure?",
      text: msgConstant.saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(dataToSave);
        this.onSave(dataToSave);
      }
    });
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification,} = this.props;
    if (navigator.onLine) {
      console.log(dataToSave)
      showLoador({ loador: true });
      apiPost({
        url: endpoint.user1,
        postBody: dataToSave,
    }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (success) {
        console.log(response)
        this.props.callApi();
      
          showNotification({
            msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
          });
         
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        {/* <DynamicForm
        showBackToList={false}
          formPath={userJSON.formPath}
          screenTitle={userJSON.screenTitle}
          fieldMeta={userJSON.fieldMeta}
          tableColumnsToFilter={userJSON.tableColumnsToFilter}
          apiBaseURL={userJSON.apiBaseURL}
          dynamicMasterData={dynamicMasterData} 
        /> */}
         <DynamicFormWithoutSave
           showBackToList={false}
          formPath={userJSON.formPath}
          screenTitle={userJSON.screenTitle}
          fieldMeta={userJSON.fieldMeta}
          tableColumnsToFilter={userJSON.tableColumnsToFilter}
          apiBaseURL={userJSON.apiBaseURL}
          dynamicMasterData={dynamicMasterData}
         
          baseIdColumn={userJSON.baseIdColumn}
          padding={true}
          paddingTopGrid={true}
          paddingTop={true}
          showSaveBtnMain={false}
          showSaveBtn={true}
          resetAfterSave={true}
          saveBtnText={"Save"}
          onSave={this.onSubmitData}
          callApi={true}
          getByIdApi={true}
          getDataById={this.getDataById}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  locationList: state.accountLedger,
  accessRole: state.accessRole,
  user:state.type
});
const mapDispatchToProps = {
  getLocation,
  getAccessRoleList,
  saveUser,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserForm);
