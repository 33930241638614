import React from "react";
import { withStyles } from "@mui/styles";
import { border, fontWeight, styles } from "../../components/reportStyle";

const B2CLTable = ({
  rowList = [],
  tableHead = [],
  classes,
}) => {
  let totalAmount=0

  let taxPercentage = 0;
  let taxableAmount = 0;
  let cessAmount = 0;
  return (
    <>
      <div style={{ overflow: "auto" }}>
     

        <table className={classes.MuiTable}>

            <>
              <tr className={classes.MuiTableHeadRoot}>
                {tableHead.map((head) => {
                  return (
                    <td
                      style={{ textAlign: "center", width: head.width }}
                      className={classes.MuiTableHeadCell}
                      colSpan={head.colSpan}
                    >
                      {head.title}
                    </td>
                  );
                })}
              </tr>

             

              {
rowList.map((data, index) => {
  totalAmount = totalAmount + data.totalAmount
                  taxPercentage = taxPercentage + data.taxPercentage
                  taxableAmount = taxableAmount + data.taxableAmount
                  cessAmount = cessAmount + data.cessAmount
                  return (
                    <>

                      <tr>

                        <td className={classes.MuiTableBodyCell}>
                        {data.documentNo}
                        </td>
                        <td className={classes.MuiTableBodyCell} align={'center'}> 
                          {data.documentDate}
                        </td>


                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                           {Math.abs(data.totalAmount).toFixed(2)}
                        </td>



                        <td className={classes.MuiTableBodyCell} >
                          {data.placeofSupply}
                        </td>

                       
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                           {Math.abs(data.taxPercentage).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                        {Math.abs(data.taxableAmount).toFixed(2)}
                        </td>
                        <td className={classes.MuiTableBodyCell} style={{ textAlign: "right" }}>
                        {Math.abs(data.cessAmount).toFixed(2)}
                        </td>
                       
                      </tr>


                    </>
                  );
                })}
             
              <tr style={{ border: border }}>

              <td className={classes.MuiTableBodyCell} style={{ fontWeight: fontWeight, }}>
                                            Total
                                        </td>

                <td className={classes.MuiTableBodyCell}></td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right", }}
                >
                  {Math.abs(totalAmount).toFixed(2)}
                </td>
                <td className={classes.MuiTableBodyCell}></td>
              
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right", }}
                >
                  {Math.abs(taxPercentage).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" ,}}
                >
                  {Math.abs(taxableAmount).toFixed(2)}
                </td>
                <td
                  className={classes.MuiTableBodyCell}
                  style={{ fontWeight: fontWeight, textAlign: "right" ,}}
                >
                  {Math.abs(cessAmount).toFixed(2)}
                </td>
               
               
               
          

              </tr>
           </>
        </table>
      </div>
    </>
  );
};

export default withStyles(styles)(B2CLTable);
