import { TabContext, TabPanel } from '@mui/lab';
import {
  Grid,
  Paper,
  Tab, Tabs
} from "@mui/material";

import React from "react";
import { connect } from "react-redux";
import { creditNoteERPJSON } from "../../DynamicFormsJson/Transaction/creditNoteERP";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { ValidationCheck, mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  serverMsg
} from "../../config/messageconstant";
  
  import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CreditNoteTable from "./CreditNoteTable";
import CreditNoteWithGRN from "./CreditNoteWithGRN";

import PopUp from "./PopUp";
  // import PaymentModeDialog from "./PaymentModeDialog";
  class CreditNoteERP extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        products:[],
        openPopup:false,
        value:0,
        rows: [],
        productDataList:[],
        dynamicMasterData: {
          tax: [],
          VendorDetails: [],
        },
        formErrors: {},
        fieldData: {type:1}
      };
    }
  
    async componentDidMount() {
      this.getCustomerData()
    }
    getCustomerData=async()=>{
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
         showLoador({ loador: true });
        await apiGet({
          url:
            endpoint.vendor +
            "/customer-for-credit-note",
        }).then(({ data, success }) => {
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              dynamicMasterData: {
                VendorDetails: data.data,
              },
            });
          }
          return success;
        });
        showLoador({ loador: false });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    getById=async (dataToSet) => {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        await showLoador({ loador: true });
        await apiGet({
          url:
            endpoint.grnHead +
            "/pending-for-credit-note?customerId=" +
            dataToSet.vendor,
        }).then(({ data, success }) => {
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              dynamicMasterData: {
                ...this.state.dynamicMasterData,
                GRNDetails:data.data.map((row)=>{
                  return {...row,name:row.grnNo}
                })
              },
            });
          }
          return success;
        });
        showLoador({ loador: false });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    };
    onSearch = async (datatoSearch) => {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        await showLoador({ loador: true });
        await apiGet({
          url:datatoSearch.type=='1'? endpoint.grnHead +
          "/details-for-credit-note-by-id?grnHeaderId=" +
          datatoSearch.grn:
            endpoint.rateConfig +
            "/get-categories-and-products-for-credit-note?customerId=" +
            datatoSearch.vendor,
        }).then(({ data, success }) => {
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if(datatoSearch.type=='1'){
              this.setState({
                ...this.state,
                rows: data.data,
                productDataList: data.data
              });
            }else{
              let category= data.data.category
              this.setState({
                ...this.state,
                rows: data.data.category,
                productDataList:category.length!=0?category[0].productListForOrder:[]
              });
            }
          
          }
          return success;
        });
        showLoador({ loador: false });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    };
  
    checkValidationOnSubmit = () => {
      const { fieldData } = this.state;
      const metaData =fieldData.type=='1'?creditNoteERPJSON.fieldMetaGRN: creditNoteERPJSON.fieldMeta;
      const mandatoryCheckErrors = mandatoryCheck({
        fieldData,
        fieldMeta: metaData,
      });
      this.setState({
        formErrors: mandatoryCheckErrors.formErrors,
      });
      if (mandatoryCheckErrors.formValid) {
        const ValidationCheckErrors = ValidationCheck({
          fieldData,
          fieldMeta: metaData,
        });
        this.setState({
          formErrors: ValidationCheckErrors.formErrors,
        });
        return ValidationCheckErrors.formValid;
      } else {
        return false;
      }
    };
    onFormSave = () => {
      if (this.checkValidationOnSubmit()) {
        this.onSearch(this.state.fieldData);
      }
    };
  
    saveData = () => {
      const { rows, value,fieldData,productDataList } = this.state;
     let products=[]
     let rowproducts=[]
      let error=false
      if(fieldData.type==1){
       products=  productDataList.map((rowObj,index) => {
          if(rowObj.grnQty==''||rowObj.grnQty==0){
            error=true
            rowObj={
              ...rowObj,
              grnQtyErr:rowObj.grnQty==0?"Qty should not be zero":"Qty should be enter"
            }
          }
          if(rowObj.returnRate==''||rowObj.returnRate==0){   
            error=true
            rowObj={
              ...rowObj,
              returnRateErr:rowObj.returnRate==0?"Rate should not be zero":"Rate should be enter"
            }
          }
          return {index:index+1,...rowObj,qty:rowObj.grnQty,amount:(rowObj.returnRate*rowObj.grnQty).toFixed(2)}
        });
      }
     else{
      rowproducts= rows.map((rowObj) => {
        let productInfo=rowObj.productListForOrder.map((prod) => {
    let errorMsg=false
     if(prod.qty!=undefined&&prod.qty!=''&&prod.qty!=0){
      
      if(prod.productRate==''||prod.productRate==0){  
        errorMsg=true 
        error=true
        prod={
          ...prod,
          productRateErr:prod.productRate==0?"Rate should not be zero":"Rate should be enter"
        }
      }else{
        products.push({index:products.length+1,...prod})
      }
    }
   
    return prod
          
        });
        return {...rowObj,productListForOrder:productInfo}
      });
     }
      
    
      if(!error&&products.length!=0){
        this.setState({
          products:products,
          openPopup:true,
          productDataList:fieldData.type==1?products:rowproducts[value].productListForOrder,
          rows:fieldData.type==1?products:rowproducts, 
         })
      }else{
        if(products.length==0){
          this.props.showNotification({ msg: "Please add atleast one product quantity", severity: "error" });
        }
        this.setState({
          
          productDataList:fieldData.type==1?products:rowproducts[value].productListForOrder,
          rows:fieldData.type==1?products:rowproducts, 
         })
      }
     
    };
  
   
  
    onDataChange = (fieldName, newValue) => {
      if(fieldName=='type' ){
        if(this.state.rows.length!=0){
          swal({
            title: "Are you sure?",
            text: "if you change type then table data cleared",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              const dataToReset = {};
              dataToReset[fieldName] = newValue
              this.setState({
                fieldData: dataToReset,
                rows:[],
                productDataList:[]
              });
            }
          });
         
        }else{
          const dataToReset = {};
        dataToReset[fieldName] = newValue
        this.setState({
          fieldData: dataToReset,
          rows:[],
          productDataList:[]
        });
        }
        
      }else{
        const dataToReset = { ...this.state.fieldData };
        let vendor=  this.state.dynamicMasterData.VendorDetails.find((taxRow)=>taxRow.id==newValue)
    
        dataToReset[fieldName] = newValue;
        if(dataToReset.type=='1'&&fieldName=='vendor'){
          this.getById(dataToReset)
        }
        this.setState({
          fieldData: {...dataToReset,
          ...vendor},
        });
      }
     
    };
    setRow = (rowList) => {
      this.setState({
        rows: rowList,
      });
    };
     handleChange = (event, newValue) => {
     
      this.setState({
        value:newValue,
        productDataList:this.state.rows[newValue]?.productListForOrder || []
      })
      
    }
    setStockConsumptionList=(rowlist)=>{
      console.log(rowlist)
      this.setState({
        ...this.state,
     ...rowlist
      })
    }
    setProductList=(rowlist)=>{
     
      this.setState({
        ...this.state,
        productDataList:rowlist
      })
    }
    togglePopup=(flag)=>{
      if(flag){
        this.setState({
          ...this.state,
          openPopup:!this.state.openPopup,
          fieldData: {type:1},
          rows: [],
          productDataList:[],
          products:[],
          openPopup:false,
          value:0,
        })
        this.getCustomerData()
      }else{
        this.setState({
          ...this.state,
          openPopup:!this.state.openPopup
        })
      }
     
    }
    render() {
      const {
        dynamicMasterData,  
        rows,
        formErrors,
        fieldData,
        value,
        productDataList,
        openPopup,
        products
      } = this.state;
      return (
        <>
          <Paper
            sx={{
              padding: 2,
            }}
            elevation={0}
          >
            <LandingScreenHeader
              showTitle={creditNoteERPJSON.showTitle}
              screenTitle={creditNoteERPJSON.screenTitle}
              showSaveBtnMain={false}
              showAddButton={false}
            />
            <br />
  
            <DynamicFormWithoutSave
              dynamicMasterData={dynamicMasterData}
            
              showTitle={false}
              screenTitle={creditNoteERPJSON.screenTitle}
              fieldMeta={fieldData.type!=1?
                creditNoteERPJSON.fieldMeta
                :creditNoteERPJSON.fieldMetaGRN}
              showCancel={creditNoteERPJSON.showCancel}
              apiBaseURL={creditNoteERPJSON.apiBaseURL}
              showSaveNextBtn={creditNoteERPJSON.showSaveNextBtn}
              saveBtnText={"Search"}
              onFormSave={this.onFormSave}
              fieldData={fieldData}
              formErrors={formErrors}
              onDataChange={this.onDataChange}
             
            />
           
          
  
           
          {rows.length !== 0 && (
        <>
        {
          fieldData.type=='1'?
          <CreditNoteWithGRN
            
           setStockConsumptionList={this.setProductList}
          productDataList={productDataList}
        />: <TabContext value={value}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            aria-label='scrollable force tabs example'
          >
            {rows &&
              rows.map((catData, index) => (
                <Tab
                  key={index}
                  label={catData.name + '(' + catData.productListForOrder.length + ')'}
                />
              ))}
          </Tabs>

          <TabPanel value={value}>
            <CreditNoteTable
            
              tabValue={value}
              rows={rows}
               setStockConsumptionList={this.setStockConsumptionList}
              productDataList={productDataList}
            />
          </TabPanel>
        </TabContext>
        }
         
          <br />
          <Grid
  item
  sm={12}
  xs={12}
  md={12}
  lg={12}
  rowSpacing={2}
  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
  container
  justifyContent="right"
>
  <ButtonCompo
    size="medium"
    type="submit"
    variant="contained"
    name="Submit"
    onClick={this.saveData}
  />
</Grid>
        
        </>
      )}       
          </Paper>
          {openPopup && (
        <PopUp
          openPopup={openPopup}
          handleCloses={this.togglePopup}
          products={products}
          popUpData={fieldData}
        />
      )}
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => ({
  });
  const mapDispatchToProps = {
    showLoador,
    showNotification,
  };
  export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteERP);
  