import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { Grid, Switch, Tooltip } from "@mui/material";

import TableColumnsToFilter from "../Comman/RT/TableColumnsToFilter";
import { ButtonCompo } from "./Button";

const rows = [
    "Cupcake",
    "Donut",
    "Eclair",
    "Frozen yoghurt",
    "Gingerbread",
    "Honeycomb",
    "Ice cream sandwich",
    "Jelly Bean",
    "KitKat",
    "Lollipop",
    "Marshmallow",
    "Nougat",
    "Oreo",
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ColumnFilterTablePopUp = ({
    openDialog, setOpenDialouge,
    title,
    columnList = [],
    setReportData
}) => {
    const [selected, setSelected] = React.useState([]);

    React.useEffect(() => {
        let data = []
        columnList.map((columnObj) => {
            if (columnObj.isChecked) {
                data.push(columnObj.name)
            }
        })
        setSelected(data)
    }, [])
    const handleClose = () => {
        setOpenDialouge(false)

    };
    const onSubmitData = () => {
        let checkdList = columnList.map((columnObj) => {
            const filterData = selected.filter((row) => row === columnObj.name)
            return { ...columnObj, isChecked: filterData.length != 0 ? true : false }
        })
        setReportData(checkdList)
    };
    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                    fullWidth
                    maxWidth="xl"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        {(title = "Column Filter")}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <TableColumnsToFilter
                            rowes={columnList.filter((row)=>row.isList!=1)}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <br />
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            rowSpacing={2}
                            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                            container
                            justifyContent="right"
                        >
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="submit"
                                onClick={onSubmitData}
                            />
                        </Grid>
                        <br />
                    </DialogContent>
                </BootstrapDialog>
            </Paper>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColumnFilterTablePopUp);
