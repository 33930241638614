import React from "react";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { withRouter } from "../../../components/withRouter";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { SubVoucherDetailColumns } from "../../../tableColumns/table-columns";
import { subVoucherDetailJSON } from "../../../DynamicFormsJson/MastersJSON/subVoucherDetail";
import {
  getSubVoucherDetail,
  getSubVoucherDetailById,
} from "../../../Slice/subVoucherDetail.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
class SubVoucherDetailTable extends React.Component {
  rowEdit = (rowData) => {
    this.props.navigate(
      subVoucherDetailJSON.formPath +
        "/" +
        rowData.id +
        "/" +
        this.props.params.paramId
    );
  };

  async componentDidMount() {
    if (this.props.params.paramId) {
      this.setState({
        showLocation: true,
      });
      const { getSubVoucherDetailById, showLoador, showNotification } =
        this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getSubVoucherDetailById({
          subVoucherId: this.props.params.paramId,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  }

  render() {
    const {
      subVoucherDetailList,
      getSubVoucherDetail,
      getSubVoucherDetailById,
    } = this.props;
    console.log(this.props.params.id);
    return (
      <>
        <DynamicMainScreen
          formPath={subVoucherDetailJSON.formPath}
          openFormPath={subVoucherDetailJSON.openFormPath}
          screenTitle={subVoucherDetailJSON.screenTitle}
          fieldMeta={subVoucherDetailJSON.fieldMeta}
          showPdfDownload={subVoucherDetailJSON.showPdfDownload}
          showExcelDownload={subVoucherDetailJSON.showExcelDownload}
          pdfFileName={subVoucherDetailJSON.pdfFileName}
          excelFileName={subVoucherDetailJSON.excelFileName}
          showAddButton={subVoucherDetailJSON.showAddButton}
          tableColumnsToFilter={subVoucherDetailJSON.tableColumnsToFilter}
          tableColumns={SubVoucherDetailColumns}
          tableData={subVoucherDetailList?.subVoucherDetail}
          apiBaseURL={subVoucherDetailJSON.apiBaseURL}
          showAddButtonInTable={false}
          rowAdd={this.rowAdd}
          showBackButton={true}
          rowEdit={this.rowEdit}
          paramsId={this.props.params.paramId}
          callApi={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subVoucherDetailList: state.subVoucherDetail,
});
const mapDispatchToProps = {
  getSubVoucherDetail,
  getSubVoucherDetailById,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SubVoucherDetailTable);
