import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { subVoucherDetailJSON } from "../../../DynamicFormsJson/MastersJSON/subVoucherDetail";
import { getAccountLedgerList } from "../../../Slice/subVoucherDetail.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicFormForDocumentUpload from "../../../components/dynamicscreens/DynamicFormForDocumentUpload";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg, serverMsg,savemsg,saveFailedMsg,saveWarningMsg,deleteWarningMsg} from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { SubVoucherDetailColumns } from "../../../tableColumns/table-columns";
import {
  getSubVoucherDetail,
  getSubVoucherDetailById,
} from "../../../Slice/subVoucherDetail.slice";
import swal from "sweetalert";
import endpoint from "../../../config/endpoints";
import { apiGet, apiPost ,apiDelete} from "../../../utils/api_service";
// import GoogleMap from "./GoogleMap";
class ColumnSpacingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dynamicMasterData: {
        ledgerAccountDetails: this.props.subVoucherDetailList?.accountLedger,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { openMap } = this.state;
    if (this.props.subVoucherDetailList !== nextProps.subVoucherDetailList) {
      if (
        this.props.subVoucherDetailList?.accountLedger !==
        nextProps.subVoucherDetailList?.accountLedger
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            ledgerAccountDetails: nextProps.subVoucherDetailList?.accountLedger,
          },
        });
      }
    }
  }
 
  
  rowEdit = (rowData) => {
   const{dynamicMasterData}=this.state;
   
   
   
    console.log(rowData)

    rowData = {
     
     ...rowData,
      ledgerAccountNo:rowData.ledgerAccountName,
      type:rowData.type,
     orderBy:rowData.orderBy,

    };
  
    this.setState({
      fieldData: rowData,
    });
  };

  async componentDidMount() {
    const {
      getOutletType,
      getAccountLedgerList,
      getSubVoucherDetailById,
      showLoador,
      showNotification,
    } = this.props;
   
      this.setState({
        showLocation: true,
      });
    
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getAccountLedgerList().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
            
          
          }
        });
       this.getList();
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  
  getList=async()=>{
    const {
     
      getAccountLedgerList,
      getSubVoucherDetailById,
      showLoador,
      showNotification,
    } = this.props;
   
      this.setState({
        showLocation: true,
      });
   
      if (navigator.onLine) {
       
        if (this.props.params.id) {
        showLoador({ loador: true });
        await getSubVoucherDetailById({
          subVoucherId:this.props.params.id,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
  }

  onCancel = () => {
    this.props.navigate(-1);
  };

 

  onRadioChange = (e) => {
    const { value } = e.target;
    this.setState({
      locationValue: value,
    });
    if (value == 1) {
      this.getCurrentLocationData();
    }
  };

 
  onSubmit = (dataToSearch) => {
   if(dataToSearch!=null){
     dataToSearch={
      ...dataToSearch,
      subVoucherMaster:{
        id:this.props.params.id?this.props.params.id:"",
      }
     }
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
         this.onSave(dataToSearch);
       
         
        }
      });
    
   }
  
  }

   onSave = async (dataToSave) => {
   
    const {
     
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.subVoucherDetail,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        console.log(success)
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg ,severity:"success"});
          
       
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
       
        return success;
      });
      this.getList();
    } else {
     showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  render() {
    const {
      dynamicMasterData,
     fieldData,
     
    } = this.state;
    const {
      subVoucherDetailList,
      
    } = this.props;
    return (
      <>
        <>
          <Paper
            sx={{
              p: 2,
              pt: 2,
              borderRadius: 2,
            }}
            elevation={0}
          >
            <LandingScreenHeader
              screenTitle={subVoucherDetailJSON.screenTitle}
              showTable={false}
              showBackButton={true}
              showAddButton={false}
              onCancel={this.onCancel}
            />
            <br />

            <br />
           
            <DynamicFormWithoutSave
              getDataForm={false}
            
              getExtraData={false}
             
              padding={false}
              paddingTop={false}
              showBackToList={false}
              formPath={subVoucherDetailJSON.formPath}
              fieldMeta={subVoucherDetailJSON.fieldMeta}
              dynamicMasterData={dynamicMasterData}
              apiBaseURL={subVoucherDetailJSON.apiBaseURL}
              showSaveNextBtn={subVoucherDetailJSON.showSaveNextBtn}
              paramKey={subVoucherDetailJSON.paramKey}
              onSave={this.onSubmit}
              setFieldData={fieldData}
              resetAfterSave={true}
            />
<br/>
<DynamicMainScreen
          formPath={subVoucherDetailJSON.formPath}
          openFormPath={subVoucherDetailJSON.openFormPath}
          showTitle={false}
          screenTitle={""}
          fieldMeta={subVoucherDetailJSON.fieldMeta}
          showPdfDownload={subVoucherDetailJSON.showPdfDownload}
          showExcelDownload={subVoucherDetailJSON.showExcelDownload}
          pdfFileName={subVoucherDetailJSON.pdfFileName}
          excelFileName={subVoucherDetailJSON.excelFileName}
          showAddButton={subVoucherDetailJSON.showAddButton}
          tableColumnsToFilter={subVoucherDetailJSON.tableColumnsToFilter}
          tableColumns={SubVoucherDetailColumns}
          tableData={subVoucherDetailList?.subVoucherDetail}
          apiBaseURL={subVoucherDetailJSON.apiBaseURL}
          showAddButtonInTable={false}
          rowAdd={this.rowAdd}
          showBackButton={true}
          getTableDataById={true}
          rowEdit={this.rowEdit}
        
          paramsId={this.props.params.paramId}
          callApi={false}
        />
          </Paper>
        </>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  
  subVoucherDetailList: state.subVoucherDetail,
 
});
const mapDispatchToProps = {
  getAccountLedgerList,
  getSubVoucherDetailById,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ColumnSpacingForm);
