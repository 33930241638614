import {
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
import React, { Component } from "react";
import { saveReport, setSaveReport } from '../../Slice/matrixReport.slice'

import { connect } from "react-redux";
import {
    showLoador,
    showNotification,
} from "../Landing/Landing.slice";
import { redColor, titleColor } from "../../config/ColorObj";
import * as msgConstant from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { withRouter } from "../../components/withRouter";
import { LabelCompo } from "../../components/Comman/Label";
import { validText } from "../../components/Comman/Util/Validations";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { ButtonCompo } from "../../components/Comman/Button";
import RTTable from '../../components/Comman/RT/RTTable'
import AddQueryParameter from './AddQueryParameter'
class AddQueryWithParameter extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            fieldData: {},
            isGraph: "1",
            openDialog: false,
            queryParam: [],
            rowData: {},
            formErrors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.report != this.props.report) {
            if (nextProps.report.reportData != this.props.report.reportData) {
                let queryParam = this.props.value == 2 && JSON.parse(nextProps.report.reportData.queryParam1) != null ?
                    JSON.parse(nextProps.report.reportData.queryParam1).map((query, index) => {
                        return { index: index + 1, ...query }
                    }) : this.props.value == 4 && JSON.parse(nextProps.report.reportData.queryParam2) != null ?
                        JSON.parse(nextProps.report.reportData.queryParam2).map((query, index) => {
                            return { index: index + 1, ...query }
                        }) : JSON.parse(nextProps.report.reportData.queryParam3) != null ? JSON.parse(nextProps.report.reportData.queryParam3).map((query, index) => {
                            return { index: index + 1, ...query }
                        }) : []
                let parameterCheck = nextProps.report.reportData.parameterCheck == "" ? "" : JSON.parse(nextProps.report.reportData.parameterCheck)
                this.setState({
                    fieldData: {
                        query: this.props.value == 2 ? nextProps.report.reportData.query1 : this.props.value == 2 ? nextProps.report.reportData.query2 : nextProps.report.reportData.query3,
                        xAxisKey: parameterCheck == "" ? "" : parameterCheck["xAxisKey"],
                        xAxisValue: parameterCheck == "" ? "" : parameterCheck["xAxisValue"],
                        yAxisKey: parameterCheck == "" ? "" : parameterCheck["yAxisKey"],
                        yAxisValue: parameterCheck == "" ? "" : parameterCheck["yAxisValue"],
                    },
                    queryParam: queryParam,
                });
            }
        }
    }
    componentDidMount() {
        console.log(this.props.value)
        let queryParam = this.props.value == 2 && JSON.parse(this.props.report.reportData.queryParam1) != null ?
            JSON.parse(this.props.report.reportData.queryParam1).map((query, index) => {
                return { index: index + 1, ...query }
            }) : this.props.value == 4 && JSON.parse(this.props.report.reportData.queryParam2) != null ?
                JSON.parse(this.props.report.reportData.queryParam2).map((query, index) => {
                    return { index: index + 1, ...query }
                }) : JSON.parse(this.props.report.reportData.queryParam3) != null ? JSON.parse(this.props.report.reportData.queryParam3).map((query, index) => {
                    return { index: index + 1, ...query }
                }) : []
        let parameterCheck = this.props.report.reportData.parameterCheck == "" ? "" : JSON.parse(this.props.report.reportData.parameterCheck)

        this.setState({
            fieldData: {
                query: this.props.value == 2 ? this.props.report.reportData.query1 : this.props.value == 4 ? this.props.report.reportData.query2 : this.props.report.reportData.query3, xAxisKey: parameterCheck == "" ? "" : parameterCheck["xAxisKey"],
                xAxisValue: parameterCheck == "" ? "" : parameterCheck["xAxisValue"],
                yAxisKey: parameterCheck == "" ? "" : parameterCheck["yAxisKey"],
                yAxisValue: parameterCheck == "" ? "" : parameterCheck["yAxisValue"],
            },
            queryParam: queryParam,


        });
    }
    onFormSave = () => {
        let name = this.props.value == 2 ? "query1" : this.props.value == 4 ? "query2" : "query3"
        let parameterCheck = {
            xAxisKey: this.state.fieldData["xAxisKey"] ? this.state.fieldData["xAxisKey"] : "",
            xAxisValue: this.state.fieldData["xAxisValue"] ? this.state.fieldData["xAxisValue"] : "",
            yAxisKey: this.state.fieldData["yAxisKey"] ? this.state.fieldData["yAxisKey"] : "",
            yAxisValue: this.state.fieldData["yAxisValue"] ? this.state.fieldData["yAxisValue"] : ""
        }
        this.onSave({
            ...this.props.report.reportData,
            [name]: this.state.fieldData.query,
            parameterCheck: JSON.stringify(parameterCheck)
        })
        this.props.changeTab(this.props.value == 2 ? 3 : this.props.value == 4 ? 5 : 7)
    }


    onSave = (dataToSave) => {

        if (navigator.onLine) {
            const {
                saveReport,
                showLoador,
                showNotification,
            } = this.props;
            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.updateMsg : msgConstant.savemsg,
                    });

                } else {
                    showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };

    toggleFilter = () => {
        if (this.state.openDialog) {
            this.setState({
                rowData: {},
            })
        }
        this.setState({
            openDialog: !this.state.openDialog
        })
    }
    rowEdit = (rowData) => {

        this.setState({
            rowData: rowData
        })
        this.toggleFilter()
    }
    onTextFieldDataChange = (event) => {
        if (
            event.target.value === "" ||
            validText.test(event.target.value)
        ) {
            this.setState({ fieldData: { ...this.state.fieldData, [event.target.name]: event.target.value } });
        }
    };
    rowDelete = (rowData) => {
        const { showNotification, showLoador } = this.props;
        swal({
            title: "Are you sure?",
            text: msgConstant.deleteWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                if (navigator.onLine) {
                    let filterData = this.state.queryParam.filter((row) => row.index != rowData.index)
                    let filterList = filterData.map((data, index) => {
                        return {
                            index: index + 1, parameterKey: data.parameterKey,
                            parameterValue: data.parameterValue,
                            isIn: data.isIn,
                        }
                    })
                    let queryParam = this.props.value == 2 ? "queryParam1" : this.props.value == 4 ? "queryParam2" : "queryParam3"
                    this.onSave({
                        ...this.props.report.reportData,
                        [queryParam]: JSON.stringify(filterList)
                    }
                    )
                } else {
                    showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
                }
            }
        });
    };
    render() {
        const {

        } = this.props;

        const { fieldData, formErrors, queryParam, rowData, openDialog } = this.state;

        return (
            <>

                <Grid container sx={{ marginTop: 2 }} spacing={1}>

                    <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={1}
                            md={1}
                            lg={1}
                        >
                            &nbsp;&nbsp;
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{
                                    fontSize: 16,
                                    color: titleColor,
                                }}
                                label={"Query :"}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={11}
                            md={11}
                            lg={11}
                        >
                            <TextFieldCompo
                                placeHolder={"Query"}
                                size="small"
                                color="primary"
                                name={"query"}
                                multiline={true}
                                id={"query"}
                                value={fieldData["query"] ? fieldData["query"] : ""}
                                onChange={this.onTextFieldDataChange}
                                fullWidth
                                errorText={formErrors["query"] ? formErrors["query"] : ""}
                            />
                        </Grid>
                    </Grid>
                    {this.props.value == 6 && <>
                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >

                            <Grid
                                item
                                xs={12}
                                sm={2}
                                md={2}
                                lg={2}
                            >
                                &nbsp;&nbsp;
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label={"*"}
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"1st Query :"}
                                />

                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={10}
                                md={10}
                                lg={10}
                            >
                                <TextFieldCompo
                                    placeHolder={"1st Query "}
                                    size="small"
                                    color="primary"
                                    name={"xAxisKey"}
                                    multiline={true}
                                    id={"xAxisKey"}
                                    value={fieldData["xAxisKey"] ? fieldData["xAxisKey"] : ""}
                                    onChange={this.onTextFieldDataChange}
                                    fullWidth
                                    errorText={formErrors["xAxisKey"] ? formErrors["xAxisKey"] : ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >

                            <Grid
                                item
                                xs={12}
                                sm={2}
                                md={2}
                                lg={2}
                            >
                                &nbsp;&nbsp;
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label={"*"}
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"3rd Query  :"}
                                />

                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={10}
                                md={10}
                                lg={10}
                            >
                                <TextFieldCompo
                                    placeHolder={"3rd Query"}
                                    size="small"
                                    color="primary"
                                    name={"xAxisValue"}
                                    multiline={true}
                                    id={"xAxisValue"}
                                    value={fieldData["xAxisValue"] ? fieldData["xAxisValue"] : ""}
                                    onChange={this.onTextFieldDataChange}
                                    fullWidth
                                    errorText={formErrors["xAxisValue"] ? formErrors["xAxisValue"] : ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                            >
                                &nbsp;&nbsp;
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label={"*"}
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"2nd Query :"}
                                />

                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                            >
                                <TextFieldCompo
                                    placeHolder={"2nd Query"}
                                    size="small"
                                    color="primary"
                                    name={"yAxisValue"}
                                    multiline={true}
                                    id={"yAxisValue"}
                                    value={fieldData["yAxisValue"] ? fieldData["yAxisValue"] : ""}
                                    onChange={this.onTextFieldDataChange}
                                    fullWidth
                                    errorText={formErrors["yAxisValue"] ? formErrors["yAxisValue"] : ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={6}
                        >

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                            >
                                &nbsp;&nbsp;
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label={"*"}
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{
                                        fontSize: 16,
                                        color: titleColor,
                                    }}
                                    label={"3rd Query:"}  
                                />

                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                            >
                                <TextFieldCompo
                                    placeHolder={"2nd Query"}
                                    size="small"
                                    color="primary"
                                    name={"yAxisKey"}
                                    multiline={true}
                                    id={"yAxisKey"}
                                    value={fieldData["yAxisKey"] ? fieldData["yAxisKey"] : ""}
                                    onChange={this.onTextFieldDataChange}
                                    fullWidth
                                    errorText={formErrors["yAxisKey"] ? formErrors["yAxisKey"] : ""}
                                />
                            </Grid>
                        </Grid>
                        
                    </>}
                    <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name="Add"
                            // fullWidth={true}
                            onClick={this.toggleFilter}
                        />
                    </Grid>


                </Grid>

                <br />
                <RTTable
                    columns={[
                        { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
                        {
                            title: "Parameter Key",
                            name: "parameterKey",
                            formDataKey: "parameterKey",
                            showInExcel: true,
                            canSearch: true,
                        },
                        {
                            title: "Parameter Value",
                            name: "parameterValue",
                            formDataKey: "parameterValue",
                            showInExcel: true,
                            canSearch: true,
                        },
                        { name: "action", title: "Action" },
                    ]}
                    hiddenColumnNames={[]}
                    tableData={queryParam}
                    rowDelete={this.rowDelete}
                    showPegination={false}
                    showEditIcon={true}
                    rowEdit={this.rowEdit}
                    isActionColActive={true}
                />

                <br />

                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                >
                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save and Next"
                        // fullWidth={true}
                        onClick={this.onFormSave}
                    />


                </Grid>
                {openDialog && <AddQueryParameter value={this.props.value} queryParam={queryParam} rowData={rowData} openDialog={openDialog} setOpenDialouge={this.toggleFilter}
                />}
            </>
        );
    }
}
const mapStateToProps = (state) => ({ report: state.matrixReport });
const mapDispatchToProps = { showLoador, showNotification, saveReport };
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AddQueryWithParameter);
