import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  user: [],
  userData:{},
};
let URL = endpoints.user1;
const userSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    userAccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        user: row,
      };
    },
    userSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        userData: data,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { userAccess,userSaveSuccess, resetState } = userSlice.actions;

export default userSlice.reducer;

export const getUserList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        const content = data.data;
        console.log(content);
        const row = content.map((typeObject, index) => {
          let typeData = {
            index: index + 1,
            userName: typeObject.userName ? typeObject.userName : "",
            usrPwd: typeObject.usrPwd ? typeObject.usrPwd : "",
            roleId: typeObject.roleId ? typeObject.roleId.toString() : "",
            id: typeObject.usrId ? typeObject.usrId : "",
            token: typeObject.token ? typeObject.token : "",
            locationId: typeObject.locationId ? typeObject.locationId : "",
            accLocationId: typeObject.accLocationId
              ? typeObject.accLocationId
              : "",
            email: typeObject.email ? typeObject.email : "",
            contact: typeObject.contact ? typeObject.contact : "",
            deptId: typeObject.deptId ? typeObject.deptId : "",
            usrType: typeObject.usrType ? typeObject.usrType : "",
          };
          return typeData;
        });
        console.log(row);
        dispatch(userAccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const saveUser =
  ({ dataToSave }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          console.log(data)
          dispatch(userSaveSuccess({ data: data.data }));
          return { response: data.data, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };