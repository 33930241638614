import MenuIcon from "@mui/icons-material/Menu";
import AutoComplete from "./Comman/AutoComplete";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import { Avatar, FormControl, Grid, Select } from "@mui/material";
import { showLoador, showNotification } from "../Pages/Landing/Landing.slice";

import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { onToggle } from "../CommonActions/common.slice";
import "../CSS/NewLanding.css";
import { logOut } from "../Pages/Login/login.slice";
import { FontAwesomeIconCompo } from "./Comman/IconButton";
import { white, blackColor, primaryColor } from "../config/ColorObj";
// import logo from "../Images/logo.png";
import bizintel from "../Images/bizintel.png";
import DrawerTab from "./DrawerTab";

import { serverMsg } from "../config/messageconstant";
import { row } from "@syncfusion/ej2-react-grids";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(9),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "21px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "26ch",
    },
    // border: "1px solid #ccc",
    borderRadius: "5px",
  },
}));
const breakpoint = 600;
const NavbarLanding = ({
  logOut,
  onToggle,
}) => {
  const [locationList, setLocationList] = React.useState([]);
  const toggleDrawer = () => {
    onToggle();
  };
  let newLocation = useLocation();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    const locationIdList = localStorage.getItem("locationIdList");
    if (locationIdList && locationList.length === 0) {
      const valuesArray = JSON.parse(locationIdList);
      let location = localStorage.getItem("location");
      if (location == null&&valuesArray.length!=0) {
        localStorage.setItem("location", valuesArray[0].id);
      }

      setLocationList(valuesArray);
    }
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const onAutocompleteChange = (e) => {
      window.location.replace(newLocation.pathname);
      localStorage.setItem("location", e.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutHandler = () => {
    localStorage.clear();
    logOut();
    window.location.replace("/login");
  };


  return (
    <>
      <Grid sx={{ background: primaryColor ,display:"flex", flexDirection:"row" , alignItems:"center", justifyContent:"space-between" }} container xs={12} sm={12} md={12}>
        <Grid sx={{ paddingLeft: 2, display:"flex" }} item xs={12} sm={1} md={1} lg={1}>
          <img
            className="Image10"
            style={{ height: 44, padding: 1 }}
            src={bizintel}
          />
        </Grid>



        <Grid sx={{ paddingLeft: 8 ,marginTop:"14px" }} item xs={12} sm={7.5} md={7.5} lg={7.5}>
          <DrawerTab />
        </Grid>
        <Grid sx={{ marginTop: 0 }} item xs={12} sm={1.5} md={1.5} lg={1.5}>
          {/* <AutoComplete
            name={"location"}
            label={"Location"}
            placeHolder={"Location"}
            keyColName={"id"}
            value={
              localStorage.getItem("location")
                ? localStorage.getItem("location")
                : ""
            }
            options={locationList}
            onChange={onAutocompleteChange}
            errorText={""}
          /> */}
          <FormControl color={"primary"} fullWidth={true}>
            <Select
              labelId="demo-simple-select-helper-label"
              className="text-black"
              name="department"
              id="department"
              sx={{
                
                textAlign: "left",
                backgroundColor: "#FFF",
                fontSize: "small",
                [`& .MuiInputBase-input`]: {
                  padding: "8px",
                },
              }}
              value={
                localStorage.getItem("location")
                  ? localStorage.getItem("location")
                  : ""
              }
              onChange={onAutocompleteChange}
              size="small"
              fullWidth={true}
            >
              {locationList.map((departmentData) => {
                return (
                  <MenuItem value={departmentData.id}>
                    {departmentData.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>




        
        <Grid sx={{ marginTop: 0 }} item xs={12} sm={2} md={2} lg={2}>
          <Button
            sx={{
              width: 50,
              height: 40,
              color: white,
              // ml: "1000px",
              minWidth: "225px",
            }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<PersonOutlineIcon />}
            endIcon={<ExpandMoreIcon />}
          >
            {localStorage.getItem("userName")}
          </Button>

          <Menu
            id="basic-menu1"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={logOutHandler}> Logout </MenuItem>

          </Menu>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  hrData: state.hrDetails,
  departmentList: state.department,
  parentChildList: state.parentProfile,
});

const mapDispatchToProps = {
  onToggle,
  logOut,
  showLoador,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarLanding);
