import endpoint from "../../config/endpoints";

export const SalePurchaseApprovalJSON = {
  formPath: "/account-ledger",
  apiBaseURL: endpoint.salePurchaseApproval,
  screenTitle: "Sale Purchase Approval",
  showAddButton: true,
  showContactInfo: true,
  showPdfDownload: true,
  showExcelDownload: true,
  tableColumnsToFilter: [
    {
      columnDisplayName: "Account Code",
      columnKeyName: "accountLedgerCode",
      isChecked: true,
    },

    {
      columnDisplayName: "Account Ledger Name",
      columnKeyName: "accountLedgerName",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "From Date",
      md: 3.5,
      lg: 3.5,
      sm: 3.5,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "To Date",
      md: 3.5,
      lg: 3.5,
      sm: 3.5,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "typeDetails",
      dataKey: "entryType",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaForOption: [
    {
      label: "TDS Account",
      controlType: "autocomplete",
      placeHolder: "Select TDS Account ",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "tdsAccountDetails",
      dataKey: "tdsAccountId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "TDS Percentage",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "tdsPer",
      inputType: "number",
      isMandatory: true,
    },
  ],
};
