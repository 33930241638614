import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  salesPurchaseApprovalList: [],
};
let URL = endpoints.salesPurchase;
const salesPurchaseApprovalSlice = createSlice({
  name: "salesPurchaseApproval",
  initialState,
  reducers: {
    salesPurchaseApprovalSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesPurchaseApprovalList: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { salesPurchaseApprovalSuccess, resetState } =
  salesPurchaseApprovalSlice.actions;

export default salesPurchaseApprovalSlice.reducer;

export const setSalesPurchaseApproval =
  ({ row }) =>
  async (dispatch) => {
    dispatch(salesPurchaseApprovalSuccess({ row }));
  };
