import endpoint from "../../config/endpoints";

export const ManageStockJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.manageStockHeader,
  screenTitle: "Manage Stock",
  formPath: "/manage-stock-form",
  formPathView: "/manage-stock-view",
  showBackToList: false,
  showTitle: false,
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
    },
    {
      label: "Stock Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "In-Stock",
          value: "1",
        },
        {
          label: "Out-Stock",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outlet",
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch",
      controlType: "textfield",
      placeHolder: "Enter Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "batch",
      isMandatory: true,
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "qty",
      isMandatory: true,
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Mrp",
      controlType: "textfield",
      placeHolder: "Enter Mrp",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "mrp",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "UOM",
      controlType: "textfield",
      placeHolder: "UOM",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uom",
      isMandatory: false,
      disableTrue: true,
    },
    {
      label: "Conversion Value",
      controlType: "textfield",
      placeHolder: "Enter Conversion Value",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uomConversionValue",
      isMandatory: false,
      disableTrue: true,
    },
  ],
};
