import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { SalePurchaseApprovalJSON } from "../../DynamicFormsJson/Transaction/salePurchaseApproval";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  saveWarningMsg,
  saveFailedMsg,
  savemsg,
} from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { apiGet } from "../../utils/api_service";
import SalePurchaseApprovalTable from "./SalePurchaseApprovalTable";
import { Paper, Divider } from "@mui/material";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { setSalesPurchaseApproval } from "../../Slice/salesPurchaseApproval.slice";

class SalePurchaseApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      rowList: [],
      showTable: false,
      dynamicMasterData: {
        typeDetails: [
          { id: 2, name: "Purchase" },
          { id: 1, name: "Sales" },
          { id: 3, name: "Credit Note" },
        ],
      },
    };
  }
  async componentDidMount() {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSubmitData = (dataToSave) => {
    console.log(dataToSave);
    this.setState({ fieldData: dataToSave });
    const { showLoador, showNotification, setSalesPurchaseApproval } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url:
          endpoint.salePurchaseApproval +
          "/get-sale-purchase-list?entryType=" +
          dataToSave.entryType +
          "&fromDate=" +
          dataToSave.fromDate +
          "&toDate=" +
          dataToSave.toDate,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          console.log(data);
          if (!data.error) {
            let rowData = data.data;
            const row = rowData.map((rowData, index) => {
              let rowDataObj = {
                index: index + 1,
                ...rowData,
                postAccJson:
                  rowData.postAccJson != null
                    ? JSON.parse(rowData.postAccJson).map(
                        (rowData1, index1) => {
                          let rowDataObj1 = {
                            index: index1 + 1,
                            dabitCode: rowData1.dabitCode,
                            ...rowData1,
                            amount:
                              rowData1.type == "D"
                                ? Math.abs(rowData1.amount).toFixed(2)
                                : rowData1.amount.toFixed(2),
                            type: rowData1.type,
                          };
                          return rowDataObj1;
                        }
                      )
                    : [],
                checked: false,
              };
              return rowDataObj;
            });
            console.log(row);
            setSalesPurchaseApproval({ row });
            this.setState({
              rowList: row,
              showTable: row.length!=0?true:false,
            });
            // console.log(rowData);
            // setSaveAccountLedger({ rowObject: rowData });

            return { data: data, success };
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
          return { data: {}, success: false };
        }
        return { response: {}, success: false };
      });
      return response;
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  goBackToHeader = () => {
    this.onSubmitData(this.state.fieldData);
  };

  render() {
    const { dynamicMasterData, fieldData, rowList, showTable } = this.state;
    console.log(rowList);
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={SalePurchaseApprovalJSON.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            showTable={false}
            showBackButton={false}
            onCancel={this.onCancel}
          />
          <br />
          <DynamicFormWithoutSave
            formPath={SalePurchaseApprovalJSON.formPath}
            fieldMeta={SalePurchaseApprovalJSON.fieldMeta}
            tableColumnsToFilter={SalePurchaseApprovalJSON.tableColumnsToFilter}
            apiBaseURL={SalePurchaseApprovalJSON.apiBaseURL}
            baseIdColumn={SalePurchaseApprovalJSON.baseIdColumn}
            dynamicMasterData={dynamicMasterData}
            showSaveBtnMain={true}
            showSaveBtn={false}
            saveBtnText={"Search"}
            onSave={this.onSubmitData}
            callApi={true}
            getByIdApi={false}
            getDataById={this.getDataById}
          />
          <br />
          <Divider sx={{ borderWidth: 1, color: "#000000" }} />
          <br />
          {showTable && (
            <SalePurchaseApprovalTable
              headerData={fieldData}
              rowList={rowList}
              goBackToHeader={this.goBackToHeader}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  setSalesPurchaseApproval,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SalePurchaseApproval);
