
export const BankRecReportoJson = {
    screenTitle: "Bank Reco Report",
    showPdfDownload: true,
    showExcelDownload: false,
    fieldMeta:
    [
     
    
     
        {
            label: "",
            controlType: "autocomplete",
            placeHolder: "Select  Account ",
            md: 3,
            lg: 3,
            sm: 3,
            xs: 12,
            labelSM: 0,
            labelMD: 0,
            labelLG: 0,
            valueSM: 11,
            valueMD: 11,
            marginLeft:0,
            valueLG: 11,
            error:"accountLedgerGroupsMaster",
            masterName: "accountLedgerGroupMasterDetail",
          hideLabel:true,
            dataKey: "accountLedgerGroupsId",
            isRootLevelKey: true,
            isMandatory: true,
          },
      {
        label: "",
        controlType: "monthpicker",
        md: 3,
        lg: 3,
        sm: 3,
        xs: 12,
        labelSM: 0,
        labelMD: 0,
        labelLG: 0,
        valueSM: 11,
        valueMD: 11,
        marginLeft:0,
        valueLG: 11,
        hideLabel:true,
        dataKey: "monthYear",
        searchDataKey:"monthYear",
        isRootLevelKey: true,
        isMandatory: true,
        // defaultValue: dayjs(new Date()).format("MM")+"-"+dayjs(new Date()).format("YYYY"),
    },
    ]
  };
  